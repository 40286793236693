import { Event } from 'src/models/event';
import placeholderEventImage from 'src/assets/placeholderEventImage.png';

export const placeHolderEvent: Event = {
  id: 'eventId1',
  name: 'Thursty Thursday - Warehouse Party',
  description: '',
  startDate: '',
  endDate: '',
  onReservation: true,
  imageUrl: placeholderEventImage,
  tickets: [
    {
      id: 'earlyBirdTicketId',
      name: 'Early Bird',
      total: 40,
      sold: 30,
      price: 10,
      description: '',
    },
    {
      id: 'generalAdmissionTicketId',
      name: 'General Admission',
      total: 40,
      sold: 10,
      price: 20,
      description: '',
    },
    {
      id: 'vipTicketId',
      name: 'VIP (Backstage)',
      total: 30,
      sold: 15,
      price: 100,
      description: '',
    },
  ],
};
