import React, { useMemo } from 'react';
import LogoIMG from '../../../assets/logo.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { logOut } from 'src/slices/authentication';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Stack, Typography, Box, Button } from '@mui/material';
import { Menu } from 'src/data/menus';
import { createUseStyles } from 'react-jss';
import { reset } from '../../../slices/event';

const useStyles = createUseStyles({
  container: {},
  logo: {
    width: 70,
    height: 45,
    minWidth: 70,
    minWeight: 45,
  },
  tabsContainer: {
    maxWidth: '50%',
  },
  menuItem: {
    fontSize: '17.2px',
    fontWeight: 500,
    fontStyle: 'normal',
    color: 'black',
    lineHeight: '20px',
    whiteSpace: 'nowrap',

    '&:hover': {
      fontWeight: 'bold',
    },
  },
  activeMenuItem: {
    fontSize: '17.2px',
    fontStyle: 'normal',
    color: 'black',
    lineHeight: '20px',
    fontWeight: 'bold',

    whiteSpace: 'nowrap',
  },
});

interface DesktopHeaderProps {
  menu: Menu[];
}

const DesktopHeader: React.VFC<DesktopHeaderProps> = ({ menu }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  const history = useHistory();

  const isAuthenticated = useMemo(() => user, [user]);

  const onClickSignIn = async () => {
    history.push('/sign-in');
  };

  const onClickLogOut = async () => {
    dispatch(logOut());
  };

  const displayMenuItem = ({
    name,
    key,
    displayMenu,
    subtitle,
    disabled,
    url,
  }: Menu) => {
    const isActive = location.pathname.slice(1) === key;
    const width = () => {
      switch (key) {
        case 'events':
          return '53.09px !important';
        case 'eventplanner':
          return '162.3px !important';
        case 'tac-app':
          return '32.27px !important';
        case 'blog':
          return '35.2px !important';
        case 'nfts':
          return '39.22px !important';
        case 'profile':
          return '50.38px !important';
        case 'about-us':
          return '60.38px !important';
        default:
          return '100% !important';
      }
    };
    return url ? (
      <a
        href={url}
        target="_blank"
        key={key}
        style={{ textDecoration: 'none' }}
        rel="noreferrer"
      >
        {name === 'Marketing tools' && <Box sx={{ marginTop: 2.4 }} />}

        <Box
          width={width()}
          sx={{
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Stack direction="row" alignItems="center">
            <Typography
              className={isActive ? classes.activeMenuItem : classes.menuItem}
            >
              {name}
            </Typography>
            {displayMenu && <ArrowDropDownIcon />}
          </Stack>
          {subtitle && (
            <Typography
              fontWeight="200"
              fontSize="13px"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
      </a>
    ) : (
      <Link
        to={`/${key}`}
        style={{
          textDecoration: 'none',
          pointerEvents: disabled ? 'none' : 'all',
        }}
        key={key}
      >
        {name === 'Marketing tools' && <Box sx={{ marginTop: 2.4 }} />}

        <Box
          width={width()}
          sx={{
            display: 'flex',
            alignItems: 'stretch',
          }}
        >
          <Stack direction="row" alignItems="center">
            <Typography
              className={isActive ? classes.activeMenuItem : classes.menuItem}
            >
              {name}
            </Typography>
            {displayMenu && <ArrowDropDownIcon />}
          </Stack>
          {subtitle && (
            <Typography
              fontWeight="200"
              fontSize="13px"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Box>
      </Link>
    );
  };

  const displayLoginOutButton = () => (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      minWidth="160px"
      marginLeft={isAuthenticated ? "20px" : "0px"}
    >
      <Button
        onClick={isAuthenticated ? onClickLogOut : onClickSignIn}
        sx={{
          border: "2px solid #FF0202",
          background: "white",
          cursor: "pointer",
          height: "45px",
          width: { xs: "155.7px", xl: "155.7" },
          borderRadius: "50px",
          textTransform: "none",
          color: "black",
          "&:hover": {
            backgroundColor: "#F70303",
            border: "solid 1px #F70303",
            color: "black",
          },
        }}
      >
        <Typography fontSize={{ xs: "16.2px", xl: "17.2px" }} fontWeight="bold">
          {isAuthenticated ? "Log out" : "Sign in"}
        </Typography>
      </Button>
    </Box>
  );

  return (
    <>
      <Box className={classes.container}>
        <Stack direction="row" justifyContent={'space-between'}>
          <Link to={"/"}>
            <Box
              component="img"
              className={classes.logo}
              src={LogoIMG}
              alt="logo"
              onClick={() => {
                dispatch(reset());
              }}
            />
          </Link>
          {user?.type === "eventPlanner" && (
            <Box sx={{ marginLeft: { xs: "24px", xl: "30px" } }}>
              <Typography
                fontSize={{ xs: "24px", xl: "30px" }}
                fontWeight="bold"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                TAC Event Planner
              </Typography>
            </Box>
          )}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="start"
            width="100%"
            gap="28.56px"
            className={classes.tabsContainer}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="start"
              gap="37px"
              width="100%"
            >
              {menu.map(displayMenuItem)}
            </Box>
            {displayLoginOutButton()}
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default DesktopHeader;
