import { createUseStyles } from 'react-jss';

export const commonStyles = createUseStyles(() => ({
  Input: {
    height: '45px',
    color: 'black',
    '& .MuiOutlinedInput-input': {
      padding: '10.7px 20.7px',
    },

    [`& fieldset`]: { border: 'none' },
  },
  Label: {
    fontWeight: 500,
    fontSize: 13.5,
    color: 'black',
    opacity: 0.26,
  },
  whiteInputProps: {
    border: 'solid 2px #F0F0F0',
    borderRadius: 30,
    backgroundColor: 'white',
    height: '45px',
    [`& fieldset`]: { border: 'none' },
  },
  greyInput: {
    color: 'black',
    [`& fieldset`]: {
      border: 'none',
    },
  },
  greyInputProps: {
    border: 'solid 2px #F0F0F0',
    borderRadius: 30,
    backgroundColor: '#F0F0F0',
  },
  mainCTA: {
    color: 'black',
    borderRadius: '10rem',
    border: '3px solid black',
    fontSize: '1.15rem',
    textTransform: 'none',
    '&:hover': {
      border: '3px solid black',
      color: 'white',
      backgroundColor: '#000',
    },
    '& svg': {
      width: '32px',
      height: '32px',
    },
  },
  orangeCTA: {
    color: 'white',
    borderRadius: '10rem',
    fontSize: '1.15rem',
    textTransform: 'none',
    marginTop: '4rem',
    background: 'orange',
    minWidth: '22rem',
    '&:hover': {
      backgroundColor: 'orange',
      opacity: 0.7,
    },
  },
  landingTitle: {
    textAlign: 'center',
    fontWeight: 900,
    color: 'black',
    fontSize: '60px',
    lineHeight: '73px',
    '@media screen and (max-width: 1500px)': {
      fontSize: '55px',
    },
    '@media screen and (max-width: 1150px)': {
      fontSize: '55px',
    },
    '@media screen and (max-width: 900px)': {
      fontSize: '40px',
    },
    '@media screen and (max-width: 500px)': {
      fontSize: '40px',
      lineHeight: '50px',
    },
  },
  landingSectionTitle: {
    fontWeight: 800,
    '@media screen and (max-width: 1950px)': {
      fontSize: '70px',
    },
    '@media screen and (max-width: 1700px)': {
      fontSize: '60px',
    },
    '@media screen and (max-width: 1300px)': {
      fontSize: '50px',
    },
    '@media screen and (max-width: 1150px)': {
      fontSize: '50px',
    },
  },
}));
