import Parse from 'parse/node';
import { City } from 'src/models/city';
import { Event } from 'src/models/event';
import { Campaign } from 'src/models/campagin';
import { Planner } from 'src/models/planner';
import { Request } from 'src/models/request';
import { PromoCode } from 'src/models/template';
import { EventTicket, MinimalTicketObject, Ticket } from 'src/models/ticket';
import { User } from 'src/models/user';
import { MarketingRequest } from 'src/models/mRequest';
import { SmsPackage } from 'src/models/smspackage';

export function serializeUser(user: Parse.User<Parse.Attributes>): User | null {
  return {
    id: user.get('objectId'),
    name: user.get('name'),
    firstName: user?.get('firstName'),
    lastName: user?.get('lastName'),
    username: user.getUsername(),
    email: user.getUsername(),
    phoneNumber: user.get('phoneNumber'),
    type: user.get('type'),
    planner: user.get('planner')?.id,
    stripeId: user.get('stripeId'),
    bio: user.get('bio'),
    socialLink: user.get('socialLink'),
    dateOfBirth: user.get('dateOfBirth'),
    gender: user.get('gender'),
  };
}

export function serializeEvent(
  event: Parse.Object<Parse.Attributes>,
): Event | null {
  try {
    return {
      name: event.get('name'),
      description: event.get('description'),
      id: event.id,
      startDate: String(event.get('start')),
      endDate: String(event.get('end')),
      currencySymbol: event.get('currencySymbol'),
      cityId: event.get('city')?.id,
      cityColor: event.get('city').get('mainColor'),
      currency: event.get('currency'),
      plannerPointer: serializePlanner(event.get('planner')),
      plannerName: event.get('planner')?.get('name'),
      imageUrl: event.get('coverPhoto')?.url(),
      address: event.get('address'),
      club: event.get('club'),
      onReservation: event.get('onReservation'),
      dj: event.get('dj'),
      music: event.get('music'),
      countryBlock: event.get('countryBlock'),
      infoRule: event.get('infoRule'),
      infoRule2: event.get('infoRule2'),
      tickets: event.get('tickets'),
      addressName: event.get('addressName'),
      timeZone: event.get('timeZone'),
      state: event.get('state'),
      available: event.get('available'),
      scannerCodePassword: event.get('scannerCodePassword'),
      ticketsExpireIn: parseInt(event.get('ticketsExpireIn')),
      createdAt: (event.get('createdAt') as Date | undefined)?.toString(),
      usePaymob: event.get('usePaymob'),
      organizerName: event.get('organizerName'),
      isPackages: event.get('isPackages'),
      onDoorTickets: event.get('onDoorTickets'),
      packages: event.get('packages'),
    };
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function serializeEvents(
  events: Parse.Object<Parse.Attributes>[],
): Event[] {
  return events
    .map((event) => serializeEvent(event))
    .filter((value) => value) as Event[];
}

export function serializeTicket(
  ticket: Parse.Object<Parse.Attributes>,
): Ticket {
  return {
    id: ticket.id,
    name: ticket.get('name'),
    chargeStripe: ticket.get('chargeStripe'),
    ticketPoint: ticket.get('ticketPoint'),
    idTicket: ticket.get('idTicket'),
    fromPlanner: ticket.get('fromPlanner'),
    price: ticket.get('price'),
    available: ticket.get('available'),
    fees: ticket.get('fees'),
    used: ticket.get('used'),
    fromPoints: ticket.get('fromPoints'),
    fromGifts: ticket.get('fromGifts'),
    eventId: ticket.get('eventId'),
    event: serializeEvent(ticket.get('event')) ?? undefined,
    user: ticket.get('user').id,
    createdAt: ticket.get('createdAt')?.toISOString(),
    updatedAt: ticket.get('updatedAt')?.toISOString(),
    planner: ticket.get('planner')?.id,
    pkpass: ticket.get('pkpass'),
    recipientEmail: ticket.get('recipientEmail'),
    recipientGender: ticket.get('recipientGender'),
    recipientName: ticket.get('name'),
    paymobOrderId: ticket.get('paymobOrderId'),
    guest: ticket.get('guest'),
    isRefunded: ticket.get('isRefunded'),
    paymentMethod: ticket.get('paymentMethod'),
  };
}

export function serializeTickets(
  tickets: Parse.Object<Parse.Attributes>[],
): Ticket[] {
  return tickets.map((ticket) => serializeTicket(ticket));
}
export function serializeSmsPackages(
  smspackages: Parse.Object<Parse.Attributes>[],
): SmsPackage[] {
  return smspackages.map((smspackage) => serializeSmsPackage(smspackage));
}

export function serializeEventTicket(
  ticket: Parse.Object<Parse.Attributes>,
): EventTicket {
  return {
    id: ticket.id,
    name: ticket.get('name'),
    description: ticket.get('description'),
    sold: ticket.get('sold'),
    total: ticket.get('total'),
    max: ticket.get('max'),
    giftTotal: ticket.get('giftTotal'),
    fees: ticket.get('fees'),
    price: ticket.get('price'),
    available: ticket.get('available'),
    giftSold: ticket.get('giftSold'),
    min: ticket.get('min'),
    idTicket: ticket.get('idTicket'),
    onReservation: ticket.get('onReservation'),
    takingRequest: ticket.get('takingRequest'),
    onInvitation: ticket.get('onInvitation'),
    used: ticket.get('used'),
    createdAt: ticket.get('createdAt'),
    recipientGender: ticket.get('recipientGender'),
    isRefunded: ticket.get('isRefunded'),
  };
}

export function serializeMarketingCampaign(
  campaign: Parse.Object<Parse.Attributes>,
): Campaign {
  return {
    id: campaign.id,
    name: campaign.get('name'),

    description: campaign.get('description'),
    price: campaign.get('price'),
    title: campaign.get('title'),
    subTitle: campaign.get('subTitle'),
    color: campaign.get('color'),
    type: campaign.get('type'),
  };
}

export function serializeSmsPackage(
  smspackage: Parse.Object<Parse.Attributes>,
): SmsPackage {
  return {
    id: smspackage.id,

    price: smspackage.get('price'),
    amount: smspackage.get('amount'),
  };
}

export function serializeMarketingRequest(
  request: Parse.Object<Parse.Attributes>,
): MarketingRequest {
  return {
    id: request.id,
    plannerId: request.get('plannerId'),
    eventId: serializeEvent(request.get('eventId')) ?? undefined,
    status: request.get('status'),
    delivered: request.get('delivered'),
    totalCost: request.get('totalCost'),
    targetCampaign:
      serializeMarketingCampaign(request.get('targetCampaign')) ?? undefined,
    targetDatabase: request.get('targetDatabase'),
    emailsCount: request.get('emailsCount'),
  };
}
export function serializeMarketingRequqests(
  requests: Parse.Object<Parse.Attributes>[],
): MarketingRequest[] {
  return requests.map((ticket) => serializeMarketingRequest(ticket));
}
export function serializeMarketingCampaigns(
  campaigns: Parse.Object<Parse.Attributes>[],
): Campaign[] {
  return campaigns.map((campaigns) => serializeMarketingCampaign(campaigns));
}

export function seriaLizePromoCode(
  promoCode: Parse.Object<Parse.Attributes>,
): PromoCode {
  return {
    id: promoCode.id,
    code: promoCode.get('code'),
    discount: promoCode.get('discount'),
    limit: promoCode.get('lmiit'),
    used: promoCode.get('used'),
    sales: promoCode.get('sales'),
  };
}

export function serializePromoCodes(
  promoCodes: Parse.Object<Parse.Attributes>[],
): PromoCode[] {
  return promoCodes.map((promoCode) => seriaLizePromoCode(promoCode));
}
export function serializeEventTickets(
  tickets: Parse.Object<Parse.Attributes>[],
): EventTicket[] {
  return tickets.map((ticket) => serializeEventTicket(ticket));
}

export function serializeRequest(
  request: Parse.Object<Parse.Attributes>,
): Request | null {
  try {
    return {
      id: request.id,
      eventId:
        typeof request.get('eventId') === 'string'
          ? request.get('eventId')
          : request.get('eventId')
          ? serializeEvent(request.get('eventId'))
          : null,
      user: request.get('user')
        ? serializeUser(request.get('user')) ?? undefined
        : undefined,
      isApproved: request.get('isApproved'),
      isDeclined: request.get('isDeclined'),
      isPending: request.get('isPending'),
      isInvitation: request.get('isInvitation'),
      createdAt: request.get('createdAt') as Date | undefined,
      updatedAt: request.get('updatedAt') as Date | undefined,
      ACL: request.getACL(),
      ticketId: request.get('ticketId'),
      ticketName: request.get('ticketName'),
      numberOfTickets: request.get('numberOfTickets'),
      recipients: request.get('recipients'),
      paid: request.get('paid'),
      tickets: request.get('tickets'),
      ticketsNumberById: request.get('ticketsNumberById'),
      numberOfMen: request.get('numberOfMen'),
      numberOfWomen: request.get('numberOfWomen'),
      coupon: request.get('coupon'),
      currencySymbol: request.get('currencySymbol'),
      promoter: request.get('promoter'),
      isRefunded: request.get('isRefunded'),
      isOndoor: request.get('isOndoor'),
      paymentMethod: request.get('paymentMethod'),
      approvedAt: request.get('approvedAt'),
    };
  } catch (error) {
    console.log(error);
    return null;
  }
}

export function serializeRequests(
  requests: Parse.Object<Parse.Attributes>[],
): Request[] {
  return requests.map(serializeRequest).filter((value) => value) as Request[];
}

export function serializePlanner(
  planner: Parse.Object<Parse.Attributes>,
): Planner {
  return {
    id: planner.id,
    name: planner.get('name'),
    email: planner.get('email'),
    smsBalance: planner.get('smsBalance'),
  };
}

export function serializeMinimalTicketObject(
  ticket: Parse.Object<Parse.Attributes>,
): MinimalTicketObject {
  return {
    id: ticket.id,
    createdAt: (ticket.get('createdAt') as Date | undefined)?.toString(),
    eventId: ticket.get('event')?.id,
  };
}

export function serializeMinimalTicketObjects(
  tickets: Parse.Object<Parse.Attributes>[],
): MinimalTicketObject[] {
  return tickets.map(serializeMinimalTicketObject);
}

export function serializeCity(city: Parse.Object<Parse.Attributes>): City {
  return {
    name: city.get('name'),
    id: city.id,
    code: city.get('code'),
    mainColor: city.get('mainColor'),
    secondColor: city.get('secondColor'),
    festivalType: city.get('festivalType'),
    timeZone: city.get('timeZone'),
    imageUrl: city.get('coverPhoto') ? city.get('coverPhoto').url() : null,
    currency: city.get('currency'),
  };
}

export function serializeCities(
  cities: Parse.Object<Parse.Attributes>[],
): City[] {
  return cities.map(serializeCity);
}
