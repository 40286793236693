import {
  CardMedia,
  TextField,
  Grid,
  Box,
  Stack,
  Typography,
  Button,
  SvgIcon,
} from '@mui/material';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Request } from 'src/models/request';
import { Event } from 'src/models/event';
import SearchIcon from '@mui/icons-material/Search';
import { DateTime } from 'luxon';
import { ReactComponent as Tickets } from '../../assets/ticketLogo.svg';
import { ReactComponent as Calendar } from '../../assets/CalendarRequests.svg';
import { ReactComponent as identification } from '../../assets/identification.svg';
import { ReactComponent as PrintKing } from '../../assets/PrintKing.svg';
import { IoFingerPrint } from 'react-icons/all';
import { useHistory } from 'react-router-dom';

const useStyles = createUseStyles({
  button: {
    color: 'black',
    borderRadius: '30px',
    cursor: 'pointer',
    width: '28vw',
    minWidth: '28vw',
    height: '10vw',
    backgroundColor: '#F8F8F7',
    fontSize: '16px',
    justifyContent: 'center',
    textTransform: 'none',
    '&:hover': {
      color: 'Black',
      backgroundColor: '#F8F8F7',
    },
    '&:disabled': {
      opacity: 0.35,
    },
  },
  ButtonText: {
    fontSize: '12px',
    fontWeight: 800,
    fontFamily: 'SF Pro Display',
  },

  titleIcon: {
    width: '83px',
    height: '82px',
    maxWidth: '19px',
    maxHeight: '20px',
    '@media screen and (max-width: 1200px)': {
      width: '50px',
      height: '50px',
      fontSize: '32px',
    },
    '@media screen and (max-width: 900px)': {
      width: '40px',
      height: '40px',
      fontSize: '32px',
    },
  },
  titleIconCalendar: {
    width: '83px',
    height: '82px',
    maxWidth: '16px',
    maxHeight: '16px',
    '@media screen and (max-width: 1200px)': {
      width: '50px',
      height: '50px',
      fontSize: '32px',
    },
    '@media screen and (max-width: 900px)': {
      width: '40px',
      height: '40px',
      fontSize: '32px',
    },
  },
});

interface Props {
  event: Event;
  isRequestsOpen: boolean;
  onClickRequests: () => void;
}
const RequestsTabsMobile: React.VFC<Props> = ({
  event,
  isRequestsOpen,
  onClickRequests,
}) => {
  const classes = useStyles();

  const history = useHistory();

  return (
    <>
      <Stack
        direction={'row'}
        spacing={1}
        alignSelf={'flex-start'}
        justifyContent={'space-between'}
        paddingTop={1}
        paddingBottom={1}
      >
        <Button
          variant="contained"
          className={classes.button}
          onClick={onClickRequests}
        >
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <SvgIcon
              component={Tickets}
              className={classes.titleIcon}
              inheritViewBox
            />
            <Typography className={classes.ButtonText}>Requests</Typography>
          </Stack>
        </Button>
        <Button variant="contained" className={classes.button} disabled={true}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <SvgIcon
              component={identification}
              className={classes.titleIconCalendar}
              inheritViewBox
            />
            <Typography className={classes.ButtonText}>Check In</Typography>
          </Stack>
        </Button>{' '}
        <Button variant="contained" className={classes.button} disabled={true}>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <SvgIcon
              component={Calendar}
              className={classes.titleIconCalendar}
              inheritViewBox
            />
            <Typography className={classes.ButtonText}>Edit</Typography>
          </Stack>
        </Button>
      </Stack>
    </>
  );
};

export default RequestsTabsMobile;
