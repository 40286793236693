import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Parse from 'parse/node';
import { parse } from 'path';
import {
  serializeCities,
  serializeEvent,
  serializeEvents,
} from 'src/helpers/serializeModels';
import { City } from 'src/models/city';
import { Event } from 'src/models/event';

/* Thunks */

interface ProjectState {
  cities: City[];
  events: {
    byId: { [id: string]: Event };
    all: Event[];
  };
  event: Event | null;
  loading: boolean;
  error: any;
}

const initialState: ProjectState = {
  cities: [],
  events: {
    byId: {},
    all: [],
  },
  event: null,
  loading: false,
  error: null,
};

export const getCities = createAsyncThunk('city/getCities', async () => {
  const City = Parse.Object.extend('City');
  const queryCity = new Parse.Query(City);
  queryCity.equalTo('available', true);
  queryCity.ascending('order');
  try {
    const cities = await queryCity.find();
    return serializeCities(cities);
  } catch (error) {
    console.log(error);
  }
  return [] as City[];
});

// get event by id
export const getEventById = createAsyncThunk(
  'event/getEventById',
  async (id: string) => {
    const Event = Parse.Object.extend('Event');
    const queryEvent = new Parse.Query(Event);
    queryEvent.equalTo('objectId', id);
    try {
      const event = await queryEvent.first();
      if (event) {
        return serializeEvent(event);
      }
    } catch (error) {
      console.log(error);
    }
    console.log('event not found');
    return null;
  },
);
export const sendSupportEmail = createAsyncThunk<
  boolean,
  { name: string; email: string; message: string }
>('event/sendSupportEmail', async ({ name, email, message }) => {
  Parse.Cloud.run('sendSupportEmail', {
    name: name,
    email: email,
    message: message,
  }).then(
    function (result) {
      console.log(result);
    },
    function (error) {
      console.log(error);
    },
  );
  return true;
});

export const getEvents = createAsyncThunk('event/getEvents', async () => {
  const Event = Parse.Object.extend('Event');
  const queryEvent = new Parse.Query(Event);
  queryEvent.equalTo('available', true);
  queryEvent.ascending('start');
  try {
    const events = await queryEvent.find();
    return serializeEvents(events);
  } catch (error) {
    console.log(error);
  }
  return [] as Event[];
});

const projectSlice = createSlice({
  name: 'project',
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCities.fulfilled, (state: ProjectState, { payload }) => {
        state.loading = false;
        state.cities = payload;
      })
      .addCase(getCities.pending, (state: ProjectState) => {
        state.loading = true;
      })
      .addCase(getCities.rejected, (state: ProjectState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // add case for get event by id
      .addCase(getEventById.fulfilled, (state: ProjectState, { payload }) => {
        state.loading = false;
        state.event = payload;
      })
      .addCase(getEventById.pending, (state: ProjectState) => {
        state.loading = true;
      })
      .addCase(getEventById.rejected, (state: ProjectState, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(getEvents.fulfilled, (state: ProjectState, { payload }) => {
        state.loading = false;
        state.events = {
          byId: payload.reduce(
            (accumulator, event) => ({
              ...accumulator,
              [event.id]: event,
            }),
            {},
          ),
          all: payload,
        };
      })
      .addCase(getEvents.pending, (state: ProjectState) => {
        state.loading = true;
      })
      .addCase(getEvents.rejected, (state: ProjectState, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
  initialState: initialState,
});

export default projectSlice.reducer;
