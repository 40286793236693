import React from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { createUseStyles } from 'react-jss';
import ThumbLeft from '../../../assets/images/ticketsPart/thumbLeft.png';
import ThumbRight from '../../../assets/images/ticketsPart/thumbRight.png';
import ThumbCenter from '../../../assets/images/ticketsPart/thumbCenter.png';
import CenterVideo from '../../../assets/video/ticketsPart/middle.mov';
import RightVideo from '../../../assets/video/ticketsPart/right.mov';
import LeftVideo from '../../../assets/video/ticketsPart/left.mov';

const useStyles = createUseStyles({
  container: {
    marginTop: '60px',
  },
  videoBox: {
    '& video': {
      width: '100%',
      height: '100%',
      borderRadius: '40px',
      border: 'solid white',
      borderWidth: '12px',
    },
    width: '235px',
    zIndex: '1 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '40px',
    '@media screen and (max-width: 900px)': {
      '& video': {
        width: '100%',
        height: '100%',
        borderRadius: '30px',
        borderWidth: '10px',
      },
      width: '190px',
    },
  },
  centerVideoBox: {
    '& video': {
      width: '100%',
      height: '100%',
      borderRadius: '40px',
      border: 'solid white',
      borderWidth: '12px',
    },
    width: '304px',
    zIndex: '2 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: '40px',
    '@media screen and (max-width: 900px)': {
      '& video': {
        width: '100%',
        height: '100%',
        borderRadius: '30px',
        borderWidth: '12px',
      },
      width: '240px',
    },
  },
});

export const TicketsPartVideos = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 900px)', {
    noSsr: true,
  });
  return (
    <Box className={classes.container}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={isMobile ? '3%' : '-13%'}
      >
        <Box className={classes.videoBox}>
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbLeft}
          >
            <source src={LeftVideo} type="video/mp4" />
          </video>
        </Box>
        <Box className={classes.centerVideoBox}>
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbCenter}
          >
            <source src={CenterVideo} type="video/mp4" />
          </video>
        </Box>
        <Box className={classes.videoBox}>
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbRight}
          >
            <source src={RightVideo} type="video/mp4" />
          </video>
        </Box>
      </Stack>
    </Box>
  );
};
