import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  TextField,
} from '@mui/material';

import { useAppDispatch } from 'src/hooks/store';
import { Event } from 'src/models/event';
import { theme } from 'src/constants/theme';
import { TicketForm } from '../tickets/TicketForm';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { addOnDoorTicket, sendTicketsInvite } from '../../slices/request';
import { Container } from '@mui/system';

const useStyles = createUseStyles({
  nonModalBody: {
    backgroundColor: 'rgba(255,255,255, 1)',
    boxShadow: '0px 0px 23px 0px rgba(0,0,0,0.30)',
    zIndex: 2,
    paddingBottom: theme.spacing(2),
    position: 'relative',
    borderRadius: '11.7px',
    width: 333,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    float: 'left',
  },
  arrowButton: {
    fontSize: 60,
    color: 'white !important',
    fill: 'white !important',
    stroke: 'white !important',
  },
  title: {
    fontSize: '30px',
    fontWeight: 800,
    color: 'black',
    lineHeight: '36px',
  },
  subtitle: {
    fontSize: '13px',
    color: 'black',
    fontWeight: 600,
    lineHeight: '14px',
  },
  endSaleDate: {
    fontSize: '14.5px',
    color: 'black',
    fontWeight: 500,
    opacity: 0.24,
    lineHeight: '16px',
    marginBottom: '16.61px',
  },
  text: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'black',
    lineHeight: '18px',
  },
  mediumText: {
    fontSize: '14.5px',
    fontWeight: 600,
    color: 'black',
    lineHeight: '18px',
  },
  ticketForm: {
    padding: '27.9px 30.9px',
  },
  button: {
    height: '5vh',
    borderRadius: '20px',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '19px',
    width: '17vw',
  },
  backDrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: '100%',
    minHeight: '100vh',
  },

  textField: {
    width: '18em',
    height: '5vh',

    color: 'black',

    '& .MuiOutlinedInput-input': {
      padding: '10.7px 20.7px',
    },
    [`& fieldset`]: {
      border: 'none',
    },
    maxHeight: '5vh',
  },
  inputColor: {
    border: 'solid 2px #F0F0F0',
    borderRadius: 20,
    backgroundColor: '#white',
    [`& fieldset`]: { border: 'none' },
  },
});

interface Props {
  event: Event;
}

export const OndoorTicketsForm: React.VFC<Props> = ({ event }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [ticketId, setTicketId] = React.useState('Ticket Type');
  const [paymentMethod, setPaymentMethod] = React.useState('Payment Method');

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),

    inviteTickets: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required('Ticket is required'),
          quantity: yup.number().required('Quantity is required'),
        }),
      )
      .test(
        'At Least one Ticket',
        'At least one ticket is required',
        (value) => {
          return (
            value?.some((ticket) => ticket.quantity && ticket.quantity > 0) ||
            false
          );
        },
      ),
  });

  const initialValues = {
    inviteTickets:
      event.onDoorTickets?.map((ticket) => ({
        id: ticket.id,
        quantity: 0,
      })) || [],
    name: '',
    email: '',
    paymentMethod: '',
    phoneNumber: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      //TODO: add on submit
      dispatch(
        addOnDoorTicket({
          name: values.name,
          email: values.email,
          paymentMethod: values.paymentMethod,
          phoneNumber: values.phoneNumber,
          inviteTickets: values.inviteTickets.filter(
            (ticket) => ticket.quantity > 0,
          ),
          eventId: event.id,
        }),
      );
      resetForm();
    },
  });
  const handlePaymentMethodChange = (event: SelectChangeEvent) => {
    setPaymentMethod(event.target.value as string);
    formik.setFieldValue('paymentMethod', event.target.value as string);
  };
  const handleChange = (event: SelectChangeEvent) => {
    if (ticketId != 'Ticket Type') {
      formik.setFieldValue(
        'inviteTickets',
        formik.values.inviteTickets.map((ticket) =>
          ticket.id === (ticketId as string)
            ? { ...ticket, quantity: 0 }
            : ticket,
        ),
      );
    }
    formik.setFieldValue(
      'inviteTickets',
      formik.values.inviteTickets.map((ticket) =>
        ticket.id === (event.target.value as string)
          ? { ...ticket, quantity: 1 }
          : ticket,
      ),
    );
    setTicketId(event.target.value as string);
  };

  const buildTicketForm = () => (
    <Select
      style={{ width: '17vw', borderRadius: '20px', height: '5vh' }}
      fullWidth={true}
      id="ondoorTickets"
      value={ticketId}
      onChange={handleChange}
    >
      <MenuItem value="Ticket Type">
        <em>Ticket Type</em>
      </MenuItem>
      {event.onDoorTickets &&
        event.onDoorTickets.map((eventTicket, index) => (
          <MenuItem value={eventTicket.id} key={index}>
            {' '}
            {eventTicket.name}
          </MenuItem>
        ))}
    </Select>
  );

  return event.onDoorTickets ? (
    <Container sx={{ justifySelf: 'center' }}>
      <form onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <Card style={{ width: '60vw', boxShadow: ' 0px 2px 4px #979797' }}>
            <Typography
              variant="h5"
              style={{ paddingLeft: '20px', paddingTop: '20px' }}
            >
              <b>+</b> Door Payment
            </Typography>

            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="left"
              sx={{
                paddingX: 3,
                paddingY: 2,
              }}
            >
              <Stack
                direction="column"
                justifyItems={'space-evenly'}
                spacing="16px"
              >
                <Grid item width={'55vw'}>
                  <Stack direction="row" alignItems="center" spacing="20px">
                    <TextField
                      className={classes.textField}
                      name={'name'}
                      style={{ width: '17vw' }}
                      value={formik.values.name}
                      placeholder="Name"
                      onChange={formik.handleChange}
                      fullWidth
                      required
                      InputProps={{
                        className: classes.inputColor,
                      }}
                    />
                    <TextField
                      className={classes.textField}
                      value={formik.values.email}
                      style={{ width: '17vw' }}
                      name={'email'}
                      placeholder="Email"
                      onChange={formik.handleChange}
                      fullWidth
                      required
                      InputProps={{
                        className: classes.inputColor,
                      }}
                    />
                    <TextField
                      style={{ width: '17vw' }}
                      className={classes.textField}
                      name={'phoneNumber'}
                      value={formik.values.phoneNumber}
                      placeholder="Phone Number (Optional)"
                      onChange={formik.handleChange}
                      fullWidth
                      InputProps={{
                        className: classes.inputColor,
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack direction="row" spacing="20px">
                    <Grid item>{buildTicketForm()}</Grid>
                    <Grid item>
                      <Select
                        style={{
                          width: '17vw',
                          borderRadius: '20px',
                          height: '5vh',
                        }}
                        onChange={handlePaymentMethodChange}
                        value={paymentMethod}
                      >
                        <MenuItem value="Payment Method">
                          <em>Payment Method</em>
                        </MenuItem>
                        <MenuItem value="Cash">Cash</MenuItem>
                        <MenuItem value="Visa">Visa</MenuItem>
                      </Select>
                    </Grid>
                    <Button
                      className={classes.button}
                      type="submit"
                      variant="outlined"
                      sx={{
                        color: `white !important`,
                        backgroundColor: `black !important`,
                        border: `2px solid black`,
                        '&:hover': {
                          border: `2px solid black`,
                        },
                        textTransform: 'none',
                        opacity: 1,
                        borderRadius: '20px',
                      }}
                    >
                      Add Ticket
                    </Button>
                  </Stack>
                </Grid>
              </Stack>
            </Grid>
          </Card>
        </FormikProvider>
      </form>
    </Container>
  ) : (
    <></>
  );
};
