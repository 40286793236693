import React from 'react';
import { createUseStyles } from 'react-jss';
import { IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { EventTicket } from 'src/models/ticket';
import { Box } from '@mui/system';
import { ReactComponent as SoldOut } from '../../assets/icons/soldOut.svg';

const useStyles = createUseStyles({
  earlyBird: {
    marginLeft: '20px',
  },
  subText: {
    fontSize: '14.5px',
    color: 'black',
    fontWeight: 500,
    opacity: 0.24,
    lineHeight: '16px',
    marginBottom: '16.61px',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  text: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'black',
    lineHeight: '18px',
  },
  svgIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
  '@keyframes bounce': {
    '0%': {
      transform: 'translateX(0)',
    },
    '50%': {
      transform: 'translateX(15px)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  disabledButton: {
    '&:active': {
      animation: '$bounce 1s infinite',
    },
  },
});

interface Props {
  ticket: EventTicket;
  cityColor: string;
  onChangeNumberOfTickets: (value: number) => void;
  numberOfTickets: number;
  maximum?: number;
  minimum?: number;
  currency?: string;
  noAmounts?: boolean;
}

export const TicketForm: React.VFC<Props> = ({
  cityColor,
  onChangeNumberOfTickets,
  numberOfTickets,
  minimum,
  maximum,
  ticket,
  currency,
  noAmounts = false,
}) => {
  const classes = useStyles();
  const decrement = () => {
    if (numberOfTickets > 0) {
      onChangeNumberOfTickets(numberOfTickets - 1);
    }
  };
  const increment = () => {
    if (maximum ? numberOfTickets < maximum : true) {
      onChangeNumberOfTickets(numberOfTickets + 1);
    }
  };
  return (
    <Box className={classes.earlyBird} minWidth="100%">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="100%"

      >
        <Stack direction="column" alignItems="start" width="100%">
          <Typography className={classes.text}>{ticket.name}</Typography>
          {!noAmounts && (
            <div>
              <Typography className={classes.text} display="inline">
                {currency}
              </Typography>
              <Typography className={classes.text} display="inline">
                {` ${ticket.price}`}
              </Typography>
              {ticket.fees && (
                <>
                  <Typography
                    className={classes.text}
                    display="inline"
                    style={{ fontSize: '9.9px', fontWeight: 500 }}
                  >
                    {` + ${currency}`}
                  </Typography>
                  <Typography
                    className={classes.text}
                    display="inline"
                    style={{ fontSize: '9px', fontWeight: 500 }}
                  >
                    {` ${ticket.fees}`}
                  </Typography>
                </>
              )}
            </div>
          )}
        </Stack>
        {(Number(ticket.total) - Number(ticket.sold) > 0 &&
          ticket.takingRequest) ||
          noAmounts ? (
          <Stack
            direction="column"
            alignItems="center"
            width="100%"
            justifyContent="flex-end"
          >
            <Stack direction="row" alignItems="center">
              <IconButton
                onClick={
                  !noAmounts &&
                    ((ticket.onReservation && !ticket.takingRequest) ||
                      ticket.available === false ||
                      numberOfTickets === 0 ||
                      (ticket.total ?? 0) - (ticket.sold ?? 0) === 0)
                    ? undefined
                    : decrement
                }
                className={
                  (ticket.onReservation && !ticket.takingRequest) ||
                    ticket.available === false ||
                    numberOfTickets === 0 ||
                    (ticket.total ?? 0) - (ticket.sold ?? 0) === 0
                    ? classes.disabledButton
                    : undefined
                }
              >
                <RemoveCircleIcon
                  sx={{ color: `${cityColor}`, fontSize: '37px' }}
                />
              </IconButton>
              <span style={{ fontSize: '20px', fontWeight: 800 }}>
                {numberOfTickets}
              </span>
              <IconButton
                onClick={
                  !noAmounts &&
                    ((ticket.onReservation && !ticket.takingRequest) ||
                      ticket.available === false ||
                      (ticket.total ?? 0) - (ticket.sold ?? 0) <=
                      numberOfTickets ||
                      numberOfTickets === ticket.max)
                    ? undefined
                    : increment
                }
                className={
                  (ticket.onReservation && !ticket.takingRequest) ||
                    ticket.available === false ||
                    (ticket.total ?? 0) - (ticket.sold ?? 0) <= numberOfTickets ||
                    numberOfTickets === ticket.max
                    ? classes.disabledButton
                    : ''
                }
              >
                <AddCircleIcon
                  sx={{ color: `${cityColor}`, fontSize: '37px' }}
                />
              </IconButton>
            </Stack>
            {numberOfTickets > 0 && numberOfTickets < (minimum ?? 0) && (
              <Typography color="error">Minimum {minimum} tickets</Typography>
            )}
          </Stack>
        ) : (
          <div
            style={{
              paddingRight: '40px',
            }}
          >
            <SvgIcon
              component={SoldOut}
              className={classes.svgIcon}
              style={{
                width: 50.28,
                height: 50.28,
                color: `${cityColor}`,
              }}
              inheritViewBox
            />
          </div>
        )}
      </Stack>
      {!noAmounts && (
        <Typography className={classes.subText}>
          {ticket.description}
        </Typography>
      )}
    </Box>
  );
};
