import React, { useEffect, useMemo, useState } from 'react';
import { Typography, Stack, Box, useMediaQuery, Modal } from '@mui/material';
import PurchasedTicketCard from '../components/tickets/PurchasedTicketCard';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Ticket } from 'src/models/ticket';
import { Event } from 'src/models/event';
import { generateTicketPasses, getPurchasedTickets } from 'src/slices/ticket';
import FullscreenSpin from '../components/FullscreenSpin';
import RequestCard from '../components/tickets/RequestCard';
import { getUserRequests } from 'src/slices/request';
import { Pkpasses } from 'src/components/Pkpasses';
import { Request } from 'src/models/request';
import { createUseStyles } from 'react-jss';

type TicketByEvents = { [eventId: string]: Ticket[] };
type EventByEventId = { [eventId: string]: Event };
const useStyles = createUseStyles({
  container: {
    height: '100%',
    minHeight: '100vh',
    margin: '0 auto',
    marginTop: 79,
    marginBottom: 0,
    width: 867,
    '@media screen and (max-width: 1700px)': {
      margin: '79px 324px 0px 324px',
    },
    '@media screen and (max-width: 1500px)': {
      margin: '0 auto',
      marginTop: '79px !important',
      width: 867,
    },
    '@media screen and (max-width: 1000px)': {
      margin: '0 auto',
      marginTop: '79px !important',
      width: 867,
    },
    '@media screen and (max-width: 900px)': {
      margin: '0 5% 0 5%',
      width: '100%',
    },
  },
  title: {
    fontWeight: 800,
    color: 'black',
    fontSize: 40,
    lineHeight: '48px',
    '@media screen and (max-width: 900px)': {
      fontSize: 20,
    },
  },
});

const PurchasedTickets: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const [isDownloadModalOpen, setIsDownloadModalOpen] =
    useState<boolean>(false);
  const [ticketsInModal, setTicketsInModal] = useState<Ticket[] | undefined>();
  const [eventInModal, setEventInModal] = useState<Event | undefined>();
  const { purchasedTickets, isLoading, generatedPassesTickets } =
    useAppSelector(
      ({
        ticket: {
          purchasedTickets: purchasedTickets,
          isLoading: isLoading,
          generatedPassesTickets,
        },
      }) => ({
        purchasedTickets: purchasedTickets,
        isLoading: isLoading,
        generatedPassesTickets,
      }),
    );
  const { isRequestLoading, userRequests } = useAppSelector(
    ({ request: { isLoading: isLoading, userRequests: userRequests } }) => ({
      isRequestLoading: isLoading,
      userRequests: userRequests,
    }),
  );

  useEffect(() => {
    if (generatedPassesTickets) {
      downloadTicketsPasses(generatedPassesTickets);
    }
  }, [generatedPassesTickets]);

  useEffect(() => {
    dispatch(getPurchasedTickets());
  }, []);

  useEffect(() => {
    dispatch(getUserRequests());
  }, []);

  const getTicketsByEvent = (tickets: Ticket[]) => {
    const ticketsByEvents: TicketByEvents = {};
    tickets.forEach((ticket) => {
      if (ticket?.event && ticket.event.id) {
        if (ticketsByEvents[ticket.event.id]) {
          ticketsByEvents[ticket.event.id].push(ticket);
        } else {
          ticketsByEvents[ticket.event.id] = [ticket];
        }
      }
    });
    return ticketsByEvents;
  };

  const getEventsByEventId = (tickets: Ticket[]) => {
    const eventsByEventId: EventByEventId = {};
    tickets.forEach((ticket) => {
      if (ticket?.event && ticket.event.id) {
        eventsByEventId[ticket.event.id] = ticket.event;
      }
    });
    return eventsByEventId;
  };

  const ticketsByEvents = useMemo(
    () => (purchasedTickets ? getTicketsByEvent(purchasedTickets) : {}),
    [purchasedTickets],
  );

  const eventsByEventId = useMemo(
    () => (purchasedTickets ? getEventsByEventId(purchasedTickets) : {}),
    [purchasedTickets],
  );

  const generatePasses = (ticketIds: string[]) => {
    dispatch(generateTicketPasses(ticketIds));
  };

  const launchPassesDownloading = (tickets: Ticket[]) => {
    if (tickets.some((ticket) => !ticket.pkpass)) {
      generatePasses(tickets.map((ticket) => ticket.id));
    } else {
      downloadTicketsPasses(tickets);
    }
  };

  const downloadTicketsPasses = (tickets: Ticket[]) => {
    setIsDownloadModalOpen(true);
    setTicketsInModal(tickets);
    setEventInModal(
      tickets?.[0]?.event?.id
        ? eventsByEventId[tickets?.[0]?.event?.id]
        : undefined,
    );
  };

  const onCloseModal = () => {
    setIsDownloadModalOpen(false);
    setTicketsInModal(undefined);
    setEventInModal(undefined);
  };

  const requestsAndTickets = (): {
    object: [string, Ticket[]] | Request;
    type: string;
  }[] => [
    ...(userRequests?.map((request) => ({
      type: 'Request',
      object: request,
    })) ?? []),
    ...(Object.entries(ticketsByEvents)?.map((tickets) => ({
      type: 'Ticket',
      object: tickets,
    })) ?? []),
  ];

  const getNewestTicket = (tickets: Ticket[]) => {
    const sortedTickets = tickets.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      if (a.createdAt < b.createdAt) {
        return 1;
      }
      return 0;
    });
    return sortedTickets[0];
  };
  const sortedRequestAndTickets = useMemo(
    () =>
      requestsAndTickets().sort((a, b) => {
        if (
          a.type === 'Request' &&
          (a.object as Request).eventId &&
          (a.object as Request).eventId?.endDate &&
          new Date((a.object as Request).eventId?.endDate) < new Date()
        ) {
          return 1;
        }
        if (
          a.type === 'Ticket' &&
          (a.object as [string, Ticket[]])[0] &&
          eventsByEventId[(a.object as [string, Ticket[]])[0]]?.endDate &&
          new Date(
            eventsByEventId[(a.object as [string, Ticket[]])[0]]?.endDate,
          ) < new Date()
        ) {
          return 1;
        }
        if (
          b.type === 'Request' &&
          (b.object as Request).eventId &&
          (b.object as Request).eventId?.endDate &&
          new Date((b.object as Request).eventId?.endDate) < new Date()
        ) {
          return -1;
        }
        if (
          b.type === 'Ticket' &&
          (b.object as [string, Ticket[]])[0] &&
          eventsByEventId[(b.object as [string, Ticket[]])[0]]?.endDate &&
          new Date(
            eventsByEventId[(b.object as [string, Ticket[]])[0]]?.endDate,
          ) < new Date()
        ) {
          return -1;
        }
        if (a.type === 'Request') {
          console.log("This shouldn't happen", (a.object as Request).eventId);
        } else {
          console.log(
            "This shouldn't happen",
            (a.object as [string, Ticket[]])[0],
          );
        }

        if (a.type === 'Request' && b.type === 'Request') {
          if (
            (a.object as Request).isApproved &&
            !(b.object as Request).isApproved
          ) {
            return -1;
          } else if (
            !(a.object as Request).isApproved &&
            (b.object as Request).isApproved
          ) {
            return 1;
          }
        }
        if (a.type === 'Request' && (a.object as Request).isDeclined) {
          return -1;
        }
        let aCreatedAt = new Date();
        let bCreatedAt = new Date();
        if (a.type === 'Ticket' && (a.object as [string, Ticket[]])[1]) {
          const newestTicket = getNewestTicket(
            (a.object as [string, Ticket[]])[1],
          );
          aCreatedAt = new Date(newestTicket.createdAt);
        } else {
          const request = a.object as Request;
          aCreatedAt = request.createdAt
            ? new Date(request.createdAt)
            : new Date();
        }
        if (b.type === 'Ticket' && (b.object as [string, Ticket[]])[1]) {
          const newestTicket = getNewestTicket(
            (b.object as [string, Ticket[]])[1],
          );
          bCreatedAt = new Date(newestTicket.createdAt);
        } else {
          const request = b.object as Request;
          bCreatedAt = request.createdAt
            ? new Date(request.createdAt)
            : new Date();
        }
        return bCreatedAt.getTime() - aCreatedAt.getTime();
      }),
    [purchasedTickets, userRequests, eventsByEventId],
  );

  if (isLoading || isRequestLoading) {
    return <FullscreenSpin />;
  } else {
    return (
      <Box className={classes.container} paddingBottom="24px">
        <Stack direction="column" spacing={isDesktop ? 4 : 5} width="100%">
          {(!purchasedTickets?.length || purchasedTickets.length === 0) &&
            (!userRequests?.length || userRequests?.length === 0) && (
              <Stack
                direction="column"
                spacing={5}
                alignItems="center"
                width="100%"
                height="100vh"
              >
                <Typography className={classes.title}>
                  Purchased Tickets
                </Typography>
                <Typography variant="h5">
                  You have no tickets purchased yet.
                </Typography>
              </Stack>
            )}
          {((purchasedTickets && purchasedTickets.length > 0) ||
            (userRequests && userRequests?.length > 0)) && (
            <>
              <Box fontSize={{ xs: '30px', md: '45px' }} fontWeight={800}>
                Purchased Tickets
              </Box>
              {sortedRequestAndTickets.map((requestOrTicket, index) => {
                if (requestOrTicket.type === 'Request') {
                  const request = requestOrTicket.object as Request;
                  return (
                    Boolean(request.eventId && !request.paid) && (
                      <RequestCard
                        key={index}
                        request={request}
                        event={request.eventId}
                      />
                    )
                  );
                } else {
                  const [eventId, tickets] = requestOrTicket.object as [
                    string,
                    Ticket[],
                  ];
                  return (
                    eventsByEventId?.[eventId] && (
                      <PurchasedTicketCard
                        key={index}
                        numberOfTickets={tickets.length}
                        isEventOver={
                          // TODO: Should it be over only if it's yesterday ?
                          eventsByEventId?.[eventId]?.endDate
                            ? new Date(eventsByEventId?.[eventId]?.endDate) <
                              new Date()
                            : true
                        }
                        isApprovalPending={false}
                        event={eventsByEventId[eventId]}
                        onClickDownloadPasses={() =>
                          launchPassesDownloading(tickets)
                        }
                      />
                    )
                  );
                }
              })}
            </>
          )}
        </Stack>
        {ticketsInModal && eventInModal && (
          <Modal
            open={isDownloadModalOpen}
            onClose={onCloseModal}
            sx={{ overflow: 'scroll', bgcolor: 'white' }}
          >
            <Pkpasses
              tickets={ticketsInModal}
              event={eventInModal}
              onClose={onCloseModal}
            />
          </Modal>
        )}
      </Box>
    );
  }
};

export default PurchasedTickets;
