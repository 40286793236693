import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { renderToString } from 'react-dom/server';
import { useParams } from 'react-router-dom';
import {
  approveRequest,
  createMarketingRequest,
  declineRequest,
  fetchEventRequests,
  fetchEventTickets,
  resendRequestEmail,
  resetMarketingRequest,
  resetSentEmail,
} from 'src/slices/request';
import { getEventById, stopTakingRequests } from 'src/slices/event';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import {
  Alert,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import ReactToPrint from 'react-to-print';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Bar } from 'react-chartjs-2';
import SalesBarChart from 'src/components/salesReportGraphs/SalesBarGraph';
import {
  getNetProfit,
  getTicketsSales,
  getTotalSales,
} from 'src/slices/ticket';
import { getAllPromoCodes } from 'src/slices/promoCode';
import { Container } from '@mui/system';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Ticks } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { YAxis } from 'recharts';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { ImPointUp } from 'react-icons/im';
import Download from '@mui/icons-material/Download';
import { CgCardDiamonds } from 'react-icons/cg';
import { getRecipientsEmails } from 'src/slices/planner';
ChartJS.register(ArcElement, Tooltip, Legend);
const useStyles = createUseStyles({
  main: {
    width: '100%',

    backgroundColor: 'white',

    '@media print': {
      marginTop: 0,
      scale: 0.5,
      backgroundColor: 'white',
    },
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',

    justifyContent: 'space-between',
    justifyItems: 'space-between',
    alignItems: 'space-between',
    padding: '0 170px 70px',
    '@media (max-width: 1000px)': {
      padding: '0 100px 30px',
    },
  },
  componentRef: {},
  grid: {
    margin: '0',
    padding: 'auto',
    backgroundColor: 'white',
    width: '100%',
  },
  subheaderButton: {
    '&:hover': {
      opacity: 0.7,
      transition: '0.5s',
    },
    backgroundColor: '#48C9B0',
    color: 'black',
    border: 'solid 1px #FFFFFF ',
    borderRadius: 50,
    height: 40,
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '8px 30px',
  },
  subheaderButton2: {
    '&:hover': {
      opacity: 0.7,
      transition: '0.5s',
      backgroundColor: '#FE5A6D',
    },
    alignSelf: 'center',
    textTransform: 'none',
    borderRadius: 20,
    width: '7rem',
    height: '3rem',
    marginTop: '30px',
    backgroundColor: '#FE5A6D',
    color: 'white',
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '15rem',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  title: {
    fontSize: '45px',
    fontWeight: 800,
    fontStyle: 'bold',
    color: '#000000',
  },
  subTitle: {
    fontSize: '30px',
    fontWeight: 800,
    fontStyle: 'bold',
  },
  subSubTitle: {
    fontSize: '20px',
    fontWeight: 700,
    fontStyle: 'bold',
  },
  text: {
    fontSize: '17px',
    fontWeight: 600,
  },
  text2: {
    fontSize: '17.5px',
    fontWeight: 800,
  },

  '@page': {
    size: 'portrait',
  },
});
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const MarketingTools: React.VFC = () => {
  //Intializing styles,history, appdispatch and so on...
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentlyDisplayedEvent, requests, sentEmail } = useAppSelector(
    ({
      event: { currentlyDisplayedEvent },
      request: { plannerRequests, sentEmail },
    }) => ({
      currentlyDisplayedEvent: currentlyDisplayedEvent,
      requests: plannerRequests,
      sentEmail,
    }),
  );
  const internationalNumberFormat = new Intl.NumberFormat('en-US');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const eventId = currentlyDisplayedEvent?.id || '';
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));

  const { plannerTickets } = useAppSelector(
    ({ request: { plannerTickets } }) => ({
      plannerTickets,
    }),
  );
  const { promoCodes } = useAppSelector(({ promoCode: { promoCodes } }) => ({
    promoCodes,
  }));
  const { approvedCount, paidCount, totalCount } = useAppSelector(
    ({ planner: { approvedCount, paidCount, totalCount } }) => ({
      approvedCount,
      paidCount,
      totalCount,
    }),
  );

  useEffect(() => {
    dispatch(resetMarketingRequest());
    dispatch(getRecipientsEmails('paid'));
  }, []);

  return (
    <div className={classes.main}>
      <Box marginTop={1}>
        <Grid container className={classes.grid}>
          <div className={classes.main}>
            <div className={classes.subheader}>
              <Typography className={classes.title}></Typography>
            </div>

            <Container
              sx={{
                minWidth: '70%',
                minHeight: '100%',
                margin: 'auto',
                padding: 'auto',
              }}
            >
              <Stack
                sx={{ paddingTop: 1 }}
                direction="row"
                justifyContent="space-between"
              >
                <Stack
                  width={'100%'}
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography className={classes.title}>
                    Marketing Tools
                  </Typography>
                  <Button
                    className={classes.subheaderButton}
                    style={{
                      width: '15rem',
                      height: '3rem',

                      backgroundColor: '#000000',
                      color: 'white',
                    }}
                    onClick={() => {
                      history.push('/active-campaigns');
                    }}
                  >
                    Active Campaigns
                  </Button>
                </Stack>
              </Stack>

              <Stack
                direction="row"
                paddingTop={5}
                spacing={'1%'}
                paddingBottom={0}
                justifyContent="space-between"
              >
                <Card sx={{ width: '50%', height: '25rem' }}>
                  <Container
                    sx={{
                      width: '80%',
                      paddingTop: 4,
                      marginLeft: 5,
                      paddingRight: 5,
                      minHeight: '90%',
                    }}
                  >
                    <Typography className={classes.subTitle}>
                      Email Campaign
                    </Typography>
                    <Typography className={classes.subSubTitle}>
                      Newsletters
                    </Typography>
                    <Typography className={classes.text} sx={{ paddingTop: 2 }}>
                      Inform all people that sent requests to your previous
                      event(s) about your upcoming party.
                    </Typography>
                    <Typography
                      className={classes.text2}
                      sx={{ paddingTop: 6 }}
                    >
                      Your total database:{' '}
                      {totalCount ? totalCount + '  Users ' : 'Loading...'}
                    </Typography>

                    <Stack direction="row" spacing={2} paddingTop={3}>
                      <img
                        src={'https://i.imgur.com/J0Rblpa.png'}
                        width="192px"
                        height={'81px'}
                      ></img>
                    </Stack>
                    <Button
                      className={classes.subheaderButton}
                      onClick={() => {
                        history.push('/checkout/C6MKIQxK2S');
                      }}
                      style={{
                        width: '14rem',
                        height: '4rem',
                        marginTop: '2rem',
                        backgroundColor: '#10BF7A',
                        color: 'white',
                        bottom: 50,
                        right: 50,
                        position: 'absolute',
                      }}
                    >
                      Send Emails/SMS
                    </Button>
                  </Container>
                </Card>
                <Card sx={{ width: '50%', height: '25rem' }}>
                  <Container
                    sx={{
                      width: '80%',
                      paddingTop: 4,
                      marginLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <Typography className={classes.subTitle}>
                      Bi-Weekly Newsletters
                    </Typography>
                    <Typography className={classes.subSubTitle}>
                      To our Techno And Chill Community
                    </Typography>
                    <Typography className={classes.text} sx={{ paddingTop: 2 }}>
                      Inform the Techno And Chill community about your upcoming
                      event through our bi-weekly newsletter. Reach your target
                      audience in just one click.
                    </Typography>
                    <Typography
                      className={classes.text2}
                      sx={{ paddingTop: 3 }}
                    >
                      TAC Community (Egypt): 6987 Users
                    </Typography>

                    <Stack direction="row" spacing={2} paddingTop={3}>
                      <img
                        src={'https://i.imgur.com/SVSqa4j.png'}
                        width="192px"
                        height={'81px'}
                      ></img>
                    </Stack>
                    <Button
                      className={classes.subheaderButton}
                      onClick={() => {
                        history.push('/checkout/Z3yK7wB7c1');
                      }}
                      style={{
                        bottom: 50,
                        right: 50,
                        position: 'absolute',
                        width: '12rem',
                        height: '4rem',
                        marginTop: '2rem',
                        backgroundColor: '#FE5A6D',
                        color: 'white',
                      }}
                    >
                      Sign Up
                    </Button>
                  </Container>
                </Card>
              </Stack>

              <Stack
                direction="row"
                paddingTop={5}
                spacing={'1%'}
                paddingBottom={0}
                justifyContent="space-between"
              >
                <Card sx={{ width: '50%', height: '25rem' }}>
                  <Container
                    sx={{
                      width: '95%',
                      paddingTop: 4,
                      marginLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <Typography className={classes.subTitle}>
                      Blog Post
                    </Typography>
                    <Typography className={classes.subSubTitle}>
                      www.technoandchillmag.com
                    </Typography>
                    <Typography className={classes.text} sx={{ paddingTop: 2 }}>
                      Have one of our editors write an article about your brand,
                      event or festival.It will be published on our blog and
                      promoted with a Story accross our Instagram page.
                    </Typography>
                    <Typography
                      className={classes.subSubTitle}
                      sx={{ paddingTop: 1 }}
                    >
                      10,000 EGP
                    </Typography>

                    <Button
                      onClick={() => {
                        history.push('/checkout/fEVw50RR34');
                      }}
                      className={classes.subheaderButton}
                      style={{
                        width: '12rem',
                        height: '4rem',
                        marginTop: '30px',
                        backgroundColor: '#66A6E3',
                        color: 'white',
                        bottom: 50,
                        left: 50,
                        position: 'absolute',
                        display: 'block',
                      }}
                    >
                      Write Article
                    </Button>
                    <img
                      src="https://i.imgur.com/nwQjkfx.png"
                      style={{
                        bottom: 30,
                        right: 50,
                        position: 'absolute',
                      }}
                      width={'250px'}
                      height="150px"
                      alt="blog post"
                    />
                  </Container>
                </Card>
                <Card
                  sx={{ width: '50%', overflow: 'visible', height: '25rem' }}
                >
                  <Container
                    sx={{
                      width: '80%',
                      paddingTop: 4,
                      marginLeft: 2,
                      paddingRight: 5,
                    }}
                  >
                    <Button
                      onClick={() => {
                        history.push('/checkout/1DxC7cBIod');
                      }}
                      className={classes.subheaderButton}
                      style={{
                        width: '12rem',
                        height: '4rem',
                        marginTop: '48px',
                        backgroundColor: '#FFCF5C',
                        color: 'white',
                        bottom: 50,
                        left: 50,
                        position: 'absolute',
                      }}
                    >
                      Buy Now
                    </Button>
                    <Stack direction="row" justifyContent="space-equally">
                      <Container>
                        <Typography className={classes.subTitle}>
                          TAC App
                        </Typography>
                        <Typography className={classes.subSubTitle}>
                          Feed Promotion
                        </Typography>
                        <Typography
                          className={classes.text}
                          sx={{ paddingTop: 2 }}
                        >
                          Have your event show up on the top of our feed for 3
                          days
                        </Typography>
                        <Typography
                          className={classes.subSubTitle}
                          sx={{ paddingTop: 1 }}
                        >
                          7,500 EGP
                        </Typography>
                      </Container>
                      <Container sx={{ position: 'relative' }}>
                        <img
                          src="https://i.imgur.com/rjPlOOW.png"
                          alt="feed promotion"
                          style={{
                            position: 'absolute',
                            paddingRight: 20,
                            minWidth: '320px',
                            zIndex: 99,
                            overflow: 'visible',
                          }}
                        />
                      </Container>
                    </Stack>
                  </Container>
                </Card>
              </Stack>
              <Card sx={{ height: '17.5em', width: '50%', marginTop: 5 }}>
                <Container
                  sx={{
                    width: '80%',
                    paddingTop: 4,
                    marginLeft: 5,
                    paddingRight: 5,
                  }}
                >
                  <Typography className={classes.subTitle}>
                    Ads thru our Facebook Pixel
                  </Typography>
                  <Typography className={classes.subSubTitle}>
                    Sponsored Ads
                  </Typography>
                  <Typography className={classes.text} sx={{ paddingTop: 2 }}>
                    After using our Facebook pixel for over 7 years, we’ve
                    managed to build audiences in all different countries all
                    interested in electronic music which makes your ads reach
                    the right audience with the lowest prices.
                  </Typography>
                </Container>
              </Card>
              <Stack paddingTop={5}>
                <Card
                  sx={{ overflow: 'visible', width: '100%', height: '25rem' }}
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Container
                      sx={{
                        width: '70%',
                        paddingTop: 4,
                        marginLeft: 5,
                        paddingRight: 5,
                      }}
                    >
                      <Typography className={classes.subTitle}>
                        Instagram Campaign
                      </Typography>
                      <Typography className={classes.subSubTitle}>
                        www.instagram.com/technoandchill
                      </Typography>
                      <Typography
                        className={classes.text}
                        sx={{ paddingTop: 2 }}
                      >
                        We offer many different packages to promote your event
                        accross our Instagram page. You can also reach out to us
                        in case you want a custom package for your event.
                      </Typography>
                      <Stack direction="row" paddingTop={2}>
                        <Card
                          sx={{
                            width: '9.5rem',
                            height: '9.5rem',
                            marginRight: 2,
                          }}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              className={classes.text}
                              sx={{
                                paddingTop: 3,
                                color: 'black',
                                textAlign: 'center',
                                paddingBottom: 1,
                                fontSize: 2,
                                fontWeight: 'normal',
                              }}
                            >
                              1 Story
                            </Typography>
                            <Button
                              className={classes.subheaderButton2}
                              onClick={() => {
                                history.push('/checkout/AGJg2wvYAe');
                              }}
                            >
                              1,500{' '}
                              <span
                                style={{
                                  fontSize: 10,
                                  paddingLeft: 2,
                                  paddingTop: 2,
                                }}
                              >
                                {' '}
                                EGP
                              </span>
                            </Button>
                          </Stack>
                        </Card>

                        <Card
                          sx={{
                            width: '9.5rem',
                            height: '9.5rem',
                            marginRight: 2,
                          }}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              className={classes.text}
                              sx={{
                                paddingTop: 3,
                                color: 'black',
                                textAlign: 'center',
                                paddingBottom: 1,
                                fontSize: 2,
                                fontWeight: 'normal',
                              }}
                            >
                              3 Stories
                            </Typography>
                            <Button
                              className={classes.subheaderButton2}
                              onClick={() => {
                                history.push('/checkout/jOkHGqBOMB');
                              }}
                            >
                              3,500 <br></br>{' '}
                              <span
                                style={{
                                  fontSize: 10,
                                  paddingLeft: 2,
                                  paddingTop: 2,
                                }}
                              >
                                {' '}
                                EGP
                              </span>
                            </Button>
                          </Stack>
                        </Card>
                        <Card
                          sx={{
                            width: '9.5rem',
                            height: '9.5rem',
                            marginRight: 2,
                          }}
                        >
                          <Stack
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              className={classes.text}
                              sx={{
                                paddingTop: 3,
                                color: 'black',
                                paddingBottom: 1,
                                textAlign: 'center',
                                fontSize: 2,
                                fontWeight: 'normal',
                              }}
                            >
                              1 Post/Reel
                            </Typography>
                            <Button
                              className={classes.subheaderButton2}
                              onClick={() => {
                                history.push('/checkout/EIfi7zNjpm');
                              }}
                            >
                              15,000{' '}
                              <span
                                style={{
                                  fontSize: 10,
                                  paddingLeft: 2,
                                  paddingTop: 0,
                                }}
                              >
                                {' '}
                                EGP
                              </span>
                            </Button>
                          </Stack>
                        </Card>
                        <Card
                          sx={{
                            width: '9.5rem',
                            height: '9.5rem',
                            marginRight: 2,
                          }}
                        >
                          <Stack
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              className={classes.text}
                              sx={{
                                paddingTop: 2,
                                height: '2rem',
                                paddingBottom: 5,
                                color: 'black',
                                textAlign: 'center',
                              }}
                            >
                              3 Stories + 1 Post/Reel
                            </Typography>
                            <Button
                              className={classes.subheaderButton2}
                              onClick={() => {
                                history.push('/checkout/OJWAHX6lxg');
                              }}
                            >
                              17,000{' '}
                              <span
                                style={{
                                  fontSize: 10,
                                  paddingLeft: 2,
                                  paddingTop: 2,
                                }}
                              >
                                {' '}
                                EGP
                              </span>
                            </Button>
                          </Stack>
                        </Card>
                        <Card
                          sx={{
                            width: '9.5rem',
                            height: '9.5rem',
                            marginRight: 2,
                          }}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            marginBottom="2px"
                            alignItems="center"
                          >
                            <Typography
                              className={classes.text}
                              sx={{
                                height: '2.5rem',
                                paddingTop: 2,
                                paddingBottom: 5,
                                color: 'black',
                                textAlign: 'center',
                              }}
                            >
                              Custom <br></br>Package
                            </Typography>
                            <Button
                              className={classes.subheaderButton2}
                              onClick={() => {
                                window.open(
                                  'mailto:support@technoandchill.com',
                                  '_blank',
                                );
                              }}
                            >
                              Email Us
                            </Button>
                          </Stack>
                        </Card>
                      </Stack>
                    </Container>
                    <Container
                      sx={{
                        width: '30%',
                        paddingTop: 4,
                        marginRight: 5,
                        paddingRight: 5,
                      }}
                    >
                      <img
                        src="https://i.imgur.com/CKo6Ua1.png"
                        alt="logo"
                        style={{
                          width: '400px',
                          height: '150%',
                          position: 'absolute',
                        }}
                      />
                    </Container>
                  </Stack>
                </Card>
              </Stack>
              <Stack
                direction="row"
                paddingTop={5}
                paddingBottom={5}
                spacing={'1%'}
                justifyContent="space-between"
              >
                <Card
                  sx={{ width: '50%', height: '25rem', overflow: 'visible' }}
                >
                  <img
                    src="https://i.imgur.com/bbkTx2I.png"
                    alt="logo"
                    style={{
                      position: 'absolute',
                      left: 40,
                      top: -20,
                      width: '50px',
                      height: '50px',
                    }}
                  />

                  <Container
                    sx={{
                      width: '80%',
                      paddingTop: 3,
                      marginLeft: 5,
                      paddingRight: 5,
                    }}
                  >
                    <Typography className={classes.subTitle}>
                      Full International PR Campaign
                    </Typography>
                    <Typography className={classes.subSubTitle}>
                      Ideal for festivals and multiple day events
                    </Typography>
                    <Typography className={classes.text} sx={{ paddingTop: 2 }}>
                      The cost for delivering a full PR campaign for your
                      festival or brand can vary depending on my factors. Here
                      are some of the items included in our full local, national
                      and international PR coverage.
                    </Typography>
                    <Typography sx={{ paddingTop: 1, fontSize: '12px' }}>
                      • Media Partnerships with industry leaders in the
                      electronic music scene <br />
                      • Interviews (Pitch for promoters, sponsors, organizers
                      and artists) <br />
                      • Securing press trips and inviting media to attend <br />
                      • Online News, Features & Previews <br />
                      • Post event reviews and coverage <br />
                      • Press trips and on-site coverage
                      <br />
                    </Typography>

                    <Button
                      className={classes.subheaderButton}
                      style={{
                        bottom: 30,
                        right: 30,
                        position: 'absolute',
                        height: '3rem',

                        backgroundColor: '#BF9505',
                        color: 'white',
                      }}
                      onClick={() => {
                        window.open(
                          'mailto:support@technoandchill.com',
                          '_blank',
                        );
                      }}
                    >
                      Email Us
                    </Button>
                  </Container>
                </Card>
                <Card
                  sx={{ width: '50%', height: '25rem', overflow: 'visible' }}
                >
                  <img
                    src="https://i.imgur.com/bbkTx2I.png"
                    alt="logo"
                    style={{
                      position: 'absolute',
                      left: 40,
                      top: -20,
                      width: '50px',
                      height: '50px',
                    }}
                  />
                  <Container
                    sx={{
                      width: '90%',
                      paddingTop: 3.5,
                      marginLeft: 5,
                      paddingRight: 10,
                    }}
                  >
                    <Typography className={classes.subTitle}>
                      Tribes’ Instagram Campaign
                    </Typography>
                    <Typography className={classes.subSubTitle}>
                      Posts accross the 10 biggest Techno pages
                    </Typography>

                    <Typography sx={{ paddingTop: 1, fontSize: '15px' }}>
                      More and more festivals are utilising these campaigns as
                      they provide high impact, targeted and data driven
                      results. We would recommend a minimum of 20 posts across
                      the house and techno instagram network here which can be
                      split across line-up announcement and post event coverage.{' '}
                      <br />
                    </Typography>
                    <Stack direction="row" spacing={1} paddingTop={4}>
                      <img
                        src="https://i.imgur.com/HzEXekW.png"
                        alt="logo"
                        width={'300'}
                      />
                      <div style={{ marginLeft: '-50px', marginTop: 70 }}>
                        <Button
                          className={classes.subheaderButton}
                          style={{
                            bottom: 30,
                            right: 30,
                            position: 'absolute',
                            height: '3rem',

                            backgroundColor: '#BF9505',
                            color: 'white',
                            alignSelf: 'flex-end',
                            marginTop: 'auto',
                          }}
                          onClick={() => {
                            window.open(
                              'mailto:support@technoandchill.com',
                              '_blank',
                            );
                          }}
                        >
                          Email Us
                        </Button>
                      </div>
                    </Stack>
                  </Container>
                </Card>
              </Stack>
            </Container>
          </div>
        </Grid>
      </Box>
    </div>
  );
};

export default MarketingTools;
