import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Link,
  useMediaQuery,
  Container,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { paymobCallback } from 'src/slices/request';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { FaTemperatureLow } from 'react-icons/fa';
import GetInTouch from 'src/components/eventPlanner/GetInTouch';
import GetInTouchCustomer from 'src/components/eventPlanner/GetInTouchCustomer';
import dashedDownArrow from "../assets/dashedDownArrows.png";

const useStyles = createUseStyles({
  mainContainer: {
    height: "100%",
    "@media screen and (max-width: 900px)": {},
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    margin: "0 auto",
    padding: "0 20px",
    display: "flex",
    maxWidth: "80%",
  },
  img: {
    objectFit: "cover",
    width: "10%",
    borderRadius: 10,
    [`@media (max-width: 768px)`]: {
      width: "30%",
    },
  },
  mobileContainer: {
    height: "100%",
    width: "95%",

    justifyContent: "left",
    alignItems: "left",
    justifyItems: "left",
    margin: "0 20px ",

    display: "flex",
  },
  headerText: {
    fontWeight: 800,
    fontSize: "34px",
    color: "black",
  },
  headerTextPhone: {
    fontWeight: 800,
    fontSize: "28px",
    whiteSpace: "nowrap",
    color: "black",
  },

  subHeader: {
    fontWeight: 500,
    fontSize: "18px",
    color: "text.primary",
    underline: "none",
    lineHeight: "30px",
  },
  logo: {
    display: "inline-flex",
    marginLeft: "10px",
    marginRight: "10px",

    minWidth: "30px",
    maxHeight: "30px",
  },
  logo2: {
    display: "inline-flex",
    marginLeft: "15px",
    marginRight: "15px",
  },
  backToHomeButton: {
    textTransform: "none",
    backgroundColor: "orange",
    borderRadius: 20,
    width: "100%",
    maxWidth: "500px",
    alignSelf: "center",
  },
});

export const Aboutus: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery('(min-width:600px)');
  return isDesktop ? (
    <div className={classes.mainContainer}>
      <Stack
        alignItems={isDesktop ? "center" : "flex-start"}
        direction="column"
        justifyContent="center"
        spacing={3}
        marginBottom={4}
      >
        <Box m={3}></Box>

        {/* TODO: Confirm with the client if he needs to display the back to home button */}
        <Grid width={"60%"} justifyContent={"center"} alignSelf="center"></Grid>

        <GetInTouchCustomer />
      </Stack>
    </div>
  ) : (
    <div className={classes.mobileContainer}>
      <Stack
        alignItems={"left"}
        direction="column"
        justifyContent={"left"}
        justifySelf={"left"}
        spacing={2}
        marginBottom={4}
      >
        <Container sx={{marginTop:'10%'}}>
          <img className={classes.img} src={dashedDownArrow} alt="-->" />
        </Container>

        <Container style={{ alignSelf: "flex-start", width: "80%" }}>
          <GetInTouchCustomer />
        </Container>
      </Stack>
    </div>
  );
};
