export interface Currency {
  name: 'EURO' | 'DOLLAR' | 'POUND' | 'EGYPTIAN POUND' | 'UAE DIRHAM';
  symbol: '$' | '€' | '£' | 'EGP' | 'AED';
  code: 'EUR' | 'USD' | 'GBP' | 'EGP' | 'AED';
}

export const currencies: { [key: string]: Currency } = {
  EUR: {
    name: 'EURO',
    symbol: '€',
    code: 'EUR',
  },
  USD: {
    name: 'DOLLAR',
    symbol: '$',
    code: 'USD',
  },
  GBP: {
    name: 'POUND',
    symbol: '£',
    code: 'GBP',
  },
  EGP: {
    name: 'EGYPTIAN POUND',
    symbol: 'EGP',
    code: 'EGP',
  },
  AED: {
    name: 'UAE DIRHAM',
    symbol: 'AED',
    code: 'AED',
  },
};
