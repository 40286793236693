import { createUseStyles } from 'react-jss';
import { Event } from 'src/models/event';
import React, { useEffect } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link, useHistory } from 'react-router-dom';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Calendar } from '../../assets/calendar.svg';
import { ReactComponent as Location } from '../../assets/location.svg';
import { ReactComponent as Cd } from '../../assets/cd.svg';
import { getEventDates } from 'src/helpers/event/getEventDates';
import { getPlannerName } from 'src/actions/actions';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import planner, { getPlannerById } from 'src/slices/planner';

interface DesktopEventsEventCardProps {
  event: Event;
  displayGetTickets?: boolean;
  redirectOnClickName?: boolean;
  statusColor?: string;
}

const useStyles = createUseStyles({
  card: {
    display: 'flex',
    width: '177px',
    height: '177px',
    aspectRatio: 1,
    boxShadow: 'none',
    borderRadius: '8px',
    position: 'relative',
    cursor: 'pointer',
  },
  img: {
    borderRadius: 10,
    objectFit: 'cover',
    width: '100%',
  },
  title: {
    fontSize: '30px',
    color: 'black',
    lineHeight: '32px',
    fontWeight: '800',
    textOverflow: 'ellipsis',

    overflow: 'hidden',
    maxHeight: '32px',
    display: '-webkit-box',
  },
  subtitle: {
    fontSize: '13px',
    color: 'black',
    fontWeight: 600,
  },
  fieldTitle: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 600,
    color: 'black',

    maxLines: 1,
  },
  fieldSubtitle: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'black',
    opacity: 0.75,
    textOverflow: 'ellipsis',
    maxLines: 1,
  },
  DesktopEventsEventCard: {
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.01)',
    },
    transition: 'all .2s ease-in-out',
  },
  getTicketsButton: {
    color: 'white',
    borderRadius: '50%',
    width: '21px',
    height: '21px',
    position: 'relative',
    top: '-10px',
    right: '-12px',
  },
  svgIcon: {
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
});

export const DesktopEventsEventCard: React.VFC<DesktopEventsEventCardProps> = ({
  event,
  redirectOnClickName,
  displayGetTickets,
  statusColor,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { dateString, timeString } = getEventDates(event);
  const dispatch = useAppDispatch();

  const handleClickOnEvent = () => {
    history.push(`/events/${event.id}`);
  };
  const buildImageSection = () => (
    <Box className={classes.card}>
      <img src={event?.imageUrl} className={classes.img} />
      {displayGetTickets !== false && (
        <Box
          position="absolute"
          bottom="75%"
          left="65%"
          width="100%"
          paddingX="10%"
        >
          <div
            className={classes.getTicketsButton}
            style={{
              backgroundColor: statusColor
                ? `#${statusColor}`
                : `#${event.cityColor}`,
            }}
          />
        </Box>
      )}
    </Box>
  );

  const buildDescriptionField = (
    title?: string,
    subtitle?: string,
    icon?: () => JSX.Element,
    allRow?: boolean,
  ) => (
    <Grid
      item
      container
      direction="row"
      display="flex"
      xs={allRow ? 12 : 6}
      sx={{ maxWidth: '100px' }}
    >
      <Stack direction="row" alignItems="center">
        {icon && icon()}
        <Stack direction="column">
          <Typography
            className={classes.fieldTitle}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',

              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              textoverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
          <Typography
            className={classes.fieldSubtitle}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );

  const buildDescriptionSection = () => (
    <Box>
      <Stack direction="column" justifyContent="left" marginBottom="28px">
        {redirectOnClickName != false ? (
          <Link
            style={{ textDecoration: 'none', color: 'black' }}
            to={`/events/${event.id}`}
          >
            <Typography
              sx={{
                display: '-webkit-box',
                overflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
              className={classes.title}
            >
              {event.name}{' '}
            </Typography>
          </Link>
        ) : (
          <Typography
            sx={{
              display: '-webkit-box',
              overflow: 'ellipsis',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
            className={classes.title}
          >
            {event.name}{' '}
          </Typography>
        )}
        <Typography className={classes.subtitle}>
          By {event?.organizerName ? event?.organizerName : 'Makanak'}
        </Typography>
      </Stack>
      <Box style={{ width: 550 }}>
        <Grid
          container
          style={{ maxWidth: 550, width: 550 }}
          rowSpacing="11.19px"
          columnSpacing="67px"
        >
          {buildDescriptionField(dateString, timeString, () => (
            <SvgIcon
              component={Calendar}
              className={classes.svgIcon}
              style={{
                width: 21,
                height: 21,
              }}
              inheritViewBox
            />
          ))}
          {event.dj &&
            buildDescriptionField(event.dj, event.music, () => (
              <SvgIcon
                component={Cd}
                className={classes.svgIcon}
                style={{
                  width: 21,
                  height: 21,
                }}
                inheritViewBox
              />
            ))}
          {buildDescriptionField(
            event.addressName,
            event.address,
            () => (
              <SvgIcon
                component={Location}
                className={classes.svgIcon}
                style={{
                  width: 14,
                  height: 21,
                  marginRight: 10,
                  marginLeft: 4,
                }}
                inheritViewBox
              />
            ),
            true,
          )}
          {event.infoRule &&
            event.infoRule2 &&
            buildDescriptionField(event.infoRule, event.infoRule2, () => (
              <CheckCircleOutlineIcon sx={{ mr: 1 }} />
            ))}
        </Grid>
      </Box>
    </Box>
  );
  return (
    <Stack
      direction="row"
      justifyContent="start"
      spacing="27px"
      className={classes.DesktopEventsEventCard}
      onClick={redirectOnClickName !== false ? handleClickOnEvent : undefined}
    >
      {buildImageSection()}
      {buildDescriptionSection()}
    </Stack>
  );
};
