import React from 'react';
import { createUseStyles } from 'react-jss';
import { commonStyles } from 'src/constants/common-styles';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/system';
import { Stack, Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Tickets } from '../../../assets/icons/backwardTickets.svg';
import { TicketsPartVideos } from './TicketsPartVideos';

const useStyles = createUseStyles({
  buttonDiv: {},
  ticketImgGroup: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '& video': { width: '100%' },
    '@media screen and (max-width: 900px)': {
      paddingRight: '10%',
    },
  },
  backgroundContainer: {
    backgroundColor: '#66a7e3',
    width: '100%',
    padding: '50px',
    marginTop: '60px',
    "'@media (max-width: 768px)':": { padding: '0' },
    overflow: 'hidden',
  },
  ticketContainer: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    transition: '0.2s linear',
    '@media screen and (max-width: 900px)': {
      width: '100%',
      margin: '15px auto',
      transition: '0.2s linear',
    },
  },
  textContainer: {
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '20px',
    transition: '0.2s linear',
    '@media screen and (max-width: 900px)': {
      width: '100%',
      margin: '15px auto',
      transition: '0.2s linear',
    },
  },
  button: {
    marginBottom: 67,
    marginTop: 64.8,
    fontSize: 18.9,
    fontWeight: 'bold',
    width: 277.2,
    height: 51.3,
    borderWidth: 0,
    borderColor: '#528EC5',
    backgroundColor: '#528EC5',
    color: 'white',
    '&:hover': {
      backgroundColor: '#447ec9',
      borderColor: '#447ec9',
      borderWidth: 1,
    },
    '@media screen and (max-width: 900px)': {
      marginTop: 64.8,
      margin: '0 auto',
      marginLeft: '-5%',
    },
  },
  title: {
    color: 'white',
    textAlign: 'start',
    fontWeight: 800,
    marginTop: -22,
    fontSize: 60,
  },

  titleIcon: {
    width: '83px',
    height: '82px',
    '@media screen and (max-width: 1200px)': {
      width: '50px',
      height: '50px',
      fontSize: '32px',
    },
    '@media screen and (max-width: 900px)': {
      width: '40px',
      height: '40px',
      fontSize: '32px',
    },
  },
  titleContainer: {
    width: '100%',
    marginTop: 37,
  },
  contained: {
    margin: '0 auto',
    width: 622,
    '@media screen and (max-width: 1700px)': {
      margin: '0px 420px 0px 420px',
    },
    '@media screen and (max-width: 1500px)': {
      margin: '0 auto',
      width: 622,
    },
    '@media screen and (max-width: 1000px)': {
      margin: '0 auto',
      width: 622,
    },
    '@media screen and (max-width: 900px)': {
      margin: '0 5% 0 5%',
      width: '100%',
    },
  },
  text: {
    fontSize: 17,
    fontWeight: 500,
    color: 'white',
  },
  ticketsTextContainer: {
    color: 'black',
    width: 520,
    '@media screen and (max-width: 900px)': {
      width: '90%',
    },
  },
  boldText: {
    color: 'white',
    fontSize: 18,
    fontWeight: 800,
  },
});

const TicketsPart: React.VFC = () => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const history = useHistory();
  const onClickLearnMore = () => {
    history.push('/events');
  };
  return (
    <Box className={classes.backgroundContainer} id="tickets">
      <Stack
        direction="column"
        alignItems={'center'}
        justifyContent="center"
        width="100%"
      >
        <Box className={classes.contained}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing="20px"
          >
            <Box className={classes.textContainer}>
              <Box className={classes.titleContainer}>
                <Stack
                  direction="column"
                  alignItems={'left'}
                  justifyContent="center"
                  width="100%"
                >
                  <SvgIcon
                    component={Tickets}
                    className={classes.titleIcon}
                    inheritViewBox
                  />
                  <Typography className={classes.title}>Tickets.</Typography>
                </Stack>
              </Box>
              <div className={classes.ticketsTextContainer}>
                <Typography className={classes.text} display="inline">
                  {
                    'We will also feature every event by city, giving you access to a '
                  }
                </Typography>
                <Typography className={classes.boldText} display="inline">
                  detailed description
                </Typography>
                <Typography className={classes.text} display="inline">
                  {' of the event, '}
                </Typography>
                <Typography className={classes.boldText} display="inline">
                  pictures
                </Typography>
                <Typography className={classes.text} display="inline">
                  {' from past events as well as the opportunity to '}
                </Typography>
                <Typography className={classes.boldText} display="inline">
                  purchase your ticket
                </Typography>
                <Typography className={classes.text} display="inline">
                  {
                    ' right from the app with a tap on the screen, ready to be used at the door.'
                  }
                </Typography>
              </div>
              <div className={classes.ticketsTextContainer}>
                <Typography className={classes.text} display="inline">
                  {'Promoting your event is totally '}
                </Typography>
                <Typography className={classes.boldText} display="inline">
                  FREE
                </Typography>
                <Typography className={classes.text} display="inline">
                  {' on our App.'}
                </Typography>
              </div>
            </Box>
          </Stack>
        </Box>
        <TicketsPartVideos />
        <div className={classes.buttonDiv}>
          <Button
            className={`${commonClasses.mainCTA} ${classes.button}`}
            onClick={onClickLearnMore}
          >
            Discover Events
          </Button>
        </div>
      </Stack>
    </Box>
  );
};

export default TicketsPart;
