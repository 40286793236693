import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';

const useStyles = createUseStyles({
  mainContainer: {
    margin: theme.spacing(3.5),
    height: '100%',
  },
  headerText: {
    fontWeight: 900,
    fontSize: '34px',
  },
  backToHomeButton: {
    textTransform: 'none',
    backgroundColor: 'orange',
    borderRadius: 20,
    width: '100%',
    maxWidth: '500px',
    alignSelf: 'center',
  },
});

export const PageNotAvailable: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const takeBackToHome = () => {
    history.push('/landing');
  };
  return (
    <div className={classes.mainContainer}>
      <Stack direction="column" justifyContent="center" spacing={5}>
        <p className={classes.headerText}>
          Sorry, this page is
          <br />
          only available on
          <br />
          Desktop!
        </p>
        <p style={{ fontWeight: 500, fontSize: '20px' }}>
          The Mobile version of this page is still in development. Please use a
          computer to acess the Event Planner Dashboard.
        </p>
        {/* TODO: Confirm with the client if he needs to display the back to home button */}
        <Box
          paddingX={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Button
            variant="contained"
            onClick={takeBackToHome}
            className={classes.backToHomeButton}
            size="large"
            fullWidth
          >
            Back To Home
          </Button>
        </Box>
      </Stack>
    </div>
  );
};
