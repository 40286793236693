import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import EventPlannerDasboard from 'src/pages/EventPlannerDashboard';

import { Layout } from 'src/containers/Layout';
import CreateEventPage from 'src/pages/CreateEventPage';
import Profile from 'src/pages/Profile';
import RequestsPage from 'src/pages/RequestsPage';
import UpdateEventPage from 'src/pages/UpdateEventPage';
import { useMediaQuery } from '@mui/material';
import { PageNotAvailable } from 'src/pages/PageNotAvailable';
import EventPlanner from 'src/pages/EventPlanner';
import LiveCheckInPage from 'src/pages/LiveCheckInPage';
import SalesReportPage from 'src/pages/SalesReportPage';
import MarketingTools from 'src/pages/MarketingTools';
import ActiveCampaigns from 'src/pages/ActiveCampaigns';
import CampaignCheckout from 'src/pages/CampaignCheckout';
import { MPaymentProcessing } from 'src/pages/MakretingPProcessing';
import ThanksForPurchase from 'src/components/tickets/ThanksForPurchase';
import ThanksForPurchaseM from 'src/pages/ThanksForPurchaseM';
import SmsCheckout from 'src/pages/SmsCheckout';
import EventPlannerDashboardMobile from 'src/pages/EventPlannerDashboardMobile';
import PlannerRequestsPageMobile from 'src/pages/PlannerRequestsPageMobile';
import PlannerProfile from 'src/pages/PlannerProfile';

export const PlannerSwitch: React.VFC = () => {
  const isDesktop = useMediaQuery('(min-width:767px)');
  return (
    <Layout>
      <Switch>
        <Route
          exact
          path="/"
          component={
            isDesktop ? EventPlannerDasboard : EventPlannerDashboardMobile
          }
        />
        <Route exact path="/sms-checkout" component={SmsCheckout} />
        <Route
          exact
          path="/create-event"
          component={isDesktop ? CreateEventPage : PageNotAvailable}
        />
        <Route
          exact
          path="/update-event/:id"
          component={isDesktop ? UpdateEventPage : PageNotAvailable}
        />
        <Route
          exact
          path="/profile"
          component={isDesktop ? PlannerProfile : PageNotAvailable}
        />
        <Route
          path="/event/:id/requests"
          component={isDesktop ? RequestsPage : PlannerRequestsPageMobile}
        />
        <Route path="/livecheckin/:id" component={LiveCheckInPage} />
        <Route path="/salesreport/:id" component={SalesReportPage} />
        <Route
          path="/marketing-tools"
          component={isDesktop ? MarketingTools : PageNotAvailable}
        />
        <Route path="/active-campaigns" component={ActiveCampaigns} />
        <Route path="/checkout/:id" component={CampaignCheckout} />
        <Route
          exact
          path="/Mpayment-processing/"
          component={MPaymentProcessing}
        />
        <Route path="/mthanks-for-purchase" component={ThanksForPurchaseM} />

        <Redirect to="/" />
      </Switch>
    </Layout>
  );
};
