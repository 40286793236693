import React from 'react';
import { BsHeartFill } from 'react-icons/bs';
import ImgGroup from '../../../assets/instagram-Group.svg';

import styled from 'styled-components';
import { Container } from '../basic/Container';
import { createUseStyles } from 'react-jss';
import { CardMedia, Typography, useMediaQuery } from '@mui/material';
import InstaImg from '../../../assets/instaApp.jpg';
import Icon from '../../Icon/Icon';
import SoundWave from '../../SoundWave';

const Followers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 20px;
  @media screen and (max-width: 500px) {
    margin-top: 80px;
    transition: 0.2s linear;
  }
  @media screen and (min-width: 767px) {
    margin-top: 97.14px;
    margin-left: 80px;
  }
`;

const InstagramImg = styled.img`
  width: 51.57px;
  height: 74.3px;
  transition: 0.2s linear;
  @media screen and (max-width: 500px) {
    width: 50px;
    transition: 0.2s linear;
  }
`;

const FollowerTitleDiv = styled.div``;
const FollowerHeart = styled.div`
  font-size: 55px;
  @media screen and (max-width: 500px) {
    font-size: 40px;
    transition: 0.2s linear;
  }
  @media screen and (max-width: 425px) {
    font-size: 30px;
    transition: 0.2s linear;
  }
`;

const useStyles = createUseStyles({
  followersNumber: {
    fontSize: '88px',
    fontWeight: 'bold',
    transition: '0.2s linear',
    '@media screen and (max-width: 768px)': {
      fontSize: '80px',
      transition: '0.2s linear',
    },
    '@media screen and (max-width: 425px)': {
      fontSize: '64px',
      transition: '0.2s linear',
    },
  },
  followersText: {
    fontSize: '30px',
    fontWeight: 500,
    marginTop: '-15px',
    textAlign: 'center',
    '@media screen and (max-width: 768px)': {
      fontSize: '20px',
      transition: '0.2s linear',
    },
    '@media screen and (max-width: 425px)': {
      fontSize: '18px',
      transition: '0.2s linear',
    },
  },
  instaAnimContainer: {
    position: 'relative',
    '@media only screen and (max-width: 900px)': {
      marginBottom: '32px',
    },
  },
  instaImg: {
    '& img': { borderRadius: '39px' },
    position: 'relative',
    top: '-20px',
    left: '50px',
    transform: 'rotate(20deg)',
    borderRadius: '39px',
    display: 'inline-block',
    transition: 'all 400ms ease-in-out',
    boxShadow:
      'rgba(0, 0, 0, 0.12) 0.3px 0.5px 0.7px 0px, \n  rgba(0, 0, 0, 0.12) 1.5px 2.9px 3.7px -0.4px, \n  rgba(0, 0, 0, 0.12) 2.7px 5.4px 6.8px -0.7px, \n  rgba(0, 0, 0, 0.12) 4.5px 8.9px 11.2px -1.1px, \n  rgba(0, 0, 0, 0.12) 7.1px 14.3px 18px -1.4px, \n  rgba(0, 0, 0, 0.12) 11.2px 22.3px 28.1px -1.8px, \n  rgba(0, 0, 0, 0.12) 17px 33.9px 42.7px -2.1px, \n  rgba(0, 0, 0, 0.12) 25px 50px 62.9px -2.5px',
    backgroundColor: 'black',
    '@media only screen and (max-width: 900px)': {
      marginTop: '50px',
      marginLeft: '-20px',
      width: '150px',
      '-webkit-border-radius': '20px',
      '-khtml-border-radius': '20px',
      '-moz-border-radius': '20px',
      borderRadius: '20px',
      height: 'auto',
    },
    '&:hover': { transform: 'scale3d(1.07, 1.07, 1.07) rotate(20deg)' },
  },
  soundWave: {
    transform: 'rotate(20deg)',
    position: 'absolute',
    top: '200px',
    left: '-70px',
    zIndex: -1,
    '@media only screen and (max-width: 670px)': {
      top: '120px',
      left: '-200px',
    },
  },
  instaImgSquare: {
    width: 78.57,
    height: 98.01,
    transform: 'rotate(20deg)',
    borderRadius: '12px',
    backgroundColor: '#FF5A6D',
    position: 'absolute',
    top: '480px',
    left: '170px',
    zIndex: -1,
    '@media only screen and (max-width: 900px)': {
      left: '80px',
      top: '270px',
      width: '90px',
      height: '100px',
    },
  },
  '@keyframes djSpin': {
    from: {
      transform: 'rotateZ(0deg)',
      msTransform: 'rotateZ(0deg)',
      MozTransform: 'rotateZ(0deg)',
      WebkitTransform: 'rotateZ(0deg)',
      OTransform: 'rotateZ(0deg)',
    },
    to: {
      transform: 'rotateZ(360deg)',
      msTransform: 'rotateZ(360deg)',
      MozTransform: 'rotateZ(360deg)',
      WebkitTransform: 'rotateZ(360deg)',
      OTransform: 'rotateZ(360deg)',
    },
  },
  cd: {
    position: 'absolute',
    top: '20px',
    left: '431.31px',
    width: '172px',
    height: '172px',
    backfaceVisibility: 'hidden',
    perspective: '1000',
    animation: '$djSpin 2200ms linear infinite',
    '@media only screen and (max-width: 900px)': {
      left: '240px',
      width: '100px',
      height: '100px',
    },
  },
});

const FollowerPart = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width: 900px)');
  return (
    <Container>
      <Followers>
        <FollowerTitleDiv>
          <InstagramImg src={ImgGroup} alt="instagram" />
          <Typography className={classes.followersNumber}>1,000,000</Typography>
          <Typography className={classes.followersText}>
            Followers on Instagram in 7 years
          </Typography>
        </FollowerTitleDiv>
        <FollowerHeart>
          <BsHeartFill />
        </FollowerHeart>
      </Followers>
      <div className={classes.instaAnimContainer}>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/technoandchill/"
        >
          <div className={classes.instaImg}>
            <CardMedia
              sx={{
                width: isDesktop ? 264 : 150,
                height: isDesktop ? 545 : 310,
                borderRadius: '39px',
                '@media only screen and (max-width: 900px)': {
                  borderRadius: '20px',
                },
              }}
              image={InstaImg}
            />
          </div>
        </a>

        <div className={classes.instaImgSquare} />
        <div className={classes.cd}>
          <Icon name="cd" size={isDesktop ? 172 : 100} />
        </div>
        <SoundWave
          className={classes.soundWave}
          width={500}
          barWidth={4}
          gutter={4}
          height={90}
        />
      </div>
    </Container>
  );
};

export default FollowerPart;
