import React, { useEffect, useMemo, useState } from 'react';
import {
  Stack,
  Box,
  Typography,
  Button,
  useMediaQuery,
  Modal,
} from '@mui/material';
import { createUseStyles } from 'react-jss';
import { addOpacityToColor } from '../helpers/colors';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { getRequestById, paymobCheckout, payRequest } from '../slices/request';
import { Token } from '@stripe/stripe-js';
import FullscreenSpin from '../components/FullscreenSpin';
import { CheckoutForm } from '../containers/CheckoutForm';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Hint } from '../assets/hint.svg';
import { FormTextField } from '../components/FormTextField';
import { getPromoCode, testPromoCode } from '../slices/promoCode';
import { setCurrentlyDisplayedEvent } from 'src/slices/event';
import FooterBar from 'src/components/FooterBar';
const useHintBoxStyles = createUseStyles({
  container: {
    height: '100%',
    '@media screen and (max-width: 900px)': {},
  },
  title: {
    fontSize: '34px',
    fontWeight: 800,
    lineHeight: '35px',
    color: 'black',
  },
  hint: {
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '27px',
    color: 'black',
  },
  text: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '18px',
    color: 'black',
  },
  boldText: {
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '18px',
    color: 'black',
  },
  hintBox: {
    width: '335px',
    '@media screen and (max-width: 900px)': {
      width: '100%',
    },
    height: '125px',
    background: '#F9F9F9',
    borderRadius: '9px',
    paddingTop: '14px',
    paddingLeft: '15px',
  },
  svgIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  button: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '15px',
    width: '337px',
    height: '50px',
    borderRadius: '30px',
    backgroundColor: '#F7B500',
    '&:hover': {
      backgroundColor: addOpacityToColor('#F7B500', 0.9),
      transform: 'scale(0.99)',
    },
    '@media (max-width: 900px)': {
      margin: '0 auto',
    },
  },
});
const useStyles = createUseStyles({
  container: {
    height: '100%',
    '@media screen and (max-width: 900px)': {},
  },
  title: {
    fontSize: '34px',
    fontWeight: 800,
    lineHeight: '35px',
    color: 'black',
  },
  hint: {
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '27px',
    color: 'black',
  },
  text: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '18px',
    color: 'black',
  },
  boldText: {
    fontSize: '16px',
    fontWeight: 800,
    lineHeight: '18px',
    color: 'black',
  },
  hintBox: {
    width: '335px',
    '@media screen and (max-width: 900px)': {
      width: '100%',
    },
    height: '112px',
    background: '#F9F9F9',
    borderRadius: '9px',
    paddingTop: '14px',
    paddingLeft: '15px',
  },
  svgIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  button: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '15px',
    width: '337px',
    height: '50px',
    borderRadius: '30px',
    backgroundColor: '#F7B500',
    '&:hover': {
      backgroundColor: addOpacityToColor('#F7B500', 0.9),
      transform: 'scale(0.99)',
    },
    '@media (max-width: 900px)': {
      margin: '0 auto',
    },
  },
  backDrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: '100%',
    minHeight: '100vh',
  },
  textField: {
    color: '#5E5E5E',
    '& .MuiOutlinedInput-input': {
      textAlign: 'center',
      padding: '10.7px 20.7px',
      '&::placeholder': {
        color: '#5E5E5E',
      },
    },
    [`& fieldset`]: {
      border: 'none',
    },
  },
  inputColor: {
    color: '#5E5E5E',
    height: '50px',
    border: 'solid 2px #F0F0F0',
    borderRadius: 30,
    backgroundColor: '#F2F2F2',
    [`& fieldset`]: { border: 'none' },
  },
});

const RequestApproved: React.VFC = () => {
  const classes = useStyles();
  const hintBoxClasses = useHintBoxStyles();
  let isPaymobLoading = false;
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const { id } = useParams<{ id: string }>();
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeError, setPromoCodeError] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { currentlyDisplayedRequest, isLoading, isSuccessfullyPaid } =
    useAppSelector(
      ({
        request: { currentlyDisplayedRequest, isLoading, isSuccessfullyPaid },
      }) => ({
        currentlyDisplayedRequest: currentlyDisplayedRequest,
        isLoading: isLoading,
        isSuccessfullyPaid: isSuccessfullyPaid,
      }),
    );

  const [isCheckoutModalOpen, setIsCheckoutModalOpen] = useState(false);
  const [paymobpaymentkey, setPaymobpaymentkey] = useState(
    'ZXlKMGVYQWlPaUpLVjFRaUxDSmhiR2NpT2lKSVV6VXhNaUo5LmV5SmpiR0Z6Y3lJNklrMWxjbU5vWVc1MElpd2libUZ0WlNJNkltbHVhWFJwWVd3aUxDSndjbTltYVd4bFgzQnJJam8yTkRBek9UbDkuQmN3N080ZXVtYWlrVDRvZlIzXzYzR1phQ2s5T3dEazVFcG9lanJMbzhERVFGbU1wTURHbGVCZi1jblExbjZaZVFvOHYzajdkYzlBV3FRdndsdTlNUXc=',
  );
  const [IsPaymobModelOpen, setIsPaymobModalOpen] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const [paymoblink, setPaymoblink] = useState(
    'https://accept.paymob.com/api/acceptance/iframes/704969?payment_token=',
  );

  useEffect(() => {
    dispatch(getRequestById(id));
    setIsCheckoutModalOpen(false);
  }, [id]);
  const [isBuyButtonDisabled, setIsBuyButtonDisabled] = useState(false);
  const totalPrice = useMemo<number>(() => {
    if (!currentlyDisplayedRequest) {
      return 0;
    }

    return currentlyDisplayedRequest.tickets.reduce(
      (accumulator, current) =>
        (current.id && current.aPrice
          ? currentlyDisplayedRequest.ticketsNumberById?.[current.id]
            ? current.aPrice ?? 0
            : 0
          : 0) +
        accumulator +
        (current.price ?? 0) *
          (current.id
            ? currentlyDisplayedRequest.ticketsNumberById?.[current.id] ?? 0
            : 0),

      0,
    );
  }, [currentlyDisplayedRequest]);

  const totalFees = useMemo<number>(() => {
    if (!currentlyDisplayedRequest) {
      return 0;
    }
    return currentlyDisplayedRequest.tickets.reduce(
      (accumulator, current) =>
        (current.id && current.accomdationFees
          ? currentlyDisplayedRequest.ticketsNumberById?.[current.id]
            ? current.accomdationFees ?? 0
            : 0
          : 0) +
        accumulator +
        (current.fees ?? 0) *
          (current.id
            ? currentlyDisplayedRequest.ticketsNumberById?.[current.id] ?? 0
            : 0),

      0,
    );
  }, [currentlyDisplayedRequest]);

  const onClickBuy = async () => {
    setIsBuyButtonDisabled(true);
    if (promoCode && promoCode !== '') {
      setPromoCodeError(false);
      const validate = await testPromoCode(promoCode);
      if (!validate) {
        setPromoCodeError(true);
        return;
      }
      dispatch(getPromoCode(promoCode));
    }

    if (currentlyDisplayedRequest?.updatedAt != undefined) {
      //add 1 day to current date
      if (currentlyDisplayedRequest?.eventId?.ticketsExpireIn != undefined) {
        if (currentlyDisplayedRequest?.eventId?.ticketsExpireIn) {
          const currentDate = new Date();

          const updatedAt = currentlyDisplayedRequest?.updatedAt;
          updatedAt.setDate(
            updatedAt.getDate() +
              currentlyDisplayedRequest?.eventId?.ticketsExpireIn,
          );

          setIsExpired(updatedAt.getTime() < currentDate.getTime());
        }
        if (currentlyDisplayedRequest?.eventId?.available == false) {
          setIsExpired(true);
        }
      }
    }
    if (currentlyDisplayedRequest?.paid !== true && !isExpired) {
      if (
        currentlyDisplayedRequest?.eventId.usePaymob === true &&
        currentlyDisplayedRequest?.eventId?.available == true
      ) {
        isPaymobLoading = true;
        const paymenttoken = await dispatch(
          paymobCheckout({
            requestId: id,
            fromGifts: false,
            fromPoints: false,
            coupon: promoCode !== '' ? promoCode : undefined,
          }),
        );

        setPaymoblink('' + paymenttoken.payload);
        setIsPaymobModalOpen(true);
      } else {
        setIsCheckoutModalOpen(true);
      }
    }
  };

  const onSubmitPayment = async (token: Token) => {
    if (token.id) {
      try {
        dispatch(
          payRequest({
            requestId: id,
            fromGifts: false,
            fromPoints: false,
            stripeToken: token.id,
            coupon: promoCode !== '' ? promoCode : undefined,
          }),
        );
      } catch (e) {
        console.log('haha', e);
      }
    }
  };

  if ((isLoading && !currentlyDisplayedRequest) || isPaymobLoading) {
    return <FullscreenSpin />;
  }
  if (!currentlyDisplayedRequest || isExpired) {
    return (
      <Box maxWidth="500px" marginX="auto" className={hintBoxClasses.container}>
        <Stack
          direction="column"
          spacing={isDesktop ? '36px' : '15px'}
          justifyContent="center"
          alignItems="center"
        >
          <Stack
            direction="column"
            spacing="23px"
            justifyContent="center"
            alignItems="start"
          >
            <Stack
              direction="column"
              spacing="9px"
              alignSelf="center"
              justifyContent="center"
            >
              <Stack direction="column" spacing={0} justifyContent="center">
                <Typography
                  textAlign={'center'}
                  className={hintBoxClasses.title}
                >
                  {isExpired
                    ? 'Your request has expired'
                    : 'This request belongs'}{' '}
                </Typography>
                <Typography
                  textAlign={'center'}
                  className={hintBoxClasses.title}
                >
                  {isExpired ? '' : 'to a different user'}
                </Typography>
              </Stack>
              <Stack
                alignSelf={'center'}
                textAlign={'center'}
                direction="column"
                spacing={0}
                justifyContent="center"
              >
                <Typography className={hintBoxClasses.text}>
                  {isExpired
                    ? 'Some requests expire after a certain amount of time.'
                    : 'The request you’re trying to open belongs to'}{' '}
                </Typography>
                <Typography className={hintBoxClasses.text}>
                  {isExpired
                    ? ' Please send a new reservation and the event planner '
                    : ' a different user. You can either login to the right '}{' '}
                </Typography>
                <Typography className={hintBoxClasses.text}>
                  {isExpired
                    ? 'will approve it shortly. Sorry for the inconvenience.'
                    : 'account or send a new reservation from this one.'}{' '}
                </Typography>
              </Stack>
            </Stack>
            <Box
              maxWidth={isDesktop ? '100%' : '75%'}
              alignSelf="center"
              className={hintBoxClasses.hintBox}
            >
              <Stack direction="column" spacing={0} justifyContent="start">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="start"
                  spacing="6px"
                >
                  <SvgIcon
                    component={Hint}
                    className={hintBoxClasses.svgIcon}
                    inheritViewBox
                  />
                  <Typography className={hintBoxClasses.hint}>Hint</Typography>
                </Stack>
                <Stack direction="column" spacing={0} justifyContent="center">
                  <Box paddingRight={2}>
                    <Typography
                      className={hintBoxClasses.text}
                      display="inline"
                    >
                      {isExpired
                        ? 'Tickets expiry time is written in '
                        : 'You can find out by checking the email '}{' '}
                    </Typography>
                    <Typography
                      className={hintBoxClasses.boldText}
                      display="inline"
                    >
                      {isExpired
                        ? 'the email you receive once your reservation has'
                        : ' where you received this link.'}{' '}
                    </Typography>
                    <Typography
                      className={hintBoxClasses.text}
                      display="inline"
                    >
                      {isExpired ? ' been approved.' : ''}
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <Stack
            direction="column"
            spacing={0}
            justifyContent="center"
            paddingBottom="100px"
          >
            <Button
              className={hintBoxClasses.button}
              variant="contained"
              size="large"
              onClick={() => history.push('/events/')}
            >
              Request New Tickets
            </Button>
          </Stack>
        </Stack>
      </Box>
    );
  }
  return isSuccessfullyPaid ? (
    <Box maxWidth="500px" marginX="auto" className={classes.container}>
      <Stack
        direction="column"
        spacing={isDesktop ? '36px' : '15px'}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          direction="column"
          spacing="23px"
          justifyContent="center"
          alignItems="start"
        >
          <Stack direction="column" spacing="9px" justifyContent="center">
            <Stack direction="column" spacing={0} justifyContent="center">
              <Typography className={classes.title}>Thank you </Typography>
              <Typography className={classes.title}>
                for your purchase!{' '}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={0} justifyContent="center">
              <Typography className={classes.text}>
                Each ticket holder will receive his tickets on his{' '}
              </Typography>
              <Typography className={classes.text}>
                email. Please make sure to check your junk/spam{' '}
              </Typography>
              <Typography className={classes.text}>
                folder in case you can’t find your QR Codes.{' '}
              </Typography>
              <br />
              <Typography className={classes.text}>
                The tickets can also be found
              </Typography>
              <Box>
                <Typography className={classes.text} display="inline">
                  under{' '}
                </Typography>
                <Typography
                  className={classes.boldText}
                  style={{ cursor: 'pointer' }}
                  display="inline"
                  onClick={() => history.push('/purchased-tickets')}
                >
                  Purchased Tickets
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Box className={classes.hintBox}>
            <Stack direction="column" spacing={0} justifyContent="start">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="start"
                spacing="6px"
              >
                <SvgIcon
                  component={Hint}
                  className={classes.svgIcon}
                  inheritViewBox
                />
                <Typography className={classes.hint}>Bonus</Typography>
              </Stack>
              <Stack direction="column" spacing={0} justifyContent="center">
                <Typography className={classes.text}>
                  You just earned some points for buying your{' '}
                </Typography>
                <Typography className={classes.text}>
                  tickets with us! Download our Mobile App to{' '}
                </Typography>
                <Typography className={classes.text}>
                  view how many points you’ve earned!{' '}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Stack
          direction="column"
          spacing={0}
          justifyContent="center"
          paddingBottom="27px"
        >
          <Button
            className={classes.button}
            variant="contained"
            size="large"
            href="https://apps.apple.com/app/techno-and-chill/id1508918558"
          >
            Download The app
          </Button>
        </Stack>
      </Stack>
    </Box>
  ) : (
    <Box maxWidth="500px" marginX="auto" className={classes.container}>
      <Stack
        direction="column"
        spacing={isDesktop ? '36px' : '110px'}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          direction="column"
          spacing="23px"
          justifyContent="center"
          alignItems="start"
        >
          <Stack direction="column" spacing={0} justifyContent="center">
            <Typography className={classes.title}>Your request</Typography>
            <Typography className={classes.title}>
              has been approved!
            </Typography>
          </Stack>
          {currentlyDisplayedRequest?.tickets.map(
            (ticket, index) =>
              ticket.onReservation &&
              !ticket.onInvitation && (
                <Stack
                  key={index}
                  direction="column"
                  spacing={0}
                  justifyContent="center"
                  width="100%"
                >
                  <Typography className={classes.text}>
                    {currentlyDisplayedRequest?.eventId?.isPackages
                      ? 'Festival Passes'
                      : ticket.name}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography className={classes.boldText} display="inline">
                        {`${currentlyDisplayedRequest.eventId.currency} ${ticket.price}`}
                      </Typography>
                      <Typography className={classes.text} display="inline">
                        {` + ${currentlyDisplayedRequest.eventId.currency} ${ticket.fees}`}
                      </Typography>
                    </Box>
                    <Typography className={classes.boldText}>
                      {ticket.id
                        ? currentlyDisplayedRequest.ticketsNumberById[ticket.id]
                        : 0}
                    </Typography>
                  </Stack>
                </Stack>
              ),
          )}
          {currentlyDisplayedRequest?.tickets.map(
            (ticket, index) =>
              ticket.aPrice &&
              ticket.id &&
              ticket.onReservation &&
              !ticket.onInvitation &&
              currentlyDisplayedRequest.ticketsNumberById[ticket.id] > 0 && (
                <Stack
                  key={index}
                  direction="column"
                  spacing={0}
                  justifyContent="center"
                  width="100%"
                >
                  <Typography className={classes.text}>
                    {ticket.name}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography className={classes.boldText} display="inline">
                        {`${currentlyDisplayedRequest.eventId.currency} ${ticket.aPrice}`}
                      </Typography>
                      <Typography className={classes.text} display="inline">
                        {` + ${currentlyDisplayedRequest.eventId.currency} ${ticket.accomdationFees}`}
                      </Typography>
                    </Box>
                    <Typography className={classes.boldText}>1</Typography>
                  </Stack>
                </Stack>
              ),
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography className={classes.boldText}>Total</Typography>

            <Typography className={classes.boldText}>
              {`${currentlyDisplayedRequest?.eventId?.currency} ${
                totalPrice + totalFees
              }`}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          spacing={'12px'}
          justifyContent="center"
          paddingBottom="27px"
        >
          <FormTextField
            className={classes.textField}
            placeholder="Promo Code"
            value={promoCode}
            error={
              promoCodeError
                ? "Promo code doesn't exist or you have reached your limit"
                : undefined
            }
            touched={true}
            onChange={(e) => {
              setPromoCodeError(false);
              setPromoCode(e.target.value);
            }}
            InputProps={{ className: classes.inputColor }}
            fullWidth
            name={'promoCode'}
          />
          <Button
            className={classes.button}
            variant="contained"
            size="large"
            onClick={onClickBuy}
            disabled={isBuyButtonDisabled}
          >
            Buy tickets{' '}
          </Button>
        </Stack>
      </Stack>
      <Modal
        open={isCheckoutModalOpen}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        onClose={() => {
          setIsCheckoutModalOpen(false);
        }}
      >
        <CheckoutForm
          canExit
          cityColor={'FF4771'}
          onSuccess={onSubmitPayment}
          onError={(error) => console.log(error)}
          onClose={() => {
            setIsCheckoutModalOpen(false);
          }}
          amount={totalPrice + totalFees}
          currency={currentlyDisplayedRequest?.currencySymbol ?? '$'}
          isPaymentLoading={isLoading}
          isPaymentSuccessful={isSuccessfullyPaid ?? false}
          isRequest
        />
      </Modal>

      <Modal
        open={IsPaymobModelOpen}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        onClose={() => {
          setIsPaymobModalOpen(false);
        }}
      >
        <iframe
          src={paymoblink}
          style={{ width: '100%', height: '100%', border: 'none' }}
        ></iframe>
      </Modal>
    </Box>
  );
};

export default RequestApproved;
