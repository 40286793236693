export interface Menu {
  name: string;
  key: string;
  url?: string;
  sub?: SubMenu[];
  displayMenu?: boolean;
  subtitle?: string;
  disabled?: boolean;
}

interface SubMenu {
  name: string;
  key: string;
  subtitle: string;
}

export const customerMenu: Menu[] = [
 
  {
    name: 'Profile',
    key: 'profile',
    displayMenu: false,
  },
  {
    name: 'Purchased Tickets',
    key: 'purchased-tickets',
    displayMenu: false,
  },
  {
    name: 'Contact us',
    key: 'about-us',
    sub: [],
    displayMenu: false,
  },
];

export const plannerMenu: Menu[] = [
  {
    name: 'My Events',
    key: '',
    displayMenu: false,
  },
  {
    name: 'Profile',
    key: 'profile',
    displayMenu: false,
  }
];

export const publicMenu = [
 
  {
    name: 'Contact us',
    key: 'about-us',
    sub: [],
    displayMenu: false,
  },
  {
    name: 'Promote your event',
    key: 'eventplanner',
    sub: [],
    displayMenu: false,
  },
];
