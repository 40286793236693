import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Hint } from '../../assets/hint.svg';
import { createUseStyles } from 'react-jss';
import { addOpacityToColor } from '../../helpers/colors';
import { useHistory } from 'react-router-dom';
const useStyles = createUseStyles({
  container: {
    height: '100%',
    '@media screen and (max-width: 900px)': {},
  },
  title: {
    fontSize: '34px',
    fontWeight: 800,
    lineHeight: '35px',
    color: 'black',
  },
  hint: {
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '27px',
    color: 'black',
  },
  text: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '18px',
    color: 'black',
  },
  boldText: {
    fontSize: '16px',
    fontWeight: 800,
    lineHeight: '18px',
    color: 'black',
  },
  hintBox: {
    width: '335px',
    '@media screen and (max-width: 900px)': {
      width: '100%',
    },
    height: '112px',
    background: '#F9F9F9',
    borderRadius: '9px',
    paddingTop: '14px',
    paddingLeft: '15px',
  },
  svgIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  button: {
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '15px',
    width: '337px',
    height: '50px',
    borderRadius: '30px',
    backgroundColor: '#F7B500',
    '&:hover': {
      backgroundColor: addOpacityToColor('#F7B500', 0.9),
      transform: 'scale(0.99)',
    },
    '@media (max-width: 900px)': {
      bottom: '22px',
      position: 'fixed',
      margin: '0 auto',
      left: 0,
      right: 0,
    },
  },
  backDrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: '100%',
    minHeight: '100vh',
  },
});
const ThanksForPurchase: React.VFC = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Box maxWidth="500px" marginX="auto" className={classes.container}>
      <Stack
        direction="column"
        spacing={isDesktop ? '36px' : '0px'}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          direction="column"
          spacing="23px"
          justifyContent="center"
          alignItems="start"
        >
          <Stack direction="column" spacing="9px" justifyContent="center">
            <Stack direction="column" spacing={0} justifyContent="center">
              <Typography className={classes.title}>Thank you </Typography>
              <Typography className={classes.title}>
                for your purchase!{' '}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={0} justifyContent="center">
              <Typography className={classes.text}>
                Each ticket holder will receive his tickets on his{' '}
              </Typography>
              <Typography className={classes.text}>
                email. Please make sure to check your junk/spam{' '}
              </Typography>
              <Typography className={classes.text}>
                folder in case you can’t find your QR Codes.{' '}
              </Typography>
              <br />
              <Typography className={classes.text}>
                The tickets can also be found
              </Typography>
              <Box>
                <Typography className={classes.text} display="inline">
                  under{' '}
                </Typography>
                <Typography
                  className={classes.boldText}
                  style={{ cursor: 'pointer' }}
                  display="inline"
                  onClick={() => history.push('/purchased-tickets')}
                >
                  Purchased Tickets
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Box className={classes.hintBox}>
            <Stack direction="column" spacing={0} justifyContent="start">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="start"
                spacing="6px"
              >
                <SvgIcon
                  component={Hint}
                  className={classes.svgIcon}
                  inheritViewBox
                />
                <Typography className={classes.hint}>Bonus</Typography>
              </Stack>
              <Stack direction="column" spacing={0} justifyContent="center">
                <Typography className={classes.text}>
                  You just earned some points for buying your{' '}
                </Typography>
                <Typography className={classes.text}>
                  tickets with us! Download our Mobile App to{' '}
                </Typography>
                <Typography className={classes.text}>
                  view how many points you’ve earned!{' '}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
        <Stack direction="column" spacing={0} justifyContent="center">
          <Button
            className={classes.button}
            variant="contained"
            size="large"
            href="https://apps.apple.com/app/techno-and-chill/id1508918558"
          >
            Download The app
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ThanksForPurchase;
