// TODO: same code for create ticket(s) and update ticket(s)

import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { FieldArray, FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormValues } from 'src/pages/CreateEventPage';
import { CreateTicket } from './CreateTicket';
import AddIcon from '@mui/icons-material/Add';
import { commonStyles } from 'src/constants/common-styles';
import { createUseStyles } from 'react-jss';
import { CreatePackageTicket } from './CreatePackageTicket';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { getPlannerFees } from 'src/slices/planner';

interface CreateTicketsProps {
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1,
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  values: FormValues;
  formikErrors: FormikErrors<FormValues>;
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  formikTouched: FormikTouched<FormValues>;
}

const useStyles = createUseStyles({
  radioWrapper: {
    marginLeft: '16px !important',
  },
});

export const CreateTickets: React.VFC<CreateTicketsProps> = ({
  values: { tickets, onReservation, usePaymob, ticketsExpireIn },
  onChange,
  formikErrors,
  formikTouched,
  onBlur,
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const [isPackages, setIsPackages] = useState(false);
  const dispatch = useAppDispatch();
  const fees = useAppSelector((state) => state.planner.fees);
  useEffect(() => {
    dispatch(getPlannerFees('planner'));
  }, []);

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3">
            Settings<sup>*</sup>
          </Typography>
          <Stack spacing={1}>
            <Typography variant="h6">
              Request before booking<sup>*</sup>:
            </Typography>
            <RadioGroup
              row
              name="onReservation"
              value={onReservation}
              onChange={onChange}
              className={classes.radioWrapper}
            >
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
              <FormControlLabel value={false} control={<Radio />} label="No" />
            </RadioGroup>
            <Typography variant="h6">
              Requests Expire After:<sup>*</sup>:
            </Typography>
            <RadioGroup
              row
              name="ticketsExpireIn"
              value={ticketsExpireIn}
              onChange={onChange}
              className={classes.radioWrapper}
            >
              <FormControlLabel value={0} control={<Radio />} label="None" />
              <FormControlLabel value={1} control={<Radio />} label="1 Day" />
              <FormControlLabel value={2} control={<Radio />} label="2 Days" />
              <FormControlLabel value={3} control={<Radio />} label="3 Days" />
            </RadioGroup>
            <Typography variant="h6">
              Use Paymob:<sup>*</sup>:
            </Typography>
            <RadioGroup
              row
              onChange={onChange}
              name="usePaymob"
              className={classes.radioWrapper}
              value={usePaymob}
            >
              <FormControlLabel value={false} control={<Radio />} label="No" />
              <FormControlLabel value={true} control={<Radio />} label="Yes" />
            </RadioGroup>
            <Typography variant="h6">
              Tickets Type<sup>*</sup>:
            </Typography>
            <RadioGroup
              row
              name="isPackages"
              onChange={onChange}
              className={classes.radioWrapper}
            >
              <FormControlLabel
                control={<Radio />}
                label="Tickets"
                checked={!isPackages}
                onClick={() => setIsPackages(false)}
              />
              <FormControlLabel
                control={<Radio />}
                label="Packages"
                checked={isPackages}
                onClick={() => setIsPackages(true)}
              />
            </RadioGroup>
          </Stack>
          <Typography variant="h3">
            {isPackages ? 'Packages' : 'Tickets'}
            <sup>*</sup>
          </Typography>
        </Grid>

        <FieldArray
          name="tickets"
          validateOnChange={false}
          render={(fieldArrayProps) => (
            <>
              {isPackages &&
                tickets.map((ticket, index) => (
                  <CreatePackageTicket
                    key={index}
                    pFees={fees ? fees : 8}
                    ticketValues={ticket}
                    onChange={onChange}
                    onClickDelete={() => {
                      fieldArrayProps.remove(index);
                    }}
                    index={index}
                    canDelete={true}
                    formikErrors={formikErrors}
                    onBlur={onBlur}
                    formikTouched={formikTouched}
                  />
                ))}
              {!isPackages &&
                tickets.map((ticket, index) => (
                  <CreateTicket
                    key={index}
                    ticketValues={ticket}
                    usePaymob={usePaymob}
                    Pfees={fees ? fees : 8}
                    onChange={onChange}
                    onClickDelete={() => {
                      fieldArrayProps.remove(index);
                    }}
                    index={index}
                    canDelete={true}
                    formikErrors={formikErrors}
                    onBlur={onBlur}
                    formikTouched={formikTouched}
                  />
                ))}

              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    fieldArrayProps.insert(tickets.length, {
                      name: '',
                      description: '',
                    });
                  }}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className={commonClasses.mainCTA}
                >
                  Add New Ticket Category
                </Button>
              </Grid>
            </>
          )}
        />
      </Grid>
    </>
  );
};
