import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { ReactComponent as TechnoCalendar } from '../../../assets/icons/features/technoCalendar.svg';
import { ReactComponent as Ticket } from '../../../assets/icons/features/tickets.svg';
import { ReactComponent as Gift } from '../../../assets/icons/features/gift.svg';
import { ReactComponent as Puzzle } from '../../../assets/icons/features/puzzle.svg';
import { ReactComponent as Newsfeed } from '../../../assets/icons/features/newsfeed.svg';
import SvgIcon from '@mui/material/SvgIcon';
import { createUseStyles } from 'react-jss';
import { HiLockClosed } from 'react-icons/all';

const useStyles = createUseStyles({
  featureDiv: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '@media screen and (min-width: 900px)': {
      marginTop: '150px',
    },
  },
  mobileFeatureGroup: {
    display: 'grid',
    position: 'relative',
    gridGap: '20px',
    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  feature: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 14.79,
    height: 191.59,
  },
  icon: {
    width: '116.82px',
    height: '116.82px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  svgIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  featureText: {
    width: 140,
    textAlign: 'center',
    fontSize: '24.75px',
    lineHeight: '30px',
    fontWeight: 700,
    '@media screen and (max-width: 500px)': {
      fontSize: '25px',
      lineHeight: '25px',
      transition: '0.2s linear',
      width: 110,
    },
    '@media screen and (max-width: 900px)': {
      fontSize: '18px',
      lineHeight: '18px',
      transition: '0.2s linear',
      width: 110,
    },
  },
  featuresTitle: {
    color: 'black',
    fontSize: '65px',
    fontWeight: 'bold',
    transition: '0.2s linear',
    paddingBottom: '35.7px',
  },
  container: {
    padding: '0 0px',
    '@media screen and (max-width: 1700px)': {
      padding: '0 50p',
    },
    '@media screen and (max-width: 1300px)': {
      padding: '0 20px',
    },
    '@media screen and (max-width: 900px)': {
      padding: '50px 50px 0px',
    },
    '@media screen and (max-width: 500px)': {
      padding: '30px 30px 0px',
      marginTop: '80px',
    },
  },
  featureBox: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    aspectRatio: '1.05',
    padding: 40,
    maxWidth: '280px',
    maxHeight: '270px',
    width: '100%',
    '@media screen and (max-width: 1400px)': {
      padding: 25,
    },
    '@media screen and (max-width: 1200px)': {
      padding: 20,
    },
    '@media screen and (max-width: 900px)': {
      padding: 20,
    },
  },
  features: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: '45px',
    '@media screen and (max-width: 1200px)': {
      gridGap: '30px',
    },
    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: '1fr 1fr',
      marginTop: '-15px',
    },
  },
  featureIcon: {
    borderRadius: '100%',
    fontSize: '45px',
    width: '124px',
    height: '124px',
    color: 'white',
    padding: 30,
    '@media screen and (max-width: 900px)': {
      width: '60px',
      height: '60px',
      fontSize: '32px',
      padding: 15,
    },
  },
  puzzleFeatureIcon: {
    fontSize: '45px',
    width: '63px',
    height: '63px',
    '@media screen and (max-width: 900px)': {
      width: '40px',
      height: '40px',
      fontSize: '32px',
    },
  },
  featureTitle: {
    fontSize: '32px',
    color: 'white',
    fontWeight: '800',
    textAlign: 'center',
    '@media screen and (max-width: 900px)': {
      fontSize: '18px',
      lineHeight: '18px',
    },
  },
  featureTitleBox: {
    margin: '0 auto',
    width: 867,
    '@media screen and (max-width: 1700px)': {
      margin: '25px 324px 0px 324px',
    },
    '@media screen and (max-width: 1500px)': {
      margin: '0 auto',
      width: 867,
    },
    '@media screen and (max-width: 900px)': {
      padding: '0 0px',
      margin: '0 auto',
    },
  },
  featuresGroup: {
    marginLeft: '-80px',
    '@media screen and (max-width: 1700px)': {
      marginLeft: '-12%',
    },
    '@media screen and (max-width: 1500px)': {
      marginLeft: '-80px',
    },
    '@media screen and (max-width: 900px)': {
      marginLeft: '0px',
    },
  },
  currentFeatures: {
    width: '787px',
    '@media screen and (max-width: 900px)': {
      width: '100%',
    },
  },
});

const FeaturePart: React.VFC = () => {
  const isBig = useMediaQuery('(min-width: 1510px)', { noSsr: true });
  const isMobile = useMediaQuery('(max-width: 900px)', {
    noSsr: true,
  });
  const classes = useStyles();

  const lockedFeatures = (n = 1) => {
    return isMobile ? null : (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing="80px"
      >
        {[...Array(n)].map((e, index) => (
          <div className={classes.feature} key={index}>
            <div
              className={classes.icon}
              style={{ backgroundColor: '#F6F6F6' }}
            />
            <HiLockClosed
              className={classes.svgIcon}
              style={{
                width: 42,
                height: 42,
                color: '#F6F6F6',
              }}
            />
          </div>
        ))}
      </Stack>
    );
  };

  const displayFeatures = () => (
    <>
      <div className={classes.feature}>
        <div
          className={classes.icon}
          style={{
            backgroundColor: '#FF5A6D',
          }}
        >
          <SvgIcon
            className={classes.svgIcon}
            style={{ width: 43.81, height: 43.75 }}
            component={Newsfeed}
            inheritViewBox
          />
        </div>
        <Typography
          className={classes.featureText}
          style={{ color: '#FF5A6D' }}
        >
          Interactive Newsfeed
        </Typography>
      </div>
      <div className={classes.feature}>
        <div className={classes.icon} style={{ backgroundColor: '#66A7E3' }}>
          <SvgIcon
            className={classes.svgIcon}
            style={{ width: 48.1, height: 48.03 }}
            component={TechnoCalendar}
            inheritViewBox
          />
        </div>
        <Typography
          className={classes.featureText}
          style={{ color: '#66A7E3' }}
        >
          Events Calendar
        </Typography>
      </div>
      <div className={classes.feature}>
        <div className={classes.icon} style={{ backgroundColor: '#FFCF5C' }}>
          <SvgIcon
            className={classes.svgIcon}
            style={{ width: 51.13, height: 51.06 }}
            component={Ticket}
            inheritViewBox
          />
        </div>
        <Typography
          className={classes.featureText}
          style={{ color: '#FFCF5C' }}
        >
          Tickets Platform
        </Typography>
      </div>
      <div className={classes.feature}>
        <div className={classes.icon} style={{ backgroundColor: '#2CB9A2' }}>
          <SvgIcon
            className={classes.svgIcon}
            style={{ width: 49.82, height: 46.32 }}
            component={Gift}
            inheritViewBox
          />
        </div>
        <Typography
          className={classes.featureText}
          style={{ color: '#2CB9A2' }}
        >
          Loyality System
        </Typography>
      </div>
    </>
  );

  return (
    <div className={classes.container}>
      <div className={classes.featureDiv}>
        <Box className={classes.featureTitleBox}>
          <Stack
            direction="column"
            alignItems={'left'}
            justifyContent="center"
            width="100%"
          >
            <SvgIcon
              component={Puzzle}
              className={classes.puzzleFeatureIcon}
              inheritViewBox
            />
            <Typography className={classes.featuresTitle}>Features.</Typography>
          </Stack>
        </Box>
        {isMobile ? (
          <Box className={classes.mobileFeatureGroup}>{displayFeatures()}</Box>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing="80px"
            className={classes.featuresGroup}
          >
            {lockedFeatures(isBig ? 2 : 1)}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
              spacing="80px"
              className={classes.currentFeatures}
            >
              {displayFeatures()}
            </Stack>
            {lockedFeatures(isBig ? 2 : 1)}
          </Stack>
        )}
      </div>
    </div>
  );
};

export default FeaturePart;
