import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { EventTicket as Ticket } from 'src/models/ticket';
import { MdDelete } from 'react-icons/md';
import { commonStyles } from 'src/constants/common-styles';
import { FieldArray, FormikErrors, FormikTouched } from 'formik';
import { FormValues } from 'src/pages/CreateEventPage';
import { FileUploader } from 'react-drag-drop-files';
import AddIcon from '@mui/icons-material/Add';

interface CreateTicketProps {
  ticketValues: Ticket;
  pFees: number;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1,
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  onClickDelete: () => void;
  index: number;
  canDelete: boolean;
  formikErrors: FormikErrors<FormValues>;
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  formikTouched: FormikTouched<FormValues>;
}

const useStyles = createUseStyles({
  buttonWrapper: {
    position: 'relative',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  deleteTicketButton: {
    position: 'absolute',
    right: '1rem',
    bottom: '1rem',
  },
  Input: {
    color: 'black',
    [`& fieldset`]: { border: 'none' },
  },
  whiteInputProps: {
    color: 'black',
    border: '1px solid #F0F0F0',
    borderRadius: 30,
    [`& fieldset`]: { border: 'none' },
  },
});

export const CreatePackageTicket: React.VFC<CreateTicketProps> = ({
  ticketValues: {
    name,
    description,
    price,
    min,
    max,
    total,
    fees,
    onInvitation,
    aPrice,
    accomdationFees,
    accomadationType,
    websiteLink,
    websiteRating,
    distanceFromVenue,
    ticketType,
    ratedBy,
    imagesFiles,
  },
  pFees,
  onChange,
  canDelete,
  onClickDelete,
  index,
  formikErrors,
  formikTouched,
  onBlur,
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  const handleFileChange = (files: File[]) => {
    console.log(files);
  };

  return (
    <Grid item container xs={12} spacing={4}>
      <Grid item xs={2}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Name<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.name`}
            name={`tickets.${index}.name`}
            placeholder="Name"
            value={name}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.name &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.name,
            )}
            helperText={
              formikTouched.tickets?.[index]?.name
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.name
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Quantity<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.total`}
            name={`tickets.${index}.total`}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="e.g. 100"
            type="number"
            value={total}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.total &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.total,
            )}
            helperText={
              formikTouched.tickets?.[index]?.total
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.total
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Sleeps<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.sleeps`}
            name={`tickets.${index}.sleeps`}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="e.g. 2"
            type="number"
            value={total}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.sleeps &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.sleeps,
            )}
            helperText={
              formikTouched.tickets?.[index]?.sleeps
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.sleeps
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack spacing={1}>
          <Typography variant="h6">Min number of Tickets:</Typography>
          <TextField
            id={`tickets.${index}.min`}
            name={`tickets.${index}.min`}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="Min number of Tickets"
            type="number"
            value={min}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.min &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.min,
            )}
            helperText={
              formikTouched.tickets?.[index]?.min
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.min
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack spacing={1}>
          <Typography variant="h6">Max number of Tickets:</Typography>
          <TextField
            id={`tickets.${index}.max`}
            name={`tickets.${index}.max`}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="Max number of Tickets"
            type="number"
            value={max}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.max &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.max,
            )}
            helperText={
              formikTouched.tickets?.[index]?.max
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.max
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Ticket Price<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.price`}
            name={`tickets.${index}.price`}
            placeholder="Price"
            type="number"
            value={price}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.price &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.price,
            )}
            helperText={
              formikTouched.tickets?.[index]?.price
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.price
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Fees<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.fees`}
            name={`tickets.${index}.fees`}
            placeholder="Fees"
            type="number"
            value={fees}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.fees &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.fees,
            )}
            helperText={
              formikTouched.tickets?.[index]?.fees
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.fees
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Accomdation Price<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.aPrice`}
            name={`tickets.${index}.aPrice`}
            placeholder="Accomdation Price"
            type="number"
            value={aPrice}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.aPrice &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.aPrice,
            )}
            helperText={
              formikTouched.tickets?.[index]?.aPrice
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.aPrice
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Accomdation Fees<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.accomdationFees`}
            name={`tickets.${index}.accomdationFees`}
            placeholder="Accomdation Fees"
            type="number"
            value={accomdationFees}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.accomdationFees &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.accomdationFees,
            )}
            helperText={
              formikTouched.tickets?.[index]?.accomdationFees
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.accomdationFees
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={2}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Ticket Type<sup>*</sup>:
          </Typography>
          {
            // make 3 checkboxes for ticket type
          }
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="ticketType"
              name={`tickets.${index}.ticketType`}
              value={ticketType}
              onChange={onChange}
              onBlur={onBlur}
            >
              <div className={classes.radioGroup}>
                <FormControlLabel
                  value="appartment"
                  control={<Radio />}
                  label="Appartment"
                />
                <FormControlLabel
                  value="hotel"
                  control={<Radio />}
                  label="Hotel"
                />
                <FormControlLabel
                  value="villa"
                  control={<Radio />}
                  label="Villa"
                />
              </div>
            </RadioGroup>
          </FormControl>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Link to Website <sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.websiteLink`}
            name={`tickets.${index}.websiteLink`}
            placeholder="Link to Website"
            value={websiteLink}
            onChange={onChange}
            InputProps={{ className: classes.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.websiteLink &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.websiteLink,
            )}
            helperText={
              formikTouched.tickets?.[index]?.websiteLink
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.websiteLink
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Rating<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.websiteRating`}
            name={`tickets.${index}.websiteRating`}
            placeholder="Rating"
            value={websiteRating}
            onChange={onChange}
            InputProps={{ className: classes.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.websiteRating &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.websiteRating,
            )}
            helperText={
              formikTouched.tickets?.[index]?.websiteRating
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.websiteRating
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Rated by<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.ratedBy`}
            name={`tickets.${index}.ratedBy`}
            placeholder="Rated by"
            value={ratedBy}
            onChange={onChange}
            InputProps={{ className: classes.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.ratedBy &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.ratedBy,
            )}
            helperText={
              formikTouched.tickets?.[index]?.ratedBy
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.ratedBy
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Distance From Venue (in Km)<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.distanceFromVenue`}
            name={`tickets.${index}.distanceFromVenue`}
            placeholder="Distance From Venue (in Km)"
            value={distanceFromVenue}
            onChange={onChange}
            InputProps={{ className: classes.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.distanceFromVenue &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.distanceFromVenue,
            )}
            helperText={
              formikTouched.tickets?.[index]?.distanceFromVenue
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.distanceFromVenue
                : ''
            }
          />
        </Stack>
      </Grid>

      <Grid item xs={8}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Description<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.description`}
            name={`tickets.${index}.description`}
            minRows={5}
            placeholder="Description"
            multiline
            value={description}
            onChange={onChange}
            InputProps={{ className: classes.whiteInputProps }}
            className={classes.Input}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.description &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.description,
            )}
            helperText={
              formikTouched.tickets?.[index]?.description
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.description
                : ''
            }
          />
        </Stack>
        <Typography variant="h6">
          Accommodation Images Links: <sup>*</sup>
        </Typography>

        <Grid item xs={8}>
          <Stack spacing={1}>
            <TextField
              id={`tickets.${index}.images`}
              name={`tickets.${index}.images`}
              placeholder="Images"
              multiline
              minRows={5}
              onChange={onChange}
              InputProps={{ className: classes.whiteInputProps }}
              className={classes.Input}
              required
              onBlur={onBlur}
              error={Boolean(
                formikTouched.tickets?.[index]?.images &&
                  (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.images,
              )}
              helperText={
                formikTouched.tickets?.[index]?.images
                  ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                      ?.images
                  : ''
              }
            />
          </Stack>
        </Grid>
      </Grid>
      {canDelete && (
        <Grid item xs={4} className={classes.buttonWrapper}>
          <IconButton
            onClick={onClickDelete}
            className={`${commonClasses.mainCTA} ${classes.deleteTicketButton}`}
          >
            <MdDelete />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
