import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

import { DefaultSwitch } from './routes/DefaultSwitch';
import './locales/i18n';
import ThemeConfig from './constants/theme';
import GlobalStyles from './constants/theme/globalStyles';
import { BaseOptionChartStyle } from './components/old/charts/BaseOptionChart';
import keys from './config/keys';
import Parse from 'parse/node';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterLuxon';
import { QueryParamProvider } from 'use-query-params';

Parse.initialize(keys.APP_ID, keys.JS_KEY);
Parse.serverURL = keys.API_URL;

const App: React.VFC = () => {
  return (
    <ThemeConfig>
      <GlobalStyles />
      <BaseOptionChartStyle />
      <LocalizationProvider dateAdapter={DateAdapter}>
        <I18nextProvider i18n={i18next}>
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              <DefaultSwitch />
            </QueryParamProvider>
          </Router>
        </I18nextProvider>
      </LocalizationProvider>
    </ThemeConfig>
  );
};

export default App;
