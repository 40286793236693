import React from 'react';
import { createUseStyles } from 'react-jss';
import { City } from 'src/models/city';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  city?: City;
  eventsCount: number;
  displayEventsCount?: boolean;
  imgUrl: string;
  displayText: string;
}

const EventsCard: React.VFC<Props> = ({
  city,
  eventsCount,
  displayEventsCount = true,
  imgUrl,
  displayText,
}) => {
  const useStyles = createUseStyles({
    cityCard: {},
    cityImage: {
      borderRadius: '10%',
      width: '260.59px',
      height: '260.59px',
      color: 'black',
      objectFit: 'cover',
      marginBottom: '5.8px',
      alignContent: 'center',

      textAlign: 'center',
      borderColor: '#E7E7E7',
      borderStyle: 'solid',
    },
    cityNameAndEventCount: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      fontSize: '14.58px',
      fontWeight: 600,
      justifyContent: 'center',
      color: `#${city?.mainColor}`,
      '@media screen and (max-width: 900px)': {
        fontSize: '13px',
      },
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
    },
    subtitle: {
      fontSize: '10.935px',
      fontWeight: 500,
      justifyContent: 'center',
      marginTop: '-2px',
      color: '#D3D3D3',
      '@media screen and (max-width: 900px)': {
        fontSize: '10px',
      },
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.cityCard}>
      <Stack direction="column" alignItems="center" width="100%">
        <img className={classes.cityImage} src={imgUrl} />
        <div className={classes.cityNameAndEventCount}>
          <div style={{ width: '160.59px' }} className={classes.title}>
            {' '}
            {displayText}{' '}
          </div>
          {displayEventsCount && (
            <div className={classes.subtitle}>
              {eventsCount} {eventsCount == 1 ? 'event' : 'events'}
            </div>
          )}
        </div>
      </Stack>
    </Box>
  );
};

export default EventsCard;
