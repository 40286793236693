import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Box, Button, Grid, IconButton, Modal, Stack, Typography } from '@mui/material';
import { TicketForm } from './TicketForm';
import {
  MobileTicketGuestSection,
  GuestFormValues,
} from './MobileTicketGuestSection';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { setPurchasedTicketsFormState } from 'src/slices/ticket';
import { Event } from 'src/models/event';
import { CheckoutForm } from 'src/containers/CheckoutForm';
import { Token } from '@stripe/stripe-js';
import { theme } from 'src/constants/theme';
import { SignUpValues } from 'src/containers/EventPageContent';
import { IoIosArrowDropdownCircle } from 'react-icons/all';
import { UserGender } from '../../models/user';
import { useParams } from 'react-router-dom';
import { TableSelector } from './TableSelector';
import { TableSelectorMapLegend } from './TableSelectorMapLegend';
import { TableSelectorMobile } from './TableSelectorMobile';
import { TableSelectorMapLegendMobile } from './TableSelectorMapLegendMobile';
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const useStyles = createUseStyles({
  modalBody: {
    backgroundColor: 'rgba(255,255,255, 1)',
    width: '100vw',
    maxWidth: '500px',
    overflowY: 'scroll',
    zIndex: 2,
    paddingBottom: theme.spacing(2),
    borderRadius: '15px',
    alignSelf: 'center',
    justifySelf: 'center',
    position: 'relative',
  },
  nonModalBody: {
    backgroundColor: 'rgba(255,255,255, 1)',
    boxShadow: '0px 0px 23px 0px rgba(0,0,0,0.30)',
    zIndex: 2,
    paddingBottom: theme.spacing(2),
    position: 'relative',
    borderRadius: '11.7px',
    width: '450px',
    display: 'block',
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    float: 'left',
  },
  arrowButton: {
    fontSize: 60,
    color: 'white !important',
    fill: 'white !important',
    stroke: 'white !important',
  },
  title: {
    fontSize: '30px',
    fontWeight: 800,
    color: 'black',
    lineHeight: '36px',
  },
  subtitle: {
    fontSize: '13px',
    color: 'black',
    fontWeight: 600,
    lineHeight: '14px',
  },
  endSaleDate: {
    fontSize: '14.5px',
    color: 'black',
    fontWeight: 500,
    opacity: 0.24,
    lineHeight: '16px',
    marginBottom: '16.61px',
  },
  text: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'black',
    lineHeight: '18px',
  },
  mediumText: {
    fontSize: '14.5px',
    fontWeight: 600,
    color: 'black',
    lineHeight: '18px',
  },
  ticketForm: {
    padding: '27.9px 30.9px',
  },
  button: {
    width: '296.1px',
    height: '45px',
    borderRadius: '22.5px',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '19px',
  },
  backDrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: '100%',
    minHeight: '100vh',
  },
  noTicketsAvailableText: {
    fontSize: '15px',
    textAlign: 'center',
    fontWeight: 700,
    color: 'black',
    lineHeight: '18px',
    opacity: 0.49,
  }, text2: {
    fontSize: "13.5px",
    fontWeight: "500",
    color: "black",
    lineHeight: "16.11px",
    opacity: 0.24,
    maxWidth: "170px",
  },
});

interface Props {
  open?: boolean;
  isModal?: boolean;
  handleClose?: any;
  cityColor: string;
  event: Event;
  totalTicketsByType: TicketsNumberByType;
  totalNumberOfTickets: number;
  totalPrice: number;
  totalFees: number;
  isPaymentModalOpen?: boolean;
  isRightAmountOfTickets?: boolean;
  onReservation: boolean;
  isBuyTicketsFormOpen?: boolean;
  isPaymentOpen: boolean;
  onRedirectToLogin: () => void;
  setTotalTicketsByType: React.Dispatch<
    React.SetStateAction<TicketsNumberByType>
  >;
  onCloseBuyTicketsForm?: () => void;
  onOpenBuyTicketsForm?: () => void;
  onClosePayment?: () => void;
  onSubmitRequest: (
    guestFormValues: GuestFormValues,
    signUpValues?: SignUpValues,
  ) => void;
  onSubmitBuyTickets: (signUpValues?: SignUpValues) => void;
  onSubmitPayment: (token: Token) => void;
  noTicketsAvailable: boolean;
  setSelectedTicket: any;
  selectedTicket: any;
}

export interface GuestForm {
  name: string;
  email?: string;
  link?: string;
  phone?: string;
  gender?: UserGender;
  isUser?: boolean;
  nationality?: string;
}

export type TicketsNumberByType = { [name: string]: number };

export const TicketsModal: React.VFC<Props> = ({
  open = true,
  isModal = true,
  handleClose,
  cityColor,
  event,
  onReservation,
  totalTicketsByType,
  totalNumberOfTickets,
  totalPrice,
  isPaymentModalOpen = true,
  isRightAmountOfTickets = true,
  onClosePayment,
  onSubmitRequest,
  onSubmitPayment,
  setTotalTicketsByType,
  onSubmitBuyTickets,
  onRedirectToLogin,
  totalFees,
  noTicketsAvailable,
  selectedTicket,
  setSelectedTicket,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isLoading, ticketsSuccessfullyBought, user, error } = useAppSelector(
    ({
      ticket: { isLoading, ticketsSuccessfullyBought, error },
      authentication: { user },
    }) => ({
      isLoading,
      ticketsSuccessfullyBought,
      user,
      error,
    }),
  );
  const { id, password } = useParams<{ id: string; password?: string }>();
  const [isGuestsModalOpen, setIsGuestModalOpen] = useState(false);

  useEffect(() => {
    if (error || ticketsSuccessfullyBought) {
      setIsGuestModalOpen(false);
    }
  }, [error, ticketsSuccessfullyBought]);

  const resetPurchasedTicketsFormState = () => {
    dispatch(setPurchasedTicketsFormState(null));
  };

  function onChangeTicket(ticketId: string, value: number) {
    setTotalTicketsByType({
      ...totalTicketsByType,
      [ticketId]: value,
    });
  }

  //reset total tickets by type when selectedTicket changes



  const onClickContinue = () => {
    if (user && !onReservation) {
      onSubmitBuyTickets();
    } else {
      setIsGuestModalOpen(true);
    }
  };

  const onCloseGuestModal = () => {
    setIsGuestModalOpen(false);
  };

  const EndDate = new Date(event.endDate);
  const EndDateUTC = new Date(EndDate.toUTCString());
  const dateString = EndDateUTC.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
  });


  const buildTicketForm = () => (
    <Box className={classes.ticketForm}>
      <Typography display="inline" className={classes.title}>
        Tickets
      </Typography>
      <Typography display="inline" className={classes.subtitle}>
        {" + Booking fees"}
      </Typography>
      {!noTicketsAvailable ? (
        <>
          <Typography className={classes.endSaleDate}>
            {`Sales end on ${dateString}`}
          </Typography>
          {password == event.scannerCodePassword &&
            (
              event.tickets?.filter(
                (ticket) => ticket.onInvitation && ticket.isHidden
              ) ?? []
            ).map((eventTicket, index) => (
              <TicketForm
                key={index}
                cityColor={cityColor}
                onChangeNumberOfTickets={(value: number) => {
                  if (eventTicket.id) {
                    onChangeTicket(eventTicket.id, value);
                  }
                }}
                numberOfTickets={
                  eventTicket.id ? totalTicketsByType[eventTicket.id] : 0
                }
                minimum={eventTicket.min}
                maximum={eventTicket.max}
                ticket={eventTicket}
                currency={event.currency}
              />
            ))}

          {password != event.scannerCodePassword && ( //ADD IF EVENT USES TABLES AND ADD BACK THE NORMAL TICKET FORM
            <div>
              {isModal ? (
                <Grid container justifyContent={"center"} direction={"column"} minWidth={'90%'} >
                  <TableSelectorMobile
                    totalTicketsByType={totalTicketsByType}
                    setTotalTicketsByType={setTotalTicketsByType}
                    selectedTicket={selectedTicket}
                    setSelectedTicket={setSelectedTicket}
                    event={event}
                  ></TableSelectorMobile>
                  <TableSelectorMapLegendMobile
                    event={event}
                  ></TableSelectorMapLegendMobile>
                </Grid>
              ) : (
                <Grid container>
                  <Grid item md={4}>
                    <TableSelectorMapLegend
                      event={event}
                    ></TableSelectorMapLegend>
                  </Grid>
                  <Grid item md={8}>
                    <TableSelector
                      totalTicketsByType={totalTicketsByType}
                      setTotalTicketsByType={setTotalTicketsByType}
                      selectedTicket={selectedTicket}
                      setSelectedTicket={setSelectedTicket}
                      event={event}
                    ></TableSelector>
                  </Grid>
                </Grid>
              )}
              {(
                event.tickets?.filter(
                  (ticket) => ticket.id == selectedTicket
                ) ?? []
              ).map((eventTicket, index) => (
                <TicketForm
                  key={index}
                  cityColor={cityColor}
                  onChangeNumberOfTickets={(value: number) => {
                    if (eventTicket.id) {
                      onChangeTicket(eventTicket.id, value);
                    }
                  }}
                  numberOfTickets={
                    eventTicket.id ? totalTicketsByType[eventTicket.id] : 0
                  }
                  minimum={eventTicket.min}
                  maximum={eventTicket.max}
                  ticket={eventTicket}
                  currency={event.currency}
                />

              ))}
              {event.tickets?.filter((ticket) => ticket.id == selectedTicket)
                ?.length === 0 && (
                  <Stack
                    direction="row"

                    justifyContent={'center'}
                    alignItems={'center'}
                    marginLeft={'5%'}
                    maxWidth={'90%'}
                  >
                    <Stack direction="column" alignItems="start" width="90%">
                      <Typography className={classes.text}>
                        Please Make Your Selection
                      </Typography>
                      <Typography className={classes.text2} display="inline">
                        Choose your ticket type from the seating plan
                      </Typography>
                    </Stack>
                    <IconButton disabled>
                      <RemoveCircleIcon
                        sx={{
                          color: `#FF0202`,
                          fontSize: "37px",
                          opacity: 0.2,
                        }}
                      />
                    </IconButton>
                    <span
                      style={{
                        fontSize: "20px",
                        fontWeight: 800,
                        opacity: 0.2,
                      }}
                    >
                      0
                    </span>
                    <IconButton disabled>
                      <AddCircleIcon
                        sx={{
                          color: `#FF0202`,
                          fontSize: "37px",
                          opacity: 0.2,
                        }}
                      />
                    </IconButton>
                  </Stack>
                )}
            </div>
          )}


          <Stack
            direction="column"
            spacing="5.6px"
            marginTop="25.5px"
            marginLeft={'5%'}
            maxWidth={'90%'}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography className={classes.mediumText}>Sub total</Typography>
              <Typography className={classes.mediumText}>
                {`${event.currency} `}
                {totalPrice - totalFees}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography className={classes.mediumText}>
                Booking fees
              </Typography>
              <Typography className={classes.mediumText}>
                {`${event.currency} `}
                {totalFees}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography className={classes.text}>Total</Typography>
              <Typography className={classes.text}>
                {`${event.currency} `}
                {totalPrice}
              </Typography>
            </Stack>
          </Stack>
        </>
      ) : (
        <Stack sx={{ marginTop: "56.71px" }}>
          <Typography className={classes.noTicketsAvailableText}>
            Sorry, there are no tickets available
          </Typography>
          <Typography className={classes.noTicketsAvailableText}>
            for this event.
          </Typography>
        </Stack>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "3em",
        }}
      >
        <Button
          onClick={onClickContinue}
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: noTicketsAvailable
              ? `${cityColor} !important`
              : `${cityColor}`,
            textTransform: "none",
            color: noTicketsAvailable ? "white !important" : "white",
            opacity: noTicketsAvailable ? "0.39" : "1",
            borderRadius: "20px",
          }}
          // className={classes.button}
          disabled={isRightAmountOfTickets === false}
        >
          {!(user && onReservation) || noTicketsAvailable
            ? "Buy Tickets"
            : "Continue"}
        </Button>
      </div>
      {
        <MobileTicketGuestSection
          open={isGuestsModalOpen}
          handleClose={() => {
            setIsGuestModalOpen(false);
            handleClose ? handleClose() : onCloseGuestModal;
          }}
          cityColor={cityColor}
          eventName={`${event.name} - ${event.dj}` ?? ""}
          numberOfTickets={totalNumberOfTickets}
          onSubmit={onSubmitRequest}
          onSignUp={onSubmitBuyTickets}
          onReservation={onReservation}
          isLoggedIn={!!user}
          onRedirectToLogin={onRedirectToLogin}
          onClickBack={onCloseGuestModal}
          isPackages={false}
        />
      }
    </Box>
  );

  return isModal ? (
    <>
      {!isPaymentModalOpen ? (
        <Modal
          open={open}
          onClose={() => {
            resetPurchasedTicketsFormState();
            handleClose();
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            overflow: 'auto',
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            justifySelf={'center'}
            spacing="33px"
            style={{ top: '310px', right: "50%", left: "50%", position: 'absolute' }}
          >
            <Stack direction="row" justifyContent="center">
              <IoIosArrowDropdownCircle
                onClick={handleClose}
                className={classes.arrowButton}
              />
            </Stack>
            <div className={isModal ? classes.modalBody : classes.nonModalBody}>
              {buildTicketForm()}
            </div>
          </Stack>
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={() => {
            resetPurchasedTicketsFormState();
            handleClose();
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            overflow: 'auto',
            position: 'absolute',
          }}
        >
          <div
            className={isModal ? classes.modalBody : classes.nonModalBody}
            style={{ backgroundColor: 'transparent' }}
          >
            <CheckoutForm
              canExit
              cityColor={cityColor}
              onSuccess={onSubmitPayment}
              onError={(error) => console.log(error)}
              onClose={() => {
                if (onClosePayment) {
                  onClosePayment();
                }
              }}
              amount={totalPrice}
              currency={event.currencySymbol ?? '$'}
              isPaymentLoading={isLoading}
              isPaymentSuccessful={ticketsSuccessfullyBought ?? false}
            />
          </div>
        </Modal>
      )}
    </>
  ) : (
    <div className={isModal ? classes.modalBody : classes.nonModalBody}>
      {buildTicketForm()}
    </div>
  );
};

export default TicketsModal;
