import {
  GuestForm,
  TicketsNumberByType,
} from 'src/components/tickets/ModalTicket';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { EventTicket } from 'src/models/ticket';
import { Event } from 'src/models/event';
import {
  buyTickets,
  resetPurchasedTickets,
  setPurchasedTicketsFormState,
  setSelectedTableId,
  setSignUpToBuyTicketsState,
} from 'src/slices/ticket';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { createRequest, resetSentRequest } from 'src/slices/request';
import { GuestFormValues } from 'src/components/tickets/MobileTicketGuestSection';
import { Token } from '@stripe/stripe-js';
import { DesktopEventPageContent } from './DesktopEventPageContent';
import { useMediaQuery } from '@mui/material';
import { MobileEventPageContent } from './MobileEventPageContent';
import { getUserLocationCountry, signUp } from 'src/slices/authentication';
import { Box } from '@mui/system';
import { createUseStyles } from 'react-jss';
import { getEventDates } from 'src/helpers/event/getEventDates';
import { request } from 'http';

interface Props {
  event: Event;
}

const useStyles = createUseStyles({
  container: {
    height: '100%',
    minHeight: '100vh',
    margin: '0 auto',
    marginTop: 25,
    marginBottom: 0,
    width: 867,
    '@media screen and (max-width: 1700px)': {
      margin: '25px 324px 0px 324px',
    },
    '@media screen and (max-width: 1500px)': {
      margin: '0 auto',
      marginTop: '25px !important',
      width: 867,
    },
    '@media screen and (max-width: 1000px)': {
      margin: '0 auto',
      marginTop: '25px !important',
      width: 867,
    },
    '@media screen and (max-width: 900px)': {
      margin: '0 5% 0 0',
      width: '100%',
      height: '100%',
    },
  },
});

export interface SignUpValues {
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
  password: string;
  dateOfBirth?: Date;
}

export const EventPageContent: React.VFC<Props> = ({ event }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    ticketsSuccessfullyBought,
    error,
    user,
    purchasedTicketsFormState,
    signUpToBuyTicketState,
    userCountry,
    selectedTableId
  } = useAppSelector(
    ({
      ticket: {
        isLoading,
        ticketsSuccessfullyBought,
        error,
        purchasedTicketsFormState,
        signUpToBuyTicketState,
        selectedTableId
      },
      authentication: { user, userCountry },
    }) => ({
      signUpToBuyTicketState,
      isLoading,
      ticketsSuccessfullyBought,
      error,
      purchasedTicketsFormState,
      user,
      userCountry,
      selectedTableId
    }),
  );
  const { isSent } = useAppSelector(({ request: { isSent } }) => ({ isSent }));
  const isDesktop = useMediaQuery('(min-width: 900px)', { noSsr: true });
  const [totalTicketsByType, setTotalTicketsByType] =
    useState<TicketsNumberByType>({});
  const [isBuyTicketsFormOpen, setIsBuyTicketsFormOpen] =
    useState<boolean>(false);
  const [isPaymentOpen, setIsPaymentOpen] = useState<boolean>(false);

  const { endDate } = getEventDates(event);

  const getInitialTotalTickets = () => {
    return (event.tickets ?? event.packages ?? []).reduce(
      (accumulator: TicketsNumberByType, current: EventTicket) => ({
        ...accumulator,
        ...(current.id ? { [current.id]: 0 } : {}),
      }),
      {},
    );
  };
  const [selectedTicket, setSelectedTicket] = useState<string | null>(null);
  const getTotalPrice = (): number => {
    return (event.tickets ?? event.packages ?? []).reduce(
      (accumulator, current) =>
        accumulator +
        (current.aPrice && current.id && totalTicketsByType[current.id] > 0
          ? current.aPrice
          : 0) + //Accommodation price if any is found
        (current.accomdationFees &&
          current.id &&
          totalTicketsByType[current.id] > 0
          ? current.accomdationFees
          : 0) + //Accommodation fees if any are found

        (current.price ?? 0) *
        (current.id ? ((current.fixedPrice == true && totalTicketsByType[current.id] > 0 ? 1 : totalTicketsByType[current.id])) : 0) +
        (current.fees ?? 0) * (current.id ? ((current.fixedPrice == true && totalTicketsByType[current.id] > 0 ? 1 : totalTicketsByType[current.id])) : 0),
      0,
    );
  };

  useEffect(() => {
    if (event.tickets || event.packages) {
      if (
        purchasedTicketsFormState &&
        event.id === purchasedTicketsFormState.eventId
      ) {
        setTotalTicketsByType(purchasedTicketsFormState.tickets);
        setSelectedTicket(selectedTableId ? selectedTableId : purchasedTicketsFormState.tickets[0]['name'])
      } else if (
        purchasedTicketsFormState &&
        event.id !== purchasedTicketsFormState.eventId
      ) {
        dispatch(setPurchasedTicketsFormState(null));
        setTotalTicketsByType(getInitialTotalTickets());
      } else {
        setTotalTicketsByType(getInitialTotalTickets());
      }
    }
    return () => {
      setTotalTicketsByType({});
    };
  }, [event.tickets] || [event.packages]);

  useEffect(() => {
    if (error) {
      console.log(error);
    } else if (ticketsSuccessfullyBought) {
      dispatch(resetPurchasedTickets());
      history.push('/thanks-for-purchase');
    }
  }, [error, ticketsSuccessfullyBought]);

  useEffect(() => {
    if (isSent) {
      dispatch(resetSentRequest());
      history.push('/request-sent');
    }
  }, [isSent]);

  useEffect(() => {
    if (user && signUpToBuyTicketState?.isWaiting && event) {
      if (event.onReservation && signUpToBuyTicketState?.guestFormValues) {
        onCreateRequest(signUpToBuyTicketState?.guestFormValues, true);
        dispatch(setSignUpToBuyTicketsState(null));
      } else {
        dispatch(setSignUpToBuyTicketsState(null));
        setIsPaymentOpen(true);
      }
    }
  }, [user, signUpToBuyTicketState, event]);

  const totalNumberOfTickets = useMemo(
    () =>
      Object.values(totalTicketsByType).reduce(
        (accumulator, current) => current + accumulator,
        0,
      ),
    [totalTicketsByType, event.tickets || event.packages],
  );

  const totalPrice = useMemo(() => getTotalPrice(), [totalTicketsByType]);

  const totalFees = useMemo(
    () =>
      (event.tickets ?? event.packages ?? []).reduce(
        (accumulator, current) =>
          accumulator +
          (current.id &&
            current.accomdationFees &&
            totalTicketsByType[current.id] > 0
            ? current.accomdationFees
            : 0) +
          (current.fees ?? 0) *
          (current.id ? ((current.fixedPrice == true && totalTicketsByType[current.id] > 0 ? 1 : totalTicketsByType[current.id])) : 0),
        0,
      ),
    [totalTicketsByType, event.tickets || event.packages],
  );

  const isRightAmountOfTickets = useMemo<boolean>(
    () =>
      Boolean(
        totalNumberOfTickets > 0 &&
        (event.tickets
          ? event.tickets.reduce(
            (accumulator: boolean, current: EventTicket) => {
              return Boolean(
                accumulator &&
                Boolean(
                  !(
                    current.id &&
                    totalTicketsByType[current.id] &&
                    totalTicketsByType[current.id] > 0 &&
                    current.min &&
                    totalTicketsByType[current.id] < current.min
                  ),
                ),
              );
            },
            true,
          )
          : event.packages
            ? event.packages.reduce(
              (accumulator: boolean, current: EventTicket) => {
                return Boolean(
                  accumulator &&
                  Boolean(
                    !(
                      current.id &&
                      totalTicketsByType[current.id] &&
                      totalTicketsByType[current.id] > 0 &&
                      current.min &&
                      totalTicketsByType[current.id] < current.min
                    ),
                  ),
                );
              },
              true,
            )
            : true),
      ),
    [totalTicketsByType, totalNumberOfTickets],
  );
  useEffect(() => {
    if (userCountry == null) {
      dispatch(getUserLocationCountry());
    }
  }, []);

  const noTicketsAvailable = useMemo<boolean>(
    () =>
      Boolean(
        endDate < new Date() ||
        event.available === false ||
        // TODO uncomment when ready to release
        // event.state !== 'active' ||

        (event.tickets &&
          event.tickets.reduce(
            (accumulator: boolean, current: EventTicket) => {
              return Boolean(
                accumulator &&
                !Boolean(current.id && current.available !== false),
              );
            },
            true,
          )) ||
        (event.packages &&
          event.packages.reduce(
            (accumulator: boolean, current: EventTicket) => {
              return Boolean(
                accumulator &&
                !Boolean(current.id && current.available !== false),
              );
            },
            true,
          )),
      ),
    [event.tickets, event.packages],
  );

  const onSubmitPayment = async (token: Token) => {
    if (token.id) {
      try {
        await dispatch(
          buyTickets({
            boughtTickets: (event.tickets ?? event.packages ?? []).reduce(
              (accumulator, ticket) => ({
                ...accumulator,
                ...(ticket.id && totalTicketsByType[ticket.id]
                  ? { [ticket.id]: totalTicketsByType[ticket.id] }
                  : {}),
              }),
              {},
            ),
            fromGifts: false,
            fromPoints: false,
            paid: false,
            eventId: event.id,
            stripeToken: token.id,
          }),
        ).unwrap();
        setTotalTicketsByType(getInitialTotalTickets());
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onCreateRequest = (
    guestFormValues: GuestFormValues,
    afterWaiting?: boolean,
  ) => {
    if (totalNumberOfTickets > 0) {
      dispatch(
        createRequest({
          ticketsNumberById: totalTicketsByType,
          tickets:
            (event.tickets ?? event.packages ?? []).filter(
              (ticket) => ticket.id && totalTicketsByType[ticket.id] > 0,
            ) ?? [],
          eventId: event.id,
          userLink: guestFormValues.userLink ?? 'Not Found',
          currencySymbol: event.currencySymbol ?? '$',
          recipients: [
            ...guestFormValues.guests,
            {
              name: guestFormValues.firstName + ' ' + guestFormValues.lastName,
              email: user?.email ?? '',
              link: guestFormValues.userLink ?? '',
              phone: user?.phoneNumber ?? '',
              gender: guestFormValues.gender ?? 'other',
              isUser: true,
              nationality: guestFormValues.country ?? 'EG',
            } as GuestForm,
          ],
          genderRatio: guestFormValues.genderRatio,
          numberOfMen: guestFormValues.numberOfMen,
          numberOfWomen: guestFormValues.numberOfWomen,
        }),
      );
      setTotalTicketsByType(getInitialTotalTickets());
    } else if (purchasedTicketsFormState?.tickets) {
      dispatch(
        createRequest({
          ticketsNumberById: purchasedTicketsFormState.tickets,
          tickets:
            (event.tickets ?? event.packages ?? []).filter(
              (ticket) => ticket.id && totalTicketsByType[ticket.id] > 0,
            ) ?? [],
          eventId: purchasedTicketsFormState.eventId,
          currencySymbol: event.currencySymbol ?? '$',
          recipients: [
            ...guestFormValues.guests,
            {
              name: user?.firstName + ' ' + user?.lastName,
              email: user?.email ?? '',
              link: guestFormValues.userLink ?? '',
              phone: user?.phoneNumber ?? '',
              gender: user?.gender ?? 'other',
              isUser: true,
              nationality: guestFormValues.country ?? 'EG',
            } as GuestForm,
          ],
          genderRatio: guestFormValues.genderRatio,
          numberOfMen: guestFormValues.numberOfMen,
          numberOfWomen: guestFormValues.numberOfWomen,
        }),
      );
      setTotalTicketsByType(getInitialTotalTickets());
    }
  };

  const onSubmitRequest = (
    guestFormValues: GuestFormValues,
    signUpValues?: SignUpValues,
  ) => {
    if (user) {
      onCreateRequest(guestFormValues);
    } else if (signUpValues) {
      dispatch(
        setSignUpToBuyTicketsState({
          isWaiting: true,
          guestFormValues,
        }),
      );
      dispatch(
        setPurchasedTicketsFormState({
          tickets: totalTicketsByType,
          eventId: event.id,
        }),

      );

      dispatch(
        signUp({
          firstName: signUpValues.firstName,
          lastName: signUpValues.lastName,
          email: signUpValues.email,
          password: signUpValues.password,
          dateOfBirth: signUpValues.dateOfBirth,
          username: signUpValues.email,
          isPlanner: false,
        }),
      );
    }
  };

  const onSubmitBuyTickets = (signUpValues?: SignUpValues) => {
    if (user) {
      setIsPaymentOpen(true);
    } else if (signUpValues) {
      dispatch(
        setSignUpToBuyTicketsState({
          isWaiting: true,
        }),
      );
      dispatch(
        setPurchasedTicketsFormState({
          tickets: totalTicketsByType,
          eventId: event.id,
        }),
      );
      dispatch(
        signUp({
          firstName: signUpValues.firstName,
          lastName: signUpValues.lastName,
          email: signUpValues.email,
          password: signUpValues.password,
          dateOfBirth: signUpValues.dateOfBirth,
          username: signUpValues.email,
          isPlanner: false,
        }),
      );
    }
  };

  const onRedirectToLogin = () => {
    dispatch(
      setPurchasedTicketsFormState({
        tickets: totalTicketsByType,
        eventId: event.id,
      }),
    );
    dispatch(setSelectedTableId(selectedTicket));
    history.push('/signUp');
  };
  return (
    { event } && (
      <Box className={classes.container}>
        {isDesktop ? (
          <DesktopEventPageContent
            totalFees={totalFees}
            totalPrice={totalPrice}
            totalNumberOfTickets={totalNumberOfTickets}
            event={event}
            isPaymentModalOpen={isPaymentOpen}
            noTicketsAvailable={noTicketsAvailable}
            onSubmitRequest={onSubmitRequest}
            selectedTicket={selectedTicket}
            setSelectedTicket={setSelectedTicket}
            onSubmitBuyTickets={onSubmitBuyTickets}
            onSubmitPayment={onSubmitPayment}
            onClosePaymentModal={() => setIsPaymentOpen(false)}
            onRedirectToLogin={onRedirectToLogin}
            totalTicketsByType={totalTicketsByType}
            setTotalTicketsByType={setTotalTicketsByType}
            isRightAmountOfTickets={isRightAmountOfTickets}
          />
        ) : (
          <MobileEventPageContent
            event={event}
            isPaymentModalOpen={isPaymentOpen}
            onSubmitRequest={onSubmitRequest}
            onSubmitBuyTickets={onSubmitBuyTickets}
            onSubmitPayment={onSubmitPayment}
            selectedTicket={selectedTicket}
            setSelectedTicket={setSelectedTicket}
            onClosePayment={() => setIsPaymentOpen(false)}
            totalTicketsByType={totalTicketsByType}
            totalNumberOfTickets={totalNumberOfTickets}
            totalPrice={totalPrice}
            totalFees={totalFees}
            noTicketsAvailable={noTicketsAvailable}
            setTotalTicketsByType={setTotalTicketsByType}
            onOpenPayment={() => setIsPaymentOpen(true)}
            isBuyTicketsFormOpen={isBuyTicketsFormOpen}
            onCloseBuyTicketsForm={() => setIsBuyTicketsFormOpen(false)}
            isPaymentOpen={isPaymentOpen}
            isRightAmountOfTickets={isRightAmountOfTickets}
            onOpenBuyTicketsForm={() => setIsBuyTicketsFormOpen(true)}
            onReservation={event.onReservation ?? false}
            onRedirectToLogin={onRedirectToLogin}
          />
        )}
      </Box>
    )
  );
};
