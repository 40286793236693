import {
  CardMedia,
  TextField,
  Grid,
  Box,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Request } from 'src/models/request';
import { Event } from 'src/models/event';
import SearchIcon from '@mui/icons-material/Search';
import { DateTime } from 'luxon';
import { IoIosArrowBack } from 'react-icons/io';
import zIndex from '@mui/material/styles/zIndex';
import { useHistory } from 'react-router-dom';

const useStyles = createUseStyles({
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '90vw',
    height: '100%',
    maxHeight: '55vh',
    maxWidth: '90vw',
    zIndex: 1,
  },
  eventDate: {
    display: 'block',
    alignSelf: 'start',
    maxWidth: '70vw',
    fontFamily: 'SF Pro Display',
    color: '#4A4A4A',
    fontWeight: 700,
    fontSize: 15,
  },
});

interface Props {
  event: Event | null;
}
const RequestsTopBarMobile: React.VFC<Props> = ({ event }) => {
  const classes = useStyles();
  const history = useHistory();
  const getEventDates = (
    events: Event,
  ): {
    startDate: Date;
    endDate: Date;
    dateString: string;
    timeString: string;
    timeBeforeStart: string;
  } => {
    const StartDate = new Date(events.startDate);
    const StartDateUTC = StartDate; //getEventDate(event.timeZone, StartDate);
    const EndDate = new Date(events.endDate);
    const EndDateUTC = EndDate; // getEventDate(event.timeZone, EndDate);
    const timeBeforeStartDuration = DateTime.fromJSDate(StartDateUTC).diff(
      DateTime.now(),
    );

    const timeBeforeStart =
      new Date() > EndDateUTC
        ? 'Finished'
        : new Date() > StartDateUTC
        ? 'Started'
        : timeBeforeStartDuration.as('seconds') < 0
        ? '00:00:00'
        : timeBeforeStartDuration.as('days') < 1
        ? timeBeforeStartDuration.toFormat('hh:mm:ss')
        : timeBeforeStartDuration.as('months') < 1
        ? timeBeforeStartDuration.as('days') >= 2
          ? `${timeBeforeStartDuration.toFormat('d')} days`
          : '1 day'
        : timeBeforeStartDuration
        ? timeBeforeStartDuration.as('months') > 2
          ? `${timeBeforeStartDuration.toFormat('MM')} months`
          : '1 month'
        : '0 months';
    const dateString = `${StartDateUTC.toLocaleDateString('en-US', {
      timeZone: events.timeZone,
      month: 'long',
      day: 'numeric',
    })}  - ${EndDateUTC.toLocaleDateString('en-US', {
      timeZone: events.timeZone,
      month: 'long',
      day: 'numeric',
    })}`;
    const timeString = `${StartDateUTC.toLocaleTimeString('en-US', {
      timeZone: events.timeZone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })} - ${EndDateUTC.toLocaleTimeString('en-US', {
      timeZone: events.timeZone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;
    return {
      startDate: StartDateUTC,
      endDate: EndDateUTC,
      dateString,
      timeString,
      timeBeforeStart,
    };
  };

  return (
    <>
      {' '}
      <Stack
        direction={'column'}
        spacing={1}
        justifyContent={'center'}
        alignItems={'center'}
        position={'relative'}
      >
        <Button
          onClick={() => history.push('/events')}
          variant="contained"
          sx={{
            fontSize: '22px',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            position: 'absolute',
            top: '5%',
            left: '5%',
            color: 'black',
            zIndex: 2,
            backgroundColor: 'white',
          }}
        >
          <IoIosArrowBack />
        </Button>
        {event?.imageUrl == null ? (
          <CardMedia
            component="img"
            className={classes.eventImage}
            image="https://images.unsplash.com/photo-1506157786151-b8491531f063?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
          />
        ) : (
          <CardMedia
            component="img"
            className={classes.eventImage}
            image={event?.imageUrl}
          ></CardMedia>
        )}

        <Typography
          variant="size4"
          textAlign="start"
          fontWeight={900}
          display="block"
          maxWidth={'70vw'}
          alignSelf={'start'}
        >
          {event?.name} at {event?.addressName}
        </Typography>

        <Typography className={classes.eventDate}>
          {' '}
          {event &&
            DateTime.fromJSDate(getEventDates(event).startDate).toFormat(
              'cccc, LLLL dd T ZZZZ',
            )}
        </Typography>
      </Stack>
    </>
  );
};

export default RequestsTopBarMobile;
