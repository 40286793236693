import { createUseStyles } from 'react-jss';
import { Event } from 'src/models/event';
import React, { useEffect } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/system';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Calendar } from '../../assets/calendar.svg';
import { ReactComponent as Location } from '../../assets/location.svg';
import { ReactComponent as Cd } from '../../assets/cd.svg';
import { ReactComponent as Tickets } from '../../assets/ticketLogo.svg';
import { getEventDates } from 'src/helpers/event/getEventDates';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { cloudGetPlanner } from 'src/slices/event';
import { useParams } from 'react-router-dom';
interface DesktopEventsEventCardProps {
  event: Event;
}

const useStyles = createUseStyles({
  card: {
    width: '220px',
    height: '100%',
    aspectRatio: 1,
    boxShadow: 'none',
    borderRadius: '12.5px',
    position: 'relative',
  },
  img: {
    borderRadius: 12,
    objectFit: 'fill',
    width: '300px',
    height: '300px',
  },
  title: {
    fontSize: '30px',
    fontWeight: 800,
    color: '#000',
    lineHeight: '36px',
  },
  subtitle: {
    fontSize: '13px',
    color: '#000',
    fontWeight: 600,
  },
  DesktopEventsEventCard: {
    width: '100%',
  },
  fieldTitle: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 600,
    color: 'black',
    textOverflow: 'ellipsis',
    maxLines: 1,
  },
  fieldSubtitle: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'black',
    opacity: 0.75,
    textOverflow: 'ellipsis',
    maxLines: 1,
  },
  svgIcon: {
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
});

export const EventSection: React.VFC<DesktopEventsEventCardProps> = ({
  event,
}) => {
  const classes = useStyles();

  const { dateString, timeBeforeStart, timeString, startDate, endDate } =
    getEventDates(event);
  const dispatch = useAppDispatch();
  const plannerName = 'Hi';

  const ticketsPricesMinAndMax: [number, number] | undefined = event.tickets
    ? event.tickets?.reduce(
      ([min, max]: [number, number], ticket) => {
        if (!ticket.onInvitation) {
          if (!ticket.price) {
            return [min, max];
          }
          if (ticket.price < min && ticket.price > max) {
            return [ticket.price, ticket.price];
          } else if (ticket.price < min) {
            return [ticket.price, max];
          } else if (ticket.price > max) {
            return [min, ticket.price];
          }
        }
        return [min, max];
      },
      [Infinity, 0],
    )
    : event.packages?.reduce(
      ([min, max]: [number, number], ticket) => {
        if (!ticket.onInvitation) {
          if (!ticket.aPrice) {
            return [min, max];
          }
          if (ticket.aPrice < min && ticket.aPrice > max) {
            return [ticket.aPrice, ticket.aPrice];
          } else if (ticket.aPrice < min) {
            return [ticket.aPrice, max];
          } else if (ticket.aPrice > max) {
            return [min, ticket.aPrice];
          }
        }
        return [min, max];
      },
      [Infinity, 0],
    );

  const ticketPricesString =
    new Date() < endDate &&
      ticketsPricesMinAndMax &&
      ticketsPricesMinAndMax?.[0] != Infinity
      ? `${ticketsPricesMinAndMax?.[0]} ${event.currencySymbol} - ${ticketsPricesMinAndMax?.[1]} ${event.currencySymbol}`
      : 'No tickets available';

  const buildImageSection = () => (
    <img src={event?.imageUrl} className={classes.img} alt="" />
  );

  const buildDescriptionField = (
    title?: string,
    subtitle?: string,
    icon?: () => JSX.Element,
    allRow?: boolean,
    onClick?: () => void,
  ) => (
    <Grid
      item
      container
      direction="row"
      display="flex"
      xs={12}
      md={allRow ? 12 : 6}
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
      }}
    >
      <Stack direction="row" alignItems="center">
        {icon && icon()}
        <Stack direction="column">
          <Typography
            className={classes.fieldTitle}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            {title}
          </Typography>
          <Typography
            className={classes.fieldSubtitle}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );

  const buildDescriptionSection = () => (
    <Box>
      <Stack direction="column" justifyContent="left" marginBottom="27px">
        <Typography className={classes.title}>{event.name}</Typography>
        <Typography className={classes.subtitle}>
          By {event?.organizerName ? event?.organizerName : 'Makanak'}
        </Typography>
      </Stack>
      <Box>
        <Grid container rowSpacing="11.19px" columnSpacing="67px">
          {buildDescriptionField(dateString, timeString, () => (
            <SvgIcon
              component={Calendar}
              className={classes.svgIcon}
              style={{
                width: 21,
                height: 21,
              }}
              inheritViewBox
            />
          ))}
          {event.dj &&
            buildDescriptionField(event.dj, event.music, () => (
              <SvgIcon
                component={Cd}
                className={classes.svgIcon}
                style={{
                  width: 21,
                  height: 21,
                }}
                inheritViewBox
              />
            ))}
          {buildDescriptionField(
            event.addressName,
            event.address,
            () => (
              <SvgIcon
                component={Location}
                className={classes.svgIcon}
                style={{
                  width: 14,
                  height: 21,
                  marginRight: 10,
                  marginLeft: 4,
                }}
                inheritViewBox
              />
            ),
            true,
            () => {
              window.open(
                'https://www.google.com/maps/search/?api=1&query=' +
                event.address,
              );
            },
          )}
          {buildDescriptionField(
            timeBeforeStart,
            ticketPricesString,
            () => (
              <SvgIcon
                component={Tickets}
                className={classes.svgIcon}
                style={{
                  width: 21,
                  height: 19,
                }}
                inheritViewBox
              />
            ),
          )}
          {event.infoRule &&
            event.infoRule2 &&
            buildDescriptionField(event.infoRule, event.infoRule2, () => (
              <CheckCircleOutlineIcon sx={{ mr: 1 }} />
            ))}
        </Grid>
      </Box>
    </Box>
  );
  return (
    <Stack
      direction="row"
      justifyContent="start"
      spacing="31px"
      className={classes.DesktopEventsEventCard}
    >
      {buildImageSection()}
      {buildDescriptionSection()}
    </Stack>
  );
};
