import React, { useMemo, VFC } from 'react';
import styled from 'styled-components';
import { useAppSelector } from 'src/hooks/store';
import { customerMenu, plannerMenu, publicMenu } from 'src/data/menus';
import { useMediaQuery } from '@mui/material';
import OldDesktopHeader from './OldDesktopHeader';
import OldMobileHeader from './OldMobileHeader';

export const HeaderDiv = styled.div`
  margin: 43px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s linear;
  @media screen and (max-width: 914px) {
    flex-direction: column;
    transition: 0.2s linear;
  }
  @media screen and (max-width: 768px) {
    width: calc(90% - 15px);
    flex-direction: row;
    margin: 10px 0 10px 27px;
    transition: 0.2s linear;
    svg {
      font-size: 30px;
      cursor: pointer;
    }
  }
  @media screen and (max-width: 425px) {
    width: calc(100% - 30px);
    flex-direction: row;
    margin: 10px 15px;
    transition: 0.2s linear;
  }
`;

export const HeaderLogoImg = styled.img`
  width: 115px;
  transition: 0.2s linear;
  @media screen and (max-width: 914px) {
    width: 80px;
    transition: 0.2s linear;
  }
  /* @media screen and (max-width: 768px) {
    width: 80px;
    transition: 0.2s linear;
  } */
`;

export const HeaderMenuBar = styled.div`
  display: flex;
  gap: 2%;
  width: 100%;
  justify-content: end;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  align-items: center;
`;

// Define Menu styled components
export const MenuGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
`;

type MenuItemProps = {
  active: boolean;
  sub?: boolean;
};

export const MenuItem = styled.div<MenuItemProps>`
  position: relative;
  padding-bottom: 4px;
  margin: 0 10px;
  color: #000;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  :hover {
    ${({ active }) =>
      !active &&
      `
      ::before {
        width: 100%;
        height: 2px;
        content: "";
        background-color: #000;
        position: absolute;
        bottom: 0;
        border-radius: 1.5px;
      }`}
  }
  ${({ active }) =>
    active &&
    `font-weight: 700;
    ::before {
      width: 100%;
      height: 2px;
      content: "";
      background-color: #000;
      position: absolute;
      bottom: 0;
      border-radius: 1.5px;
    }`};
}
  @media screen and (max-width: 1024px) {
    transition: 0.2s linear;
  }
`;

export const Submenu = styled.div`
  position: relative;
`;

export const SubmenuRoot = styled.div`
  z-index: 1;
  padding: 20px 0 0 0;
`;

export const SubmenuDiv = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid #555555;
  padding: 10px 0;
  background-color: #fff;
  /* margin: 10px 0 0 0; */
`;

export const SubmenuItem = styled.div`
  position: relative;
  width: 150px;
  padding-bottom: 4px;
  font-size: 16px;
  color: #000;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 20px;
  transition: 0.2s linear;
  :hover {
    font-weight: 700;
    transition: 0.2s linear;
  }
`;

export const LogoutBtnDiv = styled.div`
  width: 195px;
  @media screen and (max-width: 1024px) {
    width: 150px;
    font-size: 16px;
    transition: 0.2s linear;
  }
`;

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  min-height: calc(100vh - 100px);
`;

export const SideMenuPart = styled.div`
  position: fixed;
  /* z-index: 1; */
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

type SideMenuFlag = {
  fg: boolean;
};

export const SideMenuDiv = styled.div<SideMenuFlag>`
  position: fixed;
  ${({ fg }) =>
    fg
      ? 'left: 0;transition: 0.5s ease;'
      : 'left: -340px;transition: 0.5s ease;'}
  width: 320px;
  height: 100vh;
  top: 0;
  background-color: #000000;
  z-index: 2;
`;

export const EmptySide = styled.div`
  position: fixed;
  width: calc(100vw - 320px);
  height: 100vh;
  right: 0;
  top: 0;
  z-index: 1;
`;

export const SideMenubar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  height: 100vh;
  width: 250px;
  justify-content: space-between;
  background-color: #f5f5f5;
`;

export const SideMenuItem = styled.div`
  color: gray !important;
  font-size: 15px;
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    color: white;
    transition: 0.2s linear;
  }
`;

export const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
`;

export const SpinnerContainer = styled.div`
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  backdrop-filter: blur(20px);
`;
const Header: VFC = () => {
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  const isDesktop = useMediaQuery('(min-width: 767px)', { noSsr: true });
  const isAuthenticated = useMemo(() => user, [user]);
  const isCustomer = useMemo(() => user?.type == 'customer', [user]);
  const currentMenu = useMemo(
    () =>
      !isAuthenticated ? publicMenu : isCustomer ? customerMenu : plannerMenu,
    [isAuthenticated, isCustomer],
  );

  return isDesktop ? (
    <OldDesktopHeader menu={currentMenu} />
  ) : (
    <OldMobileHeader menu={currentMenu} />
  );
};

export default Header;
