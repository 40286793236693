import { configureStore, combineReducers } from '@reduxjs/toolkit';
import projectReducer from 'src/slices/project';
import eventReducer from 'src/slices/event';
import authenticationReducer from 'src/slices/authentication';
import requestReducer from 'src/slices/request';
import ticketReducer from 'src/slices/ticket';
import paymentReducer from 'src/slices/payment';
import promoCodeReducer from '../slices/promoCode';
import plannerReducer from '../slices/planner';

/* Reducers */
const rootReducer = combineReducers({
  project: projectReducer,
  event: eventReducer,
  authentication: authenticationReducer,
  request: requestReducer,
  ticket: ticketReducer,
  payment: paymentReducer,
  promoCode: promoCodeReducer,
  planner: plannerReducer,
});

export const store = configureStore({ reducer: rootReducer });

/* Infer the `RootState` and `AppDispatch` types from the store itself */
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
