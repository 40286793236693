import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Stack,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Box,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { logIn, resetError } from 'src/slices/authentication';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const useStyles = createUseStyles({
  container: {},
  buttonForgotpsswd: {
    color: "gray",
    fontSize: "15px",
    textTransform: "none",
    opacity: ".5",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonSignup: {
    color: "black",
    borderRadius: "300px",
    border: "2px solid black",
    borderColor: "#FF0202",
    width: "313.2px",
    height: "45px",
    textTransform: "none",
    fontSize: "17.2px",
    "&:hover": {
      backgroundColor: "#F70303",
      border: "solid 1px #F70303",
      color:'white'
    },
  },
  buttoncontinue: {
    color: "white",
    borderRadius: "300px",
    width: "313.2px",
    height: "45px",
    fontSize: "17.2px",
    backgroundColor: "#FF0202",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#F70303",
      border: "solid 1px #F70303",
    },
  },

  textfield1: {
    color: "black",
    "& .MuiOutlinedInput-input": {
      padding: "10.7px 20.7px",
    },
    [`& fieldset`]: {
      border: "none",
    },
  },

  menubutton: {
    color: "black",
    height: "10%",
    width: "10%",
  },

  bigimage: {
    width: "40%",
    padding: "10px",
    margin: "10px",
  },
  typographyposition: {
    position: "absolute",
    left: "300px",
  },
  root: {
    width: "100%",
    height: "100%",
  },
  signInHeader: {
    fontWeight: 800,
    color: "black",
    fontSize: 45,
    lineHeight: "64px",
    "@media screen and (max-width: 900px)": {
      marginTop: "70px",
    },
  },
  signInDesc: {
    color: "black",
    marginTop: "-8px !important",
    marginLeft: "3px !important",
    fontWeight: 500,
    fontSize: 17.2,
  },
  inputColor: {
    border: "solid 2px #F0F0F0",
    borderRadius: 30,
    backgroundColor: "white",
    height: "45px",
  },
});

const EventPlannerAuth = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [displayError, setDisplayError] = useState(false);
  const { loading, signError, user } = useAppSelector(
    ({ authentication: { loading, signError, user } }) => ({
      loading,
      signError,
      user,
    }),
  );

  useEffect(() => {
    if (signError && !loading) {
      setDisplayError(true);
      resetError();
    }
  }, [signError, loading, user]);

  const onClickSignIn = () => {
    dispatch(logIn({ username: email, password }));
  };

  const onClickSignUp = () => {
    history.push('/eventPlannerSignUp');
  };

  const onClickForgotPassword = () => {
    history.push('/forgot-password');
  };

  return (
    <Box maxWidth="500px" marginX="auto" className={classes.container}>
      <Helmet>
        <script>
          {`
            window.Intercom("boot", {
  api_base: "https://api-iam.intercom.io",
  app_id: "okyuu12q"
});
          `}
        </script>
      </Helmet>
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Stack direction="column" spacing={1.5} justifyContent="center">
          <Typography className={classes.signInHeader}>Sign in</Typography>
          <Typography className={classes.signInDesc}>Event Planner</Typography>
          <TextField
            className={classes.textfield1}
            value={email}
            onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
            placeholder="Email"
            size="small"
            InputProps={{ className: classes.inputColor }}
          />
          <TextField
            className={classes.textfield1}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            size="small"
            InputProps={{ className: classes.inputColor }}
          />
          <Stack>
            <Button
              variant="outlined"
              onClick={onClickSignIn}
              className={classes.buttoncontinue}
              size="medium"
            >
              Continue
            </Button>
            <Stack alignItems="flex-end">
              <Button
                variant="text"
                className={classes.buttonForgotpsswd}
                onClick={onClickForgotPassword}
              >
                Forgot Password ?
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ fontWeight: 700, color: 'black', fontSize: 17.2 }}>
            Or
          </Typography>
          <Button
            onClick={onClickSignUp}
            variant="outlined"
            className={classes.buttonSignup}
            size="medium"
          >
            Sign up
          </Button>
        </Stack>
        <Snackbar
          open={displayError}
          autoHideDuration={6000}
          onClose={() => setDisplayError(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setDisplayError(false)}
            severity="error"
            sx={{ width: '100%' }}
          >
            Wrong password, please try again
          </Alert>
        </Snackbar>
      </Stack>
    </Box>
  );
};
export default EventPlannerAuth;
