import { Stack, Typography } from '@mui/material';
import React from 'react';
import { commonStyles } from 'src/constants/common-styles';
import styled from 'styled-components';
import { Box } from '@mui/system';
import { createUseStyles } from 'react-jss';

const TitleCommon = styled.div`
  font-size: 75px;
  font-weight: 900;
  transition: 0.2s linear;
  @media screen and (max-width: 1950px) {
    font-size: 70px;
    max-width: 100% !important;
  }
  @media screen and (max-width: 1700px) {
    font-size: 60px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 50px;
  }
  @media screen and (max-width: 1150px) {
    font-size: 30px;
  }
  @media screen and (max-width: 767px) {
    font-size: 30px;
  }
  @media screen and (max-width: 500px) {
    font-size: 30px;
  }
`;
type TitleProps = {
  color: string;
};
styled(TitleCommon)<TitleProps>`
  color: ${({ color }) => color};

  @media screen and (max-width: 425px) {
    text-align: left;
    width: 100%;
    font-weight: bold;
    font-size: 40px;
  }
`;

const useStyles = createUseStyles({
  container: {
    '@media screen and (max-width: 900px)': {
      margin: '0px 5% 30px 5%',
    },
  },
});

const Title: React.VFC = () => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  return (
    <Box className={classes.container}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Typography className={commonClasses.landingTitle}>
          By Techno People…For
        </Typography>
        <Typography className={commonClasses.landingTitle}>
          Techno People
        </Typography>
      </Stack>
    </Box>
  );
};

export default Title;
