import { createUseStyles } from 'react-jss';
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  CardContent,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Token } from '@stripe/stripe-js';
import LogoIMG from '../assets/whiteLogo.png';
import { AiFillCloseCircle } from 'react-icons/all';
import { addOpacityToColor } from '../helpers/colors';
import { useAppSelector } from '../hooks/store';
import FullscreenSpin from '../components/FullscreenSpin';

interface CheckoutFormProps {
  amount: number;
  cityColor: string;
  currency: string;
  canExit?: boolean;
  onSuccess: (token: Token) => void;
  onError: (error: string) => void;
  onClose: () => void;
  isPaymentLoading: boolean;
  isPaymentSuccessful: boolean;
  isRequest?: boolean;
}
export const CheckoutForm: React.VFC<CheckoutFormProps> = ({
  onSuccess,
  onError,
  cityColor,
  onClose,
  isPaymentLoading,
  amount,
  currency,
  canExit = false,
  isRequest = false,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const isMobile = useMediaQuery(`(max-width: 900px)`);
  const { isLoading, discount } = useAppSelector(
    ({ promoCode: { isLoading, discount } }) => ({
      isLoading,
      discount,
    }),
  );
  const useStyles = createUseStyles({
    checkoutContainer: {
      height: '100vh',
      width: '450px',
      '@media (max-width: 900px)': {
        width: '250px',
      },
      margin: '0 auto',
    },
    cardElement: {},
    checkoutCard: {
      marginTop: '5px',
      width: '100%',
      zIndex: 10000,
    },
    title: {
      fontSize: '40px',
      color: 'white',
      fontWeight: 800,
      lineHeight: '48px',
      textAlign: 'left',
    },
    text: {
      fontSize: '16px',
      color: 'white',
      fontWeight: 500,
      lineHeight: '18px',
    },
    boldText: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: 'white',
      lineHeight: '18px',
    },
    logo: {
      width: 70,
      height: 45,
      minWidth: 70,
      minWeight: 45,
    },
    payment: {
      height: '22px',
      padding: 0,
    },
    button: {
      marginTop: 47,
      color: 'white',
      borderRadius: '22.5px',
      fontSize: '17px',
      width: '360px',
      '@media (max-width: 900px)': {
        width: '250px',
      },
      height: '45px',
      textTransform: 'none',
      '&:hover': {
        opacity: 0.7,
      },
    },
    colorPrimary: {
      backgroundColor: addOpacityToColor(
        `#${cityColor.replace('#', '')}`,
        0.25,
      ),
    },
    barColorPrimary: {
      backgroundColor: `#${cityColor.replace('#', '')}`,
    },
  });
  const classes = useStyles();
  const { buyError, requestError } = useAppSelector(
    ({ ticket: { error: buyError }, request: { error: requestError } }) => ({
      buyError,
      requestError,
    }),
  );
  const [errorMessage, setErrorMessage] = React.useState<string | null>(
    isRequest ? requestError : buyError,
  );
  useEffect(() => {
    if (isRequest && requestError) {
      setErrorMessage(requestError);
    } else if (!isRequest && buyError) {
      setErrorMessage(buyError);
    }
  }, [buyError, requestError]);

  const cardElementStyles = {
    style: {
      base: {
        iconColor: 'white',
        color: '#fff',
        fontWeight: 500,
        fontFamily: 'sans-serif',
        fontSize: isMobile ? '11px' : '15px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#000',
        },
        '::placeholder': {
          color: '#4B4B4B',
        },
      },
      invalid: {
        iconColor: '#FFC7EE',
        color: '#FFC7EE',
      },
    },
  };

  const onClick = async () => {
    const cardElement = elements?.getElement('card');
    if (stripe && cardElement) {
      const token = await stripe.createToken(cardElement);
      if (token.token) {
        onSuccess(token.token);
      } else {
        setErrorMessage(token.error?.message ?? 'Something went wrong');
        onError(token.error?.message ?? '');
      }
    }
  };
  const isDesktop = useMediaQuery('(min-width: 900px)');

  return isLoading ? (
    <FullscreenSpin />
  ) : (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: isDesktop ? '20%' : '0' }}
      className={classes.checkoutContainer}
    >
      <Stack direction="column" justifyContent="center" alignItems="start">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width={isDesktop ? '360px' : '250px'}
          marginBottom="56.37px"
        >
          <Box
            component="img"
            className={classes.logo}
            src={LogoIMG}
            alt="logo"
          />
          {(isDesktop || canExit) && (
            <Box>
              <Button onClick={onClose}>
                <AiFillCloseCircle
                  fontSize="large"
                  style={{ fontSize: '36px', color: 'white', opacity: 0.5 }}
                />
              </Button>
            </Box>
          )}
        </Stack>
        <Stack
          direction="column"
          justifyContent="center"
          width={isDesktop ? '450px' : '250px'}
        >
          <Typography className={classes.title}>Payment</Typography>
          {isPaymentLoading ? (
            <Typography className={classes.text}>
              {'Please do not close this page while payment is processing'}
            </Typography>
          ) : (
            <>
              <Box sx={{ mb: '20px' }}>
                <Typography
                  className={classes.boldText}
                >{`Total to pay ${currency} ${Number(
                  discount ? amount * (1 - discount / 100) : amount,
                ).toFixed(2)}`}</Typography>
              </Box>
              <Typography className={classes.text}>
                {'Please enter your card details to complete the payment.'}
              </Typography>
            </>
          )}
        </Stack>
        <Stack
          alignItems="center"
          direction="column"
          width={isDesktop ? '360px' : '250px'}
        >
          <div className={classes.checkoutCard}>
            {isPaymentLoading ? (
              <>
                <CardContent className={classes.payment}>
                  <LinearProgress
                    classes={{
                      colorPrimary: classes.colorPrimary,
                      barColorPrimary: classes.barColorPrimary,
                    }}
                  />
                </CardContent>
              </>
            ) : (
              <>
                <CardContent className={classes.payment}>
                  <CardElement
                    onChange={() => setErrorMessage(null)}
                    options={{
                      iconStyle: 'solid',
                      hidePostalCode: true,
                      ...cardElementStyles,
                    }}
                  />
                </CardContent>
              </>
            )}
          </div>
          {errorMessage && (
            <Typography sx={{ mt: '10px', color: '#FF4771' }}>
              {errorMessage}
            </Typography>
          )}
          <Button
            className={classes.button}
            style={{
              background: `#${cityColor.replace('#', '')}`,
              color: 'white',
            }}
            onClick={onClick}
            disabled={isPaymentLoading || !stripe}
          >
            Pay
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
