import styled from 'styled-components';

export const Container = styled.div`
  // width: 90%;
  /* max-width: 1060px; */
  // margin: 0 auto;
  @media screen and (max-width: 1950px) {
    padding: 0 100px;
  }
  @media screen and (max-width: 1700px) {
    padding: 0 100px;
  }
  @media screen and (max-width: 1300px) {
    padding: 0 70px;
  }
  @media screen and (max-width: 1150px) {
    padding: 0 50px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 50px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 30px;
  }
`;
