import { Grid, Box, Typography, Card, Container } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import Donut from 'src/components/event/eventCircleProgress';
import { useIsBigScreen } from 'src/hooks/useBigScreen';
import { Event } from 'src/models/event';
import { EventTicket } from 'src/models/ticket';
import { getDateInTimeZone } from 'src/helpers/dateHelpers';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
interface Props {
  event: Event;
}

export const getEventDates = (
  event: Event,
): {
  startDate: Date;
  endDate: Date;
  dateString: string;
  timeString: string;
  timeBeforeStart: string;
} => {
  const StartDate = new Date(event.startDate);
  const StartDateUTC = StartDate; //getEventDate(event.timeZone, StartDate);
  const EndDate = new Date(event.endDate);
  const EndDateUTC = EndDate; // getEventDate(event.timeZone, EndDate);
  const timeBeforeStartDuration = DateTime.fromJSDate(StartDateUTC).diff(
    DateTime.now(),
  );

  const timeBeforeStart =
    new Date() > EndDateUTC
      ? 'Finished'
      : new Date() > StartDateUTC
      ? 'Started'
      : timeBeforeStartDuration.as('seconds') < 0
      ? '00:00:00'
      : timeBeforeStartDuration.as('days') < 1
      ? timeBeforeStartDuration.toFormat('hh:mm:ss')
      : timeBeforeStartDuration.as('months') < 1
      ? timeBeforeStartDuration.as('days') >= 2
        ? `${timeBeforeStartDuration.toFormat('d')} days`
        : '1 day'
      : timeBeforeStartDuration
      ? timeBeforeStartDuration.as('months') > 2
        ? `${timeBeforeStartDuration.toFormat('MM')} months`
        : '1 month'
      : '0 months';
  const dateString = `${StartDateUTC.toLocaleDateString('en-US', {
    timeZone: event.timeZone,
    month: 'long',
    day: 'numeric',
  })}  - ${EndDateUTC.toLocaleDateString('en-US', {
    timeZone: event.timeZone,
    month: 'long',
    day: 'numeric',
  })}`;
  const timeString = `${StartDateUTC.toLocaleTimeString('en-US', {
    timeZone: event.timeZone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })} - ${EndDateUTC.toLocaleTimeString('en-US', {
    timeZone: event.timeZone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;
  return {
    startDate: StartDateUTC,
    endDate: EndDateUTC,
    dateString,
    timeString,
    timeBeforeStart,
  };
};

const useStyles = createUseStyles({
  image: {
    minWidth: '20vw',
    minHeight: '20vw',
    width: '20vw',
    height: '20vw',
  },
  ticketSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  NameDate: {
    marginTop: '5vw',
  },
  Name: {
    fontFamily: 'SF Pro Display',
    fontSize: '12px',
    fontWeight: 800,
  },
  Date: {
    fontFamily: 'SF Pro Display SemiBold',
    fontSize: '10px',
    color: '#4A4A4A',
  },
});

const PlannerEventCard: React.VFC<Props> = ({ event }) => {
  const classes = useStyles();
  const isBigScreen = useIsBigScreen();
  const history = useHistory();
  const OpenEvent = () => {
    history.push(`/event/${event.id}/requests`);
  };
  const getTotalTicketsPercentage = (
    ticketsSold: number,
    ticketsNumber: number,
  ) => (ticketsSold / ticketsNumber) * 100;

  const getTotalTicketsNumber = (tickets: EventTicket[]) =>
    tickets.reduce(
      (accumulator, ticket) => accumulator + (ticket.total ?? 0),
      0,
    );

  const getTotalTicketsSold = (tickets: EventTicket[]) =>
    tickets.reduce(
      (accumulator, ticket) => accumulator + (ticket.sold ?? 0),
      0,
    );

  const totalTicketsNumber = useMemo<number>(
    () =>
      getTotalTicketsNumber(
        event?.tickets?.filter((ticket) => !ticket.onInvitation) ?? [],
      ),
    [event],
  );

  const totalTicketsSold = useMemo<number>(
    () =>
      getTotalTicketsSold(
        event?.tickets?.filter((ticket) => !ticket.onInvitation) ?? [],
      ),
    [event],
  );

  const totalTicketsPercentage = useMemo<number>(() => {
    return totalTicketsSold <= totalTicketsNumber && totalTicketsSold > 0
      ? getTotalTicketsPercentage(totalTicketsSold, totalTicketsNumber)
      : 0;
  }, [totalTicketsSold, totalTicketsNumber]);

  return (
    <Grid
      container
      direction={'row'}
      spacing={0}
      alignItems={'center'}
      alignContent="center"
      justifyContent={'space-between'}
      width={'85vw'}
      paddingLeft={'2vw'}
    >
      <Grid item xs={10}>
        <Stack direction="row" onClick={OpenEvent}>
          <Card
            onClick={OpenEvent}
            component="img"
            className={classes.image}
            src={event.imageUrl}
          ></Card>
          <Stack direction={'column'} spacing={0}>
            <Container
              sx={{
                whiteSpace: 'pre-line',
                maxLines: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                marginTop: '25px',
              }}
            >
              <Typography className={classes.Name}>{event.name}</Typography>

              <Typography className={classes.Date}>
                {' '}
                {DateTime.fromJSDate(getEventDates(event).startDate).toFormat(
                  'LLL dd - h:mm a',
                )}
              </Typography>
            </Container>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={2}>
        <div>
          <Donut
            size={isBigScreen ? 80 : 64}
            value={totalTicketsPercentage}
            color={totalTicketsPercentage > 0 ? 'orange' : 'orange'}
            fontSize={'12'}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default PlannerEventCard;
