import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Link,
  useMediaQuery,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { paymobCallback } from 'src/slices/request';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { FaTemperatureLow } from 'react-icons/fa';

const useStyles = createUseStyles({
  mainContainer: {
    height: '100%',
    '@media screen and (max-width: 900px)': {},
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    margin: '0 auto',
    padding: '0 20px',
    display: 'flex',
    maxWidth: '80%',
  },
  mobileContainer: {
    height: '100%',
    '@media screen and (max-width: 900px)': {},
    justifyContent: 'left',
    alignItems: 'left',
    justifyItems: 'left',
    margin: '0 auto',
    padding: '0 20px',
    display: 'flex',
    maxWidth: '93%',
  },
  headerText: {
    fontWeight: 800,
    color: 'black',
    fontSize: '34px',
    alignContent: 'center',
    align: 'center',
    alignSelf: 'center',
  },
  headerTextPhone: {
    fontWeight: 800,
    fontSize: '18px',
    alignContent: 'center',
    align: 'center',
    alignSelf: 'center',
    color: 'black',
  },
  subHeader: {
    fontWeight: 400,
    fontSize: '22px',
    color: 'text.primary',
    underline: 'none',
    lineHeight: '30px',
  },
  logo: {
    display: 'inline-flex',
    marginLeft: '10px',
    marginRight: '10px',

    minWidth: '30px',
    maxHeight: '30px',
  },
  logo2: {
    display: 'inline-flex',
    marginLeft: '15px',
    marginRight: '15px',
  },
  backToHomeButton: {
    textTransform: 'none',
    backgroundColor: 'orange',
    borderRadius: 20,
    width: '100%',
    maxWidth: '500px',
    alignSelf: 'center',
  },
});

export const Terms: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery('(min-width:600px)');
  return isDesktop ? (
    <div className={classes.mainContainer}>
      <Stack
        alignItems={isDesktop ? 'flex' : 'flex-start'}
        direction="column"
        justifyContent="left"
        spacing={3}
      >
        <Box m={3}></Box>

        <Typography variant="h4" className={classes.headerText}>
          <img
            src="https://i.imgur.com/c9O9LCK.png"
            style={{ marginBottom: -2, display: 'inline-flex' }}
            width="52px"
            height="35px"
          ></img>{' '}
          Terms And Conditions
        </Typography>
        <p>
          <strong>Payment: Terms &amp; Conditions</strong>
        </p>
        <p>
          Reservation prices and ticket prices include tax, unless otherwise
          specified.
        </p>
        <ul>
          <li>
            All incidental expenses, gratuities, registrations, fees or charges,
            and other expenses, including, but not limited to, food, beverage,
            and merchandise are the sole responsibility of the guest.
          </li>
          <li>
            Guest must arrive by time specified on the reservation or ticket for
            guaranteed entry.
          </li>
          <li>
            Guest must bring a printed or digital copy of the reservation
            confirmation with scan-able ticket(s), valid photo I.D., and the
            credit card used to purchase the ticket(s).
          </li>
          <li>
            Guest must be of legal drinking age with a valid government issued
            ID to be presented at the door.
          </li>
          <li>
            Tickets / Reservations are non-transferrable or available for
            resale. Lost or stolen tickets will not be replaced.
          </li>
          <li>
            Artists, programming and other event details are subject to change
            without notice.
          </li>
          <li>All Ticket / Table sales are final and nonrefundable.</li>
          <li>
            Organizer reserves the right to deny access into venue due to
            capacity restrictions.
          </li>
          <li>Appropriate dress is required and strictly enforced.</li>
          <li>
            Disregard or violation of these Terms and Conditions will
            automatically invalidate ticket(s) purchased and guest will not
            receive a refund of any kind.
          </li>
          <li>
            Management reserves all rights, including, without limitation, the
            right to cancel, suspend or modify the event at its sole discretion.
          </li>
          <li>
            This offer is subject to all applicable federal, state and local
            laws, and regulations.
          </li>
          <li>
            Tickets are non-refundable in all cases, including governmental acts
            and acts of God, the event organizer may, in it&rsquo;s sole and
            absolute direction elect to either (a) postpone the event for a
            future date and/or (b) offer Purchaser a comparable &lsquo;make
            good.&rsquo;
          </li>
        </ul>
        <ol>
          <li>
            <strong> Introduction and Key Terms</strong>
          </li>
        </ol>
        <p>
          <strong>THIS AGREEMENT AND THESE TERMS AND CONDITIONS</strong>
          <br />
          <br /> This Agreement is between Makanak LLC
          (&ldquo;Makanak&rdquo;) and you (&ldquo;User&rdquo;, &ldquo;you&rdquo;,
          &ldquo;your&rdquo;). Whether you are using the Makanak Services to
          purchase Tickets (&ldquo;Buyer&rdquo;) or sell Tickets
          (&ldquo;Seller&rdquo;), this Agreement governs your access to and use
          of the Makanak Services.
        </p>
        <p>Capitalised words in this Agreement are defined in paragraph 15.</p>
        <p>
          <strong>Please note:</strong>
        </p>
        <ul>
          <li>
            Makanak does not routinely screen the content of any Listing or its
            suitability for individual Users;
          </li>
          <li>Makanak does not own or set the Sale Price of any Ticket;</li>
          <li>
            Makanak does not endorse any Users or any Listings and reserves the
            right to remove a Listing it deems inappropriate from the Makanak
            website (&ldquo;Website&rdquo;) at any time and for any reason;
          </li>
          <li>
            Makanak does not confirm the identity or suitability of Users; Users
            interact with and contact one another at their own risk;
          </li>
          <li>Makanak is not an event organiser or promoter;</li>
          <li>
            Makanak provides an online platform to facilitate the sale and purchase
            of Tickets; and
          </li>
          <li>
            Makanak is not party to the agreement between a Buyer and Seller for the
            sale and purchase of Tickets using the Makanak Services. Therefore, Makanak
            is not responsible for the Listing, the Event or the Ticket(s)
            purchased. Delivery of Tickets is the Seller&rsquo;s responsibility
            and Makanak is unable to guarantee that Sellers will complete
            Transactions in every single case. <br /> Legally Binding Agreement:
            This Agreement is a legally binding contract and you should read the
            following terms and conditions, the{' '}
            <a href="https://www.technoandchill.com/terms-and-conditions/">
              Makanak Terms of Use
            </a>{' '}
            and{' '}
            <a href="https://www.technoandchill.com/privacy-policy/">
              Privacy Policy
            </a>{' '}
            carefully before you use the Makanak Services. By using the Makanak
            Services, you acknowledge that you have read, understood and agree
            to be bound by the terms and conditions of this Agreement. If you
            don&rsquo;t agree with this Agreement, or cannot comply with it,
            then you must not use the Makanak Services.
            <br />
            <br /> Changes to this Agreement: We may make changes to these Terms
            at any time. Any changes we make will be effective immediately when
            we post a revised version of these Terms on the Website. Your
            continued use of the Makanak Services will constitute your acceptance of
            the new Agreement so please make sure you read any such notification
            carefully. If you do not agree with the terms of this Agreement or
            any revised version of this Agreement, you must immediately stop
            using the Makanak Services, including removing any Listings that you
            have posted.
          </li>
        </ul>
        <p>
          <strong>About Makanak : </strong>
        </p>
        <p>Makanak LLC is a company registered in Delaware, USA.</p>
        <p>
          Contacting Makanak: If you have any questions or want any further
          information regarding this Agreement, please contact&nbsp;
          <a href="mailto:info@technoandchill.com">info@technoandchill.com</a>.
        </p>
        <p>
          Contacting You: If we need to contact you, we will do so using the
          telephone number or email address that you have provided with your
          Account.
        </p>
        <p>
          While some of the events listed on the Website may appeal to children,
          the Website is not targeted at children under the age of 18, and they
          are not permitted to use the Website. We strongly encourage all
          parents and guardians to monitor Internet use by their children. If
          you use the Website, you affirm you are at least 18 years old.
        </p>
        <ol>
          <li>
            <strong> Registration, Your Account and User Content</strong>
          </li>
        </ol>
        <p>
          <strong>REGISTRATION</strong>
        </p>
        <p>
          Registration: In order to set up a Listing or buy Tickets using the
          Makanak Services you must:
        </p>
        <p>
          be at least 18 years old, or the legal age of majority in the country
          where you reside, and able to enter into legally binding contracts;
          and&nbsp;provide your correct full name (or business name, if you are
          a business) and email address (all your details must be kept up to
          date at all times), further details can be added at a later date if
          desired. You may not include the name of another person with the
          intent to impersonate that person, or be offensive, vulgar or obscene.
        </p>
        <p>
          You can also register to join by logging into your account via a
          Third-Party Social Media Site. Please note that by connecting to the
          Website and the Makanak Services using a Third-Party Social Media Site,
          you are giving Makanak permission to (i) access and use your information
          from that service as permitted by that service; and (ii) to store your
          authorisation access to that Third-Party Social Media Site.
        </p>
        <p>
          <strong>YOUR ACCOUNT</strong>
        </p>
        <p>
          You shall not permit anyone other than you to use your Account. You
          may not transfer or sell access to your account. We will not be liable
          for any harm related to disclosure of your username or password or the
          use by anyone else of your username or password. You may not use
          another user&rsquo;s account without that user&rsquo;s permission. You
          will immediately notify us in writing if you discover any unauthorized
          use of your account or other account-related security breach. We may
          require you to change your username and/or password if we believe your
          account is no longer secure or if we receive a complaint that your
          username violates someone else&rsquo;s rights. You will have no
          ownership in your account or your username. We may refuse
          registration, cancel an account or deny access to the Website for any
          reason.
        </p>
        <p>
          Companies: If you are accepting this Agreement on behalf of a company
          or other legal entity, you represent and warrant that you have the
          authority to bind that company or other legal entity (and
          &ldquo;you&rdquo; refers and applies to that company or other legal
          entity).
        </p>
        <p>
          Financial Checks: You permit Makanak and/or a third party contractor to
          carry out financial checks for the purposes of verifying your
          financial status (which does not include a credit check but does
          include a fraud check) and you agree that Makanak may pass your Account
          and payment card details to such third party contractor to carry out
          such checks.
        </p>
        <p>
          Privacy Policy: Please refer to the&nbsp;
          <a href="https://www.technoandchill.com/privacy-policy/">
            Makanak Privacy Policy and the Cookie Policy
          </a>
          &nbsp;for more details on how we use and protect your Makanak Account
          Information.
        </p>
        <p>
          International: Your Account can be used to access and use the Makanak
          Services on the Website and any international platform operated by Makanak
          and/or to purchase tickets to Events taking place in various
          countries. However, please be aware that buying and selling Tickets
          for Events in such countries may be subject to different laws, rules,
          regulations and a different Privacy Policy.
        </p>
        <p>
          <strong>USER CONTENT</strong>
        </p>
        <p>
          User Content: We may, in our sole discretion, permit Users to post,
          upload, publish, submit or transmit profile information, comments,
          questions, Feedback, images and other content or information
          (&ldquo;User Content&rdquo;). User Content shall include all
          information posted, transmitted or otherwise made available by Users
          on the Makanak Services in connection with the creation or booking of
          Listings.
        </p>
        <p>
          User Content Warranties: In relation to your User Content, you
          represent and warrant that:
        </p>
        <p>
          you have the written consent of each and every identifiable natural
          person in the User Content to use such person&rsquo;s name or likeness
          in the manner contemplated by the Makanak Services and this Agreement, and
          each such person has released you from any liability that may arise in
          relation to such use; you have the written consent of the photographer
          or other owner of any images included in your User Content to use such
          pictures in the manner contemplated by the Makanak Services and this
          Agreement;
        </p>
        <p>
          Makanak&rsquo;s use your User Content as contemplated by this Agreement
          and the Makanak Services will not breach any law or infringe any rights of
          any third party, including but not limited to any intellectual
          property rights and privacy rights ; your User Content is not
          unlawful, harmful, threatening, abusive, harassing, tortious,
          defamatory, vulgar, obscene, libellous, invasive of another&rsquo;s
          privacy, hateful or racially, ethnically or otherwise objectionable;
        </p>
        <p>
          Makanak may exercise the rights to your User Content granted under this
          Agreement without liability for payment of any fees or royalties
          payable under any collective bargaining agreement or otherwise; and to
          the best of your knowledge, all your User Content and any other
          information that you provide to us is truthful and accurate.
        </p>
        <p>
          User Content Licence Grant: By posting any User Content on the Makanak
          Services, you expressly grant, and you represent and warrant that you
          have all rights, power and authority necessary to grant, to Makanak a
          royalty-free, sublicensable, transferable, perpetual, irrevocable,
          non-exclusive, worldwide licence to use, reproduce, modify, publish,
          list information regarding, edit, translate, distribute, syndicate,
          publicly perform, publicly display, and make derivative works of all
          such User Content and your name, voice, and/or likeness, and any third
          party name, voice, and/or likeness, as contained in your User Content,
          in whole or in part, and in any form, media or technology, whether now
          known or hereafter developed, for use in connection with the Makanak
          Services and Makanak&rsquo;s (and its successors&rsquo; and
          affiliates&rsquo;) business, including without limitation for
          promoting and redistributing part or all of the Makanak Services (and
          derivative works thereof) in any media formats and through any media
          channels. You also hereby grant each User of the Makanak Services a
          non-exclusive licence to access your User Content through the Makanak
          Services, and to use, reproduce, distribute, display and perform such
          User Content as permitted through the functionality of the Makanak
          Services and under this Agreement.
        </p>
        <p>
          Makanak and User Content: Makanak takes no responsibility and assumes no
          liability for any User Content that you or any other User or third
          party posts or sends using the Makanak Services. You shall be solely
          responsible for your User Content and the consequences of posting or
          publishing it, and you agree that we are only acting as a passive
          conduit for your online distribution and publication of your User
          Content. You understand and agree that you may be exposed to User
          Content that is inaccurate, objectionable, inappropriate for children,
          or otherwise unsuited to your purpose, and you agree that Makanak shall
          not be liable for any damages you incur (or allege to incur) as a
          result of User Content.
        </p>
        <p>
          For U.S. Residents: Under the Digital Millennium Copyright Act of 1998
          (the &ldquo;DMCA&rdquo;) if you believe in good faith that any content
          on the Website infringes your copyright, you may send us a notice
          requesting that the content be removed. The notice must include: (a)
          your (or your agent&rsquo;s) physical or electronic signature; (b)
          identification of the copyrighted work on our Website that is claimed
          to have been infringed (or a representative list if multiple
          copyrighted works are included in one notification); (c)
          identification of the content that is claimed to be infringing or the
          subject of infringing activity, including information reasonably
          sufficient to allow us to locate the content on the Website; (d) your
          name, address, telephone number and email address (if available); (e)
          a statement that you have a good faith belief that use of the content
          in the manner complained of is not authorized by you or your agent or
          the law; and (f) a statement that the information in the notification
          is accurate and, under penalty of perjury, that you or your agent is
          authorized to act on behalf of the copyright owner. If you believe in
          good faith that a notice of copyright infringement has been wrongly
          filed against you, you may send us a counter-notice. You may read more
          information about the DMCA at http://www.loc.gov/copyright. Notices
          and counter-notices should be sent to inf@themrblack.com. There can be
          penalties for false claims under the DMCA. We suggest that you consult
          your legal advisor before filing a notice or counter-notice. It is our
          policy to terminate, in appropriate circumstances, the access rights
          to the Website of repeat infringers.
        </p>
        <ol>
          <li>
            <strong>
              {' '}
              Terms and Conditions &ndash; Listing and Selling Tickets
            </strong>
          </li>
        </ol>
        <p>
          <strong>CREATING A LISTING</strong>
        </p>
        <p>
          Listing Responsibility of Seller: By creating a Listing using the Makanak
          Services, Sellers are offering to sell or distribute, for the Sale
          Price, where applicable, Ticket(s) for Buyers to attend an Event. It
          is the Seller&rsquo;s responsibility to make any Event or Ticket
          restrictions clear to the Buyer and the Seller is solely responsible
          for all the Listings that they post on the Website. Any changes to
          Listings, Events and Tickets must be made clear to Users and Buyers as
          soon as possible.
        </p>
        <p>
          Listing Representation: A Listing is a legal representation made by
          the Seller about the details relevant to the Ticket and Event, and
          their accuracy.
        </p>
        <p>
          Service Charge Preference: The Seller shall elect whether the Service
          Charge shall be paid by the Buyer, in addition to the Sale Price, or
          included in the Sale Price and paid by the Seller.
        </p>
        <p>
          Listing Description: The Seller must provide all the Event information
          in the Listing, including:
        </p>
        <ul>
          <li>the location of the Event;</li>
          <li>the name of the Event and performer(s) (if relevant);</li>
          <li>the date of the Event;</li>
          <li>the name and address of the Venue;</li>
          <li>
            the type of Ticket (e.g. seated, standing, timed entry, etc.);
          </li>
          <li>the Sale Price of each Ticket;</li>
          <li>
            whether or not the Service Charge is included in the Sale Price or
            not;
          </li>
          <li>
            whether or not a portion of the Sale Price will be donated to a
            particular cause or charity and the relevant associated details;
          </li>
          <li>the refund policy, if any;</li>
          <li>
            any additional information, special rules or requirements which
            apply to the Event and/or the Tickets (e.g., age restrictions,
            accessibility restrictions, restricted view, family section, alcohol
            restrictions, etc.);
          </li>
          <li>
            if applicable, information on how and when the Buyer will be
            informed about whether the Seller has approved the Transaction or
            not;
          </li>
          <li>
            any details about the Buyer that the Seller will be collecting
            during the Transaction process and details as to how such data will
            be processed, including any Buyer User Data to be collected; and any
            other significant information relating to the Event and/or Ticket(s)
            that may reasonably affect a Buyer&rsquo;s decision about whether to
            purchase or apply for a Ticket or not, including any information
            required by law. In Ontario and any other jurisdiction where
            required by law, this must include a description of any other
            distribution methods or sales channels for the Tickets to the Event
            that you will make available for sale, including any sale that will
            occur before tickets are made available for sale to the general
            public (e.g. pre-sales).
          </li>
        </ul>
        <p>
          Compliance: When creating a Listing, the Seller must comply with all
          applicable laws and regulations and the terms of this Agreement. If
          you are a Seller trading as a business, you must identify yourself as
          such by using a business Account. Sellers trading as a business are
          responsible for complying with all applicable laws and regulations in
          relation to their obligations as a &ldquo;trader&rdquo;. Makanak assumes
          no responsibility for a Seller&rsquo;s compliance with applicable
          laws, rules or regulations.
        </p>
        <p>
          Seller Warranties: The Seller represents and warrants to and for the
          benefit of Makanak, that any Listings that he/she creates:
        </p>
        <ul>
          <li>
            relate only to Tickets and Events over which that Seller has
            control, either directly or indirectly, and for which the Seller is
            permitted to sell or distribute Tickets, and that the Seller is in
            possession of the Tickets at the time of the Listing;
          </li>
          <li>
            include accurate and transparent Ticket prices and information
            relating to all additional charges which will be incurred by Buyers;
          </li>
          <li>
            if booked, will be honoured, unless otherwise agreed with the Buyer;
          </li>
          <li>
            do not infringe any third party rights (including intellectual
            property rights);
          </li>
          <li>
            are not being listed by the Seller serving in the capacity of a
            broker, rental agent or listing agent for a third party;
          </li>
          <li>
            do not comprise of the same or substantially the same content as any
            other Listings on the Website and are not published on any other
            website;
          </li>
          <li>
            do not in any way contain fraudulent or inaccurate or misleading
            information; Please note: selling stolen or counterfeit property is
            a crime and Makanak will report such activity to the relevant
            authorities and permanently ban anyone found selling stolen or
            counterfeit property from using the Makanak Services.
          </li>
          <li>are not defamatory, abusive, threatening or obscene;</li>
          <li>
            are not unethical or discriminatory on the basis of age, gender,
            race, marital status, nationality or cultural, religious or personal
            beliefs, disability, sexual orientation, political opinion or
            otherwise;
          </li>
          <li>
            do not attempt to solicit any Users to use a third party service
            that competes with the Makanak Services;
          </li>
          <li>
            do not breach any applicable law or regulation, including those
            associated with making charity donations;
          </li>
          <li>
            do not contain any virus or other programme that interferes with or
            seeks to interfere with any computer system or data;
          </li>
          <li>
            cannot be construed to be associated with any terrorist
            organisations; and
          </li>
          <li>
            do not contain political campaigning, commercial solicitation, chain
            letters, mass mailings or any form of &ldquo;spam&rdquo;.
          </li>
        </ul>
        <p>
          VAT: The Seller is responsible for ensuring that the Sale Price
          includes all taxes required on Listings, including VAT. If in doubt,
          the Seller should seek the advice of a tax expert to determine which
          taxes need to be collected from the Buyer.
        </p>
        <p>
          Insurance: The Seller is solely responsible for any insurance that may
          be required in relation to the Listing and the Event.
        </p>
        <p>
          Event Cancellation or Alteration: If an Event is cancelled,
          re-scheduled or amended in any way, this must be dealt with by the
          Seller and communicated clearly to Buyers. The Listing must be updated
          or removed by the Seller and if it is not, we reserve the right to
          remove the Listing from the Makanak Services.
        </p>
        <p>
          No Makanak Guarantee: Makanak does not guarantee that Listings made via the
          Makanak Services will be published and active within a certain time of the
          Seller posting a Listing or that it will appear in a particular order
          in search results or that the Ticket(s) will sell. If a Ticket does
          not sell, Makanak accepts no responsibility.
        </p>
        <p>
          <strong>SALE OF TICKETS</strong>
        </p>
        <p>
          Payment Processing Preferences: When a Buyer completes the sales
          process by entering their payment details and clicking on &ldquo;pay
          now&rdquo; for paid for Events or &ldquo;register&rdquo; for free
          Events, the parties shall become contractually bound to one another to
          complete the Transaction. An Order Confirmation will be generated and
          sent to the Buyer once the Buyer completed the payment process. Failed
          Delivery: Order Confirmations are automatically generated following
          the Buyer&rsquo;s completion of the payment process. Any notification
          of a failed delivery that the Seller receives from a Buyer must be
          dealt with by the Seller as soon as possible and, in any event, in
          advance of the date of the relevant Event.
        </p>
        <p>
          Disputed Events: If a Buyer disputes any element of an Event or a
          Transaction Value, such dispute shall be between the Buyer and the
          Seller and Makanak shall have no involvement.
        </p>
        <p>
          <strong>STRIPE PAYMENTS</strong>
        </p>
        <p>
          Stripe Payments: By setting up a Stripe Connect account via the&nbsp;
          <a href="https://stripe.com/">Stripe website</a>, Sellers can
          integrate their Account with Stripe to process payments for their
          Events using their own merchant account.
        </p>
        <p>
          Payments: Immediately upon a Transaction being completed by the Buyer,
          the total value of the Sale Price(s) will be paid directly into the
          Seller&rsquo;s Stripe account and the Service Charge shall be paid to
          Makanak.
        </p>
        <p>
          Refunds: In the event that a Buyer requests a refund of the
          Transaction Value, this shall be granted at the sole discretion of the
          Seller. Makanak may initiate a refund request on behalf of a Buyer but is
          under no obligation to do so.
        </p>
        <p>
          <strong>PAYMENTS</strong>
        </p>
        <p>
          Applicable Taxes: Sellers agree to pay all applicable taxes or charges
          imposed by any government entity in connection with their Listings and
          associated Transactions.
        </p>
        <p>
          Chargebacks: Any credit card chargebacks or other transaction
          reversals initiated against Makanak or its affiliates for any reason
          (except to the extent they are caused solely by Makanak&rsquo;s negligence
          or wilful misconduct) with respect to a Seller&rsquo;s Listing and all
          related credit card association, payment processing and other fees and
          expenses incurred by Makanak or its affiliates in connection with such
          chargebacks shall ultimately be the responsibility of the Seller and
          the Seller agrees to promptly and fully reimburse Makanak for such amounts
          on demand. Makanak reserves the right to withhold at least 10% of a
          Seller&rsquo;s proceeds, in its sole discretion, to cover the cost of
          any such chargebacks that may arise.
          <br />
          <br /> Cancellation and Refunds: The cancellation and refund policy
          for a Ticket is at the Seller&rsquo;s discretion and will be set out
          in the Listing description. If no such policy is set out by the
          Seller, Buyers may cancel a booking and receive a refund of the Sale
          Price, if requested 24 hours or more before the start time of the
          Event.
        </p>
        <p>
          <strong>USE AND COLLECTION OF DATA BY SELLERS</strong>
        </p>
        <p>
          Makanak Account Information: The Seller may be given access to
          Buyers&rsquo; Makanak Account Information where necessary to fulfil
          Tickets. Makanak does not provide any warranties as to the accuracy,
          relevance or fitness for any particular purpose in relation to the Makanak
          Account Information. The Seller is the Data Controller (as defined in
          EU Data Protection Legislation) in respect of the Makanak Account
          Information of Buyers, from the point at which it is passed and/or
          made available to the Seller and the Seller must comply with Data
          Protection Legislation when processing Makanak Account Information of
          Buyers.
        </p>
        <p>
          Where Makanak Account Information of Buyers is made available, it is
          confidential and must only be disclosed to authorised persons using
          appropriate security measures. The Seller must keep Buyers&rsquo; Makanak
          Account Information secure and must take reasonable steps to protect
          it from misuse, loss and from unauthorised access, modification or
          disclosure.
        </p>
        <p>
          Buyer User Data: The Seller is the Data Controller of all Buyer User
          Data and must comply with Data Protection Legislation when processing
          Buyer User Data including, but not limited to, providing Buyers with
          information about how their data will be used, and collecting
          appropriate permission from Buyers for any uses made of their User
          Data. The Seller must keep Buyer User Data secure and must take
          reasonable steps to protect it from misuse, loss and from unauthorised
          access, modification or disclosure. Makanak has no control over or input
          into Buyer User Data, nor does it have responsibility for or ownership
          of any Buyer User Data collected and solely acts as a processor in
          relation to the same. Notwithstanding this, Makanak will take appropriate
          measures to protect Buyer User Data, when held within the Makanak
          Services, from loss, misuse and unauthorised access, disclosure,
          alteration and destruction, taking into account the risks involved in
          the processing and the nature of the Buyer User Data. Makanak will only
          process Buyer User Data on the instructions of the Seller.
        </p>
        <p>
          Prohibited Data Classes: Sellers must not use the Makanak Services to
          collect the following types of data: payment card or bank details,
          details of racial or ethnic origin, political opinions, details of
          religious beliefs, trade union membership details, physical or mental
          health information, details of sexual life, details of the commission
          or alleged commission of any offence, and personal data of or other
          information relating to children under the age of 18. If the Seller
          collects such information, Makanak is entitled, in its sole discretion, to
          terminate the Agreement for material breach in accordance with
          paragraph 16 of this Agreement.
        </p>
        <p>
          <strong>ENFORCEMENT ACTION AGAINST SELLERS</strong>
        </p>
        <p>
          Cancellation: Makanak reserves the right to cancel a Listing and/or a
          Transaction and/or a Seller&rsquo;s Account in its sole discretion,
          for any reason and at any time.
        </p>
        <ol>
          <li>
            <strong> Terms and Conditions &ndash; Buying Tickets</strong>
          </li>
        </ol>
        <p>
          <strong>PRICE AND PAYMENT</strong>
        </p>
        <p>
          Sale Price: The Seller sets the Sale Price of the Ticket and can
          change the Sale Price at any time prior to a Transaction taking place.
        </p>
        <p>
          Fees/Charges: When a Buyer selects one or more Tickets for purchase,
          any additional charges and/or fees associated with the Ticket and
          Transaction shall be made clear by the Seller, which shall include:
        </p>
        <ul>
          <li>the Sale Price;</li>
          <li>the Service Charge (if applicable); and</li>
          <li>any other charges included by the Seller.</li>
        </ul>
        <p>
          Check Your Order: As a Buyer you must ensure that you have read the
          full Listing details and are happy with your Ticket selection prior to
          purchase. Makanak will not rectify any issues that arise and Sellers will
          only do so in their sole discretion.
        </p>
        <p>
          Payment Information: The Buyer warrants and represents that s/he is
          authorised to use the selected payment method and that the billing
          information provided is complete, up to date and accurate.
        </p>
        <p>
          Approval Settings: Some Listings may require the Seller&rsquo;s
          approval prior to the Buyer being able to purchase Tickets. Such
          Listings shall specify the timeframes within which approval must be
          given by the Seller. If no approval is granted within such timeframes,
          your order will be cancelled and payment will not be taken.
        </p>
        <p>
          Order Confirmation: Upon Buyers completing their purchase, by entering
          their payment details, where necessary, and clicking &ldquo;pay
          now&rdquo; for paid for Events or &ldquo;register&rdquo; for free
          Events, an Order Confirmation shall automatically be issued to the
          Buyer, which is the point at which the Buyer and Seller enter into a
          binding agreement. The Order Confirmation will contain the Ticket with
          which the Buyer will be able to attend the Event. If the Order
          Confirmation is not received, it can be retrieved from the Account
          dashboard, along with the Ticket.
        </p>
        <p>
          Payment: Once the Buyer receives the Order Confirmation, depending on
          which Payment Processing Method the Seller has selected, the Buyer
          agrees that Makanak or a third party payment processor, acting on behalf
          of the Seller, may debit or authorise the amount of the Transaction
          Value from the method of payment provided by the Buyer. If applicable,
          the Service Charge is non-refundable and, unless otherwise agreed by
          the Seller, no refunds or credits will be provided once the Buyer has
          been charged.
        </p>
        <p>
          Other Ticket Terms and Conditions: All Tickets purchased by the Buyer
          are subject to the Ticket, Event and Venue terms and conditions, and
          all applicable laws and regulations.
        </p>
        <p>
          After-Sales Queries: If the Buyer has any questions or concerns
          following the purchase of a Ticket, the Buyer should contact the
          Seller directly and should not contact or attempt to contact Makanak.
        </p>
        <p>
          Donations: Sellers may pledge to donate a portion of the Sale Price to
          a particular cause or charity. Makanak does not control or accept any
          responsibility or liability for any donations pledged by the Seller.
          Makanak does not investigate or otherwise ensure the factual accuracy of
          any donation claims made by Sellers in Listings.
        </p>
        <p>
          <strong>EJECTION:</strong>
        </p>
        <p>
          The Venue always reserves the right to refuse admission to, or remove,
          anyone who acts with disorderly conduct or otherwise violates any rule
          of the Venue (e.g., refusing entry to those carrying alcohol,
          recording devices, and/or noise/chemical irritants).
        </p>
        <p>
          To enforce this policy, a Venue may subject you and your personal
          belongings to a search when you enter a Venue. If you do not agree to
          a search, then you are not eligible for a refund.
        </p>
        <p>
          <strong>GUEST REGISTRATION AND COMMUNICATION:</strong>
        </p>
        <ul>
          <li>
            Our registration process is designed to give us just enough
            information to ensure that we can reach you and any of your
            additional guests easily and enable you purchase tickets securely.
          </li>
          <li>
            Please see our privacy policy to learn about all the ways we protect
            your privacy and keep your data safe.
          </li>
          <li>
            All activity on your account is your responsibility. Do not use
            another person&rsquo;s account or contact information without
            explicit permission. If you see any unauthorized activity on your
            account or have been sent communications from us as a result of
            actions taken by someone other than you without your permission,
            please contact us immediately at info@technoandchill.com.
          </li>
          <li>
            All correspondence to and from Makanak is handled first by email and
            secondly via SMS satisfies any legal requirement that our
            communication with you be in writing. SMS charges may apply.
          </li>
        </ul>
        <p>
          <strong>CONDUCT:</strong>
        </p>
        <p>
          Our Site requires reasonable common-sense conduct from our users to
          keep running smoothly. The basics:
        </p>
        <ul>
          <li>
            You have to be real&mdash;not a robot or part of any commercial
            service looking to use Makanak for resale of tickets on the secondary
            market. This includes putting unreasonable load on our system or
            requesting more than 1,500 pages of our Site during any 24-hour
            period.
          </li>
          <li>
            You have to be who you say you are. Your IP should not be concealed
            and your username should not be used to impersonate another
            individual. Usernames should not be obscene or otherwise offensive.
          </li>
          <li>
            Don&rsquo;t try to hack into the Site to circumvent our security
            measures, including our CAPTCHA, or get unauthorized access to other
            users&rsquo; accounts.
          </li>
          <li>
            Don&rsquo;t deploy any malware, spamming, or flooding that would
            interfere with other users&rsquo; experiences on the Site.
          </li>
          <li>
            Don&rsquo;t download, export, reverse engineer, or create derivative
            works of any content on our Site.
          </li>
          <li>
            Don&rsquo;t frame or mirror the Site, or use any spider, robot, or
            automated tool to index, dateline, or reproduce content or user
            behavior on the Site, unless you have written authorization from Makanak
            to do so.
          </li>
          <li>
            Don&rsquo;t use our Site to transmit or upload material that would
            infringe upon others&rsquo; rights or privacy, intellectual
            property, or publicity.
          </li>
          <li>
            Don&rsquo;t use a password or code (pre-sales or otherwise) to get
            access to the Site if you aren&rsquo;t the intended recipient of
            that password or code.
          </li>
          <li>
            Don&rsquo;t make any copy or counterfeit reproduction of our
            tickets.
          </li>
          <li>
            Don&rsquo;t seek a dispute or &ldquo;chargeback&rdquo; from your
            credit card company for a valid purchase. If you do, your tickets
            may be cancelled, and in the future we may refuse to honor the
            credit card and/or block the accounts of people who use that card.
          </li>
        </ul>
        <p>
          <strong>CANCELLATION AND REFUNDS</strong>
        </p>
        <p>
          Cancellation: The cancellation and refund policy for a Ticket is at
          the Seller&rsquo;s discretion and will be set out in the Listing
          description. If no such policy is set out by the Seller, Buyers may
          cancel a booking and receive a refund of the Sale Price, if requested
          24 hours or more before the start time of the Event.
        </p>
        <p>
          Refunds: If a Buyer cancels a booking within the timeframe set out by
          the Seller, if any, the Buyer may be refunded the Sale Price for such
          booking within a commercially reasonable amount of time, typically one
          week after a refund is agreed upon. Service Charges, if applicable to
          the Buyer, are non-refundable.
        </p>
        <p>
          The Seller and the Buyer must arrange a refund between themselves and
          Makanak shall have no responsibility or liability for any such refunds.
        </p>
        <p>
          Disputes: If a Seller fails to fulfil its obligations in relation to a
          Transaction, subject to the refund options a Seller has set out in the
          Listing, Buyers will have 48 hours from the start time of the Event to
          request a refund. If a Buyer requests a refund within this timeframe,
          Makanak will promptly notify the Seller. The Seller will have 48 hours to
          rebut such dispute, resulting in one of two scenarios: (i) if the
          Seller does not rebut such dispute, a full refund of the Sale Price
          will be given to the Buyer by the Seller; or (ii) if the Seller does
          rebut such dispute, the two parties must contact one another to
          resolve the dispute and submit the confirmation of the resolution to
          Makanak within 30 days. If no confirmation of resolution is received by
          Makanak within 30 days, Makanak will resolve the dispute in its sole and
          absolute discretion, which may include a decision to refund the Buyer
          and pursue the Seller for the refunded amount. It is the
          Seller&rsquo;s responsibility to provide proof that a Buyer was in
          attendance at an Event.
        </p>
        <p>
          <strong>ENFORCEMENT ACTION AGAINST BUYERS</strong>
        </p>
        <p>
          Cancelling Transactions: Makanak reserves the right to cancel a
          Buyer&rsquo;s Transaction in any of the following circumstances if:
        </p>
        <ul>
          <li>
            Makanak suspects any fraudulent, illegal or unethical activity relating
            to one or more Transactions; and/or
          </li>
          <li>
            Makanak suspects any unauthorised use of a Buyer&rsquo;s Account and/or
            of the Makanak Services; and/or
          </li>
          <li>a breach of the terms of this Agreement occurs.</li>
        </ul>
        <ol>
          <li>
            <strong> General Terms and Conditions</strong>
          </li>
        </ol>
        <p>
          <strong>DEFINITIONS AND INTERPRETATION</strong>
        </p>
        <p>In this Agreement:</p>
        <ul>
          <li>
            &ldquo;Account&rdquo; means the account set up by you to use the Makanak
            Services.
          </li>
          <li>
            &ldquo;Agreement&rdquo; means these terms and conditions, the{' '}
            <a href="https://www.technoandchill.com/terms-and-conditions">
              Terms of Use
            </a>
            , the{' '}
            <a href="https://www.technoandchill.com/privacy-policy">
              Makanak Privacy Policy
            </a>
            , and any other instructions or terms and conditions made available
            to you.
          </li>
          <li>
            &ldquo;Buyer User Data&rdquo; means the data collected pursuant to
            the bespoke questions, if any, that a Seller is able to include in a
            Listing in order to collect personal data from Buyers during the
            checkout process, as well as personal data the Buyer is able to
            upload into the Makanak Services.
          </li>
          <li>
            &ldquo;Data Protection Legislation&rdquo; means all data and privacy
            laws, rules and regulations to the extent applicable from time to
            time, including without limitation the following legislation
            (&ldquo;EU Data Protection Legislation&rdquo;): (a) national laws
            implementing the Data Protection Directive (95/46/EC) and the
            Directive on Privacy and Electronic Communications (2002/58/EC); and
            (b) any other applicable national privacy law.
          </li>
          <li>
            &ldquo;Event&rdquo; means any event or ticketed activity detailed in
            a Listing for which Tickets may be issued, bought or sold via the
            Makanak Services.
          </li>
          <li>
            &ldquo;Feedback&rdquo; means feedback, comments and suggestions for
            improvements to the Makanak Services posted by Users.
          </li>
          <li>
            &ldquo;Force Majeure&rdquo; means any cause beyond Makanak&rsquo;s
            control including without limitation, acts of God, war,
            insurrection, riot, civil disturbances, acts of terrorism, fire,
            explosion, flood, epidemic, theft of essential equipment, malicious
            damage, strike, lock out, weather, third party injunction, national
            defence requirements, acts or regulations of national or local
            governments.
          </li>
          <li>
            &ldquo;Listing&rdquo; means the Event and Ticket listing(s) created
            by a Seller, for which Tickets are available for sale or
            distribution and which includes the digital content created for that
            Event.
          </li>
          <li>
            &ldquo;Order Confirmation&rdquo; means the written notice confirming
            a Transaction, issued to the Buyer automatically once the booking
            process has been completed, at which point a legally binding
            agreement in respect of the Transaction between that Buyer and that
            Seller is formed.
          </li>
          <li>
            &ldquo;Payment Processing Method&rdquo; means one of the two methods
            of payment processing, either Paymob Payments or Stripe Payments,
            when selling Tickets using the Makanak Services. The default option is
            set to Makanak Payments and Sellers can choose to integrate Stripe
            Payments into their Account to manage their own payment process.
          </li>
          <li>
            &ldquo;Sale Price&rdquo; means the price at which a Seller offers a
            Ticket for sale using the Makanak Services, which can be nil (i.e. some
            Events may be free) and which includes any and all additional costs
            and charges which may be set by the Seller.
          </li>
          <li>
            &ldquo;Service Charge&rdquo; means the service charge payable to Makanak
            for the provision of the Makanak Services, which at the Seller&rsquo;s
            election may either be charged to the Seller (in which case such
            amount will be collected by Makanak prior to settlement of sales
            proceeds) or charged to the Buyer (in which case such amount will
            show in the subtotal on the checkout page and will be paid by the
            Buyer on top of the Sale Price as part of the total Transaction
            Value) for providing the platform, which is inclusive of VAT at the
            then-current rate.
          </li>
          <li>
            &ldquo;Stripe Payments&rdquo; means the integration of Stripe by a
            Seller to process payments made by a Buyer; payments shall be made
            directly to the Seller and all queries and disputes which relate to
            Stripe Payments shall be governed by Stripe.
          </li>
          <li>
            &ldquo;Third-Party Social Media Site&rdquo; means third party
            websites and applications that enable users to create and share
            content or to participate in social networking, including, but not
            limited to, Facebook, Twitter and Google Chrome.
          </li>
          <li>
            &ldquo;Ticket(s)&rdquo; means an electronic ticket or other type of
            evidence of the right to occupy space at or to attend an Event.
          </li>
          <li>
            &ldquo;Transaction&rdquo; means a transaction undertaken via the Makanak
            Services where the Buyer agrees to buy and the Seller agrees to sell
            one or more Tickets at the listed Sale Price(s).
          </li>
          <li>
            &ldquo;Transaction Value&rdquo; means the total amount payable by a
            Buyer pursuant to a Transaction, which includes all the costs and
            fees set out in paragraph 12.2.
          </li>
          <li>
            &ldquo;Makanak Account Information&rdquo; means the personal data you
            upload when registering your Account.
          </li>
          <li>
            &ldquo;Makanak Payments&rdquo; means the Payment Processing Method
            option which requires no set up by the Seller and through which the
            Transaction Value gets paid to Makanak who will remit the net Sale Price
            amount(s) to the Seller 48 to 72 hours after the end of the Event,
            subject to the terms of this Agreement.
          </li>
          <li>
            &ldquo;Makanak Services&rdquo; means all of Makanak&rsquo;s online services,
            including but not limited to the Website, the software application
            available for download to a smartphone which contains a modified
            version of the Website and all mobile services, any software
            provided and any related tools and services provided by Makanak.
          </li>
          <li>
            &ldquo;Venue&rdquo; means any site, facility or location where an
            Event is held.
          </li>
          <li>
            &ldquo;Website&rdquo; means any website run or provided by Makanak,
            including any software application available for download to a
            smartphone which contains a modified version of the Website.
          </li>
        </ul>
        <p>
          Interpretation: The terms &ldquo;including&rdquo;,
          &ldquo;include&rdquo;, &ldquo;for example&rdquo; or any similar
          expression shall be construed as illustrative and shall not limit the
          sense of the words, description, definition, phrase or term preceding
          those terms.
        </p>
        <p>
          Headings: The headings used in this Agreement are for reference
          purposes only and do not affect its interpretation.
        </p>
        <p>
          <strong>AGREEMENT TERM AND TERMINATION</strong>
        </p>
        <p>
          Commencement and Term: This Agreement shall commence on the date that
          you register an Account with Makanak or purchase a ticket through Makanak and
          shall continue indefinitely subject to earlier termination by either
          you or Makanak.
        </p>
        <p>
          <strong>
            Termination by Makanak : Makanak may terminate this Agreement if:
          </strong>
        </p>
        <ul>
          <li>
            any abusive or threatening behaviour is reported to Makanak as being
            carried out by you or via your Account;
          </li>
          <li>
            Makanak suspects any unauthorised use of your Account and/or the Makanak
            Services;
          </li>
          <li>
            Makanak suspects any fraudulent activity or other illegal activity
            relating to your Account;
          </li>
          <li>you otherwise breach the terms of this Agreement;</li>
          <li>Makanak is ordered to do so by any legal or regulatory authority;</li>
          <li>
            You are an individual, and you are unable to pay your debts as they
            fall due or are declared bankrupt or if you are a company and you
            become subject to an administration order or make a voluntary
            arrangement or composition with your creditors, or an encumbrancer
            takes possession of or a receiver is appointed over your property or
            assets, or the company is wound up or ceases or threatens to cease
            to carry on business except for the purposes of reconstruction;
            and/or
          </li>
          <li>
            Makanak provides you with 30 days&rsquo; written notice of termination,
            at Makanak&rsquo;s election.
          </li>
        </ul>
        <p>
          <br /> Termination by You: You may cancel your Account at any time via
          the by sending an email to info@themrblack.com. If you are a Seller,
          before cancelling your account, you must cancel any Tickets you have
          sold for Events that have not taken place, notify Buyers and refund
          the Buyers, if you are using Stripe Payments, or instruct Makanak to
          refund Buyers, if using Makanak Payments. Please note that if your Account
          is cancelled, Makanak does not have an obligation to delete or return to
          you any User Content you have posted to the Makanak Services.
        </p>
        <p>
          Consequences of Termination: Termination of this Agreement shall not
          affect either your or Makanak&rsquo;s rights and liabilities accrued prior
          to and including the date of termination and/or any terms intended
          expressly or by implication to survive termination.
        </p>
        <p>
          <strong>LICENSES</strong>
        </p>
        <p>
          User Licence: Subject to your compliance with this Agreement, Makanak
          grants you a limited, non-exclusive, non-transferable licence, to
          access and view content via the Makanak Services for your personal use and
          solely for the purposes expressly permitted by the Makanak Services. This
          license is expressly conditioned on your pre-existing agreement to
          comply with, and your actual compliance with, each of the provisions
          described in this Agreement.
        </p>
        <p>
          User Licence Restrictions: You will not use, copy, adapt, modify,
          prepare derivative works based upon, distribute, license, sublicense,
          sell, transfer, publicly display, publicly perform, transmit,
          broadcast, or otherwise exploit the Makanak Services, except as expressly
          permitted in this Agreement. No licences or rights are granted to you
          by implication or otherwise under any intellectual property rights
          owned or controlled by Makanak or its licensors, except for the licences
          and rights expressly granted in this Agreement.
        </p>
        <p>
          Trademarks: logos as well as the name and any designs found on the
          Website are registered trademarks of Makanak. Makanak&rsquo;s trademarks may
          be used publicly only with our written permission. Except as expressly
          stated in this Agreement, Makanak does not grant any express or implied
          right to you under any of its trademarks, copyrights or other
          proprietary information.
        </p>
        <p>
          <strong>Makanak SERVICE RULES</strong>
        </p>
        <p>
          Rules: In connection with your use of the Makanak Services, you may not
          and you agree that you will not:
        </p>
        <ul>
          <li>
            breach any local, state, provincial, national, or other law or
            regulation, or any order of a court;
          </li>
          <li>
            infringe the rights of any person or entity, including without
            limitation, their intellectual property, privacy, publicity or
            contractual rights;
          </li>
          <li>
            interfere with or damage the Makanak Services, including, without
            limitation, through the use of viruses, bots, Trojan horses, harmful
            code, flood pings, denial-of-service attacks, packet or IP spoofing,
            forged routing or electronic mail address information or similar
            methods or technology;
          </li>
          <li>
            use the Makanak Services in connection with the distribution of
            unsolicited commercial email (&ldquo;spam&rdquo;) or advertisements
            unrelated to the Makanak Services;
          </li>
          <li>
            &ldquo;stalk&rdquo; or harass any other User of the Makanak Services or
            collect or store any personally identifiable information about any
            other User (except as expressly permitted by the Makanak Services);
          </li>
          <li>
            submit, or provide links to, any postings containing material that
            could be considered harmful, obscene, pornographic, sexually
            explicit, indecent, lewd, violent, abusive, profane, insulting,
            threatening, harassing, hateful or otherwise objectionable, includes
            the image or likeness of individuals under 18 years of age,
            encourages or otherwise depicts or glamorizes drug use (including
            alcohol and cigarettes), characterizes violence as acceptable,
            glamorous or desirable, or contains any personal contact information
            or other personal information identifying any third party;
          </li>
          <li>
            submit, or provide links to, any postings containing material that
            harasses, victimizes, degrades, or intimidates an individual or
            group of individuals on the basis of religion, race, ethnicity,
            sexual orientation, gender, age, or disability;
          </li>
          <li>
            register for more than one Account or register for an Account on
            behalf of an individual other than yourself;
          </li>
          <li>
            recruit or otherwise solicit any Seller or other User to join third
            party services or websites that are competitive to Makanak, without Makanak
            prior written approval;
          </li>
          <li>
            impersonate any person or entity, or falsify or otherwise
            misrepresent yourself or your affiliation with any person or entity,
            including any employee or representative of our company;
          </li>
          <li>
            use automated scripts to collect information or otherwise interact
            with the Makanak Services;
          </li>
          <li>
            use the Makanak Services to find a Seller or Buyer and then complete the
            transaction independent of the Makanak Services in order to circumvent
            the obligation to pay any fees related to Makanak&rsquo;s provision of
            the Listing;
          </li>
          <li>
            as a Seller, submit any Listing with a false or misleading price
            information, or submit any Listing with a price, service or activity
            that you do not intend to honour;
          </li>
          <li>
            use, display, mirror or frame the Makanak Services, or any individual
            element within the Makanak Services, Makanak&rsquo;s name, any Makanak
            trademark, logo or other proprietary information, or the layout and
            design of any page or form contained on a page, without Makanak&rsquo;s
            express written consent;
          </li>
          <li>
            access, tamper with, or use non-public areas of the Makanak Services,
            Makanak&rsquo;s computer systems, or the technical delivery systems of
            Makanak&rsquo;s providers;
          </li>
          <li>
            attempt to probe, scan, or test the vulnerability of any Makanak system
            or network or breach any security or authentication measures;
          </li>
          <li>
            avoid, bypass, remove, deactivate, impair, descramble or otherwise
            circumvent any technological measure implemented by Makanak or any of
            Makanak&rsquo;s providers or any other third party (including another
            User) to protect the Makanak Services or any content thereon;
          </li>
          <li>
            attempt to decipher, decompile, disassemble or reverse engineer any
            of the software used to provide the Makanak Services;
          </li>
          <li>
            reproduce or scan Tickets in a format or medium different from that
            provided by the Website;
          </li>
          <li>
            use any automated software or computer system to search for,
            reserve, buy or otherwise obtain Tickets, discount codes,
            promotional codes, vouchers, gift cards or any other items available
            on the Website, including sending information from your computer to
            another computer where such software or system is active; and/or
          </li>
          <li>
            advocate, encourage, or assist any third party in doing any of the
            foregoing.
          </li>
        </ul>
        <p>
          Legal Action: Makanak reserves the right to investigate and take
          appropriate legal action against any illegal and/or unauthorised use
          of the Makanak Services. Makanak may involve and cooperate with law
          enforcement authorities in prosecuting Users who breach this
          Agreement, including, without limitation, civil, criminal and
          injunctive redress. You agree that monetary damages may not provide
          Makanak a sufficient remedy and that Makanak may pursue injunctive or other
          relief for your violation of these Terms. If Makanak determines that you
          have violated these Terms or the law, or for any other reason or for
          no reason, Makanak may cancel your Account, delete all your User content
          and prevent you from accessing the Website at any time without notice
          to you. If that happens, you may no longer use the Makanak Services or the
          Website. You will still be bound by your obligations under these
          Terms. You agree that Makanak will not be liable to you or any third party
          for termination of your access to the Website or to your account or
          any related information, and Makanak will not be required to make the
          Website or your account or any related information available to you.
        </p>
        <p>
          <strong>WARRANTIES AND INDEMNITIES</strong>
        </p>
        <p>
          True Information: You represent and warrant that the information that
          you submit to Makanak for your Account and in your use of the Makanak Services
          is true, accurate and complete and you will not use false information,
          including name and email address, in using the Makanak Services.
        </p>
        <p>
          Legal Compliance: You represent and warrant that in using the Makanak
          Services, you shall comply will all applicable local, regional,
          national and international laws, regulations, codes of practice and
          the terms of this Agreement. You further warrant that you are aged 13
          years or over and can enter into legally binding contracts.
        </p>
        <p>
          Tax Indemnity : You agree that Makanak is not in any way responsible for
          the accuracy or suitability of any payment of taxes to any entity on
          your behalf (whether Buyer and/or Seller). If in using the Makanak
          Services you are trading as a business, you hereby indemnify and hold
          Makanak harmless against all liabilities, costs, interests and expenses
          (including reasonable legal fees) incurred by Makanak that arise out of
          any third party or governmental claim that involves, relates to or
          concerns any local, regional or national tax obligation or amounts due
          or owing under any tax regulation, order, law or decree or any dispute
          concerned the tax status of Makanak.
        </p>
        <p>
          General Indemnity: You agree to indemnify and hold Makanak and its parent
          and affiliated companies, suppliers, advertisers and sponsors, and
          each of their officers, directors, employees, agents, legal
          representatives and sub-contractors (the &ldquo;Indemnified
          Parties&rdquo;) harmless against any claim or demand and all
          liabilities, costs and expenses (including reasonable legal fees and
          costs) incurred by an Indemnified Party arising as a result of or in
          connection with your use of the Website, breach of this Agreement
          (including any misuse of the Makanak Services) and/or your violation of
          any law or the rights of a third party. We reserve the right to take
          exclusive control and defense of any claim, and you will cooperate
          fully with us in asserting any available defenses.
        </p>
        <p>
          <strong>DISCLAIMERS</strong>
        </p>
        <p>
          Makanak Disclaimer: Makanak disclaims any and all liability for the acts,
          omissions and conduct of any third-party users, Users, advertisers
          and/or sponsors on the Website, in connection with the Makanak Services or
          otherwise related to your use of the Website and/or the Makanak Services.
        </p>
        <p>
          Makanak WE PROVIDE THE SITE AND THE CONTENT TO YOU &ldquo;AS IS&rdquo; AND
          &ldquo;AS AVAILABLE&rdquo;. WE TRY TO KEEP THE SITE UP, BUG-FREE AND
          SAFE, BUT YOU USE IT AT YOUR OWN RISK. TO THE FULLEST EXTENT
          PERMISSIBLE BY LAW, AND TO THE EXTENT THAT APPLICABLE LAW PERMITS THE
          DISCLAIMER OF EXPRESS OR IMPLIED WARRANTIES, Makanak DISCLAIMS ALL
          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF TITLE,
          NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, OR WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING OR COURSE
          OF PERFORMANCE OR USAGE OF TRADE. Makanak DOES NOT GUARANTEE THAT THE SITE
          WILL ALWAYS BE SAFE, SECURE OR ERROR-FREE OR THAT THE SITE WILL ALWAYS
          FUNCTION WITHOUT DISRUPTIONS, DELAYS OR IMPERFECTIONS. WE ARE NOT
          RESPONSIBLE FOR THE ACTIONS OR INFORMATION OF THIRD PARTIES, AND YOU
          RELEASE US FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT
          OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH
          THIRD PARTIES. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA
          CIVIL CODE &sect;1542, WHICH SAYS: A GENERAL RELEASE DOES NOT EXTEND
          TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS
          OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY
          HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH
          THE DEBTOR.
        </p>
        <p>
          Makanak is not responsible for the products, services, actions or failure
          to act of any venue, performer, promoter or other third party in
          connection with or referenced on the Website. Without limiting the
          foregoing, you may report the misconduct of Users and/or third party
          advertisers, service and/or product providers referenced on or
          included in the Website to Makanak using our&nbsp;
          <a href="https://universesupport.zendesk.com/hc/en-us">
            online help desk
          </a>
          . Makanak may investigate a claim and take appropriate action, in its sole
          discretion.
        </p>
        <p>
          <strong>LIABILITY</strong>
        </p>
        <p>
          Makanak Disclaimer: Due to the nature of Makanak&rsquo;s business, and the
          fact that Makanak cannot control Users&rsquo; acts or omissions,
        </p>
        <p>
          Makanak makes no warranty, and specifically disclaims all liability, in
          relation to any goods or services provided by Sellers.
        </p>
        <p>
          Event Attendance: Under no circumstances shall Makanak be liable for death
          or personal injury suffered by you or your guests arising out of
          attendance at an Event unless directly caused by Makanak&rsquo;s gross
          negligence. Neither shall Makanak be liable for any loss or damage
          sustained to your property or belongings or that of your guests
          attending an Event.
        </p>
        <p>
          <strong>Limitation of Liability:</strong>
        </p>
        <p>
          IN NO EVENT WILL Makanak OR ITS SUPPLIERS, ADVERTISERS AND SPONSORS, BE
          RESPONSIBLE OR LIABLE TO YOU OR ANYONE ELSE FOR, AND YOU HEREBY
          KNOWINGLY AND EXPRESSLY WAIVE ALL RIGHTS TO SEEK, DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES OF
          ANY TYPE (INCLUDING WITHOUT LIMITATION LOSS OF DATA, PROFITS, REVENUE,
          GOODWILL, REPUTATION, LOSS OF ENJOYMENT OR OPPORTUNITY) OTHER THAN OUT
          OF POCKET EXPENSES, AND ANY RIGHTS TO HAVE DAMAGES MULTIPLIED OR
          OTHERWISE INCREASED, ARISING DIRECTLY OR INDIRECTLY OUT OF OR IN
          CONNECTION WITH THE Makanak SERVICES, THE WEBSITE, THE CONTENT, OR ANY
          PRODUCT OR SERVICE PURCHASED THROUGH THE SITE, EVEN IF Makanak HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF WHETHER
          THE CLAIM IS BASED UPON ANY CONTRACT, TORT, OR OTHER LEGAL OR
          EQUITABLE THEORY. WITHOUT LIMITING THE FOREGOING, YOU EXPRESSLY
          ACKNOWLEDGE AND AGREE THAT Makanak WILL HAVE NO LIABILITY OR
          RESPONSIBILITY WHATSOEVER FOR (a) ANY FAILURE OF ANOTHER USER OF THE
          SITE TO CONFORM TO THE CODES OF CONDUCT, (b) PERSONAL INJURY OR
          PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, WHETHER ARISING IN CONTRACT
          OR IN TORT, RESULTING FROM YOUR ACCESS TO AND USE OF THE WEBSITE, (c)
          ANY UNAUTHORIZED ACCESS TO OR USE OF Makanak&rsquo;S SECURE SERVERS AND/OR
          ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN, (d) ANY BUGS, VIRUSES, WORMS, TROJAN HORSES, DEFECTS, DATE
          BOMBS, TIME BOMBS OR OTHER ITEMS OF A DESTRUCTIVE NATURE WHICH MAY BE
          TRANSMITTED TO OR THROUGH THE WEBSITE, (e) ANY ERRORS, MISTAKES,
          INACCURACIES OR OMISSIONS IN ANY CONTENT, OR (f) ANY LOST, STOLEN OR
          DAMAGED TICKETS, OR THE FAILURE OF A VENUE TO HONOR A TICKET. YOUR
          SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE Makanak SERVICES
          AND THE WEBSITE IS TO STOP USING THE Makanak SERVICES AND THE WEBSITE. THE
          LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY
          FAILS OF ITS ESSENTIAL PURPOSE. THE ALLOCATION OF RISK BETWEEN YOU AND
          Makanak IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN YOU
          AND Makanak. Makanak&rsquo;S AGGREGATE LIABILITY ARISING OUT OF THESE TERMS OR
          THE USE OF Makanak SERVICES OR THE WEBSITE WILL NOT EXCEED THE GREATER OF
          ONE HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID Makanak IN THE PAST
          TWELVE MONTHS. IN NO EVENT WILL ATTORNEYS&rsquo; FEES BE AWARDED OR
          RECOVERABLE. Makanak&rsquo;S LIABILITY WILL BE LIMITED UNDER THIS
          PARAGRAPH TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, AND THE
          PROVISIONS OF THIS PARAGRAPH WILL NOT APPLY TO THE EXTENT APPLICABLE
          LAW PERMITS THE RECOVERY OF DAMAGES, ATTORNEYS&rsquo; FEES OR COSTS
          OTHERWISE PROHIBITED UNDER THIS PARAGRAPH. THE PROVISIONS OF THIS
          PARAGRAPH THAT (A) PROHIBIT DAMAGES TO BE MULTIPLIED OR OTHERWISE
          INCREASED, (B) IMPOSE A DAMAGES LIMITATION OF THE GREATER OF ONE
          HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID Makanak IN THE PAST
          TWELVE MONTHS, AND (C) PROHIBIT THE RECOVERY OF ATTORNEYS&rsquo; FEES
          AND COSTS, DO NOT APPLY IN CERTAIN STATES, INCLUDING WITHOUT
          LIMITATION NEW JERSEY, TO CLAIMS BROUGHT UNDER STATUTES PERMITTING
          SUCH RECOVERY.
        </p>
        <p>
          Force Majeure: Makanak will not be liable to you for failure to perform
          any of its obligations under this Agreement to the extent that the
          failure is caused by reason of Force Majeure.
        </p>
        <p>
          <strong>DISPUTE RESOLUTION</strong>
        </p>
        <ul>
          <li>
            If any dispute arises out of this Agreement or any Transaction
            pursuant to this Agreement, you should contact{' '}
            <a href="mailto:info@technoandchill.com">info@technoandchill.com</a>{' '}
            and we will try to resolve the matter as expediently as possible.
            Your complaint shall be acknowledged by Makanak as soon as possible.
          </li>
          <li>
            Makanak shall use reasonable endeavours to consult or negotiate in good
            faith and attempt to reach a just and equitable settlement that is
            satisfactory to you and Makanak.
          </li>
          <li>
            In the unlikely event that we are able to resolve your complaint by
            negotiation within 28 days, we may attempt to settle a matter via
            mediation. To initiate a mediation a party must give written notice
            to the other party to the dispute requesting a mediation. The
            mediation shall be conducted in accordance with the STAR Code of
            Practice and Dispute Resolution Procedure current at the date of the
            referral which sets out the procedures to be adopted, the process of
            selection of the mediator and the costs involved and which terms are
            deemed incorporated into this agreement.
          </li>
          <li>
            The above does not restrict your statutory rights, your right to
            make a complaint to Trading Standards or another appropriate
            regulatory authority, or your right to pursue court proceedings or
            other forms of settlement such as through the European Union Online
            Dispute Resolution Platform, a portal through which you can submit a
            complaint to a registered Alternative Dispute Resolution Provider
            with the aim of resolving the dispute.
          </li>
        </ul>
        <p>
          For U.S. and Canada Residents:&nbsp;Any dispute or claim relating in
          any way to your use of the Website, or to products or services sold or
          distributed by us or through us, will be resolved by binding
          arbitration rather than in court, with the following exceptions:
        </p>
        <ul>
          <li>
            You may assert claims in small claims court if your claims apply;
          </li>
          <li>
            If a claim involves the conditional license granted to you as
            described in the Ownership of Content and Grant of Conditional
            License section above, either of us may file a lawsuit in a federal
            or state court located within Los Angeles County, California, or
            Toronto, Ontario, as applicable, and we both consent to the
            jurisdiction of those courts for such purposes; and
          </li>
          <li>
            In the event that the arbitration agreement in these Terms is for
            any reason held to be unenforceable, any litigation against us
            (except for small-claims court actions) may be commenced only in a
            federal or state court located within Los Angeles County,
            California, or Toronto, Ontario, as applicable, and we both consent
            to the jurisdiction of those courts for such purposes.
          </li>
          <li>
            The arbitration agreement in these Terms is governed by the Federal
            Arbitration Act (FAA) or the Canada Commercial Arbitration Act
            (CAA), as applicable, including its procedural provisions, in all
            respects. This means that the FAA or the CAA, as applicable,
            governs, among other things, the interpretation and enforcement of
            this arbitration agreement and all of its provisions, including,
            without limitation, the class action waiver discussed below. State
            arbitration laws do not govern in any respect.
          </li>
          <li>
            This arbitration agreement is intended to be broadly interpreted,
            and will survive termination of these Terms. The arbitrator, and not
            any court or agency, shall have exclusive authority to the extent
            permitted by law to resolve all disputes arising out of or relating
            to the interpretation, applicability, enforceability or formation of
            this Agreement, including, but not limited to any claim that all or
            any part of this Agreement is void or voidable. There is no judge or
            jury in arbitration, and court review of an arbitration award is
            limited. However, an arbitrator can award on an individual basis the
            same damages and relief as a court (including injunctive and
            declaratory relief or statutory damages), and must follow these
            Terms as a court would.
          </li>
          <li>
            To begin an arbitration proceeding, you must send a letter
            requesting arbitration and describing your claim to Makanak
            LLC at: 1745 Wilcox Avenue, Los Angeles, CA 90028, USA. We each
            agree that the arbitrator may not consolidate more than one
            person&rsquo;s claims, and may not otherwise preside over any form
            of a representative or class proceeding, and that any dispute
            resolution proceedings will be conducted only on an individual basis
            and not in a class, consolidated or representative action. You agree
            to waive any right to a jury trial or to participate in a class
            action. If this specific provision is found to be unenforceable,
            then the entirety of this arbitration section will be null and void
            and neither of us will be entitled to arbitrate our dispute.
          </li>
          <li>
            You agree that these Terms evidence a transaction involving
            interstate commerce and will be governed by and construed in
            accordance with U.S. federal law or Ontario law, as applicable, to
            the fullest extent possible.
          </li>
        </ul>
        <p>
          <strong>GENERAL</strong>
        </p>
        <p>
          Taxes: As a User of the Makanak Services, you agree that you are solely
          responsible for determining your applicable tax reporting requirements
          in consultation with your tax advisors. Makanak cannot and does not offer
          tax-related advice to any Users. Additionally, please note that each
          Seller is responsible for determining local indirect taxes and for
          including any applicable taxes to be collected or obligations relating
          to applicable taxes in Listings. You are also responsible for paying
          any governmental taxes imposed on your purchase from or use of the Makanak
          Services, including, but not limited to, sales, use, or value-added
          taxes. To the extent Makanak is required to collect such taxes, the
          applicable tax will be added to your billing account and Makanak will
          issue a receipt on request from a Seller as required by law.
        </p>
        <p>
          Waiver: If Makanak delays or fails to enforce any of the provisions of
          this Agreement, it shall not mean that Makanak has waived the right to do
          so.
        </p>
        <p>
          Assignment: Makanak shall be entitled to assign its rights and obligations
          under this Agreement provided that your rights are not adversely
          affected.
        </p>
        <p>
          Severability: If it is found by a Court that any provision of this
          Agreement for any reason is invalid or cannot be enforced, this shall
          not prevent the other provisions from continuing to remain in full
          force and operate separately.
        </p>
        <p>
          Illegality: If any provision of this Agreement is or becomes illegal,
          invalid or unenforceable under the law of any jurisdiction, that shall
          not affect or impair the legality, validity or enforceability in that
          jurisdiction of any other provision of this Agreement.
        </p>
        <p>
          No Partnership/Agency: Nothing contained within this Agreement and no
          action taken by you or Makanak under this Agreement shall create, or be
          deemed to create, a partnership, joint venture, or establish a
          relationship of principal and agent or any other relationship between
          you and Makanak beyond the relationship created under this Agreement.
        </p>
        <p>
          Entire Agreement: This Agreement and all documents incorporated into
          this Agreement by reference constitutes the entire agreement between
          the parties in connection to its subject matter and supersedes any
          previous agreement, terms and conditions or arrangement between the
          parties, whether written or oral. Each of the parties agrees that it
          has not entered into this Agreement in reliance on, and shall have no
          remedy in respect of any statement, representation, covenant,
          warranty, undertaking or indemnity (whether negligently or innocently
          made) by any person (whether party to this Agreement or not) other
          than as expressly set out in this Agreement. Nothing in this paragraph
          shall limit any liability for (or remedy in respect of) fraud or
          fraudulent misrepresentation.
        </p>
        <p>
          Notices: A notice provided under this Agreement shall be delivered
          upon receipt and shall be deemed to have been received (i) at the time
          of delivery, if delivered by hand, registered post or courier; or (ii)
          at the time of transmission if delivered by email (and no error
          message is received in response).
        </p>
        <p>
          Governing Law and Jurisdiction: This Agreement shall be governed by
          and construed in all respects in accordance with the law of the
          country in which this Website is based, without regard to its
          conflict-of-law provisions, and the parties agree to submit to the
          non-exclusive jurisdiction of the courts of such country.
        </p>
        <p>
          <strong>Questions</strong>
        </p>
        <p>
          Questions regarding these Terms, Our Privacy Policy, or other policy
          related material can be directed to our support staff by emailing us
          at:&nbsp;
          <a href="mailto:support@technoandchill.com">
            support@technoandchill.com
          </a>
        </p>

        <br></br>
        <br></br>
      </Stack>
    </div>
  ) : (
    <div className={classes.mobileContainer}>
      <Stack
        alignItems={'flex-start'}
        direction="column"
        justifyContent="center"
        spacing={3}
      >
        <Box m={3}></Box>

        <Typography variant="h4" className={classes.headerText}>
          <img
            src="https://i.imgur.com/84fa23X.png"
            style={{ display: 'inline-flex' }}
          ></img>{' '}
          Terms And Conditions
        </Typography>
        <p>
          <strong>Payment: Terms &amp; Conditions</strong>
        </p>
        <p>
          Reservation prices and ticket prices include tax, unless otherwise
          specified.
        </p>
        <ul>
          <li>
            All incidental expenses, gratuities, registrations, fees or charges,
            and other expenses, including, but not limited to, food, beverage,
            and merchandise are the sole responsibility of the guest.
          </li>
          <li>
            Guest must arrive by time specified on the reservation or ticket for
            guaranteed entry.
          </li>
          <li>
            Guest must bring a printed or digital copy of the reservation
            confirmation with scan-able ticket(s), valid photo I.D., and the
            credit card used to purchase the ticket(s).
          </li>
          <li>
            Guest must be of legal drinking age with a valid government issued
            ID to be presented at the door.
          </li>
          <li>
            Tickets / Reservations are non-transferrable or available for
            resale. Lost or stolen tickets will not be replaced.
          </li>
          <li>
            Artists, programming and other event details are subject to change
            without notice.
          </li>
          <li>All Ticket / Table sales are final and nonrefundable.</li>
          <li>
            Organizer reserves the right to deny access into venue due to
            capacity restrictions.
          </li>
          <li>Appropriate dress is required and strictly enforced.</li>
          <li>
            Disregard or violation of these Terms and Conditions will
            automatically invalidate ticket(s) purchased and guest will not
            receive a refund of any kind.
          </li>
          <li>
            Management reserves all rights, including, without limitation, the
            right to cancel, suspend or modify the event at its sole discretion.
          </li>
          <li>
            This offer is subject to all applicable federal, state and local
            laws, and regulations.
          </li>
          <li>
            Tickets are non-refundable in all cases, including governmental acts
            and acts of God, the event organizer may, in it&rsquo;s sole and
            absolute direction elect to either (a) postpone the event for a
            future date and/or (b) offer Purchaser a comparable &lsquo;make
            good.&rsquo;
          </li>
        </ul>
        <ol>
          <li>
            <strong> Introduction and Key Terms</strong>
          </li>
        </ol>
        <p>
          <strong>THIS AGREEMENT AND THESE TERMS AND CONDITIONS</strong>
          <br />
          <br /> This Agreement is between Makanak LLC
          (&ldquo;Makanak&rdquo;) and you (&ldquo;User&rdquo;, &ldquo;you&rdquo;,
          &ldquo;your&rdquo;). Whether you are using the Makanak Services to
          purchase Tickets (&ldquo;Buyer&rdquo;) or sell Tickets
          (&ldquo;Seller&rdquo;), this Agreement governs your access to and use
          of the Makanak Services.
        </p>
        <p>Capitalised words in this Agreement are defined in paragraph 15.</p>
        <p>
          <strong>Please note:</strong>
        </p>
        <ul>
          <li>
            Makanak does not routinely screen the content of any Listing or its
            suitability for individual Users;
          </li>
          <li>Makanak does not own or set the Sale Price of any Ticket;</li>
          <li>
            Makanak does not endorse any Users or any Listings and reserves the
            right to remove a Listing it deems inappropriate from the Makanak
            website (&ldquo;Website&rdquo;) at any time and for any reason;
          </li>
          <li>
            Makanak does not confirm the identity or suitability of Users; Users
            interact with and contact one another at their own risk;
          </li>
          <li>Makanak is not an event organiser or promoter;</li>
          <li>
            Makanak provides an online platform to facilitate the sale and purchase
            of Tickets; and
          </li>
          <li>
            Makanak is not party to the agreement between a Buyer and Seller for the
            sale and purchase of Tickets using the Makanak Services. Therefore, Makanak
            is not responsible for the Listing, the Event or the Ticket(s)
            purchased. Delivery of Tickets is the Seller&rsquo;s responsibility
            and Makanak is unable to guarantee that Sellers will complete
            Transactions in every single case. <br /> Legally Binding Agreement:
            This Agreement is a legally binding contract and you should read the
            following terms and conditions, the{' '}
            <a href="https://www.technoandchill.com/terms-and-conditions/">
              Makanak Terms of Use
            </a>{' '}
            and{' '}
            <a href="https://www.technoandchill.com/privacy-policy/">
              Privacy Policy
            </a>{' '}
            carefully before you use the Makanak Services. By using the Makanak
            Services, you acknowledge that you have read, understood and agree
            to be bound by the terms and conditions of this Agreement. If you
            don&rsquo;t agree with this Agreement, or cannot comply with it,
            then you must not use the Makanak Services.
            <br />
            <br /> Changes to this Agreement: We may make changes to these Terms
            at any time. Any changes we make will be effective immediately when
            we post a revised version of these Terms on the Website. Your
            continued use of the Makanak Services will constitute your acceptance of
            the new Agreement so please make sure you read any such notification
            carefully. If you do not agree with the terms of this Agreement or
            any revised version of this Agreement, you must immediately stop
            using the Makanak Services, including removing any Listings that you
            have posted.
          </li>
        </ul>
        <p>
          <strong>About Makanak : </strong>
        </p>
        <p>Makanak LLC is a company registered in Delaware, USA.</p>
        <p>
          Contacting Makanak: If you have any questions or want any further
          information regarding this Agreement, please contact&nbsp;
          <a href="mailto:support@technoandchill.com">
            support@technoandchill.com
          </a>
          .
        </p>
        <p>
          Contacting You: If we need to contact you, we will do so using the
          telephone number or email address that you have provided with your
          Account.
        </p>
        <p>
          While some of the events listed on the Website may appeal to children,
          the Website is not targeted at children under the age of 18, and they
          are not permitted to use the Website. We strongly encourage all
          parents and guardians to monitor Internet use by their children. If
          you use the Website, you affirm you are at least 18 years old.
        </p>
        <ol>
          <li>
            <strong> Registration, Your Account and User Content</strong>
          </li>
        </ol>
        <p>
          <strong>REGISTRATION</strong>
        </p>
        <p>
          Registration: In order to set up a Listing or buy Tickets using the
          Makanak Services you must:
        </p>
        <p>
          be at least 18 years old, or the legal age of majority in the country
          where you reside, and able to enter into legally binding contracts;
          and&nbsp;provide your correct full name (or business name, if you are
          a business) and email address (all your details must be kept up to
          date at all times), further details can be added at a later date if
          desired. You may not include the name of another person with the
          intent to impersonate that person, or be offensive, vulgar or obscene.
        </p>
        <p>
          You can also register to join by logging into your account via a
          Third-Party Social Media Site. Please note that by connecting to the
          Website and the Makanak Services using a Third-Party Social Media Site,
          you are giving Makanak permission to (i) access and use your information
          from that service as permitted by that service; and (ii) to store your
          authorisation access to that Third-Party Social Media Site.
        </p>
        <p>
          <strong>YOUR ACCOUNT</strong>
        </p>
        <p>
          You shall not permit anyone other than you to use your Account. You
          may not transfer or sell access to your account. We will not be liable
          for any harm related to disclosure of your username or password or the
          use by anyone else of your username or password. You may not use
          another user&rsquo;s account without that user&rsquo;s permission. You
          will immediately notify us in writing if you discover any unauthorized
          use of your account or other account-related security breach. We may
          require you to change your username and/or password if we believe your
          account is no longer secure or if we receive a complaint that your
          username violates someone else&rsquo;s rights. You will have no
          ownership in your account or your username. We may refuse
          registration, cancel an account or deny access to the Website for any
          reason.
        </p>
        <p>
          Companies: If you are accepting this Agreement on behalf of a company
          or other legal entity, you represent and warrant that you have the
          authority to bind that company or other legal entity (and
          &ldquo;you&rdquo; refers and applies to that company or other legal
          entity).
        </p>
        <p>
          Financial Checks: You permit Makanak and/or a third party contractor to
          carry out financial checks for the purposes of verifying your
          financial status (which does not include a credit check but does
          include a fraud check) and you agree that Makanak may pass your Account
          and payment card details to such third party contractor to carry out
          such checks.
        </p>
        <p>
          Privacy Policy: Please refer to the&nbsp;
          <a href="https://www.technoandchill.com/privacy-policy/">
            Makanak Privacy Policy and the Cookie Policy
          </a>
          &nbsp;for more details on how we use and protect your Makanak Account
          Information.
        </p>
        <p>
          International: Your Account can be used to access and use the Makanak
          Services on the Website and any international platform operated by Makanak
          and/or to purchase tickets to Events taking place in various
          countries. However, please be aware that buying and selling Tickets
          for Events in such countries may be subject to different laws, rules,
          regulations and a different Privacy Policy.
        </p>
        <p>
          <strong>USER CONTENT</strong>
        </p>
        <p>
          User Content: We may, in our sole discretion, permit Users to post,
          upload, publish, submit or transmit profile information, comments,
          questions, Feedback, images and other content or information
          (&ldquo;User Content&rdquo;). User Content shall include all
          information posted, transmitted or otherwise made available by Users
          on the Makanak Services in connection with the creation or booking of
          Listings.
        </p>
        <p>
          User Content Warranties: In relation to your User Content, you
          represent and warrant that:
        </p>
        <p>
          you have the written consent of each and every identifiable natural
          person in the User Content to use such person&rsquo;s name or likeness
          in the manner contemplated by the Makanak Services and this Agreement, and
          each such person has released you from any liability that may arise in
          relation to such use; you have the written consent of the photographer
          or other owner of any images included in your User Content to use such
          pictures in the manner contemplated by the Makanak Services and this
          Agreement;
        </p>
        <p>
          Makanak&rsquo;s use your User Content as contemplated by this Agreement
          and the Makanak Services will not breach any law or infringe any rights of
          any third party, including but not limited to any intellectual
          property rights and privacy rights ; your User Content is not
          unlawful, harmful, threatening, abusive, harassing, tortious,
          defamatory, vulgar, obscene, libellous, invasive of another&rsquo;s
          privacy, hateful or racially, ethnically or otherwise objectionable;
        </p>
        <p>
          Makanak may exercise the rights to your User Content granted under this
          Agreement without liability for payment of any fees or royalties
          payable under any collective bargaining agreement or otherwise; and to
          the best of your knowledge, all your User Content and any other
          information that you provide to us is truthful and accurate.
        </p>
        <p>
          User Content Licence Grant: By posting any User Content on the Makanak
          Services, you expressly grant, and you represent and warrant that you
          have all rights, power and authority necessary to grant, to Makanak a
          royalty-free, sublicensable, transferable, perpetual, irrevocable,
          non-exclusive, worldwide licence to use, reproduce, modify, publish,
          list information regarding, edit, translate, distribute, syndicate,
          publicly perform, publicly display, and make derivative works of all
          such User Content and your name, voice, and/or likeness, and any third
          party name, voice, and/or likeness, as contained in your User Content,
          in whole or in part, and in any form, media or technology, whether now
          known or hereafter developed, for use in connection with the Makanak
          Services and Makanak&rsquo;s (and its successors&rsquo; and
          affiliates&rsquo;) business, including without limitation for
          promoting and redistributing part or all of the Makanak Services (and
          derivative works thereof) in any media formats and through any media
          channels. You also hereby grant each User of the Makanak Services a
          non-exclusive licence to access your User Content through the Makanak
          Services, and to use, reproduce, distribute, display and perform such
          User Content as permitted through the functionality of the Makanak
          Services and under this Agreement.
        </p>
        <p>
          Makanak and User Content: Makanak takes no responsibility and assumes no
          liability for any User Content that you or any other User or third
          party posts or sends using the Makanak Services. You shall be solely
          responsible for your User Content and the consequences of posting or
          publishing it, and you agree that we are only acting as a passive
          conduit for your online distribution and publication of your User
          Content. You understand and agree that you may be exposed to User
          Content that is inaccurate, objectionable, inappropriate for children,
          or otherwise unsuited to your purpose, and you agree that Makanak shall
          not be liable for any damages you incur (or allege to incur) as a
          result of User Content.
        </p>
        <p>
          For U.S. Residents: Under the Digital Millennium Copyright Act of 1998
          (the &ldquo;DMCA&rdquo;) if you believe in good faith that any content
          on the Website infringes your copyright, you may send us a notice
          requesting that the content be removed. The notice must include: (a)
          your (or your agent&rsquo;s) physical or electronic signature; (b)
          identification of the copyrighted work on our Website that is claimed
          to have been infringed (or a representative list if multiple
          copyrighted works are included in one notification); (c)
          identification of the content that is claimed to be infringing or the
          subject of infringing activity, including information reasonably
          sufficient to allow us to locate the content on the Website; (d) your
          name, address, telephone number and email address (if available); (e)
          a statement that you have a good faith belief that use of the content
          in the manner complained of is not authorized by you or your agent or
          the law; and (f) a statement that the information in the notification
          is accurate and, under penalty of perjury, that you or your agent is
          authorized to act on behalf of the copyright owner. If you believe in
          good faith that a notice of copyright infringement has been wrongly
          filed against you, you may send us a counter-notice. You may read more
          information about the DMCA at http://www.loc.gov/copyright. Notices
          and counter-notices should be sent to inf@themrblack.com. There can be
          penalties for false claims under the DMCA. We suggest that you consult
          your legal advisor before filing a notice or counter-notice. It is our
          policy to terminate, in appropriate circumstances, the access rights
          to the Website of repeat infringers.
        </p>
        <ol>
          <li>
            <strong>
              {' '}
              Terms and Conditions &ndash; Listing and Selling Tickets
            </strong>
          </li>
        </ol>
        <p>
          <strong>CREATING A LISTING</strong>
        </p>
        <p>
          Listing Responsibility of Seller: By creating a Listing using the Makanak
          Services, Sellers are offering to sell or distribute, for the Sale
          Price, where applicable, Ticket(s) for Buyers to attend an Event. It
          is the Seller&rsquo;s responsibility to make any Event or Ticket
          restrictions clear to the Buyer and the Seller is solely responsible
          for all the Listings that they post on the Website. Any changes to
          Listings, Events and Tickets must be made clear to Users and Buyers as
          soon as possible.
        </p>
        <p>
          Listing Representation: A Listing is a legal representation made by
          the Seller about the details relevant to the Ticket and Event, and
          their accuracy.
        </p>
        <p>
          Service Charge Preference: The Seller shall elect whether the Service
          Charge shall be paid by the Buyer, in addition to the Sale Price, or
          included in the Sale Price and paid by the Seller.
        </p>
        <p>
          Listing Description: The Seller must provide all the Event information
          in the Listing, including:
        </p>
        <ul>
          <li>the location of the Event;</li>
          <li>the name of the Event and performer(s) (if relevant);</li>
          <li>the date of the Event;</li>
          <li>the name and address of the Venue;</li>
          <li>
            the type of Ticket (e.g. seated, standing, timed entry, etc.);
          </li>
          <li>the Sale Price of each Ticket;</li>
          <li>
            whether or not the Service Charge is included in the Sale Price or
            not;
          </li>
          <li>
            whether or not a portion of the Sale Price will be donated to a
            particular cause or charity and the relevant associated details;
          </li>
          <li>the refund policy, if any;</li>
          <li>
            any additional information, special rules or requirements which
            apply to the Event and/or the Tickets (e.g., age restrictions,
            accessibility restrictions, restricted view, family section, alcohol
            restrictions, etc.);
          </li>
          <li>
            if applicable, information on how and when the Buyer will be
            informed about whether the Seller has approved the Transaction or
            not;
          </li>
          <li>
            any details about the Buyer that the Seller will be collecting
            during the Transaction process and details as to how such data will
            be processed, including any Buyer User Data to be collected; and any
            other significant information relating to the Event and/or Ticket(s)
            that may reasonably affect a Buyer&rsquo;s decision about whether to
            purchase or apply for a Ticket or not, including any information
            required by law. In Ontario and any other jurisdiction where
            required by law, this must include a description of any other
            distribution methods or sales channels for the Tickets to the Event
            that you will make available for sale, including any sale that will
            occur before tickets are made available for sale to the general
            public (e.g. pre-sales).
          </li>
        </ul>
        <p>
          Compliance: When creating a Listing, the Seller must comply with all
          applicable laws and regulations and the terms of this Agreement. If
          you are a Seller trading as a business, you must identify yourself as
          such by using a business Account. Sellers trading as a business are
          responsible for complying with all applicable laws and regulations in
          relation to their obligations as a &ldquo;trader&rdquo;. Makanak assumes
          no responsibility for a Seller&rsquo;s compliance with applicable
          laws, rules or regulations.
        </p>
        <p>
          Seller Warranties: The Seller represents and warrants to and for the
          benefit of Makanak, that any Listings that he/she creates:
        </p>
        <ul>
          <li>
            relate only to Tickets and Events over which that Seller has
            control, either directly or indirectly, and for which the Seller is
            permitted to sell or distribute Tickets, and that the Seller is in
            possession of the Tickets at the time of the Listing;
          </li>
          <li>
            include accurate and transparent Ticket prices and information
            relating to all additional charges which will be incurred by Buyers;
          </li>
          <li>
            if booked, will be honoured, unless otherwise agreed with the Buyer;
          </li>
          <li>
            do not infringe any third party rights (including intellectual
            property rights);
          </li>
          <li>
            are not being listed by the Seller serving in the capacity of a
            broker, rental agent or listing agent for a third party;
          </li>
          <li>
            do not comprise of the same or substantially the same content as any
            other Listings on the Website and are not published on any other
            website;
          </li>
          <li>
            do not in any way contain fraudulent or inaccurate or misleading
            information; Please note: selling stolen or counterfeit property is
            a crime and Makanak will report such activity to the relevant
            authorities and permanently ban anyone found selling stolen or
            counterfeit property from using the Makanak Services.
          </li>
          <li>are not defamatory, abusive, threatening or obscene;</li>
          <li>
            are not unethical or discriminatory on the basis of age, gender,
            race, marital status, nationality or cultural, religious or personal
            beliefs, disability, sexual orientation, political opinion or
            otherwise;
          </li>
          <li>
            do not attempt to solicit any Users to use a third party service
            that competes with the Makanak Services;
          </li>
          <li>
            do not breach any applicable law or regulation, including those
            associated with making charity donations;
          </li>
          <li>
            do not contain any virus or other programme that interferes with or
            seeks to interfere with any computer system or data;
          </li>
          <li>
            cannot be construed to be associated with any terrorist
            organisations; and
          </li>
          <li>
            do not contain political campaigning, commercial solicitation, chain
            letters, mass mailings or any form of &ldquo;spam&rdquo;.
          </li>
        </ul>
        <p>
          VAT: The Seller is responsible for ensuring that the Sale Price
          includes all taxes required on Listings, including VAT. If in doubt,
          the Seller should seek the advice of a tax expert to determine which
          taxes need to be collected from the Buyer.
        </p>
        <p>
          Insurance: The Seller is solely responsible for any insurance that may
          be required in relation to the Listing and the Event.
        </p>
        <p>
          Event Cancellation or Alteration: If an Event is cancelled,
          re-scheduled or amended in any way, this must be dealt with by the
          Seller and communicated clearly to Buyers. The Listing must be updated
          or removed by the Seller and if it is not, we reserve the right to
          remove the Listing from the Makanak Services.
        </p>
        <p>
          No Makanak Guarantee: Makanak does not guarantee that Listings made via the
          Makanak Services will be published and active within a certain time of the
          Seller posting a Listing or that it will appear in a particular order
          in search results or that the Ticket(s) will sell. If a Ticket does
          not sell, Makanak accepts no responsibility.
        </p>
        <p>
          <strong>SALE OF TICKETS</strong>
        </p>
        <p>
          Payment Processing Preferences: When a Buyer completes the sales
          process by entering their payment details and clicking on &ldquo;pay
          now&rdquo; for paid for Events or &ldquo;register&rdquo; for free
          Events, the parties shall become contractually bound to one another to
          complete the Transaction. An Order Confirmation will be generated and
          sent to the Buyer once the Buyer completed the payment process. Failed
          Delivery: Order Confirmations are automatically generated following
          the Buyer&rsquo;s completion of the payment process. Any notification
          of a failed delivery that the Seller receives from a Buyer must be
          dealt with by the Seller as soon as possible and, in any event, in
          advance of the date of the relevant Event.
        </p>
        <p>
          Disputed Events: If a Buyer disputes any element of an Event or a
          Transaction Value, such dispute shall be between the Buyer and the
          Seller and Makanak shall have no involvement.
        </p>
        <p>
          <strong>STRIPE PAYMENTS</strong>
        </p>
        <p>
          Stripe Payments: By setting up a Stripe Connect account via the&nbsp;
          <a href="https://stripe.com/">Stripe website</a>, Sellers can
          integrate their Account with Stripe to process payments for their
          Events using their own merchant account.
        </p>
        <p>
          Payments: Immediately upon a Transaction being completed by the Buyer,
          the total value of the Sale Price(s) will be paid directly into the
          Seller&rsquo;s Stripe account and the Service Charge shall be paid to
          Makanak.
        </p>
        <p>
          Refunds: In the event that a Buyer requests a refund of the
          Transaction Value, this shall be granted at the sole discretion of the
          Seller. Makanak may initiate a refund request on behalf of a Buyer but is
          under no obligation to do so.
        </p>
        <p>
          <strong>PAYMENTS</strong>
        </p>
        <p>
          Applicable Taxes: Sellers agree to pay all applicable taxes or charges
          imposed by any government entity in connection with their Listings and
          associated Transactions.
        </p>
        <p>
          Chargebacks: Any credit card chargebacks or other transaction
          reversals initiated against Makanak or its affiliates for any reason
          (except to the extent they are caused solely by Makanak&rsquo;s negligence
          or wilful misconduct) with respect to a Seller&rsquo;s Listing and all
          related credit card association, payment processing and other fees and
          expenses incurred by Makanak or its affiliates in connection with such
          chargebacks shall ultimately be the responsibility of the Seller and
          the Seller agrees to promptly and fully reimburse Makanak for such amounts
          on demand. Makanak reserves the right to withhold at least 10% of a
          Seller&rsquo;s proceeds, in its sole discretion, to cover the cost of
          any such chargebacks that may arise.
          <br />
          <br /> Cancellation and Refunds: The cancellation and refund policy
          for a Ticket is at the Seller&rsquo;s discretion and will be set out
          in the Listing description. If no such policy is set out by the
          Seller, Buyers may cancel a booking and receive a refund of the Sale
          Price, if requested 24 hours or more before the start time of the
          Event.
        </p>
        <p>
          <strong>USE AND COLLECTION OF DATA BY SELLERS</strong>
        </p>
        <p>
          Makanak Account Information: The Seller may be given access to
          Buyers&rsquo; Makanak Account Information where necessary to fulfil
          Tickets. Makanak does not provide any warranties as to the accuracy,
          relevance or fitness for any particular purpose in relation to the Makanak
          Account Information. The Seller is the Data Controller (as defined in
          EU Data Protection Legislation) in respect of the Makanak Account
          Information of Buyers, from the point at which it is passed and/or
          made available to the Seller and the Seller must comply with Data
          Protection Legislation when processing Makanak Account Information of
          Buyers.
        </p>
        <p>
          Where Makanak Account Information of Buyers is made available, it is
          confidential and must only be disclosed to authorised persons using
          appropriate security measures. The Seller must keep Buyers&rsquo; Makanak
          Account Information secure and must take reasonable steps to protect
          it from misuse, loss and from unauthorised access, modification or
          disclosure.
        </p>
        <p>
          Buyer User Data: The Seller is the Data Controller of all Buyer User
          Data and must comply with Data Protection Legislation when processing
          Buyer User Data including, but not limited to, providing Buyers with
          information about how their data will be used, and collecting
          appropriate permission from Buyers for any uses made of their User
          Data. The Seller must keep Buyer User Data secure and must take
          reasonable steps to protect it from misuse, loss and from unauthorised
          access, modification or disclosure. Makanak has no control over or input
          into Buyer User Data, nor does it have responsibility for or ownership
          of any Buyer User Data collected and solely acts as a processor in
          relation to the same. Notwithstanding this, Makanak will take appropriate
          measures to protect Buyer User Data, when held within the Makanak
          Services, from loss, misuse and unauthorised access, disclosure,
          alteration and destruction, taking into account the risks involved in
          the processing and the nature of the Buyer User Data. Makanak will only
          process Buyer User Data on the instructions of the Seller.
        </p>
        <p>
          Prohibited Data Classes: Sellers must not use the Makanak Services to
          collect the following types of data: payment card or bank details,
          details of racial or ethnic origin, political opinions, details of
          religious beliefs, trade union membership details, physical or mental
          health information, details of sexual life, details of the commission
          or alleged commission of any offence, and personal data of or other
          information relating to children under the age of 18. If the Seller
          collects such information, Makanak is entitled, in its sole discretion, to
          terminate the Agreement for material breach in accordance with
          paragraph 16 of this Agreement.
        </p>
        <p>
          <strong>ENFORCEMENT ACTION AGAINST SELLERS</strong>
        </p>
        <p>
          Cancellation: Makanak reserves the right to cancel a Listing and/or a
          Transaction and/or a Seller&rsquo;s Account in its sole discretion,
          for any reason and at any time.
        </p>
        <ol>
          <li>
            <strong> Terms and Conditions &ndash; Buying Tickets</strong>
          </li>
        </ol>
        <p>
          <strong>PRICE AND PAYMENT</strong>
        </p>
        <p>
          Sale Price: The Seller sets the Sale Price of the Ticket and can
          change the Sale Price at any time prior to a Transaction taking place.
        </p>
        <p>
          Fees/Charges: When a Buyer selects one or more Tickets for purchase,
          any additional charges and/or fees associated with the Ticket and
          Transaction shall be made clear by the Seller, which shall include:
        </p>
        <ul>
          <li>the Sale Price;</li>
          <li>the Service Charge (if applicable); and</li>
          <li>any other charges included by the Seller.</li>
        </ul>
        <p>
          Check Your Order: As a Buyer you must ensure that you have read the
          full Listing details and are happy with your Ticket selection prior to
          purchase. Makanak will not rectify any issues that arise and Sellers will
          only do so in their sole discretion.
        </p>
        <p>
          Payment Information: The Buyer warrants and represents that s/he is
          authorised to use the selected payment method and that the billing
          information provided is complete, up to date and accurate.
        </p>
        <p>
          Approval Settings: Some Listings may require the Seller&rsquo;s
          approval prior to the Buyer being able to purchase Tickets. Such
          Listings shall specify the timeframes within which approval must be
          given by the Seller. If no approval is granted within such timeframes,
          your order will be cancelled and payment will not be taken.
        </p>
        <p>
          Order Confirmation: Upon Buyers completing their purchase, by entering
          their payment details, where necessary, and clicking &ldquo;pay
          now&rdquo; for paid for Events or &ldquo;register&rdquo; for free
          Events, an Order Confirmation shall automatically be issued to the
          Buyer, which is the point at which the Buyer and Seller enter into a
          binding agreement. The Order Confirmation will contain the Ticket with
          which the Buyer will be able to attend the Event. If the Order
          Confirmation is not received, it can be retrieved from the Account
          dashboard, along with the Ticket.
        </p>
        <p>
          Payment: Once the Buyer receives the Order Confirmation, depending on
          which Payment Processing Method the Seller has selected, the Buyer
          agrees that Makanak or a third party payment processor, acting on behalf
          of the Seller, may debit or authorise the amount of the Transaction
          Value from the method of payment provided by the Buyer. If applicable,
          the Service Charge is non-refundable and, unless otherwise agreed by
          the Seller, no refunds or credits will be provided once the Buyer has
          been charged.
        </p>
        <p>
          Other Ticket Terms and Conditions: All Tickets purchased by the Buyer
          are subject to the Ticket, Event and Venue terms and conditions, and
          all applicable laws and regulations.
        </p>
        <p>
          After-Sales Queries: If the Buyer has any questions or concerns
          following the purchase of a Ticket, the Buyer should contact the
          Seller directly and should not contact or attempt to contact Makanak.
        </p>
        <p>
          Donations: Sellers may pledge to donate a portion of the Sale Price to
          a particular cause or charity. Makanak does not control or accept any
          responsibility or liability for any donations pledged by the Seller.
          Makanak does not investigate or otherwise ensure the factual accuracy of
          any donation claims made by Sellers in Listings.
        </p>
        <p>
          <strong>EJECTION:</strong>
        </p>
        <p>
          The Venue always reserves the right to refuse admission to, or remove,
          anyone who acts with disorderly conduct or otherwise violates any rule
          of the Venue (e.g., refusing entry to those carrying alcohol,
          recording devices, and/or noise/chemical irritants).
        </p>
        <p>
          To enforce this policy, a Venue may subject you and your personal
          belongings to a search when you enter a Venue. If you do not agree to
          a search, then you are not eligible for a refund.
        </p>
        <p>
          <strong>GUEST REGISTRATION AND COMMUNICATION:</strong>
        </p>
        <ul>
          <li>
            Our registration process is designed to give us just enough
            information to ensure that we can reach you and any of your
            additional guests easily and enable you purchase tickets securely.
          </li>
          <li>
            Please see our privacy policy to learn about all the ways we protect
            your privacy and keep your data safe.
          </li>
          <li>
            All activity on your account is your responsibility. Do not use
            another person&rsquo;s account or contact information without
            explicit permission. If you see any unauthorized activity on your
            account or have been sent communications from us as a result of
            actions taken by someone other than you without your permission,
            please contact us immediately at info@technoandchill.com.
          </li>
          <li>
            All correspondence to and from Makanak is handled first by email and
            secondly via SMS satisfies any legal requirement that our
            communication with you be in writing. SMS charges may apply.
          </li>
        </ul>
        <p>
          <strong>CONDUCT:</strong>
        </p>
        <p>
          Our Site requires reasonable common-sense conduct from our users to
          keep running smoothly. The basics:
        </p>
        <ul>
          <li>
            You have to be real&mdash;not a robot or part of any commercial
            service looking to use Makanak for resale of tickets on the secondary
            market. This includes putting unreasonable load on our system or
            requesting more than 1,500 pages of our Site during any 24-hour
            period.
          </li>
          <li>
            You have to be who you say you are. Your IP should not be concealed
            and your username should not be used to impersonate another
            individual. Usernames should not be obscene or otherwise offensive.
          </li>
          <li>
            Don&rsquo;t try to hack into the Site to circumvent our security
            measures, including our CAPTCHA, or get unauthorized access to other
            users&rsquo; accounts.
          </li>
          <li>
            Don&rsquo;t deploy any malware, spamming, or flooding that would
            interfere with other users&rsquo; experiences on the Site.
          </li>
          <li>
            Don&rsquo;t download, export, reverse engineer, or create derivative
            works of any content on our Site.
          </li>
          <li>
            Don&rsquo;t frame or mirror the Site, or use any spider, robot, or
            automated tool to index, dateline, or reproduce content or user
            behavior on the Site, unless you have written authorization from Makanak
            to do so.
          </li>
          <li>
            Don&rsquo;t use our Site to transmit or upload material that would
            infringe upon others&rsquo; rights or privacy, intellectual
            property, or publicity.
          </li>
          <li>
            Don&rsquo;t use a password or code (pre-sales or otherwise) to get
            access to the Site if you aren&rsquo;t the intended recipient of
            that password or code.
          </li>
          <li>
            Don&rsquo;t make any copy or counterfeit reproduction of our
            tickets.
          </li>
          <li>
            Don&rsquo;t seek a dispute or &ldquo;chargeback&rdquo; from your
            credit card company for a valid purchase. If you do, your tickets
            may be cancelled, and in the future we may refuse to honor the
            credit card and/or block the accounts of people who use that card.
          </li>
        </ul>
        <p>
          <strong>CANCELLATION AND REFUNDS</strong>
        </p>
        <p>
          Cancellation: The cancellation and refund policy for a Ticket is at
          the Seller&rsquo;s discretion and will be set out in the Listing
          description. If no such policy is set out by the Seller, Buyers may
          cancel a booking and receive a refund of the Sale Price, if requested
          24 hours or more before the start time of the Event.
        </p>
        <p>
          Refunds: If a Buyer cancels a booking within the timeframe set out by
          the Seller, if any, the Buyer may be refunded the Sale Price for such
          booking within a commercially reasonable amount of time, typically one
          week after a refund is agreed upon. Service Charges, if applicable to
          the Buyer, are non-refundable.
        </p>
        <p>
          The Seller and the Buyer must arrange a refund between themselves and
          Makanak shall have no responsibility or liability for any such refunds.
        </p>
        <p>
          Disputes: If a Seller fails to fulfil its obligations in relation to a
          Transaction, subject to the refund options a Seller has set out in the
          Listing, Buyers will have 48 hours from the start time of the Event to
          request a refund. If a Buyer requests a refund within this timeframe,
          Makanak will promptly notify the Seller. The Seller will have 48 hours to
          rebut such dispute, resulting in one of two scenarios: (i) if the
          Seller does not rebut such dispute, a full refund of the Sale Price
          will be given to the Buyer by the Seller; or (ii) if the Seller does
          rebut such dispute, the two parties must contact one another to
          resolve the dispute and submit the confirmation of the resolution to
          Makanak within 30 days. If no confirmation of resolution is received by
          Makanak within 30 days, Makanak will resolve the dispute in its sole and
          absolute discretion, which may include a decision to refund the Buyer
          and pursue the Seller for the refunded amount. It is the
          Seller&rsquo;s responsibility to provide proof that a Buyer was in
          attendance at an Event.
        </p>
        <p>
          <strong>ENFORCEMENT ACTION AGAINST BUYERS</strong>
        </p>
        <p>
          Cancelling Transactions: Makanak reserves the right to cancel a
          Buyer&rsquo;s Transaction in any of the following circumstances if:
        </p>
        <ul>
          <li>
            Makanak suspects any fraudulent, illegal or unethical activity relating
            to one or more Transactions; and/or
          </li>
          <li>
            Makanak suspects any unauthorised use of a Buyer&rsquo;s Account and/or
            of the Makanak Services; and/or
          </li>
          <li>a breach of the terms of this Agreement occurs.</li>
        </ul>
        <ol>
          <li>
            <strong> General Terms and Conditions</strong>
          </li>
        </ol>
        <p>
          <strong>DEFINITIONS AND INTERPRETATION</strong>
        </p>
        <p>In this Agreement:</p>
        <ul>
          <li>
            &ldquo;Account&rdquo; means the account set up by you to use the Makanak
            Services.
          </li>
          <li>
            &ldquo;Agreement&rdquo; means these terms and conditions, the{' '}
            <a href="https://www.technoandchill.com/terms-and-conditions">
              Terms of Use
            </a>
            , the{' '}
            <a href="https://www.technoandchill.com/privacy-policy">
              Makanak Privacy Policy
            </a>
            , and any other instructions or terms and conditions made available
            to you.
          </li>
          <li>
            &ldquo;Buyer User Data&rdquo; means the data collected pursuant to
            the bespoke questions, if any, that a Seller is able to include in a
            Listing in order to collect personal data from Buyers during the
            checkout process, as well as personal data the Buyer is able to
            upload into the Makanak Services.
          </li>
          <li>
            &ldquo;Data Protection Legislation&rdquo; means all data and privacy
            laws, rules and regulations to the extent applicable from time to
            time, including without limitation the following legislation
            (&ldquo;EU Data Protection Legislation&rdquo;): (a) national laws
            implementing the Data Protection Directive (95/46/EC) and the
            Directive on Privacy and Electronic Communications (2002/58/EC); and
            (b) any other applicable national privacy law.
          </li>
          <li>
            &ldquo;Event&rdquo; means any event or ticketed activity detailed in
            a Listing for which Tickets may be issued, bought or sold via the
            Makanak Services.
          </li>
          <li>
            &ldquo;Feedback&rdquo; means feedback, comments and suggestions for
            improvements to the Makanak Services posted by Users.
          </li>
          <li>
            &ldquo;Force Majeure&rdquo; means any cause beyond Makanak&rsquo;s
            control including without limitation, acts of God, war,
            insurrection, riot, civil disturbances, acts of terrorism, fire,
            explosion, flood, epidemic, theft of essential equipment, malicious
            damage, strike, lock out, weather, third party injunction, national
            defence requirements, acts or regulations of national or local
            governments.
          </li>
          <li>
            &ldquo;Listing&rdquo; means the Event and Ticket listing(s) created
            by a Seller, for which Tickets are available for sale or
            distribution and which includes the digital content created for that
            Event.
          </li>
          <li>
            &ldquo;Order Confirmation&rdquo; means the written notice confirming
            a Transaction, issued to the Buyer automatically once the booking
            process has been completed, at which point a legally binding
            agreement in respect of the Transaction between that Buyer and that
            Seller is formed.
          </li>
          <li>
            &ldquo;Payment Processing Method&rdquo; means one of the two methods
            of payment processing, either Paymob Payments or Stripe Payments,
            when selling Tickets using the Makanak Services. The default option is
            set to Makanak Payments and Sellers can choose to integrate Stripe
            Payments into their Account to manage their own payment process.
          </li>
          <li>
            &ldquo;Sale Price&rdquo; means the price at which a Seller offers a
            Ticket for sale using the Makanak Services, which can be nil (i.e. some
            Events may be free) and which includes any and all additional costs
            and charges which may be set by the Seller.
          </li>
          <li>
            &ldquo;Service Charge&rdquo; means the service charge payable to Makanak
            for the provision of the Makanak Services, which at the Seller&rsquo;s
            election may either be charged to the Seller (in which case such
            amount will be collected by Makanak prior to settlement of sales
            proceeds) or charged to the Buyer (in which case such amount will
            show in the subtotal on the checkout page and will be paid by the
            Buyer on top of the Sale Price as part of the total Transaction
            Value) for providing the platform, which is inclusive of VAT at the
            then-current rate.
          </li>
          <li>
            &ldquo;Stripe Payments&rdquo; means the integration of Stripe by a
            Seller to process payments made by a Buyer; payments shall be made
            directly to the Seller and all queries and disputes which relate to
            Stripe Payments shall be governed by Stripe.
          </li>
          <li>
            &ldquo;Third-Party Social Media Site&rdquo; means third party
            websites and applications that enable users to create and share
            content or to participate in social networking, including, but not
            limited to, Facebook, Twitter and Google Chrome.
          </li>
          <li>
            &ldquo;Ticket(s)&rdquo; means an electronic ticket or other type of
            evidence of the right to occupy space at or to attend an Event.
          </li>
          <li>
            &ldquo;Transaction&rdquo; means a transaction undertaken via the Makanak
            Services where the Buyer agrees to buy and the Seller agrees to sell
            one or more Tickets at the listed Sale Price(s).
          </li>
          <li>
            &ldquo;Transaction Value&rdquo; means the total amount payable by a
            Buyer pursuant to a Transaction, which includes all the costs and
            fees set out in paragraph 12.2.
          </li>
          <li>
            &ldquo;Makanak Account Information&rdquo; means the personal data you
            upload when registering your Account.
          </li>
          <li>
            &ldquo;Makanak Payments&rdquo; means the Payment Processing Method
            option which requires no set up by the Seller and through which the
            Transaction Value gets paid to Makanak who will remit the net Sale Price
            amount(s) to the Seller 48 to 72 hours after the end of the Event,
            subject to the terms of this Agreement.
          </li>
          <li>
            &ldquo;Makanak Services&rdquo; means all of Makanak&rsquo;s online services,
            including but not limited to the Website, the software application
            available for download to a smartphone which contains a modified
            version of the Website and all mobile services, any software
            provided and any related tools and services provided by Makanak.
          </li>
          <li>
            &ldquo;Venue&rdquo; means any site, facility or location where an
            Event is held.
          </li>
          <li>
            &ldquo;Website&rdquo; means any website run or provided by Makanak,
            including any software application available for download to a
            smartphone which contains a modified version of the Website.
          </li>
        </ul>
        <p>
          Interpretation: The terms &ldquo;including&rdquo;,
          &ldquo;include&rdquo;, &ldquo;for example&rdquo; or any similar
          expression shall be construed as illustrative and shall not limit the
          sense of the words, description, definition, phrase or term preceding
          those terms.
        </p>
        <p>
          Headings: The headings used in this Agreement are for reference
          purposes only and do not affect its interpretation.
        </p>
        <p>
          <strong>AGREEMENT TERM AND TERMINATION</strong>
        </p>
        <p>
          Commencement and Term: This Agreement shall commence on the date that
          you register an Account with Makanak or purchase a ticket through Makanak and
          shall continue indefinitely subject to earlier termination by either
          you or Makanak.
        </p>
        <p>
          <strong>
            Termination by Makanak : Makanak may terminate this Agreement if:
          </strong>
        </p>
        <ul>
          <li>
            any abusive or threatening behaviour is reported to Makanak as being
            carried out by you or via your Account;
          </li>
          <li>
            Makanak suspects any unauthorised use of your Account and/or the Makanak
            Services;
          </li>
          <li>
            Makanak suspects any fraudulent activity or other illegal activity
            relating to your Account;
          </li>
          <li>you otherwise breach the terms of this Agreement;</li>
          <li>Makanak is ordered to do so by any legal or regulatory authority;</li>
          <li>
            You are an individual, and you are unable to pay your debts as they
            fall due or are declared bankrupt or if you are a company and you
            become subject to an administration order or make a voluntary
            arrangement or composition with your creditors, or an encumbrancer
            takes possession of or a receiver is appointed over your property or
            assets, or the company is wound up or ceases or threatens to cease
            to carry on business except for the purposes of reconstruction;
            and/or
          </li>
          <li>
            Makanak provides you with 30 days&rsquo; written notice of termination,
            at Makanak&rsquo;s election.
          </li>
        </ul>
        <p>
          <br /> Termination by You: You may cancel your Account at any time via
          the by sending an email to info@themrblack.com. If you are a Seller,
          before cancelling your account, you must cancel any Tickets you have
          sold for Events that have not taken place, notify Buyers and refund
          the Buyers, if you are using Stripe Payments, or instruct Makanak to
          refund Buyers, if using Makanak Payments. Please note that if your Account
          is cancelled, Makanak does not have an obligation to delete or return to
          you any User Content you have posted to the Makanak Services.
        </p>
        <p>
          Consequences of Termination: Termination of this Agreement shall not
          affect either your or Makanak&rsquo;s rights and liabilities accrued prior
          to and including the date of termination and/or any terms intended
          expressly or by implication to survive termination.
        </p>
        <p>
          <strong>LICENSES</strong>
        </p>
        <p>
          User Licence: Subject to your compliance with this Agreement, Makanak
          grants you a limited, non-exclusive, non-transferable licence, to
          access and view content via the Makanak Services for your personal use and
          solely for the purposes expressly permitted by the Makanak Services. This
          license is expressly conditioned on your pre-existing agreement to
          comply with, and your actual compliance with, each of the provisions
          described in this Agreement.
        </p>
        <p>
          User Licence Restrictions: You will not use, copy, adapt, modify,
          prepare derivative works based upon, distribute, license, sublicense,
          sell, transfer, publicly display, publicly perform, transmit,
          broadcast, or otherwise exploit the Makanak Services, except as expressly
          permitted in this Agreement. No licences or rights are granted to you
          by implication or otherwise under any intellectual property rights
          owned or controlled by Makanak or its licensors, except for the licences
          and rights expressly granted in this Agreement.
        </p>
        <p>
          Trademarks: logos as well as the name and any designs found on the
          Website are registered trademarks of Makanak. Makanak&rsquo;s trademarks may
          be used publicly only with our written permission. Except as expressly
          stated in this Agreement, Makanak does not grant any express or implied
          right to you under any of its trademarks, copyrights or other
          proprietary information.
        </p>
        <p>
          <strong>Makanak SERVICE RULES</strong>
        </p>
        <p>
          Rules: In connection with your use of the Makanak Services, you may not
          and you agree that you will not:
        </p>
        <ul>
          <li>
            breach any local, state, provincial, national, or other law or
            regulation, or any order of a court;
          </li>
          <li>
            infringe the rights of any person or entity, including without
            limitation, their intellectual property, privacy, publicity or
            contractual rights;
          </li>
          <li>
            interfere with or damage the Makanak Services, including, without
            limitation, through the use of viruses, bots, Trojan horses, harmful
            code, flood pings, denial-of-service attacks, packet or IP spoofing,
            forged routing or electronic mail address information or similar
            methods or technology;
          </li>
          <li>
            use the Makanak Services in connection with the distribution of
            unsolicited commercial email (&ldquo;spam&rdquo;) or advertisements
            unrelated to the Makanak Services;
          </li>
          <li>
            &ldquo;stalk&rdquo; or harass any other User of the Makanak Services or
            collect or store any personally identifiable information about any
            other User (except as expressly permitted by the Makanak Services);
          </li>
          <li>
            submit, or provide links to, any postings containing material that
            could be considered harmful, obscene, pornographic, sexually
            explicit, indecent, lewd, violent, abusive, profane, insulting,
            threatening, harassing, hateful or otherwise objectionable, includes
            the image or likeness of individuals under 18 years of age,
            encourages or otherwise depicts or glamorizes drug use (including
            alcohol and cigarettes), characterizes violence as acceptable,
            glamorous or desirable, or contains any personal contact information
            or other personal information identifying any third party;
          </li>
          <li>
            submit, or provide links to, any postings containing material that
            harasses, victimizes, degrades, or intimidates an individual or
            group of individuals on the basis of religion, race, ethnicity,
            sexual orientation, gender, age, or disability;
          </li>
          <li>
            register for more than one Account or register for an Account on
            behalf of an individual other than yourself;
          </li>
          <li>
            recruit or otherwise solicit any Seller or other User to join third
            party services or websites that are competitive to Makanak, without Makanak
            prior written approval;
          </li>
          <li>
            impersonate any person or entity, or falsify or otherwise
            misrepresent yourself or your affiliation with any person or entity,
            including any employee or representative of our company;
          </li>
          <li>
            use automated scripts to collect information or otherwise interact
            with the Makanak Services;
          </li>
          <li>
            use the Makanak Services to find a Seller or Buyer and then complete the
            transaction independent of the Makanak Services in order to circumvent
            the obligation to pay any fees related to Makanak&rsquo;s provision of
            the Listing;
          </li>
          <li>
            as a Seller, submit any Listing with a false or misleading price
            information, or submit any Listing with a price, service or activity
            that you do not intend to honour;
          </li>
          <li>
            use, display, mirror or frame the Makanak Services, or any individual
            element within the Makanak Services, Makanak&rsquo;s name, any Makanak
            trademark, logo or other proprietary information, or the layout and
            design of any page or form contained on a page, without Makanak&rsquo;s
            express written consent;
          </li>
          <li>
            access, tamper with, or use non-public areas of the Makanak Services,
            Makanak&rsquo;s computer systems, or the technical delivery systems of
            Makanak&rsquo;s providers;
          </li>
          <li>
            attempt to probe, scan, or test the vulnerability of any Makanak system
            or network or breach any security or authentication measures;
          </li>
          <li>
            avoid, bypass, remove, deactivate, impair, descramble or otherwise
            circumvent any technological measure implemented by Makanak or any of
            Makanak&rsquo;s providers or any other third party (including another
            User) to protect the Makanak Services or any content thereon;
          </li>
          <li>
            attempt to decipher, decompile, disassemble or reverse engineer any
            of the software used to provide the Makanak Services;
          </li>
          <li>
            reproduce or scan Tickets in a format or medium different from that
            provided by the Website;
          </li>
          <li>
            use any automated software or computer system to search for,
            reserve, buy or otherwise obtain Tickets, discount codes,
            promotional codes, vouchers, gift cards or any other items available
            on the Website, including sending information from your computer to
            another computer where such software or system is active; and/or
          </li>
          <li>
            advocate, encourage, or assist any third party in doing any of the
            foregoing.
          </li>
        </ul>
        <p>
          Legal Action: Makanak reserves the right to investigate and take
          appropriate legal action against any illegal and/or unauthorised use
          of the Makanak Services. Makanak may involve and cooperate with law
          enforcement authorities in prosecuting Users who breach this
          Agreement, including, without limitation, civil, criminal and
          injunctive redress. You agree that monetary damages may not provide
          Makanak a sufficient remedy and that Makanak may pursue injunctive or other
          relief for your violation of these Terms. If Makanak determines that you
          have violated these Terms or the law, or for any other reason or for
          no reason, Makanak may cancel your Account, delete all your User content
          and prevent you from accessing the Website at any time without notice
          to you. If that happens, you may no longer use the Makanak Services or the
          Website. You will still be bound by your obligations under these
          Terms. You agree that Makanak will not be liable to you or any third party
          for termination of your access to the Website or to your account or
          any related information, and Makanak will not be required to make the
          Website or your account or any related information available to you.
        </p>
        <p>
          <strong>WARRANTIES AND INDEMNITIES</strong>
        </p>
        <p>
          True Information: You represent and warrant that the information that
          you submit to Makanak for your Account and in your use of the Makanak Services
          is true, accurate and complete and you will not use false information,
          including name and email address, in using the Makanak Services.
        </p>
        <p>
          Legal Compliance: You represent and warrant that in using the Makanak
          Services, you shall comply will all applicable local, regional,
          national and international laws, regulations, codes of practice and
          the terms of this Agreement. You further warrant that you are aged 13
          years or over and can enter into legally binding contracts.
        </p>
        <p>
          Tax Indemnity : You agree that Makanak is not in any way responsible for
          the accuracy or suitability of any payment of taxes to any entity on
          your behalf (whether Buyer and/or Seller). If in using the Makanak
          Services you are trading as a business, you hereby indemnify and hold
          Makanak harmless against all liabilities, costs, interests and expenses
          (including reasonable legal fees) incurred by Makanak that arise out of
          any third party or governmental claim that involves, relates to or
          concerns any local, regional or national tax obligation or amounts due
          or owing under any tax regulation, order, law or decree or any dispute
          concerned the tax status of Makanak.
        </p>
        <p>
          General Indemnity: You agree to indemnify and hold Makanak and its parent
          and affiliated companies, suppliers, advertisers and sponsors, and
          each of their officers, directors, employees, agents, legal
          representatives and sub-contractors (the &ldquo;Indemnified
          Parties&rdquo;) harmless against any claim or demand and all
          liabilities, costs and expenses (including reasonable legal fees and
          costs) incurred by an Indemnified Party arising as a result of or in
          connection with your use of the Website, breach of this Agreement
          (including any misuse of the Makanak Services) and/or your violation of
          any law or the rights of a third party. We reserve the right to take
          exclusive control and defense of any claim, and you will cooperate
          fully with us in asserting any available defenses.
        </p>
        <p>
          <strong>DISCLAIMERS</strong>
        </p>
        <p>
          Makanak Disclaimer: Makanak disclaims any and all liability for the acts,
          omissions and conduct of any third-party users, Users, advertisers
          and/or sponsors on the Website, in connection with the Makanak Services or
          otherwise related to your use of the Website and/or the Makanak Services.
        </p>
        <p>
          Makanak WE PROVIDE THE SITE AND THE CONTENT TO YOU &ldquo;AS IS&rdquo; AND
          &ldquo;AS AVAILABLE&rdquo;. WE TRY TO KEEP THE SITE UP, BUG-FREE AND
          SAFE, BUT YOU USE IT AT YOUR OWN RISK. TO THE FULLEST EXTENT
          PERMISSIBLE BY LAW, AND TO THE EXTENT THAT APPLICABLE LAW PERMITS THE
          DISCLAIMER OF EXPRESS OR IMPLIED WARRANTIES, Makanak DISCLAIMS ALL
          WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY OF TITLE,
          NON-INFRINGEMENT, ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, OR WARRANTIES THAT MAY ARISE FROM COURSE OF DEALING OR COURSE
          OF PERFORMANCE OR USAGE OF TRADE. Makanak DOES NOT GUARANTEE THAT THE SITE
          WILL ALWAYS BE SAFE, SECURE OR ERROR-FREE OR THAT THE SITE WILL ALWAYS
          FUNCTION WITHOUT DISRUPTIONS, DELAYS OR IMPERFECTIONS. WE ARE NOT
          RESPONSIBLE FOR THE ACTIONS OR INFORMATION OF THIRD PARTIES, AND YOU
          RELEASE US FROM ANY CLAIMS AND DAMAGES, KNOWN AND UNKNOWN, ARISING OUT
          OF OR IN ANY WAY CONNECTED WITH ANY CLAIM YOU HAVE AGAINST ANY SUCH
          THIRD PARTIES. IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE CALIFORNIA
          CIVIL CODE &sect;1542, WHICH SAYS: A GENERAL RELEASE DOES NOT EXTEND
          TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS
          OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY
          HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH
          THE DEBTOR.
        </p>
        <p>
          Makanak is not responsible for the products, services, actions or failure
          to act of any venue, performer, promoter or other third party in
          connection with or referenced on the Website. Without limiting the
          foregoing, you may report the misconduct of Users and/or third party
          advertisers, service and/or product providers referenced on or
          included in the Website to Makanak using our&nbsp;
          <a href="https://universesupport.zendesk.com/hc/en-us">
            online help desk
          </a>
          . Makanak may investigate a claim and take appropriate action, in its sole
          discretion.
        </p>
        <p>
          <strong>LIABILITY</strong>
        </p>
        <p>
          Makanak Disclaimer: Due to the nature of Makanak&rsquo;s business, and the
          fact that Makanak cannot control Users&rsquo; acts or omissions,
        </p>
        <p>
          Makanak makes no warranty, and specifically disclaims all liability, in
          relation to any goods or services provided by Sellers.
        </p>
        <p>
          Event Attendance: Under no circumstances shall Makanak be liable for death
          or personal injury suffered by you or your guests arising out of
          attendance at an Event unless directly caused by Makanak&rsquo;s gross
          negligence. Neither shall Makanak be liable for any loss or damage
          sustained to your property or belongings or that of your guests
          attending an Event.
        </p>
        <p>
          <strong>Limitation of Liability:</strong>
        </p>
        <p>
          IN NO EVENT WILL Makanak OR ITS SUPPLIERS, ADVERTISERS AND SPONSORS, BE
          RESPONSIBLE OR LIABLE TO YOU OR ANYONE ELSE FOR, AND YOU HEREBY
          KNOWINGLY AND EXPRESSLY WAIVE ALL RIGHTS TO SEEK, DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES OF
          ANY TYPE (INCLUDING WITHOUT LIMITATION LOSS OF DATA, PROFITS, REVENUE,
          GOODWILL, REPUTATION, LOSS OF ENJOYMENT OR OPPORTUNITY) OTHER THAN OUT
          OF POCKET EXPENSES, AND ANY RIGHTS TO HAVE DAMAGES MULTIPLIED OR
          OTHERWISE INCREASED, ARISING DIRECTLY OR INDIRECTLY OUT OF OR IN
          CONNECTION WITH THE Makanak SERVICES, THE WEBSITE, THE CONTENT, OR ANY
          PRODUCT OR SERVICE PURCHASED THROUGH THE SITE, EVEN IF Makanak HAS BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND REGARDLESS OF WHETHER
          THE CLAIM IS BASED UPON ANY CONTRACT, TORT, OR OTHER LEGAL OR
          EQUITABLE THEORY. WITHOUT LIMITING THE FOREGOING, YOU EXPRESSLY
          ACKNOWLEDGE AND AGREE THAT Makanak WILL HAVE NO LIABILITY OR
          RESPONSIBILITY WHATSOEVER FOR (a) ANY FAILURE OF ANOTHER USER OF THE
          SITE TO CONFORM TO THE CODES OF CONDUCT, (b) PERSONAL INJURY OR
          PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, WHETHER ARISING IN CONTRACT
          OR IN TORT, RESULTING FROM YOUR ACCESS TO AND USE OF THE WEBSITE, (c)
          ANY UNAUTHORIZED ACCESS TO OR USE OF Makanak&rsquo;S SECURE SERVERS AND/OR
          ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN, (d) ANY BUGS, VIRUSES, WORMS, TROJAN HORSES, DEFECTS, DATE
          BOMBS, TIME BOMBS OR OTHER ITEMS OF A DESTRUCTIVE NATURE WHICH MAY BE
          TRANSMITTED TO OR THROUGH THE WEBSITE, (e) ANY ERRORS, MISTAKES,
          INACCURACIES OR OMISSIONS IN ANY CONTENT, OR (f) ANY LOST, STOLEN OR
          DAMAGED TICKETS, OR THE FAILURE OF A VENUE TO HONOR A TICKET. YOUR
          SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE Makanak SERVICES
          AND THE WEBSITE IS TO STOP USING THE Makanak SERVICES AND THE WEBSITE. THE
          LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY
          FAILS OF ITS ESSENTIAL PURPOSE. THE ALLOCATION OF RISK BETWEEN YOU AND
          Makanak IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN YOU
          AND Makanak. Makanak&rsquo;S AGGREGATE LIABILITY ARISING OUT OF THESE TERMS OR
          THE USE OF Makanak SERVICES OR THE WEBSITE WILL NOT EXCEED THE GREATER OF
          ONE HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID Makanak IN THE PAST
          TWELVE MONTHS. IN NO EVENT WILL ATTORNEYS&rsquo; FEES BE AWARDED OR
          RECOVERABLE. Makanak&rsquo;S LIABILITY WILL BE LIMITED UNDER THIS
          PARAGRAPH TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, AND THE
          PROVISIONS OF THIS PARAGRAPH WILL NOT APPLY TO THE EXTENT APPLICABLE
          LAW PERMITS THE RECOVERY OF DAMAGES, ATTORNEYS&rsquo; FEES OR COSTS
          OTHERWISE PROHIBITED UNDER THIS PARAGRAPH. THE PROVISIONS OF THIS
          PARAGRAPH THAT (A) PROHIBIT DAMAGES TO BE MULTIPLIED OR OTHERWISE
          INCREASED, (B) IMPOSE A DAMAGES LIMITATION OF THE GREATER OF ONE
          HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID Makanak IN THE PAST
          TWELVE MONTHS, AND (C) PROHIBIT THE RECOVERY OF ATTORNEYS&rsquo; FEES
          AND COSTS, DO NOT APPLY IN CERTAIN STATES, INCLUDING WITHOUT
          LIMITATION NEW JERSEY, TO CLAIMS BROUGHT UNDER STATUTES PERMITTING
          SUCH RECOVERY.
        </p>
        <p>
          Force Majeure: Makanak will not be liable to you for failure to perform
          any of its obligations under this Agreement to the extent that the
          failure is caused by reason of Force Majeure.
        </p>
        <p>
          <strong>DISPUTE RESOLUTION</strong>
        </p>
        <ul>
          <li>
            If any dispute arises out of this Agreement or any Transaction
            pursuant to this Agreement, you should contact{' '}
            <a href="mailto:info@technoandchill.com">info@technoandchill.com</a>{' '}
            and we will try to resolve the matter as expediently as possible.
            Your complaint shall be acknowledged by Makanak as soon as possible.
          </li>
          <li>
            Makanak shall use reasonable endeavours to consult or negotiate in good
            faith and attempt to reach a just and equitable settlement that is
            satisfactory to you and Makanak.
          </li>
          <li>
            In the unlikely event that we are able to resolve your complaint by
            negotiation within 28 days, we may attempt to settle a matter via
            mediation. To initiate a mediation a party must give written notice
            to the other party to the dispute requesting a mediation. The
            mediation shall be conducted in accordance with the STAR Code of
            Practice and Dispute Resolution Procedure current at the date of the
            referral which sets out the procedures to be adopted, the process of
            selection of the mediator and the costs involved and which terms are
            deemed incorporated into this agreement.
          </li>
          <li>
            The above does not restrict your statutory rights, your right to
            make a complaint to Trading Standards or another appropriate
            regulatory authority, or your right to pursue court proceedings or
            other forms of settlement such as through the European Union Online
            Dispute Resolution Platform, a portal through which you can submit a
            complaint to a registered Alternative Dispute Resolution Provider
            with the aim of resolving the dispute.
          </li>
        </ul>
        <p>
          For U.S. and Canada Residents:&nbsp;Any dispute or claim relating in
          any way to your use of the Website, or to products or services sold or
          distributed by us or through us, will be resolved by binding
          arbitration rather than in court, with the following exceptions:
        </p>
        <ul>
          <li>
            You may assert claims in small claims court if your claims apply;
          </li>
          <li>
            If a claim involves the conditional license granted to you as
            described in the Ownership of Content and Grant of Conditional
            License section above, either of us may file a lawsuit in a federal
            or state court located within Los Angeles County, California, or
            Toronto, Ontario, as applicable, and we both consent to the
            jurisdiction of those courts for such purposes; and
          </li>
          <li>
            In the event that the arbitration agreement in these Terms is for
            any reason held to be unenforceable, any litigation against us
            (except for small-claims court actions) may be commenced only in a
            federal or state court located within Los Angeles County,
            California, or Toronto, Ontario, as applicable, and we both consent
            to the jurisdiction of those courts for such purposes.
          </li>
          <li>
            The arbitration agreement in these Terms is governed by the Federal
            Arbitration Act (FAA) or the Canada Commercial Arbitration Act
            (CAA), as applicable, including its procedural provisions, in all
            respects. This means that the FAA or the CAA, as applicable,
            governs, among other things, the interpretation and enforcement of
            this arbitration agreement and all of its provisions, including,
            without limitation, the class action waiver discussed below. State
            arbitration laws do not govern in any respect.
          </li>
          <li>
            This arbitration agreement is intended to be broadly interpreted,
            and will survive termination of these Terms. The arbitrator, and not
            any court or agency, shall have exclusive authority to the extent
            permitted by law to resolve all disputes arising out of or relating
            to the interpretation, applicability, enforceability or formation of
            this Agreement, including, but not limited to any claim that all or
            any part of this Agreement is void or voidable. There is no judge or
            jury in arbitration, and court review of an arbitration award is
            limited. However, an arbitrator can award on an individual basis the
            same damages and relief as a court (including injunctive and
            declaratory relief or statutory damages), and must follow these
            Terms as a court would.
          </li>
          <li>
            To begin an arbitration proceeding, you must send a letter
            requesting arbitration and describing your claim to Makanak
            LLC at: 1745 Wilcox Avenue, Los Angeles, CA 90028, USA. We each
            agree that the arbitrator may not consolidate more than one
            person&rsquo;s claims, and may not otherwise preside over any form
            of a representative or class proceeding, and that any dispute
            resolution proceedings will be conducted only on an individual basis
            and not in a class, consolidated or representative action. You agree
            to waive any right to a jury trial or to participate in a class
            action. If this specific provision is found to be unenforceable,
            then the entirety of this arbitration section will be null and void
            and neither of us will be entitled to arbitrate our dispute.
          </li>
          <li>
            You agree that these Terms evidence a transaction involving
            interstate commerce and will be governed by and construed in
            accordance with U.S. federal law or Ontario law, as applicable, to
            the fullest extent possible.
          </li>
        </ul>
        <p>
          <strong>GENERAL</strong>
        </p>
        <p>
          Taxes: As a User of the Makanak Services, you agree that you are solely
          responsible for determining your applicable tax reporting requirements
          in consultation with your tax advisors. Makanak cannot and does not offer
          tax-related advice to any Users. Additionally, please note that each
          Seller is responsible for determining local indirect taxes and for
          including any applicable taxes to be collected or obligations relating
          to applicable taxes in Listings. You are also responsible for paying
          any governmental taxes imposed on your purchase from or use of the Makanak
          Services, including, but not limited to, sales, use, or value-added
          taxes. To the extent Makanak is required to collect such taxes, the
          applicable tax will be added to your billing account and Makanak will
          issue a receipt on request from a Seller as required by law.
        </p>
        <p>
          Waiver: If Makanak delays or fails to enforce any of the provisions of
          this Agreement, it shall not mean that Makanak has waived the right to do
          so.
        </p>
        <p>
          Assignment: Makanak shall be entitled to assign its rights and obligations
          under this Agreement provided that your rights are not adversely
          affected.
        </p>
        <p>
          Severability: If it is found by a Court that any provision of this
          Agreement for any reason is invalid or cannot be enforced, this shall
          not prevent the other provisions from continuing to remain in full
          force and operate separately.
        </p>
        <p>
          Illegality: If any provision of this Agreement is or becomes illegal,
          invalid or unenforceable under the law of any jurisdiction, that shall
          not affect or impair the legality, validity or enforceability in that
          jurisdiction of any other provision of this Agreement.
        </p>
        <p>
          No Partnership/Agency: Nothing contained within this Agreement and no
          action taken by you or Makanak under this Agreement shall create, or be
          deemed to create, a partnership, joint venture, or establish a
          relationship of principal and agent or any other relationship between
          you and Makanak beyond the relationship created under this Agreement.
        </p>
        <p>
          Entire Agreement: This Agreement and all documents incorporated into
          this Agreement by reference constitutes the entire agreement between
          the parties in connection to its subject matter and supersedes any
          previous agreement, terms and conditions or arrangement between the
          parties, whether written or oral. Each of the parties agrees that it
          has not entered into this Agreement in reliance on, and shall have no
          remedy in respect of any statement, representation, covenant,
          warranty, undertaking or indemnity (whether negligently or innocently
          made) by any person (whether party to this Agreement or not) other
          than as expressly set out in this Agreement. Nothing in this paragraph
          shall limit any liability for (or remedy in respect of) fraud or
          fraudulent misrepresentation.
        </p>
        <p>
          Notices: A notice provided under this Agreement shall be delivered
          upon receipt and shall be deemed to have been received (i) at the time
          of delivery, if delivered by hand, registered post or courier; or (ii)
          at the time of transmission if delivered by email (and no error
          message is received in response).
        </p>
        <p>
          Governing Law and Jurisdiction: This Agreement shall be governed by
          and construed in all respects in accordance with the law of the
          country in which this Website is based, without regard to its
          conflict-of-law provisions, and the parties agree to submit to the
          non-exclusive jurisdiction of the courts of such country.
        </p>
        <p>
          <strong>Questions</strong>
        </p>
        <p>
          Questions regarding these Terms, Our Privacy Policy, or other policy
          related material can be directed to our support staff by emailing us
          at:&nbsp;
          <a href="mailto:support@technoandchill.com">
            support@technoandchill.com
          </a>
        </p>
        <br></br>
        <br></br>
      </Stack>
    </div>
  );
};
