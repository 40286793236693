import {
  Box,
  Button,
  Divider,
  Typography,
  Grid,
  Stack,
  Card,
  IconButton,
  Collapse,
  Select,
  MenuItem,
  Table,
} from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LaunchIcon from '@mui/icons-material/Launch';
import { Request } from 'src/models/request';
import { createUseStyles } from 'react-jss';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useIsBigScreen } from 'src/hooks/useBigScreen';
import { TicketInvitesForm } from './TicketInvitesForm';
import { Event } from 'src/models/event';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { deleteRequest } from '../../slices/request';
import { FaCheck } from 'react-icons/fa';
import { Container } from '@mui/system';
import { TailSpin } from 'react-loading-icons';
const useStyles = createUseStyles({
  boldHeader: {
    fontWeight: 900,
  },
  pendingButton: {
    fontFamily: 'SF Pro Display',
    fontSize: '30px',
    borderRadius: '50%',
    minWidth: '50px',
    height: '50px',
    maxWidth: '50px',
    minHeight: '50px',
    maxHeight: '50px',
    border: '4px solid #989898',
  },
  cancelButton: {
    '&:hover': {
      color: 'white',
      backgroundColor: '#989898',
    },
    backgroundColor: 'white',
    border: '1px solid #989898',
    color: '#989898',
    padding: '16px 30px',
    '@media (max-width: 1500px)': {
      padding: '12.2px 24px',
    },
  },
  inviteMenu: {
    width: '100%',
    height: '45px',
    backgroundColor: '#F4B708',
    borderRadius: '21px',
    color: 'white',
    fontSize: '14px',
    textAlign: 'center',
  },
  header: {
    color: 'black',
    fontSize: '25px',
    textTransform: 'none',
  },
  parent: {
    textAlign: 'start',
  },
  child: {
    display: 'inline-block',
    marginLeft: '0.5rem',
  },

  launchIcon: {
    color: 'black',
  },
  button: {
    height: '45px',
    borderRadius: '50%',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '19px',
  },
  rejectedMenu: {
    width: '100%',

    height: '45px',
    fontSize: '14px',
    backgroundColor: '#FF2F2F',

    borderRadius: '21px',
    color: 'white',

    textAlign: 'center',
  },
  acceptedMenu: {
    width: '100%',

    height: '45px',
    backgroundColor: '#28AF86',
    borderRadius: '21px',
    color: 'white',
    fontSize: '14px',
    textAlign: 'center',
  },
  icon: {
    color: 'white',
    fill: 'white',
  },
  paidMenu: {
    width: '100%',

    height: '45px',

    backgroundColor: 'black',
    borderRadius: '21px',
    color: 'white',
    fontSize: '14px',

    textAlign: 'center',
  },
});

interface Props {
  requests: Request[] | null;
  event: Event;
  onClickApprove: (requestId: string) => void;
  onClickDecline: (requestId: string) => void;
  onClickResendRequestEmail: (requestId: string) => void;
  searchText: string | undefined;
}
const RequestsdCardMobile: React.VFC<Props> = ({
  requests,
  event,
  onClickApprove,
  onClickDecline,
  onClickResendRequestEmail,
  searchText,
}) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState({
    approved: false,
    pending: false,
    declined: false,
    invitations: false,
    all: true,
  });
  const { requestLoadingId, approvedOrRejected } = useAppSelector(
    ({ request: { requestLoadingId, approvedOrRejected } }) => ({
      requestLoadingId,
      approvedOrRejected,
    }),
  );

  const getTicketNameForSearch = (request: Request) => {
    const tickets = request.tickets.filter(
      (ticket) =>
        ticket.id &&
        request.ticketsNumberById[ticket.id] &&
        request.ticketsNumberById[ticket.id] > 0,
    );
    return tickets[0]?.name;
  };
  const [expandedRequestId, setExpandedRequestId] = useState<
    string | undefined
  >();
  const isBigScreen = useIsBigScreen();
  const handleClickCard = (requestId: string) => {
    if (expandedRequestId === requestId) {
      setExpandedRequestId(undefined);
    } else {
      setExpandedRequestId(requestId);
    }
  };

  const handleInviteCancellation = (id: string) => {
    dispatch(deleteRequest({ id, eventId: event.id }));
  };
  const [popUp, setPopUp] = useState(false);
  const [popupRequestId, setPopupRequestId] = useState('');
  const handleClickOpen = (requestId: string) => {
    setPopUp(true);
    setPopupRequestId(requestId);
  };

  const buildNameSection = (request: Request) => {
    const tickets = request.tickets.filter(
      (ticket) =>
        ticket.id &&
        request.ticketsNumberById[ticket.id] &&
        request.ticketsNumberById[ticket.id] > 0,
    );
    return (
      <Grid item xs={6} onClick={() => handleClickCard(request.id)}>
        <Stack>
          <Grid item xs={12} sm={12}>
            <Typography
              textOverflow="ellipsis"
              fontSize={'14px'}
              fontWeight={800}
              display="inline-block"
            >
              {request.user?.firstName} {request.user?.lastName}
              {request.recipients.length > 1
                ? ' +' + (request.recipients.length - 1)
                : ''}
            </Typography>
          </Grid>
          <Typography
            fontSize={14}
            fontFamily={'SF Pro Display'}
            textOverflow="ellipsis"
          >
            {tickets[0]?.name || ''}
          </Typography>
          {expandedRequestId == request.id && (
            <Typography
              fontSize={13}
              fontFamily={'SF Pro Display'}
              fontWeight={700}
              textOverflow="ellipsis"
            >
              {request.user?.phoneNumber}
            </Typography>
          )}

          <Collapse in={expandedRequestId === request.id}>
            <Box sx={{ m: 1 }} />
            {request.recipients.map((recipient, index) => (
              <Grid item key={index} xs={12}>
                <Container
                  sx={{
                    width: '80vw',
                    backgroundColor: index % 2 === 0 ? '#EAEAEA' : '#FFFFFF',
                    marginBottom: '4px',
                    borderRadius: '5px',
                    maxWidth: '90vw',
                  }}
                >
                  {' '}
                  <Stack
                    direction="row"
                    spacing={0}
                    justifyContent="space-between"
                  >
                    {' '}
                    <Typography
                      variant="size4"
                      fontWeight={600}
                      fontSize="14px !important"
                    >
                      {index + 1}. {recipient.name}
                    </Typography>
                    <a href={recipient.link} target="_blank" rel="noreferrer">
                      <LaunchIcon
                        className={classes.launchIcon}
                        fontSize={isBigScreen ? 'medium' : 'small'}
                      />
                    </a>
                  </Stack>
                </Container>
              </Grid>
            ))}
            <Stack
              direction="row"
              spacing={0}
              justifyContent="center"
              minWidth="80vw"
              justifySelf="center"
              alignSelf={'center'}
            >
              <Grid item xs={3} md={3}>
                <Typography
                  fontSize={{ xs: 15 }}
                  fontWeight={800}
                  fontFamily={'SF Pro Display'}
                  display="inline"
                >
                  M/F &nbsp;&nbsp;&nbsp;{' '}
                </Typography>
                <Typography
                  fontSize={{ xs: 15 }}
                  variant="size4"
                  fontWeight={500}
                  style={{
                    color:
                      (request.recipients.filter(
                        (recipient) =>
                          recipient.gender == 'male' ||
                          recipient.gender == 'other',
                      ).length ?? 0) >
                      (request.recipients.filter(
                        (recipient) => recipient.gender == 'female',
                      ).length ?? 0)
                        ? 'red'
                        : undefined,
                  }}
                  display="inline"
                >
                  {
                    request.recipients.filter(
                      (recipient) =>
                        recipient.gender == 'male' ||
                        recipient.gender == 'other',
                    ).length
                  }
                </Typography>
                <Typography
                  variant="size4"
                  fontSize={{ xs: 15 }}
                  fontWeight={500}
                  display="inline"
                >
                  /{' '}
                  {
                    request.recipients.filter(
                      (recipient) => recipient.gender == 'female',
                    ).length
                  }
                </Typography>
              </Grid>
            </Stack>
          </Collapse>
        </Stack>
      </Grid>
    );
  };

  const buildApproveSection = (request: Request) => (
    <>
      <Grid
        container
        justifyContent="space-between"
        justifyItems={'space-between'}
        direction="row"
        alignSelf={'baseline'}
        justifySelf={'baseline'}
        display={'flex'}
        xs={5}
        position={'relative'}
      >
        {requestLoadingId[request.id] == 1 && (
          <Grid
            item
            xs={12}
            md={12}
            display="flex"
            justifySelf={'center'}
            justifyContent={'center'}
          >
            <TailSpin
              style={{ marginBottom: '10px' }}
              scale={'2%'}
              stroke="#000000"
              speed={0.75}
            />
          </Grid>
        )}
        {request.isPending == true &&
          approvedOrRejected[request.id] == undefined &&
          (requestLoadingId[request.id] == 0 ||
            requestLoadingId[request.id] == undefined) && (
            <>
              {' '}
              <Grid item xs={6} sx={{ left: 5, position: 'absolute' }}>
                <Button
                  onClick={() => {
                    onClickApprove(request.id);
                  }}
                  size="small"
                  variant="contained"
                  className={classes.pendingButton}
                  style={{
                    backgroundColor:
                      (request.recipients.filter(
                        (recipient) =>
                          recipient.gender == 'male' ||
                          recipient.gender == 'other',
                      ).length ?? 0) >
                      (request.recipients.filter(
                        (recipient) => recipient.gender == 'female',
                      ).length ?? 0)
                        ? '#FFFFFF'
                        : '#28AF86',
                    color:
                      (request.recipients.filter(
                        (recipient) =>
                          recipient.gender == 'male' ||
                          recipient.gender == 'other',
                      ).length ?? 0) >
                      (request.recipients.filter(
                        (recipient) => recipient.gender == 'female',
                      ).length ?? 0)
                        ? '#000000'
                        : '#FFFFFF',
                    border:
                      (request.recipients.filter(
                        (recipient) =>
                          recipient.gender == 'male' ||
                          recipient.gender == 'other',
                      ).length ?? 0) >
                      (request.recipients.filter(
                        (recipient) => recipient.gender == 'female',
                      ).length ?? 0)
                        ? '4px solid #000000'
                        : '3px solid #28AF86',
                  }}
                >
                  <FaCheck size={50} />
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                lg={6}
                xl={6}
                sx={{ right: 0, position: 'absolute' }}
              >
                <Button
                  onClick={() => {
                    onClickDecline(request.id);
                  }}
                  className={classes.pendingButton}
                  style={{
                    backgroundColor:
                      (request.recipients.filter(
                        (recipient) =>
                          recipient.gender == 'male' ||
                          recipient.gender == 'other',
                      ).length ?? 0) >
                      (request.recipients.filter(
                        (recipient) => recipient.gender == 'female',
                      ).length ?? 0)
                        ? '#FF2F2F'
                        : '#FFFFFF',
                    color:
                      (request.recipients.filter(
                        (recipient) =>
                          recipient.gender == 'male' ||
                          recipient.gender == 'other',
                      ).length ?? 0) >
                      (request.recipients.filter(
                        (recipient) => recipient.gender == 'female',
                      ).length ?? 0)
                        ? '#FFFFFF'
                        : '#000000',
                    border:
                      (request.recipients.filter(
                        (recipient) =>
                          recipient.gender == 'male' ||
                          recipient.gender == 'other',
                      ).length ?? 0) >
                      (request.recipients.filter(
                        (recipient) => recipient.gender == 'female',
                      ).length ?? 0)
                        ? '1px solid #FF2F2F'
                        : '4px solid #000000',
                  }}
                >
                  X
                </Button>
              </Grid>
            </>
          )}
        {((request.isDeclined == true &&
          approvedOrRejected[request.id] == undefined &&
          requestLoadingId[request.id] != 1) ||
          (approvedOrRejected[request.id] == 0 &&
            requestLoadingId[request.id] != 1)) && (
          <Grid item xs={12}>
            <Select
              key={request.id}
              value={1}
              className={classes.rejectedMenu}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
            >
              <MenuItem value={1}>Rejected</MenuItem>

              <MenuItem
                onClick={() => {
                  onClickApprove(request.id);
                }}
              >
                Approve
              </MenuItem>
            </Select>
          </Grid>
        )}
        {((request.isApproved == true &&
          approvedOrRejected[request.id] == undefined &&
          requestLoadingId[request.id] != 1) ||
          (approvedOrRejected[request.id] == 1 &&
            requestLoadingId[request.id] != 1)) && (
          <>
            {request.paid ? (
              <Grid item xs={12}>
                <Select
                  key={request.id}
                  value={1}
                  className={classes.paidMenu}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  <MenuItem style={{ display: 'none' }} value={1}>
                    {request.isRefunded ? 'Refunded' : 'Paid'}
                  </MenuItem>

                  {!request.isRefunded && (
                    <MenuItem
                      onClick={() => {
                        // onClickRefund(request.id);
                      }}
                    >
                      Refund
                    </MenuItem>
                  )}
                </Select>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Select
                  key={request.id}
                  value={1}
                  className={classes.acceptedMenu}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  <MenuItem style={{ display: 'none' }} value={1}>
                    Accepted
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      onClickDecline(request.id);
                    }}
                  >
                    Decline
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      onClickResendRequestEmail(request.id);
                    }}
                  >
                    Send Reminder
                  </MenuItem>
                </Select>
              </Grid>
            )}
          </>
        )}{' '}
      </Grid>
    </>
  );

  const buildInviteSection = (request: Request) => (
    <div key={request.id}>
      <Box sx={{ m: 3 }} />
      <Card
        variant="outlined"
        onClick={() => handleClickCard(request.id)}
        sx={{
          maxWidth: '95vw',
          boxShadow: '0px 2px 4px 1px #B9B6B6',
          borderRadius: '15px',
        }}
      >
        <Grid
          container
          direction={'row'}
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingX: 3,
            paddingY: 2,
          }}
        >
          <Grid item xs={6}>
            <Stack>
              <Grid item xs={12} sm={12}>
                <Typography
                  textOverflow="ellipsis"
                  fontSize={'14px'}
                  fontWeight={800}
                  display="inline-block"
                >
                  {request?.recipients?.length > 0
                    ? request.recipients[0].name
                    : ''}
                </Typography>
              </Grid>
              <Typography
                fontSize={14}
                fontFamily={'SF Pro Display'}
                textOverflow="ellipsis"
              >
                {getTicketNameForSearch(request)}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={5}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Select
                key={request.id}
                value={1}
                className={classes.inviteMenu}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem style={{ display: 'none' }} value={1}>
                  Invitation
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClickOpen(request.id);
                  }}
                >
                  Cancel
                </MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </div>
  );

  const buildRequestSection = (request: Request) =>
    request.isInvitation ? (
      buildInviteSection(request)
    ) : (
      <div key={request.id}>
        <Box sx={{ m: 3 }} />
        <Card
          variant="outlined"
          sx={{
            maxWidth: '95vw',
            boxShadow: '0px 2px 4px 1px #B9B6B6',
            borderRadius: '15px',
          }}
        >
          <Grid
            container
            direction={'row'}
            justifyContent="space-between"
            alignItems="center"
            sx={{
              paddingX: 3,
              paddingY: 2,
            }}
          >
            {buildNameSection(request)}
            {buildApproveSection(request)}
          </Grid>
        </Card>
      </div>
    );

  const getSearchTextRegexCondition = (request: Request) => {
    if (!searchText) {
      return true;
    } else {
      const lowerCaseSearchText = searchText.toLowerCase();
      const fullname =
        request?.user?.firstName?.toLowerCase() +
        ' ' +
        request?.user?.lastName?.toLowerCase();
      const ticketname = getTicketNameForSearch(request).toLowerCase();

      return (
        (fullname && new RegExp(`${lowerCaseSearchText}`).test(fullname)) ||
        (ticketname && new RegExp(`${lowerCaseSearchText}`).test(ticketname))
      );
    }
  };
  return (
    <>
      {filter.invitations && <TicketInvitesForm event={event} />}
      {filter.invitations
        ? requests
            ?.filter(
              (request) =>
                request.isInvitation && getSearchTextRegexCondition(request),
            )
            .map(buildRequestSection)
        : filter.pending
        ? requests
            ?.filter(
              (request) =>
                request.isPending && getSearchTextRegexCondition(request),
            )
            .map(buildRequestSection)
        : filter.approved
        ? requests
            ?.filter(
              (request) =>
                request.isApproved && getSearchTextRegexCondition(request),
            )
            .map(buildRequestSection)
        : filter.declined
        ? requests
            ?.filter(
              (request) =>
                request.isDeclined && getSearchTextRegexCondition(request),
            )
            .map(buildRequestSection)
        : requests
            ?.filter(getSearchTextRegexCondition)
            .slice(0, 300)
            .map(buildRequestSection)}
    </>
  );
};

export default RequestsdCardMobile;
