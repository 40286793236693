import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';

import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Container } from '@mui/system';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Ticks } from 'chart.js';

import MarketingRequest from 'src/components/requests/MarketingRequestCard';
import { fetchEventRequests, fetchPlannerMRequests } from 'src/slices/request';

ChartJS.register(ArcElement, Tooltip, Legend);
const useStyles = createUseStyles({
  main: {
    width: '100%',

    backgroundColor: 'white',

    '@media print': {
      marginTop: 0,
      scale: 0.5,
      backgroundColor: 'white',
    },
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',

    justifyContent: 'space-between',
    justifyItems: 'space-between',
    alignItems: 'space-between',
    padding: '0 170px 70px',
    '@media (max-width: 1000px)': {
      padding: '0 100px 30px',
    },
  },
  componentRef: {},
  grid: {
    margin: '0',
    padding: 'auto',
    backgroundColor: 'white',
    width: '100%',
  },
  subheaderButton: {
    '&:hover': {
      opacity: 0.7,
      transition: '0.5s',
    },
    backgroundColor: '#48C9B0',
    color: 'black',
    border: 'solid 1px #FFFFFF ',
    borderRadius: 50,
    height: 40,
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '8px 30px',
  },
  subheaderButton2: {
    '&:hover': {
      opacity: 0.7,
      transition: '0.5s',
      backgroundColor: '#FE5A6D',
    },
    alignSelf: 'center',
    textTransform: 'none',
    borderRadius: 20,
    width: '7rem',
    height: '3rem',
    marginTop: '30px',
    backgroundColor: '#FE5A6D',
    color: 'white',
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '15rem',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  title: {
    fontSize: '45px',
    color: 'black',
    fontWeight: 800,
    fontStyle: 'bold',
  },
  subTitle: {
    fontSize: '30px',
    fontWeight: 800,
    fontStyle: 'bold',
  },
  subSubTitle: {
    fontSize: '20px',
    fontWeight: 700,
    fontStyle: 'bold',
  },
  text: {
    fontSize: '17px',
    fontWeight: 600,
  },

  '@page': {
    size: 'portrait',
  },
});

const instagramCampagin = {
  id: 'Instagram',
  description: '3 Stories + 1 Post/Reel',
  color: '#FE5A6D',
  title: 'Instagram Campaign',
  subTitle: 'www.instagram.com/technoandchill',
};

const EmailCampaign = {
  id: 'Email',
  description: 'Past Requests Campaign',
  color: '#10BF7A',
  title: 'Email Campaign',
  subTitle: 'Newsletters',
};
const BlogPost = {
  id: 'Blog',
  description: '1 Article',
  color: '#66A6E3',
  title: 'Blog Post',
  subTitle: 'www.technoandchillmag.com',
};

const ActiveCampaigns: React.VFC = () => {
  //Intializing styles,history, appdispatch and so on...
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentlyDisplayedEvent, plannerMRequests, sentEmail } =
    useAppSelector(
      ({
        event: { currentlyDisplayedEvent },
        request: { plannerMRequests, sentEmail },
      }) => ({
        currentlyDisplayedEvent: currentlyDisplayedEvent,
        plannerMRequests: plannerMRequests,
        sentEmail,
      }),
    );

  const theme = useTheme();

  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(fetchPlannerMRequests());
  }, []);

  return (
    <div className={classes.main}>
      <Box marginTop={1}>
        <Grid container className={classes.grid}>
          <div className={classes.main}>
            <div className={classes.subheader}>
              <Typography className={classes.title}></Typography>
            </div>

            <Container
              sx={{
                minWidth: '75%',
                minHeight: '100%',
              }}
            >
              <Stack
                sx={{ paddingTop: 1 }}
                direction="row"
                justifyContent="space-between"
              >
                <Stack
                  width={'100%'}
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography className={classes.title}>
                    Active Campaigns
                  </Typography>
                  <Button
                    className={classes.subheaderButton}
                    style={{
                      width: '15rem',
                      height: '3rem',

                      backgroundColor: '#000000',
                      color: 'white',
                      marginTop: '1rem',
                    }}
                    onClick={() => {
                      history.push('/marketing-tools');
                    }}
                  >
                    Back
                  </Button>
                </Stack>
              </Stack>

              <Stack
                sx={{ paddingTop: 8, paddingBottom: 8 }}
                spacing="30px"
                direction="column"
                justifyContent="space-between"
              >
                {plannerMRequests?.filter((request) => {
                  if (request.status != 'Pending') {
                    return request;
                  }
                }).length ? (
                  plannerMRequests
                    ?.filter((request) => {
                      if (request.status != 'Pending') {
                        return request;
                      }
                    })
                    ?.map((request) => (
                      <MarketingRequest
                        key={request.id}
                        searchText={'searchText'}
                        marketingRequest={
                          request.targetCampaign
                            ? request.targetCampaign
                            : EmailCampaign
                        }
                        event={
                          request.eventId
                            ? request.eventId
                            : currentlyDisplayedEvent
                        }
                        request={request}
                        user={user?.name}
                      />
                    ))
                ) : (
                  <Box style={{ opacity: 0.2 }}>
                    <MarketingRequest
                      event={currentlyDisplayedEvent}
                      marketingRequest={EmailCampaign}
                    />
                  </Box>
                )}
              </Stack>
            </Container>
          </div>
        </Grid>
      </Box>
    </div>
  );
};

export default ActiveCampaigns;
