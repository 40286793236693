import React, { useEffect, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { paymobCallback } from 'src/slices/request';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { FaTemperatureLow } from 'react-icons/fa';

const useStyles = createUseStyles({
  mainContainer: {
    height: '100%',
    '@media screen and (max-width: 900px)': {},
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    margin: '0 auto',
    padding: '0 20px',
    display: 'flex',
    maxWidth: '100%',
  },
  headerText: {
    fontWeight: 900,
    fontSize: '34px',
  },
  backToHomeButton: {
    textTransform: 'none',
    backgroundColor: 'orange',
    borderRadius: 20,
    width: '100%',
    maxWidth: '500px',
    alignSelf: 'center',
  },
});

export const PaymentProcessing: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  //const search2 = "https://16fc-156-213-39-123.eu.ngrok.io/1/functions/paymobCallback?integration_id=3093431&is_3d_secure=true&captured_amount=0&profile_id=625184&source_data.pan=2346&hmac=73ff21bc5e49b940553f06ec22d1dd37e5de3c66b3ec4100ffa246fbf7e0f8243c855c6c5a5211863153422d96ed6d979c39526666bf755a1be2e4ace1bc99a6&txn_response_code=APPROVED&refunded_amount_cents=0&order=81926654&is_standalone_payment=true&currency=EGP&success=true&id=69546100&source_data.type=card&source_data.sub_type=MasterCard&is_voided=false&acq_response_code=00&error_occured=false&is_void=false&updated_at=2022-11-20T22%3A26%3A23.810997&is_refund=false&created_at=2022-11-20T22%3A26%3A05.833248&is_auth=false&data.message=Approved&owner=1053982&has_parent_transaction=false&amount_cents=200&discount_details=%5B%5D&pending=false&merchant_commission=0&is_refunded=false&is_capture=false"
  const search2 = useLocation().search;
  // const txn_response_code = search2.split('txn_response_code=')[1].split('&')[0];
  let success = false;
  let id = '0';
  let integrationid = '0';
  try {
    success = Boolean(search2.split('success=')[1].split('&')[0] == 'true');
  } catch {
    success = Boolean(search2.split('&success=')[1] == 'true');
  }
  try {
    id = search2.split('&id=')[1].split('&')[0];
  } catch {
    id = search2.split('?id=')[1].split('&')[0];
  }
  try {
    integrationid = search2.split('integration_id=')[1].split('&')[0];
  } catch {
    integrationid = search2.split('&integration_id=')[1];
  }

  const { isRequestLoading, isSuccessfullyPaid } = useAppSelector(
    ({
      request: { isLoading: isLoading, isSuccessfullyPaid: isSuccessfullyPaid },
    }) => ({
      isRequestLoading: isLoading,
      isSuccessfullyPaid: isSuccessfullyPaid,
    }),
  );

  const takeBackToHome = () => {
    history.push('/landing');
  };

  if (success) {
    useEffect(() => {
      dispatch(paymobCallback({ orderId: id, integrationId: integrationid }));
    }, [success]);
    history.push('/thanks-for-purchase');
  }

  function handleRedrict() {
    history.push('/thanks-for-purchase');
    return true;
  }
  return isRequestLoading ? (
    <div className={classes.mainContainer}>
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={5}
      >
        <Box m={3}></Box>
        <p className={classes.headerText}>Please wait</p>
        <p style={{ fontWeight: 500, fontSize: '20px' }}>
          Your transaction is being processed. Please dont close this window
          this will only take a few seconds...
        </p>
        {/* TODO: Confirm with the client if he needs to display the back to home button */}
        <Box
          paddingX={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Button
            variant="contained"
            onClick={takeBackToHome}
            className={classes.backToHomeButton}
            size="large"
            fullWidth
          >
            Back To Home
          </Button>
        </Box>
      </Stack>
    </div>
  ) : !success ? (
    <div className={classes.mainContainer}>
      <Stack
        alignItems="center"
        direction="column"
        justifyContent={'center'}
        spacing={5}
      >
        <Box m={3}></Box>
        <p className={classes.headerText}>
          Sorry, there was a problem processing your payment.
        </p>
        <p style={{ fontWeight: 500, fontSize: '20px' }}>
          There was a problem processing your payment, please try again later.
        </p>
        {/* TODO: Confirm with the client if he needs to display the back to home button */}
        <Box
          paddingX={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Button
            variant="contained"
            onClick={takeBackToHome}
            className={classes.backToHomeButton}
            size="large"
            fullWidth
          >
            Back To Home
          </Button>
        </Box>
      </Stack>
    </div>
  ) : isSuccessfullyPaid ? (
    <div> {handleRedrict()} </div>
  ) : (
    <div className={classes.mainContainer}>
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={5}
      >
        <Box m={3}></Box>
        <p className={classes.headerText}>
          Sorry, there was a problem processing your payment.
        </p>
        <p style={{ fontWeight: 500, fontSize: '20px' }}>
          There was a problem processing your payment, please try again later.
        </p>
        {/* TODO: Confirm with the client if he needs to display the back to home button */}
        <Box
          paddingX={3}
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <Button
            variant="contained"
            onClick={takeBackToHome}
            className={classes.backToHomeButton}
            size="large"
            fullWidth
          >
            Back To Home
          </Button>
        </Box>
      </Stack>
    </div>
  );
};
