import { Typography } from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface Props {
  description: string;
  cityColor?: string;
}

const useStyles = createUseStyles({
  descriptionSection: {
    paddingTop: 25,
    paddingBottom: 25,
    maxHeight: '600px',
    overflow: 'auto',
  },
  title: {
    fontSize: '30px',
    fontWeight: 800,
    color: '#000',
    lineHeight: '36px',
    marginBottom: '9px',
  },
  descriptionSectionText: {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 500,
    color: '#000',
    whiteSpace: 'pre-line',
  },
});

export const EventDescriptionSection: React.VFC<Props> = ({ description }) => {
  const classes = useStyles();
  return (
    <div>
      <Typography className={classes.title}>About</Typography>
      <Typography className={classes.descriptionSectionText}>
        {description}
      </Typography>
    </div>
  );
};
