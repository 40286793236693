import React from 'react';

import { Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { Box } from '@mui/system';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as NftLogo } from '../../../assets/icons/nft/nftLogo.svg';
import VideoCollection from '../../VideoCollection/VideoCollection';
import { ReactComponent as Arrow } from '../../../assets/arrow-shape-3.svg';
import { ReactComponent as Rocket } from '../../../assets/rocket.svg';

const useStyles = createUseStyles({
  container: {
    marginTop: '132px',
    '@media screen and (max-width: 900px)': {
      marginTop: '100px',
      marginLeft: '5%',
    },
  },
  tacNftText: {
    color: '#666666',
    fontSize: '20px',
    marginLeft: '10px',
    '@media (min-width: 1200px)': {
      marginLeft: '20px',
    },
    fontWeight: 500,
  },
  tacNftDocs: {
    width: '100%',
    position: 'relative',
    '@media screen and (max-width: 900px)': {
      width: '100%',
    },
  },
  title: {
    textAlign: 'start',
    fontWeight: 800,
    fontSize: 52,
    color: '#000',
    '@media screen and (max-width: 425px)': {
      lineHeight: '52px',
      marginBottom: '20px',
    },
  },

  regularBoxContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr',
    },
  },
  titleIcon: {
    fontSize: '45px',
    width: '70px',
    height: '70px',
    '@media screen and (max-width: 900px)': {
      width: '60px',
      height: '60px',
      fontSize: '32px',
      marginBottom: '10px',
    },
  },
  arrowSvg: {
    width: '150px',
    height: '157px',
    marginLeft: 140,
    marginBottom: 15,
    '@media screen and (max-width: 900px)': {
      width: '130px',
      height: '130px',
      marginLeft: '60px',
    },
  },
  rocketSvg: {
    width: 98.1,
    height: 94.5,
    position: 'relative',
    left: '265px',
    bottom: '70px',
    '@media screen and (max-width: 900px)': {
      width: '80px',
      height: '80px',
    },

    '@media screen and (max-width: 756px)': {
      left: '240px',
      bottom: '60px',
    },
    '@media screen and (max-width: 600px)': {
      left: '230px',
      bottom: '60px',
    },
    '@media screen and (max-width: 500px)': {
      left: '240px',
      bottom: '60px',
    },
  },
  nftBox: {
    marginTop: '20px',
    '@media screen and (max-width: 900px)': {
      width: '95%',
      margin: '0',
    },
  },
  nftButton: {
    textTransform: 'none',
    width: '100%',
    fontSize: 18,
    fontWeight: 800,
    padding: '12px 85px',
    borderWidth: 0,
    borderRadius: '27px',
    backgroundColor: '#FFA9B2',
    color: 'white',
    '&:hover': {
      backgroundColor: '#FF909B',
    },
  },
  buttonBox: {
    '@media screen and (max-width: 500px)': {
      marginTop: '20px',
      marginBottom: '20px',
    },
  },
  text: {
    color: 'black',
    fontSize: 17,
    fontWeight: 600,
  },
  textContainer: {
    color: 'black',
    marginTop: 5,
    marginBottom: 24,
    width: 675,
    '@media screen and (max-width: 900px)': {
      width: '90%',
    },
  },
  boldText: {
    color: 'black',
    fontSize: 18,
    fontWeight: 800,
  },
});
const TacNftPart = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 900px)', { noSsr: true });

  const RenderButton = () => {
    return (
      <Stack
        direction="column"
        alignItems={'center'}
        justifyContent="center"
        width="100%"
      >
        <Typography className={classes.title}>Discover NFTs</Typography>
        <Box className={classes.buttonBox}>
          <Button
            variant="contained"
            color="primary"
            className={classes.nftButton}
            href={'https://nft.technoandchill.com/'}
          >
            {"Let's go"}
          </Button>
          <SvgIcon
            component={Rocket}
            className={classes.rocketSvg}
            inheritViewBox
          />
        </Box>
      </Stack>
    );
  };

  return (
    <div className={classes.container} id="tacNft">
      <Box>
        <Stack
          direction="column"
          alignItems={'center'}
          justifyContent="center"
          width="100%"
        >
          <div className={classes.tacNftDocs}>
            <Box>
              <Stack
                direction="column"
                alignItems={'left'}
                justifyContent="center"
                width="100%"
              >
                <SvgIcon
                  component={NftLogo}
                  className={classes.titleIcon}
                  inheritViewBox
                />
                <Typography className={classes.title}>
                  TAC-NFT Collection.
                </Typography>
              </Stack>
            </Box>
            <div className={classes.textContainer}>
              <Typography className={classes.text} display="inline">
                {'Building a Web3, Blockchain based ecosystem that '}
              </Typography>
              <Typography className={classes.boldText} display="inline">
                {'connects artists '}
              </Typography>
              <Typography className={classes.text} display="inline">
                {'directly with their '}
              </Typography>
              <Typography className={classes.boldText} display="inline">
                fans
              </Typography>
              <Typography className={classes.text} display="inline">
                {' . NFT holders will receive '}
              </Typography>
              <Typography className={classes.boldText} display="inline">
                10%
              </Typography>
              <Typography className={classes.text} display="inline">
                {' of the '}
              </Typography>
              <Typography className={classes.boldText} display="inline">
                {'marketplace earnings '}
              </Typography>
              <Typography className={classes.text} display="inline">
                {
                  'and become part of the DAO by participating in the decision making process using a voting system.'
                }
              </Typography>
            </div>
            <div className={classes.textContainer}>
              <Typography className={classes.text} display="inline">
                {'Techno and chill NFTs is a collection of '}
              </Typography>
              <Typography className={classes.boldText} display="inline">
                {'1,777 avatar-style 3D NFTs'}
              </Typography>
              <Typography className={classes.text} display="inline">
                {
                  ' built on the Ethereum blockchain. These outer-worldly digital assets feature more than '
                }
              </Typography>
              <Typography className={classes.boldText} display="inline">
                {'200 fashion traits'}
              </Typography>
              <Typography className={classes.text} display="inline">
                {', resulting in unique digital artworks.'}
              </Typography>
            </div>
            <div className={classes.nftBox}>
              <VideoCollection autoplay={isMobile} />
            </div>
            <Box
              sx={{ mt: '32.4px', ml: isMobile ? '0px' : '80px', mb: '-50px' }}
            >
              <Stack
                direction="column"
                alignItems={'left'}
                justifyContent="center"
                width="100%"
              >
                <SvgIcon
                  component={Arrow}
                  className={classes.arrowSvg}
                  inheritViewBox
                />
                {RenderButton()}
              </Stack>
            </Box>
          </div>
        </Stack>
      </Box>
    </div>
  );
};

export default TacNftPart;
