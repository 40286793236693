import React, { useMemo, useState, VFC } from 'react';
import LogoIMG from '../../../assets/logo.svg';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SideMenubar, SideMenu, SideMenuItem, LogoutBtnDiv } from './Header';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { logOut } from 'src/slices/authentication';
import Menu from '@mui/icons-material/Menu';
import { Box, Button, Drawer, IconButton, Stack } from '@mui/material';
import { Menu as MenuType } from 'src/data/menus';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { reset } from '../../../slices/event';

interface MobileHeaderProps {
  menu: MenuType[];
}

const MobileHeader: VFC<MobileHeaderProps> = ({ menu }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  const history = useHistory();
  const [linkUrl, setLinkUrl] = useState('');
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [tacAppMenuOpen, setTacAppMenuOpen] = useState<boolean>(false);

  const isAuthenticated = useMemo(() => user, [user]);

  const onMenuClick = (key: string) => {
    setLinkUrl(key);
    setMenuOpen(false);
  };

  const onClickSignIn = async () => {
    setLinkUrl('sign-in');
    history.push('/sign-in');
    setMenuOpen(false);
  };

  const onClickLogOut = async () => {
    dispatch(logOut());
  };

  const displayMenuItem = ({ name, key, displayMenu, url }: MenuType) =>
    url ? (
      <a
        href={url}
        target="_blank"
        key={key}
        style={{ textDecoration: 'none' }}
        rel="noreferrer"
      >
        <SideMenuItem
          style={{
            color: linkUrl === key ? 'gray' : 'black',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {name}
          {displayMenu && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setTacAppMenuOpen(!tacAppMenuOpen);
              }}
            >
              {tacAppMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          )}
        </SideMenuItem>
      </a>
    ) : (
      <Link
        key={key}
        to={'/' + key}
        onClick={() => onMenuClick(key)}
        style={{ textDecoration: 'none' }}
      >
        <SideMenuItem
          style={{
            color: linkUrl === key ? 'gray' : 'black',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {name}
          {displayMenu && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setTacAppMenuOpen(!tacAppMenuOpen);
              }}
            >
              {tacAppMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
            </IconButton>
          )}
        </SideMenuItem>
      </Link>
    );

  const displayLoginOutButton = () => (
    <LogoutBtnDiv>
      <SideMenuItem
        onClick={isAuthenticated ? onClickLogOut : onClickSignIn}
        style={{
          color: 'black',
        }}
      >
        {isAuthenticated ? 'Log Out' : 'Sign In'}
      </SideMenuItem>
    </LogoutBtnDiv>
  );

  return (
    <Box>
      <Stack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        position={
          !['/events', '/tac-app', '/request', '/thank'].some((word) =>
            location.pathname.includes(word),
          )
            ? 'fixed'
            : 'relative'
        }
        top="0"
        width="100%"
        height="90px"
        paddingX="20px"
        sx={{ backgroundColor: 'white', zIndex: 2 }}
      >
        <Box
          component="img"
          justifySelf="center"
          src={"https://i.imgur.com/6UBrNLA.png"}
          width="141px"
          height="50px"
          onClick={() => {
            dispatch(reset());
            history.push('/');
          }}
          sx={{ cursor: 'pointer' }}
        />
        <Box visibility="hidden" padding="15px" />
        <Button
          onClick={() => setMenuOpen(!menuOpen)}
          variant="contained"
          sx={{
            fontSize: '30px',
            color: 'black',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            padding: 0,
            minHeight: 0,
            minWidth: 0,
            backgroundColor: 'white',
            ...(location.pathname === '/tac-app'
              ? {
                position: 'fixed',
                right: '20px',
              }
              : {}),
          }}
        >
          <Menu
            onClick={() => setMenuOpen(!menuOpen)}
            sx={{ color: 'black' }}
          />
        </Button>
      </Stack>
      <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)}>
        <SideMenubar>
          <SideMenu>
            <IconButton
              onClick={() => setMenuOpen(!menuOpen)}
              sx={{
                color: 'gray',
                fontSize: '40px',
                position: 'absolute',
                top: 0,
                right: 0,
                stroke: 'gray',
                strokeWidth: 2,
              }}
            >
              <CloseIcon />
            </IconButton>
            {menu.map(displayMenuItem)}
            {displayLoginOutButton()}
          </SideMenu>
        </SideMenubar>
      </Drawer>
    </Box>
  );
};

export default MobileHeader;
