import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Event } from 'src/models/event';
import { Request } from 'src/models/request';
import { useHistory } from 'react-router-dom';
import { DesktopEventsEventCard } from '../events/DesktopEventsEventCard';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Pending } from '../../assets/pending.svg';
import { ReactComponent as Finished } from '../../assets/finishedFlag.svg';
import { getEventDates } from 'src/helpers/event/getEventDates';
import { date } from 'yup/lib/locale';
import { isPending } from '@reduxjs/toolkit';
import { DateSchema } from 'yup';
import { addDays } from 'date-fns';

const useStyles = createUseStyles({
  button: {
    borderRadius: 10,
    width: '150px',
    height: '25px',
    textTransform: 'none',
    padding: '8px 15px',
    '@media (max-width: 500px)': {
      padding: '8px 10px',
      width: '180px',
    },
  },
  requestButton: {
    backgroundColor: 'black',
  },
  icon: {
    fontSize: '1.3em',
  },
  date: {
    color: 'gray',
  },
  text: {
    fontWeight: 0,
    color: 'black',
    fontSize: '12px',
  },
  desktopButtonText: {
    fontWeight: 700,
    fontSize: 17,
    lineHeight: '19px',
  },
  approvedButton: {
    fontWeight: 0,
    color: 'white',
    fontSize: '16.2px',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '12.5px',
    border: '1px solid #FFF',
    minWidth: '100px',
    maxWidth: '120px',
    height: '85px',
    margin: '1rem',
    aspectRatio: 1,
  },
  svgIcon: {
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    '@media screen and (max-width: 900px)': {
      width: '30px',
      height: '30px',
    },
  },
});

interface RequestCardProps {
  request: Request;
  event: Event;
}

export const RequestCard: React.FC<RequestCardProps> = ({ request, event }) => {
  const classes = useStyles();
  const history = useHistory();

  let isExpired = false;

  if (request.createdAt != undefined) {
    //add 1 day to current date
    if (event.ticketsExpireIn != undefined) {
      if (event.ticketsExpireIn > 0) {
        const currentDate = new Date();
        if (request.approvedAt != undefined) {
          const updatedAt = addDays(request.approvedAt, event.ticketsExpireIn);
          isExpired = updatedAt < currentDate ? true : false;
        } else {
          const updatedAt = addDays(request.createdAt, event.ticketsExpireIn);
          isExpired = updatedAt < currentDate ? true : false;
        }
      }
      if (event.available == false) {
        isExpired = true;
      }
    }
  }

  const isDesktop = useMediaQuery('(min-width: 900px)');
  const { startDate, endDate } = getEventDates(event);
  const isEventOver = event.endDate ? endDate < new Date() : false;
  const onClickGoToEvent = () => {
    history.push(`/events/${event.id}`);
  };
  const onClickRequest = () => {
    if (request.isApproved) {
      history.push(`/request/${request.id}`);
    }
  };

  const getStatusColor = () => {
    if (isEventOver) {
      return '6CC9FF';
    } else if (request.isPending) {
      return 'FF4771';
    } else {
      return '000000';
    }
  };

  const displayMobileVersion = () => (
    <Stack direction="row" alignItems="center" maxWidth="100%">
      <Box
        sx={{
          aspectRatio: 1,
          height: '115px',
          width: '115px',
          borderRadius: '50px',
        }}
      >
        <Box
          component="img"
          src={event.imageUrl}
          sx={{ borderRadius: '13px', objectFit: 'cover', height: '100%' }}
        />
      </Box>
      <Stack direction="column" spacing={1} paddingLeft={2}>
        <p className={classes.date}>{startDate.toLocaleDateString()}</p>
        <Box
          fontWeight="bold"
          fontSize="18px"
          maxWidth={{ xs: '150px', sm: '200px' }}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
          onClick={onClickGoToEvent}
        >
          {event.name}
        </Box>
        {isEventOver ? (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={onClickGoToEvent}
          >
            <Typography className={classes.desktopButtonText} color="#6CC9FF">
              Finished Event
            </Typography>
            <SvgIcon
              component={Finished}
              className={classes.svgIcon}
              inheritViewBox
            />
          </Stack>
        ) : request.isPending ? (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={onClickGoToEvent}
          >
            <Typography className={classes.desktopButtonText} color="#FF4771">
              Pending Approval
            </Typography>
            <SvgIcon
              component={Pending}
              className={classes.svgIcon}
              inheritViewBox
            />
          </Stack>
        ) : request.isApproved && !isExpired ? (
          <Button
            className={classes.button}
            variant="contained"
            sx={{ backgroundColor: 'black' }}
            onClick={onClickRequest}
          >
            <p className={classes.approvedButton}>Approved - Pay Now</p>
          </Button>
        ) : request.isDeclined ? (
          <Button className={classes.button} disabled variant="contained">
            <p className={classes.text}>Declined</p>
          </Button>
        ) : isExpired ? (
          <Button className={classes.button} disabled variant="contained">
            <p className={classes.text}>Expired</p>
          </Button>
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  );
  const displayDesktopTicketRequest = () => (
    <Stack direction="row" alignItems="center" spacing={2}>
      {isEventOver ? (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={onClickGoToEvent}
          width="237px"
        >
          <Typography className={classes.desktopButtonText} color="#6CC9FF">
            Finished Event
          </Typography>
          <SvgIcon
            component={Finished}
            className={classes.svgIcon}
            inheritViewBox
          />
        </Stack>
      ) : request.isPending ? (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={onClickGoToEvent}
          width="276px"
        >
          <Typography className={classes.desktopButtonText} color="#FF4771">
            Pending Approval
          </Typography>
          <SvgIcon
            component={Pending}
            className={classes.svgIcon}
            inheritViewBox
          />
        </Stack>
      ) : request.isApproved && !isExpired ? (
        <Button
          sx={{
            backgroundColor: 'black',
            borderRadius: '50px',
            minWidth: '225px',
            padding: '8px 30px',
            color: 'white',
            textTransform: 'none',
            height: '45px',
          }}
          onClick={onClickRequest}
          variant="contained"
        >
          <Stack direction="row" spacing={1}>
            <p className={classes.approvedButton}>Approved - Pay Now</p>
          </Stack>
        </Button>
      ) : request.isDeclined ? (
        <Button
          sx={{
            backgroundColor: 'black',
            borderRadius: '50px',
            minWidth: '225px',
            padding: '8px 30px',
            textTransform: 'none',
          }}
          disabled
          variant="contained"
        >
          <Stack direction="row" spacing={1}>
            <p className={classes.desktopButtonText}>Declined</p>
          </Stack>
        </Button>
      ) : isExpired && !request.isDeclined && !request.isPending ? (
        <Button
          sx={{
            backgroundColor: 'black',
            borderRadius: '50px',
            minWidth: '225px',
            padding: '8px 30px',
            textTransform: 'none',
          }}
          disabled
          variant="contained"
        >
          <Stack direction="row" spacing={1}>
            <p className={classes.desktopButtonText}>Expired</p>
          </Stack>
        </Button>
      ) : (
        <></>
      )}
    </Stack>
  );
  const displayDesktopVersion = () => (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <DesktopEventsEventCard
        event={event}
        displayGetTickets
        redirectOnClickName={false}
        statusColor={getStatusColor()}
      />

      {displayDesktopTicketRequest()}
    </Stack>
  );
  return isDesktop ? displayDesktopVersion() : displayMobileVersion();
};

export default RequestCard;
