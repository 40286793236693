import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { renderToString } from 'react-dom/server';
import { useParams } from 'react-router-dom';
import {
  approveRequest,
  declineRequest,
  fetchEventRequests,
  fetchEventTickets,
  resendRequestEmail,
  resetSentEmail,
} from 'src/slices/request';
import { getEventById, stopTakingRequests } from 'src/slices/event';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import {
  Alert,
  Card,
  CardContent,
  Chip,
  Paper,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import ReactToPrint from 'react-to-print';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Bar } from 'react-chartjs-2';
import SalesBarChart from 'src/components/salesReportGraphs/SalesBarGraph';
import {
  getNetProfit,
  getTicketsSales,
  getTotalSales,
} from 'src/slices/ticket';
import { getAllPromoCodes } from 'src/slices/promoCode';
import { Container } from '@mui/system';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Ticks } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { YAxis } from 'recharts';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { ImPointUp } from 'react-icons/im';
import Download from '@mui/icons-material/Download';
ChartJS.register(ArcElement, Tooltip, Legend);
const useStyles = createUseStyles({
  main: {
    width: '100%',

    backgroundColor: 'white',

    '@media print': {
      marginTop: 0,
      scale: 0.5,
      backgroundColor: 'white',
    },
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',

    justifyContent: 'space-between',
    justifyItems: 'space-between',
    alignItems: 'space-between',
    padding: '0 170px 70px',
    '@media (max-width: 1000px)': {
      padding: '0 100px 30px',
    },
  },
  componentRef: {},
  grid: {
    margin: '0',
    padding: 'auto',
    backgroundColor: 'white',
    width: '100%',
  },
  subheaderButton: {
    '&:hover': {
      color: 'white',
      backgroundColor: '#48C9B0',
    },
    backgroundColor: '#48C9B0',
    color: 'black',
    border: 'solid 1px #48C9B0',
    borderRadius: 50,
    height: 40,
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '8px 30px',
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '15rem',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  title: {
    fontSize: '60px',
    fontWeight: 800,
    fontStyle: 'bold',
  },
  subTitle: {
    fontSize: '30px',
    fontWeight: 800,
    fontStyle: 'bold',
  },
  '@page': {
    size: 'portrait',
  },
});
interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

const SalesReportPage: React.VFC = () => {
  //Intializing styles,history, appdispatch and so on...
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { currentlyDisplayedEvent, requests, sentEmail } = useAppSelector(
    ({
      event: { currentlyDisplayedEvent },
      request: { plannerRequests, sentEmail },
    }) => ({
      currentlyDisplayedEvent: currentlyDisplayedEvent,
      requests: plannerRequests,
      sentEmail,
    }),
  );
  const internationalNumberFormat = new Intl.NumberFormat('en-US');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const eventId = currentlyDisplayedEvent?.id || '';
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));

  const { plannerTickets } = useAppSelector(
    ({ request: { plannerTickets } }) => ({
      plannerTickets,
    }),
  );
  const { promoCodes } = useAppSelector(({ promoCode: { promoCodes } }) => ({
    promoCodes,
  }));
  //Sales table style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#F0F6FF',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  //Get the number of availble tickets for each ticket category for the event
  type RequestNumberByTicketId = { [ticketName: string]: number };
  const getPaidRequestsNumberByTicketId = () =>
    plannerTickets?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.available) {
        return accumulator;
      }
      if (request.name != undefined) {
        accumulator[request.name] = (accumulator[request.name] ?? 0) + 1;
      }

      return accumulator;
    }, {});
  const getMaleAndFemeleRatio = () =>
    plannerTickets?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.available) {
        return accumulator;
      }
      if (request.recipientGender != undefined) {
        const genderFixed =
          request.recipientGender.charAt(0).toUpperCase() +
          request.recipientGender.slice(1).toLowerCase();
        accumulator[genderFixed] = (accumulator[genderFixed] ?? 0) + 1;
      }

      return accumulator;
    }, {});

  //Gets the number of checked in tickets for the event
  const getUsedTicketsNumberByTicketName = () =>
    plannerTickets?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.used || !request.available) {
        return accumulator;
      }
      if (request.name != undefined && request.used == true) {
        accumulator[request.name] = (accumulator[request.name] ?? 0) + 1;
      }

      return accumulator;
    }, {});

  const getTotalCheckedin = () => {
    let total = 0;
    if (usedTicketsByTicketName != undefined) {
      Object.values(usedTicketsByTicketName).map((value) => {
        total += value;
      });
    }
    return total;
  };

  //Gets the number of total tickets sold for the event each day

  const getTicketsSales = () =>
    plannerTickets?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.available) {
        return accumulator;
      }
      if (request.createdAt != undefined) {
        if (request.isRefunded) return accumulator;
        if (request.price == 0) return accumulator;
        const date = new Date(request.createdAt);
        date.setDate(date.getDate() + 1);
        const day = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
        );

        if (accumulator[day.toISOString().substring(5, 10)])
          accumulator[day.toISOString().substring(5, 10)] += 1;
        else accumulator[day.toISOString().substring(5, 10)] = 1;
      }

      return accumulator;
    }, {});
  //Gets the number of total tickets sold for the event
  const getTotalTickets = () =>
    plannerTickets?.reduce((accumulator: number, request) => {
      if (request.available && request.price && !request.isRefunded) {
        if (request.price > 0) accumulator += 1;
      }
      return accumulator;
    }, 0);

  //Table pagination setup

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const printDocument = () => {
    const input = document.getElementById('divToPrint');

    if (input) {
      const oldminWidth = input.style.width;
      const oldHeight = input.style.height;

      input.style.width = '1950';
      input.style.height = '1950';
      input.style.marginLeft = 'auto';

      htmlToImage
        .toPng(input, {
          quality: 1,
          width: Number(input.style.width),
          height: 1950,
        })
        .then(function (dataUrl) {
          const pdf = new jsPDF('p', 'mm', 'a4');

          const imgProps = pdf.getImageProperties(dataUrl);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();

          pdf.addImage(
            dataUrl,
            'PNG',
            0,
            0,
            pdfWidth,
            pdfHeight,
            undefined,
            'FAST',
          );
          pdf.save('salesreport.pdf');
        });

      input.style.width = oldminWidth;
      input.style.height = oldHeight;
    }
  };

  const DisplayData =
    !promoCodes || promoCodes.length === 0 ? (
      <StyledTableRow>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell
          style={{ fontStyle: 'bold', fontWeight: 'bolder' }}
          align="center"
        >
          No Promo Codes Available
        </StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
        <StyledTableCell align="center"></StyledTableCell>
      </StyledTableRow>
    ) : rowsPerPage > 0 ? (
      promoCodes
        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((promoCode, index) => {
          return (
            <StyledTableRow key={index}>
              <StyledTableCell align="center">{promoCode.code}</StyledTableCell>
              <StyledTableCell align="center">
                {promoCode.discount}
              </StyledTableCell>
              <StyledTableCell align="center">{promoCode.used}</StyledTableCell>
              <StyledTableCell align="center">
                {internationalNumberFormat.format(Number(promoCode.sales) ?? 0)}{' '}
                {currentlyDisplayedEvent?.currency}
              </StyledTableCell>
            </StyledTableRow>
          );
        })
    ) : (
      promoCodes?.map((promoCode, index) => {
        return (
          <StyledTableRow key={index}>
            <StyledTableCell align="center">{promoCode.code}</StyledTableCell>
            <StyledTableCell align="center">
              {promoCode.discount}
            </StyledTableCell>
            <StyledTableCell align="center">{promoCode.used}</StyledTableCell>
            <StyledTableCell align="center">
              {internationalNumberFormat.format(Number(promoCode.sales) ?? 0)}{' '}
              {currentlyDisplayedEvent?.currency}
            </StyledTableCell>
          </StyledTableRow>
        );
      })
    );

  const { totalSales } = useAppSelector(({ ticket: { totalSales } }) => ({
    totalSales,
  }));
  const { totalNetProfit } = useAppSelector(
    ({ ticket: { totalNetProfit } }) => ({
      totalNetProfit,
    }),
  );

  let totalTicketRequests = 0;
  requests?.map(
    (request) => (totalTicketRequests += request.recipients.length),
  );
  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(fetchEventTickets({ eventId }));
    dispatch(getAllPromoCodes(eventId));
    dispatch(getTicketsSales);
    dispatch(getTotalSales());
    dispatch(getNetProfit());
    dispatch(getTotalCheckedin);
  }, []);

  //Defining the options of the graphs
  const options = {
    animation: false,
    tooltipTemplate: '<%= value %>',
    tooltipFillColor: 'rgba(0,0,0,0)',
    tooltipFontColor: '#444',
    tooltipEvents: [],
    tooltipCaretSize: 0,
    showTooltips: true,
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 13,
            weight: 'bold',
          },
        },
      },
      title: {
        display: true,
        text: 'Checked In Tickets vs Total Tickets',
      },
    },
  };
  const doughnutGraphoptions = {
    responsive: true,
    cutout: 60,
    plugins: {
      legend: {
        position: 'top' as const,
        align: 'center' as const,
        labels: {
          font: {
            size: 18,
            weight: 'bold',
          },
        },
      },
      title: {
        display: true,
        text: 'Male vs Female Ratio',
      },
    },
  };

  const salesTableOptions = {
    maintainAspectRatio: false,
    responsive: true,
    animation: true,
    scales: {
      xAxes: [
        {
          style: 'bold',
          weight: 'bold',
          fontSize: 13,
        },
      ],
    },

    plugins: {
      Tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
      },

      legend: {
        position: 'top' as const,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 13,
            weight: 'bold',
          },
        },
      },

      title: {
        display: true,
        text: 'Ticket Sales per Day',
      },
    },
  };
  const paidRequestByTicketName = useMemo(
    () => getPaidRequestsNumberByTicketId(),
    [plannerTickets],
  );
  const usedTicketsByTicketName = useMemo(
    () => getUsedTicketsNumberByTicketName(),
    [plannerTickets],
  );

  const totalTickets = useMemo(() => getTotalTickets(), [plannerTickets]);
  const totalCheckedin = useMemo(
    () => getTotalCheckedin(),
    [usedTicketsByTicketName],
  );

  const maleAndFemeleRatio = useMemo(
    () => getMaleAndFemeleRatio(),
    [plannerTickets],
  );

  const ticketSales = useMemo(() => getTicketsSales(), [plannerTickets]);

  const labels: string[] = [];
  const paidTicketDate: number[] = [];
  const usedTicketDate: number[] = [];

  currentlyDisplayedEvent?.tickets?.map((ticket) => {
    labels.push(ticket.name);
    if (
      paidRequestByTicketName != undefined &&
      usedTicketsByTicketName != undefined
    ) {
      paidTicketDate.push(paidRequestByTicketName?.[ticket.name]);
      usedTicketDate.push(usedTicketsByTicketName?.[ticket.name]);
    }
  });
  //Doughnut Chart Data and Configiration
  const dLabels =
    Object.keys(maleAndFemeleRatio ? maleAndFemeleRatio : ['Male', 'Female']) !=
    null
      ? Object.keys(
          maleAndFemeleRatio != null ? maleAndFemeleRatio : ['Male', 'Female'],
        )
      : ['Male', 'Female'];
  const dData = Object.values(
    maleAndFemeleRatio != null ? maleAndFemeleRatio : [0, 0],
  );
  const doughnutData = {
    labels: dLabels.length > 0 ? dLabels : ['No Data To Display'],
    datasets: [
      {
        label: 'Requests',
        data: dData,
        backgroundColor: ['rgba(129, 182, 255, 1)', 'rgba(228, 235, 244, 1)'],
      },
    ],
  };

  //Sales Table Lables and data
  const labels2 = Object.keys(ticketSales || {});
  const data2 = Object.values(ticketSales || {});
  const SalesTableData = {
    labels: labels2.reverse(),
    datasets: [
      {
        barThickness: labels2.length > 6 ? 16 : 36,
        barPercentage: 1,

        label: 'Total Tickets Sold',
        data: data2.reverse(),
        backgroundColor: 'rgba(129, 182, 255, 1)',
      },
    ],
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Total Tickets',
        data: paidTicketDate,
        backgroundColor: 'rgba(228, 235, 244, 1)',
      },
      {
        label: 'Checked In Tickets',
        data: usedTicketDate,
        backgroundColor: 'rgba(129, 182, 255, 1)',
      },
    ],
  };
  type ActionButtonProps = {
    componentToPrint: React.MutableRefObject<null>;
  };
  //Print Components
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div className={classes.main}>
      <Box marginTop={0}>
        <Grid container className={classes.grid}>
          <div className={classes.main}>
            <div className={classes.subheader}>
              <Typography className={classes.title}></Typography>
            </div>

            <div
              id="divToPrint"
              className={classes.componentRef}
              ref={componentRef}
            >
              <Container
                sx={{
                  minWidth: '85%',
                  minHeight: '100%',
                  margin: 'auto',
                  padding: 'auto',
                }}
              >
                <Stack
                  sx={{ paddingTop: 1 }}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Stack width={'100%'} spacing={2} direction="column">
                    <Typography className={classes.title}>
                      Sales Report
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {currentlyDisplayedEvent?.name}
                    </Typography>
                  </Stack>

                  <img
                    style={{}}
                    src="https://www.technoandchill.com/static/media/logo.13ff009e.png"
                    width={120}
                    height={80}
                  ></img>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    marginTop: 6,
                  }}
                >
                  <Card sx={{ height: '9rem', width: '19.5%' }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: '1.1rem', color: 'text.secondary' }}
                        gutterBottom
                      >
                        <h3>Total Sales </h3>
                        <br></br>
                      </Typography>
                      <Typography sx={{ fontSize: '1rem' }} gutterBottom>
                        <h3>
                          {' '}
                          {internationalNumberFormat.format(
                            totalSales?.[eventId]
                              ? Number(totalSales?.[eventId])
                              : 0,
                          ) +
                            ' ' +
                            currentlyDisplayedEvent?.currency ?? '0'}{' '}
                        </h3>
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    variant="elevation"
                    sx={{ height: '9rem', width: '19.5%' }}
                  >
                    <CardContent>
                      <Typography
                        sx={{ fontSize: '1.1rem', color: 'text.secondary' }}
                        gutterBottom
                      >
                        <h3>Net Profit </h3>
                        <br></br>
                      </Typography>
                      <Typography sx={{ fontSize: '1rem' }} gutterBottom>
                        <h3>
                          {' '}
                          {internationalNumberFormat.format(
                            totalNetProfit?.[eventId]
                              ? Number(totalNetProfit?.[eventId])
                              : 0,
                          ) +
                            ' ' +
                            currentlyDisplayedEvent?.currency ?? '0'}{' '}
                        </h3>
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ height: '9rem', width: '19.5%' }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: '1.1rem', color: 'text.secondary' }}
                        gutterBottom
                      >
                        <h3>Tickets Sold</h3>
                        <br></br>
                      </Typography>
                      <Typography sx={{ fontSize: '1rem' }} gutterBottom>
                        <h3> {totalTickets?.toString() ?? '0'} </h3>
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ height: '9rem', width: '19.5%' }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: '1.1rem', color: 'text.secondary' }}
                        gutterBottom
                      >
                        <h3>Total Requests</h3>
                        <br></br>
                      </Typography>
                      <Typography sx={{ fontSize: '1rem' }} gutterBottom>
                        <h3> {totalTicketRequests.toString() ?? '0'} </h3>
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card sx={{ height: '9rem', width: '19.5%' }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: '1.1rem', color: 'text.secondary' }}
                        gutterBottom
                      >
                        <h3>Total Checked-in</h3>
                        <br></br>
                      </Typography>
                      <Typography sx={{ fontSize: '1rem' }} gutterBottom>
                        <h3> {totalCheckedin ?? '0'} </h3>
                      </Typography>
                    </CardContent>
                  </Card>
                </Stack>
                <Stack
                  direction="row"
                  paddingTop={2}
                  spacing={'1%'}
                  paddingBottom={0}
                  justifyContent="space-between"
                >
                  <Container
                    component={Card}
                    sx={{
                      backgroundColor: 'white',
                      minWidth: '60%',
                      paddingBottom: 2,
                      paddingLeft: 2,
                      paddingRight: 2,
                      paddingTop: 2,
                      minHeight: '450px',
                      maxHeight: '450px',
                    }}
                  >
                    <div style={{ height: '400px' }}>
                      <SalesBarChart
                        options={salesTableOptions}
                        data={SalesTableData}
                      />
                    </div>
                  </Container>

                  <TableContainer
                    sx={{ height: '450px', minWidth: '35%' }}
                    component={Card}
                    variant={'elevation'}
                  >
                    <Table sx={{ height: '100%', border: 0 }} aria-label="">
                      <TableHead>
                        <StyledTableRow
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <StyledTableCell align="center">
                            Promo Code
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Discount
                          </StyledTableCell>
                          <StyledTableCell align="center">Uses</StyledTableCell>
                          <StyledTableCell align="center">
                            Sales
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>{DisplayData}</TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 15]}
                            colSpan={3}
                            count={promoCodes?.length ? promoCodes?.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: {
                                'aria-label': 'rows per page',
                              },
                              native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Stack>
                <Stack paddingTop={2}>
                  <Card>
                    <Container sx={{ paddingBottom: 2 }}>
                      <SalesBarChart options={options} data={data} />
                    </Container>
                  </Card>
                </Stack>
                <Stack direction="row" paddingTop={2} paddingBottom={5}>
                  <Card
                    variant="elevation"
                    sx={{
                      minWidth: '50%',
                      display: 'flex',
                    }}
                  >
                    <Container sx={{ marginTop: 1, minHeight: '300px' }}>
                      <Doughnut
                        style={{
                          alignSelf: 'center',
                          width: '90%',
                          maxHeight: '90%',
                        }}
                        options={doughnutGraphoptions}
                        data={doughnutData}
                      ></Doughnut>
                    </Container>
                  </Card>

                  <Container
                    sx={{
                      display: 'flex',
                      backgroundColor: 'white',
                      paddingTop: 0,
                      minWidth: '51%',
                      height: '100%',
                    }}
                  >
                    <TableContainer
                      sx={{ height: '100%', backgroundColor: 'white' }}
                      component={Card}
                    >
                      <Table aria-label="">
                        <TableHead>
                          <StyledTableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <StyledTableCell align="center">
                              Ticket Name
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Number of Braclets
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        {currentlyDisplayedEvent?.tickets?.map(
                          (ticket, index) => {
                            return (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="center">
                                  {ticket.name}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {usedTicketsByTicketName?.[ticket.name] ?? 0}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          },
                        )}
                      </Table>
                    </TableContainer>
                  </Container>
                </Stack>
              </Container>
            </div>

            <Button
              startIcon={<Download />}
              color="primary"
              className={classes.subheaderButton}
              style={{
                float: 'right',
                marginRight: '45%',
                marginBottom: '25px',
              }}
              onClick={() => {
                printDocument();
              }}
            >
              Download
            </Button>
          </div>
        </Grid>
      </Box>
    </div>
  );
};

export default SalesReportPage;
