import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import RequestsPaidApproved from 'src/components/requests/RequestsPaidApproved';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { useParams } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';

import {
  fetchEventRequests,
  fetchEventTickets,
  resetSentEmail,
} from 'src/slices/request';
import { getEventById, stopTakingRequests } from 'src/slices/event';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Card,
  CardContent,
  Container,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import LiveAttendanceTopBar from 'src/components/livecheckin/LiveAttendanceTopBar';
import RequestsCheckIn from 'src/components/requests/RequestsLiveCheckin';
import { OndoorTicketsForm } from 'src/components/requests/OndoorTicketsForm';
import { Ticket } from 'src/models/ticket';
const useStyles = createUseStyles({
  main: {
    flex: 1,
    height: '100%',
    minHeight: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    padding: 'auto',
    margin: 'auto',
  },
  subheader: {
    display: 'flex',
    justifyContent: 'center',

    alignItems: 'center',
    padding: '0 70px 70px',
  },
  subheaderButton: {
    '&:hover': {
      color: 'white',
      backgroundColor: 'black',
    },
    backgroundColor: 'white',
    color: 'black',
    border: 'solid 1px black',
    borderRadius: 50,
    height: 40,
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '8px 30px',
  },
  searchBar: {
    color: 'black',
    [`& fieldset`]: {
      border: 'none',
    },
    width: '200px',

    '@media (max-width: 1500px)': {
      width: '200px',
    },
  },
  fullSearchBar: {
    border: 'solid 1px #989898',
    borderRadius: 20,
    marginRight: -30,
    boxShadow: ' 0px 2px 4px #979797',
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '16em',
    height: '18em',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  title: {
    fontSize: '3rem',
    fontWeight: 800,
  },
});

const LiveCheckInPage: React.VFC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { currentlyDisplayedEvent, requests, sentEmail } = useAppSelector(
    ({
      event: { currentlyDisplayedEvent },
      request: { plannerRequests, sentEmail },
    }) => ({
      currentlyDisplayedEvent: currentlyDisplayedEvent,
      requests: plannerRequests,
      sentEmail,
    }),
  );
  const [isSentEmailDialogOpen, setIsSentEmailDialogOpen] = useState(false);
  useEffect(() => {
    dispatch(
      fetchEventRequests({
        eventId: id,
      }),
    );
  }, [id]);
  useEffect(() => {
    dispatch(getEventById(id));
  }, [id]);
  useEffect(() => {
    if (sentEmail) {
      setIsSentEmailDialogOpen(true);
      dispatch(resetSentEmail());
    }
  }, [sentEmail]);

  const [popUp, setPopUp] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>();

  const getSearchTextRegexCondition = (ticket: Ticket) => {
    if (!searchText) {
      return true;
    } else {
      const lowerCaseSearchText = searchText.toLowerCase();
      const fullname = ticket.guest?.toLowerCase();

      return fullname && new RegExp(`${lowerCaseSearchText}`).test(fullname);
    }
  };

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const eventId = currentlyDisplayedEvent?.id || '';
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));

  const { plannerTickets } = useAppSelector(
    ({ request: { plannerTickets } }) => ({
      plannerTickets,
    }),
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchEventTickets({ eventId }));
  }, []);
  return (
    /** Display event name and image */
    <div className={classes.main}>
      <Box marginTop={5} className={classes.subheader}>
        <Grid
          container
          sx={{
            justifyContent: 'center',
          }}
        >
          <Stack
            direction="row"
            justifyContent={'space-between'}
            width={'80vw'}
            alignItems={'center'}
            paddingBottom={10}
          >
            <Typography className={classes.title}>
              Live Admission Check in
            </Typography>

            <Button
              className={classes.subheaderButton}
              onClick={() => history.push(`/event/${eventId}/requests`)}
            >
              Requests
            </Button>
          </Stack>

          <LiveAttendanceTopBar
            event={currentlyDisplayedEvent ? currentlyDisplayedEvent : null}
            requests={requests}
            tickets={plannerTickets}
          />

          <Grid container direction="row" spacing={0} marginLeft={5}>
            <Stack direction="row" alignItems="top" spacing={2}>
              <div>
                <RequestsPaidApproved
                  requests={requests}
                  event={currentlyDisplayedEvent}
                />
              </div>
              <Container
                sx={{
                  width: '85%',
                  alignItems: 'top',
                  justifyContent: 'top ',
                }}
              >
                <Stack direction="column">
                  <RequestsCheckIn
                    requests={plannerTickets}
                    event={currentlyDisplayedEvent}
                  />

                  <Stack
                    direction="row"
                    justifyContent={'space-between'}
                    paddingBottom={2}
                    paddingTop={5}
                    marginLeft={3}
                    width={'59vw'}
                  >
                    <Typography
                      variant="size3"
                      textAlign="start"
                      fontWeight={800}
                      display="block"
                    >
                      Check-ins by User
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={0}
                      className={classes.fullSearchBar}
                      alignItems="center"
                    >
                      <SearchIcon
                        sx={{
                          color: '#989898',
                          fontSize: { xs: '40px', xl: '50px' },
                          paddingLeft: 2,
                        }}
                      />
                      <TextField
                        className={classes.searchBar}
                        label={searchText ? '' : 'Search by name'}
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                        fullWidth
                        InputProps={{
                          sx: { fontSize: { xs: 11.2, xl: 14 } },
                        }}
                        InputLabelProps={{
                          shrink: false,
                          sx: { fontSize: { xs: 11.2, xl: 14 } },
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack
                    direction="column"
                    marginLeft={1}
                    spacing={1}
                    justifyContent={'space-evenly'}
                  >
                    {currentlyDisplayedEvent && (
                      <OndoorTicketsForm event={currentlyDisplayedEvent} />
                    )}
                    <Container sx={{ minWidth: '100%' }}>
                      {plannerTickets
                        ?.filter(
                          (ticket) =>
                            ticket.used === true &&
                            ticket.available === true &&
                            getSearchTextRegexCondition(ticket),
                        )
                        .map((ticket, index) => (
                          <Card
                            style={{
                              paddingTop: 10,
                              marginTop: 20,
                              marginBottom: 10,
                              width: '60vw',
                              boxShadow: ' 0px 2px 4px #979797',
                            }}
                            key={index}
                          >
                            <CardContent>
                              <Stack
                                direction="row"
                                justifyContent={'space-between'}
                              >
                                <div>
                                  <Typography variant="h5" component="div">
                                    {ticket.guest}
                                  </Typography>
                                  <Typography variant="body2">
                                    {ticket.name}
                                  </Typography>
                                </div>
                                <Typography variant="h5">
                                  {ticket.paymentMethod
                                    ? ticket.paymentMethod
                                    : 'Online'}
                                </Typography>
                              </Stack>
                            </CardContent>
                          </Card>
                        ))}
                    </Container>
                  </Stack>
                </Stack>
              </Container>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default LiveCheckInPage;
