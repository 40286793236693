import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import RequestsPaidApproved from 'src/components/requests/RequestsPaidApproved';
import RequestsTopBarMobile from 'src/components/requests/RequestsTopBarMobile';
import RequestsTabsMobile from 'src/components/requests/RequestsTabsMobile';
import RequestsBottomBarMobile from 'src/components/requests/RequestsBottomBarMobile';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { useParams } from 'react-router-dom';
import FooterBar from 'src/components/FooterBar';
import {
  approveRequest,
  declineRequest,
  fetchEventRequests,
  resendRequestEmail,
  resetSentEmail,
} from 'src/slices/request';
import { getEventById, stopTakingRequests } from 'src/slices/event';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Alert, Container, Snackbar, Typography } from '@mui/material';
import FullscreenSpin from '../components/FullscreenSpin';
import { Stack } from '@mui/system';
import ManageRequestsTopBarMobile from 'src/components/requests/ManageRequestsTopBarMobile';
import RequestsdCardMobile from 'src/components/requests/RequestsCardMobile';

const useStyles = createUseStyles({
  main: {
    maxWidth: '99vw',
  },
  main2: {
    maxWidth: '99vw',
    minHeight: '100vh',
    paddingTop: '5px',
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 170px 70px',
    '@media (max-width: 1000px)': {
      padding: '0 100px 30px',
    },
  },
  subheaderButton: {
    '&:hover': {
      color: 'white',
      backgroundColor: 'black',
    },
    backgroundColor: 'white',
    color: 'black',
    border: 'solid 1px black',
    borderRadius: 50,
    height: 40,
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '8px 30px',
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '15rem',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  title: {
    fontSize: '60px',
    fontWeight: 800,
  },
});

const PlannerRequestsPageMobile: React.VFC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { currentlyDisplayedEvent, requests, sentEmail } = useAppSelector(
    ({
      event: { currentlyDisplayedEvent },
      request: { plannerRequests, sentEmail },
    }) => ({
      currentlyDisplayedEvent: currentlyDisplayedEvent,
      requests: plannerRequests,
      sentEmail,
    }),
  );
  const [isSentEmailDialogOpen, setIsSentEmailDialogOpen] = useState(false);
  useEffect(() => {
    dispatch(
      fetchEventRequests({
        eventId: id,
      }),
    );
  }, [id]);
  useEffect(() => {
    dispatch(getEventById(id));
  }, [id]);
  useEffect(() => {
    if (sentEmail) {
      setIsSentEmailDialogOpen(true);
      dispatch(resetSentEmail());
    }
  }, [sentEmail]);

  const onClickApprove = (requestId: string) => {
    dispatch(approveRequest({ eventId: id, id: requestId }));
  };

  const onClickDecline = (requestId: string) => {
    dispatch(declineRequest({ eventId: id, id: requestId }));
  };

  const onClickResendRequestEmail = (requestId: string) => {
    dispatch(resendRequestEmail({ eventId: id, requestId }));
  };

  const [popUp, setPopUp] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>();
  const handleClickOpen = () => {
    setPopUp(true);
  };

  const handleClose = () => {
    setPopUp(false);
  };

  const handleStopTakingRequests = () => {
    dispatch(
      stopTakingRequests({
        eventId: id,
      }),
    );
    handleClose();
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isRequestsOpen, setVisible] = React.useState(true);
  const [isLoading] = useAppSelector(({ request: { isLoading } }) => [
    isLoading,
  ]);

  const onClickRequests = () => {
    if (isRequestsOpen) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };

  return (
    <div>
      {isLoading ? (
        <FullscreenSpin />
      ) : isRequestsOpen ? (
        <Container className={classes.main}>
          <RequestsTopBarMobile event={currentlyDisplayedEvent} />
          {currentlyDisplayedEvent && (
            <RequestsTabsMobile
              event={currentlyDisplayedEvent}
              isRequestsOpen={isRequestsOpen}
              onClickRequests={onClickRequests}
            />
          )}
          <Stack paddingTop={2} direction={'column'} alignSelf="start">
            {currentlyDisplayedEvent && (
              <RequestsBottomBarMobile
                requests={requests}
                event={currentlyDisplayedEvent}
              />
            )}
          </Stack>
        </Container>
      ) : isLoading ? (
        <FullscreenSpin />
      ) : (
        <Container className={classes.main2}>
          <ManageRequestsTopBarMobile
            searchText={searchText}
            isRequestsOpen={isRequestsOpen}
            onClickRequests={onClickRequests}
            setSearchText={setSearchText}
            event={currentlyDisplayedEvent}
            requests={requests}
          ></ManageRequestsTopBarMobile>
          {currentlyDisplayedEvent ? (
            <RequestsdCardMobile
              searchText={searchText}
              requests={requests}
              event={currentlyDisplayedEvent}
              onClickApprove={onClickApprove}
              onClickDecline={onClickDecline}
              onClickResendRequestEmail={onClickResendRequestEmail}
            />
          ) : (
            <FullscreenSpin />
          )}
        </Container>
      )}
      <Box m={4} />
      <FooterBar />
    </div>
  );
};

export default PlannerRequestsPageMobile;
