import {
  CardMedia,
  TextField,
  Grid,
  Box,
  Stack,
  Typography,
  Button,
  Icon,
  Card,
  Container,
  SvgIcon,
} from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Request } from 'src/models/request';
import { Event } from 'src/models/event';
import SearchIcon from '@mui/icons-material/Search';
import { forEach } from 'lodash';
import { createPlanner } from 'src/slices/planner';
import { getPlannerName } from 'src/actions/actions';
import { useHistory } from 'react-router-dom';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import LensIcon from '@mui/icons-material/Lens';
import { ReactComponent as Calendar } from '../../assets/calendar.svg';
import { ReactComponent as Location } from '../../assets/location.svg';
import { Campaign } from 'src/models/campagin';
import { MarketingRequest } from 'src/models/mRequest';
import { getEventDates } from 'src/helpers/event/getEventDates';

const useStyles = createUseStyles({
  main: {
    margin: '5em',
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  subheaderButton: {
    border: '1px solid black',
    color: 'black',
    padding: '0em 2em',
    borderRadius: '50px',
    backgroundColor: 'white',
    '&:active': {
      color: 'white',
      backgroundColor: 'black',
    },
    transform: 'none',
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '225px',
    height: '225px',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  searchBar: {
    color: 'black',
    [`& fieldset`]: {
      border: 'none',
    },
    width: '400px',
    '@media (max-width: 1500px)': {
      width: '320px',
    },
  },
  buttonSignup: {
    color: 'white',

    borderRadius: '20px',
    border: '1px solide',
    borderColor: 'black',
    width: '300px',
    height: '60px',
    textTransform: 'none',
    '&:hover': {
      opacity: 0.8,
    },
  },
  fullSearchBar: {
    border: 'solid 1px #989898',
    borderRadius: 20,
  },
  boldHeader: {
    fontWeight: 900,
  },
  title: {
    fontSize: '20px',
    fontWeight: 800,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '20px',
    justifyContent: 'center',
    fontWeight: 600,
  },
  numberOfRequests: {
    fontSize: '30px',
    fontWeight: 800,
  },
  svgIcon: {
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
  fieldTitle: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 600,
    color: 'black',

    maxLines: 1,
  },
  fieldSubtitle: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'black',
    opacity: 0.75,
    textOverflow: 'ellipsis',
    maxLines: 1,
  },
});

interface Props {
  event: Event | null;

  marketingRequest: Campaign | null;
  searchText?: string;
  user?: string;
  request?: MarketingRequest;
}
const MarketingRequestCard: React.VFC<Props> = ({
  event,

  marketingRequest,
  request,
  user,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { dateString, timeString } = event
    ? getEventDates(event)
    : { dateString: '', timeString: '' };

  const buildDescriptionField = (
    title?: string,
    subtitle?: string,
    icon?: () => JSX.Element,
    allRow?: boolean,
  ) => (
    <Grid
      item
      container
      direction="row"
      display="flex"
      xs={12}
      sx={{ maxWidth: '100px' }}
    >
      <Stack direction="row" alignItems="center">
        {icon && icon()}
        <Stack direction="column">
          <Typography
            className={classes.fieldTitle}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',

              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              textoverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
          <Typography
            className={classes.fieldSubtitle}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );

  return (
    <Card sx={{ width: '100%', overflow: 'visible' }}>
      <LensIcon
        sx={{
          color: marketingRequest?.color,
          position: 'absolute',
          top: '-0.5rem',
          left: '-0.5rem',
          scale: '2',
        }}
      ></LensIcon>
      <Container sx={{ width: '95%', paddingTop: 5, paddingBottom: 5 }}>
        <Stack direction="row" justifyContent={'space-evenly'} spacing={'10%'}>
          <Stack
            direction="row"
            display="inline-flex"
            spacing={3}
            alignItems="center"
          >
            <div>
              {event?.imageUrl == null ? (
                <CardMedia
                  component="img"
                  className={classes.eventImage}
                  image="https://images.unsplash.com/photo-1506157786151-b8491531f063?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                />
              ) : (
                <CardMedia
                  component="img"
                  className={classes.eventImage}
                  image={event?.imageUrl}
                />
              )}
            </div>
            <Grid item xs={5} sx={{ paddingBottom: 8 }} display="inline-block">
              <Typography
                variant="size4"
                textAlign="start"
                fontWeight={800}
                display="block"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '2',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {event?.name}
              </Typography>
              <Box sx={{ m: 2 }} />
              {buildDescriptionField(dateString, timeString, () => (
                <SvgIcon
                  component={Calendar}
                  className={classes.svgIcon}
                  style={{
                    width: 21,
                    height: 21,
                  }}
                  inheritViewBox
                />
              ))}
              <Box sx={{ m: 1 }} />
              {buildDescriptionField(
                event?.addressName,
                event?.address,
                () => (
                  <SvgIcon
                    component={Location}
                    className={classes.svgIcon}
                    style={{
                      width: 14,
                      height: 21,
                      marginRight: 10,
                      marginLeft: 4,
                    }}
                    inheritViewBox
                  />
                ),
                true,
              )}
            </Grid>
          </Stack>

          <Stack>
            <Typography
              sx={{ fontSize: 30, fontWeight: 800 }}
              textAlign="start"
              fontWeight={800}
              display="block"
              fontFamily={'SF Pro Display'}
              fontStyle={'light'}
            >
              {marketingRequest?.title}
            </Typography>
            <Typography
              sx={{ fontSize: 12, fontWeight: 800 }}
              textAlign="start"
              fontWeight={800}
              fontFamily={'SF Pro Display'}
              display="block"
            >
              {marketingRequest?.subTitle}
            </Typography>
            <Box sx={{ m: 5 }} />
            <Button
              className={classes.buttonSignup}
              sx={{
                backgroundColor: marketingRequest?.color,
                ':hover': { backgroundColor: marketingRequest?.color },
              }}
              variant="contained"
            >
              <Typography variant="size4" paddingLeft={2} fontWeight={500}>
                {marketingRequest?.description}
              </Typography>
            </Button>
          </Stack>
        </Stack>
        <Box sx={{ m: 4 }} />
        <Stack
          sx={{ width: '98%', paddingLeft: '20px' }}
          direction="row"
          justifyContent="space-between"
        >
          <Stack direction="column" alignItems="start" spacing={2}>
            <Typography
              variant="size4"
              textAlign="start"
              fontWeight={800}
              display="block"
            >
              Delivered:
            </Typography>
            <Typography
              textAlign="start"
              fontWeight={1000}
              display="block"
              fontFamily={'SF Pro Display'}
              fontStyle={'heavy'}
              fontSize={'20px'}
            >
              {request?.delivered
                ? request?.delivered
                : marketingRequest?.id != 'fEVw50RR34'
                ? 0
                : ''}{' '}
              <span style={{ fontSize: 12, fontWeight: 600, color: '#000000' }}>
                {marketingRequest?.id == 'C6MKIQxK2S' ||
                marketingRequest?.id == 'Z3yK7wB7c1'
                  ? 'Emails'
                  : marketingRequest?.id == 'fEVw50RR34'
                  ? 'N/A'
                  : marketingRequest?.id == '1DxC7cBIod'
                  ? 'Days Event Feed Highlight'
                  : marketingRequest?.id == 'EIfi7zNjpm'
                  ? 'Posts / Reels'
                  : marketingRequest?.id == 'gDSmDflc69' ||
                    marketingRequest?.id == 'AEJn9qdSfn'
                  ? 'SMS'
                  : 'Story'}
              </span>
            </Typography>
          </Stack>

          <Typography
            variant="size4"
            textAlign="start"
            fontWeight={800}
            display="block"
          >
            Status: <span style={{ color: '#10BF7A' }}>{request?.status}</span>
          </Typography>
          <Stack direction="column" alignItems="start" spacing={2}>
            <Typography
              variant="size4"
              textAlign="start"
              fontWeight={800}
              display="block"
            >
              Total Amount Spent:
            </Typography>
            <Typography
              alignSelf={'self-end'}
              fontWeight={1000}
              display="block"
              fontFamily={'SF Pro Display'}
              fontStyle={'heavy'}
              fontSize={'20px'}
            >
              {request?.totalCost}{' '}
              <span style={{ fontSize: 12, fontWeight: 600, color: '#000000' }}>
                EGP
              </span>
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Card>
  );
};

export default MarketingRequestCard;
