import React from 'react';

import CenterVideo from 'src/assets/video/centerVideo2.mp4';
import Min1 from 'src/assets/video/min1.mp4';
import Min2 from 'src/assets/video/min1.mp4';
import Min3 from 'src/assets/video/min3.mp4';
import Min4 from 'src/assets/video/min4.mp4';
import Min5 from 'src/assets/video/min5.mp4';
import ThumbCenter from 'src/assets/video/thumbCenter.png';
import ThumbMin1 from 'src/assets/animation/2.webp';
import ThumbMin2 from 'src/assets/video/thumbMin2.png';
import ThumbMin3 from 'src/assets/video/thumbMin2.png';
import ThumbMin4 from 'src/assets/video/thumbMin3.png';
import ThumbMin5 from 'src/assets/video/thumbMin5.png';
import styled from 'styled-components';
import { createUseStyles } from 'react-jss';

type AnimProps = {
  no: number;
};

const AnimationContainer = styled.div`
  flex-grow: 1;
  width: 686px;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    width: 80%;
  }

  @media screen and (max-width: 425px) {
    // padding: 0 25px;
  }
`;

const IndexAnimation = styled.div`
  position: relative;
  aspect-ratio: 1/1;
  width: 100%;
  margin: auto;
  @media screen and (max-width: 900px) {
    aspect-ratio: 3/4;
  }
  @media screen and (max-width: 767px) {
    aspect-ratio: 2/3;
    margin-top: 0px;
    width: 100%;
  }
`;

const AnimItem = styled.div<AnimProps>`
  video {
    width: 100%;
  }
  position: relative;
  ${({ no }) => {
    switch (no) {
      case 1:
        return 'video{width: 100%; height: 100%;border-radius: 40px;}; width:250px;left: 218px;top: 110.6px;z-index: 1; !important; display: flex; justify-content: center; align-items: center;';
      case 2:
        return 'video{width: 100%;border-radius: 12px;};width:134.6px;opacity:.24;left:23px;top: 79.94px;z-index: 1;';
      case 3:
        return 'video{width: 100%;border-radius: 12px;};width:134.6px;opacity:.24;right: 120px;top: 41.23px;';
      case 4:
        return 'video{width: 100%;border-radius: 12px;};width:134.6px;opacity:.24;right: 25px;bottom: -50px;';
      case 5:
        return 'video{width: 100%;border-radius: 12px;};width:134.6px;opacity:.24;left: 123px;bottom: 25px;';
      case 6:
        return 'video{width: 100%;border-radius: 10px;};width:95.57px;opacity:.24;right: -50px;top: 130px;';
      default:
        return;
    }
  }}
  }
  @media screen and (max-width: 900px) {
    ${({ no }) => {
      switch (no) {
        case 1:
          return 'video{width: 100%; height: 100%;border-radius: 11%;};width:54%;left: 23%;top: 15%;z-index: 1; !important; display: flex; justify-content: center; align-items: center;';
        case 2:
          return 'video{width: 100%;border-radius: 7%;};width:30%;opacity:.24;left:2%;top: 13%;';
        case 3:
          return 'video{width: 100%;border-radius: 7%;};width:30%;opacity:.24;right: 15%;top: 4%;';
        case 4:
          return 'video{width: 100%;border-radius: 7%;};width:30%;opacity:.24;right: 4%;bottom: 0%;';
        case 5:
          return 'video{width: 100%;border-radius: 7%;};width:30%;opacity:.24;left: 7%;bottom: 0%;';
        case 6:
          return 'video{width: 100%;border-radius: 7%;};width:16%;opacity:.24;right: 0%;top: 15%;';
        default:
          return;
      }
    }}
  }
  img {
    /* border-radius: 3%; */
  }
  position: absolute;
  transition: 0.2s linear;
`;

const AnimShapes = styled.div<AnimProps>`
  width: 54px;
  position: absolute;
  height: 42px;
  border-radius: 18%;
  opacity: 0.75;
  ${({ no }) => {
    switch (no) {
      case 1:
        return 'left: -3px;top: 60px;z-index: 1; background-color: #FFCF5C;';
      case 2:
        return 'right: 14px;top: 315px;z-index: 1; background-color: #66A7E3;';
      case 3:
        return 'left: 124px;bottom: 10px;z-index: 1; background-color: #FF5A6D;';
      case 4:
        return 'right: 5px;bottom: -70px;z-index: 1; background-color: #2CB9A2;';
      default:
        return;
    }
  }}
  @media screen and (max-width: 900px) {
    width: 10%;
    height: 6%;
    ${({ no }) => {
      switch (no) {
        case 1:
          return 'left: -2%;top: 10%;z-index: 1; background-color: #FFCF5C;';
        case 2:
          return 'right: 7%;top: 40%;z-index: 1; background-color: #66A7E3;';
        case 3:
          return 'left: 5%;bottom: 0%;z-index: 1; background-color: #FF5A6D;';
        case 4:
          return 'right: 0%;bottom: 0%;z-index: 1; background-color: #2CB9A2;';
        default:
          return;
      }
    }}
  }
  transition: 0.2s linear;
`;

const useStyles = createUseStyles({});

const AnimationPart: React.VFC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles();
  return (
    <AnimationContainer>
      <IndexAnimation>
        <AnimItem no={1} className="animation-move">
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbCenter}
          >
            <source
              src={CenterVideo}
              type="video/mp4"
              // preload="none"
            />
          </video>
        </AnimItem>
        <AnimItem no={2} className="animation-move">
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbMin1}
          >
            <source
              src={Min1}
              type="video/mp4"
              // preload="none"
            />
          </video>
        </AnimItem>
        <AnimItem no={3} className="animation-move">
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbMin2}
          >
            <source
              src={Min2}
              type="video/mp4"
              // preload="none"
            />
          </video>
        </AnimItem>
        <AnimItem no={4} className="animation-move">
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbMin3}
          >
            <source
              src={Min3}
              type="video/mp4"
              // preload="none"
            />
          </video>
        </AnimItem>
        <AnimItem no={5} className="animation-move">
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbMin4}
          >
            <source
              src={Min4}
              type="video/mp4"
              // preload="none"
            />
          </video>
        </AnimItem>
        <AnimItem no={6} className="animation-move">
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbMin5}
          >
            <source
              src={Min5}
              type="video/mp4"
              // preload="none"
            />
          </video>
        </AnimItem>
        <AnimShapes no={1} className="animation-move" />
        <AnimShapes no={2} className="animation-move" />
        <AnimShapes no={3} className="animation-move" />
        <AnimShapes no={4} className="animation-move" />
      </IndexAnimation>
    </AnimationContainer>
  );
};

export default AnimationPart;
