import {
  Card,
  Grid,
  CardContent,
  Stack,
  Container,
  Button,
  Typography,
} from '@mui/material';
import React from 'react';
import { Event } from 'src/models/event';
import EventButton from 'src/components/event/EventButton';
import DisabledEventButton from 'src/components/event/DisabledEventButton';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import Box from '@mui/material/Box';
import Parse from 'parse/node';
import { FaPen, FaPenSquare } from 'react-icons/fa';

const useStyles = createUseStyles({
  button: {
    color: 'white',
    borderRadius: '30px',
    cursor: 'pointer',
    width: '80vw',
    height: '50px',
    marginTop: 15,
  },
  image: {
    height: '40vw',
    width: '40vw',
    padding: '10%',
    fill: 'black',
    opacity: '0.3',
  },
  buttonText: {
    fontWeight: 800,
    fontSize: '20px',
    '@media screen and (max-width: 1500px)': {
      fontSize: '16px',
    },
    '@media screen and (max-width: 1200px)': {
      fontSize: '12px',
    },
    '@media screen and (max-width: 1000px)': {
      fontSize: '10px',
    },
  },
  title: {
    marginTop: '3%',
    fontSize: '20px',
    fontWeight: 800,
    color: '#000',
  },
  availablesoon: {
    position: 'absolute',

    right: 20,
    top: 17,
    bottom: 0,
  },
  CardforIcon: {
    position: 'absolute',

    top: 5,
    right: 5,
    backgroundColor: 'grey',
    width: '30px',
    height: '30px',
    borderRadius: '9px',
    opacity: 0.5,
    zIndex: 99,
  },
  Penicon: {
    color: 'white',
    marginLeft: '1.5vw',
    marginTop: '1.3vw',
  },
  TopBarContainer: {
    marginBottom: '3%',
    marginTop: '3%',
  },
});

const PlannerTopBar: React.VFC = () => {
  const history = useHistory();
  const classes = useStyles();
  const PlannerName = Parse.User.current()?.get('name');
  const imageLink = Parse.User.current()?.get('profilePicture')?.url();
  return (
    <Container className={classes.TopBarContainer}>
      <Stack direction={'column'} alignItems={'center'}>
        <Card
          sx={{
            marginBottom: 1,
            position: 'relative',
            boxShadow: '0px 2px 4px 1px #B9B6B6',
          }}
        >
          <img
            className={classes.image}
            src={imageLink ? imageLink : 'https://i.imgur.com/KIhGaHq.png'}
          />
          <Card className={classes.CardforIcon}>
            <FaPen className={classes.Penicon} />
          </Card>
        </Card>

        <div className={classes.title}>{PlannerName}</div>
        <Button
          variant="contained"
          className={classes.button}
          sx={{
            backgroundColor: '#979797',
            fontSize: '16px',

            justifyContent: 'flex-start',
            textTransform: 'none',
            '&:disabled': {
              color: 'white',
            },
          }}
          disabled={true}
        >
          {
            <div>
              <Typography fontSize={18} marginLeft={'10px'}>
                Create New Event
              </Typography>

              <Typography fontSize={12} className={classes.availablesoon}>
                Available Soon!
              </Typography>
            </div>
          }
        </Button>
      </Stack>
    </Container>
  );
};

export default PlannerTopBar;
