import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Link,
  useMediaQuery,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { theme } from 'src/constants/theme';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { paymobCallback } from 'src/slices/request';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { FaTemperatureLow } from 'react-icons/fa';

const useStyles = createUseStyles({
  mainContainer: {
    height: '100%',
    '@media screen and (max-width: 900px)': {},
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    margin: '0 auto',
    padding: '0 20px',
    display: 'flex',
  },
  mobileContainer: {
    height: '100%',
    '@media screen and (max-width: 900px)': {},
    justifyContent: 'left',
    alignItems: 'left',
    justifyItems: 'left',
    margin: '0 auto',
    padding: '0 20px',
    display: 'flex',
    maxWidth: '90%',
  },
  headerText: {
    fontWeight: 800,
    fontSize: '34px',
    alignContent: 'center',
    align: 'center',
    alignSelf: 'center',
    color: 'black',
  },
  subHeader: {
    fontWeight: 400,
    fontSize: '22px',
    color: 'text.primary',
    underline: 'none',
    lineHeight: '30px',
  },
  logo: {
    display: 'inline-flex',
    marginLeft: '10px',
    marginRight: '10px',

    minWidth: '30px',
    maxHeight: '30px',
  },
  logo2: {
    display: 'inline-flex',
    marginLeft: '15px',
    marginRight: '15px',
  },
  backToHomeButton: {
    textTransform: 'none',
    backgroundColor: 'orange',
    borderRadius: 20,
    width: '100%',
    maxWidth: '500px',
    alignSelf: 'center',
  },
});

export const PrivacyPolicy: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isDesktop = useMediaQuery('(min-width:600px)');
  return (
    <div
      className={classes.mainContainer}
      style={isDesktop ? { maxWidth: '80%' } : { maxWidth: '93%' }}
    >
      <Stack
        alignItems={isDesktop ? 'flex' : 'flex-start'}
        direction="column"
        justifyContent="left"
        spacing={3}
      >
        <Box m={3}></Box>

        <Typography variant="h4" className={classes.headerText}>
          <img
            src="https://i.imgur.com/c9O9LCK.png"
            style={{ marginBottom: -2, display: 'inline-flex' }}
            width="52px"
            height="35px"
          ></img>{' '}
          Privacy Policy
        </Typography>
        <p>
          {' '}
          <b>Privacy Policy</b>
        </p>
        <p>
          <em>Last updated: Dec 27, 2022</em>
        </p>
        <p>
          At Makanak, we are committed to protecting the privacy of our
          users. This Privacy Policy explains how we collect, use, and share
          information about you when you use our ticketing software and related
          services (the Services).
        </p>
        <p>
          Please read this Privacy Policy carefully. By using our Services, you
          agree to the collection, use, and sharing of your information as
          described in this Privacy Policy. If you do not agree with our
          policies and practices, do not use our Services.
        </p>
        <p>
          <strong>Information We Collect</strong>
        </p>
        <p>
          We collect information about you in a few different ways when you use
          our Services.
        </p>
        <p>
          <strong>Information You Provide to Us:</strong> We collect information
          that you provide to us directly, such as when you create an account,
          purchase tickets, or contact customer support. This information may
          include your name, email address, phone number, and payment
          information.
        </p>
        <p>
          <strong>Information We Collect Automatically:</strong> When you use
          our Services, we may collect certain information automatically, such
          as your IP address, device type, and browser type. We may also collect
          information about your location and how you use our Services.
        </p>
        <p>
          <strong>Information We Collect from Third Parties:</strong> We may
          receive information about you from third parties, such as social media
          platforms or ticket resellers.
        </p>
        <p>
          <strong>How We Use Your Information</strong>
        </p>
        <p>
          We use the information we collect about you for a variety of purposes,
          including:
        </p>
        <p>
          <strong>To provide, maintain, and improve our Services:</strong> We
          use your information to provide, maintain, and improve the Services,
          including to process transactions, send you tickets and other
          information, and respond to your requests for support.
        </p>
        <p>
          <strong>To communicate with you:</strong> We may use your information
          to send you marketing communications, updates about our Services, and
          other information that may be of interest to you. You can opt out of
          receiving these communications at any time by following the
          unsubscribe instructions in the communications or by adjusting your
          communication preferences in your account settings.
        </p>
        <p>
          <strong>For research and development:</strong> We may use your
          information to conduct research and development to improve our
          Services and develop new products and features.
        </p>
        <p>
          <strong>To comply with legal obligations:</strong> We may use your
          information to comply with legal obligations, such as to respond to a
          subpoena or to protect the safety of our users.
        </p>
        <p>
          <strong>How We Share Your Information</strong>
        </p>
        <p>
          We may share your information with third parties in the following
          circumstances:
        </p>
        <p>
          <strong>With service providers:</strong> We may share your information
          with service providers who perform services on our behalf, such as
          payment processors and customer support providers.
        </p>
        <p>
          <strong>With business partners:</strong> We may share your information
          with business partners who offer products or services that may be of
          interest to you.
        </p>
        <p>
          <strong>For legal reasons:</strong> We may share your information if
          we believe it is necessary to comply with a legal obligation, such as
          to respond to a subpoena or to protect the safety of our users.
        </p>
        <p>
          <strong>In the event of a merger or acquisition:</strong> If we are
          acquired by or merged with another company, we may share your
          information with that company.
        </p>
        <p>
          <strong>Your Choices and Rights</strong>
        </p>
        <p>
          You have certain choices and rights regarding the information we
          collect and how we use it. These include the following:
        </p>
        <p>
          <strong>Opting out of marketing communications:</strong> You can opt
          out of receiving marketing communications from us by following the
          unsubscribe instructions in the communications or by adjusting your
          communication preferences in your account settings.
        </p>
        <p>
          <strong>Accessing and updating your information:</strong> You can
          access and update your information by logging into your account and
          making the necessary changes.
        </p>
        <p>
          <strong>Requesting the deletion of your information:</strong> You can
          request the deletion of your information.
        </p>
        <br></br>
        <br></br>
      </Stack>
    </div>
  );
};
