import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { EventTicket as Ticket } from 'src/models/ticket';
import { MdDelete } from 'react-icons/md';
import { commonStyles } from 'src/constants/common-styles';
import { FormikErrors, FormikTouched } from 'formik';
import { FormValues } from 'src/pages/CreateEventPage';

interface CreateTicketProps {
  ticketValues: Ticket;
  Pfees: number;
  usePaymob: boolean;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1,
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  onClickDelete: () => void;
  index: number;
  canDelete: boolean;
  formikErrors: FormikErrors<FormValues>;
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  formikTouched: FormikTouched<FormValues>;
}

const useStyles = createUseStyles({
  buttonWrapper: {
    position: 'relative',
  },
  deleteTicketButton: {
    position: 'absolute',
    right: '1rem',
    bottom: '1rem',
  },
  Input: {
    color: 'black',
    [`& fieldset`]: { border: 'none' },
  },
  whiteInputProps: {
    color: 'black',
    border: '1px solid #F0F0F0',
    borderRadius: 30,
    [`& fieldset`]: { border: 'none' },
  },
});

export const CreateTicket: React.VFC<CreateTicketProps> = ({
  ticketValues: {
    name,
    description,
    price,
    min,
    max,
    total,
    fees,
    onInvitation,
  },
  Pfees,
  usePaymob,
  onChange,
  canDelete,
  onClickDelete,
  index,
  formikErrors,
  formikTouched,
  onBlur,
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  return (
    <Grid item container xs={12} spacing={4}>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Name<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.name`}
            name={`tickets.${index}.name`}
            placeholder="Name"
            value={name}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.name &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.name,
            )}
            helperText={
              formikTouched.tickets?.[index]?.name
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.name
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Price<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.price`}
            name={`tickets.${index}.price`}
            placeholder="Price"
            type="number"
            value={price}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.price &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.price,
            )}
            helperText={
              formikTouched.tickets?.[index]?.price
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.price
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Fees<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.fees`}
            name={`tickets.${index}.fees`}
            placeholder="Fees"
            type="number"
            value={price ? Math.floor((price * (Pfees / 100) + 2) * 1.14) : 0}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            disabled={true}
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.fees &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.fees,
            )}
            helperText={
              formikTouched.tickets?.[index]?.fees
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.fees
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Quantity<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.total`}
            name={`tickets.${index}.total`}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="Quantity"
            type="number"
            value={total}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.total &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.total,
            )}
            helperText={
              formikTouched.tickets?.[index]?.total
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.total
                : ''
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={onInvitation}
                id={`tickets.${index}.onInvitation`}
                value={true}
                onChange={onChange}
                name={`tickets.${index}.onInvitation`}
                color="primary"
              />
            }
            label="Invitation Ticket"
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">Min number of Tickets:</Typography>
          <TextField
            id={`tickets.${index}.min`}
            name={`tickets.${index}.min`}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="Min number of Tickets"
            type="number"
            value={min}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.min &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.min,
            )}
            helperText={
              formikTouched.tickets?.[index]?.min
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.min
                : ''
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">Max number of Tickets:</Typography>
          <TextField
            id={`tickets.${index}.max`}
            name={`tickets.${index}.max`}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="Max number of Tickets"
            type="number"
            value={max}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.max &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.max,
            )}
            helperText={
              formikTouched.tickets?.[index]?.max
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]?.max
                : ''
            }
          />
        </Stack>
      </Grid>

      <Grid item xs={8}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Description<sup>*</sup>:
          </Typography>
          <TextField
            id={`tickets.${index}.description`}
            name={`tickets.${index}.description`}
            minRows={5}
            placeholder="Description"
            multiline
            value={description}
            onChange={onChange}
            InputProps={{ className: classes.whiteInputProps }}
            className={classes.Input}
            required
            onBlur={onBlur}
            error={Boolean(
              formikTouched.tickets?.[index]?.description &&
                (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                  ?.description,
            )}
            helperText={
              formikTouched.tickets?.[index]?.description
                ? (formikErrors.tickets as FormikErrors<Ticket[]>)?.[index]
                    ?.description
                : ''
            }
          />
        </Stack>
      </Grid>
      {canDelete && (
        <Grid item xs={4} className={classes.buttonWrapper}>
          <IconButton
            onClick={onClickDelete}
            className={`${commonClasses.mainCTA} ${classes.deleteTicketButton}`}
          >
            <MdDelete />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
