import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Event } from 'src/models/event';
import { DesktopEventsEventCard } from '../events/DesktopEventsEventCard';
import { useHistory } from 'react-router-dom';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Finished } from '../../assets/finishedFlag.svg';
import { ReactComponent as Pending } from '../../assets/pending.svg';
import { getEventDates } from 'src/helpers/event/getEventDates';

const useStyles = createUseStyles({
  button: {
    borderRadius: 10,
    width: '150px',
    height: '25px',
    textTransform: 'none',
    padding: '8px 15px',
    '@media (max-width: 500px)': {
      padding: '8px 10px',
      width: '180px',
    },
  },
  ticketButton: {
    backgroundColor: '#F7BE1F',
  },
  icon: {
    fontSize: '16.2px',
  },
  date: {
    color: 'gray',
  },
  text: {
    fontWeight: 0,
    color: 'black',
    textOverflow: 'ellipsis',
    fontSize: '12px',
  },
  desktopDisabledButtonText: {
    fontWeight: 0,
    color: 'black',
  },
  desktopButtonText: {
    fontWeight: 0,
    color: 'white',
    fontSize: '16.2px',
    fontFamily: 'SF PRO Display',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '12.5px',
    border: '1px solid #FFF',
    minWidth: '100px',
    maxWidth: '120px',
    height: '85px',
    margin: '1rem',
  },
  svgIcon: {
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    '@media screen and (max-width: 900px)': {
      width: '30px',
      height: '30px',
    },
  },
});

interface PurchasedTicketCardProps {
  numberOfTickets: number;
  isEventOver: boolean;
  isApprovalPending: boolean;
  event: Event;
  onClickDownloadPasses: () => void;
}

export const PurchasedTicketCard: React.FC<PurchasedTicketCardProps> = ({
  numberOfTickets,
  isEventOver,
  isApprovalPending,
  event,
  onClickDownloadPasses,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const onClickGoToEvent = () => {
    history.push(`/events/${event.id}`);
  };
  const displayMobileVersion = () => (
    <Stack direction="row" alignItems="center" maxWidth="100%">
      <Box
        sx={{
          aspectRatio: 1,
          height: '115px',
          width: '115px',
          borderRadius: '50px',
        }}
      >
        <Box
          component="img"
          src={event.imageUrl}
          sx={{ borderRadius: '13px', objectFit: 'cover', height: '100%' }}
        />
      </Box>
      <Stack direction="column" spacing={1} paddingLeft={2}>
        <p className={classes.date}>
          {getEventDates(event).startDate.toLocaleDateString()}
        </p>
        <Box
          fontWeight="bold"
          fontSize="18px"
          onClick={onClickGoToEvent}
          textOverflow="ellipsis"
          overflow="elliipsis"
          maxWidth={{ xs: '150px', sm: '200px' }}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
          }}
        >
          {event.name}
        </Box>
        {!isApprovalPending && !isEventOver && (
          <Button
            sx={{
              backgroundColor: '#F7BE1F !important',
              borderRadius: '50px',
              padding: '6px 15px',
              textTransform: 'none',
            }}
            variant="contained"
            size="small"
            onClick={onClickDownloadPasses}
            fullWidth
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <p className={classes.desktopButtonText}>
                {numberOfTickets} Tickets
              </p>
              <DownloadIcon className={classes.icon} />
            </Stack>
          </Button>
        )}
        {isApprovalPending && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={onClickGoToEvent}
          >
            <Typography className={classes.desktopButtonText} color="#FF4771">
              Pending Approval
            </Typography>
            <SvgIcon
              component={Pending}
              className={classes.svgIcon}
              inheritViewBox
            />
          </Stack>
        )}
        {isEventOver && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={onClickGoToEvent}
          >
            <Typography className={classes.desktopButtonText} color="#6CC9FF">
              Finished Event
            </Typography>
            <SvgIcon
              component={Finished}
              className={classes.svgIcon}
              inheritViewBox
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
  const displayDesktopTicketButton = () => (
    <Stack direction="row" alignItems="center" spacing={2}>
      {!isApprovalPending && !isEventOver ? (
        <Button
          sx={{
            backgroundColor: '#F7BE1F !important',
            borderRadius: '50px',
            padding: '6px 15px',
            textTransform: 'none',
            width: '180px',
            height: '45px',
          }}
          variant="contained"
          size="small"
          onClick={onClickDownloadPasses}
          fullWidth
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <p className={classes.desktopButtonText}>
              {numberOfTickets} Tickets
            </p>
            <DownloadIcon className={classes.icon} />
          </Stack>
        </Button>
      ) : isEventOver ? (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={onClickGoToEvent}
          width="237px"
        >
          <Typography className={classes.desktopButtonText} color="#6CC9FF">
            Finished Event
          </Typography>
          <SvgIcon
            component={Finished}
            className={classes.svgIcon}
            inheritViewBox
          />
        </Stack>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          onClick={onClickGoToEvent}
        >
          <Typography className={classes.desktopButtonText} color="#FF4771">
            Pending Approval
          </Typography>
          <SvgIcon
            component={Pending}
            className={classes.svgIcon}
            inheritViewBox
          />
        </Stack>
      )}
    </Stack>
  );
  const getStatusColor = () => {
    if (!isApprovalPending && !isEventOver) {
      return 'F7BE1F';
    } else if (isEventOver) {
      return '6CC9FF';
    } else {
      return 'FF4771';
    }
  };
  const displayDesktopVersion = () => (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <DesktopEventsEventCard
        event={event}
        displayGetTickets
        redirectOnClickName={false}
        statusColor={getStatusColor()}
      />
      {displayDesktopTicketButton()}
    </Stack>
  );
  return isDesktop ? displayDesktopVersion() : displayMobileVersion();
};

export default PurchasedTicketCard;
