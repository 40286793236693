import React from 'react';
import { createUseStyles } from 'react-jss';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { EventTicket, EventTicket as Ticket } from 'src/models/ticket';
import { commonStyles } from 'src/constants/common-styles';
import { FormikErrors, FormikTouched } from 'formik';
import { FormValues } from 'src/pages/UpdateEventPage';
import { FormTextField } from '../FormTextField';

interface UpdateTicketProps {
  ticketValues: Ticket;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1,
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  onClickDelete: () => void;
  index: number;
  canDelete: boolean;
  formikErrors: FormikErrors<FormValues>;
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  formikTouched: FormikTouched<FormValues>;
}

const useStyles = createUseStyles({
  buttonWrapper: {
    position: 'relative',
  },
  deleteTicketButton: {
    position: 'absolute',
    right: '1rem',
    bottom: '1rem',
  },
  Input: {
    color: 'black',
    [`& fieldset`]: { border: 'none' },
  },
  whiteInputProps: {
    color: 'black',
    border: '1px solid #F0F0F0',
    borderRadius: 30,
    [`& fieldset`]: { border: 'none' },
  },
});

export const UpdateTicket: React.VFC<UpdateTicketProps> = ({
  ticketValues: { name, description, price, min, max, total, fees },
  onChange,
  onClickDelete,
  canDelete,
  index,
  onBlur,
  formikErrors,
  formikTouched,
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  return (
    <Grid item container xs={12} spacing={4}>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Name<sup>*</sup>:
          </Typography>
          <FormTextField
            name={`tickets.${index}.name`}
            placeholder="Name"
            value={name}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            className={commonClasses.Input}
            required
            onBlur={onBlur}
            error={
              typeof formikErrors.tickets === 'string'
                ? (formikErrors.tickets as string)
                : Array.isArray(formikErrors.tickets) &&
                  (formikErrors.tickets as unknown[]).length > 0
                ? typeof formikErrors.tickets[0] === 'string'
                  ? (formikErrors.tickets?.[index] as string | undefined)
                  : (
                      formikErrors.tickets as
                        | FormikErrors<EventTicket>[]
                        | undefined
                    )?.[index]?.name
                : undefined
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            touched={formikTouched?.tickets?.[index]?.name}
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Price<sup>*</sup>:
          </Typography>
          <FormTextField
            name={`tickets.${index}.price`}
            placeholder="Price"
            type="number"
            value={price?.toString()}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            className={commonClasses.Input}
            required
            onBlur={onBlur}
            error={
              typeof formikErrors.tickets === 'string'
                ? (formikErrors.tickets as string)
                : Array.isArray(formikErrors.tickets) &&
                  (formikErrors.tickets as unknown[]).length > 0
                ? typeof formikErrors.tickets[0] === 'string'
                  ? (formikErrors.tickets?.[index] as string | undefined)
                  : (
                      formikErrors.tickets as
                        | FormikErrors<EventTicket>[]
                        | undefined
                    )?.[index]?.price
                : undefined
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            touched={formikTouched?.tickets?.[index]?.price}
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Fees<sup>*</sup>:
          </Typography>
          <FormTextField
            name={`tickets.${index}.fees`}
            placeholder="Fees"
            type="number"
            value={fees?.toString()}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            className={commonClasses.Input}
            required
            onBlur={onBlur}
            error={
              typeof formikErrors.tickets === 'string'
                ? (formikErrors.tickets as string)
                : Array.isArray(formikErrors.tickets) &&
                  (formikErrors.tickets as unknown[]).length > 0
                ? typeof formikErrors.tickets[0] === 'string'
                  ? (formikErrors.tickets?.[index] as string | undefined)
                  : (
                      formikErrors.tickets as
                        | FormikErrors<EventTicket>[]
                        | undefined
                    )?.[index]?.fees
                : undefined
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            touched={formikTouched?.tickets?.[index]?.fees}
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Quantity<sup>*</sup>:
          </Typography>
          <FormTextField
            name={`tickets.${index}.total`}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="Quantity"
            type="number"
            value={total?.toString()}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            className={commonClasses.Input}
            required
            onBlur={onBlur}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            error={
              typeof formikErrors.tickets === 'string'
                ? (formikErrors.tickets as string)
                : Array.isArray(formikErrors.tickets) &&
                  (formikErrors.tickets as unknown[]).length > 0
                ? typeof formikErrors.tickets[0] === 'string'
                  ? (formikErrors.tickets?.[index] as string | undefined)
                  : (
                      formikErrors.tickets as
                        | FormikErrors<EventTicket>[]
                        | undefined
                    )?.[index]?.total
                : undefined
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            touched={formikTouched?.tickets?.[index]?.total}
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">Min number of Tickets:</Typography>
          <FormTextField
            name={`tickets.${index}.min`}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="Min number of Tickets"
            type="number"
            value={min?.toString()}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            className={commonClasses.Input}
            onBlur={onBlur}
            error={
              typeof formikErrors.tickets === 'string'
                ? (formikErrors.tickets as string)
                : Array.isArray(formikErrors.tickets) &&
                  (formikErrors.tickets as unknown[]).length > 0
                ? typeof formikErrors.tickets[0] === 'string'
                  ? (formikErrors.tickets?.[index] as string | undefined)
                  : (
                      formikErrors.tickets as
                        | FormikErrors<EventTicket>[]
                        | undefined
                    )?.[index]?.min
                : undefined
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            touched={formikTouched?.tickets?.[index]?.min}
          />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack spacing={1}>
          <Typography variant="h6">Max number of Tickets:</Typography>
          <FormTextField
            name={`tickets.${index}.max`}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            placeholder="Max number of Tickets"
            type="number"
            value={max?.toString()}
            onChange={onChange}
            InputProps={{ className: commonClasses.whiteInputProps }}
            className={commonClasses.Input}
            onBlur={onBlur}
            error={
              typeof formikErrors.tickets === 'string'
                ? (formikErrors.tickets as string)
                : Array.isArray(formikErrors.tickets) &&
                  (formikErrors.tickets as unknown[]).length > 0
                ? typeof formikErrors.tickets[0] === 'string'
                  ? (formikErrors.tickets?.[index] as string | undefined)
                  : (
                      formikErrors.tickets as
                        | FormikErrors<EventTicket>[]
                        | undefined
                    )?.[index]?.max
                : undefined
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            touched={formikTouched?.tickets?.[index]?.max}
          />
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <Stack spacing={1}>
          <Typography variant="h6">
            Description<sup>*</sup>:
          </Typography>
          <FormTextField
            name={`tickets.${index}.description`}
            minRows={5}
            placeholder="Description"
            multiline
            value={description}
            onChange={onChange}
            InputProps={{ className: classes.whiteInputProps }}
            className={classes.Input}
            required
            onBlur={onBlur}
            error={
              typeof formikErrors.tickets === 'string'
                ? (formikErrors.tickets as string)
                : Array.isArray(formikErrors.tickets) &&
                  (formikErrors.tickets as unknown[]).length > 0
                ? typeof formikErrors.tickets[0] === 'string'
                  ? (formikErrors.tickets?.[index] as string | undefined)
                  : (
                      formikErrors.tickets as
                        | FormikErrors<EventTicket>[]
                        | undefined
                    )?.[index]?.description
                : undefined
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            touched={formikTouched?.tickets?.[index]?.description}
          />
        </Stack>
      </Grid>
      {canDelete && (
        <Grid item xs={4} className={classes.buttonWrapper}>
          <IconButton
            onClick={onClickDelete}
            className={`${commonClasses.mainCTA} ${classes.deleteTicketButton}`}
          >
            <MdDelete />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
