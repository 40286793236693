import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import React, { useMemo } from 'react';
import RequestCircleProgress from 'src/components/requests/RequestCircleProgress';
import { createUseStyles } from 'react-jss';
import { Request } from 'src/models/request';
import { EventTicket } from 'src/models/ticket';
import { useIsBigScreen } from 'src/hooks/useBigScreen';
import { Event } from '../../models/event';

const useStyles = createUseStyles({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '130px',
    fontSize: '',
  },
  paid: {
    fontSize: '20px',
    fontWeight: 800,
  },
  approved: {
    fontSize: '17px',
    fontWeight: 500,
  },
});

type RequestNumberByTicketId = { [ticketId: string]: number };

interface Props {
  requests: Request[] | null;
  event: Event | null;
}
const RequestsPaidApproved: React.VFC<Props> = ({ requests, event }) => {
  const classes = useStyles();
  const isBigScreen = useIsBigScreen();
  const getPaidRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.paid) {
        return accumulator;
      }
      Object.entries(request.ticketsNumberById).forEach(
        ([ticketId, ticketNumber]) => {
          accumulator[ticketId] =
            (accumulator[ticketId] ?? 0) + (ticketNumber ?? 0);
        },
      );
      return accumulator;
    }, {});

  const getApprovedRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.isApproved) {
        return accumulator;
      }
      Object.entries(request.ticketsNumberById).forEach(
        ([ticketId, ticketNumber]) => {
          accumulator[ticketId] =
            (accumulator[ticketId] ?? 0) + (ticketNumber ?? 0);
        },
      );
      return accumulator;
    }, {});

  const getRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      Object.entries(request.ticketsNumberById).forEach(
        ([ticketId, ticketNumber]) => {
          accumulator[ticketId] =
            (accumulator[ticketId] ?? 0) + (ticketNumber ?? 0);
        },
      );
      return accumulator;
    }, {});

  const getTotalPaidRequest = () =>
    Object.values(paidRequestByTicketId ?? {}).reduce(
      (accumulator, value) => accumulator + value,
      0,
    );
  const getTotalApprovedRequest = () =>
    Object.values(approvedRequestByTicketId ?? {}).reduce(
      (accumulator, value) => accumulator + value,
      0,
    );

  const paidRequestByTicketId = useMemo(
    () => getPaidRequestsNumberByTicketId(),
    [requests],
  );
  const approvedRequestByTicketId = useMemo(
    () => getApprovedRequestsNumberByTicketId(),
    [requests],
  );

  const requestsByTicketId = useMemo(
    () => getRequestsNumberByTicketId(),
    [requests],
  );

  const totalPaidApprovedRequestRatio = useMemo(() => {
    const totalApprovedRequests = getTotalApprovedRequest();
    const totalPaidRequest = getTotalPaidRequest();
    return totalApprovedRequests === 0
      ? 0
      : (totalPaidRequest / totalApprovedRequests) * 100;
  }, [paidRequestByTicketId, approvedRequestByTicketId]);

  const totalPaidRequests = useMemo(
    () => getTotalPaidRequest(),
    [paidRequestByTicketId],
  );

  const totalApprovedRequests = useMemo(
    () => getTotalApprovedRequest(),
    [approvedRequestByTicketId],
  );

  const displayPaidApprovedRatio = (ticket: EventTicket, index: number) => {
    if (ticket.id) {
      return (
        <Grid
          container
          item
          key={index}
          alignItems="center"
          justifyContent="center"
          paddingBottom={3}
          xs={12}
          marginTop={'-10px'}
          marginLeft={'-10px'}
        >
          <Grid item xs={10} sm={7} md={7} justifyContent="center">
            <div className={classes.centered}>
              <RequestCircleProgress
                size={isBigScreen ? 55 : 55}
                value={
                  ticket?.onInvitation
                    ? requestsByTicketId?.[ticket.id] ?? 0
                    : approvedRequestByTicketId?.[ticket.id] === 0 ||
                      !ticket.id ||
                      !paidRequestByTicketId?.[ticket.id] ||
                      !approvedRequestByTicketId?.[ticket.id]
                    ? 0
                    : (paidRequestByTicketId[ticket.id] /
                        approvedRequestByTicketId[ticket.id]) *
                      100
                }
                color="#F7B500"
                fontSize={'size6'}
                isInvitation={ticket?.onInvitation}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <Stack direction="column" alignItems="start">
              <Typography fontSize={{ xs: 10.4, xl: 13 }} fontWeight={500}>
                {ticket.name}
              </Typography>
              {!ticket?.onInvitation ? (
                <Typography>
                  <Typography
                    fontSize={{ xs: 12, xl: 15 }}
                    display="inline"
                    fontWeight={700}
                  >
                    {paidRequestByTicketId?.[ticket.id] ?? 0}
                  </Typography>
                  <Typography
                    fontSize={{ xs: 8, xl: 10 }}
                    display="inline"
                    fontWeight={500}
                  >
                    /{approvedRequestByTicketId?.[ticket.id] ?? 0}
                  </Typography>
                </Typography>
              ) : (
                <Typography
                  fontSize={{ xs: 12, xl: 15 }}
                  display="inline"
                  fontWeight={700}
                >
                  {requestsByTicketId?.[ticket.id] ?? 0}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      );
    }
  };

  const displayTotalPaidApprovedRatio = () => (
    <Grid container item xs={9} xl={9} alignItems="center" paddingBottom={3}>
      <Grid item xs={12} paddingBottom={4}>
        <p style={{ textAlign: 'center' }}>
          <Typography
            fontSize={{ xs: 17, xl: 22 }}
            variant="size4"
            display="inline"
            fontWeight={800}
          >
            Paid
          </Typography>
          <Typography
            fontSize={{ xs: 12, xl: 17 }}
            display="inline"
            fontWeight={500}
          >
            {' '}
            / Approved
          </Typography>
        </p>
      </Grid>
      <Grid
        item
        sm={4}
        display="flex"
        justifyContent={'center'}
        marginRight={4}
      >
        <RequestCircleProgress
          size={isBigScreen ? 95 : 90}
          value={totalPaidApprovedRequestRatio}
          color="#F7B500"
          fontSize={'size4'}
        />
      </Grid>
      <Grid item sm={4}>
        <Stack direction="column" alignItems="start">
          <Typography fontSize={{ xs: 18.4, xl: 23 }} fontWeight={500}>
            Total
          </Typography>
          <Typography>
            <Typography
              fontSize={{ xs: 17.5, xl: 22 }}
              display="inline"
              fontWeight={700}
            >
              {totalPaidRequests}
            </Typography>
            <Typography
              fontSize={{ xs: 12, xl: 15 }}
              display="inline"
              fontWeight={500}
            >
              /{totalApprovedRequests}
            </Typography>
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
  return (
    <Grid
      container
      alignItems="center"
      maxWidth="250px"
      justifySelf={{ xs: 'center', xl: 'flex-start' }}
      justifyContent="center"
    >
      {displayTotalPaidApprovedRatio()}
      {(event?.tickets ?? event?.packages ?? []).map(displayPaidApprovedRatio)}
    </Grid>
  );
};

export default RequestsPaidApproved;
