import React, { useEffect, useMemo, useState } from 'react';
import { Event } from 'src/models/event';
import { createUseStyles } from 'react-jss';
import { Box, Button, Drawer, IconButton, Stack } from '@mui/material';
import ModalTicket, {
  TicketsNumberByType,
} from 'src/components/tickets/ModalTicket';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Token } from '@stripe/stripe-js';
import { GuestFormValues } from 'src/components/tickets/MobileTicketGuestSection';
import { Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { SignUpValues } from './EventPageContent';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Calendar } from '../assets/calendar.svg';
import { ReactComponent as Location } from '../assets/location.svg';
import { ReactComponent as Cd } from '../assets/cd.svg';
import { ReactComponent as Tickets } from '../assets/ticketLogo.svg';
import Menu from '@mui/icons-material/Menu';
import { Link, useHistory } from 'react-router-dom';
import {
  LogoutBtnDiv,
  SideMenu,
  SideMenubar,
  SideMenuItem,
} from '../components/old/layout/Header';
import CloseIcon from '@mui/icons-material/Close';
import {
  customerMenu,
  Menu as MenuType,
  plannerMenu,
  publicMenu,
} from '../data/menus';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { logOut } from '../slices/authentication';
import { IoIosArrowBack } from 'react-icons/all';
import { getEventDates } from 'src/helpers/event/getEventDates';
import FooterBar from '../components/FooterBar';

import MobilePackage from 'src/components/tickets/MobilePackage';

interface Props {
  event: Event;
  totalTicketsByType: TicketsNumberByType;
  totalNumberOfTickets: number;
  totalPrice: number;
  totalFees: number;
  isPaymentModalOpen: boolean;
  isRightAmountOfTickets: boolean;
  onReservation: boolean;
  isBuyTicketsFormOpen: boolean;
  setSelectedTicket: any;
  selectedTicket: any;
  isPaymentOpen: boolean;
  noTicketsAvailable: boolean;
  setTotalTicketsByType: React.Dispatch<
    React.SetStateAction<TicketsNumberByType>
  >;
  onCloseBuyTicketsForm: () => void;
  onOpenBuyTicketsForm: () => void;
  onClosePayment: () => void;
  onOpenPayment: () => void;
  onSubmitRequest: (
    guestFormValues: GuestFormValues,
    signUpValues?: SignUpValues,
  ) => void;
  onSubmitBuyTickets: (signUpValues?: SignUpValues) => void;
  onSubmitPayment: (token: Token) => void;
  onRedirectToLogin: () => void;
}
const useStyles = createUseStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 20px 0px 20px',
  },
  img: {
    borderRadius: '12.5px',
    height: 'auto',
    width: '100%',
    display: 'block',
    marginTop: '-80px',
  },
  title: {
    fontSize: '33px',
    justifyContent: 'center',
    fontWeight: 800,
    marginTop: '15px',
    lineHeight: '35px',
  },
  subtitle: {
    fontSize: '16px',
    justifyContent: 'center',
    fontWeight: 600,
    marginBottom: '16px',
    lineHeight: '18px',
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0.3em 0 0.3em',
    marginTop: '0.5rem',
  },
  button: {
    color: 'white',
    padding: '14px 30px',
    borderRadius: '30px',
    cursor: 'pointer',
    width: 337,
    height: 50,
  },
  noTicketsAvailable: {
    borderRadius: '20px',
    position: 'fixed',
    bottom: '1em',
    padding: '1em 5em',
  },
  fieldTitle: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 600,
    color: 'black',
    textOverflow: 'ellipsis',
    maxLines: 1,
  },
  fieldSubtitle: {
    fontSize: '13px',
    fontWeight: 500,
    color: 'black',
    opacity: 0.75,
    textOverflow: 'ellipsis',
    maxLines: 1,
  },
  description: {
    paddingTop: '15px',
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 500,
    color: 'black',
    whiteSpace: 'pre-line',
  },
  icon: {
    width: '35px',
    height: '35px',
  },
  svgIcon: {
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
  menu: {
    marginLeft: '5px',
  },
});

export const MobileEventPageContent: React.VFC<Props> = ({
  event,
  totalTicketsByType,
  totalNumberOfTickets,
  totalPrice,
  totalFees,
  isPaymentModalOpen,
  isRightAmountOfTickets,
  isBuyTicketsFormOpen,
  isPaymentOpen,
  noTicketsAvailable,
  setTotalTicketsByType,
  onCloseBuyTicketsForm,
  onOpenBuyTicketsForm,
  onClosePayment,
  onSubmitRequest,
  onSubmitBuyTickets,
  onSubmitPayment,
  onRedirectToLogin,
  setSelectedTicket,
  selectedTicket,
}) => {
  const classes = useStyles();
  const { purchasedTicketsFormState } = useAppSelector(
    ({ ticket: { purchasedTicketsFormState } }) => ({
      purchasedTicketsFormState,
    }),
  );
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  const isAuthenticated = useMemo(() => user, [user]);
  const isCustomer = useMemo(() => user?.type == 'customer', [user]);
  const [tacAppMenuOpen, setTacAppMenuOpen] = useState<boolean>(false);

  const menu = useMemo(
    () =>
      !isAuthenticated ? publicMenu : isCustomer ? customerMenu : plannerMenu,
    [isAuthenticated, isCustomer],
  );

  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = useState('');

  const onMenuClick = (key: string) => {
    setUrl(key);
    setMenuOpen(false);
  };

  const onClickSignIn = async () => {
    setUrl('sign-in');
    history.push('/sign-in');
    setMenuOpen(false);
  };

  const onClickLogOut = async () => {
    dispatch(logOut());
  };

  useEffect(() => {
    if (purchasedTicketsFormState) {
      setOpen(true);
    }
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const { dateString, timeBeforeStart, timeString } = getEventDates(event);
  const ticketsPricesMinAndMax: [number, number] | undefined = event.tickets
    ? event.tickets?.reduce(
      ([min, max]: [number, number], ticket) => {
        if (!ticket.onInvitation) {
          if (!ticket.price) {
            return [min, max];
          }
          if (ticket.price < min && ticket.price > max) {
            return [ticket.price, ticket.price];
          } else if (ticket.price < min) {
            return [ticket.price, max];
          } else if (ticket.price > max) {
            return [min, ticket.price];
          }
        }
        return [min, max];
      },
      [Infinity, 0],
    )
    : event.packages?.reduce(
      ([min, max]: [number, number], ticket) => {
        if (!ticket.onInvitation) {
          if (!ticket.aPrice) {
            return [min, max];
          }
          if (ticket.aPrice < min && ticket.aPrice > max) {
            return [ticket.aPrice, ticket.aPrice];
          } else if (ticket.aPrice < min) {
            return [ticket.aPrice, max];
          } else if (ticket.aPrice > max) {
            return [min, ticket.aPrice];
          }
        }
        return [min, max];
      },
      [Infinity, 0],
    );

  const ticketPricesString =
    ticketsPricesMinAndMax && ticketsPricesMinAndMax?.[0] != Infinity
      ? `${ticketsPricesMinAndMax?.[0]} ${event.currencySymbol} - ${ticketsPricesMinAndMax?.[1]} ${event.currencySymbol}`
      : 'No tickets available';

  const buildDescriptionField = (
    title?: string,
    subtitle?: string,
    icon?: () => JSX.Element,
    onClick?: () => void,
  ) => (
    <Stack
      direction="row"
      alignItems="center"
      paddingBottom="15px"
      onClick={onClick}
    >
      {icon && icon()}
      <Stack direction="column">
        <Typography
          className={classes.fieldTitle}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          {title}
        </Typography>
        <Typography
          className={classes.fieldSubtitle}
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  );
  const displayMenuItem = ({ name, key, displayMenu }: MenuType) => (
    <Link
      key={key}
      to={'/' + key}
      onClick={() => onMenuClick(key)}
      style={{ textDecoration: 'none' }}
    >
      <SideMenuItem
        style={{
          color: url === key ? 'gray' : 'black',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {name}
        {displayMenu && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setTacAppMenuOpen(!tacAppMenuOpen);
            }}
          >
            {tacAppMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        )}
      </SideMenuItem>
    </Link>
  );

  const displayLoginOutButton = () => (
    <LogoutBtnDiv>
      <SideMenuItem
        onClick={isAuthenticated ? onClickLogOut : onClickSignIn}
        style={{
          color: 'black',
        }}
      >
        {isAuthenticated ? 'Log Out' : 'Sign In'}
      </SideMenuItem>
    </LogoutBtnDiv>
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div id="eventTicket" className={classes.card}>
        <Box>
          <Stack
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            top="0"
            width="100%"
            height="80px"
            paddingX="10px"
            className={classes.menu}
            sx={{ zIndex: 2 }}
          >
            <Button
              onClick={() => history.push('/events')}
              variant="contained"
              sx={{
                fontSize: '22px',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
                minHeight: 0,
                minWidth: 0,
                color: 'black',
                backgroundColor: 'white',
              }}
            >
              <IoIosArrowBack />
            </Button>
            <Box visibility="hidden" justifySelf="end" padding="15px" />
            <Button
              onClick={() => setMenuOpen(!menuOpen)}
              variant="contained"
              sx={{
                fontSize: '30px',
                color: 'black',
                marginRight: '10px',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                padding: 0,
                minHeight: 0,
                minWidth: 0,
                backgroundColor: 'white',
              }}
            >
              <Menu
                onClick={() => setMenuOpen(!menuOpen)}
                sx={{ color: 'black' }}
              />
            </Button>
          </Stack>
          <Drawer
            anchor="left"
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
          >
            <SideMenubar>
              <SideMenu>
                <IconButton
                  onClick={() => setMenuOpen(!menuOpen)}
                  sx={{
                    color: 'gray',
                    fontSize: '40px',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    stroke: 'gray',
                    strokeWidth: 2,
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {menu.map(displayMenuItem)}
                {displayLoginOutButton()}
              </SideMenu>
            </SideMenubar>
          </Drawer>
          <Box component="img" className={classes.img} src={event?.imageUrl} />
        </Box>
        <div className={classes.infos}>
          <Typography className={classes.title}>{event.name}</Typography>
          <Typography className={classes.subtitle}>
            By{' '}
            {event?.organizerName ? event?.organizerName : 'Techno and Chill'}
          </Typography>
          {buildDescriptionField(dateString, timeString, () => (
            <SvgIcon
              component={Calendar}
              className={classes.svgIcon}
              style={{
                width: 23,
                height: 23,
              }}
              inheritViewBox
            />
          ))}
          {event.dj &&
            buildDescriptionField(event.dj, event.music, () => (
              <SvgIcon
                component={Cd}
                className={classes.svgIcon}
                style={{
                  width: 22,
                  height: 22,
                  marginLeft: 1,
                }}
                inheritViewBox
              />
            ))}
          {buildDescriptionField(
            event.addressName,
            event.address,
            () => (
              <SvgIcon
                component={Location}
                className={classes.svgIcon}
                style={{
                  width: 17,
                  height: 25,
                  marginRight: 10,
                  marginLeft: 3,
                }}
                inheritViewBox
              />
            ),
            () => {
              window.open(
                'https://www.google.com/maps/search/?api=1&query=' +
                event.address,
              );
            },
          )}
          {buildDescriptionField(
            timeBeforeStart + ' left',
            ticketPricesString,
            () => (
              <SvgIcon
                component={Tickets}
                className={classes.svgIcon}
                style={{
                  width: 22,
                  height: 20,
                  marginLeft: 3,
                }}
                inheritViewBox
              />
            ),
          )}
          {event.infoRule &&
            event.infoRule2 &&
            buildDescriptionField(event.infoRule, event.infoRule2, () => (
              <CheckCircleOutlineIcon
                sx={{
                  paddingRight: 1,
                  marginRight: -0.3,
                  marginLeft: -0.1,
                  fontSize: '36px',
                }}
              />
            ))}
        </div>
        <div style={{ marginBottom: 120 }}>
          <Typography className={classes.description}>
            {event?.description}
          </Typography>
        </div>

        <FooterBar normal />
        <Box
          sx={{
            position: 'fixed',
            width: '100%',
            bottom: '1em',
            left: 0,
            padding: { xs: '0 11%', sm: '0 15%' },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            variant="contained"
            onClick={handleOpen}
            className={classes.button}
            fullWidth
            sx={{
              backgroundColor: `#${event.cityColor}`,
              fontSize: '16px',
              textTransform: 'none',
              '&:disabled': {
                backgroundColor: `#${event.cityColor}`,
                color: 'white',
              },
            }}
            disabled={noTicketsAvailable}
          >
            {noTicketsAvailable
              ? 'No tickets available'
              : !event.onReservation
                ? 'Buy Tickets'
                : 'Request Tickets'}
          </Button>
        </Box>
      </div>

      {event.isPackages && (
        <MobilePackage
          open={open}
          handleClose={handleClose}
          cityColor={`#${event.cityColor}`}
          event={event}
          onReservation={event.onReservation ?? false}
          totalTicketsByType={totalTicketsByType}
          totalNumberOfTickets={totalNumberOfTickets}
          totalPrice={totalPrice}
          totalFees={totalFees}
          isPaymentModalOpen={isPaymentModalOpen}
          isRightAmountOfTickets={isRightAmountOfTickets}
          isBuyTicketsFormOpen={isBuyTicketsFormOpen}
          isPaymentOpen={isPaymentOpen}
          onRedirectToLogin={onRedirectToLogin}
          setTotalTicketsByType={setTotalTicketsByType}
          onCloseBuyTicketsForm={onCloseBuyTicketsForm}
          onOpenBuyTicketsForm={onOpenBuyTicketsForm}
          onClosePayment={onClosePayment}
          onSubmitRequest={onSubmitRequest}
          onSubmitBuyTickets={onSubmitBuyTickets}
          onSubmitPayment={onSubmitPayment}
          noTicketsAvailable={noTicketsAvailable}
        />
      )}
      {!event.isPackages && (
        <ModalTicket
          open={open}
          handleClose={handleClose}
          cityColor={`#${event.cityColor}`}
          event={event}
          onReservation={event.onReservation ?? false}
          totalTicketsByType={totalTicketsByType}
          totalNumberOfTickets={totalNumberOfTickets}
          totalPrice={totalPrice}
          totalFees={totalFees}
          isPaymentModalOpen={isPaymentModalOpen}
          isRightAmountOfTickets={isRightAmountOfTickets}
          isBuyTicketsFormOpen={isBuyTicketsFormOpen}
          isPaymentOpen={isPaymentOpen}
          onRedirectToLogin={onRedirectToLogin}
          setTotalTicketsByType={setTotalTicketsByType}
          onCloseBuyTicketsForm={onCloseBuyTicketsForm}
          onOpenBuyTicketsForm={onOpenBuyTicketsForm}
          onClosePayment={onClosePayment}
          onSubmitRequest={onSubmitRequest}
          onSubmitBuyTickets={onSubmitBuyTickets}
          onSubmitPayment={onSubmitPayment}
          noTicketsAvailable={noTicketsAvailable}
          setSelectedTicket={setSelectedTicket}
          selectedTicket={selectedTicket}
        />
      )}
    </div>
  );
};
