import React from 'react';
import { Ticket } from 'src/models/ticket';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Event } from 'src/models/event';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  event: Event;
  tickets: Ticket[];
  onClose: () => void;
}

export const Pkpasses: React.VFC<Props> = ({ event, tickets, onClose }) => {
  const onClickDownload = (ticket: Ticket) => {
    window.open(ticket.pkpass.url());
  };
  const onClickView = (ticket: Ticket) => {
    //make an iframe and set the src to the pkpass url
    const ticketImage =
      'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' +
      ticket.id;
    return ticketImage;

    // const win = window.open();
    //win?.document.write(
    //'<iframe src="' +
    //ticketImage +
    //'" width="100%" height="100%" frameborder="0"></iframe>',
    //);
  };

  return (
    <Box width="100%" height="100%" paddingTop="14px">
      <Stack
        direction="row"
        margin="0px 14px 12px"
        justifyContent="space-between"
      >
        <IconButton onClick={onClose} sx={{ padding: 0 }}>
          <CloseIcon
            fontSize="large"
            sx={{ fontSize: '50px', color: 'black' }}
          />
        </IconButton>
      </Stack>
      <Box margin="24px">
        <Typography variant="h3">{event.name}</Typography>
        <Typography variant="h3" marginBottom="24px">
          Download your tickets here
        </Typography>
        <Stack direction="column" spacing={2}>
          {tickets.map((ticket, index) => (
            <Stack key={index} spacing={1}>
              <img
                src={onClickView(ticket)}
                style={{ alignSelf: 'center' }}
              ></img>

              <Button
                key={index}
                variant="contained"
                sx={{ borderRadius: '50px', backgroundColor: 'black' }}
                endIcon={<ArrowCircleDownIcon />}
                onClick={() => onClickDownload(ticket)}
              >
                Download {ticket.guest} - {ticket.name} Ticket
              </Button>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};
