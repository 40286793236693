import { addDays } from 'date-fns';
export const getPlaceholderTicketSales = (): { [date: string]: number } => {
  const firstDate = new Date();
  firstDate.setDate(1);
  firstDate.setHours(0, 0, 0, 0);
  return {
    [addDays(firstDate, 1).toISOString()]: 15,
    [addDays(firstDate, 2).toISOString()]: 16,
    [addDays(firstDate, 3).toISOString()]: 18,
    [addDays(firstDate, 4).toISOString()]: 20,
    [addDays(firstDate, 5).toISOString()]: 22,
    [addDays(firstDate, 6).toISOString()]: 24,
    [addDays(firstDate, 7).toISOString()]: 12,
    [addDays(firstDate, 8).toISOString()]: 14,
    [addDays(firstDate, 9).toISOString()]: 16,
    [addDays(firstDate, 10).toISOString()]: 4,
    [addDays(firstDate, 11).toISOString()]: 6,
    [addDays(firstDate, 12).toISOString()]: 8,
    [addDays(firstDate, 13).toISOString()]: 10,
    [addDays(firstDate, 14).toISOString()]: 12,
    [addDays(firstDate, 15).toISOString()]: 14,
    [addDays(firstDate, 16).toISOString()]: 16,
    [addDays(firstDate, 17).toISOString()]: 18,
    [addDays(firstDate, 18).toISOString()]: 18,
    [addDays(firstDate, 19).toISOString()]: 18,
    [addDays(firstDate, 20).toISOString()]: 16,
    [addDays(firstDate, 21).toISOString()]: 4,
    [addDays(firstDate, 22).toISOString()]: 6,
    [addDays(firstDate, 23).toISOString()]: 8,
    [addDays(firstDate, 24).toISOString()]: 10,
    [addDays(firstDate, 25).toISOString()]: 12,
    [addDays(firstDate, 26).toISOString()]: 14,
    [addDays(firstDate, 27).toISOString()]: 16,
    [addDays(firstDate, 28).toISOString()]: 18,
  };
};
