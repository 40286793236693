import React from 'react';
import { createUseStyles } from 'react-jss';
import { City } from 'src/models/city';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  city?: City;
  smsCount: number;
  smsPrice: number;
  selected?: boolean;
}

const SMSCard: React.VFC<Props> = ({ city, smsCount, smsPrice, selected }) => {
  const useStyles = createUseStyles({
    cityCard: {},
    cityImage: {
      borderRadius: '50%',
      width: '170.59px',
      height: '170.59px',

      objectFit: 'cover',
      marginBottom: '5.8px',
      alignContent: 'center',
      paddingTop: '30%',
      marginLeft: 2,
      marginRight: 2,
      textAlign: 'center',
      borderColor: '#E7E7E7',
      borderStyle: 'solid',
      fontWeight: 800,
      fontSize: '40px',
      fontFamily: 'SF Pro Display',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.cityCard}>
      <Stack direction="column" alignItems="center" width="100%">
        <div
          style={{
            backgroundColor: selected ? '#F7B500' : '#FFFFFF',
            color: selected ? 'white' : 'black',
          }}
          className={classes.cityImage}
        >
          {smsCount}
          <p
            style={{
              marginTop: 2,
              fontFamily: 'SF Pro Display',
              fontSize: '12px',
              fontWeight: 200,
            }}
          >
            {smsPrice} EGP / SMS
          </p>
        </div>
      </Stack>
    </Box>
  );
};

export default SMSCard;
