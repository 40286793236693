import React from 'react';

import { Stack, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { Box } from '@mui/system';
import FAQ from '../../FAQ';

const useStyles = createUseStyles({
  container: {
    marginTop: '144.81px',
    width: '100%',
    marginBottom: '97px',
    '@media screen and (max-width: 900px)': {
      marginLeft: '5%',
      marginTop: '100px',
    },
  },
  faqText: {
    color: '#666666',
    fontSize: '20px',
    marginLeft: '10px',
    '@media (min-width: 1200px)': {
      marginLeft: '20px',
    },
    fontWeight: 500,
  },
  faqDocs: {
    width: '100%',
    position: 'relative',
  },
  regularBoxContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    '@media screen and (max-width: 475px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr',
    },
  },
  titleIcon: {
    fontSize: '45px',
    width: '70px',
    height: '70px',
    '@media screen and (max-width: 1200px)': {
      width: '50px',
      height: '50px',
      fontSize: '32px',
    },
    '@media screen and (max-width: 900px)': {
      width: '40px',
      height: '40px',
      fontSize: '32px',
    },
  },
  faqBox: {
    marginTop: '20px',
  },
  faqButton: {
    textTransform: 'none',
    width: '100%',
    fontSize: 18,
    padding: '7px 70px',
    borderWidth: 0,
    borderRadius: '25px',
    backgroundColor: '#FFA9B2',
    color: 'white',
  },
  title: {
    color: '#212121',
    textAlign: 'start',
    fontWeight: 800,
    fontSize: 60,
  },
  text: {
    color: 'black',
    fontSize: 17,
    marginTop: 5,
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    width: 675,
    '@media screen and (max-width: 500px)': {
      width: '90%',
    },
  },
});
const FaqPart = () => {
  const classes = useStyles();
  return (
    <div className={classes.container} id="faq">
      <Box>
        <Stack
          direction="column"
          alignItems={'center'}
          justifyContent="center"
          width="100%"
        >
          <Box className={classes.faqDocs}>
            <Typography className={classes.title}>FAQ.</Typography>
            <FAQ question="What is Techno And Chill?">
              Techno and Chill started as a small Instagram page and over the
              span of 6 years, we managed to grow our community to over 1.1M
              people and reach over 2 million people daily with our content
              ranging from funny techno videos to music productions.
            </FAQ>
            <FAQ question="What is the TAC App?">
              TAC is your typical FUBU app. By techno people for techno people,
              we have the ambition to make it the top platform that will bring
              all the people of the industry together. We position ourselves as
              a bridge between the content creators, the producers and event
              planners on one side and the techno lovers on the other side.
            </FAQ>
            <FAQ question="What’s so special about the TAC App?">
              We pride ourselves in being the first ticket platform solely
              dedicated to techno events, so you never miss a great event, both
              in your city or in any other city you happen to visit. And as a
              bonus of being part of our community, purchasing tickets and
              inviting friends, you will benefit from rewards $ that you can use
              to purchase tickets and receive free gifts.
            </FAQ>
            <FAQ question="Who can promote their events on the TAC App?">
              Techno And Chill gives you the opportunity to reach a passionate
              and loyal techno community craving to attend the best events.
              Either you are an event planner, a DJ, a festival organizer, or a
              content creator, you can directly promote your offer to our
              fanbase right on the platform with few easy clicks.
            </FAQ>
            <FAQ question="How much does it cost to add an event on the TAC App?">
              Adding your event to the app is totally for FREE.
            </FAQ>
            <FAQ question="How do I add my events to the TAC App?">
              By pressing on the “Promote Your Event” button, you’ll be able to
              create an Event Planner account, and then create your first event.
              One of our Team will get in touch to ask for more information
              about the event and discuss your payment payout method.
            </FAQ>
            <FAQ question="How much are the Booking Fees?">
              Our booking fees are not fixed as we have different packages
              depending on events sizes. Although we can guarantee that we have
              some of the lowest fees in the market.
            </FAQ>
            <FAQ question="How will I track my sales?">
              We’ve designed a professional dashboard that you can access from
              any computer, where you’ll be able to track your daily sales, and
              your checked in guests, on the day of the event.
            </FAQ>
            <FAQ question="Do you offer any marketing packages?">
              You can promote your event using one of our numerous packages,
              combining a Techno And Chill App shoutout, a press release on our
              TAC Magazine as well as a shoutout on our Newsfeed and our
              Instagram account.
            </FAQ>
          </Box>
        </Stack>
      </Box>
    </div>
  );
};

export default FaqPart;
