import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import { serializePlanner } from 'src/helpers/serializeModels';
import { Planner } from 'src/models/planner';
import Parse from 'parse/node';
/* Planner slice */

interface PlannerState {
  currentlyDisplayedPlanner: Planner | null;
  isLoading: boolean;
  error: string | null;
  eventPlanner: Planner | null;
  paidCount: string | null;
  approvedCount: string | null;
  totalCount: string | null;
  fees: number | null;
}

const initialState: PlannerState = {
  currentlyDisplayedPlanner: null,
  isLoading: false,
  error: null,
  eventPlanner: null,
  paidCount: null,
  approvedCount: null,
  totalCount: null,
  fees: null,
};

// Those are the parameters of the getPlannerById function.
interface CreatePlannerDto {
  email: string;
  name: string;
}
interface EmailsCountDto {
  approved: string;
  paid: string;
  total: string;
}

/* Thunks */

export const createPlanner = createAsyncThunk<
  Planner | undefined,
  CreatePlannerDto
>('planner/createPlanner', async ({ email, name }) => {
  const Planner = Parse.Object.extend('Planner');
  const newPlanner = new Planner();
  newPlanner.set('email', email);
  newPlanner.set('name', name);
  return newPlanner.save().then(serializePlanner);
});
export const getPlannerById = createAsyncThunk<Planner | undefined, string>(
  'planner/getPlannerById',
  async (id) => {
    const Planner = Parse.Object.extend('Planner');
    const plannerQuery = new Parse.Query(Planner);
    plannerQuery.equalTo('objectId', id);
    const planner = await plannerQuery.first();

    return planner ? serializePlanner(planner) : undefined;
  },
);
//make a function to run the parse cloud function to get emails count
export const getRecipientsEmails = createAsyncThunk<
  EmailsCountDto | undefined,
  string
>('planner/getRecipientsEmails', async (settings) => {
  const user = Parse.User.current();
  const plannerId = user?.get('planner');

  //run cloud functoin to get emails count
  const result = await Parse.Cloud.run('getRecipientsEmails', {
    plannerId: plannerId.id,
    settings: settings,
  });
  return result;
});

export const getPlannerFees = createAsyncThunk<number | undefined, string>(
  'planner/getPlannerFees',
  async (settings) => {
    const user = Parse.User.current();
    const plannerId = user?.get('planner');
    const Planner = Parse.Object.extend('Planner');
    const plannerQuery = new Parse.Query(Planner);
    plannerQuery.equalTo('objectId', plannerId.id);
    const planner = await plannerQuery.first();
    const Fees = planner?.get('fee');
    return Fees;
  },
);

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
  },
};

/* Slice */
const plannerSlice = createSlice({
  name: 'planner',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPlanner.pending, sharedReducers.pending)
      .addCase(createPlanner.rejected, sharedReducers.rejected)
      .addCase(createPlanner.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.currentlyDisplayedPlanner = payload;
        }
      })
      .addCase(getPlannerById.pending, sharedReducers.pending)
      .addCase(getPlannerById.rejected, sharedReducers.rejected)
      .addCase(getPlannerById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.eventPlanner = payload;
        }
      })
      .addCase(getRecipientsEmails.pending, sharedReducers.pending)
      .addCase(getRecipientsEmails.rejected, sharedReducers.rejected)
      .addCase(getRecipientsEmails.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        if (payload) {
          state.approvedCount = payload.approved;
          state.paidCount = payload.paid;
          state.totalCount = payload.total;
        }
      })
      .addCase(getPlannerFees.pending, sharedReducers.pending)
      .addCase(getPlannerFees.rejected, sharedReducers.rejected)
      .addCase(getPlannerFees.fulfilled, (state, { payload }) => {
        state.isLoading = false;

        if (payload) {
          state.fees = payload;
        }
      });
  },
});

export default plannerSlice.reducer;
