import React, { useEffect } from 'react';
import { Button, TextField, Stack, Snackbar, Alert, Box } from '@mui/material';
import { Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { logIn, resetError } from 'src/slices/authentication';
import { useHistory } from 'react-router-dom';
import { useQueryParam, StringParam } from 'use-query-params';

const useStyles = createUseStyles({
  container: {
    height: "100%",
    "@media screen and (min-width: 900px)": {},
  },
  buttonForgotpsswd: {
    color: "gray",
    fontSize: "15px",
    textTransform: "none",
    opacity: ".5",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttonSignup: {
    color: "black",
    borderRadius: "300px",
    border: "2px solid #FF0202",
    borderColor: "#FF0202",
    width: "313.2px",
    height: "45px",
    textTransform: "none",
    fontSize: "17.2px",
    "&:hover": {
      backgroundColor: "#FF0202",
      border: "solid 1px #F70303",
      opacity: 0.7,
      color: "white",
    },
  },
  buttoncontinue: {
    color: "white",
    borderRadius: "300px",
    width: "313.2px",
    height: "45px",
    fontSize: "17.2px",
    backgroundColor: "#FF0202",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#F70303",
      opacity: 0.7,
      border: "solid 1px #F70303",
    },
  },

  textfield1: {
    color: "black",
    "& .MuiOutlinedInput-input": {
      padding: "10.7px 20.7px",
    },
    [`& fieldset`]: {
      border: "none",
    },
  },

  menubutton: {
    color: "black",
    height: "10%",
    width: "10%",
  },

  bigimage: {
    width: "40%",
    padding: "10px",
    margin: "10px",
  },
  typographyposition: {
    position: "absolute",
    left: "300px",
  },
  root: {
    width: "100%",
    height: "100%",
  },
  signInHeader: {
    fontWeight: 800,
    color: "black",
    fontSize: 45,
    lineHeight: "64px",
    "@media screen and (max-width: 900px)": {
      marginTop: "70px",
    },
  },
  signInDesc: {
    color: "black",
    marginTop: "-8px !important",
    marginLeft: "3px !important",
    fontWeight: 500,
    fontSize: 17.2,
  },
  inputColor: {
    border: "solid 2px #F0F0F0",
    borderRadius: 30,
    backgroundColor: "white",
    height: "45px",
  },
});

const SignIn: React.VFC = () => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const [requestId] = useQueryParam('requestId', StringParam);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { purchasedTicketsFormState, loading, signError, user } =
    useAppSelector(
      ({
        ticket: { purchasedTicketsFormState },
        authentication: { loading, signError, user },
      }) => ({
        purchasedTicketsFormState,
        loading,
        signError,
        user,
      }),
    );

  const onSignedIn = () => {
    if (requestId) {
      history.push(`/request/${requestId}`);
    } else if (purchasedTicketsFormState) {
      history.push(`/events/${purchasedTicketsFormState.eventId}`);
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    if (signError) {
      setDisplayError(true);
      dispatch(resetError());
    } else if (!loading && user) {
      onSignedIn();
    }
  }, [signError, loading, user]);

  const onClickSignIn = () => {
    dispatch(logIn({ username: email.toLowerCase(), password: password }));
  };
  const onClickSignUp = () => {
    if (requestId) {
      history.push(`/signUp?requestId=${requestId}`);
    } else {
      history.push('/signUp');
    }
  };

  const onClickForgotPassword = () => {
    history.push('/forgot-password');
  };

  return (
    <Box maxWidth="500px" marginX="auto" className={classes.container}>
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Stack direction="column" spacing={1.5} justifyContent="center">
          <Typography className={classes.signInHeader}>Sign in</Typography>
          <Typography className={classes.signInDesc}>Customer</Typography>
          <TextField
            className={classes.textfield1}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            size="small"
            InputProps={{ className: classes.inputColor }}
          />
          <TextField
            className={classes.textfield1}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            size="small"
            InputProps={{ className: classes.inputColor }}
          />
          <Stack>
            <Button
              variant="outlined"
              onClick={onClickSignIn}
              className={classes.buttoncontinue}
              size="medium"
            >
              Continue
            </Button>
            <Stack alignItems="flex-end">
              <Button
                variant="text"
                className={classes.buttonForgotpsswd}
                onClick={onClickForgotPassword}
              >
                Forgot Password ?
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ fontWeight: 700, color: 'black', fontSize: 17.2 }}>
            Or
          </Typography>
          <Button
            onClick={onClickSignUp}
            variant="outlined"
            className={classes.buttonSignup}
            size="medium"
          >
            Sign up
          </Button>
        </Stack>
        <Snackbar
          open={displayError}
          autoHideDuration={6000}
          onClose={() => setDisplayError(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setDisplayError(false)}
            severity="error"
            sx={{ width: '100%' }}
          >
            Wrong password, please try again
          </Alert>
        </Snackbar>
      </Stack>
    </Box>
  );
};

export default SignIn;
