import React from 'react';
import AnimationPart from 'src/components/old/landing/AnimationPart';
import FeaturePart from 'src/components/old/landing/FeaturePart';
import FollowerPart from 'src/components/old/landing/FollowerPart';
import LoyalityPart from 'src/components/old/landing/LoyalityPart';
import NewsFeed from 'src/components/old/landing/NewsFeed';
import TicketsPart from 'src/components/old/landing/TicketsPart';
import Title from 'src/components/old/landing/Title';

import styled from 'styled-components';
import TacNftPart from '../components/old/landing/TacNftPart';
import FaqPart from '../components/old/landing/FaqPart';
import { createUseStyles } from 'react-jss';
import { Box } from '@mui/system';
export type DescProps = {
  align?: string;
};

const IndexPage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 768px) {
    padding: 80px 0 80px 0;
    transition: 0.2s linear;
  }
  @media screen and (max-width: 425px) {
    padding: 20px 0 30px 0;
    transition: 0.2s linear;
  }
`;

const IndexDiv = styled.div`
  width: 100%;
  height: 100%;
`;

const Hero = styled.div`
  display: block;
  width: 100%;
  margin: auto;
  @media screen and (min-width: 767px) {
    padding: 0 !important;
  }
`;
export const PartDesc = styled.div<DescProps>`
  svg {
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  font-size: 21px;
  font-weight: 500;
  color: #777777;
  margin-top: 10px;
  text-align: ${({ align }) => align};
  @media screen and (max-width: 900px) {
    font-size: 18px;
    transition: 0.2s linear;
  }
  @media screen and (max-width: 500px) {
    font-size: 15px;
    transition: 0.2s linear;
  }
  @media screen and (max-width: 425px) {
    text-align: left;
    transition: 0.2s linear;
    font-size: 15px;
  }
`;

const useStyles = createUseStyles({
  contained: {
    margin: '0 auto',
    width: 867,
    marginTop: '91px',
    '@media screen and (max-width: 1700px)': {
      margin: '91px 324px 0px 324px',
    },
    '@media screen and (max-width: 1500px)': {
      margin: '0 auto',
      marginTop: '91px !important',
      width: 867,
    },
    '@media screen and (max-width: 1000px)': {
      margin: '0 auto',
      marginTop: '91px !important',
      width: 867,
    },
    '@media screen and (max-width: 900px)': {
      margin: '0 20px 0 0',
      width: '100%',
      marginTop: '-50px !important',
      overflow: 'hidden',
    },
  },
});

function Landing() {
  const classes = useStyles();
  return (
    <IndexPage>
      <IndexDiv>
        <Box className={classes.contained}>
          <Hero>
            <Title />
            <AnimationPart />
          </Hero>
        </Box>
        <FeaturePart />
        <Box className={classes.contained}>
          <FollowerPart />
          <NewsFeed />
        </Box>
        <TicketsPart />
        <Box className={classes.contained}>
          <TacNftPart />
        </Box>
        <LoyalityPart />
        <Box className={classes.contained}>
          <FaqPart />
        </Box>
      </IndexDiv>
    </IndexPage>
  );
}

export default Landing;
