import React from 'react';
import { createUseStyles } from 'react-jss';
import { Grid, MenuItem, Select, Stack, Typography } from '@mui/material';
import { FormTextField } from 'src/components/FormTextField';
import { FormikErrors, FormikTouched } from 'formik';
import { GuestFormValues } from '../MobileTicketGuestSection';
import { GuestForm } from '../ModalTicket';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { UserGender } from '../../../models/user';
import { commonStyles } from '../../../constants/common-styles';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

interface TicketGuestFormProps {
  guestNumber: number;
  name?: string;
  email?: string;
  gender?: UserGender;
  phone?: string;
  link?: string;
  nationality?: string;
  isPackages?: boolean;
  setValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<any>> | Promise<void>;
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1,
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  formikErrors: FormikErrors<GuestFormValues>;
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  formikTouched: FormikTouched<GuestFormValues>;
  cityColor: string;
}

const useStyles = createUseStyles({
  guestForm: {
    width: '313.2px',
  },
  textfield: {
    color: 'black',
    '& .MuiOutlinedInput-input': {
      padding: '10.7px 20.7px',
    },
    [`& fieldset`]: {
      border: 'none',
    },
  },
  inputColor: {
    border: 'solid 2px #F0F0F0',
    borderRadius: 30,
    backgroundColor: '#white',
    [`& fieldset`]: { border: 'none' },
  },
  guestIndex: {
    fontWeight: 'bold',
    textAlign: 'center',
    verticalAlign: 'middle',
    color: 'white',
    lineHeight: 1.5,
  },
  guestIndexContainer: {
    borderRadius: '50%',
    width: 25.2,
    height: 25.2,
    marginBottom: '8px',
  },
  fixInput: {
    maxWidth: '100%',
    height: '45px',
    border: 'solid 2px #F0F0F0',
    '& .MuiOutlinedInput-input': {
      padding: '10.7px 19.7px',
    },
    [`& fieldset`]: { border: 'none' },
  },
  phoneInput: {
    borderRadius: '50px !important',
    width: '100% !important',
    height: '45px',
    border: 'solid 2px #F0F0F0 !important',
    '&:focus': {
      boxShadow: 'none !important',
    },
  },
  phoneInputButton: {
    marginLeft: '10px',
  },
  urlText: {
    fontWeight: '500',
    fontSize: '10.8px',
    opacity: 0.26,
    color: 'black',
    textAlign: 'center',
  },
});

export const TicketGuestForm: React.VFC<TicketGuestFormProps> = ({
  guestNumber,
  cityColor,
  name,
  email,
  phone,
  gender,
  link,
  nationality,
  setValue,
  onChange,
  onBlur,
  formikErrors,
  formikTouched,
  isPackages,
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  countries.registerLocale(enLocale);
  const countryObject = countries.getNames('en', { select: 'official' });
  const countryArray = Object.keys(countryObject).map((key) => {
    return { value: key, label: countryObject[key] };
  });

  return (
    <div className={classes.guestForm}>
      <div
        className={classes.guestIndexContainer}
        style={{
          backgroundColor: cityColor,
        }}
      >
        <Typography className={classes.guestIndex}>
          {guestNumber + 2}
        </Typography>
      </div>
      <Grid container direction="row" spacing="8.1px">
        <Grid item xs={12} md={12}>
          <FormTextField
            name={`guests.${guestNumber}.name`}
            placeholder="Name"
            required
            className={classes.textfield}
            value={name ?? ''}
            onChange={onChange}
            fullWidth
            InputProps={{
              className: classes.inputColor,
            }}
            error={
              typeof formikErrors.guests === 'string'
                ? (formikErrors.guests as string)
                : Array.isArray(formikErrors.guests) &&
                  formikErrors.guests.length > 0
                ? typeof formikErrors.guests[0] === 'string'
                  ? (formikErrors.guests?.[guestNumber] as string | undefined)
                  : (
                      formikErrors.guests as
                        | FormikErrors<GuestForm>[]
                        | undefined
                    )?.[guestNumber]?.name
                : undefined
            }
            touched={formikTouched?.guests?.[guestNumber]?.name}
            onBlur={onBlur}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <FormTextField
            name={`guests.${guestNumber}.email`}
            placeholder="Email"
            required
            className={classes.textfield}
            value={email ?? ''}
            onChange={onChange}
            fullWidth
            InputProps={{
              className: classes.inputColor,
            }}
            error={
              typeof formikErrors.guests === 'string'
                ? (formikErrors.guests as string)
                : Array.isArray(formikErrors.guests) &&
                  formikErrors.guests.length > 0
                ? typeof formikErrors.guests[0] === 'string'
                  ? (formikErrors.guests?.[guestNumber] as string | undefined)
                  : (
                      formikErrors.guests as
                        | FormikErrors<GuestForm>[]
                        | undefined
                    )?.[guestNumber]?.email
                : undefined
            }
            touched={formikTouched?.guests?.[guestNumber]?.email}
            onBlur={onBlur}
          />
        </Grid>
        {isPackages && (
          <Grid item xs={12} md={12}>
            <Select
              name={`guests.${guestNumber}.nationality`}
              value={nationality ?? ''}
              className={`${commonClasses.whiteInputProps} ${classes.fixInput}`}
              displayEmpty
              required
              onChange={onChange}
              sx={{ width: '100%' }}
              renderValue={
                nationality !== undefined
                  ? undefined
                  : () => <div style={{ opacity: 0.4 }}>Nationality</div>
              }
            >
              {!!countryArray &&
                countryArray.map(({ label, value }) => {
                  return (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
        )}
        <Grid container item xs={12} md={12} gap={1.5}>
          <Grid item xs={7.5} md={7.5}>
            <PhoneInput
              country={'eg'}
              value={phone ?? ''}
              inputClass={classes.phoneInput}
              buttonClass={classes.phoneInputButton}
              placeholder=""
              onChange={(phone) =>
                setValue(`guests.${guestNumber}.phone`, phone)
              }
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <Stack justifyContent="flex-start" alignItems="center">
              <Select
                value={gender}
                className={`${commonClasses.whiteInputProps} ${classes.fixInput}`}
                displayEmpty
                onChange={(event) => {
                  setValue(
                    `guests.${guestNumber}.gender`,
                    event.target.value as UserGender,
                  );
                }}
                sx={{ width: '100%' }}
                renderValue={
                  gender !== undefined
                    ? undefined
                    : () => <div style={{ opacity: 0.4 }}>Gender</div>
                }
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <FormTextField
            name={`guests.${guestNumber}.link`}
            placeholder="Social account"
            className={classes.textfield}
            value={link ?? ''}
            onChange={onChange}
            fullWidth
            required
            InputProps={{
              className: classes.inputColor,
            }}
            error={
              typeof formikErrors.guests === 'string'
                ? (formikErrors.guests as string)
                : Array.isArray(formikErrors.guests) &&
                  formikErrors.guests.length > 0
                ? typeof formikErrors.guests[0] === 'string'
                  ? (formikErrors.guests?.[guestNumber] as string | undefined)
                  : (
                      formikErrors.guests as
                        | FormikErrors<GuestForm>[]
                        | undefined
                    )?.[guestNumber]?.link
                : undefined
            }
            touched={formikTouched?.guests?.[guestNumber]?.link}
            onBlur={onBlur}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography className={classes.urlText}>
            {
              "You must provide the person's Instagram or Facebook profile url (please include https:// at the beginning of your social link)"
            }
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
