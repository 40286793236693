import { Grid, Box, Button, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EventPlannerCard from 'src/components/event/EventPlannerCard';
import { Card } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Event } from 'src/models/event';
import { fetchPlannerEvents } from 'src/slices/event';
import { useHistory } from 'react-router-dom';
import { getTicketsSales, getTotalSales } from 'src/slices/ticket';
import FullscreenSpin from 'src/components/FullscreenSpin';
import { createUseStyles } from 'react-jss';
import { placeHolderEvent } from 'src/data/placeholderEvent';
import { getPlaceholderTicketSales } from 'src/data/placeholderPurchasesTickets';
import FooterBar from 'src/components/FooterBar';
import { Helmet } from 'react-helmet-async';

const useStyles = createUseStyles({
  title: {
    fontSize: '60px',
    fontWeight: '800',
  },
  eventPlannerDashboardContainer: {
    minWidth: '100%',
    minHeight: '100vh',
  },
  placeholderEventsContainer: {
    pointerEvents: 'none',
    opacity: '0.29',
  },
  createEventButton: {
    color: 'black',
    padding: '8px 30px',
    borderRadius: '10rem',
    border: '1px solid black',
    fontWeight: 500,
    fontSize: '22px',
    textTransform: 'none',
    '&:hover': {
      border: '3px solid black',
      color: 'white',
      backgroundColor: '#000',
    },
    '& svg': {
      width: '32px',
      height: '32px',
    },
  },
});

function EventPlannerDashboard() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { events, isEventsLoading } = useAppSelector(
    ({ event: { plannerEvents, isLoading } }) => ({
      events: plannerEvents,
      isEventsLoading: isLoading,
    }),
  );
  const { ticketsSales } = useAppSelector(({ ticket: { ticketsSales } }) => ({
    ticketsSales,
  }));
  const { totalSales } = useAppSelector(({ ticket: { totalSales } }) => ({
    totalSales,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchPlannerEvents());
    dispatch(getTicketsSales());
    dispatch(getTotalSales());
  }, []);
  const sortedEvents = useMemo(
    () =>
      [...(events ?? [])].sort((firstEvent, secondEvent) => {
        const firstEventDate = new Date(firstEvent.startDate);
        const secondEventDate = new Date(secondEvent.startDate);
        const firstEventEndDate = new Date(firstEvent.endDate);
        const secondEventEndDate = new Date(secondEvent.endDate);
        if (firstEventEndDate < new Date() && secondEventEndDate > new Date()) {
          return 1;
        } else if (
          firstEventEndDate > new Date() &&
          secondEventEndDate < new Date()
        ) {
          return -1;
        } else if (
          firstEvent.state === 'active' &&
          secondEvent.state !== 'active'
        ) {
          return -1;
        } else if (
          firstEvent.state !== 'active' &&
          secondEvent.state === 'active'
        ) {
          return 1;
        } else {
          return firstEvent.createdAt && secondEvent.createdAt
            ? new Date(secondEvent.createdAt).getTime() -
                new Date(firstEvent.createdAt).getTime()
            : secondEventDate.getTime() - firstEventDate.getTime();
        }
      }),
    [events],
  );

  if (isEventsLoading) {
    return <FullscreenSpin />;
  }
  return (
    <>
      <div className={classes.eventPlannerDashboardContainer}>
        <Helmet>
          <script>
            {`
            window.Intercom("boot", {
  api_base: "https://api-iam.intercom.io",
  app_id: "okyuu12q"
});
          `}
          </script>
        </Helmet>
        <Box sx={{ flexGrow: 2, m: 6 }}>
          <Grid
            container
            spacing={{ xs: events?.length === 0 ? 5 : 2 }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            paddingX="15%"
            marginBottom="45px"
          >
            <Grid item xs={events?.length === 0 ? 12 : 7}>
              <Typography variant="h2">My Events</Typography>
            </Grid>
            <Grid
              item
              xs={events?.length === 0 ? 12 : 5}
              display="flex"
              justifyContent="center"
            >
              <Button
                onClick={() => {
                  {
                    history.push('/create-event');
                  }
                }}
                variant="outlined"
                className={classes.createEventButton}
                size="medium"
                startIcon={<AddIcon />}
              >
                <Typography variant="h5" fontWeight={500}>
                  {events?.length === 0
                    ? 'Create Your First Event'
                    : 'Create An Event'}
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
          >
            <Grid item xs={12}>
              {events?.length === 0 ? (
                <Card
                  className={classes.placeholderEventsContainer}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                  style={{ boxShadow: 'none' }}
                >
                  <div>
                    <EventPlannerCard
                      event={placeHolderEvent}
                      ticketSales={getPlaceholderTicketSales()}
                    />
                  </div>
                </Card>
              ) : (
                sortedEvents.map((event: Event) => (
                  <Card
                    key={event.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingBottom: '60px',
                    }}
                    style={{ boxShadow: 'none' }}
                  >
                    <div>
                      {event && (
                        <EventPlannerCard
                          event={event}
                          ticketSales={ticketsSales?.[event.id] ?? undefined}
                          totalSales={totalSales?.[event.id]?.toString() ?? '0'}
                        />
                      )}
                    </div>
                  </Card>
                ))
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

export default EventPlannerDashboard;
