import React from 'react';

import { createUseStyles } from 'react-jss';
import { Icon, Icons } from './Icon/Icon';

const useStyles = createUseStyles({
  container: {
    border: 'none',
    outline: 'none',
    borderRadius: '40px',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    transition: '0.2s ease-in-out all',
    '@media only screen and (max-width: 670px)': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'lighten(#6BA8E0, 35%)',
      '@media only screen and (max-width: 670px)': {
        backgroundColor: 'transparent',
      },
      path: { fill: '#6BA8E0', animation: 'danceLight 1200ms infinite' },
    },
    '&:active': { border: '2px solid #6BA8E0' },
  },

  icon: {
    display: 'block',
    minWidth: '26px',
    minHeight: '26px',
    '@media only screen and (max-width: 670px)': {
      width: '32px',
      height: '32px',
    },
    '&path': {
      fill: 'white',
    },
  },
  iconReverse: {
    display: 'block',
    minWidth: '26px',
    minHeight: '26px',
    '@media only screen and (max-width: 670px)': {
      width: '32px',
      height: '32px',
    },
    '&path': {
      fill: '#212121',
    },
  },
  disabled: {
    opacity: 0.4,
    '&:hover': { '&path': { animation: 'none !important' } },
    '&:active': { animation: 'shake 200ms linear' },
  },

  '@keyframes danceLight': {
    '0%': { fill: '#6BA8E0' },
    '40%': { fill: '#FFCD58' },
    '80%': { fill: '#F1446C' },
    '100%': { fill: '#6BA8E0' },
  },
  '@keyframes shake': {
    '25%': { transform: 'translateX(-15px)' },
    '50%': { transform: 'translateX(15px)' },
    '75%': { transform: 'translateX(15px)' },
    '100%': { transform: 'translateX(0)' },
  },
});

interface Props {
  icon: Icons;
  fill?: string;
  size?: number;
  href?: string;
  reverse?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export const IconButton: React.FC<Props> = ({
  className,
  icon,
  size = 12,
  fill = '#212121',
  reverse = false,
  disabled = false,
  href,
  onClick,
}) => {
  const classes = useStyles();
  return href ? (
    <a
      href={!disabled ? href : undefined}
      className={`
        ${classes.container}
        ${className}
        ${disabled ? classes.disabled : undefined}
  `}
      rel="noreferrer"
      target="_blank"
    >
      <Icon
        className={reverse ? classes.iconReverse : classes.icon}
        name={icon}
        size={size}
        fill={fill}
      />
    </a>
  ) : (
    <button
      disabled
      onClick={onClick}
      className={`
        ${classes.container}
        ${className}
        ${disabled ? classes.disabled : undefined}
  `}
    >
      <Icon
        className={reverse ? classes.iconReverse : classes.icon}
        name={icon}
        size={size}
        fill={fill}
      />
    </button>
  );
};

export default IconButton;
