import React from 'react';
import PresendIcons from '../../../assets/presents.svg';
import styled from 'styled-components';
import { createUseStyles } from 'react-jss';
import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import { LoyaltySystemVideos } from './LoyaltySystemVideos';

export const LoyalityTitleDiv = styled.div``;
export const LoyalityDiv = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const LoyalityIcons = styled.img`
  width: 103.5px;
  height: 128.84px;
  transition: 0.2s linear;
  @media screen and (max-width: 767px) {
    width: 115px;
    transition: 0.2s linear;
    margin-bottom: 20px;
  }
`;
export const LoyalityDocs = styled.div`
  width: 100%;
  transition: 0.2s linear;
  margin: 0 auto 16.5px;
  @media screen and (max-width: 768px) {
    margin: 0 auto 30px;
    width: 100%;
    transition: 0.2s linear;
  }
  @media screen and (max-width: 425px) {
    transition: 0.2s linear;
  }
`;
const useStyles = createUseStyles({
  container: {
    padding: 30,
  },
  title: {
    color: '#2CB9A2',
    textAlign: 'start',
    fontWeight: 800,
    fontSize: 60,
    '@media screen and (max-width: 900px)': {
      lineHeight: '55px',
      marginBottom: '30px',
    },
  },
  contained: {
    margin: '0 auto',
    width: 867,
    marginTop: '30px',
    '@media screen and (max-width: 1700px)': {
      margin: '91px 324px 0px 324px',
    },
    '@media screen and (max-width: 1500px)': {
      margin: '0 auto',
      marginTop: '30px !important',
      width: 867,
    },
    '@media screen and (max-width: 1000px)': {
      margin: '0 auto',
      marginTop: '30px !important',
      width: 867,
    },
    '@media screen and (max-width: 900px)': {
      margin: '0 20px 0 20px',
      width: '90%',
      marginTop: '50px !important',
    },
  },
  text: {
    color: 'black',
    fontSize: 17,
    fontWeight: 600,
  },
  textContainer: {
    color: 'black',
    marginTop: 5,
    marginBottom: 24,
    width: 675,
    '@media screen and (max-width: 900px)': {
      width: '90%',
    },
  },
  boldText: {
    color: 'black',
    fontSize: 18,
    fontWeight: 800,
  },
});
const LoyalityPart = () => {
  const classes = useStyles();
  return (
    <LoyalityDiv id="loyalty">
      <Box>
        <Box className={classes.contained}>
          <Stack
            direction="column"
            alignItems={'start'}
            justifyContent="center"
            width="100%"
          >
            <LoyalityDocs>
              <LoyalityTitleDiv>
                <LoyalityIcons src={PresendIcons} alt="icons" />
                <Typography className={classes.title}>
                  Loyalty System.
                </Typography>
              </LoyalityTitleDiv>
              <div className={classes.textContainer}>
                <Typography className={classes.text} display="inline">
                  {'As a '}
                </Typography>
                <Typography className={classes.boldText} display="inline">
                  bonus
                </Typography>
                <Typography className={classes.text} display="inline">
                  {
                    ' of being part of our community, purchasing tickets and inviting friends, you will benefit from '
                  }
                </Typography>
                <Typography className={classes.boldText} display="inline">
                  reward points
                </Typography>
                <Typography className={classes.text} display="inline">
                  {' that you can use to purchase tickets and '}
                </Typography>
                <Typography className={classes.boldText} display="inline">
                  receive free gifts.
                </Typography>
              </div>
            </LoyalityDocs>
            <LoyaltySystemVideos />
          </Stack>
        </Box>
      </Box>
    </LoyalityDiv>
  );
};

export default LoyalityPart;
