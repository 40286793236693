import React, { useMemo, useState, VFC } from 'react';
import LogoIMG from '../../../assets/logo.svg';
import { Link, useHistory } from 'react-router-dom';
import { SideMenubar, SideMenu, SideMenuItem, LogoutBtnDiv } from './Header';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { logOut } from 'src/slices/authentication';
import Menu from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton } from '@mui/material';
import { Menu as MenuType } from 'src/data/menus';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';

interface MobileHeaderProps {
  menu: MenuType[];
}

const MobileHeader: VFC<MobileHeaderProps> = ({ menu }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  const history = useHistory();
  const [url, setUrl] = useState('');
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [tacAppMenuOpen, setTacAppMenuOpen] = useState<boolean>(false);

  const isAuthenticated = useMemo(() => user, [user]);

  const onMenuClick = (key: string) => {
    setUrl(key);
    setMenuOpen(false);
  };

  const onClickSignIn = async () => {
    setUrl('sign-in');
    history.push('/sign-in');
    setMenuOpen(false);
  };

  const onClickLogOut = async () => {
    dispatch(logOut());
  };

  const displayMenuItem = ({ name, key, displayMenu }: MenuType) => (
    <Link
      key={key}
      to={'/' + key}
      onClick={() => onMenuClick(key)}
      style={{ textDecoration: 'none' }}
    >
      <SideMenuItem
        style={{
          color: url === key ? 'gray' : 'black',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {name}
        {displayMenu && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setTacAppMenuOpen(!tacAppMenuOpen);
            }}
          >
            {tacAppMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          </IconButton>
        )}
      </SideMenuItem>
    </Link>
  );

  const displayLoginOutButton = () => (
    <LogoutBtnDiv>
      <SideMenuItem
        onClick={isAuthenticated ? onClickLogOut : onClickSignIn}
        style={{
          color: 'black',
        }}
      >
        {isAuthenticated ? 'Log Out' : 'Sign In'}
      </SideMenuItem>
    </LogoutBtnDiv>
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        position="fixed"
        top="0"
        boxShadow="0 0 8px rgba(0,0,0,.15)"
        width="100%"
        height="50px"
        paddingX="10px"
        sx={{ backgroundColor: 'white', zIndex: 99 }}
      >
        <Menu
          onClick={() => setMenuOpen(!menuOpen)}
          sx={{ fontSize: '30px' }}
        />
        <Box
          component="img"
          src={LogoIMG}
          height="35px"
          onClick={() => history.push('/')}
          sx={{ cursor: 'pointer' }}
        />
        <Box visibility="hidden" justifySelf="end" padding="15px" />
      </Box>
      <Drawer anchor="left" open={menuOpen} onClose={() => setMenuOpen(false)}>
        <SideMenubar>
          <SideMenu>
            <IconButton
              onClick={() => setMenuOpen(!menuOpen)}
              sx={{
                color: 'gray',
                fontSize: '40px',
                position: 'absolute',
                top: 0,
                right: 0,
                stroke: 'gray',
                strokeWidth: 2,
              }}
            >
              <CloseIcon />
            </IconButton>
            {menu.map(displayMenuItem)}
            {displayLoginOutButton()}
          </SideMenu>
        </SideMenubar>
      </Drawer>
    </>
  );
};

export default MobileHeader;
