import React, { useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import CloseIcon from '@mui/icons-material/Close';
import { GuestForm } from './ModalTicket';
import { TicketGuestForm } from './ReserveTicket/TicketGuestForm';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { FormTextField } from '../FormTextField';
import { SignUpValues } from 'src/containers/EventPageContent';
import DatePicker from '@mui/lab/DatePicker';
import { DateTime } from 'luxon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { CountryDropdown } from 'react-country-region-selector';
import { MdAccountCircle } from 'react-icons/md';
import { useState } from 'react';

import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { UserGender } from '../../models/user';
import { BsCalendarFill } from 'react-icons/all';
import { commonStyles } from '../../constants/common-styles';
import { useAppSelector } from 'src/hooks/store';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

const useStyles = createUseStyles({
  root: {
    [`& fieldset`]: {
      borderRadius: 50,
    },
  },
  modalBody: {
    backgroundColor: 'white',
    height: '100%',
    justifyContent: 'start',
    alignItems: 'center',
    overflowY: 'auto',
    paddingBottom: '15px',
  },
  title: {
    fontWeight: 800,
    color: 'black',
    fontSize: 45,
    '@media screen and (max-width: 900px)': {
      marginTop: '70px',
    },
  },
  subTitle: {
    color: 'black',
    marginTop: '-8px !important',
    marginLeft: '3px !important',
    fontWeight: 500,
    fontSize: 17.2,
  },
  textField: {
    color: 'black',
    '& .MuiOutlinedInput-input': {
      padding: '10.7px 20.7px',
    },
    [`& fieldset`]: {
      border: 'none',
    },
  },
  inputColor: {
    border: 'solid 2px #F0F0F0',
    borderRadius: 30,
    backgroundColor: '#white',
    [`& fieldset`]: { border: 'none' },
  },
  fixInput: {
    maxWidth: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '10px 20.7px',
      marginBottom: '0px',
    },
  },
  fixInput2: {
    maxWidth: '100%',

    fontStyle: 'bold',
    fontSize: 14.2,
    color: 'black',

    paddingRight: '20px',
    paddingLeft: '20px',
  },
  whiteInputProps: {
    border: 'solid 2px #F0F0F0',
    ['& menu']: {
      backgroundColor: 'white',
      maxWidth: '70%',
    },

    borderRadius: 30,
    backgroundColor: 'white',
    height: '45px',
    [`& fieldset`]: {
      marginRight: '20px',
      width: '90%',
      border: 'solid 3px #F0F0F0',
    },
    paddingRight: '20px',
    marginRight: '20px',
    ['& .MuiOutlinedInput-input']: {
      padding: '10px 20.7px',
      marginBottom: '0px',
    },
  },

  urlText: {
    fontWeight: '500',
    fontSize: '10.8px',
    opacity: 0.26,
    color: 'black',
    textAlign: 'center',
  },
});
interface Props {
  open: boolean;
  handleClose: any;
  onClickBack: () => void;
  cityColor: string;
  numberOfTickets: number;
  onSubmit: (values: GuestFormValues, signUpValues?: SignUpValues) => void;
  onSignUp: (values: SignUpValues) => void;
  onRedirectToLogin: () => void;
  onReservation: boolean;
  isLoggedIn: boolean;
  eventName: string;
  isPackages: boolean;
}

interface SignUpFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  dateOfBirth?: Date;
  password: string;
  confirmPassword: string;
}

export interface GuestFormValues {
  guests: GuestForm[];
  userLink: string;
  firstName: string;
  lastName: string;
  genderRatio?: string;
  numberOfMen: number;
  numberOfWomen: number;
  gender?: UserGender;
  email?: string;
  country?: string;
}

export const MobileTicketGuestSection: React.VFC<Props> = ({
  open,
  handleClose,
  eventName,
  cityColor,
  numberOfTickets,
  onSubmit,
  onSignUp,
  onRedirectToLogin,
  onClickBack,
  onReservation,
  isLoggedIn,
  isPackages,
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();

  const isDesktop = useMediaQuery('(min-width: 900px)');
  const gotoSignup = () => {
    onRedirectToLogin();
    handleClose();
  };
  const onSubmitForm = async (values: any) => {
    if (isLoggedIn) {
      onSubmit(values);
    } else if (Object.keys(await signUpFormik.validateForm()).length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { confirmPassword, ...signUpValues } = signUpFormik.values;
      onSubmit(values, signUpValues);
    }
  };

  countries.registerLocale(enLocale);
  const countryObject = countries.getNames('en', { select: 'official' });
  const countryArray = Object.keys(countryObject).map((key) => {
    return { value: key, label: countryObject[key] };
  });

  const isLoading = useAppSelector(({ request: { isLoading } }) => isLoading);
  const onSubmitSignUpForm = async () => {
    const values = await signUpFormik.validateForm();
    gotoSignup();
    if (Object.keys(values).length === 0) {
      onSignUp(signUpFormik.values);
    }
  };

  useEffect(() => {
    if (formik.values.numberOfMen > 0 || formik.values.numberOfWomen === 0) {
      formik.setFieldValue(
        'numberOfMen',
        formik.values.numberOfMen +
          numberOfTickets -
          (formik.values.numberOfMen + formik.values.numberOfWomen),
      );
    } else {
      formik.setFieldValue(
        'numberOfWomen',
        formik.values.numberOfWomen +
          numberOfTickets -
          (formik.values.numberOfWomen + formik.values.numberOfMen),
      );
    }
  }, [numberOfTickets]);

  const validationSchema = yup.object({
    country: isPackages
      ? yup.string().required('Required').label('Nationality')
      : yup.string(),
    userLink: yup
      .string()
      .required('Required')
      .matches(
        /^(https?:\/\/)?(www\.)?(mbasic.facebook|m\.facebook|facebook|fb|instagram)\.com\/[a-zA-Z0-9(\.\?)?]/,
        'Required',
      ),
    genderRatio: yup.string().required(),
    guests: yup.array().of(
      yup.object().shape({
        name: yup.string().required().label('Name'),
        email: yup.string().email().required().label('Email'),
        link: yup
          .string()
          .required()
          .label('Social link')
          .matches(
            /^(https?:\/\/)?(www\.)?(mbasic.facebook|m\.facebook|facebook|fb|instagram)\.com\/[a-zA-Z0-9(\.\?)?]/,
            'Required',
          ),
        phone: yup.string().label('Phone'),
        gender: yup
          .mixed<UserGender>()
          .oneOf(['male', 'female'])
          .label('gender'),
      }),
    ),
  });

  const signUpValidationSchema = yup.object({
    firstName: yup.string().required().label('First Name'),
    lastName: yup.string().required().label('Last Name'),
    email: yup.string().email().required().label('Email'),
    phoneNumber: yup.string().label('Phone Number'),
    dateOfBirth: yup.date().label('Date of Birth'),
    password: yup.string().required().label('Password'),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('confirmPassword'), undefined])
      .label('Confirm Password'),
  });

  const user = useAppSelector(({ authentication: { user } }) => user);

  const initialValues: GuestFormValues = {
    userLink: user?.socialLink || '',
    guests: [],
    genderRatio: 'M/F',
    numberOfMen: 0,
    numberOfWomen: 0,
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    gender: user?.gender,
    email: user?.email,
  };

  const signUpInitialValues: SignUpFormValues = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: undefined,
    password: '',
    confirmPassword: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: onSubmitForm,
  });

  const signUpFormik = useFormik({
    initialValues: signUpInitialValues,
    validationSchema: signUpValidationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: () => {
      //
    },
  });

  const displayLoginButton = () => (
    <Box marginTop="12px" width="313.2px">
      <Button
        variant="contained"
        onClick={onRedirectToLogin}
        fullWidth
        sx={{
          backgroundColor: `${cityColor}`,
          borderRadius: '32px',
          padding: '14px 30px',
          textTransform: 'none',
          marginBottom: '34px',
          width: '313.2px',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <MdAccountCircle fontSize="24px" />
            <Typography>Returning Customer?</Typography>
          </Stack>
          <Typography>Login</Typography>
        </Stack>
      </Button>
    </Box>
  );
  const displaySignUpForm = () => (
    <Stack
      direction="column"
      spacing="8.1px"
      alignItems="center"
      width="313.2px"
      marginBottom="8.1px"
    >
      <Stack direction="row" spacing={2} alignItems="center" width="100%">
        <FormTextField
          className={classes.textField}
          placeholder="First Name"
          name="firstName"
          value={signUpFormik.values.firstName}
          onChange={signUpFormik.handleChange}
          onBlur={signUpFormik.handleBlur}
          touched={signUpFormik.touched.firstName}
          error={signUpFormik.errors.firstName}
          InputProps={{ className: classes.inputColor }}
          fullWidth
        />
        <FormTextField
          className={classes.textField}
          placeholder="Last Name"
          name="lastName"
          value={signUpFormik.values.lastName}
          onChange={signUpFormik.handleChange}
          onBlur={signUpFormik.handleBlur}
          touched={signUpFormik.touched.lastName}
          error={signUpFormik.errors.lastName}
          InputProps={{ className: classes.inputColor }}
          fullWidth
        />
      </Stack>
      <FormTextField
        className={classes.textField}
        placeholder="Phone Number"
        name="phoneNumber"
        value={signUpFormik.values.phoneNumber}
        onChange={signUpFormik.handleChange}
        onBlur={signUpFormik.handleBlur}
        touched={signUpFormik.touched.phoneNumber}
        error={signUpFormik.errors.phoneNumber}
        InputProps={{ className: classes.inputColor }}
        fullWidth
      />
      <FormTextField
        className={classes.textField}
        placeholder="Email"
        name="email"
        value={signUpFormik.values.email}
        onChange={signUpFormik.handleChange}
        onBlur={signUpFormik.handleBlur}
        touched={signUpFormik.touched.email}
        error={signUpFormik.errors.email}
        InputProps={{ className: classes.inputColor }}
        fullWidth
      />
      <FormTextField
        className={classes.textField}
        placeholder="Password"
        name="password"
        value={signUpFormik.values.password}
        onChange={signUpFormik.handleChange}
        onBlur={signUpFormik.handleBlur}
        touched={signUpFormik.touched.password}
        error={signUpFormik.errors.password}
        InputProps={{ className: classes.inputColor }}
        fullWidth
        type="password"
      />
      <FormTextField
        className={classes.textField}
        placeholder="Confirm Password"
        name="confirmPassword"
        value={signUpFormik.values.confirmPassword}
        onChange={signUpFormik.handleChange}
        onBlur={signUpFormik.handleBlur}
        touched={signUpFormik.touched.confirmPassword}
        error={signUpFormik.errors.confirmPassword}
        InputProps={{ className: classes.inputColor }}
        fullWidth
        type="password"
      />
      <DatePicker
        inputFormat="MM/dd/yyyy"
        value={signUpFormik.values.dateOfBirth}
        components={{ OpenPickerIcon: BsCalendarFill }}
        OpenPickerButtonProps={{
          style: {
            fontSize: 17.1,
            opacity: 0.46,
            marginRight: '1px',
            marginTop: '-3px',
          },
        }}
        onChange={(date: any) => {
          signUpFormik.setFieldValue(
            'dateOfBirth',
            (date as DateTime).toJSDate(),
          );
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            className={`${commonClasses.whiteInputProps} ${classes.fixInput}`}
            {...params}
            {...(isDesktop
              ? {}
              : {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <BsCalendarFill
                          style={{
                            fontSize: 18.1,
                            opacity: 0.46,
                            marginRight: '1px',
                            marginTop: '-5px',
                          }}
                        />
                      </InputAdornment>
                    ),
                  },
                })}
            fullWidth
          />
        )}
      />
    </Stack>
  );

  function displaySocialLinkForm() {
    return (
      <Stack
        direction="column"
        spacing="8.1px"
        width="313.2px"
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="row" spacing={2} alignItems="center" width="100%">
          <FormTextField
            className={classes.textField}
            placeholder="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.firstName}
            error={formik.errors.firstName}
            InputProps={{ className: classes.inputColor }}
            fullWidth
          />
          <FormTextField
            className={classes.textField}
            placeholder="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.lastName}
            error={formik.errors.lastName}
            InputProps={{ className: classes.inputColor }}
            fullWidth
          />
        </Stack>
        <FormTextField
          className={classes.textField}
          placeholder="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.email}
          error={formik.errors.email}
          InputProps={{ disabled: true, className: classes.inputColor }}
          fullWidth
        />
        {isPackages && (
          <Select
            value={formik.values.country}
            className={`${commonClasses.whiteInputProps} ${classes.fixInput}`}
            displayEmpty
            required={true}
            error={formik.errors.country !== undefined}
            onBlur={formik.handleBlur}
            name="country"
            onChange={(event) => {
              formik.setFieldValue('country', event.target.value as string);
            }}
            sx={{ width: '100%' }}
            renderValue={
              formik.values.country !== undefined
                ? undefined
                : () => <div style={{ opacity: 0.4 }}>Nationality</div>
            }
          >
            {!!countryArray &&
              countryArray.map(({ label, value }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                );
              })}
          </Select>
        )}
        <Stack direction="row" width="100%">
          <Grid container width="100%">
            <Grid item xs={12} md={12}>
              <Stack justifyContent="flex-start" alignItems="center">
                <Select
                  value={formik.values.gender}
                  className={`${commonClasses.whiteInputProps} ${classes.fixInput}`}
                  displayEmpty
                  onChange={(event) => {
                    formik.setFieldValue(
                      'gender',
                      event.target.value as UserGender,
                    );
                  }}
                  sx={{ width: '100%' }}
                  renderValue={
                    formik.values.gender !== undefined
                      ? undefined
                      : () => <div style={{ opacity: 0.4 }}>Gender</div>
                  }
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <FormTextField
          name={`userLink`}
          placeholder="Social account"
          className={classes.textField}
          value={formik.values.userLink}
          onChange={formik.handleChange}
          fullWidth
          required
          InputProps={{
            className: classes.inputColor,
          }}
          touched={formik.touched.userLink}
          onBlur={formik.handleBlur}
          error={formik.errors.userLink}
        />
        <Typography className={classes.urlText}>
          {'You must provide your Instagram or Facebook profile url'}
        </Typography>
        <Typography className={classes.urlText}>
          e.g. https://www.facebook.com/bobmoses
        </Typography>
        <Typography className={classes.urlText}>
          e.g. https://www.instagram.com/bobmoses
        </Typography>{' '}
      </Stack>
    );
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={classes.modalBody}>
        <Stack
          direction="row"
          margin="14px 14px 12px"
          justifyContent="space-between"
        >
          <IconButton onClick={onClickBack} sx={{ padding: 0 }}>
            <ArrowCircleLeftOutlinedIcon
              fontSize="large"
              sx={{ fontSize: '50px', color: 'black' }}
            />
          </IconButton>
          <IconButton onClick={handleClose} sx={{ padding: 0 }}>
            <CloseIcon
              fontSize="large"
              sx={{ fontSize: '50px', color: 'black' }}
            />
          </IconButton>
        </Stack>
        <Container>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            width={isDesktop ? '450px' : '100%'}
            margin="0 auto"
          >
            <Box>
              <Stack
                direction="column"
                spacing={0}
                alignItems="start"
                justifyContent="center"
                marginBottom="20px"
              >
                <Typography className={classes.title}>Send Request</Typography>
                <Typography className={classes.subTitle}>
                  {eventName}
                </Typography>
              </Stack>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
              >
                {!isLoggedIn && displayLoginButton()}
                {!isLoggedIn && displaySignUpForm()}
                {!isLoggedIn && open && gotoSignup()}
                {displaySocialLinkForm()}
              </Stack>
              {onReservation ? (
                <form onSubmit={formik.handleSubmit}>
                  <FormikProvider value={formik}>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      spacing="42.8px"
                    >
                      {onReservation &&
                        Array.from({ length: numberOfTickets - 1 })
                          .fill(0)
                          .map((_, index) => (
                            <TicketGuestForm
                              setValue={formik.setFieldValue}
                              key={index}
                              cityColor={cityColor}
                              guestNumber={index}
                              isPackages={isPackages}
                              onChange={formik.handleChange}
                              name={formik.values.guests[index]?.name}
                              email={formik.values.guests[index]?.email}
                              phone={formik.values.guests[index]?.phone}
                              gender={formik.values.guests[index]?.gender}
                              link={formik.values.guests[index]?.link}
                              nationality={
                                formik.values.guests[index]?.nationality
                              }
                              formikErrors={formik.errors}
                              formikTouched={formik.touched}
                              onBlur={formik.handleBlur}
                            />
                          ))}
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        style={{ marginTop: '42px' }}
                        disabled={isLoading}
                        sx={{
                          backgroundColor: `${cityColor}`,
                          borderRadius: 30,
                          width: '313.2px',
                        }}
                      >
                        {' '}
                        {isLoading ? 'Sending...' : 'Send Request'}
                      </Button>
                    </Stack>
                  </FormikProvider>
                </form>
              ) : (
                <Box marginX={2.25}>
                  <Button
                    type="submit"
                    onClick={onSubmitSignUpForm}
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      marginTop: 6,
                      backgroundColor: `${cityColor}`,
                      borderRadius: '19px',
                      padding: '14px 0',
                    }}
                  >
                    Buy tickets
                  </Button>
                </Box>
              )}
            </Box>
          </Stack>
        </Container>
      </div>
    </Modal>
  );
};
