import React from 'react';
import LogoIMG from '../../../assets/logo.svg';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getCurrentUrl } from '../../../utils/getCurrentUrl';
import { createUseStyles } from 'react-jss';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import IconButton from '../../IconButton';
import { Box } from '@mui/system';

export const FooterLogo = styled.div`
  margin-top: 30px;
  margin-bottom: 187px;
  width: 100%;
  @media screen and (max-width: 500px) {
    margin-top: 50px;
    margin-bottom: 100px;
  }
`;
export const FooterLogoImg = styled.img`
  width: 100px;
  margin: 0 auto;
`;
const useStyles = createUseStyles({
  title: {
    color: '#000',
    textAlign: 'center',
    fontWeight: 800,
    fontSize: 60,
    '@media screen and (max-width: 900px)': {
      fontSize: 30,
    },
  },
  footer: {
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '64px',
  },
  social: {
    width: '100%',
    marginTop: '30px',
  },
  container: {
    width: '50%',
    margin: '0 auto',
    '@media screen and (max-width: 900px)': {
      width: '90%',
    },
  },
  text: {
    color: 'black',
    fontSize: 17,
    marginBottom: 17,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    fontWeight: 600,
    '@media screen and (max-width: 900px)': {
      fontSize: 14,
    },
  },
  subTextContainer: {
    width: 480,
    margin: '0 auto',
    '@media screen and (max-width: 500px)': {
      width: '90%',
    },
  },
  footerDocs: {
    width: '100%',
    position: 'relative',
    '@media screen and (max-width: 500px)': {
      width: '100%',
    },
  },
});

const Footer = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery('(min-width: 900px)');
  return (
    <Box className={classes.container} id="footer">
      <Box>
        <Stack
          direction="column"
          alignItems={'center'}
          justifyContent="center"
          width="100%"
        >
          <Box className={classes.footerDocs}>
            <Typography className={classes.title}>A Collective.</Typography>
            <Box className={classes.subTextContainer}>
              <Typography className={classes.text}>
                Techno and chill is a Media, News and Events company crafted by
                a team of creative minds who share an unrivalled passion for
                music and club culture. Today, the brand is regarded as one of
                the leading media outlets within the electronic music community.
                <br />
                <br />
                Copyright 2022 – Techno And Chill – All rights reserved.
              </Typography>
            </Box>
            <Box className={classes.social}>
              <Stack
                direction="row"
                alignItems={'center'}
                justifyContent="center"
                spacing={isDesktop ? '29.7px' : 1}
                width="100%"
              >
                <IconButton
                  size={50}
                  reverse
                  disabled
                  icon="twitter"
                  href="https://twitter.com/technoandchill"
                />
                <IconButton
                  size={50}
                  reverse
                  disabled
                  icon="discord"
                  href="https://discord.com"
                />
                <IconButton
                  reverse
                  size={50}
                  icon="instagram"
                  href="https://instagram.com/technoandchill.nft"
                />
                <IconButton
                  size={50}
                  reverse
                  disabled
                  icon="boat"
                  href="https://discord.com"
                />
                <IconButton
                  reverse
                  disabled
                  size={50}
                  icon="etherscan"
                  href="https://etherscan.com"
                />
              </Stack>
            </Box>
            <FooterLogo>
              {!(
                getCurrentUrl() === '/signin' || getCurrentUrl() === '/signup'
              ) ? (
                <Link to="/">
                  <FooterLogoImg src={LogoIMG} />
                </Link>
              ) : null}
            </FooterLogo>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;
