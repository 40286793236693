import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { EventTicket } from 'src/models/ticket';
import { Box } from '@mui/system';
import { ReactComponent as SoldOut } from '../../assets/icons/soldOut.svg';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Swiper as SwiperType } from 'swiper';
import { ReactComponent as Location } from '../../assets/location.svg';

import SwiperCore, { Autoplay } from 'swiper';
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/all';

const useStyles = createUseStyles({
  earlyBird: {},
  earlyBirdd: {
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  swiperSlide: {
    width: 'auto',
  },

  carouselButton: {
    fontSize: '41px',
    color: '#000',
    cursor: 'pointer',
    marginBottom: '27px !important',
  },
  disabled: {
    color: '#000',
    opacity: 0.1,
  },
  subText: {
    fontSize: '12px',
    color: 'black',
    fontWeight: 500,
    opacity: 0.24,
    lineHeight: '15px',
    marginBottom: '6.61px',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  text: {
    fontSize: '14.5px',
    fontWeight: 'bold',
    color: 'black',
    lineHeight: '18px',
    maxLines: 1,
    whiteSpace: 'nowrap',
  },
  svgIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
  '@keyframes bounce': {
    '0%': {
      transform: 'translateX(0)',
    },
    '50%': {
      transform: 'translateX(15px)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  disabledButton: {
    '&:active': {
      animation: '$bounce 1s infinite',
    },
  },
  fieldTitle: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 800,
    color: 'black',
    textOverflow: 'ellipsis',
    maxLines: 1,
  },
  fieldSubtitle: {
    color: 'black',

    textOverflow: 'ellipsis',
    maxLines: 1,
    fontSize: '9px',

    fontWeight: 600,
    opacity: 0.24,
  },
});

interface Props {
  ticket: EventTicket;
  cityColor: string;
  onChangeNumberOfTickets: (value: number) => void;
  numberOfTickets: number;
  maximum?: number;
  minimum?: number;
  currency?: string;
  noAmounts?: boolean;
}

export const PackageForm: React.VFC<Props> = ({
  cityColor,
  onChangeNumberOfTickets,
  numberOfTickets,
  minimum,
  maximum,
  ticket,
  currency,
  noAmounts = false,
}) => {
  const classes = useStyles();
  const decrement = () => {
    if (numberOfTickets > 0) {
      onChangeNumberOfTickets(numberOfTickets - 1);
    }
  };
  const increment = () => {
    if (maximum ? numberOfTickets < maximum : true) {
      onChangeNumberOfTickets(numberOfTickets + 1);
    }
  };
  const images = ticket.images && ticket.images?.split('\n');
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  const [allowSwipe, setAllowSwipe] = useState({
    prev: false,
    next: true,
  });
  useEffect(() => {
    setPrevEl(prevEl);
    setNextEl(nextEl);
  }, [prevEl, nextEl]);
  const buildDescriptionField = (
    title?: string,
    subtitle?: string,
    icon?: () => JSX.Element,
    allRow?: boolean,
    onClick?: () => void,
  ) => (
    <Grid
      item
      container
      direction="row"
      display="flex"
      xs={12}
      md={allRow ? 12 : 6}
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
      }}
    >
      <Stack direction="row" alignItems="center">
        {icon && icon()}
        <Stack direction="column">
          <Typography
            className={classes.fieldTitle}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              lineHeight: '7px',
              fontStyle: 'SF Pro Display',
              opacity: 0.9,
            }}
          >
            {title}
          </Typography>
          <Typography
            className={classes.fieldSubtitle}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              lineHeight: '28px',
            }}
          >
            <b style={{ fontSize: '14px', whiteSpace: 'nowrap' }}>
              {subtitle}{' '}
            </b>
            Km from Festival Venue
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );

  return (
    <Box width="250px" className={classes.earlyBird}>
      <Stack direction="column" width="100%">
        <div
          style={{
            paddingTop: '25%',
            paddingLeft: '8px',
            position: 'absolute',
            zIndex: 10,
          }}
          ref={(node) => setPrevEl(node)}
          className={`${classes.carouselButton} ${
            allowSwipe.prev ? undefined : classes.disabled
          }`}
        >
          <img
            src="https://i.imgur.com/7tin2Fi.png" // todo
            width={'50%'}
            style={{ transform: 'rotate(180deg)' }}
          />
        </div>
        <Swiper
          direction={'horizontal'}
          slidesPerView={'auto'}
          onSlideChange={(swiper) => {
            setAllowSwipe({
              prev: !swiper.isBeginning,
              next: !swiper.isEnd,
            });
          }}
          loop={false}
          modules={[Navigation, Pagination]}
          navigation={{ prevEl, nextEl }}
          preloadImages={true}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 3,
            loadOnTransitionStart: false,
          }}
        >
          {images &&
            images.map((image, index) => (
              <SwiperSlide key={index}>
                {ticket.websiteLink && ticket.websiteRating ? (
                  <div>
                    <Button
                      variant="text"
                      sx={{
                        display: 'block',
                        backgroundColor: '#D8D8D8',
                        position: 'absolute',
                        zIndex: 10,
                        left: '20px',
                        top: '15px',
                        borderRadius: '5px',
                        opacity: '0.7',
                        maxWdth: '10px',
                      }}
                      onClick={() => {
                        window.open(
                          'https://www.' + ticket.websiteLink,
                          '_blank',
                        );
                      }}
                    >
                      <Typography
                        sx={{
                          display: 'block',
                          fontSize: '6px',
                          fontWeight: 'bold',
                          color: 'black',
                          lineWidth: '4px',

                          textTransform: 'capitalize-words',
                        }}
                      >
                        {ticket.websiteLink}
                      </Typography>
                      <Typography
                        sx={{
                          display: 'block',
                          fontSize: '14px',
                          fontWeight: 'bold',
                          color: 'black',
                          lineHeight: '7px',
                          lineWidth: '4px',
                          textTransform: 'uppercase',
                          paddingBottom: '2px',
                        }}
                      >
                        {' '}
                        <br></br> {ticket.websiteRating}
                      </Typography>
                    </Button>
                    <Button
                      variant="text"
                      sx={{
                        display: 'block',
                        backgroundColor: '#D8D8D8',
                        position: 'absolute',
                        zIndex: 10,
                        left: '20px',
                        bottom: '18%',
                        borderRadius: '5px',
                        opacity: '0.7',
                      }}
                      onClick={() => {
                        window.open(
                          'https://www.' + ticket.websiteLink,
                          '_blank',
                        );
                      }}
                    >
                      <Typography
                        sx={{
                          display: 'block',
                          fontSize: '8px',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        View Hotel
                      </Typography>
                    </Button>
                  </div>
                ) : null}
                <img
                  key={index}
                  width="265px"
                  style={{
                    borderRadius: '10px',
                    maxHeight: '150px',
                    marginBottom: '20px',

                    paddingRight: '2px',
                  }}
                  src={image}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <div
          style={{
            paddingTop: '25%',
            position: 'absolute',
            zIndex: 10,
            right: 0,
          }}
          ref={(node) => setNextEl(node)}
          className={`${classes.carouselButton} ${
            allowSwipe.next ? undefined : classes.disabled
          }`}
        >
          <img width={'50%'} src="https://i.imgur.com/7tin2Fi.png" />
        </div>

        <Stack
          direction="row"
          width={'95%'}
          display={'flex'}
          justifyContent="space-between"
        >
          <div>
            <Typography className={classes.text}>{ticket.name}</Typography>
            {!noAmounts && (
              <div>
                <Typography className={classes.text} display="inline">
                  {` ${ticket.aPrice}`}
                </Typography>
                <Typography className={classes.text} display="inline">
                  {currency}
                </Typography>
                {ticket.accomdationFees && (
                  <>
                    <Typography
                      className={classes.text}
                      display="inline"
                      style={{ fontSize: '9px', fontWeight: 500 }}
                    >
                      {`  + ${ticket.accomdationFees}`}
                    </Typography>
                    <Typography
                      className={classes.text}
                      display="inline"
                      style={{ fontSize: '9.9px', fontWeight: 500 }}
                    >
                      {` ${currency}`}
                    </Typography>
                  </>
                )}
              </div>
            )}
          </div>

          <Grid
            container
            width={'25%'}
            alignItems={'right'}
            justifyContent={'right'}
          >
            {ticket.max &&
              Array(ticket.max)
                .fill(1)
                .map((x, i) => (
                  <Grid item key={i} xs={1.71} alignSelf={'right'}>
                    <img key={i} src="https://i.imgur.com/dFzZGL4.png" />
                  </Grid>
                ))}
          </Grid>
        </Stack>

        {!noAmounts && (
          <Typography
            sx={{ minHeight: '45px', lineHeight: '8px' }}
            className={classes.subText}
          >
            {ticket.description}
          </Typography>
        )}

        {buildDescriptionField(
          ticket.location,
          ticket.distanceFromVenue?.toString(),
          () => (
            <SvgIcon
              component={Location}
              className={classes.svgIcon}
              style={{
                width: 20,
                height: 32,
                marginRight: 10,
                marginLeft: 4,
                marginTop: -10,
              }}
              inheritViewBox
            />
          ),
          true,
          () => {
            window.open(ticket.locationLink);
          },
        )}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyItems="space-between"
        justifyContent={'space-between'}
        width="100%"
        height="50px"
      >
        <Typography
          textAlign={'start'}
          fontSize={'13px'}
          minWidth={'120px'}
          display="flex"
        >
          Number Of Tickets
        </Typography>
        {Number(ticket.aTotal) - Number(ticket.sold) > 0 &&
        ticket.takingRequest ? (
          <Stack
            direction="column"
            alignItems="center"
            width="100%"
            justifyContent="flex-end"
          >
            <Stack direction="row" display="flex" alignItems="center">
              <IconButton
                onClick={
                  !noAmounts &&
                  ((ticket.onReservation && !ticket.takingRequest) ||
                    ticket.available === false ||
                    numberOfTickets === 0 ||
                    (ticket.total ?? 0) - (ticket.sold ?? 0) === 0)
                    ? undefined
                    : decrement
                }
                className={
                  (ticket.onReservation && !ticket.takingRequest) ||
                  ticket.available === false ||
                  numberOfTickets === 0 ||
                  (ticket.total ?? 0) - (ticket.sold ?? 0) === 0
                    ? classes.disabledButton
                    : undefined
                }
              >
                <RemoveCircleIcon
                  sx={{ color: `${cityColor}`, fontSize: '37px' }}
                />
              </IconButton>
              <span style={{ fontSize: '20px', fontWeight: 800 }}>
                {numberOfTickets}
              </span>
              <IconButton
                onClick={
                  !noAmounts &&
                  ((ticket.onReservation && !ticket.takingRequest) ||
                    ticket.available === false ||
                    (ticket.total ?? 0) - (ticket.sold ?? 0) <=
                      numberOfTickets ||
                    numberOfTickets === ticket.max)
                    ? undefined
                    : increment
                }
                className={
                  (ticket.onReservation && !ticket.takingRequest) ||
                  ticket.available === false ||
                  (ticket.total ?? 0) - (ticket.sold ?? 0) <= numberOfTickets ||
                  numberOfTickets === ticket.max
                    ? classes.disabledButton
                    : ''
                }
              >
                <AddCircleIcon
                  sx={{ color: `${cityColor}`, fontSize: '37px' }}
                />
              </IconButton>
            </Stack>
            {numberOfTickets > 0 && numberOfTickets < (minimum ?? 0) && (
              <Typography lineHeight={'1px'} fontSize={12} color="error">
                Min {minimum} Tix
              </Typography>
            )}
          </Stack>
        ) : (
          <div
            style={{
              paddingRight: '40px',
            }}
          >
            <SvgIcon
              component={SoldOut}
              className={classes.svgIcon}
              style={{
                width: 50.28,
                height: 50.28,
                color: `${cityColor}`,
              }}
              inheritViewBox
            />
          </div>
        )}
      </Stack>
    </Box>
  );
};
