import React, { useMemo, VFC } from 'react';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import styled from 'styled-components';
import { useAppSelector } from 'src/hooks/store';
import { customerMenu, plannerMenu, publicMenu } from 'src/data/menus';
import { useMediaQuery } from '@mui/material';

export const LogoutBtnDiv = styled.div`
  width: 195px;
  @media screen and (max-width: 1024px) {
    width: 150px;
    font-size: 16px;
    transition: 0.2s linear;
  }
`;
export const SideMenubar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  height: 100vh;
  width: 250px;
  justify-content: space-between;
  background-color: #f5f5f5;
`;

export const SideMenuItem = styled.div`
  color: gray !important;
  font-size: 15px;
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    color: white;
    transition: 0.2s linear;
  }
`;

export const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
`;
const Header: VFC = () => {
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  const isDesktop = useMediaQuery('(min-width: 900px)', { noSsr: true });
  const isAuthenticated = useMemo(() => user, [user]);
  const isCustomer = useMemo(() => user?.type == 'customer', [user]);
  const currentMenu = useMemo(
    () =>
      !isAuthenticated ? publicMenu : isCustomer ? customerMenu : plannerMenu,
    [isAuthenticated, isCustomer],
  );

  return isDesktop ? (
    <DesktopHeader menu={currentMenu} />
  ) : (
    <MobileHeader menu={currentMenu} />
  );
};

export default Header;
