import React, { useMemo } from 'react';
import LogoIMG from '../../../assets/logo.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { HeaderDiv, MenuItem } from './OldHeader';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { logOut } from 'src/slices/authentication';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Stack, Typography, Box, Button } from '@mui/material';
import { Menu } from 'src/data/menus';

interface DesktopHeaderProps {
  menu: Menu[];
}

const DesktopHeader: React.VFC<DesktopHeaderProps> = ({ menu }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  const history = useHistory();

  const isAuthenticated = useMemo(() => user, [user]);

  const onClickSignIn = async () => {
    history.push('/sign-in');
  };

  const onClickLogOut = async () => {
    dispatch(logOut());
  };

  const displayMenuItem = ({
    name,
    key,
    sub,
    displayMenu,
    subtitle,
    disabled,
  }: Menu) => (
    <Link
      to={`/${key}`}
      style={{
        textDecoration: 'none',
        pointerEvents: disabled ? 'none' : 'all',
      }}
      key={key}
    >
      {name === 'Marketing tools' && <Box sx={{ marginTop: 2.4 }} />}

      <MenuItem active={location.pathname.slice(1) === key} sub={!!sub}>
        <Stack direction="row" alignItems="center">
          <Typography
            fontSize={{ xs: '16px', xl: '20px' }}
            fontWeight={location.pathname.slice(1) === key ? 'bold' : 500}
          >
            {name}
          </Typography>
          {displayMenu && <ArrowDropDownIcon />}
        </Stack>
        {subtitle && (
          <Typography
            fontWeight="200"
            fontSize="13px"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {subtitle}
          </Typography>
        )}
      </MenuItem>
    </Link>
  );

  const displayLoginOutButton = () => (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      minWidth="150px"
    >
      <Button
        onClick={isAuthenticated ? onClickLogOut : onClickSignIn}
        sx={{
          border: '2px solid black',
          background: 'white',
          cursor: 'pointer',
          height: '50px',
          width: { xs: '140px', xl: '175px' },
          borderRadius: '50px',
          textTransform: 'none',
        }}
      >
        <Typography
          fontSize={{ xs: '16px', xl: '20px' }}
          color="black"
          fontWeight="bold"
        >
          {isAuthenticated ? 'Log Out' : 'Sign In'}
        </Typography>
      </Button>
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          margin: user?.type === 'eventPlanner' ? '0 8.5% 0 9%' : '0 5.5rem',
        }}
      >
        <HeaderDiv>
          <Link to={'/'}>
            <Box
              component="img"
              maxHeight="90px"
              minWidth={{ xs: '88px', xl: '150px' }}
              src={LogoIMG}
              alt="logo"
            />
          </Link>
          {user?.type === 'eventPlanner' && (
            <Box sx={{ marginLeft: { xs: '24px', xl: '30px' } }}>
              <Typography
                fontSize={{ xs: '24px', xl: '30px' }}
                fontWeight="bold"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                Event Planner
              </Typography>
            </Box>
          )}

          <Box
            display="flex"
            alignItems="center"
            justifyContent="end"
            gap="2%"
            width="100%"
          >
            <Box display="flex" alignItems="center" gap="10px">
              {menu.map(displayMenuItem)}
            </Box>
            {displayLoginOutButton()}
          </Box>
        </HeaderDiv>
      </Box>
      <Stack justifyContent="center" alignItems="center">
        <p style={{ borderBottom: 'solid 1px #e3e3e3', width: '65%' }}></p>
      </Stack>
    </>
  );
};

export default DesktopHeader;
