import { City } from 'src/models/city';
import { Event } from 'src/models/event';

export type EventsCountPerCity = { [cityId: string]: number };

export const getEventsCountPerCity = (
  events: Event[],
  cities: City[],
): EventsCountPerCity =>
  events.reduce(
    (accumulator: EventsCountPerCity, event: Event) => ({
      ...accumulator,
      ...(event.cityId
        ? { [event.cityId]: accumulator[event.cityId] + 1 }
        : {}),
    }),
    cities.reduce(
      (accumulator, city) => ({
        ...accumulator,
        [city.id]: 0,
      }),
      {},
    ),
  );

export const countryByCityId: { [key: string]: string | undefined } = {
  '0xOzqoldYK': undefined,
  hs6TL9QFAW: 'nl',
  gplKKvoeQA: 'us',
  '5io1bdUOQ1': 'us',
  aPqta93ZWW: 'uk',
  CDrnyeEG1b: 'es',
  o1VgNseTZI: 'fr',
  FzW024QONl: 'mx',
  aFIR0IzAhD: 'es',
};
