import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import Parse from 'parse/node';
import { TicketsNumberByDay } from 'src/components/event/EventPlannerCard';
import { GuestFormValues } from 'src/components/tickets/MobileTicketGuestSection';
import { TicketsNumberByType } from 'src/components/tickets/ModalTicket';
import {
  serializeMinimalTicketObjects,
  serializeTickets,
} from 'src/helpers/serializeModels';
import { MinimalTicketObject, Ticket } from 'src/models/ticket';

// /* Ticket slice */

interface TicketState {
  purchasedTickets: Ticket[] | null;
  plannerTickets: MinimalTicketObject[] | null;
  ticketsSuccessfullyBought: boolean | null;
  generatedPassesTickets: Ticket[] | null;
  purchasedTicketsFormState: {
    tickets: TicketsNumberByType;
    eventId: string;
  } | null;
  signUpToBuyTicketState: {
    guestFormValues?: GuestFormValues | undefined;
    isWaiting?: boolean | undefined;
  } | null;
  ticketsSales?: { [eventId: string]: TicketsNumberByDay } | null;
  isLoading: boolean;
  error: string | null;
  totalSales?: { [eventId: string]: [salesByEvent: string] } | null;
  ticketSales?: number[] | null;
  totalNetProfit?: { [eventId: string]: [netProfitByEvent: string] } | null;
  selectedTableId?: string | null;
}

const initialState: TicketState = {
  purchasedTickets: null,
  plannerTickets: null,
  ticketsSuccessfullyBought: null,
  generatedPassesTickets: null,
  purchasedTicketsFormState: null,
  ticketsSales: null,
  isLoading: false,
  error: null,
  signUpToBuyTicketState: null,
  totalSales: null,
  totalNetProfit: null,
  selectedTableId: null,
};

interface BuyTicketsDto {
  boughtTickets: BuyTicketsObject;
  fromGifts: boolean;
  fromPoints: boolean;
  eventId: string;
  paid: boolean;
  stripeToken: string;
  coupon?: string | undefined;
}

type BuyTicketsObject = { [ticketId: string]: number };

// /* Thunks */
export const buyTickets = createAsyncThunk<boolean, BuyTicketsDto>(
  'ticket/buyTickets',
  async ({
    boughtTickets,
    fromGifts,
    fromPoints,
    paid,
    eventId,
    stripeToken,
    coupon,
  }) => {
    const user = Parse.User.current();
    const isStripeModeLive = process.env.REACT_APP_STRIPE_MODE == 'LIVE';
    if (user) {
      const result = await Parse.Cloud.run('buyTickets', {
        tickets: boughtTickets,
        fromGifts: fromGifts,
        fromPoints: fromPoints,
        paid: paid,
        eventId: eventId,
        token: stripeToken,
        userId: user.id,
        liveStripe: isStripeModeLive,
        coupon: coupon,
      });
      return result;
    }
  },
);

export const getPurchasedTickets = createAsyncThunk<Ticket[] | undefined>(
  'ticket/getPurchasedTickets',
  async () => {
    const TicketClassName = Parse.Object.extend('Ticket');
    const qTicket = new Parse.Query(TicketClassName);
    qTicket.equalTo('user', Parse.User.current());
    qTicket.include('event');
    qTicket.include('event.planner');
    qTicket.include('event.city');
    qTicket.limit(1000);
    qTicket.descending('createdAt');
    const tickets = await qTicket.find();
    return serializeTickets(tickets);
  },
);

export const getTicketsSoldbyId = createAsyncThunk<number, string>(
  'ticket/getTicketsSoldbyId',
  async (ticketId) => {
    const TicketClassName = Parse.Object.extend('Ticket');
    const qTicket = new Parse.Query(TicketClassName);
    qTicket.equalTo('idTicket', ticketId);
    const tickets = await qTicket.find();
    return tickets.length;
  },
);

export const fetchPlannerTickets = createAsyncThunk<
  MinimalTicketObject[] | undefined
>('ticket/fetchPlannerTickets', async () => {
  const Ticket = Parse.Object.extend('Ticket');
  const Planner = Parse.Object.extend('Planner');
  const qTicket = new Parse.Query(Ticket);
  const plannerId = Parse.User.current()?.get('planner')?.id;
  qTicket.equalTo('planner', Planner.createWithoutData(plannerId));
  qTicket.select(['createdAt', 'id', 'event']);
  qTicket.limit(100);
  qTicket.descending('createdAt');
  return qTicket.find().then(serializeMinimalTicketObjects);
});

export const generateTicketPasses = createAsyncThunk<Ticket[], string[]>(
  'ticket/generateTicketPasses',
  async (ticketIds: string[]) => {
    return Parse.Cloud.run('generatePasses', { ticketIds: ticketIds }).then(
      serializeTickets,
    );
  },
);

export const getTicketsSales = createAsyncThunk<{
  [eventId: string]: TicketsNumberByDay;
}>('ticket/getTicketsSales', async () => {
  const plannerId = Parse.User.current()?.get('planner')?.id;
  return Parse.Cloud.run('getTicketsSales', {
    plannerId: plannerId,
  }).then((result: { [eventId: string]: TicketsNumberByDay }) => {
    return result;
  });
});

//make a function to get ticket sales by sending ticket ids

export const getTotalSales = createAsyncThunk<{
  [eventId: string]: [salesByEvent: string];
}>('ticket/getTotalSales', async () => {
  const plannerId = Parse.User.current()?.get('planner')?.id;
  return Parse.Cloud.run('getTotalSales', {
    plannerId: plannerId,
  }).then((result: { [eventId: string]: [string] }) => {
    return result;
  });
});

export const getNetProfit = createAsyncThunk<{
  [eventId: string]: [netProfitByEvent: string];
}>('ticket/getNetProfit', async () => {
  const plannerId = Parse.User.current()?.get('planner')?.id;
  return Parse.Cloud.run('getNetProfit', {
    plannerId: plannerId,
  }).then((result: { [eventId: string]: [string] }) => {
    return result;
  });
});

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
  },
};

/* Slice */
const ticketSlice = createSlice({
  name: 'ticket',
  initialState: initialState,
  reducers: {
    setPurchasedTicketsFormState: (
      state,
      {
        payload,
      }: { payload: { tickets: TicketsNumberByType; eventId: string } | null },
    ) => {
      state.purchasedTicketsFormState = payload;
    },
     setSelectedTableId: (
      state,
      {
        payload,
      }: { payload: string | null },
    ) => {
      state.selectedTableId = payload;
    },
    resetPurchasedTickets: (state) => {
      state.ticketsSuccessfullyBought = null;
      state.error = null;
    },
    setSignUpToBuyTicketsState: (
      state,
      {
        payload,
      }: {
        payload: {
          guestFormValues?: GuestFormValues;
          isWaiting?: boolean;
        } | null;
      },
    ) => {
      state.signUpToBuyTicketState = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(buyTickets.pending, (state) => {
        state.error = null;
        state.isLoading = true;
        state.ticketsSuccessfullyBought = null;
      })
      .addCase(
        buyTickets.rejected,
        (state, { error }: { error: SerializedError }) => {
          state.isLoading = false;
          state.ticketsSuccessfullyBought = false;
          state.error = error.message ?? 'error';
        },
      )
      .addCase(buyTickets.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.ticketsSuccessfullyBought = payload;
        }
      })
      .addCase(getPurchasedTickets.pending, sharedReducers.pending)
      .addCase(getPurchasedTickets.rejected, sharedReducers.rejected)
      .addCase(getPurchasedTickets.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.purchasedTickets = payload ?? null;
        }
      })
      .addCase(fetchPlannerTickets.pending, sharedReducers.pending)
      .addCase(fetchPlannerTickets.rejected, sharedReducers.rejected)
      .addCase(fetchPlannerTickets.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.plannerTickets = payload ?? null;
        }
      })
      .addCase(generateTicketPasses.pending, sharedReducers.pending)
      .addCase(generateTicketPasses.rejected, sharedReducers.rejected)
      .addCase(generateTicketPasses.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          if (state.purchasedTickets) {
            state.purchasedTickets = state.purchasedTickets.map((ticket) => {
              const sameTicket = payload.find(
                (generatedTicket) => generatedTicket.id === ticket.id,
              );
              if (sameTicket && sameTicket.pkpass) {
                return sameTicket;
              }
              return ticket;
            });
            state.generatedPassesTickets = payload;
          }
        }
      })
      .addCase(getTicketsSales.pending, sharedReducers.pending)
      .addCase(getTicketsSales.rejected, sharedReducers.rejected)
      .addCase(getTicketsSales.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.ticketsSales = payload;
        }
      })
      .addCase(getTotalSales.rejected, sharedReducers.rejected)
      .addCase(getTotalSales.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.totalSales = payload;
        }
      })
      .addCase(getNetProfit.pending, sharedReducers.pending)
      .addCase(getNetProfit.rejected, sharedReducers.rejected)
      .addCase(getNetProfit.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.totalNetProfit = payload;
        }
      });
  },
});

export const {
  setPurchasedTicketsFormState,
  resetPurchasedTickets,
  setSignUpToBuyTicketsState,
  setSelectedTableId,
} = ticketSlice.actions;

export default ticketSlice.reducer;
