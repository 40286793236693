import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  TextField,
  Stack,
  useMediaQuery,
  InputAdornment,
} from '@mui/material';
import { Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { updateUser } from 'src/slices/authentication';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Box } from '@mui/system';
import PhoneInput from 'react-phone-input-2';
import DatePicker from '@mui/lab/DatePicker';
import { BsCalendarFill } from 'react-icons/all';
import { DateTime } from 'luxon';
import { commonStyles } from 'src/constants/common-styles';

const useStyles = createUseStyles({
  container: {
    "@media (min-width: 900px)": {
      margin: "5em",

      minHeight: "100vh",
    },
  },
  textfield: {
    height: "45px",
    color: "black",
    "& .MuiOutlinedInput-input": {
      padding: "10.7px 20.7px",
    },

    [`& fieldset`]: { border: "none" },
  },
  updatePasswordButton: {
    borderRadius: 300,
    height: "45px",
    color: "white",
    backgroundColor: "#FF0202",
    "&:hover": {
      backgroundColor: "#F70303",
      border: "solid 1px #F70303",
    },
  },
  inputColor: {
    border: "solid 2px #F0F0F0",
    borderRadius: 30,
    backgroundColor: "#ffffff",
  },
  phoneInput: {
    borderRadius: "50px !important",
    width: "100% !important",
    height: "45px",
    border: "solid 2px #F0F0F0 !important",
    "&:focus": {
      boxShadow: "none !important",
    },
  },
  phoneInputButton: {
    marginLeft: "10px",
  },
  fixInput: {
    maxWidth: "100%",
    height: "45px",
    "& .MuiOutlinedInput-input": {
      padding: "10.7px 19.7px",
    },
    [`& fieldset`]: { border: "none" },
  },
  profileHeader: {
    fontWeight: 800,
    color: "black",
    fontSize: 45,
    lineHeight: "64px",
    "@media screen and (max-width: 900px)": {
      marginTop: "70px",
    },
  },
  profileDesc: {
    color: "black",
    marginTop: "-8px !important",
    marginLeft: "3px !important",
    fontWeight: 500,
    fontSize: 22,
  },
});

interface ProfileFormValues {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
  dateOfBirth: Date;
}

const Profile: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery('(max-width: 900px)');
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    user?.phoneNumber,
  );

  const validationSchema = yup.object({
    firstName: yup.string(),
    lastName: yup.string(),
    phoneNumber: yup.string(),
    currentPassword: yup.string(),
    newPassword: yup.string(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('confirmPassword'), undefined]),
  });
  const initialValues = { dateOfBirth: new Date() };
  const formik = useFormik<ProfileFormValues>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    formik.setValues({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      dateOfBirth: new Date(user?.dateOfBirth || '01-01-2000'),
    });
    setPhoneNumber(user?.phoneNumber);
  }, [user]);
  const commonClasses = commonStyles();
  const isDesktop = useMediaQuery('(min-width: 900px)');
  const onSubmit = (values: ProfileFormValues) =>
    dispatch(
      updateUser({
        newPassword: values.newPassword,
        currentPassword: values.currentPassword,
        name: `${values.firstName} ${values.lastName}`,
        phoneNumber: phoneNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        dateOfBirth: values.dateOfBirth,
      }),
    );

  const displayTextField = ({
    name,
    placeholder,
    value,
    touched,
    error,
    fullWidth,
    sx,
    type,
  }: {
    name: string;
    placeholder: string;
    value?: string;
    touched?: boolean;
    error?: string;
    fullWidth?: boolean;
    sx?: any;
    type?: string;
  }) => (
    <TextField
      className={classes.textfield}
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={formik.handleChange}
      InputProps={{ className: classes.inputColor }}
      error={Boolean(touched && error)}
      helperText={touched && error ? error : ''}
      onBlur={formik.handleBlur}
      fullWidth={fullWidth}
      sx={sx}
      type={type}
    />
  );

  return (
    <>
      <Box className={classes.container}>
        <form onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <Container maxWidth="xs" sx={{ marginTop: '1em' }}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Stack
                  direction="column"
                  spacing={'10px'}
                  justifyContent="center"
                >
                  <Stack direction="column" justifyContent="center">
                    <Typography className={classes.profileHeader}>
                      Profile
                    </Typography>
                    <Typography className={classes.profileDesc}>
                      Customer
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    {displayTextField({
                      name: 'firstName',
                      placeholder: 'First name',
                      value: formik.values.firstName,
                      touched: formik.touched.firstName,
                      error: formik.errors.firstName,
                    })}
                    {displayTextField({
                      name: 'lastName',
                      placeholder: 'Last name',
                      value: formik.values.lastName,
                      touched: formik.touched.lastName,
                      error: formik.errors.lastName,
                    })}
                  </Stack>
                  <PhoneInput
                    country={'eg'}
                    value={phoneNumber}
                    inputClass={classes.phoneInput}
                    buttonClass={classes.phoneInputButton}
                    placeholder=""
                    onChange={(phone) => setPhoneNumber(phone)}
                  />
                  <TextField
                    placeholder="Email"
                    className={classes.textfield}
                    value={user?.email}
                    InputProps={{ className: classes.inputColor }}
                    type="email"
                    disabled={true}
                  />
                  <DatePicker
                    inputFormat="MM/dd/yyyy"
                    value={formik.values.dateOfBirth}
                    components={{ OpenPickerIcon: BsCalendarFill }}
                    OpenPickerButtonProps={{
                      style: {
                        fontSize: 17.1,
                        opacity: 0.46,
                        marginRight: '1px',
                        marginTop: '-6px',
                      },
                    }}
                    onChange={(date: any) => {
                      formik.setFieldValue(
                        'dateOfBirth',
                        (date as DateTime).toJSDate(),
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={`${commonClasses.whiteInputProps} ${classes.fixInput}`}
                        {...params}
                        {...(isDesktop
                          ? {}
                          : {
                              InputProps: {
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <BsCalendarFill
                                      style={{
                                        fontSize: 18.1,
                                        opacity: 0.46,
                                        marginRight: '1px',
                                        marginTop: '-5px',
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              },
                            })}
                        fullWidth
                      />
                    )}
                  />

                  <Stack
                    direction="column"
                    spacing={'16px'}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stack
                      width={'100%'}
                      direction="column"
                      spacing={'10px'}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h4" sx={{ padding: '10px 0' }}>
                        Update Password
                      </Typography>
                      {displayTextField({
                        name: 'currentPassword',
                        placeholder: 'Current password',
                        value: formik.values.currentPassword,
                        touched: formik.touched.currentPassword,
                        error: formik.errors.currentPassword,
                        fullWidth: true,
                        type: 'password',
                      })}
                      {displayTextField({
                        name: 'newPassword',
                        placeholder: 'New password',
                        value: formik.values.newPassword,
                        touched: formik.touched.newPassword,
                        error: formik.errors.newPassword,
                        fullWidth: true,
                        type: 'password',
                      })}
                      {displayTextField({
                        name: 'confirmPassword',
                        placeholder: 'Confirm new password',
                        value: formik.values.confirmPassword,
                        touched: formik.touched.confirmPassword,
                        error: formik.errors.confirmPassword,
                        fullWidth: true,
                        sx: { paddingBottom: 2.5 },
                        type: 'password',
                      })}
                    </Stack>
                    <Button
                      variant="outlined"
                      type="submit"
                      size="large"
                      className={classes.updatePasswordButton}
                      fullWidth
                    >
                      Update Account
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Container>
          </FormikProvider>
        </form>
      </Box>
    </>
  );
};

export default Profile;
