import React, { useRef } from 'react';
import LazyLoad from 'react-lazyload';
import useInView from 'react-cool-inview';

import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    borderRadius: '16px',
    position: 'relative',
    '@media only screen and (max-width: 670px)': { borderRadius: '8px' },
  },
  video: {
    borderRadius: '16px',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    '@media only screen and (max-width: 670px)': { borderRadius: '8px' },
  },
});

interface Props {
  mp4: string;
  webm?: string;
  poster: string;
  backgroundColor: string;
  autoPlay?: boolean;
  className?: string;
}

export const VideoBox: React.FC<Props> = ({
  mp4,
  poster,
  backgroundColor,
  autoPlay = false,
  className,
}) => {
  const classes = useStyles();
  const ref = useRef<any>();
  const { observe } = useInView({
    onEnter: () => {
      ref.current?.play();
    },
    onLeave: () => {
      ref.current?.pause();
    },
  });

  return (
    <div
      style={{ backgroundColor }}
      ref={observe}
      className={`${classes.container} ${className}`}
    >
      <LazyLoad height="100%" offset={800}>
        <video
          ref={ref}
          style={{ backgroundColor }}
          className={classes.video}
          autoPlay={autoPlay}
          playsInline
          loop
          poster={poster}
          preload="auto"
          muted
        >
          <source src={mp4} type="video/mp4" />
        </video>
      </LazyLoad>
    </div>
  );
};

export default VideoBox;
