import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import React, { useMemo } from 'react';
import RequestCircleProgress from 'src/components/requests/RequestCircleProgress';
import { createUseStyles } from 'react-jss';
import { Request } from 'src/models/request';
import { EventTicket, Ticket } from 'src/models/ticket';
import { useIsBigScreen } from 'src/hooks/useBigScreen';
import { Event } from '../../models/event';
import { Box, Container, styled } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  width: '10rem',
  borderRadius: 5,

  border: '1px solid #F7B500',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#FFFFFF',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#F7B500' : '#F7B500',
  },
}));
const useStyles = createUseStyles({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '130px',
    fontSize: '',
  },
  paid: {
    fontSize: '20px',
    fontWeight: 800,
  },
  approved: {
    fontSize: '17px',
    fontWeight: 500,
  },
});

type RequestNumberByTicketId = { [ticketId: string]: number };

interface Props {
  requests: Ticket[] | null;
  event: Event | null;
}
const RequestsCheckIn: React.VFC<Props> = ({ requests, event }) => {
  const classes = useStyles();
  const isBigScreen = useIsBigScreen();
  const getPaidRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.used || !request.available) {
        return accumulator;
      }
      if (request.idTicket != undefined) {
        accumulator[request.idTicket] =
          (accumulator[request.idTicket] ?? 0) + 1;
      }

      return accumulator;
    }, {});

  const getApprovedRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.available) {
        return accumulator;
      }
      if (request.idTicket != undefined) {
        accumulator[request.idTicket] =
          (accumulator[request.idTicket] ?? 0) + 1;
      }
      return accumulator;
    }, {});

  const getRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (request.idTicket != undefined) {
        accumulator[request.idTicket] =
          (accumulator[request.idTicket] ?? 0) + 1;
      }
      return accumulator;
    }, {});

  const getTotalPaidRequest = () =>
    Object.values(paidRequestByTicketId ?? {}).reduce(
      (accumulator, value) => accumulator + value,
      0,
    );
  const getTotalApprovedRequest = () =>
    Object.values(approvedRequestByTicketId ?? {}).reduce(
      (accumulator, value) => accumulator + value,
      0,
    );

  const paidRequestByTicketId = useMemo(
    () => getPaidRequestsNumberByTicketId(),
    [requests],
  );
  const approvedRequestByTicketId = useMemo(
    () => getApprovedRequestsNumberByTicketId(),
    [requests],
  );

  const requestsByTicketId = useMemo(
    () => getRequestsNumberByTicketId(),
    [requests],
  );

  const totalPaidApprovedRequestRatio = useMemo(() => {
    const totalApprovedRequests = getTotalApprovedRequest();
    const totalPaidRequest = getTotalPaidRequest();
    return totalApprovedRequests === 0
      ? 0
      : (totalPaidRequest / totalApprovedRequests) * 100;
  }, [paidRequestByTicketId, approvedRequestByTicketId]);

  const totalPaidRequests = useMemo(
    () => getTotalPaidRequest(),
    [paidRequestByTicketId],
  );

  const totalApprovedRequests = useMemo(
    () => getTotalApprovedRequest(),
    [approvedRequestByTicketId],
  );

  const displayPaidApprovedRatio = (ticket: EventTicket, index: number) => {
    if (ticket.id) {
      return (
        <Grid item xs={6} key={index}>
          <Stack direction="row" spacing={5}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ width: '100%', mr: 1, paddingTop: 2 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    approvedRequestByTicketId?.[ticket.id] === 0 ||
                    !ticket.id ||
                    !paidRequestByTicketId?.[ticket.id] ||
                    !approvedRequestByTicketId?.[ticket.id]
                      ? 0
                      : (paidRequestByTicketId[ticket.id] /
                          approvedRequestByTicketId[ticket.id]) *
                        100
                  }
                />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="#F7B500">{`${Math.round(
                  approvedRequestByTicketId?.[ticket.id] === 0 ||
                    !ticket.id ||
                    !paidRequestByTicketId?.[ticket.id] ||
                    !approvedRequestByTicketId?.[ticket.id]
                    ? 0
                    : (paidRequestByTicketId[ticket.id] /
                        approvedRequestByTicketId[ticket.id]) *
                        100,
                )}%`}</Typography>
              </Box>
            </Box>

            <div className={classes.centered}>
              <Stack direction="row" spacing={1}>
                <Typography fontSize={{ xs: 13, xl: 18 }} fontWeight={800}>
                  {ticket.name}{' '}
                </Typography>
              </Stack>
            </div>
          </Stack>
        </Grid>
      );
    }
  };

  return (
    <Stack direction="column" marginLeft={3} spacing={2}>
      <Typography
        variant="size3"
        textAlign="start"
        fontWeight={800}
        display="block"
      >
        Admission Check-in / Sold
      </Typography>
      <Grid container spacing={0} paddingLeft={2}>
        {event?.tickets?.map(displayPaidApprovedRatio)}
        {event?.onDoorTickets?.map(displayPaidApprovedRatio)}
      </Grid>
    </Stack>
  );
};

export default RequestsCheckIn;
