export const getCurrentUrl = () => {
  const currentUrl = window.location.href;
  return currentUrl.substr(currentUrl.lastIndexOf('/'), currentUrl.length);
};

export const getDateofweek = () => {
  const curr = new Date(); // get current date
  const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
  const last = first + 6; // last day is the first day + 6

  const firstday = new Date(curr.setDate(first));
  const lastday = new Date(curr.setDate(last));

  return {
    first: firstday,
    last: lastday,
  };
};

export const getdateofmonth = () => {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return {
    first: firstDay,
    last: lastDay,
  };
};
