import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import Landing from 'src/pages/Landing';
import { Layout } from 'src/containers/Layout';
import Profile from 'src/pages/Profile';
import { useAppSelector } from 'src/hooks/store';
import EventsPage from 'src/pages/EventsPage';
import EventPage from 'src/pages/EventPage';
import PurchasedTicketsPage from 'src/pages/PurchasedTicketsPage';
import RequestSent from 'src/components/tickets/ReserveTicket/RequestSent';
import ThanksForPurchase from 'src/components/tickets/ThanksForPurchase';
import { useQueryParam, StringParam } from 'use-query-params';
import RequestApproved from '../pages/RequestApproved';
import { PaymentProcessing } from 'src/pages/PaymentProcessing';
import { Aboutus } from 'src/pages/AboutUs';
import { Terms } from 'src/pages/Terms';
import { PrivacyPolicy } from 'src/pages/PrivacyPolicy';
import FullscreenSpin from 'src/components/FullscreenSpin';

export const ClientSwitch: React.VFC = () => {
  const history = useHistory();
  const [requestId] = useQueryParam('requestId', StringParam);
  const { purchasedTicketsFormState, user, signUpToBuyTicketState } =
    useAppSelector(
      ({
        ticket: { purchasedTicketsFormState, signUpToBuyTicketState },
        authentication: { user },
      }) => ({
        purchasedTicketsFormState,
        user,
        signUpToBuyTicketState,
      }),
    );
  useEffect(() => {
    if (purchasedTicketsFormState && !signUpToBuyTicketState?.isWaiting) {
      history.push(`/events/${purchasedTicketsFormState.eventId}`);
    }
  }, [user]);

  return (
    <Layout>
      <Switch>
        <Route exact path="/tac-app" component={Landing} />
        <Route
          exact
          path="/sign-in"
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={(_) =>
            requestId ? (
              <Redirect to={`/request/${requestId}`} />
            ) : (
              <Redirect to={`/events`} />
            )
          }
        />
        <Route
          exact
          path="/signUp"
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          render={(_) =>
            requestId ? (
              <Redirect to={`/request/${requestId}`} />
            ) : (
              <Redirect to={`/events`} />
            )
          }
        />
        <Route exact path="/events" component={FullscreenSpin} />
        <Route path="/events/:id/:password?" component={EventPage} />

        <Route
          exact
          path="/purchased-tickets"
          component={PurchasedTicketsPage}
        />
        <Route path="/request/:id" component={RequestApproved} />
        <Route path="/request-sent" component={RequestSent} />
        <Route path="/thanks-for-purchase" component={ThanksForPurchase} />
        <Route exact path="/profile" component={Profile} />
        <Route path="/payment-processing" component={PaymentProcessing} />
        <Route exact path="/about-us" component={Aboutus} />
        <Route exact path="/terms-and-conditions" component={Terms} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Redirect to="/events" />
      </Switch>
    </Layout>
  );
};
