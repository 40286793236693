import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Stack,
  MenuItem,
  Snackbar,
  Alert,
  Grid,
  useMediaQuery,
  Select,
  InputAdornment,
} from '@mui/material';
import { Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { resetError, signUp } from 'src/slices/authentication';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { UserGender } from 'src/models/user';
import { useHistory } from 'react-router-dom';
import DatePicker from '@mui/lab/DatePicker';
import { DateTime } from 'luxon';
import { commonStyles } from 'src/constants/common-styles';
import { Box } from '@mui/system';
import PhoneInput from 'react-phone-input-2';
import { BsCalendarFill } from 'react-icons/all';
import FooterBar from '../FooterBar';
import Loadable from 'react-loadable';

import Helmet from 'react-helmet';
import { last } from 'lodash';

const useStyles = createUseStyles(() => ({
  container: {
    '@media (min-width: 900px)': {
      marginTop: '10px',
    },
  },
  signUpHeader: {
    fontWeight: 800,
    color: 'black',
    fontSize: 45,
    lineHeight: '64px',
    '@media screen and (max-width: 900px)': {
      marginTop: '70px',
    },
  },
  buttonSignup: {
    color: 'black',
    borderRadius: '300px',
    border: '2px solid black',
    borderColor: 'black',
    width: '313.2px',
    height: '45px',
    textTransform: 'none',
    fontSize: '17.2px',
    '&:hover': {
      background: 'black',
      color: 'white',
    },
  },
  buttoncontinue: {
    color: 'white',
    borderRadius: '300px',
    width: '313.2px',
    height: '45px',
    fontSize: '17.2px',
    backgroundColor: 'black',
    textTransform: 'none',
    '&:hover': {
      border: '2px solid black',
      color: 'black',
      backgroundColor: 'white',
    },
  },
  Logo: {
    width: '20%',
    height: '20%',
  },
  menubutton: {
    color: 'black',
    height: '10%',
    width: '10%',
  },
  fixInput: {
    maxWidth: '100%',
    height: '45px',
    '& .MuiOutlinedInput-input': {
      padding: '10.7px 19.7px',
    },
    [`& fieldset`]: { border: 'none' },
  },
  signUpDesc: {
    color: 'black',
    marginTop: '-8px !important',
    marginLeft: '3px !important',
    fontWeight: 500,
    fontSize: 17.2,
  },
  phoneInput: {
    borderRadius: '50px !important',
    width: '100% !important',
    height: '45px',
    border: 'solid 2px #F0F0F0 !important',
    '&:focus': {
      boxShadow: 'none !important',
    },
  },
  phoneInputButton: {
    marginLeft: '10px',
  },
}));
const Signup = () => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const isDesktop = useMediaQuery('(min-width: 500px)');
  const dispatch = useAppDispatch();
  const { loading, signError, user } = useAppSelector(
    ({ authentication: { loading, signError, user } }) => ({
      loading,
      signError,
      user,
    }),
  );
  const [email, setEmail] = useState('');
  const [password, setpassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>(new Date());
  const [gender, setGender] = useState<UserGender | undefined>();
  const [confirmPassword, Setconfirmation] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [requiredError, setRequiredError] = useState(false);
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 900px)');

  const onSignedUp = () => {
    history.push('/');
  };

  useEffect(() => {
    if (signError) {
      setDisplayError(true);
      dispatch(resetError());
    } else if (!loading && user) {
      onSignedUp();
    }
  }, [signError, loading, user]);

  const onClicksignUp = () => {
    if (
      !phoneNumber ||
      !firstName ||
      !lastName ||
      !email ||
      !password ||
      !confirmPassword ||
      !gender
    ) {
      setRequiredError(true);
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError(true);
      return;
    }

    dispatch(
      signUp({
        username: email,
        password,
        firstName,
        name: firstName + ' ' + lastName,
        lastName,
        email,
        dateOfBirth,
        gender,
        isPlanner: true,
        phoneNumber,
      }),
    );
  };

  const onClicksignIn = () => {
    history.push('/eventplanner');
  };

  return (
    <Box
      padding={2}
      maxWidth="330px"
      marginX="auto"
      className={classes.container}
    >
      <Helmet>
        <script>
          {`
            window.Intercom("boot", {
  api_base: "https://api-iam.intercom.io",
  app_id: "okyuu12q"
});
          `}
        </script>
      </Helmet>
      <Stack
        direction="column"
        spacing={1.5}
        justifyContent="center"
        width={isDesktop ? '313.2px' : '100%'}
      >
        <Stack direction="column" justifyContent="center">
          <Typography className={classes.signUpHeader}>Sign up</Typography>
          <Typography className={classes.signUpDesc}>Event Planner</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <TextField
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First name"
            InputProps={{ className: commonClasses.whiteInputProps }}
            className={commonClasses.Input}
          />
          <TextField
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last name"
            InputProps={{ className: commonClasses.whiteInputProps }}
            className={commonClasses.Input}
          />
        </Stack>
        <PhoneInput
          country={'eg'}
          value={phoneNumber}
          inputClass={classes.phoneInput}
          buttonClass={classes.phoneInputButton}
          placeholder=""
          onChange={(phone) => setPhoneNumber(phone)}
        />
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          InputProps={{ className: commonClasses.whiteInputProps }}
          className={commonClasses.Input}
        />
        <Stack direction="row" width="100%">
          <Grid container width="100%">
            <Grid item xs={12} md={12}>
              <Stack justifyContent="flex-start" alignItems="center">
                <Select
                  value={gender}
                  className={`${commonClasses.whiteInputProps} ${classes.fixInput}`}
                  displayEmpty
                  onChange={(event) => {
                    setGender(event.target.value as UserGender);
                  }}
                  sx={{ width: '100%' }}
                  renderValue={
                    gender !== undefined
                      ? undefined
                      : () => <div style={{ opacity: 0.4 }}>Gender</div>
                  }
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <TextField
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setpassword(e.target.value)}
          InputProps={{ className: commonClasses.whiteInputProps }}
          className={commonClasses.Input}
        />
        <TextField
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => Setconfirmation(e.target.value)}
          InputProps={{ className: commonClasses.whiteInputProps }}
          className={commonClasses.Input}
        />
        <Stack alignItems="center" spacing={3}>
          <Button
            onClick={onClicksignUp}
            variant="outlined"
            className={classes.buttoncontinue}
            size="large"
            fullWidth
          >
            Create account
          </Button>
          <Stack alignItems="center">
            <Typography variant="h5">Or</Typography>
          </Stack>
          <Button
            onClick={onClicksignIn}
            variant="outlined"
            className={classes.buttonSignup}
            size="large"
            fullWidth
          >
            Sign In
          </Button>
        </Stack>
        <Snackbar
          open={displayError}
          autoHideDuration={6000}
          onClose={() => setDisplayError(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setDisplayError(false)}
            severity="error"
            sx={{ width: '100%' }}
          >
            Sign up failed, email or phone number already in use
          </Alert>
        </Snackbar>
        <Snackbar
          open={requiredError}
          autoHideDuration={6000}
          onClose={() => setRequiredError(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setRequiredError(false)}
            severity="error"
            sx={{ width: '100%' }}
          >
            Please fill in all the fields and try again
          </Alert>
        </Snackbar>
        <Snackbar
          open={passwordError}
          autoHideDuration={6000}
          onClose={() => setPasswordError(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setPasswordError(false)}
            severity="error"
            sx={{ width: '100%' }}
          >
            Passwords do not match
          </Alert>
        </Snackbar>
      </Stack>
      {isMobile && (
        <>
          <div style={{ height: '20px' }} />
          <FooterBar />
        </>
      )}
    </Box>
  );
};
export default Signup;
