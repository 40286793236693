import React, { useState } from 'react';
import {
  BarChart,
  Bar,
  YAxis,
  Tooltip,
  XAxis,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import { Event } from 'src/models/event';
import { Typography, Box } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { TicketsNumberByDay } from './EventPlannerCard';
import { useIsBigScreen } from 'src/hooks/useBigScreen';

interface Props {
  event: Event;
  ticketNumberByDay?: TicketsNumberByDay;
}

const useStyles = createUseStyles({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function renderTooltip() {
  return <div>Custom content</div>;
}

const EventBarChartCard: React.VFC<Props> = ({ ticketNumberByDay }) => {
  const classes = useStyles();
  const isBigScreen = useIsBigScreen();
  const [hoveredBarIndex, setHoveredBarIndex] = useState<number | undefined>();

  if (
    !ticketNumberByDay ||
    Object.values(ticketNumberByDay).reduce(
      (accumulator, current) => accumulator + current,
      0,
    ) === 0
  ) {
    return (
      <>
        <Box width="100%" height="300px" display="flex" justifyContent="center">
          <Typography
            className={classes.centered}
            fontSize={{ xs: 19.2, xl: 24 }}
            fontWeight={700}
          >
            No tickets sold yet
          </Typography>
        </Box>
      </>
    );
  }

  const data = Object.entries(ticketNumberByDay)
    .map(([day, number]) => ({
      name: new Date(day).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
      }),
      value: number,
    }))
    .sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime());
  return (
    <ResponsiveContainer width="100%" height={isBigScreen ? 300 : 240}>
      <BarChart
        data={data}
        barCategoryGap="15%"
        barSize={10}
        maxBarSize={10}
        margin={{
          left: 20,
          bottom: 5, //0,
        }}
        onMouseMove={(state) => {
          if (state.isTooltipActive) {
            setHoveredBarIndex(state.activeTooltipIndex);
          } else {
            setHoveredBarIndex(undefined);
          }
        }}
      >
        <XAxis
          dataKey="name"
          fontSize={isBigScreen ? '14px' : '11.2px'}
          fontWeight="bold"
          color="grey"
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          fontSize={isBigScreen ? '14px' : '11.2px'}
          fontWeight="bold"
          color="grey"
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          cursor={{
            fill:
              hoveredBarIndex && data[hoveredBarIndex].value === 0
                ? 'lightgrey'
                : 'none',
          }}
          labelFormatter={(info) => 'Tickets ' + info}
        />

        {/* TODO see if client wants */}
        {/* <ReferenceLine y={0} stroke="#000" />
        <Brush
          dataKey="name"
          height={15}
          stroke="#F7B500"
          endIndex={30}
          travellerWidth={0}
        /> */}
        <Bar dataKey="value" fill="grey">
          {data.map((_, index) => (
            <Cell
              key={index}
              fill={hoveredBarIndex === index ? '#F7B500' : 'grey'}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EventBarChartCard;
