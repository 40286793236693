import React from 'react';
import { createUseStyles } from 'react-jss';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as TechnoCalendar } from '../../../assets/icons/features/technoCalendar.svg';
import { ReactComponent as Dj } from '../../../assets/icons/newsFeed/dj.svg';
import { ReactComponent as Content } from '../../../assets/icons/newsFeed/content.svg';
import { Typography } from '@mui/material';

const styles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px',
    borderRadius: ' 16px',
    textAlign: 'center',
    backgroundColor: '#F7F7F7',
    width: 271.35,
    height: 271.35,
    '@media only screen and (max-width: 646px)': {
      boxSizing: 'border-box',
      borderRadius: '8px',
      padding: '16px',
      width: '95%',
      height: '100%',
    },
    '&svg': {
      '@media only screen and (max-width: 646px)': {
        width: '40px',
        height: '40px',
      },
    },
  },
  title: {
    color: '#212121',
    fontSize: 20,
    lineHeight: '0px',
    fontWeight: 800,
    margin: '16px 0',
    '@media only screen and (max-width: 670px)': {
      fontSize: '18px',
      lineHeight: '20px',
    },
  },
  text: {
    color: 'black',
    fontSize: 17,
    width: 130,
    lineHeight: '20px',
    fontWeight: 500,
    '@media only screen and (max-width: 670px)': {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  svgIcon: {
    width: 114.35,
    height: 114.35,
    marginBottom: 18.9,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    '@media screen and (max-width: 425px)': {
      width: '60px',
      height: '60px',
      marginTop: 14,
    },
  },
});

interface Props {
  title: string;
  icon: 'dj' | 'technoCalendar' | 'content';
  className?: string;
  children: any;
}

export const RegularBox: React.FC<Props> = ({
  title,
  icon,
  children,
  className,
}) => {
  const classes = styles();
  const getIcon = () => {
    switch (icon) {
      case 'technoCalendar':
        return TechnoCalendar;
      case 'dj':
        return Dj;
      case 'content':
        return Content;
      default:
        return Dj;
    }
  };
  return (
    <div className={`${classes.container} ${className}`}>
      <SvgIcon
        component={getIcon()}
        className={classes.svgIcon}
        inheritViewBox
      />
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.text}>{children}</Typography>
    </div>
  );
};

export default RegularBox;
