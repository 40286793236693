import { Grid, Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import Donut from 'src/components/event/eventCircleProgress';
import { useIsBigScreen } from 'src/hooks/useBigScreen';
import { Event } from 'src/models/event';
import { EventTicket } from 'src/models/ticket';

interface Props {
  event: Event;
}

const useStyles = createUseStyles({
  card: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    height: '100px',
  },
  img: {
    borderRadius: '12.5px',
    height: '250px',
    maxWidth: '250px',
    minWidth: '250px',
    objectFit: 'cover',
  },
  title: {
    fontSize: '20px',
    fontWeight: 800,
    color: '#000',
  },
  subtitle: {
    fontSize: '1.2rem',
    color: '#000',
  },
  subtitle1: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#000',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  ticketSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  ticketName: {
    paddingLeft: 10,
  },
  parent: {
    border: '1px ',
    textAlign: 'start',
    fontSize: '1.2rem',
    color: '#000',
  },
});

const EventAdmissionsCard: React.VFC<Props> = ({ event }) => {
  const classes = useStyles();
  const isBigScreen = useIsBigScreen();

  const getSoldTicketPercentage = (
    soldTickets: number | undefined,
    totalTickets: number | undefined,
  ) => (soldTickets && totalTickets ? (soldTickets / totalTickets) * 100 : 0);

  const getTotalTicketsPercentage = (
    ticketsSold: number,
    ticketsNumber: number,
  ) => (ticketsSold / ticketsNumber) * 100;

  const getTotalTicketsNumber = (tickets: EventTicket[]) =>
    tickets.reduce(
      (accumulator, ticket) => accumulator + (ticket.total ?? 0),
      0,
    );

  const getTotalTicketsSold = (tickets: EventTicket[]) =>
    tickets.reduce(
      (accumulator, ticket) => accumulator + (ticket.sold ?? 0),
      0,
    );

  const totalTicketsNumber = useMemo<number>(
    () =>
      getTotalTicketsNumber(
        event?.tickets?.filter((ticket) => !ticket.onInvitation) ?? [],
      ),
    [event],
  );

  const totalTicketsSold = useMemo<number>(
    () =>
      getTotalTicketsSold(
        event?.tickets?.filter((ticket) => !ticket.onInvitation) ?? [],
      ),
    [event],
  );

  const totalTicketsPercentage = useMemo<number>(() => {
    return totalTicketsSold <= totalTicketsNumber && totalTicketsSold > 0
      ? getTotalTicketsPercentage(totalTicketsSold, totalTicketsNumber)
      : 0;
  }, [totalTicketsSold, totalTicketsNumber]);

  const buildTicketSection = (ticket: EventTicket) => {
    const soldTicketsPercentage = getSoldTicketPercentage(
      ticket.sold,
      ticket.total,
    );
    return (
      <Grid item xs={12} key={ticket.name} className={classes.ticketSection}>
        <div>
          <Donut
            size={isBigScreen ? 50 : 40}
            value={soldTicketsPercentage}
            color={soldTicketsPercentage > 0 ? 'orange' : 'black'}
            fontSize="size5"
          />
        </div>
        <div className={classes.ticketName}>
          <Box sx={{ marginLeft: 0.5 }}>
            <Typography
              sx={{ fontSize: { xs: 10.4, xl: 13 }, fontWeight: 500 }}
            >
              {ticket.name}
            </Typography>
            <p>
              <Typography
                display="inline"
                sx={{ fontSize: { xs: 12, xl: 15 }, fontWeight: 'bold' }}
              >
                {ticket.sold ?? 0}
              </Typography>
              <Typography
                display="inline"
                sx={{ fontSize: { xs: 8, xl: 10 }, fontWeight: 500 }}
              >
                /{ticket.total}
              </Typography>
            </p>
          </Box>
        </div>
      </Grid>
    );
  };

  return (
    <Grid container rowSpacing={2} paddingTop={2.2}>
      <Grid item xs={12}>
        <div className={classes.title}>{event.name}</div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.parent}>
          <Typography
            sx={{ fontSize: { xs: 16, xl: 20 } }}
            display="inline"
            fontWeight={800}
          >
            Admissions Sold{' '}
          </Typography>
          <Typography sx={{ fontSize: { xs: 13.6, xl: 17 } }} display="inline">
            / Total Available
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
            <div className={classes.ticketSection}>
              <div>
                <Donut
                  size={isBigScreen ? 80 : 64}
                  value={totalTicketsPercentage}
                  color={totalTicketsPercentage > 0 ? 'orange' : 'black'}
                  fontSize={'size4'}
                />
              </div>
              <div className={classes.ticketName}>
                <Box sx={{ m: 1.5 }}>
                  <Typography
                    sx={{ fontSize: { xs: 13.6, xl: 17 } }}
                    display="inline"
                    fontWeight={500}
                  >
                    Total
                  </Typography>
                  <p>
                    <Typography
                      sx={{ fontSize: { xs: 13.8, xl: 18 } }}
                      fontWeight="bold"
                      display="inline"
                    >
                      {totalTicketsSold}
                    </Typography>
                    <Typography variant="size6" fontWeight={500}>
                      /{totalTicketsNumber}
                    </Typography>
                  </p>
                </Box>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={8}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-start"
              rowSpacing={2}
            >
              {event.tickets
                ?.filter((ticket) => !ticket?.onInvitation)
                .map(buildTicketSection)}
              {event.isPackages &&
                event.packages
                  ?.slice(0, 4)
                  .filter((ticket) => !ticket?.onInvitation)
                  .map(buildTicketSection)}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventAdmissionsCard;
