import React from 'react';
import { Box, Typography } from '@mui/material';
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress';

interface DonutProps {
  value: any;
  size: number;
  color: string;
  fontSize: any;
  isInvitation?: boolean;
}

const Donut: React.FunctionComponent<DonutProps> = ({
  value,
  size,
  color,
  fontSize,
  isInvitation = false,
}) => {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={size}
        thickness={2}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.mode === 'light' ? '#F7B500' : '#F7B500',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={size}
        value={value}
        thickness={2}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant={fontSize} color={color}>{`${Math.round(value)}${
          isInvitation ? '' : '%'
        }`}</Typography>
      </Box>
    </Box>
  );
};
export default Donut;
