import React from 'react';

import { createUseStyles } from 'react-jss';
import {
  Divider,
  Box,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Mail from '../assets/icons/mail.png';
import { ReactComponent as Copyright } from '../assets/icons/copyright.svg';
import Facebook from '../assets/icons/facebook.png';
import Instagram from '../assets/icons/instagram.png';
import Twitter from '../assets/icons/twitter.png';
import Youtube from '../assets/icons/youtube.png';

const useStyles = createUseStyles({
  container: {
    bottom: 0,

    width: '100%',
  },
  colorContainer: {
    display: 'flex',
    width: '100%',
    height: 16,
  },
  bar: { width: '14.28%', height: 16, '& > :last-child': { flex: 1 } },
  c1: { backgroundColor: '#14C596' },
  c2: { backgroundColor: '#F7B403' },
  c3: { backgroundColor: '#FFB656' },
  c4: { backgroundColor: '#F1446C' },
  c5: { backgroundColor: '#FC698B' },
  c6: { backgroundColor: '#6BA8E0' },
  c7: { backgroundColor: '#4E8AC2' },
  svgIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    cursor: 'pointer',
    width: 32,
    height: 32,
    '@media screen and (max-width: 900px)': {
      width: 18,
      height: 18,
    },
  },
  copyrightText: {
    fontSize: 15,
    fontWeight: 700,
    color: 'black',
    opacity: 0.15,
    '@media screen and (max-width: 900px)': {
      fontSize: 13,
      textAlign: 'center',
    },
  },
});

interface Props {
  className?: string;
  normal?: boolean;
  addedHeight?: string;
}

export const FooterBar: React.FC<Props> = ({
  className,
  normal = false,
  addedHeight,
}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:900px)');
  const renderSocial = () => (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={'13.81px'}
      paddingBottom={isMobile ? '26px' : 0}
    >
      {[
        {
          icon: Mail,
          onClick: () =>
            window.open('mailto:tickets@makanak.app', '_blank'),
        },
        {
          icon: Youtube,
          onClick: () =>
            window.open(
              'https://www.youtube.com/channel/UCdU9rN3I30uaaqD5z88zEBA?themeRefresh=1',
              '_blank',
            ),
        },
        {
          icon: Twitter,
          onClick: () =>
            window.open('https://twitter.com/', '_blank'),
        },
        {
          icon: Facebook,
          onClick: () =>
            window.open('https://www.facebook.com/', '_blank'),
        },
        {
          icon: Instagram,
          onClick: () =>
            window.open(' https://www.instagram.com//', '_blank'),
        },
      ].map(({ icon, onClick }, index) => (
        <img
          src={icon}
          key={index}
          className={classes.svgIcon}
          style={{
            width: 26,
            height: 26,
          }}
          alt=""
          onClick={onClick}
        />
      ))}
    </Stack>
  );

  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={'19px'}
        className={normal ? '' : `${classes.container} ${className}`}
        paddingBottom={addedHeight}
      >
        {
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={isMobile ? '26px' : '10px'}
            width={'100%'}
          >
            <Divider
              style={{
                marginBottom: 0,
                width: isMobile ? '90%' : '865px',
              }}
            />
            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent="center"
              alignItems="center"
              spacing={isMobile ? '18px' : '319.25px'}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={isMobile ? '7.5px' : '37.5px'}
              >
                <SvgIcon
                  component={Copyright}
                  className={classes.svgIcon}
                  inheritViewBox
                />
                <Typography align="center" className={classes.copyrightText}>
                  2023 Makanak App LLC. All rights reserved.
                </Typography>
              </Stack>

              {renderSocial()}
            </Stack>
          </Stack>
        }
        {!isMobile && (
          <div className={classes.colorContainer}>
            <div className={`${classes.bar} ${classes.c1}`} />
            <div className={`${classes.bar} ${classes.c2}`} />
            <div className={`${classes.bar} ${classes.c3}`} />
            <div className={`${classes.bar} ${classes.c4}`} />
            <div className={`${classes.bar} ${classes.c5}`} />
            <div className={`${classes.bar} ${classes.c6}`} />
            <div className={`${classes.bar} ${classes.c7}`} />
          </div>
        )}
        {normal && <div style={{ height: 50 }} />}
      </Stack>
    </Box>
  );
};

FooterBar.displayName = 'FooterBar';

export default FooterBar;
