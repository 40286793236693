import {
  CardMedia,
  TextField,
  Grid,
  Box,
  Stack,
  Typography,
  Button,
  SvgIcon,
} from '@mui/material';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Request } from 'src/models/request';
import { Event } from 'src/models/event';
import SearchIcon from '@mui/icons-material/Search';
import { DateTime } from 'luxon';
import { ReactComponent as Tickets } from '../../assets/ticketLogo.svg';
import { ReactComponent as Calendar } from '../../assets/CalendarRequests.svg';
import { ReactComponent as identification } from '../../assets/identification.svg';
import { ReactComponent as PrintKing } from '../../assets/PrintKing.svg';
import { IoFingerPrint } from 'react-icons/all';
import { Container } from '@mui/system';
import { EventTicket } from 'src/models/ticket';
import Donut from '../event/eventCircleProgress';
import { useIsBigScreen } from 'src/hooks/useBigScreen';
import RequestCircleProgress from './RequestCircleProgress';

const useStyles = createUseStyles({
  button: {
    color: 'black',
    borderRadius: '30px',
    cursor: 'pointer',
    width: '22vw',
    height: '10vw',
    backgroundColor: '#F8F8F7',
    fontSize: '16px',
    justifyContent: 'center',
    textTransform: 'none',
    '&:hover': {
      color: 'Black',
      backgroundColor: '#F8F8F7',
    },
    '&:disabled': {
      opacity: 0.35,
    },
  },
  ButtonText: {
    fontSize: '12px',
    fontFamily: 'SF Pro Display Semibold',
  },
  ScannerPwTitle: {
    fontSize: '14px',
    fontFamily: 'SF Pro Display',
    fontWeight: 800,
  },
  ScannerPw: {
    fontSize: '30px',
    fontWeight: 800,
    fontFamily: 'SF Pro Display',
    marginLeft: '2vw',
  },
  Gross: {
    fontSize: '30px',
    fontWeight: 800,
    fontFamily: 'SF Pro Display',
    marginLeft: '2vw',
  },
  titleIcon: {
    width: '83px',
    height: '82px',
    maxWidth: '19px',
    maxHeight: '20px',
    '@media screen and (max-width: 1200px)': {
      width: '50px',
      height: '50px',
      fontSize: '32px',
    },
    '@media screen and (max-width: 900px)': {
      width: '40px',
      height: '40px',
      fontSize: '32px',
    },
  },

  PrintKing: {
    width: '83px',
    height: '82px',
    maxWidth: '22px',
    maxHeight: '22px',
    '@media screen and (max-width: 1200px)': {
      width: '50px',
      height: '50px',
      fontSize: '32px',
    },
    '@media screen and (max-width: 900px)': {
      width: '40px',
      height: '40px',
      fontSize: '32px',
    },
  },
  allign: {
    marginTop: '5px',
    alignSelf: 'flex-start',
  },
});
type RequestNumberByTicketId = { [ticketId: string]: number };

interface Props {
  event: Event;
  requests: Request[] | null;
}
const RequestsBottomBarMobile: React.VFC<Props> = ({ requests, event }) => {
  const classes = useStyles();
  const isBigScreen = useIsBigScreen();
  const getTotalPrice = (tickets: EventTicket[]) => {
    return tickets.reduce(
      (accumulator: number, ticket: EventTicket) =>
        accumulator +
        ((ticket.price ?? 0) + (ticket.fees ?? 0)) * (ticket.sold ?? 0),
      0,
    );
  };
  const ticketsTotalPrice = useMemo<number>(
    () => getTotalPrice(event.tickets ?? []),
    [event],
  );
  const getTotalTicketsNumber = (tickets: EventTicket[]) =>
    tickets.reduce(
      (accumulator, ticket) => accumulator + (ticket.total ?? 0),
      0,
    );
  const totalTicketsNumber = useMemo<number>(
    () =>
      getTotalTicketsNumber(
        event?.tickets?.filter((ticket) => !ticket.onInvitation) ?? [],
      ),
    [event],
  );
  const getTotalTicketsSold = (tickets: EventTicket[]) =>
    tickets.reduce(
      (accumulator, ticket) => accumulator + (ticket.sold ?? 0),
      0,
    );
  const totalTicketsSold = useMemo<number>(
    () =>
      getTotalTicketsSold(
        event?.tickets?.filter((ticket) => !ticket.onInvitation) ?? [],
      ),
    [event],
  );
  const getTotalTicketsPercentage = (
    ticketsSold: number,
    ticketsNumber: number,
  ) => (ticketsSold / ticketsNumber) * 100;
  const totalTicketsPercentage = useMemo<number>(() => {
    return totalTicketsSold <= totalTicketsNumber && totalTicketsSold > 0
      ? getTotalTicketsPercentage(totalTicketsSold, totalTicketsNumber)
      : 0;
  }, [totalTicketsSold, totalTicketsNumber]);
  const getPaidRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.paid) {
        return accumulator;
      }
      Object.entries(request.ticketsNumberById).forEach(
        ([ticketId, ticketNumber]) => {
          accumulator[ticketId] =
            (accumulator[ticketId] ?? 0) + (ticketNumber ?? 0);
        },
      );
      return accumulator;
    }, {});

  const getApprovedRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.isApproved) {
        return accumulator;
      }
      Object.entries(request.ticketsNumberById).forEach(
        ([ticketId, ticketNumber]) => {
          accumulator[ticketId] =
            (accumulator[ticketId] ?? 0) + (ticketNumber ?? 0);
        },
      );
      return accumulator;
    }, {});

  const getRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      Object.entries(request.ticketsNumberById).forEach(
        ([ticketId, ticketNumber]) => {
          accumulator[ticketId] =
            (accumulator[ticketId] ?? 0) + (ticketNumber ?? 0);
        },
      );
      return accumulator;
    }, {});

  const getTotalPaidRequest = () =>
    Object.values(paidRequestByTicketId ?? {}).reduce(
      (accumulator, value) => accumulator + value,
      0,
    );
  const getTotalApprovedRequest = () =>
    Object.values(approvedRequestByTicketId ?? {}).reduce(
      (accumulator, value) => accumulator + value,
      0,
    );

  const paidRequestByTicketId = useMemo(
    () => getPaidRequestsNumberByTicketId(),
    [requests],
  );
  const approvedRequestByTicketId = useMemo(
    () => getApprovedRequestsNumberByTicketId(),
    [requests],
  );

  const requestsByTicketId = useMemo(
    () => getRequestsNumberByTicketId(),
    [requests],
  );

  const totalPaidApprovedRequestRatio = useMemo(() => {
    const totalApprovedRequests = getTotalApprovedRequest();
    const totalPaidRequest = getTotalPaidRequest();
    return totalApprovedRequests === 0
      ? 0
      : (totalPaidRequest / totalApprovedRequests) * 100;
  }, [paidRequestByTicketId, approvedRequestByTicketId]);

  const totalPaidRequests = useMemo(
    () => getTotalPaidRequest(),
    [paidRequestByTicketId],
  );

  const totalApprovedRequests = useMemo(
    () => getTotalApprovedRequest(),
    [approvedRequestByTicketId],
  );

  const displayPaidApprovedRatio = (ticket: EventTicket, index: number) => {
    if (ticket.id) {
      return (
        <>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Grid item xs={6} display="flex" marginTop={'10px'}>
              <RequestCircleProgress
                size={70}
                value={
                  ticket?.onInvitation
                    ? requestsByTicketId?.[ticket.id] ?? 0
                    : approvedRequestByTicketId?.[ticket.id] === 0 ||
                      !ticket.id ||
                      !paidRequestByTicketId?.[ticket.id] ||
                      !approvedRequestByTicketId?.[ticket.id]
                    ? 0
                    : (paidRequestByTicketId[ticket.id] /
                        approvedRequestByTicketId[ticket.id]) *
                      100
                }
                color="black"
                fontSize={'size5'}
                isInvitation={ticket?.onInvitation}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Stack direction="column" alignItems="start">
                <Typography fontSize={15} fontFamily={'SF Pro Display'}>
                  {ticket.name}
                </Typography>
                {!ticket?.onInvitation ? (
                  <Typography>
                    <Typography fontSize={15} display="inline" fontWeight={700}>
                      {paidRequestByTicketId?.[ticket.id] ?? 0}
                    </Typography>
                    <Typography fontSize={15} display="inline" fontWeight={500}>
                      /{approvedRequestByTicketId?.[ticket.id] ?? 0}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography fontSize={15} display="inline" fontWeight={700}>
                    {requestsByTicketId?.[ticket.id] ?? 0}
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Stack>
        </>
      );
    }
  };

  const displayTotalPaidApprovedRatio = () => (
    <>
      <Stack direction={'row'} spacing={2} alignItems={'center'}>
        <Grid item xs={6} display="flex" marginTop={'10px'}>
          <RequestCircleProgress
            size={70}
            value={totalPaidApprovedRequestRatio}
            color="black"
            fontSize={'size5'}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <Stack direction="column" alignItems="start">
            <Typography fontSize={{ xs: 18.4, xl: 23 }} fontWeight={500}>
              Tickets Sold
            </Typography>
            <Typography>
              <Typography
                fontSize={{ xs: 17.5, xl: 22 }}
                display="inline"
                fontWeight={700}
              >
                {totalPaidRequests}
              </Typography>
              <Typography
                fontSize={{ xs: 12, xl: 15 }}
                display="inline"
                fontWeight={500}
              >
                /{totalApprovedRequests}
              </Typography>
            </Typography>
          </Stack>
        </Grid>
      </Stack>
    </>
  );
  return (
    <>
      <Stack direction={'column'}>
        <Stack direction={'row'} spacing={0.4} maxWidth={'80vw'}>
          <SvgIcon
            component={PrintKing}
            className={classes.PrintKing}
            inheritViewBox
          />
          <Typography className={classes.ScannerPwTitle}>
            Scanner password
          </Typography>
        </Stack>
        <Typography className={classes.ScannerPw}>
          {event.scannerCodePassword}
        </Typography>
      </Stack>
      <Stack direction={'column'}>
        <Stack direction={'row'} spacing={0.4} maxWidth={'80vw'}>
          <Typography marginLeft={'1vw'} className={classes.ScannerPwTitle}>
            Gross Sold
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'baseline'}>
          <Typography className={classes.Gross}>
            {' '}
            {Number(ticketsTotalPrice).toFixed(2)}
          </Typography>
          <Typography fontSize={{ xs: 14.4, xl: 18 }} fontWeight={800}>
            {event?.currency ?? '$'}
          </Typography>
        </Stack>
        <Grid item xs={12}>
          <Typography
            sx={{ fontSize: { xs: 16, xl: 20 } }}
            display="inline"
            fontWeight={800}
          >
            Admission Sold{' '}
          </Typography>
          <Typography sx={{ fontSize: { xs: 13.6, xl: 17 } }} display="inline">
            / Total Available
          </Typography>
        </Grid>
        <Stack direction={'column'} alignItems={'flex-start'} spacing={2}>
          {displayTotalPaidApprovedRatio()}
          {event?.tickets?.map(displayPaidApprovedRatio)}
        </Stack>
      </Stack>
    </>
  );
};

export default RequestsBottomBarMobile;
