import {
  createSlice,
  createAsyncThunk,
  SerializedError,
  PayloadAction,
} from '@reduxjs/toolkit';
import Parse from 'parse/node';
import {
  getFirstDayOfTheWeek,
  getLastDayOfTheWeek,
} from 'src/helpers/dateHelpers';
import { serializeEvent, serializeEvents } from 'src/helpers/serializeModels';
import { Event } from 'src/models/event';
import { EventTicket } from 'src/models/ticket';
import { v4 as uuidv4 } from 'uuid';
import { DayRange } from '@amir04lm26/react-modern-calendar-date-picker';
import { request } from 'http';

/* Event slice */

export type TimePeriod = 'ALL' | 'TODAY' | 'THIS WEEK';

interface EventState {
  plannerEvents: Event[] | null;
  searchedEvents: Event[] | null;
  currentlyDisplayedEvent: Event | null;
  eventCreated: boolean | null;
  eventsCountPerCity: EventsCountPerCity | null;
  isLoading: boolean;
  isEventsCountPerCityLoading: boolean;
  error: string | null;
  timePeriod: TimePeriod | undefined;
  dayRange: DayRange;
  plannerName: string[] | null;
  plannerId: string | null;
}

const initialState: EventState = {
  plannerEvents: null,
  plannerName: null,
  plannerId: null,
  searchedEvents: null,
  currentlyDisplayedEvent: null,
  eventCreated: null,
  eventsCountPerCity: null,
  isLoading: false,
  isEventsCountPerCityLoading: false,
  error: null,
  timePeriod: 'ALL',
  dayRange: {
    from: null,
    to: null,
  },
};

export interface CreateEventDto {
  city?: string;

  timeZone?: string;
  currencySymbol?: string;
  currency?: string;
  name?: string;
  coverPhoto?: File;
  ticketsExpireIn?: number;
  usePaymob?: boolean;
  start?: Date;
  end?: Date;

  addressName?: string;
  address?: string;

  dj?: string;
  djLink?: string;
  music?: string;

  infoRule2?: string; // minimum age
  infoRule?: string; // fashion

  description?: string;
  tickets?: EventTicket[];
  onReservation?: boolean;
}

export interface UpdateEventDto {
  city?: string;
  ticketsExpireIn?: number;
  usePaymob?: boolean;
  available?: boolean;

  timeZone?: string;
  currencySymbol?: string;
  currency?: string;
  name?: string;
  coverPhoto?: File;

  start?: Date;
  end?: Date;

  addressName?: string;
  address?: string;

  dj?: string;
  djLink?: string;
  music?: string;

  infoRule2?: string; // minimum age
  infoRule?: string; // fashion

  description?: string;
  tickets?: EventTicket[];
}

type EventsCountPerCity = {
  [city: string]: number;
};

interface SearchEventDto {
  timePeriod?: TimePeriod;
  date?: Date;
  endDate?: Date;
  cityId?: string;
}

interface getEventsCountPerCityDto {
  timePeriod?: TimePeriod;
  date?: Date;
  enDate?: Date;
}

/* Thunks */
export const createEvent = createAsyncThunk(
  'event/createEvent',
  async (createEventDto: CreateEventDto) => {
    try {
      let user = Parse.User.current();
      if (!user?.get('planner')) {
        user = await Parse.User.current()?.fetchWithInclude(['planner']);
      }
      const Event = Parse.Object.extend('Event');
      const City = Parse.Object.extend('City');
      const newEvent = new Event();
      const { start, end, city, coverPhoto, tickets, usePaymob, ...dto } =
        createEventDto;
      newEvent.set('start', start);
      newEvent.set('end', end);
      newEvent.set('city', City.createWithoutData(city));
      newEvent.set('planner', user?.get('planner'));
      newEvent.set('available', false);
      newEvent.set('state', 'not_active');
      newEvent.set('ticketsExpireIn', dto.ticketsExpireIn);
      newEvent.set('usePaymob', usePaymob);

      if (coverPhoto) {
        const file = new Parse.File(coverPhoto?.name, coverPhoto);
        newEvent.set('coverPhoto', file);
      }
      const ticketsWithIds = (tickets ?? []).map((ticket) => ({
        ...ticket,
        id: ticket.id ?? uuidv4(),
        onReservation: dto.onReservation,
        ticketsExpireIn: dto.ticketsExpireIn,
        usePaymob: usePaymob,
        takingRequest: dto.onReservation ? true : undefined,
        sold: 0,
      }));
      newEvent.set('tickets', ticketsWithIds);
      Object.entries(dto).map(([key, value]) => {
        newEvent.set(key, value);
      });
      return newEvent.save().then(serializeEvent);
    } catch (error) {
      console.log(error);
    }
  },
);

export const updateEvent = createAsyncThunk(
  'event/updateEvent',
  async ({
    id,
    updateEventDto,
  }: {
    id: string;
    updateEventDto: UpdateEventDto;
  }) => {
    const Event = Parse.Object.extend('Event');
    const City = Parse.Object.extend('City');
    const queryEvent = new Parse.Query(Event);
    queryEvent.equalTo('objectId', id);
    const event = await queryEvent.first();
    if (!event) {
      return;
    }
    const { start, end, city, coverPhoto, tickets, ...dto } = updateEventDto;
    event.set('start', start);
    event.set('end', end);
    event.set('description', dto.description);
    event.set('ticketsExpireIn', dto.ticketsExpireIn);
    event.set('usePaymob', dto.usePaymob);
    event.set('city', City.createWithoutData(city));
    if (coverPhoto) {
      const file = new Parse.File(coverPhoto?.name, coverPhoto);
      event.set('coverPhoto', file);
    }
    const ticketsWithIds = (tickets ?? []).map((ticket) => ({
      ...ticket,
      id: ticket.id ?? uuidv4(),
      onReservation: event.get('onReservation'),
      takingRequest: ticket.takingRequest ?? true,
      sold: ticket.sold ?? 0,
    }));
    event.set('tickets', ticketsWithIds);
    Object.entries(dto).map(([key, value]) => {
      event.set(key, value);
    });
    return event.save().then(serializeEvent);
  },
);

export const fetchPlannerEvents = createAsyncThunk(
  'event/fetchPlannerEvents',
  async () => {
    const Event = Parse.Object.extend('Event');
    const queryEvent = new Parse.Query(Event);
    const user = Parse.User.current();
    const plannerId = user?.get('planner');
    queryEvent.equalTo('planner', plannerId);
    queryEvent.descending('createdAt');
    try {
      const events = await queryEvent.find();
      return serializeEvents(events);
    } catch (error) {
      console.log(error);
    }
    return [] as Event[];
  },
);

export const getEventById = createAsyncThunk(
  'event/getEventById',
  async (id: string) => {
    const Event = Parse.Object.extend('Event');
    const queryEvent = new Parse.Query(Event);
    queryEvent.equalTo('objectId', id);
    try {
      const event = await queryEvent.first();
      if (event) {
        return serializeEvent(event);
      }
    } catch (error) {
      console.log(error);
    }
    console.log('event not found');
    return null;
  },
);

export const cloudGetPlanner = createAsyncThunk(
  'event/cloudGetPlanner',
  async (id: string) => {
    return null;
  },
);

export const searchEvents = createAsyncThunk<Event[], SearchEventDto>(
  'event/searchEvents',
  async ({ date, endDate, timePeriod, cityId }) => {
    const Event = Parse.Object.extend('Event');
    const queryEvent = new Parse.Query(Event);

    queryEvent.equalTo('state', 'active');
    if (cityId) {
      const cityPointer = Parse.Object.extend('City').createWithoutData(cityId);
      if (cityPointer) {
        queryEvent.equalTo('city', cityPointer);
      }
    }
    if (date && endDate) {
      queryEvent.greaterThanOrEqualTo('start', date);
      endDate.setDate(endDate.getDate() + 1);
      queryEvent.lessThanOrEqualTo('start', endDate);
      queryEvent.greaterThanOrEqualTo('end', new Date());
    } else if (date) {
      queryEvent.greaterThanOrEqualTo('start', date);
      date.setDate(date.getDate() + 1);
      queryEvent.lessThanOrEqualTo('start', date);
      queryEvent.greaterThanOrEqualTo('end', new Date());
    } else if (timePeriod === 'THIS WEEK') {
      const firstDayOfTheWeek = getFirstDayOfTheWeek();
      const lastDayOfTheWeek = getLastDayOfTheWeek();
      queryEvent.greaterThanOrEqualTo('start', firstDayOfTheWeek);
      queryEvent.lessThanOrEqualTo('start', lastDayOfTheWeek);
      queryEvent.greaterThanOrEqualTo('end', new Date());
    } else if (timePeriod === 'TODAY') {
      const currentDate = new Date();
      currentDate.setUTCHours(0, 0, 0, 0);
      queryEvent.greaterThanOrEqualTo('start', currentDate);
      currentDate.setUTCDate(currentDate.getUTCDate() + 1);
      queryEvent.lessThanOrEqualTo('start', currentDate);
      queryEvent.greaterThanOrEqualTo('end', new Date());
    } else {
      queryEvent.greaterThanOrEqualTo('end', new Date());
    }
    queryEvent.greaterThanOrEqualTo('end', new Date());
    queryEvent.ascending('start');
    try {
      const events = await queryEvent.find();
      return serializeEvents(events);
    } catch (error) {
      console.log(error);
    }
    return [] as Event[];
  },
);

export const getEventsCountPerCity = createAsyncThunk<
  any,
  getEventsCountPerCityDto
>('event/getEventsCountPerCity', async ({ date, timePeriod, enDate }) => {
  try {
    return Parse.Cloud.run('getNumberOfEventsPerCity', {
      date: date,
      timePeriod: timePeriod,
      enDate: enDate,
      active: true,
    });
  } catch (error) {
    console.log(error);
  }
});

export const stopTakingRequests = createAsyncThunk(
  'request/stopTakingRequests',
  async ({ eventId }: { eventId: string }) => {
    try {
      const Event = Parse.Object.extend('Event');
      const queryEvent = new Parse.Query(Event);
      queryEvent.equalTo('objectId', eventId);
      const event = await queryEvent.first();
      if (!event) {
        return;
      }
      const tickets = event
        .get('tickets')
        .map((ticket: EventTicket) => ({ ...ticket, takingRequest: false }));
      event.set('tickets', tickets);
      event.set('available', false);
      return event.save().then(serializeEvent);
    } catch (error) {
      console.log(error);
      return;
    }
  },
);

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
    state.requestId = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
    state.requestId = null;
  },
};

/* Slice */
const eventSlice = createSlice({
  name: 'event',
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.error = null;
      state.isLoading = false;
      state.eventCreated = null;
      state.searchedEvents = null;
      state.currentlyDisplayedEvent = null;
      state.plannerName = null;
    },
    setTimePeriod: (state, action: PayloadAction<TimePeriod | undefined>) => {
      state.timePeriod = action.payload;
    },
    setDayRange: (state, action: PayloadAction<DayRange>) => {
      state.dayRange = action.payload;
    },
    setCurrentlyDisplayedEvent: (
      state,
      action: PayloadAction<Event | null>,
    ) => {
      state.currentlyDisplayedEvent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlannerEvents.pending, sharedReducers.pending)
      .addCase(fetchPlannerEvents.rejected, sharedReducers.rejected)
      .addCase(fetchPlannerEvents.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.plannerEvents = payload;
        }
      })
      .addCase(updateEvent.pending, sharedReducers.pending)
      .addCase(updateEvent.rejected, sharedReducers.rejected)
      .addCase(updateEvent.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.currentlyDisplayedEvent = payload;
        }
      })
      .addCase(createEvent.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.eventCreated = null;
      })
      .addCase(createEvent.rejected, sharedReducers.rejected)
      .addCase(createEvent.fulfilled, (state, { payload }) => {
        state.eventCreated = true;
        state.isLoading = false;
        state.currentlyDisplayedEvent = payload;
      })
      .addCase(getEventById.pending, sharedReducers.pending)
      .addCase(getEventById.rejected, sharedReducers.rejected)
      .addCase(getEventById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.currentlyDisplayedEvent = payload;
      })
      .addCase(searchEvents.pending, sharedReducers.pending)
      .addCase(searchEvents.rejected, sharedReducers.rejected)
      .addCase(searchEvents.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.searchedEvents = payload;
        }
      })
      .addCase(stopTakingRequests.pending, sharedReducers.pending)
      .addCase(stopTakingRequests.rejected, sharedReducers.rejected)
      .addCase(stopTakingRequests.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.currentlyDisplayedEvent = payload;
        }
      })
      .addCase(getEventsCountPerCity.pending, (state) => {
        state.isEventsCountPerCityLoading = true;
        state.error = null;
      })
      .addCase(getEventsCountPerCity.rejected, sharedReducers.rejected)
      .addCase(getEventsCountPerCity.fulfilled, (state, { payload }) => {
        state.isEventsCountPerCityLoading;
        if (payload) {
          state.eventsCountPerCity = payload;
        }
      })
      .addCase(cloudGetPlanner.pending, sharedReducers.pending)
      .addCase(cloudGetPlanner.rejected, sharedReducers.rejected)
      .addCase(cloudGetPlanner.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.plannerName = payload;
        }
      });
  },
});
export const { reset, setTimePeriod, setDayRange, setCurrentlyDisplayedEvent } =
  eventSlice.actions;

export default eventSlice.reducer;
