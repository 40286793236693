import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';

import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import { getAllPromoCodes } from 'src/slices/promoCode';
import { Container } from '@mui/system';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Ticks } from 'chart.js';

import MarketingRequest from 'src/components/requests/MarketingRequestCard';
import EmailsCard from 'src/components/Campaigns/EmailsCard';
import { BsCheckCircleFill } from 'react-icons/bs';
import { getRecipientsEmails } from 'src/slices/planner';
import EventsCard from 'src/components/event/EventsCard';
import { Event } from 'src/models/event';
import {
  createMarketingRequest,
  createSmsOrder,
  fetchMarketingCampaigns,
  fetchSmsPackages,
  mPaymobCheckout,
  paymobCheckout,
  resetMarketingRequest,
  sPaymobCheckout,
} from 'src/slices/request';
import { Campaign } from 'src/models/campagin';
import { SmsPackage } from 'src/models/smspackage';
import SMSCard from 'src/components/Campaigns/SMSCard';

ChartJS.register(ArcElement, Tooltip, Legend);
const useStyles = createUseStyles({
  main: {
    width: '100%',

    backgroundColor: 'white',

    '@media print': {
      marginTop: 0,
      scale: 0.5,
      backgroundColor: 'white',
    },
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',

    justifyContent: 'space-between',
    justifyItems: 'space-between',
    alignItems: 'space-between',
    padding: '0 170px 70px',
    '@media (max-width: 1000px)': {
      padding: '0 100px 30px',
    },
  },
  componentRef: {},
  grid: {
    margin: '0',
    padding: 'auto',
    backgroundColor: 'white',
    width: '100%',
  },
  subheaderButton: {
    '&:hover': {
      opacity: 0.7,
      transition: '0.5s',
    },
    '&:disabled': {
      opacity: 0.3,
      transition: '0.5s',
    },
    backgroundColor: '#48C9B0',
    color: 'black',
    border: 'solid 1px #FFFFFF ',
    borderRadius: 50,
    height: 40,
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '8px 30px',
  },
  subheaderButton2: {
    '&:hover': {
      opacity: 0.7,
      transition: '0.5s',
      backgroundColor: '#FE5A6D',
    },
    alignSelf: 'center',
    textTransform: 'none',
    borderRadius: 20,
    width: '7rem',
    height: '3rem',
    marginTop: '30px',
    backgroundColor: '#FE5A6D',
    color: 'white',
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '15rem',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  title: {
    fontSize: '60px',
    fontWeight: 800,
    fontStyle: 'bold',
  },
  subTitle: {
    fontSize: '30px',
    fontWeight: 800,
    fontStyle: 'bold',
  },
  subSubTitle: {
    fontSize: '20px',
    fontWeight: 700,
    fontStyle: 'bold',
  },
  text: {
    fontSize: '17px',
    fontWeight: 600,
  },

  '@page': {
    size: 'portrait',
  },
  cards: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
    overflowY: 'hidden',
    alignItems: 'center',
    alignSelf: 'center',
    height: 'fit-content',
    flexShrink: 1,
    marginTop: '0.75rem',
    marginBottom: '1.5rem',
  },
  cityCardWrapper: {
    padding: '0.5rem 0.75rem',
    borderRadius: '0.5rem',
    flexShrink: 0,
    width: 'auto',
  },
  CheckIcon: {
    position: 'absolute',
    fontSize: '1.25rem',
    top: '1rem',
    right: '1.25rem',
    background: 'white',
    borderRadius: '50%',
  },
});

const SmsCheckout: React.VFC = () => {
  //Intializing styles,history, appdispatch and so on...
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { smsPackages, smsOrder } = useAppSelector(
    ({ request: { smsPackages, smsOrder } }) => ({
      smsPackages: smsPackages,
      smsOrder: smsOrder,
    }),
  );
  const createSRequest = () => {
    setIsModalLoading(true);
    dispatch(
      createSmsOrder({
        totalCost: Math.floor(totalCost * 1.05),
        packageid: selectedPackage?.id || '0',
      }),
    );
  };

  const onCheckout = async () => {
    const paymenttoken = await dispatch(
      sPaymobCheckout({
        requestId: smsOrder ? smsOrder : '0',
      }),
    );
  };
  useEffect(() => {
    if (smsOrder) {
      onCheckout();
    }
  }, [smsOrder]);

  //make a const currently displayed campaign and set it to the campaign that is currently displayed by searching for it in the marketing campaigns array

  const [totalCost, setTotalCost] = useState(0);

  const internationalNumberFormat = new Intl.NumberFormat('en-US');
  const theme = useTheme();

  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<SmsPackage>();

  const [isPaymobModalOpen, setIsPaymobModalOpen] = useState(false);
  const [paymoblink, setPaymoblink] = useState(
    'https://accept.paymob.com/api/acceptance/iframes/704969?payment_token=',
  );

  useEffect(() => {
    window.scrollTo(10, 0);

    dispatch(fetchSmsPackages());
  }, []);

  return (
    <div className={classes.main}>
      <Box marginTop={1}>
        <Grid container className={classes.grid}>
          <div className={classes.main}>
            <div className={classes.subheader}>
              <Typography className={classes.title}></Typography>
            </div>

            <Container
              sx={{
                minWidth: '70%',
                minHeight: '100%',
                margin: 'auto',
                padding: 'auto',
              }}
            >
              <Stack
                sx={{ paddingTop: 1 }}
                direction="row"
                justifyContent="space-between"
              >
                <Stack
                  width={'100%'}
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography className={classes.title}>
                    SMS Packages
                  </Typography>
                  <Button
                    className={classes.subheaderButton}
                    style={{
                      width: '15rem',
                      height: '3rem',

                      backgroundColor: '#000000',
                      color: 'white',
                      marginTop: '1rem',
                    }}
                    onClick={() => {
                      history.push('/marketing-tools');
                    }}
                  >
                    Back
                  </Button>
                </Stack>
              </Stack>
              <Stack
                sx={{ paddingTop: 8, paddingBottom: 8 }}
                spacing="30px"
                direction="column"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h3" color="black">
                    For “Invitations” Only
                  </Typography>
                  <Typography variant="h5">
                    In case you’re wondering, remaining SMS’ can be used for
                    your upcoming events as well!
                  </Typography>
                  <Box m={5} />
                  <Typography variant="h3">1. Select a Package</Typography>
                  <Box
                    sx={{ justifyContent: 'space-equally', overflow: 'hidden' }}
                    className={classes.cards}
                  >
                    {smsPackages
                      ? smsPackages.map((smsPackage, i) => (
                          <CardActionArea
                            key={i}
                            onClick={() => {
                              setSelectedPackage(smsPackage);
                              setTotalCost(
                                smsPackage.price * smsPackage.amount,
                              );
                            }}
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            className={classes.cityCardWrapper}
                          >
                            <SMSCard
                              smsPrice={smsPackage.price ? smsPackage.price : 0}
                              smsCount={
                                smsPackage.amount ? smsPackage.amount : 0
                              }
                              selected={selectedPackage?.id === smsPackage.id}
                            />
                          </CardActionArea>
                        ))
                      : null}
                  </Box>
                </Box>

                {selectedPackage && (
                  <Box
                    sx={{
                      width: '100%',
                      alignSelf: 'center',
                      display: 'flex-inline',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h3">2. Total</Typography>
                    <Card sx={{ marginTop: 2, marginBottom: 5 }}>
                      <Container>
                        <CardContent>
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={'space-between'}
                          >
                            <Stack>
                              <Typography variant="h2">SMS Packages</Typography>

                              <Typography
                                variant="h6"
                                sx={{ paddingLeft: 0.5 }}
                              >
                                For Invitations Only
                              </Typography>
                              <Box m={1} />

                              <Stack direction="column" spacing={1.5}>
                                <Typography
                                  variant="h5"
                                  sx={{ paddingLeft: 0.5 }}
                                >
                                  {'Total SMS(s): ' + selectedPackage?.amount}

                                  <br />
                                </Typography>
                                <Typography
                                  variant="h5"
                                  sx={{ paddingLeft: 0.5 }}
                                >
                                  {selectedPackage?.price}{' '}
                                  <p
                                    style={{
                                      fontSize: 10,
                                      display: 'inline',
                                    }}
                                  >
                                    EGP / SMS
                                  </p>
                                  <br />
                                </Typography>
                                <Typography
                                  variant="h5"
                                  sx={{ paddingLeft: 0.5 }}
                                >
                                  {selectedPackage?.amount}
                                  <p
                                    style={{
                                      fontSize: 10,
                                      display: 'inline',
                                    }}
                                  >
                                    {' '}
                                    SMS{' '}
                                  </p>
                                  {' x ' + selectedPackage?.price}
                                  <p
                                    style={{
                                      fontSize: 10,
                                      display: 'inline',
                                    }}
                                  >
                                    {' '}
                                    EGP{' '}
                                  </p>
                                  {' = ' + (totalCost ? totalCost : 0)}
                                  <p
                                    style={{
                                      fontSize: 10,
                                      display: 'inline',
                                    }}
                                  >
                                    {' '}
                                    EGP{' '}
                                  </p>
                                </Typography>
                              </Stack>
                            </Stack>
                            <Stack justifyContent={'space-between'}>
                              <Typography
                                textAlign={'right'}
                                paddingBottom={3}
                                variant="h3"
                              >
                                Summary
                              </Typography>
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                              >
                                <Typography variant="h6">Subtotal</Typography>
                                <Typography variant="h3" lineHeight={'25px'}>
                                  {totalCost ? totalCost : 0}{' '}
                                  <span
                                    style={{
                                      fontSize: 10,
                                      paddingLeft: 2,
                                      paddingTop: 0,
                                    }}
                                  >
                                    {' '}
                                    EGP
                                  </span>
                                </Typography>
                              </Stack>
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                              >
                                <Typography variant="h6">
                                  Taxes & Fees (5%)
                                </Typography>
                                <Typography variant="h3" lineHeight={'25px'}>
                                  {totalCost ? Math.floor(totalCost * 0.05) : 0}{' '}
                                  <span
                                    style={{
                                      fontSize: 10,
                                      paddingLeft: 2,
                                      paddingTop: 0,
                                    }}
                                  >
                                    {' '}
                                    EGP
                                  </span>
                                </Typography>
                              </Stack>
                              <img
                                src="https://i.imgur.com/8aUKIWY.png"
                                style={{ height: '3px' }}
                              />
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                              >
                                <Typography variant="h6" paddingTop={1}>
                                  Total
                                </Typography>
                                <Typography variant="h3">
                                  {totalCost ? Math.floor(totalCost * 1.05) : 0}{' '}
                                  <span
                                    style={{
                                      fontSize: 10,
                                      paddingLeft: 2,
                                      paddingTop: 0,
                                    }}
                                  >
                                    {' '}
                                    EGP
                                  </span>
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Container>
                    </Card>
                    <Button
                      disabled={isModalLoading}
                      className={classes.subheaderButton}
                      style={{
                        width: '15rem',
                        height: '3rem',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        backgroundColor: '#F7B500',
                        color: 'white',

                        margin: 'auto',

                        display: 'flex',
                      }}
                      onClick={() => {
                        createSRequest();
                      }}
                    >
                      Proceed to Payment
                    </Button>
                  </Box>
                )}
              </Stack>
            </Container>
          </div>
        </Grid>
      </Box>
      <Modal
        open={isPaymobModalOpen}
        onClose={() => {
          setIsPaymobModalOpen(false);
          dispatch(resetMarketingRequest());
        }}
      >
        <iframe
          src={paymoblink}
          style={{ width: '100%', height: '100%', border: 'none' }}
        ></iframe>
      </Modal>
    </div>
  );
};

export default SmsCheckout;
