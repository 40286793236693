import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';

import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import { getAllPromoCodes } from 'src/slices/promoCode';
import { Container } from '@mui/system';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Ticks } from 'chart.js';

import MarketingRequest from 'src/components/requests/MarketingRequestCard';
import EmailsCard from 'src/components/Campaigns/EmailsCard';
import { BsCheckCircleFill } from 'react-icons/bs';
import { getRecipientsEmails } from 'src/slices/planner';
import EventsCard from 'src/components/event/EventsCard';
import { Event } from 'src/models/event';
import {
  createMarketingRequest,
  fetchMarketingCampaigns,
  mPaymobCheckout,
  paymobCheckout,
  resetMarketingRequest,
} from 'src/slices/request';
import { Campaign } from 'src/models/campagin';

ChartJS.register(ArcElement, Tooltip, Legend);
const useStyles = createUseStyles({
  main: {
    width: '100%',

    backgroundColor: 'white',

    '@media print': {
      marginTop: 0,
      scale: 0.5,
      backgroundColor: 'white',
    },
  },
  subheader: {
    display: 'flex',
    flexDirection: 'row',

    justifyContent: 'space-between',
    justifyItems: 'space-between',
    alignItems: 'space-between',
    padding: '0 170px 70px',
    '@media (max-width: 1000px)': {
      padding: '0 100px 30px',
    },
  },
  componentRef: {},
  grid: {
    margin: '0',
    padding: 'auto',
    backgroundColor: 'white',
    width: '100%',
  },
  subheaderButton: {
    '&:hover': {
      opacity: 0.7,
      transition: '0.5s',
    },
    '&:disabled': {
      opacity: 0.3,
      transition: '0.5s',
    },
    backgroundColor: '#48C9B0',
    color: 'black',
    border: 'solid 1px #FFFFFF ',
    borderRadius: 50,
    height: 40,
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '8px 30px',
  },
  subheaderButton2: {
    '&:hover': {
      opacity: 0.7,
      transition: '0.5s',
      backgroundColor: '#FE5A6D',
    },
    alignSelf: 'center',
    textTransform: 'none',
    borderRadius: 20,
    width: '7rem',
    height: '3rem',
    marginTop: '30px',
    backgroundColor: '#FE5A6D',
    color: 'white',
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '15rem',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  title: {
    fontSize: '60px',
    fontWeight: 800,
    fontStyle: 'bold',
  },
  subTitle: {
    fontSize: '30px',
    fontWeight: 800,
    fontStyle: 'bold',
  },
  subSubTitle: {
    fontSize: '20px',
    fontWeight: 700,
    fontStyle: 'bold',
  },
  text: {
    fontSize: '17px',
    fontWeight: 600,
  },

  '@page': {
    size: 'portrait',
  },
  cards: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
    overflowY: 'hidden',
    alignItems: 'center',
    alignSelf: 'center',
    height: 'fit-content',
    flexShrink: 1,
    marginTop: '0.75rem',
    marginBottom: '1.5rem',
  },
  cityCardWrapper: {
    padding: '0.5rem 0.75rem',
    borderRadius: '0.5rem',
    flexShrink: 0,
    width: 'auto',
  },
  CheckIcon: {
    position: 'absolute',
    fontSize: '1.25rem',
    top: '1rem',
    right: '1.25rem',
    background: 'white',
    borderRadius: '50%',
  },
});

const CampaignCheckout: React.VFC = () => {
  //Intializing styles,history, appdispatch and so on...
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    currentlyDisplayedEvent,
    requests,
    sentEmail,
    marketingCampaigns,
    marketingRequest,
  } = useAppSelector(
    ({
      event: { currentlyDisplayedEvent },
      request: {
        plannerRequests,
        sentEmail,
        marketingCampaigns,
        marketingRequest,
        isLoading,
      },
    }) => ({
      currentlyDisplayedEvent: currentlyDisplayedEvent,
      requests: plannerRequests,
      sentEmail,
      marketingCampaigns: marketingCampaigns,
      marketingRequest: marketingRequest,
    }),
  );
  const { events, isEventsLoading } = useAppSelector(
    ({ event: { plannerEvents, isLoading } }) => ({
      events: plannerEvents,
      isEventsLoading: isLoading,
    }),
  );
  //make a const currently displayed campaign and set it to the campaign that is currently displayed by searching for it in the marketing campaigns array
  const params = useParams<{ id: string }>();
  const [currentlyDisplayedCampagin, setCurrentlyDisplayedCampaign] = useState<
    Campaign | undefined
  >();

  const [databaseSelected, setDatabaseSelected] = useState<
    string | undefined
  >();
  const [emailsCount, setEmailsCount] = useState<string | undefined>();
  const [totalCost, setTotalCost] = useState(0);

  const setSelectedDatabase = (database: string, emailsCount: string) => {
    if (currentlyDisplayedCampagin?.price !== undefined) {
      setDatabaseSelected(database);
      setEmailsCount(emailsCount);
      setTotalCost(parseInt(emailsCount) * currentlyDisplayedCampagin?.price);
    }
  };

  const [eventSelected, setEventSelected] = useState<Event | undefined>();
  const internationalNumberFormat = new Intl.NumberFormat('en-US');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const eventId = currentlyDisplayedEvent?.id || '';
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  const [isModalLoading, setIsModalLoading] = useState(false);

  const { approvedCount, paidCount, totalCount } = useAppSelector(
    ({ planner: { approvedCount, paidCount, totalCount } }) => ({
      approvedCount,
      paidCount,
      totalCount,
    }),
  );
  const [isPaymobModalOpen, setIsPaymobModalOpen] = useState(false);
  const [paymoblink, setPaymoblink] = useState(
    'https://accept.paymob.com/api/acceptance/iframes/704969?payment_token=',
  );

  const createMRequest = () => {
    setIsModalLoading(true);
    dispatch(
      createMarketingRequest({
        eventId: eventSelected?.id || '',
        totalCost: Math.floor(totalCost * 1.05),
        targetCampaign: currentlyDisplayedCampagin?.id || '',
        targetDatabase: databaseSelected || '',
        emailsCount: emailsCount ? parseInt(emailsCount) : 0,
      }),
    );
  };

  const onCheckout = async () => {
    const paymenttoken = await dispatch(
      mPaymobCheckout({
        requestId: marketingRequest?.id || '',
      }),
    );

    setPaymoblink('' + paymenttoken.payload);
    setIsPaymobModalOpen(true);
    setIsModalLoading(false);
  };
  useEffect(() => {
    if (marketingRequest) {
      onCheckout();
    }
  }, [marketingRequest]);

  useEffect(() => {
    window.scrollTo(10, 0);

    dispatch(getRecipientsEmails('paid'));
    dispatch(fetchMarketingCampaigns());
  }, []);
  useEffect(() => {
    if (marketingCampaigns && marketingCampaigns.length > 0) {
      setCurrentlyDisplayedCampaign(
        marketingCampaigns.find((campaign) => campaign.id === params.id),
      );
    }
  }, [marketingCampaigns]);
  useEffect(() => {
    if (
      currentlyDisplayedCampagin?.type == 'Package' &&
      currentlyDisplayedCampagin?.price !== undefined
    ) {
      setTotalCost(currentlyDisplayedCampagin?.price);
    } else {
      if (params.id === 'Z3yK7wB7c1') {
        setEmailsCount('6987');
      }
      if (currentlyDisplayedCampagin?.price !== undefined && emailsCount) {
        setTotalCost(parseInt(emailsCount) * currentlyDisplayedCampagin?.price);
      }
    }
  }, [currentlyDisplayedCampagin]);

  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  const [allowSwipe, setAllowSwipe] = useState({
    prev: false,
    next: true,
  });

  useEffect(() => {
    setPrevEl(prevEl);
    setNextEl(nextEl);
  }, [prevEl, nextEl]);
  return (
    <div className={classes.main}>
      <Box marginTop={1}>
        <Grid container className={classes.grid}>
          <div className={classes.main}>
            <div className={classes.subheader}>
              <Typography className={classes.title}></Typography>
            </div>

            <Container
              sx={{
                minWidth: '70%',
                minHeight: '100%',
                margin: 'auto',
                padding: 'auto',
              }}
            >
              <Stack
                sx={{ paddingTop: 1 }}
                direction="row"
                justifyContent="space-between"
              >
                <Stack
                  width={'100%'}
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography className={classes.title}>Checkout</Typography>
                  <Button
                    className={classes.subheaderButton}
                    style={{
                      width: '15rem',
                      height: '3rem',

                      backgroundColor: '#000000',
                      color: 'white',
                      marginTop: '1rem',
                    }}
                    onClick={() => {
                      history.push('/marketing-tools');
                    }}
                  >
                    Back
                  </Button>
                </Stack>
              </Stack>
              <Stack
                sx={{ paddingTop: 8, paddingBottom: 8 }}
                spacing="30px"
                direction="column"
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h3">
                    1. Select the event you want to promote <sup>*</sup>
                  </Typography>
                  <Box
                    sx={{ justifyContent: 'space-equally', overflow: 'hidden' }}
                    className={classes.cards}
                  >
                    {events
                      ? events.map((currentEvent) => (
                          <CardActionArea
                            key={'paid'}
                            onClick={() => {
                              setEventSelected(currentEvent);
                            }}
                            sx={{ display: 'flex', flexDirection: 'column' }}
                            className={classes.cityCardWrapper}
                          >
                            <EventsCard
                              imgUrl={
                                currentEvent.imageUrl
                                  ? currentEvent.imageUrl
                                  : ''
                              }
                              eventsCount={0}
                              displayEventsCount={false}
                              displayText={
                                currentEvent.name ? currentEvent.name : ''
                              }
                            />
                            <BsCheckCircleFill
                              className={classes.CheckIcon}
                              style={{
                                color: `#${
                                  eventSelected?.id === currentEvent.id
                                    ? 'F7B500'
                                    : 'C1C1C1'
                                }`,
                              }}
                            />
                          </CardActionArea>
                        ))
                      : null}
                  </Box>
                </Box>

                {params.id == 'C6MKIQxK2S' && eventSelected && (
                  <Box>
                    <Typography variant="h3">
                      2. Database Preference <sup>*</sup>
                    </Typography>
                    <Box m={5} />
                    <Box
                      sx={{ justifyContent: 'left' }}
                      className={classes.cards}
                    >
                      <CardActionArea
                        key={'paid'}
                        onClick={() => {
                          paidCount
                            ? setSelectedDatabase('paid', paidCount)
                            : null;
                        }}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                        className={classes.cityCardWrapper}
                      >
                        <EmailsCard
                          emailsCount={paidCount ? paidCount.toString() : '0'}
                          eventsCount={0}
                          displayEventsCount={false}
                          displayText="Users that previously bought Tickets"
                          selected={databaseSelected === 'paid' ? true : false}
                        />
                      </CardActionArea>
                      <CardActionArea
                        key={'all'}
                        onClick={() => {
                          totalCount
                            ? setSelectedDatabase('all', totalCount)
                            : null;
                        }}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                        className={classes.cityCardWrapper}
                      >
                        <EmailsCard
                          emailsCount={totalCount ? totalCount.toString() : '0'}
                          eventsCount={0}
                          displayEventsCount={false}
                          displayText="Users That Previously Sent Requests"
                          selected={databaseSelected === 'all' ? true : false}
                        />
                      </CardActionArea>
                      <CardActionArea
                        key={'approved'}
                        onClick={() => {
                          approvedCount
                            ? setSelectedDatabase('approved', approvedCount)
                            : null;
                        }}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                        className={classes.cityCardWrapper}
                      >
                        <EmailsCard
                          emailsCount={
                            approvedCount ? approvedCount.toString() : '0'
                          }
                          eventsCount={0}
                          displayEventsCount={false}
                          displayText="Users that had their requests approved"
                          selected={
                            databaseSelected === 'approved' ? true : false
                          }
                        />
                      </CardActionArea>
                    </Box>
                  </Box>
                )}

                {params.id == 'Z3yK7wB7c1' && eventSelected && (
                  <Box>
                    <Typography variant="h3">
                      2. Email or SMS Campaign<sup>*</sup>
                    </Typography>
                    <Box m={5} />
                    <Box
                      sx={{ justifyContent: 'left' }}
                      className={classes.cards}
                    >
                      <CardActionArea
                        key={'Emails'}
                        onClick={() => {
                          setCurrentlyDisplayedCampaign(
                            marketingCampaigns?.find(
                              (campaign) => campaign.id === 'Z3yK7wB7c1',
                            ),
                          );
                        }}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                        className={classes.cityCardWrapper}
                      >
                        <EmailsCard
                          emailsCount={'Email'}
                          eventsCount={0}
                          displayEventsCount={false}
                          selected={
                            currentlyDisplayedCampagin ===
                            marketingCampaigns?.find(
                              (campaign) => campaign.id === 'Z3yK7wB7c1',
                            )
                              ? true
                              : false
                          }
                        />
                      </CardActionArea>
                      <CardActionArea
                        key={'SMS'}
                        onClick={() => {
                          setCurrentlyDisplayedCampaign(
                            marketingCampaigns?.find(
                              (campaign) => campaign.id === 'gDSmDflc69',
                            ),
                          );
                        }}
                        sx={{ display: 'flex', flexDirection: 'column' }}
                        className={classes.cityCardWrapper}
                      >
                        <EmailsCard
                          emailsCount={'SMS'}
                          eventsCount={0}
                          displayEventsCount={false}
                          selected={
                            currentlyDisplayedCampagin ===
                            marketingCampaigns?.find(
                              (campaign) => campaign.id === 'gDSmDflc69',
                            )
                              ? true
                              : false
                          }
                        />
                      </CardActionArea>
                    </Box>
                  </Box>
                )}
                {params.id == 'C6MKIQxK2S' &&
                  eventSelected &&
                  databaseSelected && (
                    <Box>
                      <Typography variant="h3">
                        3. Email or SMS Campaign<sup>*</sup>
                      </Typography>
                      <Box m={5} />
                      <Box
                        sx={{ justifyContent: 'left' }}
                        className={classes.cards}
                      >
                        <CardActionArea
                          key={'Emails'}
                          onClick={() => {
                            setCurrentlyDisplayedCampaign(
                              marketingCampaigns?.find(
                                (campaign) => campaign.id === 'C6MKIQxK2S',
                              ),
                            );
                          }}
                          sx={{ display: 'flex', flexDirection: 'column' }}
                          className={classes.cityCardWrapper}
                        >
                          <EmailsCard
                            emailsCount={'Email'}
                            eventsCount={0}
                            displayEventsCount={false}
                            selected={
                              currentlyDisplayedCampagin ===
                              marketingCampaigns?.find(
                                (campaign) => campaign.id === 'C6MKIQxK2S',
                              )
                                ? true
                                : false
                            }
                          />
                        </CardActionArea>
                        <CardActionArea
                          key={'SMS'}
                          onClick={() => {
                            setCurrentlyDisplayedCampaign(
                              marketingCampaigns?.find(
                                (campaign) => campaign.id === 'AEJn9qdSfn',
                              ),
                            );
                          }}
                          sx={{ display: 'flex', flexDirection: 'column' }}
                          className={classes.cityCardWrapper}
                        >
                          <EmailsCard
                            emailsCount={'SMS'}
                            eventsCount={0}
                            displayEventsCount={false}
                            selected={
                              currentlyDisplayedCampagin ===
                              marketingCampaigns?.find(
                                (campaign) => campaign.id === 'AEJn9qdSfn',
                              )
                                ? true
                                : false
                            }
                          />
                        </CardActionArea>
                      </Box>
                    </Box>
                  )}

                {totalCost != 0 && eventSelected != undefined && (
                  <Box
                    sx={{
                      width: '100%',
                      alignSelf: 'center',
                      display: 'flex-inline',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h3">Total</Typography>
                    <Card sx={{ marginTop: 2, marginBottom: 5 }}>
                      <Container>
                        <CardContent>
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent={'space-between'}
                          >
                            <Stack>
                              <Typography variant="h2">
                                {currentlyDisplayedCampagin
                                  ? currentlyDisplayedCampagin.name
                                  : 'Campaign'}
                              </Typography>

                              <Typography
                                variant="h6"
                                sx={{ paddingLeft: 0.5 }}
                              >
                                {currentlyDisplayedCampagin
                                  ? currentlyDisplayedCampagin.subTitle
                                  : 'Campaign'}
                              </Typography>
                              <Box m={1} />
                              <Typography
                                variant="h6"
                                sx={{ paddingLeft: 0.5 }}
                              >
                                {currentlyDisplayedCampagin
                                  ? currentlyDisplayedCampagin.description
                                  : 'Campaign'}
                              </Typography>
                              <Box m={1} />
                              {currentlyDisplayedCampagin?.type === 'Unit' && (
                                <Stack direction="column" spacing={1.5}>
                                  <Typography
                                    variant="h5"
                                    sx={{ paddingLeft: 0.5 }}
                                  >
                                    {'Total User(s): ' + emailsCount}

                                    <br />
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    sx={{ paddingLeft: 0.5 }}
                                  >
                                    {currentlyDisplayedCampagin?.price + ' '}

                                    <p
                                      style={{
                                        fontSize: 10,
                                        display: 'inline',
                                      }}
                                    >
                                      EGP / User
                                    </p>
                                    <br />
                                  </Typography>
                                  <Typography
                                    variant="h5"
                                    sx={{ paddingLeft: 0.5 }}
                                  >
                                    {emailsCount}
                                    <p
                                      style={{
                                        fontSize: 10,
                                        display: 'inline',
                                      }}
                                    >
                                      {' '}
                                      User{' '}
                                    </p>
                                    {' x ' + currentlyDisplayedCampagin?.price}
                                    <p
                                      style={{
                                        fontSize: 10,
                                        display: 'inline',
                                      }}
                                    >
                                      {' '}
                                      EGP{' '}
                                    </p>
                                    {' = ' + (totalCost ? totalCost : 0)}
                                    <p
                                      style={{
                                        fontSize: 10,
                                        display: 'inline',
                                      }}
                                    >
                                      {' '}
                                      EGP{' '}
                                    </p>
                                  </Typography>
                                </Stack>
                              )}
                            </Stack>
                            <Stack justifyContent={'space-between'}>
                              <Typography
                                textAlign={'right'}
                                paddingBottom={3}
                                variant="h3"
                              >
                                Summary
                              </Typography>
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                              >
                                <Typography variant="h6">Subtotal</Typography>
                                <Typography variant="h3" lineHeight={'25px'}>
                                  {totalCost ? totalCost : 0}{' '}
                                  <span
                                    style={{
                                      fontSize: 10,
                                      paddingLeft: 2,
                                      paddingTop: 0,
                                    }}
                                  >
                                    {' '}
                                    EGP
                                  </span>
                                </Typography>
                              </Stack>
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                              >
                                <Typography variant="h6">
                                  Taxes & Fees (5%)
                                </Typography>
                                <Typography variant="h3" lineHeight={'25px'}>
                                  {totalCost ? Math.floor(totalCost * 0.05) : 0}{' '}
                                  <span
                                    style={{
                                      fontSize: 10,
                                      paddingLeft: 2,
                                      paddingTop: 0,
                                    }}
                                  >
                                    {' '}
                                    EGP
                                  </span>
                                </Typography>
                              </Stack>
                              <img
                                src="https://i.imgur.com/8aUKIWY.png"
                                style={{ height: '3px' }}
                              />
                              <Stack
                                direction={'row'}
                                justifyContent={'space-between'}
                              >
                                <Typography variant="h6" paddingTop={1}>
                                  Total
                                </Typography>
                                <Typography variant="h3">
                                  {totalCost ? Math.floor(totalCost * 1.05) : 0}{' '}
                                  <span
                                    style={{
                                      fontSize: 10,
                                      paddingLeft: 2,
                                      paddingTop: 0,
                                    }}
                                  >
                                    {' '}
                                    EGP
                                  </span>
                                </Typography>
                              </Stack>
                            </Stack>
                          </Stack>
                        </CardContent>
                      </Container>
                    </Card>
                    <Button
                      disabled={isModalLoading}
                      className={classes.subheaderButton}
                      style={{
                        width: '15rem',
                        height: '3rem',
                        alignSelf: 'center',
                        justifySelf: 'center',
                        backgroundColor: '#F7B500',
                        color: 'white',

                        margin: 'auto',

                        display: 'flex',
                      }}
                      onClick={() => {
                        createMRequest();
                      }}
                    >
                      Proceed to Payment
                    </Button>
                  </Box>
                )}
              </Stack>
            </Container>
          </div>
        </Grid>
      </Box>
      <Modal
        open={isPaymobModalOpen}
        onClose={() => {
          setIsPaymobModalOpen(false);
          dispatch(resetMarketingRequest());
        }}
      >
        <iframe
          src={paymoblink}
          style={{ width: '100%', height: '100%', border: 'none' }}
        ></iframe>
      </Modal>
    </div>
  );
};

export default CampaignCheckout;
