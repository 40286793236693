import {
  CardMedia,
  TextField,
  Grid,
  Box,
  Stack,
  Typography,
  Button,
  Icon,
} from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Request } from 'src/models/request';
import { Event } from 'src/models/event';
import SearchIcon from '@mui/icons-material/Search';
import { forEach } from 'lodash';
import { createPlanner } from 'src/slices/planner';
import { getPlannerName } from 'src/actions/actions';
import { useHistory } from 'react-router-dom';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { TailSpin } from 'react-loading-icons';
import { useAppSelector } from 'src/hooks/store';

const useStyles = createUseStyles({
  main: {
    margin: '5em',

    paddingRight: 'calc(100vw - 100%)',
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  subheaderButton: {
    border: '1px solid black',
    color: 'black',
    padding: '0em 2em',
    borderRadius: '50px',
    backgroundColor: 'white',
    '&:active': {
      color: 'white',
      backgroundColor: 'black',
    },
    transform: 'none',
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '100%',
    height: '15.5em',
    objectFit: 'fill',

    marginBottom: '5px',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  searchBar: {
    color: 'black',
    [`& fieldset`]: {
      border: 'none',
    },
  },
  buttonSignup: {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '300px',
    border: '1px solide',
    borderColor: 'black',
    width: '300px',
    textTransform: 'none',
    '&:hover': {
      color: 'black',
      backgroundColor: '#10BF7A',
    },
  },
  fullSearchBar: {
    border: 'solid 1px #A7A7A7',
    borderRadius: 20,
    boxShadow: ' 0px 2px 4px #979797',
    width: '400px',
    height: '50px',
    '@media (max-width: 1600px)': {
      width: '300px',
    },
  },
  boldHeader: {
    fontWeight: 900,
  },
  title: {
    fontSize: '20px',
    fontWeight: 800,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '20px',
    justifyContent: 'center',
    fontWeight: 600,
  },
  numberOfRequests: {
    fontSize: '30px',
    fontWeight: 800,
  },
});

interface Props {
  event: Event | null;
  requests: Request[] | null;
  setSearchText: (value: string) => void;
  searchText?: string;
  user?: string;
}
const RequestsTopBar: React.VFC<Props> = ({
  event,
  requests,
  setSearchText,
  searchText,
  user,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { isLoading } = useAppSelector(({ request: { isLoading } }) => ({
    isLoading,
  }));

  //Start of total actual tickets number code
  let totalTickets = 0;
  let totalPending = 0;
  let totalDeclined = 0;
  let totalApproved = 0;
  let totalInvitation = 0;

  requests?.map((request) => (totalTickets += request.recipients.length));
  requests?.map((request) =>
    request.isPending ? (totalPending += request.recipients.length) : 0,
  );
  requests?.map((request) =>
    request.isDeclined ? (totalDeclined += request.recipients.length) : 0,
  );
  requests?.map((request) =>
    request.isApproved ? (totalApproved += request.recipients.length) : 0,
  );
  requests?.map((request) =>
    request.isInvitation ? (totalInvitation += request.recipients.length) : 0,
  );

  const displayNumberOfRequestsSection = (
    label: string,
    color: string,
    numberOfRequests: number,
  ) => (
    <Grid
      item
      xs={1.16}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography
        variant="size4"
        textAlign="start"
        fontWeight={800}
        style={{ color: color }}
      >
        {label}
      </Typography>
      <Typography
        fontSize={{ xs: 30, xl: 35 }}
        fontWeight={800}
        textAlign="center"
        display="block"
      >
        {isLoading ? (
          <TailSpin
            style={{ marginTop: '10px' }}
            stroke="#000000"
            speed={0.75}
          />
        ) : (
          numberOfRequests
        )}
      </Typography>
    </Grid>
  );
  return (
    <Grid container spacing={3} position={'relative'}>
      <Grid item xs={2.5} xl={2.5}>
        {event?.imageUrl == null ? (
          <CardMedia
            component="img"
            className={classes.eventImage}
            image="https://images.unsplash.com/photo-1506157786151-b8491531f063?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
          />
        ) : (
          <CardMedia
            component="img"
            className={classes.eventImage}
            image={event?.imageUrl}
          />
        )}
      </Grid>
      <Grid item xs={4} xl={9.5}>
        <Stack direction="column" spacing={0} paddingTop={2}>
          <Typography
            variant="h5"
            textAlign="start"
            fontWeight={800}
            display="block"
          >
            {event?.name}
          </Typography>
          <Typography
            variant="size4"
            textAlign="start"
            fontWeight={600}
            display="block"
          >
            By {user}
          </Typography>
          <Box sx={{ m: 1 }} />
          <Typography
            variant="size4"
            textAlign="start"
            fontWeight={800}
            display="block"
          >
            Scanner Code: {event?.scannerCodePassword}
          </Typography>
          <Box sx={{ m: 2 }} />
          <Stack
            direction="row"
            spacing={0}
            className={classes.fullSearchBar}
            alignItems="center"
          >
            <SearchIcon
              sx={{
                color: '#989898',
                fontSize: { xs: '50px', xl: '50px' },
                paddingLeft: 2,
              }}
            />
            <TextField
              className={classes.searchBar}
              label={searchText ? '' : 'Search by name, ticket category..'}
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              fullWidth
              InputProps={{ sx: { fontSize: { xs: 14, xl: 14 } } }}
              InputLabelProps={{
                shrink: false,
                sx: { fontSize: { xs: 14, xl: 14 } },
              }}
            />
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={5} xl={7} sx={{ right: '0', position: 'absolute' }}>
        <Stack display="flex" direction="row" spacing={3}>
          <Stack direction="column" spacing={1} alignItems="center">
            <Grid item xs={0} paddingLeft={0}>
              <Typography variant="size4" textAlign="start" fontWeight={800}>
                Total Tickets
              </Typography>
              <Typography
                fontSize={{ xs: 40, xl: 48 }}
                fontWeight={800}
                textAlign="start"
                display="flex"
              >
                {isLoading ? (
                  <TailSpin
                    style={{ marginTop: '10px', marginBottom: '25px' }}
                    stroke="#000000"
                    speed={0.75}
                  />
                ) : (
                  totalTickets
                )}
              </Typography>
            </Grid>
            <Grid item xs={0} paddingLeft={2}>
              <Typography variant="size4" textAlign="start" fontWeight={800}>
                Total Requests
              </Typography>
              <Typography
                fontSize={{ xs: 40, xl: 48 }}
                fontWeight={800}
                textAlign="start"
                display="flex"
              >
                {!isLoading ? (
                  requests?.length
                ) : (
                  <TailSpin
                    style={{ marginTop: '10px' }}
                    stroke="#000000"
                    speed={0.75}
                  />
                )}
              </Typography>
            </Grid>
          </Stack>
          <Stack display="flex" direction="row" spacing={2}>
            <Stack direction="column" spacing={3.5} alignItems="center">
              {displayNumberOfRequestsSection(
                'Pending',
                '#000000',
                totalPending,
              )}
              {displayNumberOfRequestsSection(
                'Pending',
                '#000000',
                requests?.filter((request) => request.isPending).length ?? -1,
              )}
            </Stack>

            <Stack direction="column" spacing={3.5} alignItems="center">
              {displayNumberOfRequestsSection(
                'Approved',
                '#000000',
                totalApproved ?? -1,
              )}
              {displayNumberOfRequestsSection(
                'Approved',
                '#000000',
                requests?.filter((request) => request.isApproved).length ?? -1,
              )}
            </Stack>
            <Stack direction="column" spacing={3.5} alignItems="center">
              {displayNumberOfRequestsSection(
                'Declined',
                '#000000',
                totalDeclined ?? -1,
              )}
              {displayNumberOfRequestsSection(
                'Declined',
                '#000000',
                requests?.filter((request) => request.isDeclined).length ?? -1,
              )}
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default RequestsTopBar;
function getPlannerById(planner: string | undefined) {
  throw new Error('Function not implemented.');
}
