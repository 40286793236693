import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { Box, Container, Stack, Typography } from '@mui/material';
interface Props {
  event: Event | any;
}

const useStyles = createUseStyles({
  CategoryColour: {
    height: '21px',
    width: '21.78px',
    boxShadow: ' 0px 2px 4px rgba(0, 0, 0, 0.5)',
    borderRadius: '4px',
  },
  Text:{
    fontFamily:'SF pro Display',
    fontWeight:'600',
    fontSize:'11px',
    lineHeight:'23.13px'
  }
});
export const TableSelectorMapLegend: React.VFC<Props> = ({ event }) => {
  const classes = useStyles();
  return (
    <Stack sx={{ marginTop: '70px' }} spacing={1}>
      <Stack direction={'row'} spacing={2}>
        <Box
          className={classes.CategoryColour}
          sx={{
            backgroundColor: '#F7B4DC',
          }}
        ></Box>
        <Typography className={classes.Text}>1ST Row</Typography>
      </Stack>{' '}
      <Stack direction={'row'} spacing={2}>
        <Box
          className={classes.CategoryColour}
          sx={{
            backgroundColor: '#FBCCA6',
          }}
        ></Box>
        <Typography className={classes.Text}>2ND Row</Typography>
      </Stack>{' '}
      <Stack direction={'row'} spacing={2}>
        <Box
          className={classes.CategoryColour}
          sx={{
            backgroundColor: '#98DEF9',
          }}
        ></Box>
        <Typography className={classes.Text}>3RD Row</Typography>
      </Stack>{' '}
      <Stack direction={'row'} spacing={2}>
        <Box
          className={classes.CategoryColour}
          sx={{
            backgroundColor: '#91D2C1',
          }}
        ></Box>
        <Typography className={classes.Text}>4TH Row</Typography>
      </Stack>{' '}
      <Stack direction={'row'} spacing={2}>
        <Box
          className={classes.CategoryColour}
          sx={{
            backgroundColor: '#E9D7CB',
          }}
        ></Box>
        <Typography className={classes.Text}>HIGH TABLES</Typography>
      </Stack>{' '}
      <Stack direction={'row'} spacing={2}>
        <Box
          className={classes.CategoryColour}
          sx={{
            backgroundColor: '#F6F9EB',
          }}
        ></Box>
        <Typography className={classes.Text}>STANDING</Typography>
      </Stack>
    </Stack>
  );
};
