import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';

import { createUseStyles } from 'react-jss';
import RequestsPaidApproved from 'src/components/requests/RequestsPaidApproved';
import RequestsCard from 'src/components/requests/RequestsCard';
import RequestsTopBar from 'src/components/requests/RequestsTopBar';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { useParams } from 'react-router-dom';
import {
  approveRequest,
  declineRequest,
  fetchEventRequests,
  refundRequestPayment,
  resendRequestEmail,
  resetSentEmail,
} from 'src/slices/request';
import { getEventById, stopTakingRequests } from 'src/slices/event';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Alert, Snackbar, Stack, Typography } from '@mui/material';
import FullscreenSpin from '../components/FullscreenSpin';
import { useHistory } from 'react-router-dom';
import Footer from 'src/components/old/layout/Footer';
import FooterBar from 'src/components/FooterBar';
import MarketingRequest from 'src/components/requests/MarketingRequestCard';
import { useIsBigScreen } from 'src/hooks/useBigScreen';

const useStyles = createUseStyles({
  main: {
    margin: '0.1rem',
    flex: 1,
    height: '100%',
    minHeight: '100vh',
    width: '85vw',
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',

    alignItems: 'center',
    padding: '0 5% 5%',
  },
  subheaderText: {
    fontWeight: 800,

    font: 'SF Pro Display',
    fontFamily: 'SF Pro Display',
  },

  stopSalesButton: {
    '&:hover': {
      backgroundColor: '#FF2F2F',
      boxShadow: ' 0px 2px 8px #979797',
    },
    '&:disabled': {
      border: 'solid 1px #FF2F2F',
    },
    backgroundColor: '#FF2F2F',
    color: 'white',
    border: 'solid 1px #FF2F2F',
    borderRadius: 15,
    borderBlockColor: '#FF2F2F',

    height: 35,
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '8px 30px',
  },
  boxOfficeButton: {
    '&:hover': {
      backgroundColor: '#28AF86',
      boxShadow: ' 0px 2px 8px #979797',
    },
    '&:disabled': {
      border: 'solid 0px black',
    },

    backgroundColor: '#28AF86',
    color: 'white',
    border: 'solid 1px #28AF86',
    borderRadius: 15,
    borderBlockColor: '#28AF86',

    height: 35,
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '8px 30px',
  },
  salesReportButton: {
    '&:hover': {
      backgroundColor: '#000000',
      boxShadow: ' 0px 2px 8px #979797',
    },
    '&:disabled': {
      border: 'solid 0px black',
    },
    backgroundColor: 'black',
    color: 'white',
    border: 'solid 1px black',
    borderRadius: 15,

    borderBlockColor: 'black',

    height: 35,
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'none',
    padding: '8px 30px',
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '15rem',
    paddingBottom: '2rem',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  title: {
    fontSize: '60px',
    fontWeight: 800,
  },
});

const RequestsPage: React.VFC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const isBigScreen = useIsBigScreen();

  const { currentlyDisplayedEvent, requests, sentEmail } = useAppSelector(
    ({
      event: { currentlyDisplayedEvent },
      request: { plannerRequests, sentEmail },
    }) => ({
      currentlyDisplayedEvent: currentlyDisplayedEvent,
      requests: plannerRequests,
      sentEmail,
    }),
  );
  const [isSentEmailDialogOpen, setIsSentEmailDialogOpen] = useState(false);
  useEffect(() => {
    dispatch(
      fetchEventRequests({
        eventId: id,
      }),
    );
  }, [id]);
  useEffect(() => {
    dispatch(getEventById(id));
  }, [id]);
  useEffect(() => {
    if (sentEmail) {
      setIsSentEmailDialogOpen(true);
      dispatch(resetSentEmail());
    }
  }, [sentEmail]);

  const onClickApprove = (requestId: string) => {
    dispatch(approveRequest({ eventId: id, id: requestId }));
  };

  const onClickDecline = (requestId: string) => {
    dispatch(declineRequest({ eventId: id, id: requestId }));
  };
  const onClickRefund = (requestId: string) => {
    dispatch(refundRequestPayment({ eventId: id, requestId }));
  };

  const onClickResendRequestEmail = (requestId: string) => {
    dispatch(resendRequestEmail({ eventId: id, requestId }));
  };

  const [popUp, setPopUp] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>();
  const handleClickOpen = () => {
    setPopUp(true);
  };

  const handleClose = () => {
    setPopUp(false);
  };

  const handleStopTakingRequests = () => {
    dispatch(
      stopTakingRequests({
        eventId: id,
      }),
    );
    handleClose();
  };
  const handleSalesReport = () => {
    history.push(`/salesreport/${id}`);
  };

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const eventId = currentlyDisplayedEvent?.id;
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));
  return (
    <>
      <div className={classes.main}>
        <Box
          id="requests__subheader"
          marginTop={5}
          className={classes.subheader}
        >
          <Typography fontSize={'45px'} className={classes.subheaderText}>
            Requests
          </Typography>
          <Stack direction={'row'} spacing={1}>
            <Button
              onClick={handleSalesReport}
              className={classes.salesReportButton}
              variant="contained"
            >
              <Typography variant="size4" fontWeight={500}>
                Sales Report
              </Typography>
            </Button>
            <Button
              onClick={() =>
                history.push(`/livecheckin/${currentlyDisplayedEvent?.id}`)
              }
              className={classes.boxOfficeButton}
              variant="contained"
            >
              <Typography variant="size4" fontWeight={500}>
                Box Office & Check-in
              </Typography>
            </Button>
            <Button
              onClick={handleClickOpen}
              className={classes.stopSalesButton}
              variant="contained"
              disabled={currentlyDisplayedEvent?.available == false}
            >
              <Typography variant="size4" fontWeight={500}>
                Stop taking requests
              </Typography>
            </Button>
          </Stack>

          <Dialog
            fullScreen={fullScreen}
            open={popUp}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {'Stop taking requests'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to stop taking requests for this event?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleStopTakingRequests} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        <Box id="requests__management">
          <Box>
            <Stack direction="column" spacing={0}>
              <RequestsTopBar
                searchText={searchText}
                setSearchText={setSearchText}
                event={currentlyDisplayedEvent}
                requests={requests}
                user={user?.name}
              />
              <Grid container direction="row" spacing={1}>
                <Grid item xs={2.5} xl={2.5}>
                  <RequestsPaidApproved
                    requests={requests}
                    event={currentlyDisplayedEvent}
                  />
                </Grid>
                <Grid item xs={9.5} xl={9.5} alignItems="flex-start">
                  {currentlyDisplayedEvent ? (
                    <RequestsCard
                      searchText={searchText}
                      requests={requests}
                      event={currentlyDisplayedEvent}
                      onClickApprove={onClickApprove}
                      onClickDecline={onClickDecline}
                      onClickResendRequestEmail={onClickResendRequestEmail}
                      onClickRefund={onClickRefund}
                    />
                  ) : (
                    <FullscreenSpin />
                  )}
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Box>
        <Snackbar
          open={isSentEmailDialogOpen}
          autoHideDuration={6000}
          onClose={() => setIsSentEmailDialogOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={() => setIsSentEmailDialogOpen(false)}
            severity="success"
          >
            A reminder of the approved request has been sent to the user.
          </Alert>
        </Snackbar>
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
          paddingBottom: 0,
          paddingTop: '30px',
        }}
      >
        <FooterBar />
      </div>
    </>
  );
};

export default RequestsPage;
