import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import React, { useMemo } from 'react';
import RequestCircleProgress from 'src/components/requests/RequestCircleProgress';
import { createUseStyles } from 'react-jss';
import { Request } from 'src/models/request';
import { EventTicket } from 'src/models/ticket';
import { useIsBigScreen } from 'src/hooks/useBigScreen';
import { Event } from '../../models/event';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Box, styled } from '@mui/material';

const useStyles = createUseStyles({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '130px',
    fontSize: '',
  },
  paid: {
    fontSize: '20px',
    fontWeight: 800,
  },
  approved: {
    fontSize: '17px',
    fontWeight: 500,
  },
});

type RequestNumberByTicketId = { [ticketId: string]: number };

interface Props {
  requests: Request[] | null;
  event: Event | null;
}
const RequestsPaidPackages: React.VFC<Props> = ({ requests, event }) => {
  const classes = useStyles();
  const isBigScreen = useIsBigScreen();
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    width: '10rem',
    borderRadius: 5,

    border: '1px solid #F7B500',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#FFFFFF',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#F7B500' : '#F7B500',
    },
  }));
  const getPaidRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.paid) {
        return accumulator;
      }
      Object.entries(request.ticketsNumberById).forEach(
        ([ticketId, ticketNumber]) => {
          accumulator[ticketId] =
            (accumulator[ticketId] ?? 0) + (ticketNumber ? 1 : 0);
        },
      );
      return accumulator;
    }, {});

  const getApprovedRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      if (!request.isApproved) {
        return accumulator;
      }
      Object.entries(request.ticketsNumberById).forEach(
        ([ticketId, ticketNumber]) => {
          accumulator[ticketId] =
            (accumulator[ticketId] ?? 0) + (ticketNumber ? 1 : 0);
        },
      );
      return accumulator;
    }, {});

  const getRequestsNumberByTicketId = () =>
    requests?.reduce((accumulator: RequestNumberByTicketId, request) => {
      Object.entries(request.ticketsNumberById).forEach(
        ([ticketId, ticketNumber]) => {
          accumulator[ticketId] =
            (accumulator[ticketId] ?? 0) + (ticketNumber ?? 0);
        },
      );
      return accumulator;
    }, {});

  const getTotalPaidRequest = () =>
    Object.values(paidRequestByTicketId ?? {}).reduce(
      (accumulator, value) => accumulator + value,
      0,
    );
  const getTotalApprovedRequest = () =>
    Object.values(approvedRequestByTicketId ?? {}).reduce(
      (accumulator, value) => accumulator + value,
      0,
    );

  const paidRequestByTicketId = useMemo(
    () => getPaidRequestsNumberByTicketId(),
    [requests],
  );
  const approvedRequestByTicketId = useMemo(
    () => getApprovedRequestsNumberByTicketId(),
    [requests],
  );

  const requestsByTicketId = useMemo(
    () => getRequestsNumberByTicketId(),
    [requests],
  );

  const totalPaidApprovedRequestRatio = useMemo(() => {
    const totalApprovedRequests = getTotalApprovedRequest();
    const totalPaidRequest = getTotalPaidRequest();
    return totalApprovedRequests === 0
      ? 0
      : (totalPaidRequest / totalApprovedRequests) * 100;
  }, [paidRequestByTicketId, approvedRequestByTicketId]);

  const totalPaidRequests = useMemo(
    () => getTotalPaidRequest(),
    [paidRequestByTicketId],
  );

  const totalApprovedRequests = useMemo(
    () => getTotalApprovedRequest(),
    [approvedRequestByTicketId],
  );

  const displayPaidApprovedRatio = (ticket: EventTicket, index: number) => {
    if (ticket.id) {
      return (
        <Grid item xs={6} key={index}>
          <Stack direction="row" spacing={8}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 3,
                marginLeft: 2,
              }}
            >
              <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    ticket?.onInvitation
                      ? requestsByTicketId?.[ticket.id] ?? 0
                      : approvedRequestByTicketId?.[ticket.id] === 0 ||
                        !ticket.id ||
                        !paidRequestByTicketId?.[ticket.id] ||
                        !approvedRequestByTicketId?.[ticket.id]
                      ? 0
                      : (paidRequestByTicketId[ticket.id] /
                          approvedRequestByTicketId[ticket.id]) *
                        100
                  }
                />
              </Box>
              <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="#F7B500">
                  {paidRequestByTicketId?.[ticket.id] ?? 0} /{' '}
                  {approvedRequestByTicketId?.[ticket.id] ?? 0}
                </Typography>
              </Box>
            </Box>

            <div className={classes.centered}>
              <Stack direction="row" spacing={1} marginTop={3}>
                <Typography fontSize={{ xs: 13, xl: 18 }} fontWeight={800}>
                  {ticket.name}{' '}
                </Typography>
              </Stack>
            </div>
          </Stack>
        </Grid>
      );
    }
  };

  return (
    <Grid
      container
      alignItems="left"
      maxWidth={'60vw'}
      marginRight="10px"
      justifyContent="left"
    >
      {event?.packages?.map(displayPaidApprovedRatio)}
    </Grid>
  );
};

export default RequestsPaidPackages;
