import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import EventsPage from 'src/pages/EventsPage';
import EventPage from 'src/pages/EventPage';
import { Layout } from 'src/containers/Layout';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { ClientSwitch } from './ClientSwitch';
import { PlannerSwitch } from './PlannerSwitch';
import { initializeUserWithToken } from 'src/slices/authentication';
import EventPlanner from 'src/pages/EventPlanner';
import Landing from 'src/pages/Landing';
import SignIn from 'src/pages/SignIn';
import SignUp from 'src/pages/SignUp';
import ForgotPassword from 'src/pages/ForgotPassword';
import { EventPlannerSignUp } from 'src/pages/EventPlannerSignUp';
import FullscreenSpin from 'src/components/FullscreenSpin';
import { PaymentProcessing } from 'src/pages/PaymentProcessing';
import ThanksForPurchase from 'src/components/tickets/ThanksForPurchase';
import { MPaymentProcessing } from 'src/pages/MakretingPProcessing';
import { Aboutus } from 'src/pages/AboutUs';
import { Terms } from 'src/pages/Terms';
import { PrivacyPolicy } from 'src/pages/PrivacyPolicy';
import ThanksForPurchaseM from 'src/pages/ThanksForPurchaseM';
import SmsCheckout from 'src/pages/SmsCheckout';
import EventNotAvailable from 'src/components/tickets/EventNotAvailable';

export const DefaultSwitch: React.VFC = () => {
  const { user, loading } = useAppSelector(
    ({ authentication: { user, loading } }) => ({
      user,
      loading,
    }),
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(initializeUserWithToken());
  }, []);
  if (loading) {
    return <FullscreenSpin />;
  }
  if (user?.type == 'customer') {
    return <ClientSwitch />;
  } else if (user?.type == 'eventPlanner') {
    return <PlannerSwitch />;
  } else {
    return (

      <Switch>
        <Route exact path="/tac-app" component={Landing} />
        <Route exact path="/events" component={FullscreenSpin} />
        <Route path="/events/:id/:password?" component={EventPage} />
        <Route path="/EventNotAvailable" component={EventNotAvailable} />

        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/signUp" component={SignUp} />
        <Route exact path="/eventPlanner" component={EventPlanner} />
        <Route path="/payment-processing" component={PaymentProcessing} />
        <Route
          exact
          path="/Mpayment-processing/"
          component={MPaymentProcessing}
        />
        <Route
          exact
          path="/thanks-for-purchase"
          component={ThanksForPurchase}
        />
        <Route exact path="/sms-checkout" component={SmsCheckout} />
        <Route exact path="/about-us" component={Aboutus} />
        <Route exact path="/terms-and-conditions" component={Terms} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/eventPlannerSignUp"
          component={EventPlannerSignUp}
        />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/request/:id"
          render={(match) => (
            <Redirect to={`/sign-in?requestId=${match.match.params.id}`} />
          )}
        />
        <Redirect to="/events" />
      </Switch>

    );
  }
};
