import {
  CardMedia,
  TextField,
  Grid,
  Box,
  Stack,
  Typography,
  SvgIcon,
  Container,
  Button,
} from '@mui/material';
import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { Request } from 'src/models/request';
import { Event } from 'src/models/event';
import SearchIcon from '@mui/icons-material/Search';
import { DateTime } from 'luxon';
import { ReactComponent as Tickets } from '../../assets/ticketLogo.svg';
import { EventTicket } from 'src/models/ticket';
import { IoIosArrowBack } from 'react-icons/io';

const useStyles = createUseStyles({
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '85vw',
    height: '100%',
    maxHeight: '55vh',
    maxWidth: '90vw',
  },
  titleIcon: {
    marginTop: '5px',
    width: '83px',
    height: '82px',
    maxWidth: '33px',
    maxHeight: '33px',
    '@media screen and (max-width: 1200px)': {
      width: '50px',
      height: '50px',
      fontSize: '32px',
    },
    '@media screen and (max-width: 900px)': {
      width: '40px',
      height: '40px',
      fontSize: '32px',
    },
  },
  Totaltickets: {
    display: 'block',
    alignSelf: 'start',
    maxWidth: '60vw',
    fontFamily: 'SF Pro Display',
    fontWeight: 800,
    fontSize: 40,
    lineHeight: '45px',
  },
  TotalticketsRequests: {
    display: 'block',
    alignSelf: 'start',
    maxWidth: '60vw',
    fontFamily: 'SF Pro Display',
    fontWeight: 800,
    fontSize: 14,
  },
  ButtonText: {
    fontSize: '28px',
    fontFamily: 'SF Pro Display',
    fontWeight: 800,
  },
  fullSearchBar: {
    color: '#00000',
    border: 'solid 2px #000000',
    borderRadius: 40,
    maxWidth: '95vw',
  },
  searchBar: {
    color: '#00000',
    [`& fieldset`]: {
      border: 'none',
      color: '#00000',
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#00000',
    },
    width: '400px',
    '@media (max-width: 1500px)': {
      width: '320px',
    },
  },
});

interface Props {
  event: Event | null;
  requests: Request[] | null;
  setSearchText: (value: string) => void;
  searchText?: string;
  isRequestsOpen: boolean;
  onClickRequests: () => void;
}
const ManageRequestsTopBarMobile: React.VFC<Props> = ({
  event,
  requests,
  setSearchText,
  searchText,
  isRequestsOpen,
  onClickRequests,
}) => {
  const classes = useStyles();
  const getTotalTicketsSold = (tickets: EventTicket[]) =>
    tickets.reduce(
      (accumulator, ticket) => accumulator + (ticket.sold ?? 0),
      0,
    );
  let totalTickets = 0;
  requests?.map((request) => (totalTickets += request.recipients.length));
  const getEventDates = (
    events: Event,
  ): {
    startDate: Date;
    endDate: Date;
    dateString: string;
    timeString: string;
    timeBeforeStart: string;
  } => {
    const StartDate = new Date(events.startDate);
    const StartDateUTC = StartDate; //getEventDate(event.timeZone, StartDate);
    const EndDate = new Date(events.endDate);
    const EndDateUTC = EndDate; // getEventDate(event.timeZone, EndDate);
    const timeBeforeStartDuration = DateTime.fromJSDate(StartDateUTC).diff(
      DateTime.now(),
    );

    const timeBeforeStart =
      new Date() > EndDateUTC
        ? 'Finished'
        : new Date() > StartDateUTC
        ? 'Started'
        : timeBeforeStartDuration.as('seconds') < 0
        ? '00:00:00'
        : timeBeforeStartDuration.as('days') < 1
        ? timeBeforeStartDuration.toFormat('hh:mm:ss')
        : timeBeforeStartDuration.as('months') < 1
        ? timeBeforeStartDuration.as('days') >= 2
          ? `${timeBeforeStartDuration.toFormat('d')} days`
          : '1 day'
        : timeBeforeStartDuration
        ? timeBeforeStartDuration.as('months') > 2
          ? `${timeBeforeStartDuration.toFormat('MM')} months`
          : '1 month'
        : '0 months';
    const dateString = `${StartDateUTC.toLocaleDateString('en-US', {
      timeZone: events.timeZone,
      month: 'long',
      day: 'numeric',
    })}  - ${EndDateUTC.toLocaleDateString('en-US', {
      timeZone: events.timeZone,
      month: 'long',
      day: 'numeric',
    })}`;
    const timeString = `${StartDateUTC.toLocaleTimeString('en-US', {
      timeZone: events.timeZone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })} - ${EndDateUTC.toLocaleTimeString('en-US', {
      timeZone: events.timeZone,
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })}`;
    return {
      startDate: StartDateUTC,
      endDate: EndDateUTC,
      dateString,
      timeString,
      timeBeforeStart,
    };
  };

  return !isRequestsOpen ? (
    <>
      <Button
        onClick={() => onClickRequests()}
        variant="contained"
        sx={{
          fontSize: '22px',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          marginTop: 1,
          padding: 0,
          minHeight: 0,
          minWidth: 0,

          color: 'black',

          boxShadow: '0px 2px 4px 1px #B9B6B6',

          backgroundColor: 'white',
        }}
      >
        <IoIosArrowBack />
      </Button>
      <Stack
        direction={'column'}
        spacing={1}
        justifyContent={'center'}
        alignItems={'center'}
        paddingTop={2}
      >
        <Grid container>
          <Grid item xs={12}>
            <Stack direction={'row'} spacing={1}>
              <SvgIcon
                component={Tickets}
                className={classes.titleIcon}
                inheritViewBox
              />
              <Typography className={classes.ButtonText}>Requests</Typography>
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="size3"
          textAlign="start"
          fontSize={'20px'}
          fontWeight={700}
          display="block"
          maxWidth={'70vw'}
          alignSelf={'start'}
        >
          {event?.name} at {event?.addressName}
        </Typography>
        <Grid container>
          <Grid item xs={12} spacing={0}>
            <Typography className={classes.TotalticketsRequests}>
              Total Tickets Requests
            </Typography>
            <Typography className={classes.Totaltickets}>
              {totalTickets}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} spacing={0}>
            <Stack
              direction="row"
              spacing={0}
              className={classes.fullSearchBar}
              alignItems="center"
            >
              <SearchIcon
                sx={{
                  color: '#00000',
                  fontSize: { xs: '40px', xl: '50px' },
                  paddingLeft: 2,
                }}
              />
              <TextField
                className={classes.searchBar}
                label={searchText ? '' : 'Search by name, ticket category..'}
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                fullWidth
                InputProps={{ sx: { fontSize: { xs: 16, xl: 16 } } }}
                InputLabelProps={{
                  shrink: false,
                  sx: { fontSize: { xs: 16, xl: 16 } },
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  ) : (
    <></>
  );
};

export default ManageRequestsTopBarMobile;
