import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Parse from 'parse/node';
import {
  seriaLizePromoCode,
  serializePromoCodes,
} from 'src/helpers/serializeModels';
import { PromoCode } from 'src/models/template';

/* Event slice */

interface PromoCodeState {
  code: string | null;
  error: string | null;
  isLoading: boolean;
  discount: number;
  promoCodes: PromoCode[] | null;
}

const initialState: PromoCodeState = {
  code: null,
  error: null,
  isLoading: false,
  discount: 0,
  promoCodes: null,
};

/* Thunks */

export const updatePromoCode = async (code: string) => {
  try {
    const user = Parse.User.current();
    const PromoCode = Parse.Object.extend('PromoCode');
    const promoCodeQuery = new Parse.Query(PromoCode);
    promoCodeQuery.equalTo('code', code);
    const promoCode = await promoCodeQuery.first();
    if (promoCode) {
      const currentUsersList = promoCode.get('users').users;
      if (currentUsersList) {
        const currentUserStats = currentUsersList.find(
          (userStats: any) => userStats.id === user?.id,
        );
        const currentUsedCount = promoCode.get('used');
        if (currentUsedCount) {
          promoCode.set('used', currentUsedCount + 1);
        }

        if (currentUserStats) {
          promoCode.set('users', {
            users: [
              ...currentUsersList.filter(
                (userStats: any) => userStats.id !== user?.id,
              ),
              {
                id: user?.id,
                count: currentUserStats ? currentUserStats.count + 1 : 1,
              },
            ],
          });
        } else {
          promoCode.set('users', {
            users: [
              ...currentUsersList,
              {
                id: user?.id,
                count: 1,
              },
            ],
          });
        }
      } else {
        promoCode.set('users', {
          users: [
            {
              user: user?.id,
              times: 1,
            },
          ],
        });
      }
      await promoCode.save();
    }
  } catch (error) {
    console.log('error while updating promoCode :', error);
  }
};

export const testPromoCode = async (code: string) => {
  try {
    const user = Parse.User.current();
    const PromoCode = Parse.Object.extend('PromoCode');
    const promoCodeQuery = new Parse.Query(PromoCode);
    promoCodeQuery.equalTo('code', code);
    const promoCode = await promoCodeQuery.first();
    if (promoCode) {
      if (promoCode.get('used') >= promoCode.get('limit')) {
        return false;
      }
      const currentUsersList = promoCode.get('users').users;
      if (currentUsersList) {
        const currentUserStats = currentUsersList.find(
          (userStats: any) => userStats.id === user?.id,
        );
        if (currentUserStats) {
          if (currentUserStats.count >= promoCode.get('limit')) {
            return false;
          }
        }
      }
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getPromoCode = createAsyncThunk(
  'promoCode/getPromoCode',
  async (code: string) => {
    try {
      const PromoCode = Parse.Object.extend('PromoCode');
      const promoCodeQuery = new Parse.Query(PromoCode);
      promoCodeQuery.equalTo('code', code);
      const promoCode = await promoCodeQuery.first();
      if (promoCode) {
        return promoCode.get('discount');
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);
export const getAllPromoCodes = createAsyncThunk(
  'promoCode/getAllPromoCodes',
  async (eventId: string) => {
    try {
      const PromoCode = Parse.Object.extend('PromoCode');
      const promoCodeQuery = new Parse.Query(PromoCode);
      const Event = Parse.Object.extend('Event');
      promoCodeQuery.equalTo('eventId', eventId);
      const promoCode = await promoCodeQuery.findAll();
      if (promoCode) {
        return serializePromoCodes(promoCode);
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
);

/* Shared reducers */

/* Slice */
const promoCodeSlice = createSlice({
  name: 'promoCode',
  initialState: initialState,
  reducers: {
    reset: (state) => {
      state.error = null;
      state.isLoading = false;
      state.code = null;
      state.discount = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPromoCode.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getPromoCode.rejected, (state) => {
        state.isLoading = false;
        state.error = 'error';
      })
      .addCase(getPromoCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.discount = action.payload || 0;
      })
      .addCase(getAllPromoCodes.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllPromoCodes.rejected, (state) => {
        state.isLoading = false;
        state.error = 'error';
      })
      .addCase(getAllPromoCodes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.promoCodes = action.payload || [];
      });
  },
});
export const { reset } = promoCodeSlice.actions;

export default promoCodeSlice.reducer;
