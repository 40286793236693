import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

import { Box, Stack } from '@mui/material';
import { SetState } from 'immer/dist/internal';
interface Props {
  event: Event | any;
  selectedTicket: any;
  setSelectedTicket: any;
  setTotalTicketsByType: any;
  totalTicketsByType: any;
}

const useStyles = createUseStyles({
  Stage: {
    width: "152px",
    alignSelf: "center",
    height: "35px",
    borderRadius: "10px",
    backgroundColor: "black",
    color: "white",
    textAlign: "center",
    zIndex: 4,
  },
  Stage2: {
    width: "27px",
    alignSelf: "center",
    height: "79px",
    borderRadius: "10px",
    marginTop: "-10.86px",
    backgroundColor: "black",
  },
  fourthRowVertical: {
    height: "133px",
    borderRadius: "6px",

    backgroundColor: "#91D2C1",
    width: "30px",
    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.5)",
    marginRight: "10px",
  },
  SecondRow: {
    height: "41px",
    backgroundColor: "#FBCCA6",
    width: "37px",
    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.5)",
    borderRadius: "10px",
  },
  FirstRow: {
    height: "59px",
    backgroundColor: "#F7B4DC",
    width: "28px",
    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.5)",
    borderRadius: "7px",
  },
  FirstRowHorizontal: {
    backgroundColor: "#F7B4DC",
    height: "28px",
    width: "59px",
    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.5)",
    borderRadius: "7px",
    marginRight: "5px",
    marginTop: "70px",
  },
  ThirdRow: {
    backgroundColor: "#98DEF9",
    borderRadius: "7px",
    height: "41px",
    width: "24px",
    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.5)",
  },
  FourthRowHorizontal: {
    backgroundColor: "#91D2C1",
    height: "25px",
    width: "77px",
    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.5)",
    borderRadius: "7px",
  },
  HighTables: {
    height: "179px",
    borderRadius: "10px",

    boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.5)",
  },
});
export const TableSelectorMobile: React.VFC<Props> = ({ event, selectedTicket, totalTicketsByType, setTotalTicketsByType, setSelectedTicket }) => {
  const classes = useStyles();

  const handleSelectTicket = (ticket: any) => {
    setTotalTicketsByType({
      ...totalTicketsByType,
      [selectedTicket]: 0,
    });
    setSelectedTicket(ticket);
  };
  return (
    <Stack sx={{ marginTop: "20px", marginBottom: "20px" }} alignSelf={'center'} >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        justifySelf={"center"}
        sx={{ width: "100%", marginBottom: "-60px", zIndex: 3 }}
      >
        {/* Stage 1st Box*/}
        <Box className={classes.Stage}>STAGE</Box>
        <Box className={classes.Stage2}></Box>
        {/* Stage 2nd Box*/}
      </Box>

      <Box display={"flex"} flexDirection={"column"} sx={{ marginLeft: 1, zIndex: 5 }}>
        {/* Container Box*/}
        <Box display={"flex"} flexDirection={"row"} marginLeft={"-5px"} paddingBottom={3}>
          {/* Upper Section containing Box*/}
          <Box onClick={(event) => {
            handleSelectTicket("TABLE1");
            console.log(selectedTicket);
          }}
            sx={{
              opacity:
                selectedTicket === "TABLE1" || selectedTicket == null
                  ? 1
                  : 0.2,
            }}
            className={classes.fourthRowVertical}></Box>
          {/* 1st table type*/}
          <Box display={"flex"} flexDirection={"column"}>
            {/* Group 1 container of tables left*/}
            <Box display={"flex"} flexDirection={"row"} gap={1}>
              <Box
                className={classes.SecondRow}
                onClick={(event) => {
                  handleSelectTicket("TABLE2");
                  console.log(selectedTicket);
                }}
                sx={{
                  marginBottom: "10px",
                  opacity:
                    selectedTicket === "TABLE2" || selectedTicket == null
                      ? 1
                      : 0.2,

                }}
              ></Box>
              <Box onClick={(event) => {
                handleSelectTicket("TABLE3");
                console.log(selectedTicket);
              }} sx={{
                opacity:
                  selectedTicket === "TABLE3" || selectedTicket == null
                    ? 1
                    : 0.2,
                marginLeft: 1
              }}
                className={classes.FirstRow}></Box>

            </Box>
            <Box
              display={"flex"}
              className={classes.ThirdRow}
              onClick={(event) => {
                handleSelectTicket("TABLE4");
                console.log(selectedTicket);
              }}
              sx={{
                marginBottom: "5px",
                opacity:
                  selectedTicket === "TABLE4" || selectedTicket == null
                    ? 1
                    : 0.2,

              }}
            ></Box>
            <Box
              onClick={(event) => {
                handleSelectTicket("TABLE5");
                console.log(selectedTicket);
              }}
              display={"flex"}
              className={classes.FourthRowHorizontal}
              sx={{
                marginTop: "5px",
                opacity:
                  selectedTicket === "TABLE5" || selectedTicket == null
                    ? 1
                    : 0.2,
              }}
            ></Box>
          </Box>
          <Box display={"flex"} onClick={(event) => {
            handleSelectTicket("TABLE6");
            console.log(selectedTicket);
          }}
            sx={{
              opacity:
                selectedTicket === "TABLE6" || selectedTicket == null
                  ? 1
                  : 0.2
            }} className={classes.FirstRowHorizontal}></Box>
          <Box display={"flex"} flexDirection={"column"}>
            <Box display={"flex"} flexDirection={"row"}>
              <Box onClick={(event) => {
                handleSelectTicket("TABLE7");
                console.log(selectedTicket);
              }} sx={{

                opacity:
                  selectedTicket === "TABLE7" || selectedTicket == null
                    ? 1
                    : 0.2,

              }}
                className={classes.FirstRow}></Box>
              <Box
                onClick={(event) => {
                  handleSelectTicket("TABLE8");
                  console.log(selectedTicket);
                }}
                className={classes.SecondRow}
                sx={{
                  marginLeft: "10px",
                  opacity:
                    selectedTicket === "TABLE8" || selectedTicket == null
                      ? 1
                      : 0.2,

                }}
              ></Box>
            </Box>
            <Box
              className={classes.ThirdRow}
              onClick={(event) => {
                handleSelectTicket("TABLE9");
                console.log(selectedTicket);
              }}
              sx={{
                ml: "40px",
                opacity:
                  selectedTicket === "TABLE9" || selectedTicket == null
                    ? 1
                    : 0.2,
              }}
            ></Box>

            <Box
              onClick={(event) => {
                handleSelectTicket("TABLE10");
                console.log(selectedTicket);
              }}
              className={classes.FourthRowHorizontal}
              sx={{
                opacity:
                  selectedTicket === "TABLE10" || selectedTicket == null
                    ? 1
                    : 0.2,
                mt: "10px",
              }}
            ></Box>
          </Box>
          <Box
            className={classes.fourthRowVertical}
            onClick={(event) => {
              handleSelectTicket("TABLE11");
              console.log(selectedTicket);
            }}
            sx={{
              opacity:
                selectedTicket === "TABLE11" || selectedTicket == null
                  ? 1
                  : 0.2,
              ml: "10px",
            }}
          ></Box>
        </Box>
        <Box display={"flex"} flexDirection={"row"} gap={2}>
          <Box
            className={classes.HighTables}
            onClick={(event) => {
              handleSelectTicket("TABLE12");
              console.log(selectedTicket);
            }}

            sx={{
              opacity:
                selectedTicket === "TABLE12" || selectedTicket == null
                  ? 1
                  : 0.2,
              backgroundColor: "#E9D7CB",
              width: "60px",
            }}
          ></Box>
          <Box
            onClick={(event) => {
              handleSelectTicket("TABLE13");
              console.log(selectedTicket);
            }}
            className={classes.HighTables}
            sx={{
              opacity:
                selectedTicket === "TABLE13" || selectedTicket == null
                  ? 1
                  : 0.2,
              backgroundColor: "#F6F9EB",
              width: "146px",
            }}
          ></Box>
          <Box
            onClick={(event) => {
              handleSelectTicket("TABLE14");
              console.log(selectedTicket);
            }}
            className={classes.HighTables}
            sx={{
              opacity:
                selectedTicket === "TABLE14" || selectedTicket == null
                  ? 1
                  : 0.2,
              backgroundColor: "#E9D7CB",
              width: "60px",
            }}
          ></Box>
        </Box>
      </Box>
    </Stack>
  );
};
