import React, { useState } from 'react';

import { createUseStyles } from 'react-jss';
import Icon from './Icon/Icon';

const useStyles = createUseStyles({
  container: {
    paddingTop: '32px',
    paddingBottom: '18px',
    borderBottom: '0.8px solid #DCDCDC',
    '@media only screen and (max-width: 670px)': {
      paddingTop: '18px',
      paddingBottom: '4px',
      width: '80%',
    },
    '&:last-child': { borderBottom: 'none' },
    '& a': {
      textDecoration: 'none',
      color: '#000',
      fontWeight: 'bold',
    },
  },

  questionContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': { opacity: 0.8 },
  },
  icon: {
    position: 'relative',
    width: '44px',
    height: '44px',
    minWidth: '44px',
    marginRight: '16px',
  },
  circle: {
    width: '44px',
    height: '44px',
    position: 'absolute',
    transition: '200ms ease-in-out all',
  },
  circleOpen: {
    width: '44px',
    height: '44px',
    position: 'absolute',
    transition: '200ms ease-in-out all',
    transform: 'rotateZ(180deg)',
  },
  symbolOpen: {
    left: '6px',
    top: '6px',
    width: '32px',
    height: '32px',
    position: 'absolute',
    transition: '200ms ease-in-out all',
    transform: 'rotateZ(-180deg)',
  },
  symbol: {
    left: '6px',
    top: '6px',
    width: '32px',
    height: '32px',
    position: 'absolute',
    transition: '200ms ease-in-out all',
  },
  question: {
    fontSize: '24px',
    lineHeight: '44px',
    color: '#212121',
    fontWeight: 500,
    '@media only screen and (max-width: 670px)': {
      fontSize: '17px',
      fontWeight: 600,
      lineHeight: '18px',
    },
  },
  text: {
    marginTop: '16px',
    paddingLeft: '62px',
    fontSize: '18px',
    lineHeight: '27px',
    color: '#212121',
    fontWeight: 400,
    height: '0',
    overflow: 'hidden',
    transition: 'all 50ms ease-in-out',
    '@media only screen and (max-width: 670px)': {
      fontSize: '14px',
      lineHeight: '21px',
      marginTop: '8px',
      marginBottom: '20px',
    },
  },
  textVisible: { height: 'auto' },
});

interface Props {
  question: string;
  className?: string;
  children: any;
}

export const FAQ: React.FC<Props> = ({ question, children, className }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const onToggleHandler = () => setIsOpen((value) => !value);

  return (
    <div className={`${classes.container} ${className}`}>
      <div onClick={onToggleHandler} className={classes.questionContainer}>
        <div className={classes.icon}>
          <div className={isOpen ? classes.circleOpen : classes.circle}>
            <Icon size={44} name="circle" />
          </div>
          {isOpen ? (
            <div className={isOpen ? classes.symbolOpen : classes.symbol}>
              <Icon size={32} name="minusThin" />
            </div>
          ) : (
            <div className={isOpen ? classes.symbolOpen : classes.symbol}>
              <Icon size={32} name="plusThin" />
            </div>
          )}
        </div>
        <h3 className={classes.question}>{question}</h3>
      </div>
      <p
        className={`${classes.text} ${
          isOpen ? classes.textVisible : undefined
        }`}
      >
        {children}
      </p>
    </div>
  );
};

export default FAQ;
