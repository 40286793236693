import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { getEventById } from '../slices/event';
import { getCities } from '../slices/project';
import { useParams } from 'react-router-dom';
import FullscreenSpin from 'src/components/FullscreenSpin';
import { EventPageContent } from 'src/containers/EventPageContent';

const EventPage: React.VFC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const { event, cities } = useAppSelector(
    ({ event: { currentlyDisplayedEvent }, project: { cities } }) => ({
      event: currentlyDisplayedEvent,
      cities: cities,
    }),
  );

  const [isEventLoaded, setIsEventLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!cities || cities.length === 0) {
      dispatch(getCities());
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getEventById(id));
      setIsEventLoaded(true);
    }
  }, [id]);

  if (!event && !isEventLoaded) {
    return <FullscreenSpin />;
  }
  return event && <EventPageContent event={event} />;
};

export default EventPage;
