import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Box, Button, Modal, Stack, Typography } from '@mui/material';
import { TicketForm } from './TicketForm';
import {
  MobileTicketGuestSection,
  GuestFormValues,
} from './MobileTicketGuestSection';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { setPurchasedTicketsFormState } from 'src/slices/ticket';
import { Event } from 'src/models/event';
import { CheckoutForm } from 'src/containers/CheckoutForm';
import { Token } from '@stripe/stripe-js';
import { theme } from 'src/constants/theme';
import { SignUpValues } from 'src/containers/EventPageContent';
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from 'react-icons/all';
import { UserGender } from '../../models/user';
import { PackageForm } from './PackageForm';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Swiper as SwiperType } from 'swiper';

const useStyles = createUseStyles({
  modalBody: {
    backgroundColor: 'rgba(255,255,255, 1)',
    width: '100rw',

    zIndex: 2,
    paddingBottom: theme.spacing(2),
    borderRadius: '15px',
  },
  nonModalBody: {
    backgroundColor: 'rgba(255,255,255, 1)',
    boxShadow: '0px 0px 23px 0px rgba(0,0,0,0.30)',
    zIndex: 2,
    paddingBottom: theme.spacing(2),
    position: 'relative',
    borderRadius: '11.7px',
    width: 900,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    float: 'left',
  },
  arrowButton: {
    fontSize: 60,
    color: 'white !important',
    fill: 'white !important',
    stroke: 'white !important',
  },
  title: {
    fontSize: '30px',
    fontWeight: 800,
    color: 'black',
    lineHeight: '36px',
  },
  subtitle: {
    fontSize: '13px',
    color: 'black',
    fontWeight: 600,
    lineHeight: '14px',
  },
  endSaleDate: {
    fontSize: '14.5px',
    color: 'black',
    fontWeight: 500,
    opacity: 0.24,
    lineHeight: '16px',
    marginBottom: '16.61px',
  },
  text: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'black',
    lineHeight: '18px',
  },
  mediumText: {
    fontSize: '14.5px',
    fontWeight: 600,
    color: 'black',
    lineHeight: '18px',
  },
  ticketForm: {
    padding: '27.9px 30.9px',
  },
  button: {
    width: '296.1px',
    height: '45px',
    borderRadius: '22.5px',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '19px',
  },
  backDrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: '100%',
    minHeight: '100vh',
  },
  noTicketsAvailableText: {
    fontSize: '15px',
    textAlign: 'center',
    fontWeight: 700,
    color: 'black',
    lineHeight: '18px',
    opacity: 0.49,
  },
  carouselButton: {
    fontSize: '41px',
    color: '#000',
    cursor: 'pointer',
    marginBottom: '27px !important',
  },
  disabled: {
    color: '#000',
    opacity: 0.1,
  },
});

interface Props {
  open?: boolean;
  isModal?: boolean;
  handleClose?: any;
  cityColor: string;
  event: Event;
  totalTicketsByType: TicketsNumberByType;
  totalNumberOfTickets: number;
  totalPrice: number;
  totalFees: number;
  isPaymentModalOpen?: boolean;
  isRightAmountOfTickets?: boolean;
  onReservation: boolean;
  isBuyTicketsFormOpen?: boolean;
  isPaymentOpen: boolean;
  onRedirectToLogin: () => void;
  setTotalTicketsByType: React.Dispatch<
    React.SetStateAction<TicketsNumberByType>
  >;
  onCloseBuyTicketsForm?: () => void;
  onOpenBuyTicketsForm?: () => void;
  onClosePayment?: () => void;
  onSubmitRequest: (
    guestFormValues: GuestFormValues,
    signUpValues?: SignUpValues,
  ) => void;
  onSubmitBuyTickets: (signUpValues?: SignUpValues) => void;
  onSubmitPayment: (token: Token) => void;
  noTicketsAvailable: boolean;
}

export interface GuestForm {
  name: string;
  email?: string;
  link?: string;
  phone?: string;
  gender?: UserGender;
  isUser?: boolean;
}

export type TicketsNumberByType = { [name: string]: number };

export const ModalPackage: React.VFC<Props> = ({
  open = true,
  isModal = true,
  handleClose,
  cityColor,
  event,
  onReservation,
  totalTicketsByType,
  totalNumberOfTickets,
  totalPrice,
  isPaymentModalOpen = true,
  isRightAmountOfTickets = true,
  onClosePayment,
  onSubmitRequest,
  onSubmitPayment,
  setTotalTicketsByType,
  onSubmitBuyTickets,
  onRedirectToLogin,
  totalFees,
  noTicketsAvailable,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { isLoading, ticketsSuccessfullyBought, user, error } = useAppSelector(
    ({
      ticket: { isLoading, ticketsSuccessfullyBought, error },
      authentication: { user },
    }) => ({
      isLoading,
      ticketsSuccessfullyBought,
      user,
      error,
    }),
  );
  const [currentSelectedTicket, setCurrentSelectedTicket] =
    useState<string>('');

  const [isGuestsModalOpen, setIsGuestModalOpen] = useState(false);

  useEffect(() => {
    if (error || ticketsSuccessfullyBought) {
      setIsGuestModalOpen(false);
    }
  }, [error, ticketsSuccessfullyBought]);

  const resetPurchasedTicketsFormState = () => {
    dispatch(setPurchasedTicketsFormState(null));
  };

  function onChangeTicket(ticketId: string, value: number) {
    if (
      totalTicketsByType[currentSelectedTicket] !== 0 &&
      currentSelectedTicket !== null &&
      currentSelectedTicket !== undefined &&
      currentSelectedTicket !== ''
    ) {
      totalTicketsByType[currentSelectedTicket] = 0;
      setTotalTicketsByType({
        ...totalTicketsByType,
        [currentSelectedTicket]: 0,
      });
    }

    setCurrentSelectedTicket(ticketId);
    setTotalTicketsByType({
      ...totalTicketsByType,
      [ticketId]: value,
    });
  }
  const onClickContinue = () => {
    if (user && !onReservation) {
      onSubmitBuyTickets();
    } else if (!user) {
      setIsGuestModalOpen(true);
    } else {
      setIsGuestModalOpen(true);
    }
  };

  const onCloseGuestModal = () => {
    setIsGuestModalOpen(false);
  };

  const EndDate = new Date(event.endDate);
  const EndDateUTC = new Date(EndDate.toUTCString());
  const dateString = EndDateUTC.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
  });
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);
  const [allowSwipe, setAllowSwipe] = useState({
    prev: false,
    next: true,
  });

  useEffect(() => {
    setPrevEl(prevEl);
    setNextEl(nextEl);
  }, [prevEl, nextEl]);

  const buildTicketForm = () => (
    <Box className={classes.ticketForm}>
      <Typography display="inline" className={classes.title}>
        Packages
      </Typography>
      <Typography display="inline" className={classes.subtitle}>
        {' + Booking fees'}
      </Typography>
      {!noTicketsAvailable ? (
        <>
          <Typography className={classes.endSaleDate}>
            {`Choose the number of Festival Tickets you need for your group to view the full amount.`}
          </Typography>
          <Stack direction={isModal ? 'column' : 'row'} spacing={1}>
            {event.packages && event.packages?.length > 3 && (
              <div
                style={{
                  display: 'flex',
                  marginTop: '198px',
                  flexDirection: 'row',
                  zIndex: 3,
                  left: '5px',

                  position: 'absolute',

                  border: '1px  #000000',
                  visibility: allowSwipe.prev ? 'visible' : 'hidden',
                }}
                ref={(node) => setPrevEl(node)}
                className={`${classes.carouselButton} ${
                  allowSwipe.prev ? undefined : classes.disabled
                }`}
              >
                <IoIosArrowDropleftCircle />
              </div>
            )}

            <Swiper
              spaceBetween={10}
              direction={isModal ? 'vertical' : 'horizontal'}
              slidesPerView={isModal ? 1 : 3}
              width={isModal ? 300 : 800}
              onSlideChange={(swiper) => {
                setAllowSwipe({
                  prev: !swiper.isBeginning,
                  next: !swiper.isEnd,
                });
              }}
              modules={[Navigation, Pagination]}
              navigation={{ prevEl, nextEl }}
            >
              {(
                event.packages?.filter((ticket) => !ticket.onInvitation) ?? []
              ).map((eventTicket, index) => (
                <div key={index}>
                  <SwiperSlide key={index}>
                    <PackageForm
                      key={index}
                      cityColor={cityColor}
                      onChangeNumberOfTickets={(value: number) => {
                        if (eventTicket.id) {
                          onChangeTicket(eventTicket.id, value);
                        }
                      }}
                      numberOfTickets={
                        eventTicket.id ? totalTicketsByType[eventTicket.id] : 0
                      }
                      minimum={eventTicket.min}
                      maximum={eventTicket.max}
                      ticket={eventTicket}
                      currency={event.currencySymbol}
                    />

                    <Stack
                      direction="column"
                      justifyContent="center"
                      spacing="5.6px"
                      marginTop="20.5px"
                      width={'90%'}
                    >
                      <Stack
                        key={index}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Typography className={classes.mediumText}>
                          Sub total
                        </Typography>
                        <Typography className={classes.mediumText}>
                          {`${event.currency} `}

                          {eventTicket.id && totalTicketsByType[eventTicket.id]
                            ? totalPrice - totalFees
                            : 0}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography className={classes.mediumText}>
                          Booking fees
                        </Typography>
                        <Typography className={classes.mediumText}>
                          {`${event.currency} `}
                          {eventTicket.id && totalTicketsByType[eventTicket.id]
                            ? totalFees
                            : 0}
                        </Typography>
                      </Stack>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography className={classes.text}>Total</Typography>
                        <Typography className={classes.text}>
                          {`${event.currency} `}
                          {eventTicket.id && totalTicketsByType[eventTicket.id]
                            ? totalPrice
                            : 0}
                        </Typography>
                      </Stack>
                    </Stack>

                    <div
                      style={{
                        display: 'block',
                        justifyContent: 'center',
                        marginTop: '2em',
                        position: 'revert',
                        bottom: '0',

                        width: '250px',
                      }}
                    >
                      <Button
                        onClick={onClickContinue}
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor: noTicketsAvailable
                            ? `${cityColor} !important`
                            : `${cityColor}`,
                          textTransform: 'none',
                          color: noTicketsAvailable
                            ? 'white !important'
                            : 'white',
                          opacity: noTicketsAvailable ? '0.39' : '1',
                          borderRadius: '20px',
                        }}
                        // className={classes.button}
                        disabled={
                          isRightAmountOfTickets === false ||
                          (eventTicket.id &&
                          totalTicketsByType[eventTicket.id] > 0
                            ? false
                            : true)
                        }
                      >
                        {!(user && onReservation) || noTicketsAvailable
                          ? 'Buy Tickets'
                          : 'Continue'}
                      </Button>
                    </div>
                  </SwiperSlide>
                </div>
              ))}
            </Swiper>
            {event.packages && event.packages?.length > 3 && (
              <div
                style={{
                  display: 'flex',
                  marginTop: '200px',
                  flexDirection: 'row',
                  zIndex: 3,

                  position: 'absolute',

                  border: '1px  #000000',
                  visibility: allowSwipe.next ? 'visible' : 'hidden',
                  right: '8px',
                }}
                ref={(node) => setNextEl(node)}
                className={`${classes.carouselButton} ${
                  allowSwipe.next ? undefined : classes.disabled
                }`}
              >
                <IoIosArrowDroprightCircle />
              </div>
            )}
          </Stack>
        </>
      ) : (
        <Stack sx={{ marginTop: '56.71px' }}>
          <Typography className={classes.noTicketsAvailableText}>
            Sorry, there are no tickets available
          </Typography>
          <Typography className={classes.noTicketsAvailableText}>
            for this event.
          </Typography>
        </Stack>
      )}

      {
        <MobileTicketGuestSection
          open={isGuestsModalOpen}
          handleClose={() => {
            setIsGuestModalOpen(false);
            handleClose ? handleClose() : onCloseGuestModal;
          }}
          cityColor={cityColor}
          eventName={`${event.name} - ${event.dj}` ?? ''}
          numberOfTickets={totalNumberOfTickets}
          onSubmit={onSubmitRequest}
          onSignUp={onSubmitBuyTickets}
          onReservation={onReservation}
          isLoggedIn={!!user}
          onRedirectToLogin={onRedirectToLogin}
          onClickBack={onCloseGuestModal}
          isPackages={true}
        />
      }
    </Box>
  );

  return isModal ? (
    <>
      {!isPaymentModalOpen ? (
        <Modal
          open={open}
          onClose={() => {
            resetPurchasedTicketsFormState();
            handleClose();
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            overflow: 'auto',
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing="33px"
            style={{ top: '310px', position: 'absolute' }}
          >
            <Stack direction="row" justifyContent="center">
              <IoIosArrowDropdownCircle
                onClick={handleClose}
                className={classes.arrowButton}
              />
            </Stack>
            <div className={isModal ? classes.modalBody : classes.nonModalBody}>
              {buildTicketForm()}
            </div>
          </Stack>
        </Modal>
      ) : (
        <Modal
          open={open}
          onClose={() => {
            resetPurchasedTicketsFormState();
            handleClose();
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            overflow: 'auto',
            position: 'absolute',
          }}
        >
          <div
            className={isModal ? classes.modalBody : classes.nonModalBody}
            style={{ backgroundColor: 'transparent' }}
          >
            <CheckoutForm
              canExit
              cityColor={cityColor}
              onSuccess={onSubmitPayment}
              onError={(error) => console.log(error)}
              onClose={() => {
                if (onClosePayment) {
                  onClosePayment();
                }
              }}
              amount={totalPrice}
              currency={event.currencySymbol ?? '$'}
              isPaymentLoading={isLoading}
              isPaymentSuccessful={ticketsSuccessfullyBought ?? false}
            />
          </div>
        </Modal>
      )}
    </>
  ) : (
    <div className={isModal ? classes.modalBody : classes.nonModalBody}>
      {buildTicketForm()}
    </div>
  );
};

export default ModalPackage;
