import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Button, Stack, TextField, Typography, Link } from "@mui/material";
import dashedDownArrow from '../../assets/dashedDownArrows.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useAppDispatch } from 'src/hooks/store';
import CheckMark from "../../assets/RedCheckIcon.png";
import KingIcon from "../../assets/KingIcon.png";
import { sendSupportEmail } from "src/slices/project";
const useStyles = createUseStyles({
  root: {
    [`& fieldset`]: {
      borderRadius: 50,
    },
    "&:hover": {
      border: "1px solid #FF0202",
      borderRadius: 50,
    },
  },
  subHeader: {
    fontWeight: 500,
    fontSize: "18px",
    color: "text.primary",
    underline: "none",
    lineHeight: "30px",
  },
  textArea: {
    [`& fieldset`]: {
      borderRadius: 20,
    },
    "&:hover": {
      borderRadius: 20,
      border: "1px solid #FF0202",
    },
  },
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: 10,
  },
  img: {
    objectFit: "cover",
    width: "10%",
    borderRadius: 10,
    [`@media (max-width: 768px)`]: {
      width: "30%",
    },
  },
  icon: {
    fontSize: "75px",
  },
  textField: {
    width: 300,
    textAlign: "center",
  },
  button: {
    borderRadius: 50,
    backgroundColor: "#F70303",
    "&:hover": {
      backgroundColor: "#FF0202",
      opacity: 0.7,
    },
  },
  getInTouchText: {
    fontSize: "22px",
    display: "inline",
  },
  successText: {
    fontSize: "16px",
    fontWeight: 400,
    display: "inline",
  },
});

const GetInTouchCustomer: React.FC = () => {
  const classes = useStyles();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isSent, setIsSent] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const getInTouch = () => {
    dispatch(
      sendSupportEmail({
        name,
        email,
        message,
      }),
    );

    setIsSent(true);
  };
  return (
    <div className={classes.mainContainer}>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="h2">Get in Touch</Typography>
          <p className={classes.getInTouchText}>
            {"Have any"} <strong> {" Questions?"} </strong> {" We’re "}{" "}
            <br></br>
            {` here to `} <strong>{"help!"}</strong>
          </p>

          <Typography className={classes.subHeader}>
            <img
              src={KingIcon}
              style={{
                width: "30px",
                height: "20px",
                marginRight: "2px",
                display: "inline-flex",
                marginTop: "30px",
                marginBottom: -1,
              }}
            ></img>{" "}
            <Link
              color="text.primary"
              underline="none"
              href="/terms-and-conditions"
            >
              {" "}
              Terms And Conditions{" "}
            </Link>
          </Typography>
          <Typography className={classes.subHeader}>
            <img
              src={KingIcon}
              style={{
                width: "30px",
                height: "20px",
                marginRight: "2px",
                display: "inline-flex",
                marginBottom: -1,
              }}
            ></img>{" "}
            <Link color="text.primary" underline="none" href="/privacy-policy">
              {" "}
              Privacy Policy{" "}
            </Link>
          </Typography>
        </Stack>

        {!isSent ? (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {" "}
            <TextField
              className={`${classes.root} ${classes.textField}`}
              label="Name"
              size="small"
              onChange={(event) => setName(event.target.value)}
              value={name}
            />
            <TextField
              className={`${classes.root} ${classes.textField}`}
              label="Email"
              size="small"
              onChange={(event) => setEmail(event.target.value)}
              value={email}
            />
            <TextField
              className={`${classes.textArea} ${classes.textField}`}
              label="Message"
              multiline
              minRows={6}
              onChange={(event) => setMessage(event.target.value)}
              value={message}
            />
            <Button
              className={classes.button}
              variant="contained"
              fullWidth
              onClick={getInTouch}
            >
              Send
            </Button>
          </Stack>
        ) : (
          <Stack direction="column" justifyContent="center" alignItems="center">
            <img width={"86px"} height={"86px "} src={CheckMark} alt="-->" />
            <Typography
              color={"#F70303"}
              className={classes.getInTouchText}
              variant="h4"
            >
              Thanks {name}
            </Typography>
            <Typography
              className={classes.successText}
              color={"#777777"}
              variant="h4"
            >
              We received your question and we will{" "}
            </Typography>
            <Typography
              className={classes.successText}
              color={"#777777"}
              variant="h4"
            >
              get back to you shortly.
            </Typography>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default GetInTouchCustomer;
