import React from 'react';
import { Stack, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { createUseStyles } from 'react-jss';
import ThumbLeft from '../../../assets/images/loyalty/loyalty_1.png';
import ThumbRight from '../../../assets/images/loyalty/loyalty_2.png';
import LeftVideo from '../../../assets/video/LoyaltySystem/loyaltySystemLeft.mov';
import RightVideo from '../../../assets/video/LoyaltySystem/loyaltySystemRight.mov';

const useStyles = createUseStyles({
  container: {},
  videoBox: {
    '& video': {
      width: '100%',
      height: '100%',
      borderRadius: '40px',
      border: 'solid black',
      borderWidth: '12px',
    },
    width: '242.1px',
    zIndex: '1 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    borderRadius: '35px',
    '@media screen and (max-width: 900px)': {
      '& video': {
        width: '100%',
        height: '100%',
        borderRadius: '30px',
        borderWidth: '9px',
      },
      width: '170px',
    },
  },
  overlay: {
    width: '78.57px',
    height: '98.01px',
    background: '#2CB9A2',
    zIndex: '-1',
    borderRadius: '8.36871px 13.6379px 13.6379px 13.6379px',
    marginLeft: '93%',
    marginTop: '-70px',
    '@media screen and (max-width: 900px)': {
      width: '60px',
      height: '70px',
      marginLeft: '300px',
      marginTop: '-40px',
    },
  },
});

export const LoyaltySystemVideos = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 900px)', {
    noSsr: true,
  });
  return (
    <Box className={classes.container}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={isMobile ? '3%' : '69.3px'}
      >
        <Box className={classes.videoBox}>
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbLeft}
          >
            <source src={LeftVideo} type="video/mp4" />
          </video>
        </Box>
        <Box className={classes.videoBox}>
          <video
            autoPlay
            playsInline
            muted
            loop
            className="top-animation-video-1 grid__item small-video"
            poster={ThumbRight}
          >
            <source src={RightVideo} type="video/mp4" />
          </video>
        </Box>
      </Stack>
      <div className={classes.overlay} />
    </Box>
  );
};
