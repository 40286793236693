import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { getCities } from 'src/slices/project';
import 'react-datepicker/dist/react-datepicker.css';
import { getEventById } from 'src/slices/event';
import { useParams } from 'react-router-dom';
import { UpdateEventForm } from 'src/containers/UpdateEventForm';
import FullscreenSpin from 'src/components/FullscreenSpin';
import { EventTicket } from 'src/models/ticket';
import FooterBar from 'src/components/FooterBar';
import { Box } from '@mui/system';

export interface FormValues {
  timezone?: string;
  currency?: string;
  name?: string;
  imageFile?: File;
  startDate: Date;
  endDate: Date;
  venueName?: string;
  streetAddress?: string;
  dj?: string;
  djprofileUrl?: string;
  musicType?: string;
  fashion?: string;
  minimumAge?: number;
  description?: string;
  tickets?: EventTicket[];
  onReservation?: boolean;
  ticketsExpireIn?: number;
  usePaymob?: boolean;
}

function UpdateEventPage() {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { currentlyDisplayedEvent, cities } = useAppSelector(
    ({ event: { currentlyDisplayedEvent }, project: { cities } }) => ({
      currentlyDisplayedEvent,
      cities,
    }),
  );
  const getEventData = async (eventId: string) => {
    await dispatch(getEventById(eventId));
  };

  useEffect(() => {
    if (!cities || cities.length === 0) {
      dispatch(getCities());
    }
  }, []);

  useEffect(() => {
    getEventData(id);
  }, [id]);

  if (!currentlyDisplayedEvent) {
    return <FullscreenSpin />;
  }
  return (
    <>
      <UpdateEventForm
        id={id}
        currentlyDisplayedEvent={currentlyDisplayedEvent}
      />
    </>
  );
}

export default UpdateEventPage;
