import { DateTime } from 'luxon';

export const getFirstDayOfTheWeek = () => {
  const currentDate = new Date();
  const firstDay = currentDate.getDate() - currentDate.getDay();
  return new Date(currentDate.setDate(firstDay));
};

export const getLastDayOfTheWeek = () => {
  const currentDate = new Date();
  const lastDay = currentDate.getDate() + 7 - currentDate.getDay();
  return new Date(currentDate.setDate(lastDay));
};

export const isSameDate = (firstDate: Date, secondDate: Date) => {
  firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate();
};

export const isDateDuringThisWeek = (date: Date) => {
  const firstDayOfTheWeek = getFirstDayOfTheWeek();
  const lastDayOfTheWeek = getLastDayOfTheWeek();
  return (
    date.getTime() >= firstDayOfTheWeek.getTime() &&
    date.getTime() <= lastDayOfTheWeek.getTime()
  );
};

export const addDateSuffix = (day: number): string => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const dayPosition = day % 100;
  return (
    day + (suffix[(dayPosition - 20) % 10] || suffix[dayPosition] || suffix[0])
  );
};

export const getDateInTimeZone = (date: Date, timeZone: string | undefined) => {
  if (!timeZone) {
    return date;
  }
  const dateTime = DateTime.fromJSDate(date);
  return dateTime
    .setZone(timeZone, {
      keepLocalTime: true,
    })
    .toJSDate();
};
