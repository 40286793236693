import React from 'react';
import { Stack, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { Box } from '@mui/system';
import RegularBox from './RegularBox';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Stacked } from '../../../assets/icons/newsFeed/stacked.svg';

const useStyles = createUseStyles({
  container: {
    marginTop: 161,
    marginBottom: 140,
    '@media screen and (max-width: 900px)': {
      marginLeft: '5%',
    },
  },
  newsFeedText: {
    color: 'black',
    fontSize: 17,
    fontWeight: 600,
  },
  newsFeedTextContainer: {
    color: 'black',
    marginTop: 5,
    marginBottom: 24,
    width: 747,
    '@media screen and (max-width: 900px)': {
      width: '90%',
    },
  },
  newsFeedDocs: {
    width: '100%',
    position: 'relative',
  },
  title: {
    color: '#FF5A6D',
    textAlign: 'start',
    fontSize: 60,
    fontWeight: 800,
  },
  subTitle: {
    color: 'black',
    textAlign: 'start',
    fontWeight: 'bold',
    fontSize: 32,
    '@media screen and (max-width: 500px)': {
      lineHeight: '32px',
      marginBottom: '20px',
    },
  },
  regularBoxContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr',
    gap: 26.82,
    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
    },
    '@media screen and (max-width: 646px)': {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr',
    },
  },
  titleIcon: {
    fontSize: '45px',
    width: '70px',
    height: '70px',
    '@media screen and (max-width: 1200px)': {
      width: '50px',
      height: '50px',
      fontSize: '32px',
    },
    '@media screen and (max-width: 900px)': {
      width: '40px',
      height: '40px',
      fontSize: '32px',
    },
  },
  boldText: {
    color: 'black',
    fontSize: 18,
    fontWeight: 800,
  },
});
const NewsFeed = () => {
  const classes = useStyles();
  return (
    <Box id="newsFeed" className={classes.container}>
      <div className={classes.newsFeedDocs}>
        <Box>
          <Stack
            direction="column"
            alignItems={'left'}
            justifyContent="center"
            width="100%"
          >
            <SvgIcon
              component={Stacked}
              className={classes.titleIcon}
              inheritViewBox
            />
            <Typography className={classes.title}>NewsFeed.</Typography>
            <Typography className={classes.subTitle}>
              Daily Interactive Content
            </Typography>
          </Stack>
        </Box>
        <Box className={classes.newsFeedTextContainer}>
          <Typography className={classes.newsFeedText} display="inline">
            {
              'The feed of Techno & Chill is where most of our fans hang out to find out about the '
            }
          </Typography>
          <Typography className={classes.boldText} display="inline">
            latest techno news
          </Typography>
          <Typography className={classes.newsFeedText} display="inline">
            {
              '. Scrolling down our feed will give you the opportunity to discover '
            }
          </Typography>
          <Typography className={classes.boldText} display="inline">
            {'funny techno videos , blog articles, '}
          </Typography>
          <Typography className={classes.newsFeedText} display="inline">
            {'learn about the '}
          </Typography>
          <Typography className={classes.boldText} display="inline">
            {'latest events, '}
          </Typography>
          <Typography className={classes.newsFeedText} display="inline">
            {'have access to the '}
          </Typography>
          <Typography className={classes.boldText} display="inline">
            {'most recent songs '}
          </Typography>
          <Typography className={classes.newsFeedText} display="inline">
            and literally everything that has to do with techno.
          </Typography>
        </Box>
        <div className={classes.regularBoxContainer}>
          <RegularBox icon="content" title="Daily Content">
            uploaded by the community.
          </RegularBox>
          <RegularBox icon="dj" title="Most Recent Labels">
            growing in the techno world.
          </RegularBox>
          <RegularBox icon="technoCalendar" title="The Best Events">
            with unbeatable discounts.
          </RegularBox>
        </div>
      </div>
    </Box>
  );
};

export default NewsFeed;
