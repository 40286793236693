import { Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import EventAdmissionsCard from 'src/components/event/EventAdmissionsSubCard';
import { Event } from 'src/models/event';
import MyEventsCard from 'src/components/event/MyEventsCard';
import { createUseStyles } from 'react-jss';
import { EventTicket } from 'src/models/ticket';
import EventBarChartCard from './EventBarChartCard';
import { getEventDates } from 'src/helpers/event/getEventDates';

interface Props {
  event: Event;
  ticketSales?: { [date: string]: number };
  totalSales?: string;
}

const useStyles = createUseStyles({
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    verticalAlign: 'middle',
  },
  subtitle: {
    fontSize: '20px',
    justifyContent: 'center',
    color: 'grey',
    fontWeight: '600',
  },
  currency: {
    fontSize: '18px',
    '@media (max-width: 1500px)': {
      fontSize: '14.4px',
    },
    fontWeight: 800,
  },
  price: {
    fontSize: '35px',
    fontWeight: 800,
    '@media (max-width: 1500px)': {
      fontSize: '28px',
    },
  },
});

export type TicketsNumberByDay = {
  [day: string]: number;
};

const EventPlannerCard: React.VFC<Props> = ({
  event,
  ticketSales,
  totalSales,
}) => {
  const classes = useStyles();
  const getTotalPrice = (tickets: EventTicket[]) => {
    return tickets.reduce(
      (accumulator: number, ticket: EventTicket) =>
        accumulator +
        ((ticket.price ?? 0) + (ticket.fees ?? 0)) * (ticket.sold ?? 0),
      0,
    );
  };
  const ticketsTotalPrice = useMemo<number>(
    () => getTotalPrice(event.tickets ?? []),
    [event],
  );

  const ticketsNumberByDay = useMemo<TicketsNumberByDay>(() => {
    const initialTicketsNumberByDay: TicketsNumberByDay = {};
    const eventCreatedDate = event.createdAt
      ? new Date(event.createdAt)
      : new Date();
    const eventStartDate = getEventDates(event).startDate || new Date();
    for (
      let i = eventCreatedDate;
      i < eventStartDate;
      i.setDate(i.getDate() + 1)
    ) {
      const date = i;

      initialTicketsNumberByDay[date.toISOString()] = 0;
    }
    if (ticketSales) {
      Object.entries(ticketSales).forEach(([date, number]) => {
        const noTimeDate = new Date(date);

        initialTicketsNumberByDay[noTimeDate.toISOString()] = number;
      });
    }
    return initialTicketsNumberByDay;
  }, [ticketSales]);
  return (
    <Grid container direction="row" justifyContent="center" spacing={5}>
      <Grid item xs={2.75} className={classes.centered}>
        <MyEventsCard event={event} />
      </Grid>
      <Grid item xs={3.75}>
        <EventAdmissionsCard event={event} />
      </Grid>
      <Grid
        item
        xs={4.5}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography
            variant="size4"
            color="GrayText"
            fontWeight={600}
            textAlign="center"
            paddingTop={2}
          >
            Sales/day
          </Typography>
        </Grid>
        <Grid container item direction="row" xs={8} alignItems="center">
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="center"
            paddingTop={2}
          >
            <Typography
              variant="size4"
              color="GrayText"
              fontWeight={600}
              textAlign="end"
            >
              Gross Sold
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            display="flex"
            justifyContent="center"
            alignItems="start"
          >
            <Typography paddingTop={2}>
              <Typography
                fontSize={{ xs: 28, xl: 35 }}
                fontWeight={800}
                display="inline"
                lineHeight={{ xs: '28px', xl: '35px' }}
                sx={{ verticalAlign: 'baseline' }}
              >
                {Number(totalSales)?.toFixed(2)}
              </Typography>
              <Typography
                fontSize={{ xs: 14.4, xl: 18 }}
                fontWeight={800}
                display="inline"
                sx={{ verticalAlign: 'baseline' }}
              >
                {event?.currency ?? 'USD'}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} paddingTop="10px">
          <EventBarChartCard
            ticketNumberByDay={ticketsNumberByDay}
            event={event}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EventPlannerCard;
