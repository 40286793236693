import React from 'react';
import { Box, Stack, useMediaQuery } from '@mui/material';

import Header from 'src/components/old/layout/Header';
import Footer from 'src/components/old/layout/Footer';
import { createUseStyles } from 'react-jss';
import FooterBar from '../components/FooterBar';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../hooks/store';
import OldHeader from '../components/old/layout/OldHeader';

const useStyles = createUseStyles({
  container: {
    margin: '0 auto',
    marginTop: 25,
    marginBottom: 0,
    width: 867,
    '@media screen and (max-width: 1700px)': {
      margin: '25px 324px 0px 324px',
    },
    '@media screen and (max-width: 1500px)': {
      margin: '0 auto',
      marginTop: '25px !important',
      width: 867,
    },
    '@media screen and (max-width: 1000px)': {
      margin: '0 auto',
      marginTop: '25px !important',
      width: 867,
    },
    '@media screen and (max-width: 900px)': {
      margin: '0 20px 0 0',
      marginTop: '0px !important',
      width: '100%',
    },
  },
  fixedFooterBar: {
    position: 'fixed',
  },
});

interface Props {
  children: React.ReactNode;
}

export const Layout: React.VFC<Props> = ({ children }) => {
  const classes = useStyles();
  const { user } = useAppSelector(({ authentication: { user } }) => ({
    user,
  }));
  const isDesktop = useMediaQuery('(min-width: 900px)', { noSsr: true });
  const location = useLocation();

  const haveFooter =
    !(user?.type == 'eventPlanner') &&
    ['/', '/tac-app'].some((word) => location.pathname === word) &&
    !['/', '/profile'].some((word) => location.pathname === word);

  const haveHeader = !['/events/'].some((word) =>
    location.pathname.includes(word),
  );

  const isCentered = ['/sign-in', '/eventplanner', '/request', '/thank'].some(
    (word) => location.pathname.includes(word),
  );

  const isRequest = ['/request'].some((word) =>
    location.pathname.includes(word),
  );
  const isSignUp = ['/signUp'].some((word) => location.pathname.includes(word));
  const isThank = ['/thank'].some((word) => location.pathname.includes(word));

  const haveFixedHeight = ['/sign', '/eventplanner', 'request', '/thank'].some(
    (word) => location.pathname.includes(word),
  );
  const fixingMobile =
    [
      '/profile',
      '/thank',
      '/events/',
      'signUp',
      'eventPlannerSignUp',
      '/request/',
    ].some((word) => location.pathname.includes(word)) && !isDesktop;
  const fixingPlannerRequests =
    ['/event/'].some((word) => location.pathname.includes(word)) &&
    user?.type == 'eventPlanner';
  return (
    <Box>
      {user?.type == 'eventPlanner' ? (
        <OldHeader />
      ) : (
        (haveHeader || isDesktop) && (
          <Box className={classes.container}>
            <Header />
          </Box>
        )
      )}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          minHeight: '100%',
          paddingTop:
            !haveHeader || location.pathname === '/events' || isDesktop
              ? '0'
              : '50px',
        }}
      >
        <Box sx={{ minHeight: isRequest && !isDesktop ? '30vh' : '100vh' }}>
          {isCentered && isDesktop ? (
            <Stack
              id="here"
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: '85vh' }}
              height="100%"
            >
              {children}
            </Stack>
          ) : (
            children
          )}
        </Box>
        {haveFooter && <Footer />}
      </Box>
      <Box>
        {!fixingMobile && !fixingPlannerRequests && (
          <FooterBar
            className={
              haveFixedHeight && !isSignUp ? classes.fixedFooterBar : undefined
            }
            {...(isRequest && { display: 'block', bottomPadding: '0' })}
            {...(isThank && !isDesktop && { addedHeight: '80px' })}
          />
        )}
      </Box>
    </Box>
  );
};
