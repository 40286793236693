import React from 'react';

import range from '../helpers/range';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  '@keyframes regular': {
    '25%': { transform: 'scaleY(1)' },
    '50%': { transform: 'scaleY(.4)' },
    '75%': { transform: 'scaleY(.6)' },
  },
  '@keyframes loud': {
    '25%': { transform: 'scaleY(1)' },
    '50%': { transform: 'scaleY(.4)' },
    '75%': { transform: 'scaleY(1.2)' },
  },
  '@keyframes veryLoud': {
    '25%': { transform: 'scaleY(0.8)' },
    '50%': { transform: 'scaleY(.3)' },
    '75%': { transform: 'scaleY(1.4)' },
  },
  '@keyframes quiet': {
    '25%': { transform: 'scaleY(.6)' },
    '50%': { transform: 'scaleY(.4)' },
    '75%': { transform: 'scaleY(.8)' },
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  bar: {
    height: '100%',
    display: 'block',
    borderRadius: '10px',
    transform: 'scaleY(.4)',
  },
  quiet: {
    animation: '$quiet 1200ms 200ms ease-in-out infinite',
    backgroundColor: '#E3E3E3',
  },
  regular: {
    animation: '$regular 1200ms 300ms ease-in-out infinite',
    backgroundColor: '#959595',
  },
  loud: {
    animation: '$loud 1200ms 400ms ease-in-out infinite',
    backgroundColor: '#6b6b6b',
  },
  veryLoud: {
    animation: '$veryLoud 1200ms 500ms ease-in-out infinite',
    backgroundColor: '#2b2a2a',
  },
});

interface Props {
  width: number;
  gutter?: number;
  height?: number;
  barWidth?: number;
  className?: string;
}

export const SoundWave: React.FC<Props> = ({
  width,
  gutter = 5,
  barWidth = 10,
  height = 124,
  className,
}) => {
  const classes = useStyles();
  const barCount = width / (barWidth + gutter);

  const getAnimationName = (id: number) => {
    const x = Math.round(id / 0.3) % 4;

    switch (x) {
      case 0:
        return classes.quiet;
      case 1:
        return classes.regular;
      case 2:
        return classes.loud;
      case 3:
        return classes.veryLoud;
    }
  };
  return (
    <div
      style={{ width, height }}
      className={`${classes.container} ${className}`}
    >
      {range(barCount).map((id) => (
        <span
          key={id}
          style={{ width: barWidth }}
          className={`${classes.bar} ${getAnimationName(id)}`}
        />
      ))}
    </div>
  );
};

export default SoundWave;
