import React from 'react';
// material
import { CssBaseline } from '@mui/material';
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
//
import shape from './shape';
import palette, { ExtendedPalette } from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import breakpoints from './breakpoints';

declare module '@mui/material/styles' {
  interface Theme {
    paletteExtended: ExtendedPalette;
    shape: {
      borderRadius: number;
      borderRadiusSm: number;
      borderRadiusMd: number;
    };
    customShadows: {
      z1: string;
      z8: string;
      z12: string;
      z16: string;
      z20: string;
      z24: string;
      primary: string;
      secondary: string;
      info: string;
      success: string;
      warning: string;
      error: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    paletteExtended: ExtendedPalette;
    customShadows?: {
      z1: string;
      z8: string;
      z12: string;
      z16: string;
      z20: string;
      z24: string;
      primary: string;
      secondary: string;
      info: string;
      success: string;
      warning: string;
      error: string;
    };
    shape?: {
      borderRadius: number;
      borderRadiusSm: number;
      borderRadiusMd: number;
    };
  }
  interface TypographyVariants {
    size1: React.CSSProperties;
    size2: React.CSSProperties;
    size3: React.CSSProperties;
    size4: React.CSSProperties;
    size5: React.CSSProperties;
    size6: React.CSSProperties;
  }
  interface TypographyVariantsOptions {
    size1?: React.CSSProperties;
    size2?: React.CSSProperties;
    size3?: React.CSSProperties;
    size4?: React.CSSProperties;
    size5?: React.CSSProperties;
    size6?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    size1?: true;
    size2?: true;
    size3?: true;
    size4?: true;
    size5?: true;
    size6?: true;
  }
}

const customTheme = () => {
  const themeOptions = {
    palette: palette,
    paletteExtended: palette,
    shape,
    typography,
    shadows,
    customShadows,
    breakpoints,
  };

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return theme;
};

export const theme = customTheme();

const ThemeConfig = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
