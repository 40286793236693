import React from 'react';
import { Button, Container, TextField, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { useState } from 'react';
import { useAppDispatch } from 'src/hooks/store';
import { forgotPassword } from 'src/slices/authentication';
import { useHistory } from 'react-router-dom';

const useStyles = createUseStyles({
  buttonForgotpsswd: {
    color: 'gray',
    fontSize: '15px',
    textTransform: 'none',
    opacity: '.5',
  },
  buttonSignup: {
    color: 'black',
    borderRadius: '300px',
    border: '3px solid black',
    borderColor: 'black',
    width: '300px',
    textTransform: 'none',
    '&:hover': {
      border: '3px solid white',
      color: 'black',
    },
  },
  buttoncontinue: {
    color: 'white',
    borderRadius: '300px',
    border: '3px solid black',
    width: '300px',
    backgroundColor: 'black',
    textTransform: 'none',
    '&:hover': {
      border: '3px solid white',
      color: 'black',
    },
    marginTop: '10px',
  },

  textfield1: {
    color: 'black',
    [`& fieldset`]: {
      border: 'none',
    },
  },
  textfield2: {
    color: 'black',
    [`& fieldset`]: {
      border: 'none',
      color: 'black',
    },
  },

  menubutton: {
    color: 'black',
    height: '10%',
    width: '10%',
  },

  bigimage: {
    width: '40%',
    padding: '10px',
    margin: '10px',
  },
  typographyposition: {
    position: 'absolute',
    left: '300px',
  },
  root: {
    width: '100%',
    height: '100%',
  },
  forgotPasswordHeader: {
    marginTop: '1em',
    fontWeight: 900,
    color: 'black',
  },
  inputColor: {
    border: 'solid 2px #F0F0F0',
    borderRadius: 30,
    backgroundColor: '#F0F0F0',
  },
  enterEmailAddress: {
    marginTop: '1em',
    marginLeft: '.3em',
    width: 300,
    color: 'black',
  },
  forgotPasswordContainer: {
    paddingTop: 10,
  },
});

const ForgotPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isSent, setIsSent] = useState(false);
  const [email, setEmail] = useState<string | undefined>('');
  const dispatch = useAppDispatch();
  const onClickForgotPassword = () => {
    if (email) {
      dispatch(forgotPassword(email));
      setIsSent(true);
    }
  };

  const onClickBackToLogin = () => {
    history.push('/sign-in');
  };

  return !isSent ? (
    //if isSent is true, then display the message

    <Container maxWidth="xs" className={classes.forgotPasswordContainer}>
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Stack direction="column" spacing={2} justifyContent="center">
          <Typography variant="h3" className={classes.forgotPasswordHeader}>
            Reset Password
          </Typography>
          <Typography variant="h4" className={classes.enterEmailAddress}>
            We will send you an email with a link to reset your password.
          </Typography>
          <TextField
            className={classes.textfield1}
            value={email}
            onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
            placeholder="Email"
            size="small"
            InputProps={{ className: classes.inputColor }}
          />
          <Stack>
            <Button
              variant="outlined"
              onClick={onClickForgotPassword}
              className={classes.buttoncontinue}
              size="medium"
              disabled={!email}
            >
              Reset
            </Button>
          </Stack>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5" sx={{ fontWeight: 900, color: 'black' }}>
            Or
          </Typography>
          <Button
            onClick={onClickBackToLogin}
            variant="outlined"
            className={classes.buttonSignup}
            size="medium"
          >
            Back to sign In
          </Button>
        </Stack>
      </Stack>
    </Container>
  ) : (
    <Container maxWidth="xs" className={classes.forgotPasswordContainer}>
      <Stack
        direction="column"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Stack direction="column" spacing={2} justifyContent="center">
          <Typography variant="h3" className={classes.forgotPasswordHeader}>
            Reset Password
          </Typography>
          <Typography variant="h4" className={classes.enterEmailAddress}>
            We have sent you an email with a link to reset your password.
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

export default ForgotPassword;
