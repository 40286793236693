import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Parse from 'parse/node';
import { serializeUser } from 'src/helpers/serializeModels';
import { User, UserGender } from 'src/models/user';
import moment from 'moment';

/* Thunks */

export interface UpdateUserDto {
  name?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  currentPassword?: string;
  newPassword?: string;
  dateOfBirth?: Date;
}

export interface SignUpDto {
  username: string;
  password: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  phoneNumber?: string;
  dateOfBirth?: Date;
  gender?: UserGender;
  isPlanner: boolean;
}

interface AuthenticationState {
  user: User | null;
  loading: boolean;
  error: any;
  signError: any;
  userCountry: string | null;
}

const initialState: AuthenticationState = {
  user: null,
  loading: false,
  error: null,
  signError: null,
  userCountry: null,
};

export const logIn = createAsyncThunk<
  User | null,
  { username: string; password: string }
>(
  'authentication/logIn',
  async ({ username, password }: { username: string; password: string }) => {
    return Parse.User.logIn(username, password).then(serializeUser) ?? null;
  },
);

export const signUp = createAsyncThunk<User | null, SignUpDto>(
  'authentication/signUp',
  async ({
    username,
    email,
    password,
    name,
    phoneNumber,
    dateOfBirth,
    gender,
    isPlanner,
    firstName,
    lastName,
  }: SignUpDto) => {
    const user = await Parse.User.signUp(username, password, {
      name: name,
      email: email.toLowerCase(),
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      dateOfBirth: dateOfBirth
        ? moment(dateOfBirth.toISOString()).format('DD-MM-YYYY')
        : null,
      gender: gender,
      type: isPlanner ? 'eventPlanner' : 'customer',
    });
    let planner;
    if (isPlanner) {
      const Planner = Parse.Object.extend('Planner');
      const newPlanner = new Planner();
      newPlanner.set('email', email);
      newPlanner.set('name', name ?? `${firstName} ${lastName}`);

      planner = (await newPlanner.save()) as Parse.Object<Parse.Attributes>;
      user.set('planner', planner.toPointer());
      return user.save().then(serializeUser);
    } else {
      return serializeUser(user);
    }
  },
);

export const updateUser = createAsyncThunk<User | null, UpdateUserDto>(
  'authentication/updateUser',
  async ({
    email,
    currentPassword,
    newPassword,
    name,
    phoneNumber,
    firstName,
    lastName,
    dateOfBirth,
  }: UpdateUserDto) => {
    const currentUser = Parse.User.current();
    if (email) {
      currentUser?.setEmail(email);
    }
    if (newPassword) {
      currentUser?.setPassword(newPassword);
    }
    if (phoneNumber) {
      currentUser?.set('phoneNumber', phoneNumber);
    }
    if (name) {
      currentUser?.set('name', name);
    }
    if (firstName) {
      currentUser?.set('firstName', firstName);
    }
    if (lastName) {
      currentUser?.set('lastName', lastName);
    }
    if (dateOfBirth) {
      const dateOfBirthString = moment(dateOfBirth.toISOString()).format(
        'DD-MM-YYYY',
      );

      currentUser?.set('dateOfBirth', dateOfBirthString);
    }
    if (currentPassword && newPassword) {
      await Parse.Cloud.run('updatePassword', {
        username: currentUser?.getUsername(),
        currentPassword: currentPassword,
        newPassword: newPassword,
      });
    }
    await currentUser?.save();
    return currentUser?.fetch().then(serializeUser) ?? null;
  },
);

export const logOut = createAsyncThunk('authentication/logOut', async () => {
  return Parse.User.logOut();
});

export const getUserLocationCountry = createAsyncThunk(
  'authentication/getUserLocationCountry',
  async () => {
    const res = await fetch('https://ipapi.co/json/');
    const country = await res.json();
    return country.country_code.toString();
  },
);

export const initializeUserWithToken = createAsyncThunk(
  'authentication/initializeUserWithToken',
  async () => {
    const sessionToken = localStorage.getItem('sessionToken');
    if (sessionToken) {
      return Parse.User.become(sessionToken).then(serializeUser);
    }
    throw Error();
  },
);

export const getUser = createAsyncThunk<User | null>(
  'authentication/getUser',
  async () => {
    return Parse.User.current()?.fetch().then(serializeUser) ?? null;
  },
);

export const forgotPassword = createAsyncThunk(
  'authentication/forgotPassword',
  async (email: string) => {
    return Parse.User.requestPasswordReset(email);
  },
);

const sharedReducers = {
  pending: (state: AuthenticationState) => {
    state.loading = true;
    state.error = null;
  },
  rejected: (state: AuthenticationState, { error }: { error: any }) => {
    state.loading = false;
    state.error = error || 'error';
  },
  signRejected: (state: AuthenticationState, { error }: { error: any }) => {
    state.loading = false;
    state.signError = error || 'error';
  },
};

const authenticationSlice = createSlice({
  name: 'authentication',
  reducers: {
    resetError: (state) => {
      state.signError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logIn.fulfilled, (state: AuthenticationState, { payload }) => {
        state.loading = false;
        if (payload) {
          state.user = payload;
          const sessionToken = Parse.User.current()?.getSessionToken();
          if (sessionToken) {
            localStorage.setItem('sessionToken', sessionToken);
          }
        }
      })
      .addCase(logIn.pending, sharedReducers.pending)
      .addCase(logIn.rejected, sharedReducers.signRejected)
      .addCase(getUserLocationCountry.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userCountry = payload;
      })

      .addCase(signUp.fulfilled, (state: AuthenticationState, { payload }) => {
        state.loading = false;
        if (payload) {
          state.user = payload;
          const sessionToken = Parse.User.current()?.getSessionToken();
          if (sessionToken) {
            localStorage.setItem('sessionToken', sessionToken);
          }
        }
      })
      .addCase(signUp.pending, sharedReducers.pending)
      .addCase(signUp.rejected, sharedReducers.signRejected)
      .addCase(
        updateUser.fulfilled,
        (state: AuthenticationState, { payload }) => {
          state.loading = false;

          if (payload) {
            state.user = payload;
          }
        },
      )
      .addCase(updateUser.pending, sharedReducers.pending)
      .addCase(updateUser.rejected, sharedReducers.signRejected)
      .addCase(logOut.fulfilled, (state: AuthenticationState) => {
        state.loading = false;
        state.user = null;
        localStorage.removeItem('sessionToken');
      })
      .addCase(logOut.pending, sharedReducers.pending)
      .addCase(logOut.rejected, sharedReducers.rejected)
      .addCase(getUser.fulfilled, (state: AuthenticationState, { payload }) => {
        state.loading = false;
        if (payload) {
          state.user = payload;
          const sessionToken = Parse.User.current()?.getSessionToken();
          if (sessionToken) {
            localStorage.setItem('sessionToken', sessionToken);
          }
        }
      })
      .addCase(getUser.pending, sharedReducers.pending)
      .addCase(getUser.rejected, sharedReducers.rejected)
      .addCase(initializeUserWithToken.pending, sharedReducers.pending)
      .addCase(initializeUserWithToken.rejected, (state) => {
        state.loading = false;
        state.error = 'error';
        state.user = null;
        localStorage.removeItem('sessionToken');
      })
      .addCase(initializeUserWithToken.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;
        const sessionToken = Parse.User.current()?.getSessionToken();
        if (sessionToken) {
          localStorage.setItem('sessionToken', sessionToken);
        }
      });
  },
  initialState: initialState,
});
export const { resetError } = authenticationSlice.actions;

export default authenticationSlice.reducer;
