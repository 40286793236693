import {
  Box,
  Button,
  Divider,
  Typography,
  Grid,
  Stack,
  Card,
  IconButton,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Menu,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '../../assets/icons/facebook2.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import LaunchIcon from '@mui/icons-material/Launch';
import { Request } from 'src/models/request';
import { createUseStyles } from 'react-jss';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useIsBigScreen } from 'src/hooks/useBigScreen';
import { TicketInvitesForm } from './TicketInvitesForm';
import { Event } from 'src/models/event';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { deleteRequest } from '../../slices/request';
import { useTheme } from '@mui/material/styles';
import { request } from 'http';
import { useParams } from 'react-router';
import RequestsPaidPackages from './RequestsPaidPackages';
import ReactCountryFlag from 'react-country-flag';
import { TailSpin } from 'react-loading-icons';

const useStyles = createUseStyles({
  boldHeader: {
    fontWeight: 900,
  },
  pendingButton: {
    textTransform: 'none',
    width: '80%',
    minWidth: '60px',
    height: '40px',
    border: '1px solid #000000',

    borderRadius: '30px',
    '&:hover': {
      opacity: 0.8,
    },

    '@media (min-width: 1500px)': {
      fontSize: '15px',
    },

    fontSize: '12px',
  },
  cancelButton: {
    '&:hover': {
      color: 'white',
      backgroundColor: '#989898',
    },
    backgroundColor: 'white',
    border: '1px solid #989898',
    color: '#989898',
    padding: '16px 30px',
    '@media (max-width: 1500px)': {
      padding: '12.2px 24px',
    },
  },
  approveButton: {
    '&:hover': {
      color: 'white',
      backgroundColor: '#989898',
    },
    backgroundColor: 'white',
    border: '1px solid #989898',
    color: '#989898',
    padding: '16px 30px',
    '@media (max-width: 1500px)': {
      padding: '12.2px 24px',
    },
  },
  boldIcon: {
    stroke: '#989898',
    strokeWidth: 2,
  },
  header: {
    color: 'black',
    fontSize: '25px',
    textTransform: 'none',
  },
  parent: {
    textAlign: 'start',
  },
  child: {
    display: 'inline-block',
    marginLeft: '0.5rem',
  },
  textChild: {
    display: 'inline-block',
    marginBottom: '1rem',
  },
  launchIcon: {
    color: 'black',
  },
  button: {
    height: '45px',
    borderRadius: '22.5px',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '19px',
  },
  filterButton: {
    '&:hover': { backgroundColor: 'lightgrey' },
    borderRadius: '25px',
    textTransform: 'none',
    maxWidth: '3px',
    width: '3px',
    minWidth: '40px',
    height: '30px',
    display: 'flex',
    whiteSpace: 'nowrap',
  },
  socialLinksGrid: {
    //make grid rows alternate colors

    '&:nth-child(2n-1):not(:nth-child(1))': {
      backgroundColor: 'lightgrey',
    },
  },
  rejectedMenu: {
    width: '100%',
    height: '41px',

    backgroundColor: '#FF2F2F',
    borderRadius: '21px',
    color: 'white',

    textAlign: 'center',
  },
  acceptedMenu: {
    width: '100%',

    height: '41px',

    backgroundColor: '#28AF86',
    borderRadius: '21px',
    color: 'white',

    textAlign: 'center',
  },
  icon: {
    color: 'white',
    fill: 'white',
  },
  paidMenu: {
    width: '100%',
    height: '41px',

    backgroundColor: 'black',
    borderRadius: '21px',
    color: 'white',

    textAlign: 'center',
  },
  inviteMenu: {
    width: '100%',
    height: '41px',

    backgroundColor: '#F4B708',
    borderRadius: '21px',
    color: 'white',

    textAlign: 'center',
  },
  socialLinksGrids: {},
});

interface Props {
  requests: Request[] | null;
  event: Event;
  onClickApprove: (requestId: string) => void;
  onClickDecline: (requestId: string) => void;
  onClickRefund: (requestId: string) => void;
  onClickResendRequestEmail: (requestId: string) => void;
  searchText: string | undefined;
}
const RequestsdCard: React.VFC<Props> = ({
  requests,
  event,
  onClickApprove,
  onClickDecline,
  onClickRefund,
  onClickResendRequestEmail,

  searchText,
}) => {
  const classes = useStyles();
  const customStyles = {
    dropdownIndicator: (base) => ({
      ...base,
      color: 'red', // Custom colour
    }),
  };
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [numberFilter, setNumberFilter] = useState(200);
  const [filter, setFilter] = useState({
    paid: false,
    approved: false,
    pending: false,
    declined: false,
    invitations: false,
    packages: false,
    all: true,
  });

  const { requestLoadingId, approvedOrRejected } = useAppSelector(
    ({ request: { requestLoadingId, approvedOrRejected } }) => ({
      requestLoadingId,
      approvedOrRejected,
    }),
  );
  const [expandedRequestId, setExpandedRequestId] = useState<
    string | undefined
  >();

  const isBigScreen = useIsBigScreen();
  const handleClickCard = (requestId: string) => {
    if (expandedRequestId === requestId) {
      setExpandedRequestId(undefined);
    } else {
      setExpandedRequestId(requestId);
    }
  };

  const getTicketNameForSearch = (request: Request) => {
    const tickets = request.tickets.filter(
      (ticket) =>
        ticket.id &&
        request.ticketsNumberById[ticket.id] &&
        request.ticketsNumberById[ticket.id] > 0,
    );
    return tickets[0]?.name;
  };
  const [popUp, setPopUp] = useState(false);
  const [popupRequestId, setPopupRequestId] = useState('');
  const [refundPopup, setRefundPopup] = useState(false);
  const [refundPopupRequestId, setRefundPopupRequestId] = useState('');

  const buildNameSection = (request: Request) => {
    const tickets = request.tickets.filter(
      (ticket) =>
        ticket.id &&
        request.ticketsNumberById[ticket.id] &&
        request.ticketsNumberById[ticket.id] > 0,
    );

    return (
      <Grid
        item
        xs={12}
        sm={3}
        md={3}
        lg={3}
        xl={3}
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Typography
            textOverflow="ellipsis"
            fontSize={{ xs: '16.5px', xl: '20.5px' }}
            fontWeight={800}
            display="inline-block"
          >
            {request.user?.firstName} {request.user?.lastName}
            {request.recipients.length > 1 ? ' +' : ''}
            {request.recipients.length > 1 ? request.recipients.length - 1 : ''}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="size4" fontWeight={500} textOverflow="ellipsis">
            {tickets[0]?.name || ''}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={expandedRequestId === request.id}>
            <Stack
              direction="column"
              alignItems="left"
              justifyContent="start"
              paddingTop={1}
            >
              {tickets.slice(1).map((ticket, index) => (
                <Typography
                  variant="size4"
                  fontWeight={500}
                  key={index}
                  textOverflow="ellipsis"
                >
                  {ticket.name}
                </Typography>
              ))}
              {request.user?.phoneNumber && (
                <Typography
                  variant="size5"
                  fontWeight={500}
                  textOverflow="ellipsis"
                  fontSize="14px !important"
                >
                  {request.user?.phoneNumber}
                </Typography>
              )}
              <Typography
                variant="size5"
                fontWeight={500}
                textOverflow="ellipsis"
                fontSize="14px !important"
              >
                {request.user?.email}
              </Typography>
              <Typography
                variant="size5"
                fontWeight={500}
                textOverflow="ellipsis"
              >
                {request.coupon ? request.coupon : 'N/A'}
              </Typography>

              <Typography
                variant="size6"
                fontWeight={10}
                textOverflow="ellipsis"
                paddingTop={1}
              >
                ID: {request.id ? request.id : 'N/A'}
              </Typography>
            </Stack>
          </Collapse>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    );
  };

  const buildSocialLinksSection = (request: Request) => (
    <Grid
      item
      xs={5}
      sm={2}
      md={5}
      lg={5}
      xl={5}
      container
      direction="row"
      justifyContent="space-equally"
      justifyItems={'space-evenly'}
      paddingTop={1}
      spacing={1}
    >
      <Grid item xs={8} container direction="row" justifyContent="flex-start">
        {' '}
        <Grid
          item
          style={{
            alignSelf: 'top',

            justifySelf: 'center',
            marginTop: 4,
          }}
          xs={2}
          md={1}
          className={classes.child}
        >
          <img src={FacebookIcon} alt="facebook" />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className={classes.child}
          sx={{ marginTop: 0.2, cursor: 'pointer' }}
          onClick={() => handleClickCard(request.id)}
        >
          <Typography variant="size4">Social links</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography
          fontSize={{ xs: 19.2, xl: 24 }}
          fontWeight={800}
          display="inline"
        >
          M/F{' '}
        </Typography>
        <Typography
          variant="size4"
          fontWeight={500}
          style={{
            color:
              (request.recipients.filter(
                (recipient) =>
                  recipient.gender == 'male' || recipient.gender == 'other',
              ).length ?? 0) >
              (request.recipients.filter(
                (recipient) => recipient.gender == 'female',
              ).length ?? 0)
                ? 'red'
                : undefined,
          }}
          display="inline"
        >
          {
            request.recipients.filter(
              (recipient) =>
                recipient.gender == 'male' || recipient.gender == 'other',
            ).length
          }
        </Typography>
        <Typography variant="size4" fontWeight={500} display="inline">
          /
          {
            request.recipients.filter(
              (recipient) => recipient.gender == 'female',
            ).length
          }
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent="start"
        alignItems="start"
        minHeight={'100%'}
      >
        <Collapse in={expandedRequestId === request.id}>
          <Grid container justifyContent="flex-start" xs={12}>
            {request.recipients.map((recipient, index) => (
              <Grid item xs={6} key={index} paddingRight={1}>
                <Stack
                  direction="row"
                  alignItems="left"
                  justifyContent={'space-between'}
                >
                  <div>
                    <Typography
                      variant="size4"
                      fontWeight={500}
                      fontSize="14px !important"
                    >
                      {index + 1} . {recipient.name}
                    </Typography>
                    {recipient.nationality && event.isPackages && (
                      <ReactCountryFlag
                        countryCode={recipient.nationality}
                        svg
                        style={{ paddingLeft: 2 }}
                      />
                    )}
                  </div>
                  <a href={recipient.link} target="_blank" rel="noreferrer">
                    <LaunchIcon
                      className={classes.launchIcon}
                      fontSize={isBigScreen ? 'medium' : 'small'}
                    />
                  </a>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );

  const buildApproveSection = (request: Request) => (
    <Grid
      item
      xs={2.5}
      sm={2.5}
      md={2.5}
      lg={2.5}
      xl={2.5}
      container
      sx={{ justifyContent: 'space-between', justifyItems: 'center' }}
      direction="row"
      justifyContent="space-between"
      justifyItems={'space-between'}
      alignItems="center"
    >
      {requestLoadingId[request.id] == 1 && (
        <Grid
          item
          xs={12}
          md={12}
          display="flex"
          justifySelf={'center'}
          justifyContent={'center'}
        >
          <TailSpin
            style={{ marginBottom: '10px' }}
            stroke="#000000"
            speed={0.75}
          />
        </Grid>
      )}

      {request.isPending == true &&
        requestLoadingId[request.id] == undefined &&
        (requestLoadingId[request.id] == 0 ||
          requestLoadingId[request.id] == undefined) && (
          <>
            <Grid item xs={6} md={6} display="flex">
              <Button
                onClick={() => {
                  onClickApprove(request.id);
                }}
                variant="contained"
                className={classes.pendingButton}
                style={{
                  backgroundColor:
                    (request.recipients.filter(
                      (recipient) =>
                        recipient.gender == 'male' ||
                        recipient.gender == 'other',
                    ).length ?? 0) >
                    (request.recipients.filter(
                      (recipient) => recipient.gender == 'female',
                    ).length ?? 0)
                      ? '#FFFFFF'
                      : '#28AF86',
                  color:
                    (request.recipients.filter(
                      (recipient) =>
                        recipient.gender == 'male' ||
                        recipient.gender == 'other',
                    ).length ?? 0) >
                    (request.recipients.filter(
                      (recipient) => recipient.gender == 'female',
                    ).length ?? 0)
                      ? '#000000'
                      : '#FFFFFF',
                  border:
                    (request.recipients.filter(
                      (recipient) =>
                        recipient.gender == 'male' ||
                        recipient.gender == 'other',
                    ).length ?? 0) >
                    (request.recipients.filter(
                      (recipient) => recipient.gender == 'female',
                    ).length ?? 0)
                      ? '1px solid #000000'
                      : '1px solid #28AF86',
                }}
              >
                Approve
              </Button>
            </Grid>
            <Grid item xs={6} md={6} justifyContent="right" display="flex">
              <Button
                onClick={() => {
                  onClickDecline(request.id);
                }}
                variant="contained"
                className={classes.pendingButton}
                style={{
                  backgroundColor:
                    (request.recipients.filter(
                      (recipient) =>
                        recipient.gender == 'male' ||
                        recipient.gender == 'other',
                    ).length ?? 0) >
                    (request.recipients.filter(
                      (recipient) => recipient.gender == 'female',
                    ).length ?? 0)
                      ? '#FF2F2F'
                      : '#FFFFFF',
                  color:
                    (request.recipients.filter(
                      (recipient) =>
                        recipient.gender == 'male' ||
                        recipient.gender == 'other',
                    ).length ?? 0) >
                    (request.recipients.filter(
                      (recipient) => recipient.gender == 'female',
                    ).length ?? 0)
                      ? '#FFFFFF'
                      : '#000000',
                  border:
                    (request.recipients.filter(
                      (recipient) =>
                        recipient.gender == 'male' ||
                        recipient.gender == 'other',
                    ).length ?? 0) >
                    (request.recipients.filter(
                      (recipient) => recipient.gender == 'female',
                    ).length ?? 0)
                      ? '1px solid #FF2F2F'
                      : '1px solid #000000',
                }}
              >
                Reject
              </Button>
            </Grid>
          </>
        )}
      <Grid item xs={12}>
        {((request.isDeclined == true &&
          approvedOrRejected[request.id] == undefined &&
          requestLoadingId[request.id] != 1) ||
          (approvedOrRejected[request.id] == 0 &&
            requestLoadingId[request.id] != 1)) && (
          <Select
            key={request.id}
            value={1}
            className={classes.rejectedMenu}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem style={{ display: 'none' }} value={1}>
              Rejected
            </MenuItem>

            <MenuItem
              onClick={() => {
                onClickApprove(request.id);
              }}
            >
              Approve
            </MenuItem>
          </Select>
        )}
        {((request.isApproved == true &&
          approvedOrRejected[request.id] == undefined &&
          requestLoadingId[request.id] != 1) ||
          (approvedOrRejected[request.id] == 1 &&
            requestLoadingId[request.id] != 1)) && (
          <>
            {request.paid ? (
              <Select
                key={request.id}
                value={1}
                className={classes.paidMenu}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem style={{ display: 'none' }} value={1}>
                  {request.isRefunded ? 'Refunded' : 'Paid'}
                </MenuItem>

                {!request.isRefunded && (
                  <MenuItem
                    onClick={() => {
                      handleRefundOpen(request.id);
                    }}
                  >
                    Refund
                  </MenuItem>
                )}
              </Select>
            ) : (
              <Select
                key={request.id}
                value={1}
                className={classes.acceptedMenu}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem style={{ display: 'none' }} value={1}>
                  Approved
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    onClickDecline(request.id);
                  }}
                >
                  Decline
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onClickResendRequestEmail(request.id);
                  }}
                >
                  Send Reminder
                </MenuItem>
              </Select>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );

  const handleClickOpen = (requestId: string) => {
    setPopUp(true);
    setPopupRequestId(requestId);
  };
  const handleRefundOpen = (requestId: string) => {
    setRefundPopupRequestId(requestId);
    setRefundPopup(true);
  };
  const handleRefundClose = () => {
    setRefundPopup(false);
  };

  const handleRefundApprove = () => {
    onClickRefund(refundPopupRequestId);
    handleRefundClose();
  };

  const handleInviteCancel = () => {
    dispatch(deleteRequest({ eventId: event.id, id: popupRequestId }));
    handleClose();
  };

  const handleClose = () => {
    setPopUp(false);
  };

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const buildInviteSection = (request: Request) => (
    <div key={request.id}>
      <Box sx={{ m: 3 }} />

      <Card variant="outlined" style={{ boxShadow: ' 0px 2px 4px #979797' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingX: 3,
            paddingY: 2,
          }}
        >
          <Grid item xs={2}>
            <Stack direction="column" alignItems="center">
              <Typography variant="size4" fontWeight={'800'}>
                {request?.recipients?.length > 0
                  ? request.recipients[0].name
                  : ''}
              </Typography>
              <Typography variant="size5" fontWeight={'400'}>
                {request?.recipients?.length > 0
                  ? request.recipients[0].email
                  : ''}
              </Typography>
            </Stack>
          </Grid>
          <Divider
            style={{ marginRight: '-1px' }}
            orientation="vertical"
            flexItem
          />
          <Grid item xs={2}>
            <Typography variant="size4" textAlign={'center'} fontWeight={'800'}>
              {getTicketNameForSearch(request)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="size4" fontWeight={'800'}>
                Added By:
              </Typography>
              <Typography variant="size4" fontWeight={'400'}>
                {request?.promoter}
              </Typography>
            </Stack>
          </Grid>
          <Divider
            style={{ marginRight: '-1px' }}
            orientation="vertical"
            flexItem
          />
          <Grid item xs={2}>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Select
                key={request.id}
                value={1}
                className={classes.inviteMenu}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem style={{ display: 'none' }} value={1}>
                  Invitation
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClickOpen(request.id);
                  }}
                >
                  Cancel
                </MenuItem>
              </Select>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </div>
  );

  const buildRequestSection = (request: Request) =>
    request.isInvitation
      ? buildInviteSection(request)
      : !request.isOndoor && (
          <div key={request.id}>
            <Box sx={{ m: 3 }} />
            <Card
              variant="outlined"
              style={{ boxShadow: ' 0px 2px 4px #979797' }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="top-center "
                sx={{
                  paddingX: 3,
                  paddingY: 2,
                }}
              >
                {buildNameSection(request)}
                <Divider
                  style={{ marginRight: '-1px' }}
                  orientation="vertical"
                  flexItem
                />
                {buildSocialLinksSection(request)}
                <Divider
                  style={{ marginRight: '-1px' }}
                  orientation="vertical"
                  flexItem
                />

                {buildApproveSection(request)}
              </Grid>
            </Card>
          </div>
        );

  const getSearchTextRegexCondition = (request: Request) => {
    if (!searchText) {
      return true;
    } else {
      const lowerCaseSearchText = searchText.toLowerCase();
      const fullname =
        request?.user?.firstName?.toLowerCase() +
        ' ' +
        request?.user?.lastName?.toLowerCase();
      const ticketname = getTicketNameForSearch(request).toLowerCase();

      return (
        (fullname && new RegExp(`${lowerCaseSearchText}`).test(fullname)) ||
        (ticketname && new RegExp(`${lowerCaseSearchText}`).test(ticketname))
      );
    }
  };
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={refundPopup}
        onClose={handleRefundClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Refund Ticket?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to refund this ticket? This action cannot be
            undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleRefundClose}>
            Cancel
          </Button>
          <Button onClick={() => handleRefundApprove()} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen={fullScreen}
        open={popUp}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Cancel Invite?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this invite? This action cannot be
            undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={() => handleInviteCancel()} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Stack
        direction="row"
        height={'30px'}
        justifyContent={'space-between'}
        marginTop={'-8px'}
        minWidth={'100%'}
      >
        <Stack direction="row">
          <Button
            onClick={() => {
              {
                setFilter({
                  pending: false,
                  declined: false,
                  approved: false,
                  invitations: false,
                  paid: false,
                  packages: false,
                  all: true,
                });
              }
            }}
            sx={{ textTransform: 'none', height: '40px' }}
          >
            <Typography
              fontSize={{ xs: 20, xl: 25 }}
              fontWeight={filter.all ? 800 : 500}
              color="black"
            >
              All
            </Typography>
          </Button>

          <Button
            onClick={() => {
              {
                setFilter({
                  pending: true,
                  declined: false,
                  approved: false,
                  invitations: false,
                  paid: false,
                  packages: false,
                  all: false,
                });
              }
            }}
            sx={{ textTransform: 'none', height: '40px' }}
          >
            <Typography
              fontSize={{ xs: 20, xl: 25 }}
              fontWeight={filter.pending ? 800 : 500}
              color="black"
            >
              Pending
            </Typography>
          </Button>

          <Button
            onClick={() => {
              {
                setFilter({
                  pending: false,
                  declined: false,
                  approved: false,
                  invitations: false,
                  paid: true,
                  packages: false,
                  all: false,
                });
              }
            }}
            sx={{ textTransform: 'none', height: '40px' }}
          >
            <Typography
              fontSize={{ xs: 20, xl: 25 }}
              fontWeight={filter.paid ? 800 : 500}
              color="black"
            >
              Paid
            </Typography>
          </Button>

          <Button
            onClick={() => {
              {
                setFilter({
                  pending: false,
                  declined: false,
                  approved: true,
                  invitations: false,
                  paid: false,
                  packages: false,
                  all: false,
                });
              }
            }}
            sx={{ textTransform: 'none', height: '40px' }}
          >
            <Typography
              fontSize={{ xs: 20, xl: 25 }}
              fontWeight={filter.approved ? 800 : 500}
              color="black"
            >
              Approved
            </Typography>
          </Button>

          <Button
            onClick={() => {
              {
                setFilter({
                  pending: false,
                  paid: false,
                  declined: true,
                  approved: false,
                  invitations: false,
                  packages: false,
                  all: false,
                });
              }
            }}
            sx={{ textTransform: 'none', height: '40px' }}
          >
            <Typography
              fontSize={{ xs: 20, xl: 25 }}
              fontWeight={filter.declined ? 800 : 500}
              color="black"
            >
              Declined
            </Typography>
          </Button>

          <Button
            onClick={() => {
              {
                setFilter({
                  pending: false,
                  declined: false,
                  approved: false,
                  invitations: true,
                  paid: false,
                  packages: false,
                  all: false,
                });
              }
            }}
            sx={{ textTransform: 'none', height: '40px' }}
          >
            <Typography
              fontSize={{ xs: 20, xl: 25 }}
              fontWeight={filter.invitations ? 800 : 500}
              color="black"
            >
              Invitations
            </Typography>
          </Button>

          {event.isPackages && (
            <Button
              onClick={() => {
                {
                  setFilter({
                    pending: false,
                    declined: false,
                    approved: false,
                    invitations: false,
                    paid: false,
                    packages: true,
                    all: false,
                  });
                }
              }}
              sx={{ textTransform: 'none', height: '40px' }}
            >
              <Typography
                fontSize={{ xs: 20, xl: 25 }}
                fontWeight={filter.packages ? 800 : 500}
                color="black"
              >
                Accommodations
              </Typography>
            </Button>
          )}
        </Stack>
        <Stack direction="row" marginTop={'5px'} spacing={0}>
          <Button
            onClick={() => {
              {
                setNumberFilter(200);
              }
            }}
            className={classes.filterButton}
            sx={{ backgroundColor: numberFilter == 200 ? 'black' : 'white' }}
          >
            <Typography
              fontSize={{ xs: 10, xl: 10 }}
              fontWeight={numberFilter == 200 ? 800 : 500}
              color={numberFilter == 200 ? 'white' : 'black'}
              sx={{ whiteSpace: 'nowrap', display: 'flex' }}
            >
              200
            </Typography>
          </Button>

          <Button
            className={classes.filterButton}
            onClick={() => {
              {
                setNumberFilter(500);
              }
            }}
            sx={{ backgroundColor: numberFilter == 500 ? 'black' : 'white' }}
          >
            <Typography
              fontSize={{ xs: 10, xl: 10 }}
              fontWeight={numberFilter == 500 ? 800 : 500}
              color={numberFilter == 500 ? 'white' : 'black'}
            >
              500
            </Typography>
          </Button>

          <Button
            className={classes.filterButton}
            onClick={() => {
              {
                setNumberFilter(1000);
              }
            }}
            sx={{ backgroundColor: numberFilter == 1000 ? 'black' : 'white' }}
          >
            <Typography
              fontSize={{ xs: 10, xl: 10 }}
              fontWeight={numberFilter == 1000 ? 800 : 500}
              color={numberFilter == 1000 ? 'white' : 'black'}
            >
              1000
            </Typography>
          </Button>
          <Button
            className={classes.filterButton}
            onClick={() => {
              {
                setNumberFilter(9999);
              }
            }}
            sx={{ backgroundColor: numberFilter == 9999 ? 'black' : 'white' }}
          >
            <Typography
              fontSize={{ xs: 10, xl: 10 }}
              fontWeight={numberFilter == 999 ? 800 : 500}
              color={numberFilter == 9999 ? 'white' : 'black'}
            >
              All
            </Typography>
          </Button>
        </Stack>
      </Stack>

      {filter.invitations && <TicketInvitesForm event={event} />}
      {filter.packages && (
        <RequestsPaidPackages event={event} requests={requests} />
      )}

      {filter.paid
        ? requests
            ?.filter(
              (request) => request.paid && getSearchTextRegexCondition(request),
            )
            .slice(0, numberFilter)
            .map(buildRequestSection)
        : filter.invitations
        ? requests
            ?.filter(
              (request) =>
                request.isInvitation && getSearchTextRegexCondition(request),
            )
            .slice(0, numberFilter)
            .map(buildRequestSection)
        : filter.pending
        ? requests
            ?.filter(
              (request) =>
                request.isPending && getSearchTextRegexCondition(request),
            )
            .slice(0, numberFilter)
            .map(buildRequestSection)
        : filter.approved
        ? requests
            ?.filter(
              (request) =>
                request.isApproved && getSearchTextRegexCondition(request),
            )
            .slice(0, numberFilter)
            .map(buildRequestSection)
        : filter.declined
        ? requests
            ?.filter(
              (request) =>
                request.isDeclined && getSearchTextRegexCondition(request),
            )
            .slice(0, numberFilter)
            .map(buildRequestSection)
        : !filter.packages &&
          requests
            ?.slice(0, numberFilter)
            .filter(
              (request) =>
                !request.isInvitation && getSearchTextRegexCondition(request),
            )
            .slice(0, numberFilter)
            .map(buildRequestSection)}
    </>
  );
};

export default RequestsdCard;
