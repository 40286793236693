import React from 'react';
import { createUseStyles } from 'react-jss';
import { TicketsNumberByType } from 'src/components/tickets/ModalTicket';
import { useAppSelector } from 'src/hooks/store';
import { Event } from 'src/models/event';
import { EventSection } from 'src/components/desktopEvent/EventSection';
import { CheckoutForm } from './CheckoutForm';
import { EventDescriptionSection } from 'src/components/desktopEvent/EventDescriptionSection';
import { GuestFormValues } from 'src/components/tickets/MobileTicketGuestSection';
import { Token } from '@stripe/stripe-js';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import ModalTicket from '../components/tickets/ModalTicket';
import { Box } from '@mui/system';
import ModalPackage from 'src/components/tickets/ModalPackage';

const useStyles = createUseStyles({
  desktopEventPageContent: {
    '@media (min-width: 900px)': {
      marginTop: '67px',
      marginBottom: '67px',
    },
  },
  eventSectionContainer: {
    marginBottom: '32.71px',
  },
  button: {
    color: 'white',
    marginTop: 20,
    borderRadius: '25px',
    cursor: 'pointer',
  },
  guestsAndTotal: {
    padding: '20px 0',
  },
  totalAndSubmitButton: {
    padding: '20px',
  },
  text: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'black',
    lineHeight: '18px',
  },
  backDrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: '100%',
    minHeight: '100vh',
  },
});

interface Props {
  event: Event;
  totalTicketsByType: TicketsNumberByType;
  totalNumberOfTickets: number;
  totalPrice: number;
  totalFees: number;
  selectedTicket: any;
  setSelectedTicket: any;
  isPaymentModalOpen: boolean;
  isRightAmountOfTickets: boolean;
  noTicketsAvailable: boolean;
  setTotalTicketsByType: React.Dispatch<
    React.SetStateAction<TicketsNumberByType>
  >;
  onClosePaymentModal: () => void;
  onSubmitRequest: (guestFormValues: GuestFormValues) => void;
  onSubmitBuyTickets: () => void;
  onSubmitPayment: (token: Token) => void;
  onRedirectToLogin: () => void;
}

export const DesktopEventPageContent: React.VFC<Props> = ({
  event,
  totalNumberOfTickets,
  totalPrice,
  totalFees,
  totalTicketsByType,
  isPaymentModalOpen,
  isRightAmountOfTickets,
  noTicketsAvailable,
  setTotalTicketsByType,
  onSubmitRequest,
  onSubmitPayment,
  onClosePaymentModal,
  onRedirectToLogin,
  onSubmitBuyTickets,
  setSelectedTicket,
  selectedTicket,
}) => {
  const classes = useStyles();
  const { isLoading, ticketsSuccessfullyBought } = useAppSelector(
    ({
      ticket: {
        isLoading,
        ticketsSuccessfullyBought,
        error,
        purchasedTicketsFormState,
      },
      authentication: { user },
    }) => ({
      isLoading,
      ticketsSuccessfullyBought,
      error,
      purchasedTicketsFormState,
      user,
    }),
  );
  return (
    <div className={classes.desktopEventPageContent}>
      <Stack direction={'column'} justifyContent="center" alignItems="start">
        <Box className={classes.eventSectionContainer}>
          <EventSection event={event} />
        </Box>
        <Box
          height="100%"
          display={'flex'}
          justifySelf="center"
          width={'867px'}
          justifyContent={'left'}
          marginBottom="32.71px"
        >
          <Grid container xs={event.isPackages ?? 12} spacing={1}>
            <Grid item xs={event.isPackages ? 12 : 7}>
              <EventDescriptionSection
                description={event.description ?? ''}
                cityColor={event.cityColor}
              />
            </Grid>

            <Grid justifyContent="center" item xs={event.isPackages ? 9 : 5}>
              {event.isPackages && (
                <ModalPackage
                  isModal={false}
                  cityColor={`#${event.cityColor}`}
                  event={event}
                  onReservation={event.onReservation ?? false}
                  totalTicketsByType={totalTicketsByType}
                  totalNumberOfTickets={totalNumberOfTickets}
                  totalPrice={totalPrice}
                  totalFees={totalFees}
                  isPaymentOpen={isPaymentModalOpen}
                  isPaymentModalOpen={isPaymentModalOpen}
                  isRightAmountOfTickets={isRightAmountOfTickets}
                  onRedirectToLogin={onRedirectToLogin}
                  setTotalTicketsByType={setTotalTicketsByType}
                  onSubmitRequest={onSubmitRequest}
                  onSubmitBuyTickets={onSubmitBuyTickets}
                  onSubmitPayment={onSubmitPayment}
                  noTicketsAvailable={noTicketsAvailable}

                />
              )}
              {(
                <ModalTicket
                  isModal={false}
                  cityColor={`#${event.cityColor}`}
                  event={event}
                  onReservation={event.onReservation ?? false}
                  totalTicketsByType={totalTicketsByType}
                  totalNumberOfTickets={totalNumberOfTickets}
                  totalPrice={totalPrice}
                  totalFees={totalFees}
                  isPaymentOpen={isPaymentModalOpen}
                  isPaymentModalOpen={isPaymentModalOpen}
                  isRightAmountOfTickets={isRightAmountOfTickets}
                  onRedirectToLogin={onRedirectToLogin}
                  setTotalTicketsByType={setTotalTicketsByType}
                  onSubmitRequest={onSubmitRequest}
                  onSubmitBuyTickets={onSubmitBuyTickets}
                  onSubmitPayment={onSubmitPayment}
                  noTicketsAvailable={noTicketsAvailable}
                  setSelectedTicket={setSelectedTicket}
                  selectedTicket={selectedTicket}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Stack>
      <Modal
        open={isPaymentModalOpen}
        onClose={onClosePaymentModal}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <div>
          <CheckoutForm
            cityColor={event.cityColor || '#000000'}
            amount={totalPrice}
            currency={event.currencySymbol ?? '$'}
            onError={(error) => console.log(error)}
            onClose={onClosePaymentModal}
            onSuccess={onSubmitPayment}
            isPaymentLoading={isLoading}
            isPaymentSuccessful={ticketsSuccessfullyBought ?? false}
          />
        </div>
      </Modal>
    </div>
  );
};
