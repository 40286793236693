import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Parse from 'parse/node';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Event } from 'src/models/event';
import { theme } from 'src/constants/theme';
import { TicketForm } from '../tickets/TicketForm';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { sendTicketsInvite } from '../../slices/request';
import { Container } from '@mui/system';
import planner from 'src/slices/planner';

const useStyles = createUseStyles({
  nonModalBody: {
    backgroundColor: 'rgba(255,255,255, 1)',
    boxShadow: '0px 0px 23px 0px rgba(0,0,0,0.30)',
    zIndex: 2,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderRadius: '11.7px',
    width: 333,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  container: {
    float: 'left',
  },
  arrowButton: {
    fontSize: 60,
    color: 'white !important',
    fill: 'white !important',
    stroke: 'white !important',
  },
  title: {
    fontSize: '30px',
    fontWeight: 800,
    color: 'black',
    lineHeight: '36px',
  },
  subtitle: {
    fontSize: '13px',
    color: 'black',
    fontWeight: 600,
    lineHeight: '14px',
  },
  endSaleDate: {
    fontSize: '14.5px',
    color: 'black',
    fontWeight: 500,
    opacity: 0.24,
    lineHeight: '16px',
    marginBottom: '16.61px',
  },
  text: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'black',
    lineHeight: '25px',
  },
  mediumText: {
    fontSize: '14.5px',
    fontWeight: 600,
    color: 'black',
    lineHeight: '18px',
  },
  ticketForm: {
    padding: '27.9px 30.9px',
  },
  button: {
    height: '45px',
    borderRadius: '22.5px',
    fontWeight: 'bold',
    fontSize: '17px',
    lineHeight: '19px',
  },
  backDrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    height: '100%',
    minHeight: '100vh',
  },
  noTicketsAvailableText: {
    fontSize: '15px',
    textAlign: 'center',
    fontWeight: 700,
    color: 'black',
    lineHeight: '18px',
    opacity: 0.49,
  },
  textField: {
    color: 'black',
    '& .MuiOutlinedInput-input': {
      padding: '10.7px 20.7px',
    },
    [`& fieldset`]: {
      border: 'none',
    },
  },
  inputColor: {
    border: 'solid 2px #F0F0F0',
    borderRadius: 30,
    backgroundColor: '#white',
    [`& fieldset`]: { border: 'none' },
  },
});

interface Props {
  event: Event;
}

export const TicketInvitesForm: React.VFC<Props> = ({ event }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const Planner = Parse.User.current();

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    promoter: yup.string().required('Promoter is required'),
    inviteTickets: yup
      .array()
      .of(
        yup.object({
          id: yup.string().required('Ticket is required'),
          quantity: yup.number().required('Quantity is required'),
        }),
      )
      .test(
        'At Least one Ticket',
        'At least one ticket is required',
        (value) => {
          return (
            value?.some((ticket) => ticket.quantity && ticket.quantity > 0) ||
            false
          );
        },
      ),
  });

  const initialValues = {
    inviteTickets:
      event.tickets?.map((ticket) => ({
        id: ticket.id,
        quantity: 0,
      })) || [],
    name: '',
    email: '',
    promoter: '',
    phoneNumber: '',
  };
  const [inviteType, setInviteType] = React.useState('email');

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      console.log(values);
      //TODO: add on submit
      dispatch(
        sendTicketsInvite({
          name: values.name,
          email: values.email,
          promoter: values.promoter,
          inviteType: inviteType,
          phoneNumber: values.phoneNumber,
          inviteTickets: values.inviteTickets.filter(
            (ticket) => ticket.quantity > 0,
          ),
          eventId: event.id,
        }),
      );
      resetForm();
    },
  });

  function onChangeTicket(ticketId: string, value: number) {
    formik.setFieldValue(
      'inviteTickets',
      formik.values.inviteTickets.map((ticket) =>
        ticket.id === ticketId ? { ...ticket, quantity: value } : ticket,
      ),
    );
  }

  const buildTicketForm = () => (
    <Box className={classes.ticketForm}>
      {(event.tickets?.filter((ticket) => ticket?.onInvitation) ?? []).map(
        (eventTicket, index) => (
          <TicketForm
            key={index}
            cityColor={'#10BF7A'}
            onChangeNumberOfTickets={(value: number) => {
              if (eventTicket.id) {
                onChangeTicket(eventTicket.id, value);
              }
            }}
            noAmounts
            maximum={Infinity}
            numberOfTickets={
              formik.values.inviteTickets.find(
                (ticket) => ticket.id === eventTicket.id,
              )?.quantity || 0
            }
            ticket={eventTicket}
            currency={event.currency}
          />
        ),
      )}
    </Box>
  );

  return event.tickets &&
    event.tickets?.filter((ticket) => ticket?.onInvitation) &&
    event.tickets?.filter((ticket) => ticket?.onInvitation).length > 0 ? (
    <form onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Card
          variant="outlined"
          style={{ marginTop: '20px', boxShadow: ' 0px 2px 4px #979797' }}
        >
          <Box
            style={{
              marginTop: '20px',
              marginLeft: '30px',
              display: 'block',
              position: 'absolute',
              marginBottom: '2px',
            }}
          >
            <Typography className={classes.text}>+ Add New</Typography>
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              paddingX: 3,
              paddingY: 7,
            }}
          >
            <Grid item>
              <Stack direction="column" alignItems="center" spacing="16px">
                <TextField
                  className={classes.textField}
                  name={'name'}
                  value={formik.values.name}
                  placeholder="Name"
                  onChange={formik.handleChange}
                  fullWidth
                  required
                  InputProps={{
                    className: classes.inputColor,
                  }}
                />
                <TextField
                  className={classes.textField}
                  value={formik.values.email}
                  name={'email'}
                  placeholder="Email"
                  onChange={formik.handleChange}
                  fullWidth
                  required
                  InputProps={{
                    className: classes.inputColor,
                  }}
                />
                <Button
                  className={classes.button}
                  type="submit"
                  variant="outlined"
                  onClick={() => setInviteType('email')}
                  fullWidth
                  sx={{
                    color: `#10BF7A !important`,
                    border: `2px solid #10BF7A`,
                    '&:hover': {
                      border: `2px solid #10BF7A`,
                    },
                    textTransform: 'none',
                    opacity: 1,
                    borderRadius: '20px',
                  }}
                >
                  Send Email Invite{' '}
                  <p
                    style={{
                      paddingLeft: 20,
                      textAlign: 'right',
                      fontSize: '9px',
                    }}
                  >
                    Free
                  </p>
                </Button>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction="column" alignItems="center" spacing="16px">
                <TextField
                  className={classes.textField}
                  value={formik.values.promoter}
                  name={'promoter'}
                  placeholder="Added by"
                  onChange={formik.handleChange}
                  required
                  fullWidth
                  InputProps={{
                    className: classes.inputColor,
                  }}
                />
                <TextField
                  className={classes.textField}
                  value={formik.values.phoneNumber}
                  name={'phoneNumber'}
                  placeholder="Phone Number"
                  onChange={formik.handleChange}
                  fullWidth
                  InputProps={{
                    className: classes.inputColor,
                  }}
                />
                <Button
                  className={classes.button}
                  type="submit"
                  variant="outlined"
                  fullWidth
                  disabled={
                    Planner?.get('smsBalance') === 0 ||
                    Planner?.get('smsBalance') === undefined
                  }
                  onClick={() => setInviteType('sms')}
                  sx={{
                    color: `#10BF7A !important`,
                    border: `2px solid #10BF7A`,
                    '&:hover': {
                      border: `2px solid #10BF7A`,
                    },
                    textTransform: 'none',
                    opacity: 1,
                    borderRadius: '20px',
                  }}
                >
                  Send SMS Invite{' '}
                  <p
                    style={{
                      paddingLeft: 20,
                      textAlign: 'right',
                      fontSize: '9px',
                    }}
                  >
                    {' '}
                    {(Planner?.get('smsBalance') ?? 0) + ' Left'}{' '}
                  </p>
                </Button>
              </Stack>
            </Grid>

            <Divider
              style={{ marginRight: '-1px' }}
              orientation="vertical"
              flexItem
            />
            <Grid item>{buildTicketForm()}</Grid>
          </Grid>
        </Card>
      </FormikProvider>
    </form>
  ) : (
    <></>
  );
};
