import React from 'react';
import { createUseStyles } from 'react-jss';
import { City } from 'src/models/city';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  city?: City;
  eventsCount: number;
  displayEventsCount?: boolean;
  emailsCount: string;
  displayText?: string;
  selected?: boolean;
}

const EmailsCard: React.VFC<Props> = ({
  city,
  eventsCount,
  displayEventsCount = true,
  emailsCount,
  displayText,
  selected,
}) => {
  const useStyles = createUseStyles({
    cityCard: {},
    cityImage: {
      borderRadius: '50%',
      width: '170.59px',
      height: '170.59px',

      objectFit: 'cover',
      marginBottom: '5.8px',
      alignContent: 'center',
      paddingTop: '35%',
      marginLeft: 2,
      marginRight: 2,
      textAlign: 'center',
      borderColor: '#E7E7E7',
      borderStyle: 'solid',
      fontWeight: 800,
      fontSize: '12px',
      fontFamily: 'SF Pro Display',
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    cityNameAndEventCount: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      fontSize: '14.58px',
      fontWeight: 600,
      justifyContent: 'center',
      color: `#${city?.mainColor}`,
      '@media screen and (max-width: 900px)': {
        fontSize: '13px',
      },
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    subtitle: {
      fontSize: '10.935px',
      fontWeight: 500,
      justifyContent: 'center',
      marginTop: '-2px',
      color: '#D3D3D3',
      '@media screen and (max-width: 900px)': {
        fontSize: '10px',
      },
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.cityCard}>
      <Stack direction="column" alignItems="center" width="100%">
        <div
          style={{
            backgroundColor: selected ? '#F7B500' : '#FFFFFF',
            color: selected ? 'white' : 'black',
          }}
          className={classes.cityImage}
        >
          {displayText ? displayText : ''}
          <p
            style={{
              marginTop: 2,
              fontFamily: 'SF Pro Display',
              fontSize: '30px',
            }}
          >
            {emailsCount}
          </p>
        </div>
      </Stack>
    </Box>
  );
};

export default EmailsCard;
