import React from 'react';
import { createUseStyles } from 'react-jss';
import { EventTicket } from 'src/models/ticket';
import { Button, Card, Switch, Typography } from '@mui/material';

interface Props {
  ticket: EventTicket;
  currency: string;
  onReservation: boolean;
  canEdit: boolean;
  onClickEditTicket: (ticketId: string) => void;
  onStopTakingRequest: () => void;
  onMakeOnInvitation: () => void;
}

const useStyles = createUseStyles({
  container: {
    border: '1px solid black',
    boxShadow: 'none',
    padding: '1rem 1.5rem',
    borderRadius: '1.75rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  infosEntry: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    '& span': {
      fontSize: '0.75em',
      fontWeight: 'normal',
    },
  },
  editButton: {
    textTransform: 'lowercase',
    color: 'black',
  },
});

export const UpdateEventTicket: React.VFC<Props> = ({
  ticket,
  currency,
  onClickEditTicket,
  onStopTakingRequest,
  onMakeOnInvitation,
  canEdit,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <div>
        <Typography variant="h6">{ticket.name}</Typography>
        <div className={classes.infosEntry}>
          {ticket.total ?? 0} <span>Tickets</span>
        </div>
        <div className={classes.infosEntry}>
          {ticket.price ?? 0} <span>{currency}</span>
        </div>
      </div>
      <div>
        <Button
          className={classes.editButton}
          variant="text"
          onClick={() => {
            if (ticket.id) {
              onClickEditTicket(ticket.id);
            }
          }}
          disabled={Boolean(
            !canEdit || (ticket.sold ? ticket.sold > 0 : false),
          )}
        >
          Edit
        </Button>
        {ticket.onReservation && (
          <>
            <Typography color="error">Stop taking request</Typography>
            <Switch
              checked={ticket.takingRequest === false}
              onChange={onStopTakingRequest}
              disabled={ticket.takingRequest === false}
            />
          </>
        )}
        <Typography color="green">Make on Invitation</Typography>
        <Switch
          checked={ticket.onInvitation == true ? true : false}
          onChange={onMakeOnInvitation}
          disabled={ticket.onInvitation === true}
        />
      </div>
    </Card>
  );
};
