import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import Parse from 'parse/node';

/* Payment slice */

interface PaymentState {
  isLoading: boolean;
  error: string | null;
}

const initialState: PaymentState = {
  isLoading: false,
  error: null,
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AddPaymentMethodDto {
  token: string;
}

/* Thunks */

export const addPaymentMethod = createAsyncThunk<boolean, AddPaymentMethodDto>(
  'payment/addPaymentMethod',
  async ({ token }) => {
    try {
      const user = Parse.User.current();
      if (user) {
        await Parse.Cloud.run('addPaymentMethod', {
          token: token,
          liveStripe: false,
          userId: user.id,
        });
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
);

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
  },
};

/* Slice */
const paymentSlice = createSlice({
  name: 'payment',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addPaymentMethod.pending, sharedReducers.pending)
      .addCase(addPaymentMethod.rejected, sharedReducers.rejected)
      .addCase(addPaymentMethod.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export default paymentSlice.reducer;
