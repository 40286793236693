import React from 'react';

import VideoBox from './ViewBox';
import { createUseStyles } from 'react-jss';

import Thumb1 from '../../assets/images/nft/posterAvatar1.jpg';
import Thumb2 from '../../assets/images/nft/posterAvatar2.jpg';
import Thumb3 from '../../assets/images/nft/posterAvatar3.jpg';
import Thumb4 from '../../assets/images/nft/posterAvatar4.jpg';
import Thumb5 from '../../assets/images/nft/posterAvatar5.png';
import Thumb6 from '../../assets/images/nft/posterAvatar6.jpg';
import Thumb7 from '../../assets/images/nft/posterAvatar7.jpg';
import Thumb8 from '../../assets/images/nft/posterAvatar8.jpg';
import Thumb9 from '../../assets/images/nft/posterAvatar9.jpg';

import Video1 from '../../assets/video/nft/avatar1.mp4';
import Video2 from '../../assets/video/nft/avatar2.mp4';
import Video3 from '../../assets/video/nft/avatar3.mp4';
import Video4 from '../../assets/video/nft/avatar4B.mp4';
import Video5 from '../../assets/video/nft/avatar5B.mp4';
import Video6 from '../../assets/video/nft/avatar6B.mp4';
import Video7 from '../../assets/video/nft/avatar7.mp4';
import Video8 from '../../assets/video/nft/avatar8B.mp4';
import Video9 from '../../assets/video/nft/avatar9.mp4';

const useStyles = createUseStyles({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr 1fr',
    gap: '32px',
    '@media only screen and (max-width: 900px)': {
      gap: '16px',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr',
    },
    '&:before': {
      content: '""',
      width: '0',
      paddingBottom: '100%',
      gridRow: '1 / 1',
      gridColumn: '1 / 1',
    },
    '& > *:first-child': {
      gridRow: '1 / 1',
      gridColumn: '1 / 1',
      '@media only screen and (max-width: 900px)': {
        gridColumnStart: '1',
        gridColumnEnd: '3',
        gridRowStart: '1',
        gridRowEnd: '3',
      },
    },
    '& > *:nth-child(4)': {
      '@media only screen and (max-width: 900px)': {
        gridColumnStart: '1',
        gridColumnEnd: '3',
        gridRowEnd: '6',
        gridRowStart: '4',
      },
    },
    '& > *:nth-child(7)': {
      '@media only screen and (max-width: 900px)': {
        gridRowEnd: '9',
        gridRowStart: '7',
        gridColumnStart: '1',
        gridColumnEnd: '3',
      },
    },
    '& > *:nth-last-child(1)': {
      gridColumn: '1',
      '@media only screen and (max-width: 900px)': { gridColumn: 'auto' },
    },
  },
  hide: {
    '@media only screen and (max-width: 900px)': { display: 'none' },
  },
});

interface Props {
  autoplay?: boolean;
  className?: string;
}

export const VideoCollection: React.FC<Props> = ({ className }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.container} ${className}`}>
      <VideoBox
        autoPlay
        poster={Thumb1}
        mp4={Video1}
        backgroundColor="#F0CEB6"
      />
      <VideoBox
        autoPlay
        poster={Thumb2}
        mp4={Video2}
        backgroundColor="#FF7C8C"
      />
      <VideoBox
        autoPlay
        poster={Thumb3}
        mp4={Video3}
        backgroundColor="#08BEA2"
      />
      <VideoBox
        autoPlay
        poster={Thumb4}
        mp4={Video4}
        backgroundColor="#FFCE59"
      />
      <VideoBox
        autoPlay
        poster={Thumb5}
        mp4={Video5}
        backgroundColor="#3572B0"
      />
      <VideoBox
        autoPlay
        poster={Thumb6}
        mp4={Video6}
        backgroundColor="#FF5A6D"
      />
      <VideoBox
        className={classes.hide}
        autoPlay
        poster={Thumb7}
        mp4={Video7}
        backgroundColor="#15CDB3"
      />
      <VideoBox
        autoPlay
        className={classes.hide}
        poster={Thumb8}
        mp4={Video8}
        backgroundColor="#FFB853"
      />
      <VideoBox
        className={classes.hide}
        autoPlay
        poster={Thumb9}
        mp4={Video9}
        backgroundColor="#FFA9B2"
      />
    </div>
  );
};

export default VideoCollection;
