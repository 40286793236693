import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { FieldArray, FormikErrors, FormikTouched } from 'formik';
import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { EventTicket } from 'src/models/ticket';
import { FormValues } from 'src/pages/UpdateEventPage';
import { UpdateTicket } from './UpdateTicket';
import { UpdateEventTicket } from 'src/components/updateEvent/UpdateEventTicket';
import { commonStyles } from 'src/constants/common-styles';
import AddIcon from '@mui/icons-material/Add';

interface UpdateTicketsProps {
  onChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(
      field: T_1,
    ): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  values: FormValues;
  eventTickets: EventTicket[];
  formikErrors: FormikErrors<FormValues>;
  onBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  formikTouched: FormikTouched<FormValues>;
  setField: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<FormValues>>;
  isApproved: boolean;
}

const useStyles = createUseStyles({
  radioWrapper: {
    marginLeft: '16px !important',
  },
});

export const UpdateTickets: React.VFC<UpdateTicketsProps> = ({
  values: { tickets, onReservation, currency, ticketsExpireIn },
  isApproved,
  eventTickets,
  onChange,
  formikErrors,
  formikTouched,
  onBlur,
  setField,
}) => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const [ticketsBeingEdited, setTicketsBeingEdited] = useState<string[]>([]);

  const totalTicketsSold = useMemo<number>(
    () =>
      tickets?.reduce((total, ticket) => total + (ticket?.sold ?? 0), 0) ?? 0,
    [tickets],
  );

  const onEditTicket = (ticketId: string) => {
    if (!ticketsBeingEdited.includes(ticketId)) {
      setTicketsBeingEdited([...ticketsBeingEdited, ticketId]);
    }
  };

  const onStopTakingRequest = (ticketIndex: number) => {
    setField(`tickets.${ticketIndex}.takingRequest`, false);
  };

  const onMakeOnInvitation = (ticketIndex: number) => {
    setField(`tickets.${ticketIndex}.onInvitation`, true);
  };

  const displayTickets = () =>
    eventTickets.map(({ id }, index) => {
      if (id && !ticketsBeingEdited.includes(id)) {
        const ticket = tickets?.find((t) => t.id === id);
        if (ticket) {
          const canEdit = !isApproved && (!ticket.sold || ticket.sold < 1);
          return (
            <Grid item xs={3} key={index}>
              <UpdateEventTicket
                ticket={ticket}
                currency={currency ?? 'EUR'}
                onReservation={onReservation ?? false}
                canEdit={canEdit}
                onClickEditTicket={onEditTicket}
                onStopTakingRequest={() => onStopTakingRequest(index)}
                onMakeOnInvitation={() => onMakeOnInvitation(index)}
              />
            </Grid>
          );
        }
      }
    });

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3">
          Tickets<sup>*</sup>
        </Typography>
        <Stack spacing={1}>
          <Typography variant="h6">
            Request before booking<sup>*</sup>:
          </Typography>
          <RadioGroup
            row
            name="onReservation"
            value={onReservation}
            onChange={onChange}
            className={classes.radioWrapper}
          >
            <FormControlLabel
              disabled={isApproved || totalTicketsSold > 0}
              value={true}
              control={<Radio />}
              label="Yes"
            />
            <FormControlLabel
              disabled={isApproved || totalTicketsSold > 0}
              value={false}
              control={<Radio />}
              label="No"
            />
          </RadioGroup>
          <Typography variant="h6">
            Requests Expire After:<sup>*</sup>:
          </Typography>
          <RadioGroup
            row
            name="ticketsExpireIn"
            onChange={onChange}
            className={classes.radioWrapper}
            value={ticketsExpireIn}
          >
            <FormControlLabel value="0" control={<Radio />} label="None" />
            <FormControlLabel value="1" control={<Radio />} label="1 Day" />
            <FormControlLabel value="2" control={<Radio />} label="2 Days" />
            <FormControlLabel value="3" control={<Radio />} label="3 Days" />
          </RadioGroup>
        </Stack>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        {displayTickets()}
      </Grid>
      <FieldArray
        name="tickets"
        validateOnChange={false}
        render={(fieldArrayProps) => (
          <>
            {tickets?.map(
              (ticket, index) =>
                (!eventTickets.some(
                  (eventTicket) => eventTicket.id === ticket.id,
                ) ||
                  (ticket.id && ticketsBeingEdited.includes(ticket.id))) && (
                  <UpdateTicket
                    key={index}
                    ticketValues={ticket}
                    onChange={onChange}
                    onClickDelete={() => {
                      fieldArrayProps.remove(index);
                    }}
                    index={index}
                    canDelete={tickets.length > 1}
                    formikErrors={formikErrors}
                    onBlur={onBlur}
                    formikTouched={formikTouched}
                  />
                ),
            )}
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  fieldArrayProps.insert(tickets?.length ?? 0, {
                    name: '',
                    description: '',
                  });
                }}
                variant="outlined"
                startIcon={<AddIcon />}
                className={commonClasses.mainCTA}
              >
                Add New Ticket Category
              </Button>
            </Grid>
          </>
        )}
      />
    </Grid>
  );
};
