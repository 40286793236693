// TODO: use the same SignUp code for the two SignUp pages

import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Stack,
  MenuItem,
  Alert,
  Grid,
  useMediaQuery,
  Select,
  InputAdornment,
} from '@mui/material';
import { Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { resetError, signUp } from 'src/slices/authentication';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { UserGender } from 'src/models/user';
import { useHistory } from 'react-router-dom';
import DatePicker from '@mui/lab/DatePicker';
import { DateTime } from 'luxon';
import { commonStyles } from 'src/constants/common-styles';
import { useQueryParam, StringParam } from 'use-query-params';
import { Box } from '@mui/system';
import { BsCalendarFill } from 'react-icons/all';
import PhoneInput from 'react-phone-input-2';
import FooterBar from '../components/FooterBar';

const useStyles = createUseStyles(() => ({
  container: {
    "@media (min-width: 900px)": {
      marginTop: "10px",
    },
  },
  signUpHeader: {
    fontWeight: 800,
    color: "black",
    fontSize: 45,
    lineHeight: "64px",
    "@media screen and (max-width: 900px)": {
      marginTop: "70px",
    },
  },
  buttonSignup: {
    color: "black",
    borderRadius: "300px",
    border: "2px solid black",
    borderColor: "#F70303",
    width: "313.2px",
    height: "45px",
    textTransform: "none",
    fontSize: "17.2px",
    "&:hover": {
      backgroundColor: "#F70303",
      border: "solid 1px #F70303",
      color: "white",
      opacity: 0.7,
    },
  },
  buttoncontinue: {
    color: "white",
    borderRadius: "300px",
    width: "313.2px",
    height: "45px",
    fontSize: "17.2px",
    backgroundColor: "#FF0202",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#F70303",
      opacity: 0.7,
    },
  },
  Logo: {
    width: "20%",
    height: "20%",
  },
  menubutton: {
    color: "black",
    height: "10%",
    width: "10%",
  },
  fixInput: {
    maxWidth: "100%",
    height: "45px",
    "& .MuiOutlinedInput-input": {
      padding: "10.7px 19.7px",
    },
    [`& fieldset`]: { border: "none" },
  },
  signUpDesc: {
    color: "black",
    marginTop: "-8px !important",
    marginLeft: "3px !important",
    fontWeight: 500,
    fontSize: 17.2,
  },
  phoneInput: {
    borderRadius: "50px !important",
    width: "100% !important",
    height: "45px",
    border: "solid 2px #F0F0F0 !important",
    "&:focus": {
      boxShadow: "none !important",
    },
  },
  phoneInputButton: {
    marginLeft: "10px",
  },
}));

const Signup = () => {
  const classes = useStyles();
  const commonClasses = commonStyles();
  const isDesktop = useMediaQuery('(min-width: 500px)');
  const [requestId] = useQueryParam('requestId', StringParam);
  const dispatch = useAppDispatch();
  const { purchasedTicketsFormState, loading, signError, user } =
    useAppSelector(
      ({
        ticket: { purchasedTicketsFormState },
        authentication: { loading, signError, user },
      }) => ({
        purchasedTicketsFormState,
        loading,
        signError,
        user,
      }),
    );
  const [email, setEmail] = useState('');
  const [password, setpassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [dateOfBirth, setDateOfBirth] = useState<Date | undefined>();
  const [gender, setGender] = useState<UserGender | undefined>();
  const [confirmPassword, Setconfirmation] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const [requiredError, setRequiredError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const isMobile = useMediaQuery('(max-width: 900px)');
  const history = useHistory();

  const onSignedUp = () => {
    if (requestId) {
      history.push(`/request/${requestId}`);
    } else if (purchasedTicketsFormState) {
      history.push(`/events/${purchasedTicketsFormState.eventId}`);
    } else {
      history.push('/');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 1);
  }, []);

  useEffect(() => {
    if (signError) {
      console.log(signError);
      setDisplayError(true);
      dispatch(resetError());
    } else if (!loading && user) {
      onSignedUp();
    }
  }, [signError, loading, user]);

  const onClicksignIn = () => {
    history.push('/sign-in');
  };
  const onClicksignUp = () => {
    if (
      !email ||
      !password ||
      !firstName ||
      !lastName ||
      !phoneNumber ||
      !gender ||
      !confirmPassword
    ) {
      setRequiredError(true);
      return;
    }
    if (password !== confirmPassword) {
      setPasswordError(true);
      return;
    }
    dispatch(
      signUp({
        username: email,
        password,
        firstName,
        lastName,
        email,
        dateOfBirth,
        gender,
        isPlanner: false,
        phoneNumber,
      }),
    );
  };

  return (
    <Box
      padding={2}
      maxWidth="330px"
      marginX="auto"
      className={classes.container}
    >
      <Stack
        direction="column"
        spacing={1.5}
        justifyContent="center"
        width={isDesktop ? '313.2px' : '100%'}
      >
        <Stack direction="column" justifyContent="center">
          <Typography className={classes.signUpHeader}>Sign up</Typography>
          <Typography className={classes.signUpDesc}>Customer</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <TextField
            required
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First name"
            InputProps={{ className: commonClasses.whiteInputProps }}
            className={commonClasses.Input}
          />
          <TextField
            required
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last name"
            InputProps={{ className: commonClasses.whiteInputProps }}
            className={commonClasses.Input}
          />
        </Stack>
        <PhoneInput
          country={'eg'}
          value={phoneNumber}
          inputClass={classes.phoneInput}
          buttonClass={classes.phoneInputButton}
          placeholder=""
          onChange={(phone: any) => setPhoneNumber(phone)}
        />
        <TextField
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          InputProps={{ className: commonClasses.whiteInputProps }}
          className={commonClasses.Input}
        />
        <Stack direction="row" width="100%">
          <Grid container width="100%">
            <Grid item xs={12} md={12}>
              <Stack justifyContent="flex-start" alignItems="center">
                <Select
                  required
                  value={gender}
                  className={`${commonClasses.whiteInputProps} ${classes.fixInput}`}
                  displayEmpty
                  onChange={(event) => {
                    setGender(event.target.value as UserGender);
                  }}
                  sx={{ width: '100%' }}
                  renderValue={
                    gender !== undefined
                      ? undefined
                      : () => <div style={{ opacity: 0.4 }}>Gender</div>
                  }
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <TextField
          required
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setpassword(e.target.value)}
          InputProps={{ className: commonClasses.whiteInputProps }}
          className={commonClasses.Input}
        />
        <TextField
          required
          placeholder="Confirm Password"
          type="password"
          value={confirmPassword}
          onChange={(e) => Setconfirmation(e.target.value)}
          InputProps={{ className: commonClasses.whiteInputProps }}
          className={commonClasses.Input}
        />
        <Stack alignItems="center" spacing={3}>
          <Button
            onClick={onClicksignUp}
            variant="outlined"
            className={classes.buttoncontinue}
            size="large"
            fullWidth
          >
            Create account
          </Button>
          <Stack alignItems="center">
            <Typography variant="h5">Or</Typography>
          </Stack>
          <Button
            onClick={onClicksignIn}
            variant="outlined"
            className={classes.buttonSignup}
            size="large"
            fullWidth
          >
            Sign In
          </Button>
        </Stack>
        {(displayError && (
          <Alert
            onClose={() => setDisplayError(false)}
            severity="error"
            sx={{ width: '100%' }}
          >
            This email or phone number is already associated to an account.
            Please sign in or sign up using a different email.
          </Alert>
        )) ||
          displayError}
        {(requiredError && (
          <Alert
            onClose={() => setRequiredError(false)}
            severity="error"
            sx={{ width: '100%' }}
          >
            Please fill in all required fields
          </Alert>
        )) ||
          requiredError}
        {(passwordError && (
          <Alert
            onClose={() => setPasswordError(false)}
            severity="error"
            sx={{ width: '100%' }}
          >
            Passwords do not match
          </Alert>
        )) ||
          passwordError}
      </Stack>
      {isMobile && (
        <>
          <div style={{ height: '20px' }} />
          <FooterBar />
        </>
      )}
    </Box>
  );
};

export default Signup;
