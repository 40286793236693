import React from 'react';
import { TextField } from '@mui/material';

interface Props {
  name: string;
  placeholder?: string;
  label?: string;
  value?: string;
  touched?: boolean;
  error?: string;
  fullWidth?: boolean;
  sx?: any;
  type?: string;
  className?: string;
  inputProps?: any;
  InputProps?: any;
  required?: boolean;
  minRows?: number;
  multiline?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const FormTextField: React.VFC<Props> = ({
  name,
  placeholder,
  label,
  value,
  touched,
  error,
  fullWidth,
  required,
  sx,
  type,
  className,
  onChange,
  onBlur,
  InputProps,
  inputProps,
  minRows,
  multiline,
}) => {
  return (
    <TextField
      className={className}
      name={name}
      placeholder={placeholder}
      label={label}
      value={value}
      onChange={onChange}
      InputProps={InputProps}
      inputProps={inputProps}
      error={Boolean(touched && error)}
      helperText={touched && error ? error : ''}
      onBlur={onBlur}
      fullWidth={fullWidth}
      sx={sx}
      type={type}
      required={required}
      minRows={minRows}
      multiline={multiline}
    />
  );
};
