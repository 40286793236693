import { Grid, Box, Button, Typography, Container, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EventPlannerCard from 'src/components/event/EventPlannerCard';
import { Card } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { Event } from 'src/models/event';
import { fetchPlannerEvents } from 'src/slices/event';
import { useHistory } from 'react-router-dom';
import { getTicketsSales } from 'src/slices/ticket';
import FullscreenSpin from 'src/components/FullscreenSpin';
import { createUseStyles } from 'react-jss';
import { placeHolderEvent } from 'src/data/placeholderEvent';
import { getPlaceholderTicketSales } from 'src/data/placeholderPurchasesTickets';
import PlannerTopBar from 'src/components/eventPlannerMobile/PlannerTopBar';
import PlannerEventCard from 'src/components/eventPlannerMobile/PlannerEventCard';
import { FaPen } from 'react-icons/fa';
import Parse from 'parse/node';
import FooterBar from 'src/components/FooterBar';

const useStyles = createUseStyles({
  title: {
    marginTop: '3%',
    fontSize: '20px',
    fontWeight: 800,
    color: '#000',
  },
  eventPlannerDashboardContainer: {
    minWidth: '100vw',
    minHeight: '100vh',
  },
  placeholderEventsContainer: {
    pointerEvents: 'none',
    opacity: '0.29',
  },
  createEventButton: {
    color: 'black',
    padding: '8px 30px',
    borderRadius: '10rem',
    border: '1px solid black',
    fontWeight: 500,
    fontSize: '22px',
    textTransform: 'none',
    '&:hover': {
      border: '3px solid black',
      color: 'white',
      backgroundColor: '#000',
    },
    '& svg': {
      width: '32px',
      height: '32px',
    },
  },
  button: {
    color: 'white',
    borderRadius: '30px',
    cursor: 'pointer',
    width: '70vw',
    height: '10vw',
    marginTop: '3%',
  },
  image: {
    height: '30vw',
    width: '30vw',
  },
  availablesoon: {
    position: 'absolute',
    left: '40vw',
    right: 0,
    top: '3vw',
    bottom: 0,
  },
  CardforIcon: {
    position: 'absolute',
    left: '48vw',
    right: 0,
    top: '1vw',
    bottom: 0,
    backgroundColor: 'grey',
    maxHeight: '6vw',
    maxWidth: '6vw',
    borderRadius: '9px',
  },
  Penicon: {
    color: 'black',
    marginLeft: '1.5vw',
    marginTop: '5vw',
  },
  TopBarPlanner: {
    marginBottom: '3%',
  },

  EventsCard: {
    marginLeft: '0%',
    marginTop: '0%',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    maxWidth: '80%',
  },
});

function EventPlannerDashboardMobile() {
  const classes = useStyles();
  const history = useHistory();
  const PlannerName = Parse.User.current()?.get('name');
  const dispatch = useAppDispatch();
  const { events, isEventsLoading } = useAppSelector(
    ({ event: { plannerEvents, isLoading } }) => ({
      events: plannerEvents,
      isEventsLoading: isLoading,
    }),
  );
  const { ticketsSales } = useAppSelector(({ ticket: { ticketsSales } }) => ({
    ticketsSales,
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchPlannerEvents());
    dispatch(getTicketsSales());
  }, []);
  const sortedEvents = useMemo(
    () =>
      [...(events ?? [])].sort((firstEvent, secondEvent) => {
        const firstEventDate = new Date(firstEvent.startDate);
        const secondEventDate = new Date(secondEvent.startDate);
        const firstEventEndDate = new Date(firstEvent.endDate);
        const secondEventEndDate = new Date(secondEvent.endDate);
        if (firstEventEndDate < new Date() && secondEventEndDate > new Date()) {
          return 1;
        } else if (
          firstEventEndDate > new Date() &&
          secondEventEndDate < new Date()
        ) {
          return -1;
        } else if (
          firstEvent.state === 'active' &&
          secondEvent.state !== 'active'
        ) {
          return -1;
        } else if (
          firstEvent.state !== 'active' &&
          secondEvent.state === 'active'
        ) {
          return 1;
        } else {
          return firstEvent.createdAt && secondEvent.createdAt
            ? new Date(secondEvent.createdAt).getTime() -
                new Date(firstEvent.createdAt).getTime()
            : secondEventDate.getTime() - firstEventDate.getTime();
        }
      }),
    [events],
  );

  if (isEventsLoading) {
    return <FullscreenSpin />;
  }
  return (
    <div>
      <Container
        sx={{
          paddingTop: '2vh',
          minWidth: '100%',
          justifyContent: 'center',
          marginBottom: '30vw',
        }}
        className={classes.EventsCard}
      >
        <PlannerTopBar></PlannerTopBar>
        <Stack
          direction="column"
          alignItems={'center'}
          justifyContent={'center'}
          justifyItems={'center'}
          alignContent="center"
          sx={{ marginTop: '10%' }}
        >
          {events?.length === 0 ? (
            <div className={classes.placeholderEventsContainer}>
              <PlannerEventCard event={placeHolderEvent} />
            </div>
          ) : (
            sortedEvents.map((event: Event) => (
              <Card
                key={event.id}
                sx={{
                  maxWidth: '90vw',
                  paddingBottom: '4%',
                  minWidth: '90vw',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                style={{ boxShadow: 'none' }}
              >
                {<PlannerEventCard event={event} />}
              </Card>
            ))
          )}
        </Stack>
      </Container>
    </div>
  );
}

export default EventPlannerDashboardMobile;
