import React from 'react';
import { Button } from '@mui/material';

type ButtonProps = {
  value: any;
  icon?: any;
  onClick: any;
  color: string;
  font: string;
  disable?: boolean;
  err?: boolean;
  disabled?: boolean;
};

const DisabledEventButton = ({
  value,
  onClick,
  color,
  font,
  icon,
}: ButtonProps) => {
  return (
    <Button
      disabled
      fullWidth
      onClick={onClick}
      variant="contained"
      style={{
        background: color,
        color: font,
        cursor: 'pointer',
        opacity: '0.76',
        textTransform: 'none',
      }}
    >
      {icon}
      {value}
    </Button>
  );
};

export default DisabledEventButton;
