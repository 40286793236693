import { Card, Grid, CardContent, Stack } from '@mui/material';
import React from 'react';
import { Event } from 'src/models/event';
import EventButton from 'src/components/event/EventButton';
import DisabledEventButton from 'src/components/event/DisabledEventButton';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { createUseStyles } from 'react-jss';
import Box from '@mui/material/Box';

interface Props {
  event: Event;
}

const useStyles = createUseStyles({
  cardContent: {
    // width: 360,
    // height: 360,
    // width: '100%',
    height: '100%',
    padding: '30px',
    // '@media screen and (max-width: 1800px)': {
    //   width: 320,
    //   height: 320,
    // },
    // '@media screen and (max-width: 1600px)': {
    //   width: 288,
    //   height: 288,
    // },
    // '@media screen and (max-width: 1400px)': {
    //   width: 250,
    //   height: 250,
    // },
    // '@media screen and (max-width: 1200px)': {
    //   width: 200,
    //   height: 200,
    //   padding: '20px',
    // },
    // '@media screen and (max-width: 1000px)': {
    //   width: 150,
    //   height: 150,
    // },
  },
  button: {
    opacity: '0.5',
  },
  card: {
    height: 'auto',
    width: '100%',
    aspectRatio: 1,
  },
  image: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    // backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    backgroundPosition: 'center 40%',
    objectFit: 'cover',
  },
  buttonText: {
    fontWeight: 800,
    fontSize: '20px',
    '@media screen and (max-width: 1500px)': {
      fontSize: '16px',
    },
    '@media screen and (max-width: 1200px)': {
      fontSize: '12px',
    },
    '@media screen and (max-width: 1000px)': {
      fontSize: '10px',
    },
  },
});

const MyEventsCard: React.VFC<Props> = ({ event }) => {
  const history = useHistory();
  const classes = useStyles();
  const iscurrentDate = moment(event.endDate).isBefore(moment());

  return (
    <Card className={classes.card}>
      <Box component="img" className={classes.image} src={event.imageUrl} />
      <CardContent className={classes.cardContent}>
        <Stack direction="column" justifyContent="space-between" height="100%">
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={12}>
              <DisabledEventButton
                onClick={() => {
                  //
                }}
                color={'black'}
                font={'white'}
                value={(() => {
                  if (iscurrentDate == true) {
                    return <div className={classes.buttonText}>Finished</div>;
                  } else if (event.state === 'pending' || !event.state) {
                    return <div className={classes.buttonText}>In Review</div>;
                  } else if (event.state === 'refused') {
                    return <div className={classes.buttonText}>Refused</div>;
                  } else if (event.state === 'active') {
                    return <div className={classes.buttonText}>Approved</div>;
                  }
                })()}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={event.onReservation ? 6 : 12}>
              {(() => {
                if (iscurrentDate == true) {
                  return (
                    <DisabledEventButton
                      onClick={() => {
                        //
                      }}
                      color={'black'}
                      font={'grey'}
                      value={<div className={classes.buttonText}>Edit</div>}
                    />
                  );
                } else {
                  return (
                    <EventButton
                      onClick={() => {
                        {
                          history.push(`/update-event/${event?.id}`);
                        }
                      }}
                      color="black"
                      font={'white'}
                      value={<div className={classes.buttonText}>Edit</div>}
                    />
                  );
                }
              })()}
            </Grid>
            <Grid item xs={6}>
              {event?.onReservation && (
                <EventButton
                  onClick={() => {
                    {
                      history.push(`event/${event.id}/requests`);
                    }
                  }}
                  color={'black'}
                  font={'white'}
                  value={<div className={classes.buttonText}>Requests</div>}
                />
              )}
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MyEventsCard;
