// TODO: use the same code for creating or editing an Event

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { getCities } from 'src/slices/project';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import {
  Autocomplete,
  Button,
  CardActionArea,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import FooterBar from 'src/components/FooterBar';
import { BsCheckCircleFill } from 'react-icons/bs';
import { FileUploader } from 'react-drag-drop-files';
import CityCard from 'src/components/event/CityCard';
import 'react-datepicker/dist/react-datepicker.css';
import { currencies } from 'src/constants/currencies';
import { CreateTickets } from 'src/components/createEvent/CreateTickets';
import { createEvent, reset } from 'src/slices/event';
import { useHistory } from 'react-router-dom';
import { City } from 'src/models/city';
import { EventTicket } from 'src/models/ticket';
import DateTimePicker from '@mui/lab/DateTimePicker';
import timezones from 'timezones-list';
import FullscreenSpin from 'src/components/FullscreenSpin';
import { Box } from '@mui/system';
import { usePlacesWidget } from 'react-google-autocomplete';
import { countryByCityId } from 'src/helpers/cityFunctions';
import { DateTime } from 'luxon';
import { getDateInTimeZone } from 'src/helpers/dateHelpers';
import { getPlannerFees } from 'src/slices/planner';

const MAIN_MARGIN = '9%';

const useStyles = createUseStyles({
  main: {
    overflowY: "scroll",
    marginLeft: MAIN_MARGIN,
    "& sup": {
      color: "red",
    },
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    overflowY: "hidden",
    alignItems: "center",
    alignSelf: "center",
    height: "fit-content",
    flexShrink: 1,
    marginTop: "0.75rem",
    marginBottom: "1.5rem",
  },
  cityCardWrapper: {
    padding: "0.5rem 0.75rem",
    borderRadius: "0.5rem",
    flexShrink: 0,
    width: "auto",
  },
  CheckIcon: {
    position: "absolute",
    fontSize: "1.25rem",
    top: "1rem",
    right: "1.25rem",
    background: "white",
    borderRadius: "50%",
  },
  formInner: {
    marginRight: MAIN_MARGIN,
    maxWidth: "60rem",
  },
  createTicketsWrapper: {
    marginRight: MAIN_MARGIN,
    marginTop: "32px",
  },
  Input: {
    color: "black",
    [`& fieldset`]: { border: "none" },
  },
  whiteInputProps: {
    color: "black",
    border: "1px solid #F0F0F0",
    borderRadius: 30,
    [`& fieldset`]: { border: "none" },
  },
  orangeCTA: {
    color: "white",
    borderRadius: "10rem",
    fontSize: "1.15rem",
    textTransform: "none",
    marginTop: "4rem",
    background: "#FF2020",
    minWidth: "22rem",
    "&:hover": {
      backgroundColor: "#FF2020",
      opacity: 0.7,
    },
  },
});

export interface FormValues {
  timezone: string;
  currency: string;
  name: string;
  imageFile?: File;
  startDate: Date | undefined;
  endDate: Date | undefined;
  venueName: string;
  streetAddress: string;
  dj: string;
  djprofileUrl: string;
  musicType: string;
  fashion: string;
  minimumAge: number;
  description: string;
  tickets: EventTicket[];
  onReservation: boolean;
  ticketsExpireIn: number;
  usePaymob: boolean;
}

const CreateEventPage: React.VFC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const cities = useAppSelector((state) => state.project.cities);
  const history = useHistory();
  const [citySelected, setCitySelected] = useState<City | undefined>();
  const [initializedScreen, setInitializedScreen] = useState(false);
  const displayCityEvents = (city: City) => {
    setCitySelected(city);
  };
  const { isLoading, eventCreated } = useAppSelector((state) => state.event);
  const fees = useAppSelector((state) => state.planner.fees);

  useEffect(() => {
    if (!cities || cities.length === 0) {
      dispatch(getCities());
    }
  }, []);

  useEffect(() => {
    setInitializedScreen(true);
    dispatch(reset());
  }, []);

  useEffect(() => {
    if (eventCreated && initializedScreen) {
      history.push('/');
    }
  }, [eventCreated]);

  useEffect(() => {
    if (!citySelected && cities[0]) {
      setCitySelected(cities[0]);
    }
  }, [cities]);

  useEffect(() => {
    if (citySelected) {
      if (citySelected.timeZone) {
        formik.setFieldValue('timezone', citySelected.timeZone);
      }
      if (citySelected.currency) {
        formik.setFieldValue('currency', citySelected.currency);
      }
    }
  }, [citySelected]);

  const initialValues: FormValues = {
    ticketsExpireIn: 0,
    usePaymob: true,
    timezone: '',
    currency: '',
    name: '',
    imageFile: undefined,
    startDate: new Date(),
    endDate: undefined,
    venueName: '',
    streetAddress: '',
    dj: '',
    djprofileUrl: '',
    musicType: '',
    fashion: '',
    minimumAge: 18,
    description: '',
    tickets: [
      {
        name: '',
        description: '',
      },
    ],
    onReservation: true,
  };
  const validationSchema = yup.object({
    timezone: yup.string().required().label('Timezone'),
    currency: yup.string().required().label('Currency'),
    name: yup.string().required().label('Name'),
    imageFile: yup.mixed().required().label('Image file'),
    startDate: yup.date().required().label('Start date'),
    endDate: yup.date().required().label('End date'),
    venueName: yup.string().required().label('Venue name'),
    streetAddress: yup.string().label('Street address'),
    dj: yup.string().label('Dj'),
    djprofileUrl: yup.string().url().label('Dj profile'),
    musicType: yup.string().label('Music type'),
    fashion: yup.string().label('Fashion'),
    minimumAge: yup.number().positive().integer().label('Minimum age'),
    description: yup.string().required().label('Description'),
    onReservation: yup.boolean().default(true).label('On reservation'),
    tickketsExpireIn: yup
      .number()
      .positive()
      .integer()
      .label('Tickets expire in'),
    usePaymob: yup.boolean().default(false).label('Use paymob'),
    tickets: yup.array().of(
      yup.object().shape({
        name: yup.string().required().label('Ticket name'),
        description: yup.string().required().label('Ticket description'),
        price: yup.number().positive().required().label('Price'),
        total: yup.number().positive().required().label('Quantity'),
      }),
    ),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (values) => {
      onSubmit(values);
    },
  });

  const { ref: materialRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: async (place) => {
      await formik.setFieldValue('streetAddress', place.formatted_address);
      formik.validateField('streetAddress');
    },

    options: {
      types: ['geocode', 'establishment'],
      componentRestrictions:
        citySelected?.id && countryByCityId[citySelected.id]
          ? {
              country: citySelected?.id && countryByCityId[citySelected.id],
            }
          : undefined,
    },
  });
  const fileTypes = ['JPG', 'PNG', 'JPEG'];
  const handleFileChange = async (selectedFile: any) => {
    await formik.setFieldValue('imageFile', selectedFile);
    formik.validateField('imageFile');
  };

  const onSubmit = async (values: FormValues) => {
    dispatch(
      createEvent({
        city: citySelected?.id,
        name: values.name,
        description: values.description,
        address: values.streetAddress,
        addressName: values.venueName,
        currency: currencies?.[values?.currency]?.code,
        currencySymbol: currencies?.[values?.currency]?.symbol,
        timeZone: values.timezone,
        coverPhoto: values.imageFile,
        dj: values.dj,
        djLink: values.djprofileUrl,
        onReservation:
          values.onReservation.toString() == 'true' ||
          values.onReservation == true,
        start:
          values.startDate &&
          getDateInTimeZone(values.startDate, values.timezone),
        end:
          values.endDate && getDateInTimeZone(values.endDate, values.timezone),
        infoRule: values.fashion,
        infoRule2: values.minimumAge?.toString(),
        tickets: values.tickets,
        ticketsExpireIn: parseInt(values.ticketsExpireIn?.toString()),
        usePaymob: values.usePaymob.toString() == 'true' ? true : false,
      }),
    );
  };

  const onChangeStartDate = (date: any) => {
    const updatedDate = date as DateTime | undefined;
    if (updatedDate) {
      formik.setFieldValue('startDate', updatedDate.toJSDate());
      formik.validateField('startDate');
    }
  };

  const onChangeEndDate = (date: any) => {
    const updatedDate = date as DateTime | undefined;
    if (updatedDate) {
      formik.setFieldValue('endDate', updatedDate.toJSDate());
      formik.validateField('endDate');
    }
  };

  if (isLoading) {
    return <FullscreenSpin />;
  }
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormikProvider value={formik}>
          <div className={classes.main}>
            <div style={{ margin: '50px 90px' }}>
              <Typography variant="h2">Create New Event</Typography>
            </div>

            <Box>
              <Typography variant="h3">
                City<sup>*</sup>
              </Typography>
              <Box className={classes.cards}>
                {cities.map((city: City) => (
                  <CardActionArea
                    key={city.id}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                    onClick={() => displayCityEvents(city)}
                    className={classes.cityCardWrapper}
                  >
                    <CityCard
                      city={city}
                      eventsCount={0}
                      displayEventsCount={false}
                    />
                    <BsCheckCircleFill
                      className={classes.CheckIcon}
                      style={{
                        color: `#${
                          citySelected?.id === city.id
                            ? city.mainColor
                            : 'C1C1C1'
                        }`,
                      }}
                    />
                  </CardActionArea>
                ))}
              </Box>
            </Box>

            <Grid container spacing={4} className={classes.formInner}>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <Typography variant="h3">
                    Time Zone<sup>*</sup>
                  </Typography>
                  <Select
                    id="timezone"
                    name="timezone"
                    placeholder="Europe/Barcelona"
                    value={formik.values.timezone}
                    onChange={(event: SelectChangeEvent<string>) => {
                      if (!citySelected?.timeZone) {
                        formik.handleChange(event);
                      }
                    }}
                    disabled={
                      !!citySelected?.timeZone &&
                      citySelected?.id != '0xOzqoldYK'
                    }
                    className={classes.Input}
                    inputProps={{ className: classes.whiteInputProps }}
                    required
                    error={Boolean(
                      formik.touched.timezone && formik.errors.timezone,
                    )}
                  >
                    {timezones.map((timezone, index) => (
                      <MenuItem key={index} value={timezone.tzCode}>
                        {timezone.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <Typography variant="h3">
                    Currency<sup>*</sup>
                  </Typography>
                  <Select
                    id="currency"
                    name="currency"
                    label="Currency"
                    placeholder="EUR"
                    value={formik.values.currency}
                    onChange={(event: SelectChangeEvent<string>) => {
                      if (!citySelected?.currency) {
                        formik.handleChange(event);
                      }
                    }}
                    disabled={!!citySelected?.currency}
                    className={classes.Input}
                    inputProps={{ className: classes.whiteInputProps }}
                    required
                    error={Boolean(
                      formik.touched.currency && formik.errors.currency,
                    )}
                  >
                    {Object.values(currencies).map((currency, index) => (
                      <MenuItem key={index} value={currency.code}>
                        {currency.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack spacing={1}>
                  <Typography variant="h3">
                    Name<sup>*</sup>
                  </Typography>
                  <TextField
                    id="name"
                    name="name"
                    placeholder="e.g. Moonrock Festival "
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    InputProps={{ className: classes.whiteInputProps }}
                    className={classes.Input}
                    required
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    helperText={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : ''
                    }
                    onBlur={formik.handleBlur}
                  />
                </Stack>
              </Grid>
              <Grid item xs={8}>
                <Stack spacing={1}>
                  <Typography variant="h3">
                    Main Event Image<sup>*</sup>
                  </Typography>
                  <FileUploader
                    handleChange={handleFileChange}
                    name="file"
                    types={fileTypes}
                  />
                  {formik.errors.imageFile && (
                    <Typography color="error" fontSize={12}>
                      {formik.errors.imageFile}
                    </Typography>
                  )}
                </Stack>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3">
                    Date and Time<sup>*</sup>
                  </Typography>
                </Grid>
                <Grid item container spacing={4} xs={12}>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <Typography variant="h6">Event Starts:</Typography>
                      <DateTimePicker
                        minDate={DateTime.now()}
                        maxDate={
                          formik.values.endDate
                            ? DateTime.fromJSDate(formik.values.endDate)
                            : null
                        }
                        value={
                          formik.values.startDate
                            ? DateTime.fromJSDate(formik.values.startDate)
                            : null
                        }
                        onChange={onChangeStartDate}
                        renderInput={(params) => (
                          <TextField
                            className={classes.whiteInputProps}
                            inputProps={{
                              className: classes.whiteInputProps,
                            }}
                            {...params}
                          />
                        )}
                      />
                      {formik.errors.startDate && (
                        <Typography color="error" fontSize={12}>
                          {formik.errors.startDate}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <Typography variant="h6">Event Ends:</Typography>
                      <DateTimePicker
                        minDate={DateTime.fromJSDate(
                          formik.values.startDate
                            ? formik.values.startDate
                            : new Date(),
                        )}
                        value={
                          formik.values.endDate
                            ? DateTime.fromJSDate(formik.values.endDate)
                            : null
                        }
                        onChange={onChangeEndDate}
                        renderInput={(params) => (
                          <TextField
                            className={classes.whiteInputProps}
                            inputProps={{
                              className: classes.whiteInputProps,
                            }}
                            {...params}
                          />
                        )}
                      />
                      {formik.errors.endDate && (
                        <Typography color="error" fontSize={12}>
                          {formik.errors.endDate}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3">
                    Location<sup>*</sup>
                  </Typography>
                </Grid>
                <Grid item container spacing={4} xs={12}>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <Typography variant="h6">Venue name:</Typography>
                      <TextField
                        id="venueName"
                        name="venueName"
                        placeholder="e.g. Amnesia Ibiza"
                        value={formik.values.venueName}
                        onChange={formik.handleChange}
                        InputProps={{ className: classes.whiteInputProps }}
                        required
                        error={Boolean(
                          formik.touched.venueName && formik.errors.venueName,
                        )}
                        helperText={
                          formik.touched.venueName && formik.errors.venueName
                            ? formik.errors.venueName
                            : ''
                        }
                        onBlur={formik.handleBlur}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <Typography variant="h6">Street Address:</Typography>
                      <Autocomplete
                        id="streetAddress"
                        options={[]}
                        value={formik.values.streetAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        color="secondary"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              className: classes.whiteInputProps,
                            }}
                            error={Boolean(
                              formik.touched.streetAddress &&
                                formik.errors.streetAddress,
                            )}
                            helperText={
                              formik.touched.streetAddress &&
                              formik.errors.streetAddress
                                ? formik.errors.streetAddress
                                : ''
                            }
                            variant="outlined"
                            inputRef={materialRef}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3">Music</Typography>
                </Grid>
                <Grid item container spacing={4} xs={12}>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <Typography variant="h6">
                        Dj(s)<sup>*</sup>:
                      </Typography>
                      <TextField
                        id="dj"
                        name="dj"
                        placeholder="Laurent Garnier"
                        value={formik.values.dj}
                        onChange={formik.handleChange}
                        InputProps={{ className: classes.whiteInputProps }}
                        error={Boolean(formik.touched.dj && formik.errors.dj)}
                        helperText={
                          formik.touched.dj && formik.errors.dj
                            ? formik.errors.dj
                            : ''
                        }
                        required
                        onBlur={formik.handleBlur}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <Typography variant="h6">Dj Profile:</Typography>
                      <TextField
                        id="djprofileUrl"
                        name="djprofileUrl"
                        placeholder="e.g. Instagram.com/carlcoxofficial"
                        value={formik.values.djprofileUrl}
                        onChange={formik.handleChange}
                        InputProps={{ className: classes.whiteInputProps }}
                        error={Boolean(
                          formik.touched.djprofileUrl &&
                            formik.errors.djprofileUrl,
                        )}
                        helperText={
                          formik.touched.djprofileUrl &&
                          formik.errors.djprofileUrl
                            ? formik.errors.djprofileUrl
                            : ''
                        }
                        onBlur={formik.handleBlur}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <Typography variant="h6">Music Type:</Typography>
                      <TextField
                        id="musicType"
                        name="musicType"
                        placeholder="e.g. Techno, House"
                        value={formik.values.musicType}
                        onChange={formik.handleChange}
                        InputProps={{ className: classes.whiteInputProps }}
                        error={Boolean(
                          formik.touched.musicType && formik.errors.musicType,
                        )}
                        helperText={
                          formik.touched.musicType && formik.errors.musicType
                            ? formik.errors.musicType
                            : ''
                        }
                        onBlur={formik.handleBlur}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h3">Other</Typography>
                </Grid>
                <Grid item container spacing={4} xs={12}>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <Typography variant="h6">Fashion:</Typography>
                      <TextField
                        id="fashion"
                        name="fashion"
                        placeholder="E.g. Casual"
                        value={formik.values.fashion}
                        onChange={formik.handleChange}
                        InputProps={{ className: classes.whiteInputProps }}
                        error={Boolean(
                          formik.touched.fashion && formik.errors.fashion,
                        )}
                        helperText={
                          formik.touched.fashion && formik.errors.fashion
                            ? formik.errors.fashion
                            : ''
                        }
                        onBlur={formik.handleBlur}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <Typography variant="h6">Minimum Age:</Typography>
                      <TextField
                        id="minimumAge"
                        name="minimumAge"
                        placeholder="e.g. 18"
                        value={formik.values.minimumAge}
                        onChange={formik.handleChange}
                        InputProps={{ className: classes.whiteInputProps }}
                        error={Boolean(
                          formik.touched.minimumAge && formik.errors.minimumAge,
                        )}
                        helperText={
                          formik.touched.minimumAge && formik.errors.minimumAge
                            ? formik.errors.minimumAge
                            : ''
                        }
                        onBlur={formik.handleBlur}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Stack spacing={1}>
                  <Typography variant="h3">
                    Event Description<sup>*</sup>
                  </Typography>
                  <TextField
                    id="description"
                    name="description"
                    value={formik.values.description}
                    minRows={5}
                    multiline
                    onChange={formik.handleChange}
                    InputProps={{ className: classes.whiteInputProps }}
                    onBlur={formik.handleBlur}
                    error={Boolean(
                      formik.touched.description && formik.errors.description,
                    )}
                    helperText={
                      formik.touched.description && formik.errors.description
                        ? formik.errors.description
                        : ''
                    }
                    required
                  />
                </Stack>
              </Grid>
            </Grid>

            <Box paddingBottom={25} className={classes.createTicketsWrapper}>
              <CreateTickets
                values={formik.values}
                onChange={formik.handleChange}
                formikErrors={formik.errors}
                formikTouched={formik.touched}
                onBlur={formik.handleBlur}
              />

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.orangeCTA}
                >
                  Submit Event for Review
                </Button>
              </div>
            </Box>
          </div>
        </FormikProvider>
      </form>
    </>
  );
};

export default CreateEventPage;
