import {
  CardMedia,
  TextField,
  Grid,
  Box,
  Stack,
  Typography,
  Button,
  SvgIcon,
} from '@mui/material';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Request } from 'src/models/request';
import { Event } from 'src/models/event';
import { ReactComponent as Calendar } from '../../assets/calendar.svg';
import { ReactComponent as Location } from '../../assets/location.svg';
import { Ticket } from 'src/models/ticket';
import { ReactComponent as Cd } from '../../assets/cd.svg';
import { useAppSelector } from 'src/hooks/store';
import { getEventDates } from 'src/helpers/event/getEventDates';
import RequestCircleProgress from '../requests/RequestCircleProgress';

const useStyles = createUseStyles({
  main: {
    margin: '5em',
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  subheaderButton: {
    border: '1px solid black',
    color: 'black',
    padding: '0em 2em',
    borderRadius: '50px',
    backgroundColor: 'white',
    '&:active': {
      color: 'white',
      backgroundColor: 'black',
    },
  },
  eventImage: {
    borderRadius: '25px',
    border: '1px solid #FFF',
    width: '100%',
    height: '15.5em',
  },
  requestCancelButton: {
    border: '1px solid black',
    color: 'black',
    backgroundColor: 'white',
    padding: '0px 2em',
  },
  searchBar: {
    color: 'black',
    [`& fieldset`]: {
      border: 'none',
    },
    width: '400px',
    '@media (max-width: 1500px)': {
      width: '320px',
    },
  },
  buttonSignup: {
    color: 'black',
    borderRadius: '300px',
    border: '1px solide',
    borderColor: 'black',
    width: '300px',
    textTransform: 'none',
  },
  fullSearchBar: {
    border: 'solid 1px #989898',
    borderRadius: 20,
  },
  boldHeader: {
    fontWeight: 900,
  },
  title: {
    fontSize: '20px',
    fontWeight: 800,
    textAlign: 'center',
  },
  subtitle: {
    fontSize: '20px',
    justifyContent: 'center',
    fontWeight: 600,
  },
  numberOfRequests: {
    fontSize: '30px',
    fontWeight: 800,
  },
  svgIcon: {
    marginRight: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
  },
  fieldTitle: {
    fontSize: '15px',
    lineHeight: '18px',
    fontWeight: 600,
    color: 'black',

    maxLines: 1,
  },
  fieldSubtitle: {
    fontSize: '12px',
    fontWeight: 500,
    color: 'black',
    opacity: 0.75,
    textOverflow: 'ellipsis',
    maxLines: 1,
  },
});

interface Props {
  event: Event | null;
  requests: Request[] | null;
  tickets: Ticket[] | null;
}
const LiveAttendanceTopBar: React.VFC<Props> = ({
  event,
  requests,
  tickets,
}) => {
  const classes = useStyles();
  const { dateString, timeString } = event
    ? getEventDates(event)
    : { dateString: '', timeString: '' };
  //Start of total actual tickets number code
  let totalTickets = 0;
  let totalUsed = 0;
  let totalDeclined = 0;
  let totalApproved = 0;
  let totalInvitation = 0;

  tickets?.map((request) => (request.available ? (totalTickets += 1) : 0));
  tickets?.map((request) =>
    request.used && request.available ? (totalUsed += 1) : 0,
  );
  requests?.map((request) =>
    request.isDeclined ? (totalDeclined += request.recipients.length) : 0,
  );
  requests?.map((request) =>
    request.isApproved ? (totalApproved += request.recipients.length) : 0,
  );
  requests?.map((request) =>
    request.isInvitation ? (totalInvitation += request.recipients.length) : 0,
  );
  const { user } = useAppSelector(({ authentication: { user } }) => ({ user }));

  const buildDescriptionField = (
    title?: string,
    subtitle?: string,
    icon?: () => JSX.Element,
    allRow?: boolean,
  ) => (
    <Grid
      item
      container
      direction="row"
      display="flex"
      xs={12}
      sx={{ maxWidth: '100px' }}
    >
      <Stack direction="row" alignItems="center">
        {icon && icon()}
        <Stack direction="column">
          <Typography
            className={classes.fieldTitle}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',

              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
              textoverflow: 'ellipsis',
            }}
          >
            {title}
          </Typography>
          <Typography
            className={classes.fieldSubtitle}
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    </Grid>
  );
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={2.5} justifyContent="start">
          {event?.imageUrl == null ? (
            <CardMedia
              component="img"
              className={classes.eventImage}
              image="https://images.unsplash.com/photo-1506157786151-b8491531f063?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            />
          ) : (
            <CardMedia
              component="img"
              className={classes.eventImage}
              image={event?.imageUrl}
            />
          )}
        </Grid>

        <Grid item xs={5} marginRight={6} marginLeft={2}>
          <Typography
            variant="size4"
            textAlign="start"
            fontWeight={800}
            display="block"
          >
            {event?.name}
          </Typography>

          <Typography
            variant="size4"
            textAlign="start"
            fontWeight={400}
            display="block"
          >
            By {user?.name}
          </Typography>
          <Box sx={{ m: 2 }} />
          <Typography
            variant="size4"
            textAlign="start"
            fontWeight={800}
            display="block"
          >
            Scanner Code: {event?.scannerCodePassword}
          </Typography>
          <Box sx={{ m: 2 }} />
          <Box width={'50%'}>
            {buildDescriptionField(dateString, timeString, () => (
              <SvgIcon
                component={Calendar}
                className={classes.svgIcon}
                style={{
                  width: 21,
                  height: 21,
                }}
                inheritViewBox
              />
            ))}
            <Box sx={{ m: 1 }} />
            {buildDescriptionField(
              event?.addressName,
              event?.address,
              () => (
                <SvgIcon
                  component={Location}
                  className={classes.svgIcon}
                  style={{
                    width: 14,
                    height: 21,
                    marginRight: 10,
                    marginLeft: 4,
                  }}
                  inheritViewBox
                />
              ),
              true,
            )}
            <Box sx={{ m: 2 }} />
            {event?.dj &&
              buildDescriptionField(event.dj, event.music, () => (
                <SvgIcon
                  component={Cd}
                  className={classes.svgIcon}
                  style={{
                    width: 21,
                    height: 21,
                  }}
                  inheritViewBox
                />
              ))}
          </Box>
        </Grid>
        <Stack direction="row" spacing={7}>
          <Stack direction="row" spacing={7}>
            <Stack direction="column" spacing={1} alignItems="center">
              <Grid item xs={0} paddingLeft={4} paddingTop={3}>
                <Typography variant="size4" textAlign="start" fontWeight={800}>
                  Total Checked in
                </Typography>

                <Stack direction="row">
                  <Typography
                    fontSize={{ xs: 30, xl: 38 }}
                    fontWeight={200}
                    display="flex"
                  >
                    {totalUsed}/
                  </Typography>
                  <Typography
                    display="flex"
                    fontSize={{ xs: 30, xl: 38 }}
                    fontWeight={800}
                  >
                    {totalTickets}
                  </Typography>
                </Stack>
              </Grid>
            </Stack>
          </Stack>
          <div style={{ display: 'inline' }}>
            <RequestCircleProgress
              size={120}
              value={(totalUsed / totalTickets) * 100 || 0}
              color="black"
              fontSize={'size3'}
              isInvitation={false}
            />
          </div>
        </Stack>
      </Grid>
    </>
  );
};

export default LiveAttendanceTopBar;
