import { DateTime } from 'luxon';
import { Event } from 'src/models/event';

export const getEventDates = (
  event: Event,
): {
  startDate: Date;
  endDate: Date;
  dateString: string;
  timeString: string;
  timeBeforeStart: string;
} => {
  const StartDate = new Date(event.startDate);
  const StartDateUTC = StartDate; //getEventDate(event.timeZone, StartDate);
  const EndDate = new Date(event.endDate);
  const EndDateUTC = EndDate; // getEventDate(event.timeZone, EndDate);
  const timeBeforeStartDuration = DateTime.fromJSDate(StartDateUTC).diff(
    DateTime.now(),
  );
  const timeBeforeStart =
    new Date() > EndDateUTC
      ? 'Finished'
      : new Date() > StartDateUTC
      ? 'Started'
      : timeBeforeStartDuration.as('seconds') < 0
      ? '00:00:00'
      : timeBeforeStartDuration.as('days') < 1
      ? timeBeforeStartDuration.toFormat('hh:mm:ss')
      : timeBeforeStartDuration.as('months') < 1
      ? timeBeforeStartDuration.as('days') >= 2
        ? `${timeBeforeStartDuration.toFormat('d')} days`
        : '1 day'
      : timeBeforeStartDuration
      ? timeBeforeStartDuration.as('months') > 2
        ? `${timeBeforeStartDuration.toFormat('MM')} months`
        : '1 month'
      : '0 months';
  const dateString = `${StartDateUTC.toLocaleDateString('en-US', {
    timeZone: event.timeZone,
    month: 'long',
    day: 'numeric',
  })}  - ${EndDateUTC.toLocaleDateString('en-US', {
    timeZone: event.timeZone,
    month: 'long',
    day: 'numeric',
  })}`;
  const timeString = `${StartDateUTC.toLocaleTimeString('en-US', {
    timeZone: event.timeZone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })} - ${EndDateUTC.toLocaleTimeString('en-US', {
    timeZone: event.timeZone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;
  return {
    startDate: StartDateUTC,
    endDate: EndDateUTC,
    dateString,
    timeString,
    timeBeforeStart,
  };
};

export const getEventDate = (
  timeZone: string | undefined,
  date: Date,
): Date => {
  if (!timeZone) {
    return date;
  }
  return DateTime.fromJSDate(date).setZone(timeZone).toJSDate();
};

export const getDatePickerEventDate = (
  timeZone: string | undefined,
  date: Date,
): Date => {
  if (!timeZone) {
    return date;
  }
  return new Date(
    DateTime.fromJSDate(date)
      .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      .toJSDate()
      .toLocaleString(undefined, {
        timeZone,
      }),
  );
};
