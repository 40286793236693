import React from 'react';
import { createUseStyles } from 'react-jss';
import { City } from 'src/models/city';
import { Stack } from '@mui/material';
import { Box } from '@mui/system';

interface Props {
  city: City;
  eventsCount: number;
  displayEventsCount?: boolean;
}

const CityCard: React.VFC<Props> = ({
  city,
  eventsCount,
  displayEventsCount = true,
}) => {
  const useStyles = createUseStyles({
    cityCard: {},
    cityImage: {
      '@media screen and (max-width: 900px)': {
        width: '50px',
        height: '50px',
      },
      borderRadius: '50%',
      width: '65.59px',
      height: '65.59px',
      objectFit: 'cover',
      marginBottom: '5.8px',
    },
    cityNameAndEventCount: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      fontSize: '14.58px',
      fontWeight: 600,
      justifyContent: 'center',
      color: `#${city.mainColor}`,
      '@media screen and (max-width: 900px)': {
        fontSize: '13px',
      },
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    subtitle: {
      fontSize: '10.935px',
      fontWeight: 500,
      justifyContent: 'center',
      marginTop: '-2px',
      color: '#D3D3D3',
      '@media screen and (max-width: 900px)': {
        fontSize: '10px',
      },
    },
  });
  const classes = useStyles();
  return (
    <Box className={classes.cityCard}>
      <Stack direction="column" alignItems="center" width="100%">
        <img className={classes.cityImage} src={city.imageUrl} />
        <div className={classes.cityNameAndEventCount}>
          <div className={classes.title}>{city.name}</div>
          {displayEventsCount && (
            <div className={classes.subtitle}>
              {eventsCount} {eventsCount == 1 ? 'event' : 'events'}
            </div>
          )}
        </div>
      </Stack>
    </Box>
  );
};

export default CityCard;
