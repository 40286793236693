import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import Parse from 'parse/node';

import {
  serializeEventTicket,
  serializeEventTickets,
  serializeMarketingCampaigns,
  serializeMarketingRequest,
  serializeMarketingRequqests,
  serializeRequest,
  serializeRequests,
  serializeSmsPackages,
  serializeTickets,
} from 'src/helpers/serializeModels';
import { Campaign } from 'src/models/campagin';
import { MarketingRequest } from 'src/models/mRequest';
import { Request, RequestRecipient } from 'src/models/request';
import { SmsPackage } from 'src/models/smspackage';
import { EventTicket, Ticket } from 'src/models/ticket';
import { RootState } from 'src/store';
import planner from './planner';
import { updatePromoCode } from './promoCode';

/* Request slice */

interface RequestState {
  plannerRequests: Request[] | null;
  currentlyDisplayedRequest: Request | null;
  userRequests: Request[] | null;
  isSuccessfullyPaid: boolean | null;
  sentEmail: boolean | null;
  isLoading: boolean;
  requestLoadingId: TicketsNumberById;
  approvedOrRejected: TicketsNumberById;
  error: string | null;
  ischeckoutPending: boolean;
  plannerTickets: Ticket[] | null;
  isSent: boolean | null;
  isRequestLoading: boolean;
  marketingCampaigns: Campaign[] | null;
  marketingRequest: MarketingRequest | null;
  plannerMRequests: MarketingRequest[] | null;
  smsPackages: SmsPackage[] | null;
  smsOrder: string | null;
}

const initialState: RequestState = {
  plannerRequests: null,
  plannerTickets: null,
  currentlyDisplayedRequest: null,
  userRequests: null,
  isSuccessfullyPaid: null,
  sentEmail: null,
  isLoading: false,
  error: null,
  ischeckoutPending: false,
  isSent: null,
  isRequestLoading: false,
  marketingCampaigns: null,
  marketingRequest: null,
  plannerMRequests: null,
  smsPackages: null,
  smsOrder: null,
  requestLoadingId: {},
  approvedOrRejected: {},
};

type TicketsNumberById = { [ticketId: string]: number };

export interface CreateRequestDto {
  recipients: RequestRecipient[];
  ticketsNumberById: TicketsNumberById;
  eventId: string;
  currencySymbol?: string;
  tickets: EventTicket[];
  genderRatio: string | undefined;
  numberOfMen?: number;
  numberOfWomen?: number;
  userLink?: string;
}
export interface marketingRequestDto {
  emailsCount?: number;

  eventId: string;
  targetDatabase?: string;
  totalCost: number;
  targetCampaign: string;
}

export interface SmsOrderDto {
  packageid: string;
  totalCost: number;
}
export interface PayRequestDto {
  requestId: string;
  fromGifts: boolean;
  fromPoints: boolean;
  stripeToken: string;
  coupon?: string | undefined;
}

export interface paymobCallbackDto {
  orderId: string;
  integrationId: string;
}

export interface PaymobRequestDto {
  requestId: string;
  fromGifts: boolean;
  fromPoints: boolean;
  coupon?: string | undefined;
}

//make a function to reset the state of the request slice

/* Thunks */
export const createRequest = createAsyncThunk(
  'request/createRequest',
  async (createRequestDto: CreateRequestDto) => {
    try {
      const user = Parse.User.current();
      const Request = Parse.Object.extend('Request');
      const Event = Parse.Object.extend('Event');
      const newRequest = new Request();
      const { eventId, userLink, ...dto } = createRequestDto;
      newRequest.set('eventId', Event.createWithoutData(eventId));
      if (eventId === 'qT7HSRLGPz') {
        newRequest.set('isApproved', true);
        newRequest.set('isPending', false);
      } else {
        newRequest.set('isApproved', false);
        newRequest.set('isPending', true);
      }

      newRequest.set('isDeclined', false);

      newRequest.set('paid', false);
      const User = Parse.Object.extend('User');
      //find user and update his user link
      const query = new Parse.Query(User);
      query.equalTo('objectId', user?.id);
      const userObject = await query.first();
      if (userObject) {
        userObject.set('socialLink', userLink);
        await userObject.save();
      }

      newRequest.set('user', user?.toPointer());
      Object.entries(dto).map(([key, value]) => {
        newRequest.set(key, value);
      });

      return newRequest.save().then(serializeRequest);
    } catch (error) {
      console.log(error);
    }
  },
);
export const createMarketingRequest = createAsyncThunk(
  'request/createMarketingRequest',
  async (marketingRequestDto: marketingRequestDto) => {
    try {
      const user = Parse.User.current();
      const Request = Parse.Object.extend('MarketingRequest');
      const Campaign = Parse.Object.extend('MarketingCampaign');
      const Event = Parse.Object.extend('Event');
      const plannerId = user?.get('planner');
      const newRequest = new Request();
      const { eventId, ...dto } = marketingRequestDto;
      newRequest.set('eventId', Event.createWithoutData(eventId));
      newRequest.set('status', 'Pending');
      newRequest.set('plannerId', plannerId);
      newRequest.set(
        'targetCampaign',
        Campaign.createWithoutData(dto.targetCampaign),
      );
      newRequest.set('totalCost', dto.totalCost);
      if (dto.emailsCount) {
        newRequest.set('emailsCount', dto.emailsCount);
      }
      if (dto.targetDatabase) {
        newRequest.set('targetDatabase', dto.targetDatabase);
      }
      const User = Parse.Object.extend('User');
      //find user and update his user link
      newRequest.set('user', user?.toPointer());

      return newRequest.save().then(serializeMarketingRequest);
    } catch (error) {
      console.log(error);
    }
  },
);

export const createSmsOrder = createAsyncThunk(
  'request/createSmsOrder',
  async (SmsOrderDto: SmsOrderDto) => {
    try {
      const user = Parse.User.current();
      const Request = Parse.Object.extend('SmsOrders');
      const Package = Parse.Object.extend('SmsPackage');

      const plannerId = user?.get('planner');
      const newRequest = new Request();
      const { packageid, totalCost } = SmsOrderDto;

      newRequest.set('status', 'Pending');
      newRequest.set('plannerId', plannerId);
      newRequest.set('package', Package.createWithoutData(packageid));
      newRequest.set('totalCost', totalCost);

      //find user and update his user link
      newRequest.set('planner', user?.toPointer());

      return newRequest.save().then(newRequest.id);
    } catch (error) {
      console.log(error);
    }
  },
);

export const fetchEventRequests = createAsyncThunk<
  Request[],
  { eventId: string }
>('request/fetchEventRequests', async ({ eventId }) => {
  const Request = Parse.Object.extend('Request');
  const Event = Parse.Object.extend('Event');
  const queryRequest = new Parse.Query(Request).limit(10000);
  queryRequest.equalTo('eventId', Event.createWithoutData(eventId));
  queryRequest.include('user');
  queryRequest.descending('createdAt');
  try {
    const requests = await queryRequest.find();
    return serializeRequests(requests);
  } catch (error) {
    console.log(error);
  }
  return [] as Request[];
});
export const fetchMarketingCampaigns = createAsyncThunk(
  'request/fetchMarketingCampaigns',
  async () => {
    const Campaigns = Parse.Object.extend('MarketingCampaign');

    const queryRequest = new Parse.Query(Campaigns).limit(10000);

    queryRequest.descending('createdAt');
    try {
      const requests = await queryRequest.find();
      return serializeMarketingCampaigns(requests);
    } catch (error) {
      console.log(error);
    }
    return [] as Campaign[];
  },
);

export const fetchSmsPackages = createAsyncThunk(
  'request/fetchSmsPackages',
  async () => {
    const SmsPackages = Parse.Object.extend('SmsPackage');

    const queryRequest = new Parse.Query(SmsPackages).limit(10000);

    try {
      const smspackages = await queryRequest.find();
      return serializeSmsPackages(smspackages);
    } catch (error) {
      console.log(error);
    }
    return [] as SmsPackage[];
  },
);

export const fetchPlannerMRequests = createAsyncThunk(
  'request/fetchPlannerMRequests',
  async () => {
    const Requests = Parse.Object.extend('MarketingRequest');
    const Planner = Parse.Object.extend('Planner');
    const user = Parse.User.current();
    const planner = user?.get('planner');
    const plannerId = planner ? planner.id : '';
    const queryRequest = new Parse.Query(Requests);
    queryRequest.equalTo('plannerId', Planner.createWithoutData(plannerId));
    queryRequest.include('eventId');
    queryRequest.include('targetCampaign');
    try {
      const requests = await queryRequest.find();
      return serializeMarketingRequqests(requests);
    } catch (error) {
      console.log(error);
    }
    return [] as MarketingRequest[];
  },
);

export const fetchEventTickets = createAsyncThunk<
  Ticket[],
  { eventId: string }
>('request/fetchEventTickets', async ({ eventId }) => {
  const Ticket = Parse.Object.extend('Ticket');
  const Event = Parse.Object.extend('Event');
  const queryRequest = new Parse.Query(Ticket).limit(10000);
  queryRequest.equalTo('eventId', eventId);
  queryRequest.include('user');
  queryRequest.descending('createdAt');
  try {
    const requests = await queryRequest.find();
    return serializeTickets(requests);
  } catch (error) {
    console.log(error);
  }
  return [] as Ticket[];
});

export const approveRequest = createAsyncThunk<
  boolean,
  { eventId: string; id: string }
>('request/approveRequest', async ({ id, eventId }, { dispatch }) => {
  try {
    const result = await Parse.Cloud.run('approveRequest', {
      requestId: id,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
});

export const declineRequest = createAsyncThunk<
  boolean,
  { eventId: string; id: string }
>('request/declineRequest', async ({ id, eventId }, { dispatch }) => {
  try {
    const result = await Parse.Cloud.run('declineRequest', {
      requestId: id,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
});

export const refundRequest = createAsyncThunk<
  boolean,
  { eventId: string; id: string }
>('request/refundTransaction', async ({ id, eventId }, { dispatch }) => {
  try {
    const result = await Parse.Cloud.run('refundTransaction', {
      requestId: id,
    });
    dispatch(fetchEventRequests({ eventId }));
    return result;
  } catch (error) {
    console.log(error);
  }
});

export const sendTicketsInvite = createAsyncThunk<
  boolean,
  {
    inviteTickets: any;
    name: string;
    email: string;
    promoter: string;
    eventId: string;
    inviteType: string;
    phoneNumber: string;
  }
>(
  'request/sendTicketsInvite',
  async (
    { inviteTickets, name, email, promoter, eventId, inviteType, phoneNumber },
    { dispatch },
  ) => {
    const user = Parse.User.current();
    try {
      if (user && eventId) {
        const result = await Parse.Cloud.run('sendTicketsInvite', {
          userId: user.id,
          inviteTickets,
          name,
          email,
          promoter,
          eventId,
          inviteType: inviteType || 'invite',
          phoneNumber: phoneNumber || '',
        });
        dispatch(fetchEventRequests({ eventId }));
        return result;
      }
    } catch (error) {
      console.log(error);
    }
  },
);

export const addOnDoorTicket = createAsyncThunk<
  boolean,
  {
    inviteTickets: any;
    name: string;
    email: string;
    paymentMethod: string;
    phoneNumber: string;
    eventId: string;
  }
>(
  'request/addOnDoorTicket',
  async (
    { inviteTickets, name, email, paymentMethod, phoneNumber, eventId },
    { dispatch },
  ) => {
    const user = Parse.User.current();
    try {
      if (user && eventId) {
        const result = await Parse.Cloud.run('addOnDoorTicket', {
          userId: user.id,
          inviteTickets,
          name,
          email,
          paymentMethod,
          eventId,
          phoneNumber,
        });
        dispatch(fetchEventRequests({ eventId }));
        return result;
      }
    } catch (error) {
      console.log(error);
    }
  },
);

export const resendRequestEmail = createAsyncThunk<
  boolean,
  { eventId: string; requestId: string }
>(
  'request/resendRequestEmail',
  async ({ requestId, eventId }, { dispatch }) => {
    try {
      const result = await Parse.Cloud.run('resendRequestEmail', {
        requestId: requestId,
      });
      dispatch(fetchEventRequests({ eventId }));
      return result;
    } catch (error) {
      console.log(error);
    }
  },
);

export const refundRequestPayment = createAsyncThunk<
  boolean,
  { eventId: string; requestId: string }
>(
  'request/refundRequestPayment',
  async ({ requestId, eventId }, { dispatch }) => {
    try {
      const result = await Parse.Cloud.run('refundRequestPayment', {
        requestId: requestId,
      });
      dispatch(fetchEventRequests({ eventId }));
      return result;
    } catch (error) {
      console.log(error);
    }
  },
);

export const getRequestById = createAsyncThunk(
  'event/getRequestById',
  async (id: string) => {
    const user = Parse.User.current();
    const Request = Parse.Object.extend('Request');
    const queryRequest = new Parse.Query(Request);
    queryRequest.equalTo('objectId', id);
    queryRequest.equalTo('user', user?.toPointer());
    queryRequest.include('eventId');
    queryRequest.equalTo('isApproved', true);
    const request = await queryRequest.first();
    if (request) {
      return serializeRequest(request);
    }
  },
);

export const paymobCheckout = createAsyncThunk<string, PaymobRequestDto>(
  'request/paymobCheckout',
  async ({ requestId, fromGifts, fromPoints, coupon }) => {
    const user = Parse.User.current();
    if (user) {
      //parse event from request object
      const Request = Parse.Object.extend('Request');
      const queryRequest = new Parse.Query(Request);
      queryRequest.equalTo('objectId', requestId);
      queryRequest.equalTo('user', user.toPointer());
      queryRequest.include('eventId');
      const request = await queryRequest.first();
      //get eventid from request and parse event then get usePaymob from event
      const eventId = request?.get('eventId').id;
      const Event = Parse.Object.extend('Event');
      const queryEvent = new Parse.Query(Event);
      queryEvent.equalTo('objectId', eventId);
      const event = await queryEvent.first();
      const usePaymob = event?.get('usePaymob');
      //if usePaymob is true then use paymob payment method

      const result = await Parse.Cloud.run('paymobCheckout', {
        requestId: requestId,
        fromGifts: fromGifts,
        fromPoints: fromPoints,
        paid: false,
        userId: user.id,
        coupon: coupon,
      });

      return result;
    }
  },
);
export const mPaymobCheckout = createAsyncThunk(
  'request/mPaymobCheckout',
  async (requestId: any) => {
    const user = Parse.User.current();
    if (user) {
      const result = await Parse.Cloud.run('Mpaymobcheckout', {
        requestId: requestId,
      });

      return result;
    }
  },
);
export const sPaymobCheckout = createAsyncThunk(
  'request/sPaymobCheckout',
  async (requestId: any) => {
    const user = Parse.User.current();

    const result = await Parse.Cloud.run('sPaymobCheckout', {
      requestId: requestId,
    });

    return result;
  },
);

export const paymobCallback = createAsyncThunk<boolean, paymobCallbackDto>(
  'request/paymobCallback',
  async ({ orderId, integrationId }) => {
    //if usePaymob is true then use paymob payment method
    //if current state for this request is not loading then call paymobCallback

    const result = await Parse.Cloud.run('paymobCallback', {
      orderId: orderId,
      integrationId: integrationId,
    });
    return result;
  },
);

export const MpaymobCallback = createAsyncThunk<boolean, paymobCallbackDto>(
  'request/MpaymobCallback',
  async ({ orderId, integrationId }) => {
    //if usePaymob is true then use paymob payment method
    //if current state for this request is not loading then call paymobCallback

    const result = await Parse.Cloud.run('MpaymobCallback', {
      orderId: orderId,
      integrationId: integrationId,
    });
    return result;
  },
);
export const payRequest = createAsyncThunk<boolean, PayRequestDto>(
  'request/payRequest',
  async ({ requestId, fromGifts, fromPoints, stripeToken, coupon }) => {
    const user = Parse.User.current();
    if (user) {
      const isStripeModeLive = process.env.REACT_APP_STRIPE_MODE == 'LIVE';
      //parse event from request object
      const Request = Parse.Object.extend('Request');
      const queryRequest = new Parse.Query(Request);
      queryRequest.equalTo('objectId', requestId);
      queryRequest.equalTo('user', user.toPointer());
      queryRequest.include('eventId');
      const request = await queryRequest.first();
      //get eventid from request and parse event then get usePaymob from event
      const eventId = request?.get('eventId').id;
      const Event = Parse.Object.extend('Event');
      const queryEvent = new Parse.Query(Event);
      queryEvent.equalTo('objectId', eventId);
      const event = await queryEvent.first();
      const usePaymob = event?.get('usePaymob');
      //if usePaymob is true then use paymob payment method
      if (usePaymob) {
        return false;
      } else {
        const result = await Parse.Cloud.run('payRequest', {
          requestId: requestId,
          fromGifts: fromGifts,
          fromPoints: fromPoints,
          paid: false,
          token: stripeToken,
          userId: user.id,
          liveStripe: isStripeModeLive,
          coupon: coupon,
        });

        if (result && coupon) {
          try {
            const request = Parse.Object.extend('Request');
            const queryRequest = new Parse.Query(request);
            queryRequest.equalTo('objectId', requestId);
            const requestObject = await queryRequest.first();
            if (requestObject) {
              requestObject.set('coupon', coupon);
              await requestObject.save();
            }
            await updatePromoCode(coupon);
          } catch (error) {
            console.log(error);
            return false;
          }
        }

        return result;
      }
    }
  },
);

export const getUserRequests = createAsyncThunk(
  'request/getUserRequests',
  async () => {
    const user = Parse.User.current();
    if (user) {
      const Request = Parse.Object.extend('Request');
      const queryRequest = new Parse.Query(Request);
      queryRequest.equalTo('user', user.toPointer());
      queryRequest.include('eventId');
      queryRequest.ascending('createdAt');
      const requests = await queryRequest.find();
      return serializeRequests(requests);
    }
    return [];
  },
);

export const deleteRequest = createAsyncThunk<
  boolean,
  { eventId: string; id: string }
>('request/deleteRequest', async ({ id, eventId }, { dispatch }) => {
  const resultt = await Parse.Cloud.run('deleteInvitation', {
    id: id,
  });
  if (resultt) {
    dispatch(fetchEventRequests({ eventId }));
  }
  return resultt;
});

/* Shared reducers */
const sharedReducers = {
  pending: (state: any) => {
    state.isLoading = true;
    state.error = null;
  },
  rejected: (state: any, { error }: { error: SerializedError }) => {
    state.isLoading = false;
    state.error = error.message || 'error';
  },
};

/* Slice */
const requestSlice = createSlice({
  name: 'request',
  initialState: initialState,
  reducers: {
    resetSentEmail: (state) => {
      state.sentEmail = null;
    },
    resetSentRequest: (state) => {
      state.isSent = null;
    },
    resetMarketingRequest: (state) => {
      state.marketingRequest = null;
      state.isLoading = true;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchEventRequests.pending, sharedReducers.pending)
      .addCase(fetchEventRequests.rejected, sharedReducers.rejected)
      .addCase(fetchEventRequests.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.plannerRequests = payload;
        }
      })
      .addCase(fetchEventTickets.pending, sharedReducers.pending)
      .addCase(fetchEventTickets.rejected, sharedReducers.rejected)
      .addCase(fetchEventTickets.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.plannerTickets = payload;
        }
      })
      .addCase(approveRequest.pending, (state, action) => {
        state.requestLoadingId[action.meta.arg.id] = 1;
      })
      .addCase(approveRequest.rejected, sharedReducers.rejected)
      .addCase(approveRequest.fulfilled, (state, action) => {
        state.requestLoadingId[action.meta.arg.id] = 0;
        state.approvedOrRejected[action.meta.arg.id] = 1;

        state.isLoading = false;
      })
      .addCase(declineRequest.pending, (state, action) => {
        state.requestLoadingId[action.meta.arg.id] = 1;
      })
      .addCase(declineRequest.rejected, sharedReducers.rejected)
      .addCase(declineRequest.fulfilled, (state, action) => {
        state.requestLoadingId[action.meta.arg.id] = 0;
        state.approvedOrRejected[action.meta.arg.id] = 0;

        state.isLoading = false;
      })
      .addCase(getRequestById.pending, sharedReducers.pending)
      .addCase(getRequestById.rejected, sharedReducers.rejected)
      .addCase(getRequestById.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.currentlyDisplayedRequest = payload;
        }
      })
      .addCase(payRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccessfullyPaid = null;
      })
      .addCase(
        payRequest.rejected,
        (state, { error }: { error: SerializedError }) => {
          state.isLoading = false;
          if (
            error.message?.includes(
              'You cannot use a Stripe token more than once',
            )
          ) {
            state.isSuccessfullyPaid = true;
          } else {
            state.isSuccessfullyPaid = false;
            state.error = error.message ?? 'error';
          }
        },
      )
      .addCase(payRequest.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccessfullyPaid = true;
      })

      .addCase(paymobCallback.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSuccessfullyPaid = null;
      })
      .addCase(
        paymobCallback.rejected,
        (state, { error }: { error: SerializedError }) => {
          state.isLoading = false;
          state.isSuccessfullyPaid = false;
          state.error = error.message ?? 'error';
        },
      )
      .addCase(paymobCallback.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccessfullyPaid = payload;
      })
      .addCase(createRequest.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.isSent = false;
        state.isRequestLoading = true;
      })

      .addCase(createRequest.rejected, sharedReducers.rejected)
      .addCase(createRequest.fulfilled, (state) => {
        state.isLoading = false;
        state.isRequestLoading = true;
        state.isSent = true;
      })
      .addCase(getUserRequests.pending, sharedReducers.pending)
      .addCase(getUserRequests.rejected, sharedReducers.rejected)
      .addCase(getUserRequests.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.userRequests = payload;
        }
      })

      .addCase(sendTicketsInvite.pending, sharedReducers.pending)
      .addCase(sendTicketsInvite.rejected, sharedReducers.rejected)
      .addCase(sendTicketsInvite.fulfilled, (state) => {
        state.isLoading = false;
        state.sentEmail = true;
      })
      .addCase(resendRequestEmail.pending, sharedReducers.pending)
      .addCase(resendRequestEmail.rejected, sharedReducers.rejected)
      .addCase(resendRequestEmail.fulfilled, (state) => {
        state.isLoading = false;
        state.sentEmail = true;
      })
      .addCase(fetchMarketingCampaigns.pending, sharedReducers.pending)
      .addCase(fetchMarketingCampaigns.rejected, sharedReducers.rejected)
      .addCase(fetchMarketingCampaigns.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.marketingCampaigns = payload;
        }
      })
      .addCase(fetchSmsPackages.pending, sharedReducers.pending)
      .addCase(fetchSmsPackages.rejected, sharedReducers.rejected)
      .addCase(fetchSmsPackages.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.smsPackages = payload;
        }
      })
      .addCase(createMarketingRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMarketingRequest.rejected, sharedReducers.rejected)
      .addCase(createMarketingRequest.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSent = true;
        state.marketingRequest = payload;
      })
      .addCase(createSmsOrder.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSmsOrder.rejected, sharedReducers.rejected)
      .addCase(createSmsOrder.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSent = true;
        state.smsOrder = payload;
      })
      .addCase(fetchPlannerMRequests.pending, sharedReducers.pending)
      .addCase(fetchPlannerMRequests.rejected, sharedReducers.rejected)
      .addCase(fetchPlannerMRequests.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload) {
          state.plannerMRequests = payload;
        }
      });
  },
});
// export resetSentEmail
export const { resetSentEmail } = requestSlice.actions;
export const { resetMarketingRequest } = requestSlice.actions;

export const { resetSentRequest } = requestSlice.actions;
export default requestSlice.reducer;
